import {
  Grid,
  Box,
  Typography,
  Paper,
 
} from "@mui/material";
import React, { useState } from "react";
// import { MandatoryData } from "../../_mock/data-mock";
import { data } from "../../_mock/data-mock";
import styled from "styled-components";
import AddInput from "@material-ui/icons/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import TimePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import DeleteIcon from "@material-ui/icons/DeleteForever";
import CreateIcon from "@material-ui/icons/CreateOutlined";

function AdminSchoolEvent() {
  const [timetable, setTimetable] = useState(data);

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

    const Gridimage = {
      background: "#fafafa",
      minHeight: "100vh",
    };
  const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
  `;

  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 28px;
  `;
  const TH = styled.th`
    background-color: rgb(117, 201, 150);
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;

  const TD = styled.td`
    background-color: rgb(205, 205, 150);
    font-size: small;
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;

  const AddTimeTableSchema = Yup.object().shape({});

  const defaultValues = {
    courseDesc: "",
  };

  const methods = useForm({
    resolver: yupResolver(AddTimeTableSchema),
    defaultValues,
  });

  const { formState, setValue, resetField } = methods;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const [value, setValues] = React.useState();

  const onSubmit = () => {};
  return (
    <Box>
      <Grid>
        <Box>
          <Typography variant="h3" textAlign={"center"}>
            SCHOOL EVENTS
          </Typography>
        </Box>

        <Box textAlign={"right"} >
          <AddInput color="primary" />
          <Typography>Add Event</Typography>
        </Box>
        <AppContainer>
          <Table>
            <thead>
              <tr>
                <TH>S/N</TH>
                <TH>Course description</TH>
                <TH>Code</TH>
                <TH>Modules</TH>
                <TH>Date</TH>
                <TH>Target part</TH>
                <TH>Venue</TH>
                <TH>Time</TH>
                <TH>Delete</TH>
                <TH>Edit</TH>
              </tr>
            </thead>
            <tbody>
              {timetable.map((eachData, index) => (
                <tr key={index}>
                  <TD>{eachData.index}</TD>
                  <TD>{eachData.courseDesc}</TD>
                  <TD>{eachData.code}</TD>
                  <TD>{eachData.modules}</TD>
                  <TD>{eachData.date}</TD>
                  <TD>{eachData.targetPart}</TD>
                  <TD>{eachData.venue}</TD>
                  <TD>{eachData.time}</TD>
                  <TD><DeleteIcon/></TD>
                  <TD><CreateIcon/></TD>
                </tr>
              ))}
            </tbody>
          </Table>
        </AppContainer>
      </Grid>

      {/* <Grid container style={Gridimage} justifyContent="center"> */}
        {/* <Grid item alignSelf={"center"}> */}

        {/* ===================== */}
          {/* <Grid container direction="row">
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid item width={200}>
                <RHFTextField
                  name="courseDesc"
                  label="course Description"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item width={200}>
                <RHFTextField
                  name="code"
                  label="Code"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item width={200}>
                <RHFTextField
                  name="modules"
                  label="Modules"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid width={200}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    label="Event Date"
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <RHFTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item width={200}>
                <RHFTextField
                  name="targetPart"
                  label="Target Par"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item width={200}>
                <RHFTextField
                  name="venue"
                  label="Venue"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid width={200}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Event TIme"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <RHFTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item sx={{ textAlign: "right", mt: 3 }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Add Event
                </LoadingButton>
              </Grid>
            </FormProvider>
          </Grid> */}
        {/* </Grid> */}
      </Box>
    // </Grid>
  );
}

export default AdminSchoolEvent;



