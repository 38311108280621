import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  CardContent,
  Card,
  Stack,
  Alert,
  Avatar,
  ListSubheader,
  Grid,
  ListItem,
  ListItemAvatar,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useSnackbar } from "notistack";
import FolderIcon from "@material-ui/icons/Folder";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

function GradingModalSelect() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [grading, setGrading] = useState([]);

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  return (
    <Fragment>
      <Box>
        <Card sx={{ minWidth: 150 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              The access of the clerks has be colour coded as follows:
            </Typography>

            <Box display={"flex"}>
              <Box sx={{ fontSize: 10 }}>
                <Typography> Lead</Typography>
                <Typography> Support</Typography>
                <Typography>Both</Typography>
              </Box>

              <Box sx={{ fontSize: 10, ml: 2 }}>
                <Typography color={"#800080"}> Purple</Typography>
                <Typography color={"#ff0000"}> Red</Typography>
                <Typography color={"#0000ff"}>Blue</Typography>
              </Box>
            </Box>

            <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
            <Typography variant="body2"></Typography>
          </CardContent>
        </Card>

        <Box mt={3} display={"flex"} justifyContent={"space-between"}>
          <Box>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1A.1: Lesson observation "
                  style={{ color: "#800080" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>

            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1A.2: Assembly "
                  style={{ color: "#ff0000" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1A.3: Start of Day Observation "
                  style={{ color: "#0000ff" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1A.4: End of day routine "
                  style={{ color: "#0000ff" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1B: Playground "
                  style={{ color: "#ff0000" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Section 1B: Sport "
                  style={{ color: "#ff0000" }}

                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="WALKROUND "
                  style={{ color: "#800080" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>

            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Management Commitments "
                  style={{ color: "#ff0000" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Mandatory supplies "
                  style={{ color: "#800080" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Approved Safety Policies"
                  style={{ color: "#ff0000" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="5B Safeguarding "
                  style={{ color: "#800080" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
            <List>
              {/* {generate( */}
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Staff roles and responsibilities "
                  style={{ color: "#ff0000" }}
                  // secondary={secondary ? "Secondary text" : null}
                />
              </ListItem>
              {/* )} */}
            </List>
          </Box>

          <Box>
            <Box m={2}>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1B: Outdoor Activities"
                    style={{ color: "#ff0000" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1C:Meal Preparation "
                    style={{ color: "#800080" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1C: Meal Time "
                    style={{ color: "#800080" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1C: Sick Bay "
                    style={{ color: "#800080" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1D: Special Room  "
                    style={{ color: "#ff0000" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Section 1D: Lab Practical Session "
                    style={{ color: "#ff0000" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>

              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="School Transit"
                    style={{ color: "#800080" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>

              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="CC summary sheets (compliance criterion)
                  CC1"
                    style={{ color: "#0000ff" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="CC summary sheets (compliance criterion)
                  CC2 "
                    style={{ color: "#0000ff" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="CC summary sheets (compliance criterion)
                  CC3"
                    style={{ color: "#0000ff" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Recommendations"
                    style={{ color: "#0000ff" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
              <List>
                {/* {generate( */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary=" Declarartion "
                    style={{ color: "#0000ff" }}
                    // secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
                {/* )} */}
              </List>
            </Box>
          </Box>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Box></Box>

          <Box></Box>
        </Box>
        {/* ))}s */}
      </Box>
    </Fragment>
  );
}

export default GradingModalSelect;
