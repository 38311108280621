import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

SchoolStaffGuard.propTypes = {
  children: PropTypes.node,
};

export default function SchoolStaffGuard({ children }) {
  //
  const {
    isInitialized,
    userData: { stakeholderCategory, isAccountActive },
  } = useAuth();

  console.log({ isInitialized, stakeholderCategory, isAccountActive });

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized && stakeholderCategory === null) {
    return <LoadingScreen />;
  }

  if (!isAccountActive || stakeholderCategory !== "School Staff") {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
