import React, { Fragment, useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import logoBg from "../../images/sslagBookingForm.JPG";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import DataTable from "react-data-table-component";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AdminJourneyHumanResources() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [humanResources, setHumanResources] = useState(null);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolIntervention/GetHumanResourceLogInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setHumanResources(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    0 &:hover {
      cursor: pointer;
    }
  `;
  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  //   const filteredItems = student.filter(
  //     (item) =>
  //       item.studentId &&
  //       item.studentId.toLowerCase().includes(filterText.toLowerCase())
  //   );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "Staff details",
      selector: (row) => row.staffDetails,
    },
    {
      name: "Role",
      selector: (row) => row.staffRole,
    },
    {
      name: "Date",
      selector: (row) => row.dateStarted.toISOString().split("T")[0],
    },
    {
      name: "Designated duty",
      selector: (row) => row.designatedDuty,
    },
    {
      name: "Recruitment check",
      selector: (row) => row.safeRecruitmentCheckConducted,
    },
    {
      name: "Remarks",
      selector: (row) => row.remarks.slice(0, 10),
    },
  ];

  if (humanResources === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (humanResources === null) {
    return <Typography>No Record Found....</Typography>;
  }

  return (
    <Fragment>
      <Grid>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {humanResources.length === 0 ? (
            <Typography>No Record Present</Typography>
          ) : (
            <DataTable
              columns={columns}
              // data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Box>
      </Grid>
    </Fragment>
  );
}

export default AdminJourneyHumanResources;
