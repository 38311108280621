//request demo page guard

// SchoolDashboardGuard;

import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuthAdmin from "../hooks/useAuthAdmin";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";
import AdminLogin from "src/sections/Admin/AdminLogin";

import useAuth from "../hooks/useAuth";
import RequestDemo from "src/pages/auth/RequestDemo";

// ----------------------------------------------------------------------

RequestPageGuard.propTypes = {
  children: PropTypes.node,
};

export default function RequestPageGuard({ children }) {
  const {
    isInitialized,
    userData: { stakeholderCategory, isOnboardingTrainingDone, schoolId },
  } = useAuth();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  // console.log(isInitialized);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isOnboardingTrainingDone) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <RequestDemo />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
