import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
  Avatar,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AandESchool() {
  const [completedTask, setCompletedTask] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = completedTask.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "School Name",
      selector: (row) => row.school.schoolName,
    },
    {
      name: "Audit Date",
      selector: (row) => row.dateOfAudit,
    },
    {
      name: "Date Created",
      selector: (row) => row.dateCreated,
    },
    {
      name: "Validation Doc.",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(row.isValidationDocumentSent ? "True" : "false")}
        </Label>
      ),
      //   selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Clerk assigned",
      selector: (row) => row.auditClerk,
    },

    // { cell: (row) => <MoreMenuButton /> },
  ];

  useEffect(() => {
    let unmounted = false;
    const getAllNewTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AandE/GetAuditTasks?status=${3}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setCompletedTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllNewTask();
    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Typography variant="h4">Assessor Dashboard</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Task</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="AandEAssignSchool"
            className="header"
          >
            New Task
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/pendingTask"
            className="header"
          >
            PendingTask
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/completeTask"
            className="header active"
          >
            Completed task
          </Button>
        </Box>
      </Box>

      <Box>
        <Card
          sx={{
            minWidth: "80%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {completedTask.length === 0 ? (
              <Typography>No records found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={completedTask}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
}

export default AandESchool;
