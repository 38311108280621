import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function MealPreparation({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // =======new==========
    mealObserved: "",
    cookingEquipment: "",
    safetySignOnDisplay: "",
    fireSafetyEquipment: "",
    fireBlanket: "",
    isWaterAvailable: "",
    separateSectionForFood: "",
    kitchenRoutine: "",
    foodStorageFacilities: "",
    foodHygieneObserved: "",
    foodHygieneCertificate: "",
    staffPPE: "",
    sectionObserved: "",
    foodPrepared: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SumbitSectionOneMealPreparation",
        {
          auditTaskId: taskId,
          mealObserved: data.mealObserved,
          cookingEquipment: data.cookingEquipment,
          safetySignOnDisplay: data.safetySignOnDisplay,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          fireSafetyEquipment: data.fireSafetyEquipment,
          fireBlanket: data.fireBlanket,
          isWaterAvailable: data.isWaterAvailable,
          separateSectionForFood: data.separateSectionForFood,
          kitchenRoutine: data.kitchenRoutine,
          foodStorageFacilities: data.foodStorageFacilities,
          foodHygieneObserved: data.foodHygieneObserved,
          foodHygieneCertificate: data.foodHygieneCertificate,
          staffPPE: data.staffPPE,
          sectionObserved: data.sectionObserved,
          foodPrepared: data.foodPrepared,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1C: 2 Meal Preparation
                </Typography>
              </Box>

              <ol>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>
                          Is appropriate equipment for cooking seen?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="cookingEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        {" "}
                        <Typography> Safety signs on Display?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="safetySignOnDisplay"
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Is appropriate fire safety equipment seen?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="fireSafetyEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Kitchen fitted with a fire blanket?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="fireBlanket"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Separate areas dedicated to raw food stuff, cooked
                          meal and packing of prepared meals available?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="separateSectionForFood"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>Is access to water available?</Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="isWaterAvailable"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Appropriate storage facilities for different types of
                          food items seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="foodStorageFacilities"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Evidence of a kitchen routine seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="kitchenRoutine"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>
                          General evidence of good food hygiene practices
                          observed?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="foodHygieneObserved"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Food hygiene certificates for 50% of catering staff
                          seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="foodHygieneCertificate"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Appropriate PPE worn by catering staff?
                        </Typography>
                      </li>
                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="staffPPE"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>

                    <Box>
                      <li>
                        <Typography>Preparation of meals observed ?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="mealObserved"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="sectionObserved"
                          label="Section of the school observed "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="foodPrepared"
                          label="Types of food being prepared "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="comment"
                          label="Comments?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </li>
                    </Box>
                    <Grid sx={{ textAlign: "right" }}>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        // onClick={Navigate}
                        loading={isSubmitting}
                        sx={{
                          width: 100,
                          color: "#fff",
                          background: "#018fe2",
                        }}
                        // startIcon={<Back />}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </ol>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default MealPreparation;
