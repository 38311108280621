import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";

function ClerkCredential({
  taskId,
  handlePageCloseAssignClerk,
  assignedClerks,
  UpdateClerkToTask,
  RemoveClerkFromTask,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [clerks, setClerks] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // Dialog accept role confirmation
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [assignClerk, setAssignClerk] = useState(undefined);
  const [clerkRole, setClerkRole] = useState(undefined);

  const handleAddClerk = (auditClerkId, roleId) => {
    setAssignClerk(auditClerkId);
    setClerkRole(roleId);
    setDialogOpen(true);
  };

  const handleAddClose = () => {
    setAssignClerk(undefined);
    setDialogOpen(false);
  };

  // Dialog remove role confirmation

  const [openClerk, setOpenClerk] = React.useState(false);
  const handleOpenClerk = () => setOpenClerk(true);
  const handleCloseClerk = () => setOpenClerk(false);

  const [dialogOpenClerk, setDialogOpenClerk] = React.useState(false);
  // const [assignClerk, setAssignClerk] = useState(undefined);

  const handleremove = (auditClerkId, roleId) => {
    setAssignClerk(auditClerkId);
    setClerkRole(roleId);
    setDialogOpenClerk(true);
  };

  const handleRemoveClose = () => {
    setAssignClerk(undefined);
    setDialogOpenClerk(false);
  };

  useEffect(() => {
    let unmounted = false;
    const getAllClerks = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(`AandE/GetAuditClerks `);
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        let clerksFromDB = data;

        let modifiedClerksData = clerksFromDB.map((clerk) => {
          let clerkAssigned = assignedClerks.find((x) => x.id === clerk.id);
          return {
            ...clerk,
            role: clerkAssigned?.role,
            roleId: clerkAssigned?.roleId,
            dateAssigned: clerkAssigned?.dateAssigned,
          };
        });

        setClerks(modifiedClerksData);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllClerks();
    return () => {
      unmounted = true;
    };
  }, []);

  // /AandE/GetAllAandE

  const AssignClerksToTask = async (auditClerkId, roleId) => {
    try {
      const response = await axoisCustomAgent.post(
        `AandE/AssignAuditTaskToAuditClerk`,
        {
          auditClerkId,
          taskId,
          roleId,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      let clerkArray = [...clerks];
      let clerkIndex = clerkArray.findIndex((x) => x.id === auditClerkId);

      if (clerkIndex !== -1) {
        clerkArray[clerkIndex].roleId = roleId;
        clerkArray[clerkIndex].role = roleId === 1 ? "Lead" : "Support";
        clerkArray[clerkIndex].dateAssigned = new Date();
        setClerks(clerkArray);
        UpdateClerkToTask(clerkArray[clerkIndex], taskId);
      }

      enqueueSnackbar(message, { variant: "success" });

      //   updateAandEForTask(taskId, auditClerkId);
      handleAddClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const RemoveClerk = async (auditClerkId) => {
    try {
      const response = await axoisCustomAgent.post(
        `AandE/RemoveClerkFromAuditTask`,
        {
          auditClerkId,
          taskId,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      let clerkArray = [...clerks];
      let clerkIndex = clerkArray.findIndex((x) => x.id === auditClerkId);

      if (clerkIndex !== -1) {
        clerkArray[clerkIndex].roleId = undefined;
        clerkArray[clerkIndex].role = undefined;
        clerkArray[clerkIndex].dateAssigned = undefined;
        setClerks(clerkArray);

        RemoveClerkFromTask(clerkArray[clerkIndex], taskId);
        handleRemoveClose();
      }

      enqueueSnackbar(message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const isLeadPresent = clerks.some((x) => x.roleId === 1);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = clerks.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Middle Name",
      selector: (row) => row.middleName,
    },

    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      cell: (row) =>
        isLeadPresent && row.roleId === 1 ? (
          <Button variant="contained" onClick={() => handleremove(row.id, 1)}>
            Remove
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleAddClerk(row.id, 1)}
            disabled={isLeadPresent || row.roleId}
          >
            Lead
          </Button>
        ),
    },
    {
      cell: (row) =>
        row.roleId === 2 ? (
          <Button
            variant="contained"
            onClick={() => handleremove(row.id, 2)}
            disabled={isLeadPresent && row.roleId === 1}
          >
            Remove
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleAddClerk(row.id, 2)}
            disabled={isLeadPresent && row.roleId === 1}
          >
            Support
          </Button>
        ),
    },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Typography variant="h4">List of Clerks</Typography>
        <Card
          sx={{
            width: "99%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {clerks.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={clerks}
                filteredItems
                editModde="row"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Task
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to assign audit clerks?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose}>No</Button>
          <Button
            onClick={() => AssignClerksToTask(assignClerk, clerkRole)}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpenClerk}
        onClose={handleRemoveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Task
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to remove audit clerks?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveClose}>No</Button>
          <Button onClick={() => RemoveClerk(assignClerk, clerkRole)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ClerkCredential;
