import { Box, Container, Grid, Typography, Stack, Alert } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";

import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function AdminCC3({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId ,clerkId} = useParams();

 
  const defaultValues = {
    staffWellbeing: "",
    commentOnStaffWellbeing: "",
    drugAlcoholPolicy: "",
    commentOnDrugAlcoholPolicy: "",
    blockUnauthorisedAccessToRoofAndElectricals: "",
    commentOnBlockUnauthorisedAccessToRoofAndElectricals: "",
    managementReviews: "",
    commentOnManagementReviews: "",
    appropriateSafeguardingPolicy: "",
    commentOnAppropriateSafeguardingPolicy: "",
    contractorsAndVisitorsPolicy: "",
    commentOnContractorsAndVisitorsPolicy: "",
    regualarSafeyTalksInCommunity: "",
    commentOnRegualarSafeyTalksInCommunity: "",
    organisedStartAndEndOfDayArrangement: "",
    commentOnOrganisedStartAndEndOfDayArrangement: "",
    internetSafetyPolicy: "",
    commentOnInternetSafetyPolicy: "",
    appropriatePickupPolicy: "",
    commentOnAppropriatePickupPolicy: "",
    widerSafetyTalks: "",
    commentOnWiderSafetyTalks: "",
    safeRegistrationPackForPupils: "",
    commentOnSafeRegistrationPackForPupils: "",
    appropriateCodeOfConduct: "",
    commentOnAppropriateCodeOfConduct: "",
    continuousSafetyPromotion: "",
    commentOnContinuousSafetyPromotion: "",
    promotionOfSafeStimulatingLearningEnvironment: "",
    commentOnPromotionOfSafeStimulatingLearningEnvironment: " ",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const option = [
    { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];

  const optionOne = [
    // { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];
  const optionTwo = [
    { label: "U", value: "U" },
    // { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];
  const optionThree = [
    { label: "U", value: "U" },

    { label: "E", value: "E" },
  ];
  const optionFour = [
    { label: "S", value: "S" },

    { label: "G", value: "G" },
  ];
  const optionFive = [
    { label: "E", value: "E" },

    { label: "G", value: "G" },
  ];
  const optionSix = [
    { label: "S", value: "S" },

    { label: "U", value: "U" },
  ];
  useEffect(() => {
    let unmounted = false;
    const GetAdminCC = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionNineCcThree?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetAdminCC();
    return () => {
      unmounted = true;
    };
  }, []);
  const onSubmit =  () => {

  };
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  CC3: COMPLIANCE CRITERION-3
                </Typography>
              </Box>

              <Box sx={{ marginBlock: 1 }}>
                <Typography>Staff appearance and well-being</Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Appropriately dressed for the specialised activities being
                    carried out in the school =S, 1+ Management provide safe
                    working conditions (ergonomics and hours of teaching) =G,
                    1+2+ activities to promote staff wellbeing/ staff
                    enrichment demonstrated=E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="staffWellbeing"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnStaffWellbeing"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              {/* 1 */}
              <Box sx={{ marginBlock: 1 }}>
                <Typography>Implement a drug/alcohol policy</Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Policy seen =S, 1+ log of random checks conducted on
                    drug/alcohol testing on staff=G, 1+2+ briefing sessions
                    organised for staff or staff off site involvement in such
                    activities = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="drugAlcoholPolicy"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnDrugAlcoholPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>

              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Unauthorised access to the roof and electrical installation
                  blocked
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  Manholes, water pump installations, well and soak-away
                  barricaded safely and out of reach of pupils U or E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionThree.map((x) => x.value)}
                    name="blockUnauthorisedAccessToRoofAndElectricals"
                    getOptionLabel={optionThree.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnBlockUnauthorisedAccessToRoofAndElectricals"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>Evidence of Management Reviews</Typography>
                <Box fontSize={"13px"} p={2}>
                  Completed General Parents Questionnaires/Approved SS
                  Questionnaires =S or U
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionSix.map((x) => x.value)}
                    name="managementReviews"
                    getOptionLabel={optionSix.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnManagementReviews"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of an appropriate Safeguarding Policy
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  A general safeguarding policy =S, 1+ Staff training on
                  safeguarding protocols and observation log for children under
                  8 kept=G, 1+2+ class Value protocol embraced-E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="appropriateSafeguardingPolicy"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnAppropriateSafeguardingPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Contractors, Visitors & Placement Policy
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  a clear policy sighted on the premises, 1+ evidence of policy
                  communicated to 3rd parties sending placement students or
                  interns/ and/ contractors/visitor policy clearly displayed and
                  visible to visitors and badges are worn of individuals to
                  clearly identify them, 1+2+evidence of a code of conduct for
                  contractors and vendors
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="contractorsAndVisitorsPolicy"
                    getOptionLabel={option.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnContractorsAndVisitorsPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of regular safety talks to members of the school
                  community
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  pupils =S, 1+ academic staff=G, 1+2 + non academic staff and
                  parents-E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="regualarSafeyTalksInCommunity"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnRegualarSafeyTalksInCommunity"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of a well organised start and end of school day
                  arrangements
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  Personnel in charge of Pupils Crossing = S, 1+ clear signs for
                  motorists +G, 1+2+ clear briefings for parents and their
                  Drivers = E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="organisedStartAndEndOfDayArrangement"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnOrganisedStartAndEndOfDayArrangement"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>Evidence of an Internet Safety Policy</Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    policy seen =S, 1+ Information of Cyber Bullying for pupils
                    available on site =G 1+2+ workshops of Internet safety/
                    cyber bullying etc = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="internetSafetyPolicy"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnInternetSafetyPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of an appropriate Pick up policy
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Policy available on premises=S, 1+ appropriate strategy to
                    ensure that no pupil is released to the wrong individuals =
                    G, 1+2+ technology employed to ensure safe pick up
                    and drop off = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="appropriatePickupPolicy"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnAppropriatePickupPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              {/* 22 */}
              <Box sx={{ marginBlock: 1 }}>
                <Typography> Evidence of wider safety talks</Typography>
                <Box fontSize={"13px"} p={2}>
                  <Typography>
                    Road safety & stranger safety -S, 1+ Kidnapping -G, 1+2+
                    bomb threats -E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="widerSafetyTalks"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnWiderSafetyTalks"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of the use of a safe registration pack for
                  pupils/students
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    (See attached template)
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="safeRegistrationPackForPupils"
                    getOptionLabel={option.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSafeRegistrationPackForPupils"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of appropriate codes of conduct for
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    rewards and sanctions policy for pupils = S, Staff = G,
                    Parental partnership or Home/School agreement =E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="appropriateCodeOfConduct"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnAppropriateCodeOfConduct"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of promoting safety to all on a continuous basis
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    (S-Display of Fire assembly sign and in case of fire;
                    display of Insurance certificate; G-S evidence plus display
                    of "first" box in at least 2 locations; E=G evidence plus
                    Fire drills log seen and 5 relevant policies (any 5 of the
                    following:H&S, Control of Infection, Safety Management
                    system, Parental partnership; Staff Code of conducts;
                    Behaviour management Policies) seen in the School)
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="continuousSafetyPromotion"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnContinuousSafetyPromotion"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of promoting a safe stimulating learning environment
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    (S-Display of work completed by the pupils; display of
                    Insurance certificate; G= S evidence plus display of Safety
                    guidelines or frame work in at least 5 locations)
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="promotionOfSafeStimulatingLearningEnvironment"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnPromotionOfSafeStimulatingLearningEnvironment"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>

          
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminCC3;