import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Typography, Container, Alert, Stack } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

function SchoolComplianceMail() {
  const { enqueueSnackbar } = useSnackbar();
  const [schoolComplianceMail, setSchoolComplianceMail] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const { schoolId } = userData;

  useEffect(() => {
    const getAllSchoolComplianceMail = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `schoolintervention/getrecentintervention?schoolId=${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setSchoolComplianceMail(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllSchoolComplianceMail();
  }, [schoolId]);
  console.log(schoolComplianceMail);

  if (schoolComplianceMail === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (schoolComplianceMail === null) {
    return <Typography variant="h5">No Intervention....</Typography>;
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          //   paddingTop: 3,
        }}
      >
        <Box width={500} textAlign={"center"}>
          <Box>
            <Grid ml={17}>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 300,
                }}
              />
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box p={2} mt={4}>
        <Box>
          <Typography >
            Dear {schoolComplianceMail.schoolName}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h4">{schoolComplianceMail.subject}</Typography>
        </Box>
      </Box>

      <Box p={2}>
        <Box>
          <Typography>{schoolComplianceMail.message}</Typography>
        </Box>

              <ol style={{
            padding:2
        }}>
          {schoolComplianceMail.forms.map((eachItem, index) => (
            <li>
              <a href={eachItem.link}>{eachItem.name}</a>
            </li>
          ))}
        </ol>
      </Box>

      <Box p={2} mt={5}>
        <Typography>
          These must be completed before{" "}
          {schoolComplianceMail.dueDate.split("T")[0]} as the link will expire
          afterwards.
        </Typography>
      </Box>
    </Fragment>
  );
}

export default SchoolComplianceMail;
