import Home from "@material-ui/icons/Home";
import Person from "@material-ui/icons/Person";
import Close from "@material-ui/icons/KeyboardArrowDown";
import Arrow from "@material-ui/icons/KeyboardArrowUp";
import Advocacy from "@material-ui/icons/GroupOutlined";
import Analytics from "@material-ui/icons/BarChart";
import Question from "@material-ui/icons/QuestionAnswer";
import Report from "@material-ui/icons/Report";
import GroupAdd from "@material-ui/icons/GroupAdd";

import School from "@material-ui/icons/SchoolOutlined";
import Event from "@material-ui/icons/EventAvailableOutlined";
import Market from "@material-ui/icons/LocalGroceryStoreOutlined";

import Staff from "@material-ui/icons/PersonPinCircleOutlined";
import AdminIcon from "@material-ui/icons/SettingsApplicationsSharp";
import Bullet from "@material-ui/icons/FiberManualRecordOutlined";

import Memo from "@material-ui/icons/AssignmentIndOutlined";
import Train from "@material-ui/icons/LibraryBooks";
import SpellCheck from "@material-ui/icons/Spellcheck";

export const AdminData = [
  // {
  //   title: "Home",
  //   path: "/admin/dashboard",
  //   icon: <Home color="#1396e3" />,
  // },
  {
    title: "User Mgt",
    path: "admin/users",
    icon: (
      <AdminIcon
        sx={{
          color: "#fff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Create / Manage Admin",
        path: "/admin/users/createAdmins",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
  },
  {
    title: "Schools Mgt",
    path: "/schools",
    icon: (
      <Person
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Training",
        path: "/admin/school/training",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },

      {
        title: "Discount Management ",
        // path: "/admin/dashboard",
        path: "/admin/concession",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Schools ",
        path: "/admin/school/schools-allschools",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
        // icon: <Home />,
      },
    ],
  },

  {
    title: "Onboarding",
    path: "/admin/OnboardingProcess",
    icon: (
      <SpellCheck
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Pending compliance actions",
        path: "/admin/OnboardingProcess/pendingRequest",
        // path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },

      {
        title: "Manage Onboarding",
        path: "/admin/OnboardingProcess/newForm",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Send CJ 1-3",
        path: "/admin/OnboardingProcess/adminAllAlert/:schoolId ",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
    // children: [
    //   {
    //     title: "New Form",
    //     path: "admin/OnboardingProcess/newForm",
    //   },
    //   {
    //     title: "Pending Request",
    //     path: "admin/OnboardingProcess/pendingRequest",
    //   },
    //   {
    //     title: "Request Intervention ",
    //     path: "admin/OnboardingProcess/requestIntervention ",
    //   },
    // ],
  },

  {
    title: "Compliance Mgt",
    path: "/admin/dashboard",
    icon: (
      <Memo
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,

    // Children: [
    //   {
    //     title: "Audit",
    //     // path: "/admin/dashboard",
    //     icon: (
    //       <Bullet
    //         style={{
    //           width: "15px",
    //         }}
    //       />
    //     ),
    //     // icon: <Train />,
    //   },
    // ],
  },

  {
    title: "KT",
    path: "/admin/knowledgeTransfer",
    icon: (
      <School
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Create Knowledge Transfer ",
        path: "/admin/knowledgeTransfer/create",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Manage Knowledge Transfer ",
        // path: "/admin/knowledgeTransfer/manage",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Knowledge Transfer Calender ",
        // path: "/admin/knowledgeTransfer/calender",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
  },

  {
    title: "Audit",
    path: "/admin/dashboard",
    icon: (
      <Analytics
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Pre-Audit Protocol",
        path: "/admin/audit/pre-audit-protocol",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "On-site Audit Protocol",
        // path: "/admin/audit/onSiteProtocol",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Post Audit Protocol",
        // path: "/admin/audit/auditReport",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Audited School",
        path: "/admin/audit/audited-School",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
  },
  {
    title: "Events",
    path: "/admin/dashboard",
    icon: (
      <Event
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "School Events",
        path: "/admin/event/school-event",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "SSLAG Events",
        path: "/admin/event/sslag-event",
        // path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
  },
  {
    title: "Advocacy",
    // path: "/admin/dashboard",
    path: "/coming-soon",
    icon: (
      <Advocacy
        sx={{
          color: "#ffff",
        }}
      />
    ),
  },
  {
    title: "Marketplace",
    // path: "/admin/marketPlace",
    path: "/coming-soon",
    icon: (
      <Market
        sx={{
          color: "#ffff",
        }}
      />
    ),
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Manage Collaborators & Vendors",
        // path: "/admin/marketPlace/ManageCollaSME",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
      {
        title: "Order",
        // path: "/admin/marketPlace/Order",
        path: "/coming-soon",
        icon: (
          <Bullet
            style={{
              width: "15px",
            }}
          />
        ),
      },
    ],
  },

  {
    title: "Report",
    // path: "/admin/dashboard",
    path: "/coming-soon",
    icon: (
      <Question
        sx={{
          color: "#ffff",
        }}
      />
    ),
  },

  {
    title: "Support",
    // path: "/admin/dashboard",
    path: "/coming-soon",
    icon: (
      <Report
        sx={{
          color: "#ffff",
        }}
      />
    ),
  },
];
