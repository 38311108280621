import {
  Container,
  Card,
  CardActions,
  CardContent,
  Box,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import React from "react";
import Crest from "../../../images/5.png";
import Rating3 from "../../../images/success.png";
// import Rating2 from "../../images/Rating2.jpg";
// import Rating1 from "../../images/rating1.jpg";
import { MotionInView, varFade } from "../../../components/animate";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(12, 0),
}));

function EventCongratulation() {
  return (
    <RootStyle>
      <Container sx={{ position: "relative", textAlign: "center" }}>
        <Box>
          <Grid display={"grid"} justifyContent={"center"} mt={5}>
            <Card>
              <CardContent>
                <Box display={"flex"} justifyContent={"center"}>
                  <img src={Rating3} alt="" style={{ width: 500 }} />
                </Box>
                <Typography sx={{ fontSize: 23 }} color={"#018f3e"} mt={2}>
                  Your registration process is done successfully
                </Typography>
                <Grid textAlign={"center"} p={2}>
                  <Button
                    size="large"
                    variant="contained"
                    href="/"
                    // component={RouterLink}
                    // to={PATH_AUTH.login}
                    sx={{ mt: 5 }}
                  >
                    Back Home
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </RootStyle>
  );
}

export default EventCongratulation;
