import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Modal,
  Stack,
  Alert,
  Checkbox,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";

function SchoolCJKtModal({ addData }) {
  const defaultValues = {
    name: "",
    description: "",
    numOfNonTeachingStaff: "",
    numOfTeachingStaff: "",
    unitPrice: "",
    quantity: "",
    amount: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;





  const onSubmit = (data) => {
    addData({...data, amount: data.quantity * data.unitPrice});
    methods.reset();
  };
  return (
    <Fragment>
      <Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>
          <Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField name="name" label="Item" variant="filled" />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="description"
                multiline={true}
                minRows={3}
                label="Description"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="numOfTeachingStaff"
                label="No. of teaching staff"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="numOfNonTeachingStaff"
                label="No. of non-teaching staff"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Date ` "
                  disablePast
                  value={methods.watch().toDate}
                  onChange={(value) => {
                    methods.setValue("toDate", value, {
                      shouldValidate: true,
                    });
                  }}
                  renderInput={(params) => (
                    <RHFTextField variant="filled" {...params} name="toDate" />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="quantity"
                label="Total quantity booked"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="unitPrice"
                label="Unit price"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField name="amount" disable label="Amount" variant="filled" />
            </Box>
            <Grid sx={{ p: 3, textAlign: "center" }}>
              <LoadingButton
                variant="outlined"
                type="submit"
                loading={isSubmitting}
                sx={{ color: "#fff", width: 160, background: "#018fe2" }}
                // startIcon={<Back />}
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </FormProvider>
      </Box>
    </Fragment>
  );
}

export default SchoolCJKtModal;
