import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box as Grid,
  TextField,
  Button,
  Stack,
  Link,
} from "@mui/material";
import Container from "@mui/material/Container";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../hooks/useAuth";
import Back from "@material-ui/icons/ChevronLeft";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { async } from "@firebase/util";

function Declarations({ prevStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { userData, setIsOnboardingCompleted } = useAuth();
  const { schoolId } = userData;

  const [state, setState] = useState({
    additionalInformation: "",
    isTermsAndConditionAccepted: false,
    isInformationCertified: false,
    fullName: "",
    roleInSchool: "",
    email: "",
    uploadDescription: "",
    imageId: "",
    imageUrl: "",
    date: new Date(),
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getKeyPersonnelDetails = async () => {
      try {
        const response = await axoisCustomAgent.get(
          `SchoolOnboarding/GetKeyPersonnelInformation/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode !== 200) throw message;

        const { fullName, email, roleInSchool } = response.data.data;
        setState({
          ...state,
          fullName,
          email,
          roleInSchool,
        });
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getKeyPersonnelDetails();
  }, [schoolId]);

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;

          setState({
            ...state,
            imageId: imageId,
            imageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };

  const handleChange = (event, type) => {
    setState({
      ...state,
      [type]: event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setState({
      ...state,
      isTermsAndConditionAccepted: event.target.checked,
    });
  };

  const handleCheck2Change = (event) => {
    setState({
      ...state,
      isInformationCertified: event.target.checked,
    });
  };
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    // validate fields
    if (state.fullName.trim() === "") {
      enqueueSnackbar("Full Name is required", {
        variant: "error",
      });
      return;
    }

    if (state.roleInSchool.trim() === "") {
      enqueueSnackbar("School role is required", {
        variant: "error",
      });
      return;
    }

    if (state.imageId.trim() !== "" && state.uploadDescription.trim() === "") {
      enqueueSnackbar("Enter description for your upload", {
        variant: "error",
      });
      return;
    }

    if (state.imageId.trim() === "" && state.uploadDescription.trim() !== "") {
      enqueueSnackbar("Upload a file for your description", {
        variant: "error",
      });
      return;
    }

    if (!state.isTermsAndConditionAccepted) {
      enqueueSnackbar("Accept terms and conditions to continue", {
        variant: "error",
      });
      return;
    }
    if (!state.isInformationCertified) {
      enqueueSnackbar("Accept terms and conditions to continue", {
        variant: "error",
      });
      return;
    }

    setLoadingSubmit(true);

    try {
      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/SubmitOnboardingdeclarations/${schoolId}`,
        {
          additionalInformation: state.additionalInformation,
          isTermsAndCondtionAccepted: state.isTermsAndConditionAccepted,
          fullName: state.fullName,
          isInformationCertified: state.isInformationCertified,
          email: state.email,
          roleInSchool: state.roleInSchool,
          additionalImages:
            state.imageUrl === ""
              ? []
              : [
                  {
                    description: state.uploadDescription,
                    imageId: state.imageId.trim() === "" ? null : state.imageId,
                    imageUrl:
                      state.imageUrl.trim() === "" ? null : state.imageUrl,
                  },
                ],
        }
      );

      if (response.status === 400) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
        setLoadingSubmit(false);
        return;
      }

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        setIsOnboardingCompleted();

        navigate("/auth/onSuccess");
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      // console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  // ===============================
  // ===============================

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),  url(${Bg1}) `,
          backgroundSize: "cover",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              sx={{ fontSize: 20 }}
              onClick={async () => await handleLogout()}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>

        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              padding: 2,
              margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
              maxHeight: 1050,
            }}
          >
            <Typography sx={{ padding: 2, color: "#6495ed", fontSize: "23px" }}>
              Supporting Information & Declarations
            </Typography>

            {loading ? (
              <p>Loading.........</p>
            ) : (
              <Container
                sx={{
                  // maxWidth: 120,
                  display: "-ms-grid",
                }}
              >
                <Stack>
                  <TextField
                    sx={{ width: 470, m: 2 }}
                    id="FullName"
                    label="Any additional information you like to add"
                    variant="filled"
                    value={state.additionalInformation}
                    onChange={(e) => handleChange(e, "additionalInformation")}
                  />
                  <Typography>Any additional upload you like to add</Typography>

                  <div
                    style={{
                      border: "1px solid grey",
                      width: "400px",
                      marginTop: "10px",
                      padding: "6px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "12px",
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        onChange={(event) => {
                          setImageSelected(event.target.files[0]);
                        }}
                      />
                      <button
                        onClick={async () => await uploadImage()}
                        disabled={imageUploading || state.imageUrl.length > 0}
                      >
                        Upload Evidence
                      </button>
                    </div>
                  </div>
                  <br />
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    variant="filled"
                    placeholder="Description of the upload"
                    value={state.uploadDescription}
                    onChange={(e) => handleChange(e, "uploadDescription")}
                    style={{
                      width: "400px",
                      marginLeft: "13px",
                      height: "20vh",
                      mt: 1,
                    }}
                  />
                </Stack>

                <Typography ml={2} mt={3}>
                  I hereby certify that the information given in the form is
                  accurate{" "}
                </Typography>
                <Stack width={500}>
                  <TextField
                    sx={{ m: 2 }}
                    id="FullName"
                    label="Full name"
                    variant="filled"
                    required
                    disabled
                    value={state.fullName}
                    onChange={(e) => handleChange(e, "fullName")}
                  />
                  <TextField
                    sx={{ m: 2 }}
                    id="SchoolRole"
                    label="Designation"
                    variant="filled"
                    required
                    disabled
                    value={state.roleInSchool}
                    onChange={(e) => handleChange(e, "roleInSchool")}
                  />
                  <TextField
                    sx={{ m: 2 }}
                    id="Email"
                    label="Email"
                    variant="filled"
                    type="email"
                    required
                    disabled
                    value={state.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </Stack>
                <Stack width={470} mt={2} ml={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date "
                      inputFormat="dd/MM/yyyy"
                      value={state.date}
                      variant="filled"
                      disabled
                      onChange={handleChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <FormGroup
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isInformationCertified}
                          onChange={handleCheck2Change}
                        />
                      }
                      label="I hereby certify that the information given is true and accurate  "
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isTermsAndConditionAccepted}
                          onChange={handleCheckChange}
                        />
                      }
                      label=" "
                    />
                    <Typography>
                      Click to accept SSLAG{" "}
                      <a
                        href="/auth/DecTermNdCondition"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                    </Typography>
                  </FormGroup>
                </Stack>

                <Grid sx={{ mt: 9, textAlign: "right" }}>
                  <LoadingButton
                    loading={loadingSubmit}
                    label="Prev"
                    variant="contained"
                    type="submit"
                    startIcon={<Back />}
                    sx={{ mr: 4 }}
                    onClick={prevStep}
                  >
                    Back
                  </LoadingButton>

                  <LoadingButton
                    loading={loadingSubmit}
                    variant="contained"
                    type="submit"
                    onClick={async () => await handleSubmit()}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Declarations;
