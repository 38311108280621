import React, { useState } from "react";
import {
  Box,
  Typography,

} from "@mui/material";


import  {KnowledgeTransfer}  from "../../_mock/data-mock";
import styled from "styled-components";

function KtBookingForm() {
  const [knowledge, setKnowledge] = useState(KnowledgeTransfer);

  const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
  `;

  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 28px;
  `;
  const TH = styled.th`
    background-color: rgb(117, 201, 250);
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;

  const TD = styled.td`
    background-color: rgb(205, 235, 253);
    font-size: small;
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;

  return (
    <Box>
      <Box textAlign={"center"}>
        <Typography color={"#6495ed"} >
          SSLAG TRAINING PROVIDERS AND SUBJECT MATTER EXPERTS ACCREDITATION
          KNOWLEDGE TRANSFER SESSIONS
        </Typography>
      </Box>
      <AppContainer>
        <Table>
          <thead>
            <tr>
              <TH>S/N</TH>
              <TH>EVENTS</TH>
              <TH>TARGET AUDIENCE</TH>

              <TH>REGISTER (insert no of delegate)</TH>
            </tr>
          </thead>
          <tbody>
            {knowledge.map((eachData, index) => (
              <tr key={index}>
                <TD>{eachData.index}</TD>
                <TD>{eachData.event}</TD>
                <TD>{eachData.targetAud}</TD>
                <TD>{eachData.registerDel}</TD>
              </tr>
            ))}
          </tbody>
        </Table>
      </AppContainer>
    </Box>
  );
}

export default KtBookingForm;


