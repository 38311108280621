import { Button } from "@mui/material";
import React from "react";

function SosPopover() {
  return (
    <Button color="error" variant="contained" to="#">
      S.O.S
    </Button>
  );
}

export default SosPopover;
