import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function SickBay({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // =======new====================

    isSickBayOrClinic: "",
    fireEquipment: "",
    sickBayEquipment: "",
    safetySignOnDisplay: "",
    firstAidProvision: "",
    fireSafetyEquipment: "",
    isWaterAvailable: "",
    qualifiedSchoolNurse: "",
    EvidenceOfMedicalIncident: "",
    medicationPolicies: "",
    evidenceOfGoodFirstAidPractice: "",
    firstAiderCertificate: "",
    sickBayPPE: "",
    sectionObserved: "",
    childrenPresentAtSickBay: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const Navigate = () => {};

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SumbitSectionOneSickbay",

        {
          auditTaskId: taskId,
          isSickBayOrClinic: data.isSickBayOrClinic,
          fireEquipment: data.fireEquipment,
          sickBayEquipment: data.sickBayEquipment,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          safetySignOnDisplay: data.safetySignOnDisplay,
          firstAidProvision: data.firstAidProvision,
          fireSafetyEquipment: data.fireSafetyEquipment,
          isWaterAvailable: data.isWaterAvailable,
          qualifiedSchoolNurse: data.qualifiedSchoolNurse,
          EvidenceOfMedicalIncident: data.EvidenceOfMedicalIncident,
          medicationPolicies: data.medicationPolicies,
          evidenceOfGoodFirstAidPractice: data.evidenceOfGoodFirstAidPractice,
          firstAiderCertificate: data.firstAiderCertificate,
          sickBayPPE: data.sickBayPPE,
          sectionObserved: data.sectionObserved,
          childrenPresentAtSickBay: data.childrenPresentAtSickBay,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1C: 3 Sick Bay
                </Typography>
              </Box>

              <ol>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>Sick bay or clinic provided ?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isSickBayOrClinic"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Appropriate fire safety equipment seen ?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="fireEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Is appropriate equipment for provision seen?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="sickBayEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography> Safety signs on Display?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="safetySignOnDisplay"
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Appropriate first aid provision seen??
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="firstAidProvision"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Provision manned by a qualified school nurse?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="qualifiedSchoolNurse"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>Is access to water available?</Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="isWaterAvailable"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Evidence of a proper logs of medical incidents/
                          emergencies?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="EvidenceOfMedicalIncident"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Relevant medication/sickness policies seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="medicationPolicies"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>
                          General evidence of good first aid practices observed?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfGoodFirstAidPractice"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Named First Aiders certificates for 50% of the staff
                          in charge seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="firstAiderCertificate"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Appropriate PPE available in the sickbay/clininc?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="sickBayPPE"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="sectionObserved"
                          label="Section of the school observed "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="childrenPresentAtSickBay"
                          label="No of children attending the sick bay/clinic at the time of the audit "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="comment"
                          label="Comments?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </li>
                    </Box>
                    <Grid sx={{ textAlign: "right" }}>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        // onClick={Navigate}
                        loading={isSubmitting}
                        sx={{
                          width: 100,
                          color: "#fff",
                          background: "#018fe2",
                        }}
                        // startIcon={<Back />}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </ol>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SickBay;
