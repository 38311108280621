import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @mui
import { Container, Card,Button,Grid } from "@mui/material";
// redux
import { useDispatch } from "../../redux/store";
import { getLabels } from "../../redux/slices/mail";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {
  MailList,
  MailDetails,
  MailSidebar,
  MailCompose,
} from "../../sections/@dashboard/mail";

import MailBox from "@material-ui/icons/MailOutline";

// ----------------------------------------------------------------------

export default function MailSupport() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { mailId } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openCompose, setOpenCompose] = useState(false);

  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  return (
    <Page title="Mail">
      <Container maxWidth={themeStretch ? false : "xl"}>
        {/* <HeaderBreadcrumbs
          heading="Mail"
          links={[
            {
              name: "Intervention",
              href: PATH_DASHBOARD.mail.root,
            },
            { name: "Support", href: PATH_DASHBOARD.supportmail },
          ]}
        /> */}
        <Grid>
          <Button
            href="/dashboard/mail/all"
            // className="header active"
            startIcon={<MailBox />}
          >
            Intervention
          </Button>
          <Button
            className="header active"
            href="/dashboard/mail/supportmail"
            startIcon={<MailBox />}
          >
            Support
          </Button>
        </Grid>
        <Card sx={{ height: { md: "72vh" }, display: { md: "flex" } }}>
          <MailSidebar
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => setOpenSidebar(false)}
            onOpenCompose={() => setOpenCompose(true)}
          />
          {mailId ? (
            <MailDetails />
          ) : (
            <MailList onOpenSidebar={() => setOpenSidebar(true)} />
          )}
          <MailCompose
            isOpenCompose={openCompose}
            onCloseCompose={() => setOpenCompose(false)}
          />
        </Card>
      </Container>
    </Page>
  );
}
