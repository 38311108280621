import React, { useState } from "react";
import { ComplianceObligations } from "../../../_mock/data-mock";
import { Grid, Box, Typography, Button, IconButton } from "@mui/material";
import Bullet from "@material-ui/icons/CheckCircleOutline";
import SocialsButton from "src/components/SocialsButton";
import logoBg from "../../../images/sslag.png";

function ObligationChecklist() {
  const [checkList, setCheckList] = useState(ComplianceObligations);
  return (
    <Box>
      {checkList.map((eachData, index) => (
        <Box key={index} m={2} display={"flex"}>
          <Bullet />
          <Typography ml={2} variant="body2">
            {eachData.checklist}
          </Typography>
        </Box>
      ))}

      <Grid display={"flex"} justifyContent={"center"}>
        <Box mt={5}>
          <Box>
            <img
              src={logoBg}
              alt=""
              style={{
                width: 150,
              }}
            />
          </Box>
          <SocialsButton color="primary" />
        </Box>
      </Grid>
    </Box>
  );
}

export default ObligationChecklist;
