import React, { Fragment, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
  TextareaAutosize,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
// import { Box } from "@mui/system";

function AdminSchoolDetail() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> Supporting Information & Declarations</Typography>
            </Grid>
            <Box display={"flex"}>
              <Grid>
                <FormHelperText>
                  Any additional information you like to add:
                </FormHelperText>
                <TextareaAutosize
                  maxRows={4}
                  sx={{ marginBlock: 1, width: 250 }}
                  id="AddInfo"
                  variant="outlined"
                  disabled
                  style={{
                    width: "400px",
                    // marginLeft: "13px",
                    height: "20vh",
                    mt: 1,
                  }}
                />
                <Grid>
                  <FormHelperText>
                    Any additional upload you like to add:
                  </FormHelperText>
                  <TextField
                    sx={{ marginBlock: 1, width: 250 }}
                    id="AddImage"
                    variant="outlined"
                    disabled
                  />
                  <Button>View Image</Button>
                </Grid>

                <FormHelperText>Description of the upload:</FormHelperText>
                <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Description of the upload"
                  disabled
                  style={{
                    width: "400px",
                    // marginLeft: "13px",
                    height: "20vh",
                    mt: 1,
                  }}
                />
                <Grid>
                  <Grid display={"flex"}>
                    <Grid>
                      <FormHelperText>FullName:</FormHelperText>
                      <TextField
                        sx={{ marginBlock: 1, width: 250 }}
                        id="FullName"
                        variant="outlined"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid ml={2}>
                      <FormHelperText>Designation:</FormHelperText>
                      <TextField
                        sx={{ marginBlock: 1, width: 250 }}
                        id="Designation"
                        variant="outlined"
                        required
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid display={"flex"}>
                    <Grid>
                      <FormHelperText>Email:</FormHelperText>
                      <TextField
                        sx={{ marginBlock: 1, width: 250 }}
                        id="email"
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid ml={2}>
                      <FormHelperText>Date:</FormHelperText>
                      <TextField
                        sx={{ marginBlock: 1, width: 250 }}
                        id="email"
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 5 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => openAlertModal()}
                  >
                    Intervene
                  </Button>
                </Grid>
              </Grid>

              <Grid ml={10}></Grid>
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolDetail;
