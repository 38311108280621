import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
  MotionInView,
} from "../../../components/animate";
import Vest from "../../../images/High visibility vest.png";
import AboutKid from "../../../images/aboutWallpaper.png";
import { Fragment } from "react";
import BOSimage from "../../../images/framework.png";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
// ----------------------------------------------------------------------

export default function MajorKey() {
  return (
    <Fragment>
      <MotionInView variants={varFade().inUp}>
        <Box>
          <img
            // src={BOSimage}
            alt="sslag event"
            style={{
              height: 450,
              width: 800,
              borderRadius: "6px",
            }}
          />
        </Box>
        <Typography mt={4} variant="h4" sx={{ textAlign: "center" }}>
          SSLAG Framework
        </Typography>
      </MotionInView>
      <Box display={"flex"} justifyContent={"center"}>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", lineHeight: 2, p: 2, width: "70%" }}
        >
          Schools are changing, and responsibilities for safety and well-being
          management are being refocused. Schools must comply with the
          well-being and safety requirements put in place as proportionate
          control measures. Safety and well-being should be approached
          deliberately and not just as another legal burden or bureaucratic
          chore.
        </Typography>
      </Box>

      <Box display={"flex"} justifyContent={"center"} mt={2}>
        <img
          src={BOSimage}
          alt="sslag event"
          style={{
            height: 470,
            width: "80%",
            borderRadius: "6px",
          }}
        />
      </Box>
    </Fragment>
  );
}
