import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Alert,
  FormHelperText,
  Button,
  Card,
} from "@mui/material";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import styled from "styled-components";
import DataTable from "react-data-table-component";

import { async } from "@firebase/util";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function Location() {
  const PaperStyle = {
    padding: 40,
    width: 400,
  };

  const { userData } = useAuth();
  const { schoolId } = userData;

  // const [locationid, setLocationid] = useState([]);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };
   const customStyles = {
     headCells: {
       style: {
         paddingLeft: "8px", // override the cell padding for head cells
         paddingRight: "8px",
         // background: "#34a8e1",
         fontSize: "19px",
         borderRadius: 2,
       },
     },
   };


  const defaultValues = {
    location: "",
  };

  const LocationSchema = Yup.object().shape({
    location: Yup.string().required("duty  is required"),
  });

  const methods = useForm({
    resolver: yupResolver(LocationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `SchoolLocation/AddLocation/${schoolId}`,
        {
          schoolId,
          location: data.location,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      // methods.reset();
      window.location.reload();
      enqueueSnackbar(message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    let unmounted = false;
    const getAllLocation = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolLocation/GetAllLocations?schoolId=${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setLocation([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllLocation();
    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const UndoDuty = async (schoolId, locationId, createdAt) => {
    try {
      const response = await axoisCustomAgent.delete(
        `SchoolLocation/DeleteLocation?schoolId=${schoolId}&locationId=${locationId}`
      );

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      window.location.reload();

      enqueueSnackbar(message, { variant: "success" });
      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = location.filter(
    (item) =>
      item.location &&
      item.location.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      style:{
        width:"10px"
      }
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt.split("T")[0],
    },
    {
      cell: (row) => (
        <button
          className="unassign"
          onClick={() => UndoDuty(schoolId, row.id, row.createdAt)}
        >
          Delete
        </button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All location...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> School Management</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/staff"
            className="header "
          >
            Add Staff
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/allstaff"
            className="header"
          >
            All Staff
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/addDuties"
            className="header active"
          >
            Duty location
          </Button>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/staffDuties"
            className="header"
          >
            Staff Duty
          </Button>
        </Box>
      </Box>
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={7}>
          <Paper style={PaperStyle}>
            <Grid align="center">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                {/* <Typography>Create location</Typography>
                <hr /> */}
                <Grid>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Create location
                    </Typography>
                  </Box>
                </Grid>

                <Grid container mt={3}>
                  <Grid item width={305}>
                    {/* <FormHelperText>Location :</FormHelperText> */}
                    <RHFTextField
                      name="location"
                      variant="filled"
                      label="Location"
                   
                    />
                  </Grid>
                </Grid>

                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Card>
        {location.length === 0 ? (
          <Typography> No records found</Typography>
        ) : (
          <Grid p={3}>
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          </Grid>
        )}
      </Card>
    </Box>
  );
}

export default Location;
