import {
  Box,
  Grid,
  Paper,
  Stack,
  Alert,
  Typography,
  Button,
} from "@mui/material";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { axoisCustomAgentForAdmin } from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const PaperStyle = {
  padding: 40,
  width: 500,
};

const Gridimage = {
  background: "#fafafa",
  minHeight: "90vh",
};

const GENDER_OPTIONS = [
  {
    value: "0",
    label: "Select Gender",
  },
  {
    value: "1",
    label: "Male",
  },
  {
    value: "2",
    label: "Female",
  },
  {
    value: "3",
    label: "Other",
  },
];

const ADMINROLE_ID = [
  {
    value: "0",
    label: " Select Admin Role",
  },

  {
    value: "2",
    label: " SSLAG Admin",
  },
  {
    value: "3",
    label: "LSC Admin",
  },
  {
    value: "4",
    label: "SSLAG User",
  },
  {
    value: "5",
    label: "LSC User",
  },
  {
    value: "6",
    label: "SSLAG Report User",
  },
  {
    value: "7",
    label: "LSC Report User",
  },
];

//===============================================================

function CreateAdmin() {
  const { enqueueSnackbar } = useSnackbar();

  const CreateAdminSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    email: Yup.string()
      .email("Enter a valid Email Address")
      .required("Email is required"),
    adminRoleId: Yup.string().oneOf(
      ["2", "3", "4", "5", "6", "7", "8"],
      "Select a valid admin role "
    ),
    gender: Yup.string().oneOf(["1", "2", "3"], "Select a gender type "),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgentForAdmin.post(
        "/admins/createAdmin",
        {
          email: data.email,
          adminRoleId: data.adminRoleId,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    adminRoleId: "",
    gender: "",
  };

  const methods = useForm({
    resolver: yupResolver(CreateAdminSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <Box>
      <Box textAlign={"right"} mt={5}>
        <Box paddingRight={5}>
          <Button
            // variant="outlined"

            color="primary"
            href="/admin/users/createAdmins"
            className="header active "
          >
            Create Admin
          </Button>

          <Button
            // variant="outlined"

            color="primary"
            href="/admin/users/adminList"
            className="header  "
          >
            Manage Admin
          </Button>
        </Box>
      </Box>
      <Typography variant="h4">Admin Dashboard</Typography>
      {/* <AdminList /> */}
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={5}>
          <Paper style={PaperStyle}>
            <Grid align="center">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Create SSLAG Admin
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    id="filled-basic"
                    name="firstName"
                    label="First Name"
                    sx={{
                      marginBlock: 2,
                      width: 300,
                    }}
                    variant="filled"
                  />
                </Grid>
                <Grid mt={2}>
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    id="filled-basic"
                    name="lastName"
                    label="Last Name"
                    sx={{
                      marginBlock: 2,
                      width: 300,
                    }}
                    variant="filled"
                  />
                </Grid>
                <Grid mt={2}>
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    id="filled-basic"
                    name="email"
                    label="E-mail"
                    type="email"
                    sx={{
                      marginBlock: 2,
                      width: 300,
                    }}
                    variant="filled"
                  />
                </Grid>
                <Grid mt={2}>
                  <RHFTextField
                    select
                    name="adminRoleId"
                    label="Select Admin Role"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                  >
                    {ADMINROLE_ID.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </RHFTextField>
                </Grid>
                <Grid mt={2}>
                  <RHFTextField
                    select
                    name="gender"
                    label="Gender"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                  >
                    {GENDER_OPTIONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </RHFTextField>
                </Grid>

                <Box textAlign={"right"} mt={2}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ width: 150 }}
                    loading={isSubmitting}
                  >
                    Create
                  </LoadingButton>
                </Box>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateAdmin;
