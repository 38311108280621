import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  Alert,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SchoolProvisionalSafety() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const [dataStatus, setDataStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [schooldetailTime, setSchoolDetailTime] = React.useState("");
  const [schooldeclarationTime, setSchoolDeclarationTime] = React.useState("");
  const [initiationComplianceDate, setInitiationComplianceDate] =
    React.useState("");

  const names = ["Nigerian", "British", "American", "French"];

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "Provisional Safety License Application",
            token,
          }
        );

        const { statusCode, message, data } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }

        setLinkStatus(message);
        setDataStatus(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const theme = useTheme();
  const [schoolcirculums, setSchoolCirculums] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        schoolcirculums.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSchoolCirculums(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const defaultValues = {
    schoolName: "",
    schoolLocation: "",
    schoolEducationDistrict: "",
    contactEmail: "",
    contactPhoneNumber: "",
    contactLGA: "",
    mainContactName: "",
    mainContactEmail: "",
    mainPosition: "",
    mainContactPhoneNumber: "",
    mainContactAltPhoneNumber: "",
    mainContactRole: "",
    mainContactNumOfYearsInRole: "",
    facilities: "",
    numOfMaleStudents: "",
    numOfFemaleStudents: "",
    totalNumOfStudents: "",
    numOfMaleStaff: "",
    numOfFemaleStaff: "",
    totalNumOfStaff: "",
    numOfMaleTeachingStaff: "",
    numOfFemaleTeachingStaff: "",
    totalNumOfTeachingStaff: "",
    numOfMaleNonTeachingStaff: "",
    numOfFemaleNonTeachingStaff: "",
    totalNumOfNonTeachingStaff: "",
    schoolType: "",
    schoolComposition: "",
    schoolCurriculum: "",
    hasSchoolInitiatedComplianceJourney: "",
    complianceInitiationDate: "",
    aandELead: "",
    ministryOfEducationReference: "",
    declarationPosition: "",
    declarationFullName: "",
    declarationAcceptance: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitProvisionalSafetyLicenseInterventionForm`,
        {
          schoolId,
          token,
          schoolName: data.schoolName,
          schoolLocation: data.schoolLocation,
          schoolEducationDistrict: data.schoolEducationDistrict,
          contactEmail: data.contactEmail,
          contactPhoneNumber: data.contactPhoneNumber,
          contactLGA: data.contactLGA,
          mainContactName: data.mainContactName,
          mainContactEmail: data.mainContactEmail,
          mainContactPhoneNumber: data.mainContactPhoneNumber,
          mainContactAltPhoneNumber: data.mainContactAltPhoneNumber,
          mainContactRole: data.mainContactRole,
          mainContactNumOfYearsInRole: data.mainContactNumOfYearsInRole,
          facilities: data.facilities,
          numOfMaleStudents: data.numOfMaleStudents,
          numOfFemaleStudents: data.numOfFemaleStudents,
          totalNumOfStudents: data.totalNumOfStudents,
          numOfMaleStaff: data.numOfMaleStaff,
          numOfFemaleStaff: data.numOfFemaleStaff,
          totalNumOfStaff: data.totalNumOfStaff,
          numOfMaleTeachingStaff: data.numOfMaleTeachingStaff,
          numOfFemaleTeachingStaff: data.numOfFemaleTeachingStaff,
          totalNumOfTeachingStaff: data.totalNumOfTeachingStaff,
          numOfMaleNonTeachingStaff: data.numOfMaleNonTeachingStaff,
          numOfFemaleNonTeachingStaff: data.numOfFemaleNonTeachingStaff,
          totalNumOfNonTeachingStaff: data.totalNumOfNonTeachingStaff,
          schoolType: data.schoolType,
          schoolComposition: data.schoolComposition,
          schoolCurriculum: data.schoolCurriculum,
          hasSchoolInitiatedComplianceJourney:
            data.hasSchoolInitiatedComplianceJourney,
          complianceInitiationDate: data.complianceInitiationDate,
          aandELead: data.aandELead,
          ministryOfEducationReference: data.ministryOfEducationReference,
          declarationAcceptance: data.declarationAcceptance,
          declarationPosition: data.declarationPosition,
          declarationFullName: data.declarationFullName,
          mainPosition: data.mainPosition,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const SCHOOLTYPE = [
    {
      value: "0",
      label: "Select school type",
    },
    {
      value: "1",
      label: "Day",
    },
    {
      value: "2",
      label: "Boarding",
    },
    {
      value: "3",
      label: "Boarding and Day",
    },
  ];

  const COMPLIANCE = [
    {
      value: "0",
      label: "Select compliance journey ",
    },
    {
      value: "1",
      label: "Yes",
    },
    {
      value: "2",
      label: "No",
    },
    {
      value: "3",
      label: "Don't know",
    },
  ];

  const SCHOOLCOMPOSITION = [
    {
      value: "0",
      label: "Select whether school is ",
    },
    {
      value: "1",
      label: "A-Levels",
    },
    {
      value: "2",
      label: "Tutorial",
    },
    {
      value: "3",
      label: "pry / secondary",
    },
  ];
  const complianceStage = watch("hasSchoolInitiatedComplianceJourney");
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingInline: "20px",
              paddingTop: 5,
            }}
          >
            <Grid>
              <Box textAlign={"center"}>
                <Box>
                  <Grid>
                    <img
                      src={logoBg}
                      alt=""
                      style={{
                        width: 400,
                      }}
                    />
                  </Grid>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#097969",
                    mt: 2,
                  }}
                >
                  Provisional Safety License Application
                </Typography>
              </Box>
            </Grid>

            <Container
              sx={{
                background: "#ffff",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                mt: 2,
                minHeight: 800,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    background: "#097969",
                    borderRadius: 0.5,
                    boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                    color: "#ffff",
                    minHeight: 40,
                  }}
                >
                  <Typography variant="h6" padding={2}>
                    SCHOOL DETAILS
                  </Typography>
                </Box>
              </Grid>
              <Grid>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      label="Name"
                      name="schoolName"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      label="Location"
                      name="schoolLocation"
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="schoolEducationDistrict"
                      label="Educational District (only number)"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        value={methods.watch().schoolDetailDate}
                        onChange={(value) => {
                          methods.setValue("schoolDetailDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="schoolDetailDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        name="schoolDetailDate"
                        label="Date"
                        openTo="year"
                        views={["year", "month", "day"]}
                        inputFormat="dd/MM/yyyy"
                        value={schooldetailTime}
                        onChange={(newValue) => {
                          setSchoolDetailTime(newValue);
                        }}
                        renderInput={(params) => (
                          <RHFTextField variant="filled" {...params} />
                        )}
                      />
                    </LocalizationProvider> */}
                  </Grid>
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    background: "#097969",
                    borderRadius: 0.5,
                    boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                    color: "#ffff",
                    minHeight: 40,
                  }}
                >
                  <Typography variant="h6" padding={2}>
                    Contact Information
                  </Typography>
                </Box>
              </Grid>
              <Grid display={"flex"} justifyContent={"space-around"}>
                <Box
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  <Grid>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="contactEmail"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="contactPhoneNumber"
                      label="Phone Number"
                      // type="Number"

                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Grid
                  mt={2}
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    name="contactLGA"
                    label="LGA"
                    variant="filled"
                  />
                </Grid>
              </Grid>

              <Grid>
                <Box
                  sx={{
                    background: "#097969",
                    borderRadius: 0.5,
                    boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                    color: "#ffff",
                    minHeight: 40,
                  }}
                >
                  <Typography variant="h6" padding={2}>
                    MAIN CONTACT PERSON’S INFO
                  </Typography>
                </Box>
              </Grid>
              <Grid>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Box
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="mainContactName"
                      label="Name"
                      variant="filled"
                    />
                  </Box>
                  <Box
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="mainContactRole"
                      label="Position in school"
                      variant="filled"
                    />
                  </Box>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="mainContactEmail"
                      label="Email"
                      type="email"
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="mainContactNumOfYearsInRole"
                      label="Year in role"
                      variant="filled"
                    />
                  </Grid>
                  <Box
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="mainContactPhoneNumber"
                      label="Phone Number"
                      variant="filled"
                    />
                  </Box>
                  <Box
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="mainContactAltPhoneNumber"
                      label=" Alternative Phone Number"
                      variant="filled"
                    />
                  </Box>
                </Box>

                <Grid display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      label="Position in school"
                      name="mainPosition"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      multiline={true}
                      minRows={4}
                      variant="filled"
                      name="facilities"
                      label="Facilities in school premises"
                      placeholder="library,indoor sport area ....."
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfMaleStudents"
                      label="No. of male student"
                      variant="filled"
                    />
                  </Grid>
                </Grid>

                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfFemaleStudents"
                      label="No. of female Student"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="totalNumOfStudents"
                      label="Total student population"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfMaleStaff"
                      label="No. of male staff"
                      variant="filled"
                    />
                  </Grid>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfFemaleStaff"
                      label="No. of female Staff"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="totalNumOfStaff"
                      label="Total staff population"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      minWidth: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfMaleTeachingStaff"
                      label="No. of male teaching staff"
                      variant="filled"
                    />
                  </Grid>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfFemaleTeachingStaff"
                      label="No. of female teaching staff"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      name="totalNumOfTeachingStaff"
                      label="Total population of teaching staff"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfMaleNonTeachingStaff"
                      label="No. of male non-teaching staff"
                      variant="filled"
                    />
                  </Grid>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      name="numOfFemaleNonTeachingStaff"
                      label="No. of female non-teaching staff"
                      variant="filled"
                    />
                  </Grid>
                  <Grid
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      name="totalNumOfNonTeachingStaff"
                      label="Total population of non-teaching staff"
                      variant="filled"
                    />
                  </Grid>
                  <Box
                    sx={{
                      marginBlock: 2,
                      width: 280,
                    }}
                  >
                    <RHFTextField
                      select
                      name="schoolType"
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {SCHOOLTYPE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-around"}>
                  <Box
                    sx={{
                      marginBlock: 2,
                      width: 400,
                    }}
                  >
                    <RHFTextField
                      select
                      name="schoolComposition"
                      label="School Composition (state whether , ‘A’ Levels,Tutorial, Early years, Pry or Secondary)"
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {SCHOOLCOMPOSITION.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Box>
                  <Grid>
                    <Box>
                      <FormControl
                        variant="filled"
                        sx={{
                          marginBlock: 2,
                          width: 400,
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          school circulums
                        </InputLabel>
                        <Select
                          name="schoolCurriculum"
                          multiple
                          value={schoolcirculums}
                          onChange={handleChange}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, schoolcirculums, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    background: "#097969",
                    borderRadius: 0.5,
                    boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                    color: "#ffff",
                    minHeight: 40,
                  }}
                >
                  <Typography variant="h6" padding={2}>
                    SSLAG Compliance Information
                  </Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      marginBlock: 2,
                    }}
                  >
                    <RHFTextField
                      select
                      name="hasSchoolInitiatedComplianceJourney"
                      variant="filled"
                      label="Has the SSLAG compliance journey being initiated"
                      sx={{
                        width: 300,
                      }}
                      SelectProps={{ native: true }}
                    >
                      {COMPLIANCE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>

                    {complianceStage === "1" ? (
                      <Grid
                        sx={{
                          marginBlock: 2,
                          minWidth: 280,
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            disablePast
                            value={methods.watch().complianceInitiationDate}
                            onChange={(value) => {
                              methods.setValue(
                                "complianceInitiationDate",
                                value,
                                {
                                  shouldValidate: true,
                                }
                              );
                            }}
                            renderInput={(params) => (
                              <RHFTextField
                                variant="filled"
                                {...params}
                                name="complianceInitiationDate"
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            name="complianceInitiationDate"
                            disableFuture
                            label="state the initiation date"
                            openTo="year"
                            views={["year", "month", "day"]}
                            inputFormat="dd/MM/yyyy"
                            value={initiationComplianceDate}
                            onChange={(newValue) => {
                              setInitiationComplianceDate(newValue);
                            }}
                            renderInput={(params) => (
                              <RHFTextField variant="filled" {...params} />
                            )}
                          />
                        </LocalizationProvider> */}
                      </Grid>
                    ) : null}

                    {complianceStage === "2" ? (
                      <Grid
                        sx={{
                          marginBlock: 2,
                          minWidth: 280,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="aandELead"
                          variant="filled"
                          label="Details of SSLAG A&E Team Lead"
                        />
                      </Grid>
                    ) : null}
                    {complianceStage === "3" ? (
                      <Grid
                        sx={{
                          marginBlock: 2,
                          minWidth: 280,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="ministryOfEducationReference"
                          label="Ministry of Education/ OEQA reference no:"
                          variant="filled"
                        />
                      </Grid>
                    ) : null}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#097969",
                    borderRadius: 0.5,
                    boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                    color: "#ffff",
                    minHeight: 40,
                  }}
                >
                  <Typography variant="h6" padding={2}>
                    Declaration
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <RHFCheckbox
                      name="checkbox"
                      label={`I confirm that Safe Schools Lagos Implementation Team
                      members have visited ${dataStatus?.schoolName} and the Compliance
                      journey has been initiated; I am the person in charge of
                      the school and as such is authorised to complete this
                      application.`}
                      name="declarationAcceptance"
                      sx={{ color: "black" }}
                    />
                  </Box>
                  <Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          minWidth: 400,
                        }}
                      >
                        <RHFTextField
                          // name="declarationAcceptance"
                          name="declarationFullname"
                          label="Full Name"
                          variant="filled"
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          minWidth: 400,
                        }}
                      >
                        <RHFTextField
                          name="declarationPosition"
                          label="Position"
                          variant="filled"
                        />
                      </Grid>
                      <Grid marginBlock={2} minWidth={280}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableFuture
                            name="schoolDetailDate"
                            label="Date"
                            openTo="year"
                            views={["year", "month", "day"]}
                            inputFormat="dd/MM/yyyy"
                            value={schooldeclarationTime}
                            onChange={(newValue) => {
                              setSchoolDeclarationTime(newValue);
                            }}
                            renderInput={(params) => (
                              <RHFTextField variant="filled" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sx={{ textAlign: "right", p: 2 }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  // endIcon={<Send />}
                  variant="contained"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Container>
          </Grid>
        </FormProvider>
      </Grid>
    </Fragment>
  );
}

export default SchoolProvisionalSafety;
