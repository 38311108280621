import React from "react";
import { FormControl } from "@mui/material";
import { useState } from "react";
import { TextField, Button, Box, FormHelperText } from "@mui/material";
import Axios from "axios";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

function InsuranceForm({ addBroker }) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    hmoName: "",
    insuranceType: "",
    policyNumber: "",
    insuranceDetails: "",
    imageUrl: "",
    imageId: "",
  });

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      state.hmoName.trim() === "" ||
      state.insuranceType.trim() === "" ||
      state.policyNumber.trim() === "" ||
      state.insuranceDetails.trim() === ""
    ) {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } else {
      addBroker(state);
      setState({
        hmoName: "",
        insuranceType: "",
        policyNumber: "",
        insuranceDetails: "",
        imageUrl: "",
        imageId: "",
      });
    }
  };

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("file", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;

          setState({
            ...state,
            imageId: imageId,
            imageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };
  return (
    <Box>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            label="Provider's Name"
            variant="filled"
            value={state.hmoName}
            onChange={(e) => handleChange(e, "hmoName")}
          />
          <TextField
            label="Type of Insurance"
            variant="filled"
            sx={{
              ml: 2,
            }}
            value={state.insuranceType}
            onChange={(e) => handleChange(e, "insuranceType")}
          />
          <TextField
            label="Policy No"
            variant="filled"
            // type="number"
            sx={{
              ml: 2,
            }}
            value={state.policyNumber}
            onChange={(e) => handleChange(e, "policyNumber")}
          />
          <TextField
            label=" Detail of Insurance"
            variant="filled"
            sx={{
              ml: 2,
            }}
            value={state.insuranceDetails}
            onChange={(e) => handleChange(e, "insuranceDetails")}
          />
        </Box>
        <FormHelperText>
          Upload insurance policy certificate below (5MB maximum size)
        </FormHelperText>
        <div
          style={{
            border: "1px solid grey",
            width: "500",
            marginTop: "10px",
            padding: "13px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <input
              type="file"
              onChange={(event) => {
                setImageSelected(event.target.files[0]);
              }}
            />
            <button
              onClick={async () => await uploadImage()}
              disabled={imageUploading || state.imageUrl.length > 0}
            >
              Upload Policy Docs.
            </button>
          </div>
        </div>
        <Box mt={2}>
          <Button variant="contained" onClick={handleSubmit}>
            ADD
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
}

export default InsuranceForm;
