import {
  Grid,
  Alert,
  Stack,
  FormHelperText,
  Typography,
  Box,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import React, { useState, useEffect, Fragment } from "react";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

function Assessment() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    userData: { schoolId },
  } = useAuth();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const AssessmentSchema = Yup.object().shape({
    schoolAssessmentNumber: Yup.string().required(
      "school Assessment Number is required"
    ),
    locations: Yup.string().required("location is required"),
    typeOfIncident: Yup.string().required("type of Incident is required"),
    namesOfPeopleInvolved: Yup.string().required("please fill "),
    briefDescription: Yup.string().required("please fill"),
    witnesses: Yup.string().required("please fill"),
    actionTakenFollowingTheIncident: Yup.string().required("please fill"),
    anyOtherInformation: Yup.string().required("please fill"),
    riskAssessment: Yup.string().required("please fill"),
  });

  const defaultValues = {
    schoolAssessmentNumber: "",
    dateOfIncident: "",
    locations: "",
    timeOfIncident: "",
    typeOfIncident: "",
    namesOfPeopleInvolved: "",
    briefDescription: "",
    witnesses: "",
    actionTakenFollowingTheIncident: "",
    anyOtherInformation: "",
    riskAssessment: "",
    dateOfRiskAssessment: "",
    reasonForRiskAssessment: "",
    doesProvisionHaveHealthAndSafetyPolicy: "",
    isStaffManagementTrainingUpToDate: "",
    dateOfStaffManagementTraining: "",
    reasonForStaffManagementTraining: "",
    doesSchoolHaveAllComplianceManual: "",
    haveYouConductedASelfAssessmentReviewRecently: "",
    dateOfSelfReviewAssessment: "",
    reasonForSelfReviewAssessment: "",
    appropraiteSafeyInsurancePolicyNumber: "",
    periodOfInsurance: "",
    anyOtherSafetyMeasuresPutInPlace: "",
    descriptionOfOtherSafetyMeasures: "",
  };

  const methods = useForm({
    resolver: yupResolver(AssessmentSchema),
    defaultValues,
  });

  const SELECT = [
    {
      value: "",
      label: "",
    },
    {
      value: "No",
      label: "No",
    },
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "Not Applicable",
      label: "Not Applicable",
    },
  ];
  const METHOD = [
    {
      value: "",
      label: "",
    },
    {
      value: "True",
      label: "True",
    },
    {
      value: "False",
      label: "False",
    },
  ];

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const watchRisk = watch("riskAssessment");

  const watchTraining = watch("isStaffManagementTrainingUpToDate");
  const watchReview = watch("haveYouConductedASelfAssessmentReviewRecently");
  const watchSafetyMeasure = watch("anyOtherSafetyMeasuresPutInPlace");



  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `SchoolChecklist/SubmitChecklistR001SchoolIncidentAndAccident/${schoolId}`,
        {
          schoolId,
          schoolAssessmentNumber: data.schoolAssessmentNumber,
          dateOfIncident: new Date(data.dateOfIncident).toISOString,
          locations: data.locations,
          timeOfIncident: data.timeOfIncident,
          typeOfIncident: data.typeOfIncident,
          namesOfPeopleInvolved: data.namesOfPeopleInvolved,
          briefDescription: data.briefDescription,
          witnesses: data.witnesses,
          actionTakenFollowingTheIncident: data.actionTakenFollowingTheIncident,
          anyOtherInformation: data.anyOtherInformation,
          riskAssessment: data.riskAssessment,
          dateOfRiskAssessment: new Date(data.dateOfRiskAssessment).toISOString,
          reasonForRiskAssessment: data.reasonForRiskAssessment,
          doesProvisionHaveHealthAndSafetyPolicy:
            data.doesProvisionHaveHealthAndSafetyPolicy,
          isStaffManagementTrainingUpToDate:
            data.isStaffManagementTrainingUpToDate,
          dateOfStaffManagementTraining: new Date(
            data.dateOfStaffManagementTraining
          ).toISOString,
          reasonForStaffManagementTraining:
            data.reasonForStaffManagementTraining,
          doesSchoolHaveAllComplianceManual:
            data.doesSchoolHaveAllComplianceManual,
          haveYouConductedASelfAssessmentReviewRecently:
            data.haveYouConductedASelfAssessmentReviewRecently,
          dateOfSelfReviewAssessment: new Date(data.dateOfSelfReviewAssessment)
            .toISOString,
          reasonForSelfReviewAssessment: data.reasonForSelfReviewAssessment,
          appropraiteSafeyInsurancePolicyNumber:
            data.appropraiteSafeyInsurancePolicyNumber,
          periodOfInsurance: data.periodOfInsurance,
          anyOtherSafetyMeasuresPutInPlace:
            data.anyOtherSafetyMeasuresPutInPlace,
          descriptionOfOtherSafetyMeasures:
            data.descriptionOfOtherSafetyMeasures,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        // display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid>
        <Grid justifyContent={"center"}>
          {/* <Typography color={"#6495ed"}>
            INCIDENT/ACCIDENT REPORT FORMS
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "100%",
              padding: 2,
            }}
          >
            <Typography variant="h4"> Checklist</Typography>

            <Box paddingRight={5}>
              <Button
                sx={{ color: "#6495ed" }}
                href="/dashboard/user/checklist"
                className="header "
              >
                CJ-SM201
              </Button>

              <Button
                href="/dashboard/user/outsourceService"
                className="header"
                sx={{ color: "#6495ed" }}
              >
                CJ-SM202
              </Button>
              <Button
                href="/dashboard/user/riskAssessment"
                className="header active"
                sx={{ color: "#6495ed" }}
              >
                CJ-R001A
              </Button>
              {/* <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-SM203
          </Button>

          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R002
          </Button>
          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R003
          </Button> */}
            </Box>
          </Box>
        </Grid>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Grid p={2}>
            <Box
              sx={{
                background: "#34a8e1",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" textAlign={"center"} padding={2}>
                INCIDENT/ACCIDENT REPORT FORMS
              </Typography>
            </Box>
          </Grid>

          <Box display={"flex"} justifyContent={"space-around"}>
            <Grid width={400}>
              {/* <FormHelperText>Name</FormHelperText> */}
              <RHFTextField
                id="filled-basic"
                name="schoolAssessmentNumber"
                label="School Assessment No"
                variant="filled"
              />
            </Grid>

            <Grid width={400}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Date of Incident "
                  disableFuture
                  value={methods.watch().dateOfIncident}
                  onChange={(value) => {
                    methods.setValue("dateOfIncident", value, {
                      shouldValidate: true,
                    });
                  }}
                  renderInput={(params) => (
                    <RHFTextField
                      variant="filled"
                      {...params}
                      name="dateOfIncident"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid width={400}>
              <RHFTextField
                name="locations"
                label="Location"
                variant="filled"
              />
            </Grid>
          </Box>

          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Grid width={400}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Time of the incident "
                  value={methods.watch().timeOfIncident}
                  onChange={(value) => {
                    methods.setValue("timeOfIncident", value, {
                      shouldValidate: true,
                    });
                  }}
                  renderInput={(params) => (
                    <RHFTextField
                      variant="filled"
                      {...params}
                      name="timeOfIncident"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid width={400}>
              {/* <FormHelperText>Name</FormHelperText> */}
              <RHFTextField
                id="filled-basic"
                name="typeOfIncident"
                label="Type of Incident"
                variant="filled"
              />
            </Grid>
            <Grid width={400}>
              <RHFTextField
                multiline={true}
                minRows={4}
                name="namesOfPeopleInvolved"
                label="Names of the people involved"
                placeholder="Name ....."
                variant="filled"
              />
            </Grid>
          </Box>

          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Grid width={400}>
              <RHFTextField
                multiline={true}
                minRows={4}
                name="briefDescription"
                label="Brief Description"
                placeholder="Description ....."
                variant="filled"
              />
            </Grid>
            <Grid width={400}>
              {/* <FormHelperText>Name</FormHelperText> */}
              <RHFTextField
                multiline={true}
                minRows={4}
                name="witnesses"
                label=" All witnesses must be listed here"
                placeholder="Description ....."
                variant="filled"
              />
            </Grid>
            <Grid width={400}>
              <RHFTextField
                multiline={true}
                minRows={4}
                name="actionTakenFollowingTheIncident"
                label="Description of all Actions Taken immediately following the
                  incident"
                placeholder="Name ....."
                variant="filled"
              />
            </Grid>
          </Box>

          <Box mt={2} display={"flex"} justifyContent={"center"}>
            <Grid width={600}>
              <RHFTextField
                multiline={true}
                minRows={5}
                name="anyOtherInformation"
                label="Any other information:"
                placeholder="Description ....."
                variant="filled"
              />
            </Grid>
          </Box>

          {/* <Grid display={"flex"} justifyContent={"space-around"}>
            <Grid>
              <Grid>
                <FormHelperText>School Assessment No:</FormHelperText>
                <RHFTextField
                  name="schoolAssessmentNumber"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormHelperText>Date of Incident:</FormHelperText>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    // label="Date of Birth "
                    disableFuture
                    value={methods.watch().dateOfIncident}
                    onChange={(value) => {
                      methods.setValue("dateOfIncident", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField {...params} name="dateOfIncident" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid>
                <FormHelperText>Location</FormHelperText>
                <RHFTextField name="locations" variant="outlined" />
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormHelperText>Time</FormHelperText>
                  <TimePicker
                    // label="Date of Birth "
                    value={methods.watch().timeOfIncident}
                    onChange={(value) => {
                      methods.setValue("timeOfIncident", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField {...params} name="timeOfIncident" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid>
                <FormHelperText>Type of Incident</FormHelperText>
                <RHFTextField name="typeOfIncident" variant="outlined" />
              </Grid>
              <Grid>
                <FormHelperText>Names of the people involved:</FormHelperText>
                <RHFTextField name="namesOfPeopleInvolved" variant="outlined" />
              </Grid>
              <Grid>
                <FormHelperText>Brief Description:</FormHelperText>
                <Grid>
                  <RHFTextField
                    multiline={true}
                    minRows={3}
                    name="briefDescription"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid padding={3}>
              <Grid>
                <FormHelperText>
                  All witnesses must be listed here:
                </FormHelperText>
                <Grid>
                  <RHFTextField
                    multiline={true}
                    minRows={3}
                    width={300}
                    name="witnesses"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid>
                <FormHelperText>
                  Description of all Actions Taken immediately following the
                  incident:
                </FormHelperText>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="actionTakenFollowingTheIncident"
                  variant="outlined"
                />
              </Grid>
              <Grid>
                <FormHelperText>Any other information:</FormHelperText>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="anyOtherInformation"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid mt={3}>
            <Typography>Safety Measures please answer appropriately</Typography>
          </Grid> */}

          <Grid p={2}>
            <Box
              sx={{
                background: "#34a8e1",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" textAlign={"center"} padding={2}>
                SAFETY MEASURES PLEASE ANSWER APPROPRIATELY appropriately
              </Typography>
            </Box>
          </Grid>

          <Box display={"flex"} justifyContent={"space-around"}>
            <Grid width={400}>
              <RHFTextField
                select
                label="risk Assessment"
                variant="filled"
                name="riskAssessment"
                SelectProps={{ native: true }}
              >
                {SELECT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>

            <Grid width={400}>
              <RHFTextField
                select
                label="Does the provision have an Health and safety policy in
                      place in school?"
                variant="filled"
                name="doesProvisionHaveHealthAndSafetyPolicy"
                SelectProps={{ native: true }}
              >
                {SELECT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            <Grid width={400}>
              <RHFTextField
                select
                variant="filled"
                label="Staff school safety management training up to date?"
                name="isStaffManagementTrainingUpToDate"
                SelectProps={{ native: true }}
              >
                {SELECT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
          </Box>
          {watchRisk === "Yes" ? (
            <Box mt={2} ml={3}>
              <Grid width={1000}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* <FormHelperText>Date Of Risk Assessment :</FormHelperText> */}
                  <DatePicker
                    disableFuture
                    label="Date Of Risk Assessment"
                    inputFormat="dd/MM/yyyy"
                    // label="Date of Birth "
                    value={methods.watch().dateOfRiskAssessment}
                    onChange={(value) => {
                      methods.setValue("dateOfRiskAssessment", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        variant="filled"
                        {...params}
                        name="dateOfRiskAssessment"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Box>
          ) : null}

          {watchRisk === "No" ? (
            <Box justifyContent={"center"} display={"flex"}>
              <Grid width={1000} mt={2}>
                <RHFTextField
                  label="State Reason"
                  name="reasonForRiskAssessment"
                  variant="filled"
                />
              </Grid>
            </Box>
          ) : null}
          {watchTraining === "Yes" ? (
            <Box display={"flex"} justifyContent={"center"}>
              <Grid mt={2} width={1000}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {/* <FormHelperText>Date:</FormHelperText> */}

                  <DatePicker
                    label="Date"
                    inputFormat="dd/MM/yyyy"
                    // label="Date of Birth "
                    disableFuture
                    value={methods.watch().dateOfStaffManagementTraining}
                    onChange={(value) => {
                      methods.setValue("dateOfStaffManagementTraining", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        variant="filled"
                        {...params}
                        name="dateOfStaffManagementTraining"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Box>
          ) : null}

          {watchTraining === "No" ? (
            <Box display={"flex"} justifyContent={"center"}>
              <Grid mt={2} width={1000}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  name=" reasonForStaffManagementTraining"
                  label="state reason"
                  variant="filled"
                />
              </Grid>
            </Box>
          ) : null}

          <Box mt={2} display={"flex"} justifyContent={"space-around"}>
            <Grid width={400}>
              <RHFTextField
                select
                variant="filled"
                name="doesSchoolHaveAllComplianceManual"
                SelectProps={{ native: true }}
                label=" Does the school have all compliance manual?"
              >
                {SELECT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>

            <Grid width={400}>
              <RHFTextField
                select
                label=" Have you conducted a self assessment Review recently?"
                name="haveYouConductedASelfAssessmentReviewRecently"
                variant="filled"
                SelectProps={{ native: true }}
              >
                {SELECT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            <Grid width={400}>
              <RHFTextField
                name="appropraiteSafeyInsurancePolicyNumber"
                variant="filled"
                label=" Appropriate School Safety Insurance policy no:"
              />
            </Grid>
          </Box>

          {watchReview === "Yes" ? (
            <Box display={"flex"} justifyContent={"space-around"}>
              <Grid mt={2} width={1000}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormHelperText>Date:</FormHelperText>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    // label="Date of Birth "
                    disableFuture
                    value={methods.watch().dateOfSelfReviewAssessment}
                    onChange={(value) => {
                      methods.setValue("dateOfSelfReviewAssessment", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        variant="filled"
                        {...params}
                        name="dateOfSelfReviewAssessment"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Box>
          ) : null}

          {watchReview === "No" ? (
            <Box display={"flex"} justifyContent={"center"}>
              <Grid mt={2} width={1000}>
                <FormHelperText>State Reason :</FormHelperText>
                <RHFTextField
                  name="  reasonForSelfReviewAssessment"
                  variant="filled"
                />
              </Grid>
            </Box>
          ) : null}

          <Box mt={2} display={"flex"} justifyContent={"space-around"}>
            <Grid width={400}>
              <RHFTextField
                label="Period of Insurance:"
                id="filled-basic"
                name=" periodOfInsurance"
                variant="filled"
              />
            </Grid>

            <Grid width={400}>
              <RHFTextField
                select
                name="anyOtherSafetyMeasuresPutInPlace"
                variant="filled"
                SelectProps={{ native: true }}
              >
                {METHOD.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
          </Box>

          {watchSafetyMeasure === "True" ? (
            <Box display={"flex"} justifyContent={"center"}>
              <Grid width={1000} mt={2}>
                <RHFTextField
                  label="Please describe:"
                  name=" descriptionOfOtherSafetyMeasures"
                  variant="filled"
                />
              </Grid>
            </Box>
          ) : null}
          <Grid item sx={{ textAlign: "right", mt: 3 }}>
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
          </Grid>

        </FormProvider>
      </Grid>
    </Fragment>
  );
}

export default Assessment;
