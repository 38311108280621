import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function AdminJourneyProvisionSafety() {
  const [schooldeclarationTime, setSchoolDeclarationTime] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [provisionSafety, setProvisionSafety] = useState(null);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/SchoolIntervention/GetProvisionalSafetyLicenseInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setProvisionSafety(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  const names = ["Nigerian", "British", "American", "French"];

  const theme = useTheme();
  const [schoolcirculums, setSchoolCirculums] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        schoolcirculums.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSchoolCirculums(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const defaultValues = {
    initiationDate: "",
    location: "",
    educationDistrict: "",
    schooldetailTime: "",
    email: "",
    PhoneNumber: "",
    LocalGovDistrict: "",
    compliance: "",
    detailOfSslag: "",
    ministryOfEducationRef: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const onSubmit = () => {};
  // const SCHOOLTYPE = [
  //   {
  //     value: "0",
  //     label: "Select school type",
  //   },
  //   {
  //     value: "1",
  //     label: "Day",
  //   },
  //   {
  //     value: "2",
  //     label: "Boarding",
  //   },
  //   {
  //     value: "3",
  //     label: "Boarding and Day",
  //   },
  // ];

  // const COMPLIANCE = [
  //   {
  //     value: "0",
  //     label: "Select compliance journey ",
  //   },
  //   {
  //     value: "1",
  //     label: "Yes",
  //   },
  //   {
  //     value: "2",
  //     label: "No",
  //   },
  //   {
  //     value: "3",
  //     label: "Don't know",
  //   },
  // ];

  // const SCHOOLCOMPOSITION = [
  //   {
  //     value: "0",
  //     label: "Select whether school is ",
  //   },
  //   {
  //     value: "1",
  //     label: "A-Levels",
  //   },
  //   {
  //     value: "2",
  //     label: "Tutorial",
  //   },
  //   {
  //     value: "3",
  //     label: "pry / secondary",
  //   },
  // ];
  const complianceStage = watch("hasSchoolInitiatedComplianceJourney");
  if (provisionSafety === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  // if (provisionSafety === null) {
  //   return <Typography>No Record Found....</Typography>;
  // }
  if (provisionSafety === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found ...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
          }}
        >
          <Grid>
            <Box textAlign={"center"}>
              <Box>
                <Grid>
                  <img
                    src={logoBg}
                    alt=""
                    style={{
                      width: 400,
                    }}
                  />
                </Grid>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "#097969",
                  mt: 2,
                }}
              >
                Provisional Safety License Application
              </Typography>
            </Box>
          </Grid>

          <Container
            sx={{
              background: "#ffff",
              borderRadius: 0.5,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 800,
            }}
          >
            <Grid>
              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  SCHOOL DETAILS
                </Typography>
              </Box>
            </Grid>
            <Grid>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    label="Name"
                    // name="schoolName"
                    value={provisionSafety.schoolName}
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    label="Location"
                    value={provisionSafety.schoolLocation}
                    // name="schoolLocation"
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="schoolEducationDistrict"
                    value={provisionSafety.schoolEducationDistrict}
                    label="Educational District"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      disablePast
                      value={provisionSafety.schoolDetailDate}
                      // onChange={(value) => {
                      //   methods.setValue("schoolDetailDate", value, {
                      //     shouldValidate: true,
                      //   });
                      // }}
                      renderInput={(params) => (
                        <RHFTextField
                          variant="filled"
                          {...params}
                          name="schoolDetailDate"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Contact Information
                </Typography>
              </Box>
            </Grid>
            <Grid display={"flex"} justifyContent={"space-around"}>
              <Box
                sx={{
                  marginBlock: 2,
                  width: 400,
                }}
              >
                <Grid>
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="contactEmail"
                    value={provisionSafety.contactEmail}
                    label="Email"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="contactPhoneNumber"
                    value={provisionSafety.contactPhoneNumber}
                    label="Phone Number"
                    // type="Number"

                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid
                mt={2}
                sx={{
                  marginBlock: 2,
                  width: 400,
                }}
              >
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  // name="contactLGA"
                  value={provisionSafety.contactLGA}
                  label="LGA"
                  variant="filled"
                />
              </Grid>
            </Grid>

            <Grid>
              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  MAIN CONTACT PERSON’S INFO
                </Typography>
              </Box>
            </Grid>
            <Grid>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Box
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="mainContactName"
                    value={provisionSafety.mainContactName}
                    label="Name"
                    variant="filled"
                  />
                </Box>
                <Box
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="mainContactRole"
                    value={provisionSafety.mainContactRole}
                    label="Position in school"
                    variant="filled"
                  />
                </Box>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="mainContactEmail"
                    value={provisionSafety.mainContactEmail}
                    label="Email"
                    type="email"
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="mainContactNumOfYearsInRole"
                    value={provisionSafety.mainCOntactNumOfYearInRole}
                    label="Year in role"
                    variant="filled"
                  />
                </Grid>
                <Box
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="mainContactPhoneNumber"
                    value={provisionSafety.mainContactPhoneNumber}
                    label="Phone Number"
                    variant="filled"
                  />
                </Box>
                <Box
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  {/* <FormHelperText>Name</FormHelperText> */}
                  <RHFTextField
                    // name="mainContactAltPhoneNumber"
                    value={provisionSafety.mainContactAltPhoneNumber}
                    label=" Alternative Phone Number"
                    variant="filled"
                  />
                </Box>
              </Box>

              <Grid display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    label="Position in school"
                    // name="mainPosition"
                    value={provisionSafety.mainPosition}
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    multiline={true}
                    minRows={4}
                    variant="filled"
                    // name="facilities"
                    value={provisionSafety.facilities}
                    label="Facilities in school premises"
                    placeholder="library,indoor sport area ....."
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfMaleStudents"
                    value={provisionSafety.numOfMaleStudent}
                    label="No. of male student"
                    variant="filled"
                  />
                </Grid>
              </Grid>

              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfFemaleStudents"
                    value={provisionSafety.numOfFemaleStudent}
                    label="No. of female Student"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="totalNumOfStudents"
                    value={provisionSafety.totalNumberOfStudent}
                    label="Total student population"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfMaleStaff"
                    value={provisionSafety.numOfMaleStaff}
                    label="No. of male staff"
                    variant="filled"
                  />
                </Grid>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfFemaleStaff"
                    value={provisionSafety.numOfFemaleStaff}
                    label="No. of female Staff"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="totalNumOfStaff"
                    value={provisionSafety.totalNumOfStaff}
                    label="Total staff population"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    minWidth: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfMaleTeachingStaff"
                    value={provisionSafety.numOfMaleTeachingStaff}
                    label="No. of male teaching staff"
                    variant="filled"
                  />
                </Grid>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfFemaleTeachingStaff"
                    value={provisionSafety.numOfFemaleTeachingStaff}
                    label="No. of female teaching staff"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    // name="totalNumOfTeachingStaff"
                    value={provisionSafety.totalNumOfTeachingStaff}
                    label="Total population of teaching staff"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfMaleNonTeachingStaff"
                    value={provisionSafety.numOfMaleNonTeachingStaff}
                    label="No. of male non-teaching staff"
                    variant="filled"
                  />
                </Grid>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    // name="numOfFemaleNonTeachingStaff"
                    value={provisionSafety.numOfFemaleNonTeachingStaff}
                    label="No. of female non-teaching staff"
                    variant="filled"
                  />
                </Grid>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    // name="totalNumOfNonTeachingStaff"
                    value={provisionSafety.totalNumOfNonTeachingStaff}
                    label="Toal population of non-teaching staff"
                    variant="filled"
                  />
                </Grid>
                <Box
                  sx={{
                    marginBlock: 2,
                    width: 280,
                  }}
                >
                  <RHFTextField
                    select
                    name="schoolType"
                    variant="filled"
                    SelectProps={{ native: true }}
                    value={provisionSafety.schoolType}
                  >
                    {/* {SCHOOLTYPE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))} */}
                  </RHFTextField>
                </Box>
              </Box>

              <Box display={"flex"} justifyContent={"space-around"}>
                <Box
                  sx={{
                    marginBlock: 2,
                    width: 400,
                  }}
                >
                  <RHFTextField
                    select
                    name="schoolComposition"
                    label="School Composition (state whether , ‘A’ Levels,Tutorial, Early years, Pry or Secondary)"
                    variant="filled"
                    // SelectProps={{ native: true }}
                    value={provisionSafety.schoolComposition}
                  >
                    {/* {SCHOOLCOMPOSITION.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))} */}
                  </RHFTextField>
                </Box>
                <Grid>
                  <Box>
                    <FormControl
                      variant="filled"
                      sx={{
                        marginBlock: 2,
                        width: 400,
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        school circulums
                      </InputLabel>
                      <Select
                        name="schoolCurriculum"
                        multiple
                        value={provisionSafety.schoolCurriculum}
                        // onChange={handleChange}
                        // input={<OutlinedInput label="Name" />}
                        // MenuProps={MenuProps}
                      >
                        {/* {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, schoolcirculums, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))} */}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Box>

              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  SSLAG Compliance Information
                </Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBlock: 2,
                  }}
                >
                  <RHFTextField
                    select
                    // name="hasSchoolInitiatedComplianceJourney"
                    value={provisionSafety.hasSchoolInitiatedComplianceJourney}
                    variant="filled"
                    label="Has the SSLAG compliance journey being initiated"
                    sx={{
                      width: 300,
                    }}
                    SelectProps={{ native: true }}
                  >
                    {/* {COMPLIANCE.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))} */}
                  </RHFTextField>

                  {complianceStage === "1" ? (
                    <Grid
                      sx={{
                        marginBlock: 2,
                        minWidth: 280,
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          disablePast
                          value={provisionSafety.complianceInitiationDate}
                          // onChange={(value) => {
                          //   methods.setValue(
                          //     "complianceInitiationDate",
                          //     value,
                          //     {
                          //       shouldValidate: true,
                          //     }
                          //   );
                          // }}
                          renderInput={(params) => (
                            <RHFTextField
                              variant="filled"
                              {...params}
                              name="complianceInitiationDate"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            name="complianceInitiationDate"
                            disableFuture
                            label="state the initiation date"
                            openTo="year"
                            views={["year", "month", "day"]}
                            inputFormat="dd/MM/yyyy"
                            value={initiationComplianceDate}
                            onChange={(newValue) => {
                              setInitiationComplianceDate(newValue);
                            }}
                            renderInput={(params) => (
                              <RHFTextField variant="filled" {...params} />
                            )}
                          />
                        </LocalizationProvider> */}
                    </Grid>
                  ) : null}

                  {complianceStage === "2" ? (
                    <Grid
                      sx={{
                        marginBlock: 2,
                        minWidth: 280,
                      }}
                    >
                      {/* <FormHelperText>Name</FormHelperText> */}
                      <RHFTextField
                        name="aandELead"
                        value={provisionSafety.aandELead}
                        variant="filled"
                        label="Details of SSLAG A&E Team Lead"
                      />
                    </Grid>
                  ) : null}
                  {complianceStage === "3" ? (
                    <Grid
                      sx={{
                        marginBlock: 2,
                        minWidth: 280,
                      }}
                    >
                      {/* <FormHelperText>Name</FormHelperText> */}
                      <RHFTextField
                        // name="ministryOfEducationReference"
                        value={provisionSafety.ministryOfEducationReference}
                        label="Ministry of Education/ OEQA reference no:"
                        variant="filled"
                      />
                    </Grid>
                  ) : null}
                </Box>
              </Box>

              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Declaration
                </Typography>
              </Box>
              <Box>
                <Box>
                  <Typography>
                    I confirm that Safe Schools Lagos Implementation Team
                    members have visited <b>School Name</b> and the Compliance
                    journey has been initiated; I am the person in charge of the
                    school and as such is authorised to complete this
                    application.
                  </Typography>
                </Box>
                <Box>
                  <Box display={"flex"} justifyContent={"space-around"}>
                    <Grid
                      sx={{
                        marginBlock: 2,
                        minWidth: 400,
                      }}
                    >
                      <RHFTextField
                        name="declarationAcceptance"
                        value={provisionSafety.declarationAcceptance}
                        label="Full Name"
                        variant="filled"
                      />
                    </Grid>
                    <Grid
                      sx={{
                        marginBlock: 2,
                        minWidth: 400,
                      }}
                    >
                      <RHFTextField
                        // name="declarationPosition"
                        value={provisionSafety.declaration}
                        label="Position"
                        variant="filled"
                      />
                    </Grid>
                    <Grid marginBlock={2} minWidth={280}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          name="schoolDetailDate"
                          label="Date"
                          openTo="year"
                          views={["year", "month", "day"]}
                          inputFormat="dd/MM/yyyy"
                          value={provisionSafety.schooldeclarationTime}
                          // onChange={(newValue) => {
                          //   setSchoolDeclarationTime(newValue);
                          // }}
                          // renderInput={(params) => (
                          //   <RHFTextField variant="filled" {...params} />
                          // )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminJourneyProvisionSafety;
