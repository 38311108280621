import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
  Button,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

function ComplianceCriterion1({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    staffCBCAttendance: "",
    commentOnStaffCBCAttendance: "",
    complianceItems: "",
    commentOnComplianceItems: "",
    safetyManagementSystem: "",
    commentOnSafetyManagementSystem: "",
    safetyComplianceInsurance: "",
    commentOnSafetyComplianceInsurance: "",
    policyForPromotingPupilsHealth: "",
    commentOnPolicyForPromotingPupilsHealth: "",
    parentalPartenership: "",
    commentOnParentalPartenership: "",
    periodicSelfAssessment: "",
    commentOnPeriodicSelfAssessment: "",
    safetyTraining: "",
    commentOnSafetyTraining: "",
    safePhysicalInfrastructure: "",
    commentSafePhysicalInfrastructure: "",
    registrationOnSslagPortal: "",
    commentOnRegistrationOnSslagPortal: "",
    safeTransitPolicy: "",
    commentOnSafeTransitPolicy: "",
    safetyChampion: "",
    commentOnSafetyChampion: "",
    safetyStandardForHazardousActivities: "",
    commentOnSafetyStandardForHazardousActivities: "",
    riskAssesmentAcrossBoard: "",
    commentOnRiskAssesmentAcrossBoard: "",
    safeInteractionsInCommunity: "",
    commentOnSafeInteractionsInCommunity: "",
    behaviourManagement: "",
    commentOnBehaviourManagement: "",
    safeFoodStandards: "",
    commentOnSafeFoodStandards: "",
    specialistRoomPolicy: "",
    commentOnSpecialistRoomPolicy: "",
    clearOutingPolicy: "",
    commentOnClearOutingPolicy: "",
    clearTransitPolicy: "",
    commentOnClearTransitPolicy: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };

  const navigate = useNavigate();
  let { taskId } = useParams();

  const option = [
    { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionNineCcOne`,
        {
          // auditTaskId: taskId,
          schoolId,
          auditId,
          auditTaskId: taskId,
          staffCBCAttendance: dataToSubmit.staffCBCAttendance,
          commentOnStaffCBCAttendance: dataToSubmit.commentOnStaffCBCAttendance,
          complianceItems: dataToSubmit.complianceItems,
          commentOnComplianceItems: dataToSubmit.commentOnComplianceItems,
          safetyManagementSystem: dataToSubmit.safetyManagementSystem,
          commentOnSafetyManagementSystem:
            dataToSubmit.commentOnSafetyManagementSystem,
          safetyComplianceInsurance: dataToSubmit.safetyComplianceInsurance,
          commentOnSafetyComplianceInsurance:
            dataToSubmit.commentOnSafetyComplianceInsurance,
          policyForPromotingPupilsHealth:
            dataToSubmit.policyForPromotingPupilsHealth,
          commentOnPolicyForPromotingPupilsHealt:
            dataToSubmit.commentOnPolicyForPromotingPupilsHealt,
          parentalPartenership: dataToSubmit.parentalPartenership,
          commentOnParentalPartenership:
            dataToSubmit.commentOnParentalPartenership,
          periodicSelfAssessment: dataToSubmit.periodicSelfAssessment,
          commentOnPeriodicSelfAssessment:
            dataToSubmit.commentOnPeriodicSelfAssessment,
          safetyTraining: dataToSubmit.safetyTraining,
          commentOnSafetyTraining: dataToSubmit.commentOnSafetyTraining,
          safePhysicalInfrastructure: dataToSubmit.safePhysicalInfrastructure,
          commentSafePhysicalInfrastructure:
            dataToSubmit.commentSafePhysicalInfrastructure,
          registrationOnSslagPortal: dataToSubmit.registrationOnSslagPortal,
          commentOnRegistrationOnSslagPortal:
            dataToSubmit.commentOnRegistrationOnSslagPortal,
          safeTransitPolicy: dataToSubmit.safeTransitPolicy,
          commentOnSafeTransitPolicy: dataToSubmit.commentOnSafeTransitPolicy,
          safetyChampion: dataToSubmit.safetyChampion,
          commentOnSafetyChampion: dataToSubmit.commentOnSafetyChampion,
          safetyStandardForHazardousActivities:
            dataToSubmit.safetyStandardForHazardousActivities,
          commentOnSafetyStandardForHazardousActivities:
            dataToSubmit.commentOnSafetyStandardForHazardousActivities,
          riskAssesmentAcrossBoard: dataToSubmit.riskAssesmentAcrossBoard,
          commentOnRiskAssesmentAcrossBoard:
            dataToSubmit.commentOnRiskAssesmentAcrossBoard,
          safeInteractionsInCommunity: dataToSubmit.safeInteractionsInCommunity,
          commentOnSafeInteractionsInCommunity:
            dataToSubmit.commentOnSafeInteractionsInCommunity,
          behaviourManagement: dataToSubmit.behaviourManagement,
          commentOnBehaviourManagement:
            dataToSubmit.commentOnBehaviourManagement,
          safeFoodStandards: dataToSubmit.safeFoodStandards,
          commentOnSafeFoodStandards: dataToSubmit.commentOnSafeFoodStandards,
          specialistRoomPolicy: dataToSubmit.specialistRoomPolicy,
          commentOnSpecialistRoomPolicy:
            dataToSubmit.commentOnSpecialistRoomPolicy,
          clearOutingPolicy: dataToSubmit.clearOutingPolicy,
          commentOnClearOutingPolicy: dataToSubmit.commentOnClearOutingPolicy,
          clearTransitPolicy: dataToSubmit.clearTransitPolicy,
          commentOnClearTransitPolicy: dataToSubmit.commentOnClearTransitPolicy,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 9: CC1: COMPLIANCE CRITERION-1
                </Typography>
              </Box>

              <ol>
                <li>
                  <Box sx={{ marginBlock: 1 }}>
                    <Typography>
                      Evidence of CBC Attendance for 40% staff or above
                    </Typography>
                    <Box p={2}>
                      <Typography fontSize={"13px"}>
                        ( Below 40% U, 40%-S, 41% -60%-G, 66% -100%-E)
                      </Typography>
                    </Box>

                    <Box>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="staffCBCAttendance"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                </li>

                <Box>
                  <RHFTextField
                    name="commentOnStaffCBCAttendance"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <li>
                  <Box sx={{ marginBlock: 1 }}>
                    <Typography>
                      Full Compliance Items sighted on Premises
                    </Typography>
                    <Box p={2}>
                      <Typography fontSize={"13px"}>
                        (Minimum of 6 from the list below must be sighted to get
                        an S mark) SSLAG approved: CM, Poster, Handbook, Staff
                        CBC certificates, Accident record books, HVV, paddles;
                        First Aid Box, whistles, wet floor tripod, signages
                        (hand washing, running man, Lolli pop No smoking, Fire
                        assembly, In case of fire signs, etc 7-10items=G, 11
                        items and above =E
                      </Typography>
                    </Box>

                    <Box>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="complianceItems"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                </li>

                <Box>
                  <RHFTextField
                    name="commentOnComplianceItems"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of General Safety Policy or A Safety management
                      system in place
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (This must be signed by management) none U, General Policy
                      signed by management S, General policy with 8 Appropriate
                      policies oolslagosing on school safety-G, A full Safety
                      management System=E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyManagementSystem"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>

                <Box>
                  <RHFTextField
                    name="commentOnSafetyManagementSystem"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      School safety Compliance driven Insurance on display or a
                      cover note sighted
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      {" "}
                      (underwritten by the Approved Insurance Companies) lack of
                      this is U, for this category and Presence of it is mark
                      for this is either U or E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyComplianceInsurance"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyComplianceInsurance"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Appropriate Policies for Promoting Pupils Health & Safety
                      in place
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      ( Minimum of 6 must be sighted to get an S mark) Accident
                      policy, Incident policy, Infection control policy, Outing
                      policy, End & start of school day protocol, First Aid
                      Policy, Medication Policy, Fire drill Policy, Safeguarding
                      Policy, Confidential Policy, Equal opportunities Policy,
                      Staff working arrangement, Pupils' Registration forms:
                      Consent forms, visitors policy, Parental partnership
                      policy etc
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="policyForPromotingPupilsHealth"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnPolicyForPromotingPupilsHealth"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of parental partnership (at least from the list
                      provided by the School safety Administrator)
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (Minimum of 4 must be sighted to get an S mark, 5-7 is G
                      and above 7 is Excellent)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="parentalPartenership"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnParentalPartenership"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of Periodic Self assessments being carried out
                    </Typography>
                  </li>

                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (completed Self assessment checklists as contained in the
                      CM) (completed Self assessment checklists as contained in
                      the CM)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="periodicSelfAssessment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnPeriodicSelfAssessment"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of a programme of Safety training or budget
                      allocation for it
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Training Plan with dates, Receipt of Invoice paid, or
                      correspondence from the satey Administrator suggesting
                      training booked and CBC certificate for 40% staff S (any
                      of the scenarios listed=S; U+ One of the above scenarios
                      plus over 40% staff with CBC certificates of attendance G'
                      S+G+ 2 or more of the scenarios plus evidence of up to 65%
                      staff with CBC cerficates =E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyTraining"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyTraining"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence of Safe Physical Infrastructural management
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      appropriate exit points to the building & all exit points
                      free of clutter and unobstructed no trailing electrical
                      wires visible all electrical points covered and unexposed
                      electrical installations located high up and out of the
                      reach of children no uneven flooring on site; Corridors
                      and walkways must be free of potholes, debris and items
                      which may cause slips or trips all dilapidated buildings
                      or defective structures out of the reach of
                      students/pupils all defective toys, equipment checked and
                      removed from the reach of pupils presence of a perimeter
                      wall fencing electrical points covered; no exposed
                      electrical lightings or 2 pin plugs on appliances a locked
                      cupboard out of reach of children to satisfy COSHH schools
                      building well maintained safe method of window cleaning
                      Evidence to show that waste are disposed off safely and
                      responsibly (electrical waste, general waste, recyclable
                      materials and organic if applicable) Music Room must have
                      the approved sound insulation. less than 6 is U, 6 isS,
                      7-9-G; 10+ E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safePhysicalInfrastructure"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentSafePhysicalInfrastructure"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of registration with the SSLAG Digital portal for
                      uploading key safety compliance information to the LSC or
                      Evidence of initiating Safety certification process
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (S if either of the 2 items was sighted, G if both were
                      sighted and E if both are sighted and evidence of School
                      safety protocols uploaded or downloaded)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="registrationOnSslagPortal"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnRegistrationOnSslagPortal"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      {" "}
                      Evidence of Safe transit policy and staff Induction pack
                    </Typography>
                  </li>
                  <Box fontSize={"13px"} p={2}>
                    <Typography>
                      ( Transport system with all operating documents valid,
                      School Safety Transit workshop Certificate of attendance
                      shown-S ,1 plus Lagos state Drivers training certificate G
                      1+2+transit policies and procedures with the approved
                      School safety checklists = E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safeTransitPolicy"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafeTransitPolicy"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence of an appointed Safety Rep or champion
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Safety Rep or Champ in place-S; 1+ plus school safety
                      training certificate-G, 1+2+approved roles and
                      responsibilities/ policies for School safety/completed
                      checklists or SS protocols completed-E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyChampion"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyChampion"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      {" "}
                      Evidence of safety Infrastructures & Minimum standards of
                      dress/PPE for more hazardous activities and sports
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (Minimum of 6 must be sighted to get an S mark; 7-10 G;
                      over 10-E) first aid box with approved contents; approved
                      school safety signs appropriate fire extinguishers sited
                      in the appropriate locations lollipop paddles for pupils
                      crossings School Zone outdoor signage for warning
                      motorists School Crossing Brigades or personnel Uniformed
                      security guards: fire doors fitted evidence of adequate
                      ventilation (fans or air conditioners) evidence of natural
                      lighting: fire blanket in the kitchen smoke detectors in
                      the kitchen and laboratories high visibility vests for
                      pupils outings appropriate clothing and footwear for all
                      special sports and extra curricular activities more than
                      one hand washing facilities on site a manned sick bay;
                      triped signageused in wet areas
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyStandardForHazardousActivities"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyStandardForHazardousActivities"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of safety risk assessment being carried out
                      across board
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (Minimum of 6 must be shown to get Satisfactory mark, 7-10
                      is G and 10+ is Excellence)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="riskAssesmentAcrossBoard"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnRiskAssesmentAcrossBoard"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      Evidence of Safe interactions within the school community
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      (evidence of an effective anti bullying policy, evidence
                      of an effective staff grievance procedures, stuff
                      supervision and monitoring, complaints procedures etc). 3
                      must be seen for a Satisfactory mark, 4-6 for Good and
                      above 6 for Excellence mark)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safeInteractionsInCommunity"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafeInteractionsInCommunity"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    <Typography>
                      {" "}
                      Evidence of Behaviour management & class control
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      ( policy & Procedures available on site = S, 1 plus pupils
                      rewards and sanctions policy in existence =G, 1+2+ records
                      of rewards and sanctions seen =E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="behaviourManagement"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnBehaviourManagement"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography> Evidence of safe food standard</Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      ( Area for food food Preparation clean and free of pest
                      dropping, evidence of SSLAG food safety training
                      certificate attendance for 50% of kitchen staff,
                      Refrigerators for storage of Packed meals =U, 1 +
                      presenceof temperature controls protocols for both hot and
                      cold foods =G separate storage facilities for raw and
                      cooked meals =E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safeFoodStandards"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafeFoodStandards"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography> Specialist room policy</Typography>
                  </li>
                  <Box fontSize={"13px"} p={2}>
                    <Typography>
                      (A safety policy must be visibly displayed in all
                      specialist rooms and appropriate conducts expected of
                      students/pupils displayed-S Additional safety precautions
                      are used to ensure students/teachers safety = G evidence
                      of risk assessment for each activity)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="specialistRoomPolicy"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSpecialistRoomPolicy"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography> Evidence of a clear outing policy</Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      ( A signed policy available, policy include appropriate
                      consents-S, 1+ include detailed itinerary and emergency
                      contact details of parents-G, 1+2+a clear strategy for
                      identifying each pupil included in the policy = E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="clearOutingPolicy"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnClearOutingPolicy"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography> Evidence of clear transit policy</Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      ( a signed policy available, policy include consents and
                      parents contact details: a transit assistant is available;
                      vehicle with valid particulars = S 1+ include detailed
                      drivers checklist=G 1+2+a clear strategy for
                      vehicular maintenance = E)
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="clearTransitPolicy"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnClearTransitPolicy"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
              </ol>
              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={() => handleDirectionChange("forward")}
                    loading={isSubmitting}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default ComplianceCriterion1;
