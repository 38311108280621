import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
  Avatar,
  Stack,
  Alert,
  TextField,
  CardHeader,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import { deepOrange, deepPurple } from "@mui/material/colors";
import DatePicker from "@mui/lab/DatePicker";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import RegisterSchoolModal from "./RegisterSchoolModal";

function PreAudit() {
  const [pendingTask, setPendingTask] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [getAandEDetails, setGetAandEDetails] = useState("");
  const [taskIdToUpdate, setTaskIdToUpdate] = useState("");
  const [viewClerk, setViewClerk] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState("");

  const theme = useTheme();
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter By School Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const FilterData = () => ({
    // id: datatype.uuid(),
    // name: name.findName(),
    // email: internet.email(),
    // address: address.streetAddress(),
    // bio: lorem.sentence(),
    // image: image.avatar(),
  });

  const defaultValues = {
    auditDate: "",
  };

  const DateSchema = Yup.object().shape({
    auditDate: Yup.string().required("field is required "),
  });

  const methods = useForm({
    resolver: yupResolver(DateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = () => {};

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = pendingTask.filter(
    (item) =>
      item.SchoolName &&
      item.SchoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    let unmounted = false;
    const getAllPendingTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetAuditTasks?status=${2}&source=${1}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setPendingTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllPendingTask();
    return () => {
      unmounted = true;
    };
  }, []);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "School Name",
      selector: (row) => row.school?.schoolName,
    },
    // {
    //   name: "Date Assigned",
    //   selector: (row) => row.aandE?.dateAssiged.split("T")[0],
    // },
    {
      name: "Audit Date",
      // selector: (row) => row.dateOfAudit,
      cell: (row) =>
        row.dateOfAudit === null ? (
          <>
            <Button
              variant="contained"
              disabled={row.dateOfAudit}
              onClick={() => handleOpen(row.id)}
            >
              Add Date
            </Button>
          </>
        ) : (
          row.dateOfAudit.split("T")[0]
        ),
    },
    {
      name: "AE(URN)",
      cell: (row) =>
        row.aandE === null ? (
          <Button
            variant="contained"
            onClick={() => handlePageOpenAssign(row.id)}
          >
            Assign
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => handlePageOpen(row.aandE)}>
            {row.aandE?.urn}
          </Button>
        ),
    },

    {
      name: "Clerks (URN)",
      cell: (row) =>
        row.auditClerks === null || row.auditClerks.length === 0 ? (
          <Typography></Typography>
        ) : (
          <Button
            variant="outlined"
            onClick={() => handlePageClerk(row.id, row.auditClerks)}
          >
            {/* {row.auditClerk?.urn} */}
            View
          </Button>
        ),
      // selector: (row) => row.auditClerk?.urn,
    },
    {
      name: "Payment Status",
      cell: (row) => (
        // <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
        //   {sentenceCase(row.invoice.paymentStatus ? "paid" : "not paid")}
        // </Label>
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            row.invoice.paymentStatus ? "success" : "error"
            // (!row.invoice.paymentStatus && "error")
          }
        >
          {row.invoice.paymentStatus
            ? sentenceCase("Paid")
            : sentenceCase("Not Paid")}
        </Label>
      ),
    },

    // {
    //   name: "Payment Status",
    //   cell: (row) => (
    //     <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
    //       {sentenceCase(row.invoice.paymentStatus ? "paid" : "not paid")}
    //     </Label>
    //   ),
    // },
    {
      name: "Validation Document",
      cell: (row) => (
        <>
          <Button
            variant="contained"
            disabled={row.isValidationDocumentSent || !row.auditClerk}
            onClick={() => isValidationDocument(row.id)}
          >
            {" "}
            Send
          </Button>
        </>
      ),
    },
  ];

  const updateAandEForTask = (taskId, aeinfo) => {
    const newTask = [...pendingTask];
    const taskIndex = newTask.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      newTask[taskIndex].aandE = {
        ...aeinfo,
      };
      setPendingTask(newTask);
    }
  };

  const updatedTask = (taskId) => {
    const newTask = [...pendingTask];
    const taskIndex = newTask.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      newTask[taskIndex].statusId = 5;
      newTask[taskIndex].status = "validation document Sent";
      newTask[taskIndex].isValidationDocumentSent = true;

      setPendingTask(newTask);
    }
  };

  const isValidationDocument = async (taskId) => {
    try {
      const response = await axoisCustomAgentForAdmin.put(
        `Audit/SendValidationDocuments/${taskId}`
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      updatedTask(taskId);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const AddAuditDate = async (data) => {
    let date = new Date(methods.watch("auditDate")).toISOString();
    try {
      const response = await axoisCustomAgentForAdmin.put(
        `Audit/AddAuditDate`,
        {
          taskId: taskIdToUpdate,
          date: date,
        }
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, {
        variant: "success",
      });

      // update tasks
      let tasksCopy = [...pendingTask];
      const taskIndex = tasksCopy.findIndex((x) => x.id == taskIdToUpdate);
      if (taskIndex !== -1) {
        tasksCopy[taskIndex].dateOfAudit = date;
        setPendingTask(tasksCopy);
      }

      handleClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = (taskId) => {
    setTaskIdToUpdate(taskId);
    setOpen(true);
  };
  const handleClose = () => {
    setTaskIdToUpdate("");
    methods.setValue("auditDate", "", {
      shouldValidate: true,
    });
    setOpen(false);
  };

  const [page, setPage] = React.useState(false);
  const [value, setValue] = React.useState();

  const handlePageOpen = (taskId) => {
    setGetAandEDetails(taskId);
    setPage(true);
  };

  const handlePageClose = () => {
    setGetAandEDetails("");
    setPage(false);
  };

  const [task, setTask] = useState([]);
  const [currentTaskId, setCurrentTaskId] = useState("");

  const DeleteTask = (taskId) => {
    const newTask = [...task];
    const updatedTask = newTask.filter((x) => x.id !== taskId);

    setTask(updatedTask);
  };

  const [pageAssign, setPageAssign] = React.useState(false);

  const handlePageOpenAssign = (taskId) => {
    setCurrentTaskId(taskId);
    setPageAssign(true);
  };

  const handlePageCloseAssign = () => {
    setCurrentTaskId("");
    setPageAssign(false);
  };

  const [openClerk, setOpenClerk] = React.useState(false);
  // const [value, setValue] = React.useState();

  const handlePageClerk = (taskId, clerks) => {
    setCurrentTaskId(taskId);
    setViewClerk(clerks);

    setOpenClerk(true);
  };

  const handlePageClerkClose = () => {
    setViewClerk([]);
    setCurrentTaskId("");
    setOpenClerk(false);
  };

  // console.log(educationalDistrict);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
            padding: 2,
          }}
        >
          <Typography variant="h4">Admin Dashboard</Typography>

          <Box paddingRight={5}>
            <Button
              sx={{ color: "#6495ed" }}
              href="pre-audit-protocol"
              className="header active "
            >
              Legacy School
            </Button>

            <Button
              sx={{ color: "#6495ed" }}
              href="OnboardedSchool"
              className="header "
            >
              Onboarded School
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            width: "80%",
            mt: 4,
          }}
        >
          <CardHeader title="Pre-Audit Protocol" />
          <Grid p={3}>
            {pendingTask.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={pendingTask}
                // filteredItems
                editModde="row"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>

        {/* Assessors Model */}
        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              Assessor Information
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <Card
                sx={{
                  width: "93%",
                  height: 220,
                  mt: 4,

                  alignContent: "center",
                }}
              >
                <Box m={2} display={"flex"} justifyContent={"space-around"}>
                  <Box>
                    {/* <Avatar alt="Remy Sharp" src={proficeIcon} style={{ height: 120, width: "90%" }} /> */}
                    <Avatar
                      sx={{ bgcolor: deepOrange[500] }}
                      style={{ height: 150, width: 150 }}
                    >
                      N
                    </Avatar>
                  </Box>
                  <Box mt={2}>
                    <Box display={"flex"}>
                      <Typography>URN:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.urn}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> First Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.firstName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Middle Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.middleName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Last Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.lastName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>District:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.district}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Phone Number:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.phoneNumber}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Address:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Modal>
        {/* Audit date model */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Set Audit Date
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Stack>
                    {!!errors.afterSubmit && (
                      <Alert severity="error">
                        {errors.afterSubmit.message}
                      </Alert>
                    )}
                  </Stack>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label="Audit Date"
                      disablePast
                      value={methods.watch().auditDate}
                      onChange={(value) => {
                        methods.setValue("auditDate", value, {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <RHFTextField
                          variant="filled"
                          {...params}
                          name="auditDate"
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <Grid item sx={{ textAlign: "right" }}>
                    <LoadingButton
                      type="submit"
                      color="secondary"
                      loading={isSubmitting}
                      onClick={AddAuditDate}
                      variant="contained"
                      sx={{
                        width: 160,
                      }}
                    >
                      Add
                    </LoadingButton>
                  </Grid>
                </FormProvider>
              </div>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={pageAssign}
          onClose={handlePageCloseAssign}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            ></Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <RegisterSchoolModal
                handlePageCloseAssign={handlePageCloseAssign}
                updateAandEForTask={updateAandEForTask}
                taskId={currentTaskId}
                DeleteTask={DeleteTask}
                educationalDistrict={
                  pendingTask.find((x) => x.id === currentTaskId)?.school
                    ?.educationalDistrict
                }
              />
            </Grid>
          </Box>
        </Modal>

        {/* view clerk modal */}

        <Modal
          open={openClerk}
          onClose={handlePageClerkClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Clerks Information
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                {viewClerk.map((eachData, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    justifyContent={"space-around"}
                  >
                    <Box>
                      {/* <Avatar alt="Remy Sharp" src={proficeIcon} style={{ height: 120, width: "90%" }} /> */}
                      <Avatar
                        sx={{ bgcolor: deepOrange[500] }}
                        style={{ height: 150, width: 150 }}
                      >
                        N
                      </Avatar>
                    </Box>
                    <Box mt={2}>
                      <Box display={"flex"}>
                        <Typography>URN:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.urn}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> First Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.firstName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> Middle Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.middleName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography>Last Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.lastName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> Phone Number:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.phoneNumber}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography>Address:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.address}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </Fragment>
  );
}

export default PreAudit;
