// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
// _mock_

import React, { useState } from "react";
// components
import Iconify from "../../components/Iconify";
import Logo from "../../images/quickguide/Screanshot 001.png";
// import Logo1 from "../../images/quickguide/Screenshot 002 copyrights.png";
import Logo2 from "../../images/quickguide/Screanshot 2.png";
import Logo3 from "../../images/quickguide/Screanshot 3.png";
import Logo4 from "../../images/quickguide/Screanshot 1.png";
import Logo5 from "../../images/quickguide/Screenshot 5.png";
import Logo6 from "../../images/quickguide/Screenshot 6.png";
import Logo7 from "../../images/quickguide/Screenshot 7.png";
import Logo8 from "../../images/quickguide/Screenshot 8.png";
import Logo9 from "../../images/quickguide/Screenshot 9.png";
import Logo10 from "../../images/quickguide/Screenshot 10.png";
import Logo11 from "../../images/quickguide/Screenshot 11.png";
import Logo12 from "../../images/quickguide/Screenshot 12.png";
import Logo13 from "../../images/quickguide/Screenshot 13.png";
import Logo14 from "../../images/quickguide/Screenshot 14.png";
import Logo15 from "../../images/quickguide/Screenshot 15.png";
import Logo16 from "../../images/quickguide/Screenshot 16.png";
import Logo17 from "../../images/quickguide/Screenshot 17.png";
import Logo18 from "../../images/quickguide/Screenshot 18.png";
import Logo19 from "../../images/quickguide/Screenshot 19.png";
import Logo20 from "../../images/quickguide/Screenshot 20.png";
import Logo21 from "../../images/quickguide/Screenshot 21.png";
import Logo22 from "../../images/quickguide/Screenshot 22.png";
import Logo23 from "../../images/quickguide/Screenshot 23.png";

// ----------------------------------------------------------------------

export default function Quickguide() {
  const [expanded, setExpanded] = useState(false);

  const QuickguideList = [
    {
      id: "All On Board",
      image: Logo,
    },

    {
      id: "Implementation Team",
      image: Logo2,
    },
    {
      id: "Multi-Agency Collaboration",
      image: Logo3,
    },
    {
      id: "Why SSLAG Is necessary?",
      image: Logo4,
    },
    {
      id: "Inter-Disciplinary Collaboration Beyond The School Boundaries",
      image: Logo5,
    },
    {
      id: "Statement of Purpose",
      image: Logo6,
    },
    {
      id: "Summary Of Framework",
      image: Logo7,
    },
    {
      id: "SSLAG Framework",
      image: Logo8,
    },
    {
      id: "SSLAG Of Framework",
      image: Logo9,
    },
    {
      id: "SSLAG Competences Requirements",
      image: Logo10,
    },
    {
      id: "SSLAG Song",
      image: Logo11,
    },
    {
      id: "SSLAG Minimum Standard",
      image: Logo12,
    },
    {
      id: "Other Knowledge Transfer Courses",
      image: Logo13,
    },
    {
      id: "Summary Of The Compliance Journey for schools",
      image: Logo14,
    },
    {
      id: "Knowledge transfer",
      image: Logo15,
    },
    {
      id: "Press Release _safe Schools Lagos (SSLAG)",
      image: Logo16,
    },
    {
      id: "SSLAG Ratingr",
      image: Logo17,
    },
    {
      id: "SSLAG Awards",
      image: Logo18,
    },
    {
      id: "Faqs And Contact Us",
      image: Logo19,
    },
    {
      id: "Faqs And Contact Us Pt(2)",
      image: Logo20,
    },
    {
      id: "Faqs And Contact Us Pt(3)",
      image: Logo21,
    },
    {
      id: "Beacon Of Satey Award",
      image: Logo22,
    },
    {
      id: "SSLAG Patners",
      image: Logo23,
    },
  ];

  return (
    <>
      <Box textAlign={"center"}>Quick Guide</Box>
      {QuickguideList.map((accordion) => (
        <Accordion key={accordion.id}>
          <AccordionSummary
            expandIcon={
              <Iconify
                icon={"eva:arrow-ios-downward-fill"}
                width={20}
                height={20}
              />
            }
          >
            <Typography variant="subtitle1">{accordion.id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <img
              src={accordion.image}
              style={{ width: "600px",marginLeft:'400px'}}
              alt="sampling alt"
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
