import React from "react";
import { RHFTextField, FormProvider } from "../../../components/hook-form";

import { Box, Typography, Grid, Paper, Alert, Stack } from "@mui/material";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";

import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Iconify from "../../../components/Iconify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

function EventSingleform() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [delegation, setAllDelegation] = useState([]);
  let { eventId } = useParams();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");
  const [newEvent, setNewEvent] = useState([]);

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Hover = {
    marginRight: "20px",
    color: "black",
    // fontFamily: "Times New Roman",
    fontSize: "15px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };
  const defaultValues = {
    schoolName: "",
    schoolEmail: "",
    schoolPhoneNumber: "",
    fullName: "",
    email: "",
    roleInSchool: "",
    phoneNumber: "",
    areYouRepresentingASchool: "",

    //
  };

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    // resolver: yupResolver(EventSchema),
    defaultValues,
  });
  const [state, setState] = useState("");

  const handleProgramChange = (e) => {
    setState({
      schoolProgramId: e.target.value,
    });
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;
  const watchRepresent = watch("areYouRepresentingASchool");
  const { formState, setValue, resetField } = methods;

  const SCHOOLTYPE = [
    {
      value: "0",
      label: "Are you representing a school",
    },
    {
      value: "1",
      label: "Yes",
    },
    {
      value: "2",
      label: "No",
    },
  ];

  useEffect(() => {
    let unmounted = false;

    const getEventById = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Events/GetEventById?id=${eventId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setNewEvent(data);
     
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getEventById();

    return () => {
      unmounted = true;
    };
  }, []);

  const deleteRecommendation = async (indexToDelete, recommendationId) => {
    await axoisCustomAgentForAdmin.delete(``);

    const newArray = delegation.filter((s, index) => index !== indexToDelete);
    setAllDelegation([...newArray]);
  };

  function handleDeleteTodo(index) {
    const newTodos = [...delegation];
    newTodos.splice(index, 1);
    setDeleting(newTodos);
  }

  const addDelegation = (newDelegation) => {
    console.log(newDelegation);
    let updatedDelegations = [...delegation];
    updatedDelegations.push(newDelegation);
    setAllDelegation(updatedDelegations);
    handleClose();
  };

  //=============
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await axoisCustomAgent.post(
        `/Events/RegisterAsIndividual`,
        {
          eventId,
          schoolName: data.schoolName,
          schoolEmail: data.schoolEmail,
          schoolPhoneNumber: data.schoolPhoneNumber,
          fullName: data.fullName,
          email: data.email,
          roleInSchool: data.roleInSchool,
          phoneNumber: data.phoneNumber,
          areYouRepresentingASchool:
            data.areYouRepresentingASchool === "1" ? true : "false",
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      navigate(`/registration/page`, { replace: true });
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  //===========
  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading Event...</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      ></Box>
      <hr />
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item mt={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Typography variant="h4">
                  {" "}
                  Registration for {newEvent?.name}
                </Typography>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      //   minHeight: 10,
                    }}
                  >
                    <Typography variant="body2" p={1}>
                      Complete personal information
                    </Typography>
                  </Box>
                </Grid>

                <Box display={"flex"} justifyContent={"space-around"} p={2}>
                  <Grid width={350}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="fullName"
                      label="Full Name"
                      variant="filled"
                    />
                  </Grid>

                  <Grid width={350}>
                    <RHFTextField name="email" label="Email" variant="filled" />
                  </Grid>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"} p={1}>
                  <Grid width={350}>
                    <RHFTextField
                      name="phoneNumber"
                      label="Phone Number"
                      variant="filled"
                    />
                  </Grid>

                  <Grid
                    sx={{
                      marginBlock: 1,
                      width: 350,
                    }}
                  >
                    <RHFTextField
                      select
                      name="areYouRepresentingASchool"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {SCHOOLTYPE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                </Box>
                {watchRepresent === "1" ? (
                  <Box>
                    <Grid p={2}>
                      <Box
                        sx={{
                          background: "#34a8e1",
                          borderRadius: 0.5,
                          boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                          color: "#ffff",
                          //   minHeight: 10,
                        }}
                      >
                        <Typography variant="body2" p={1}>
                          Complete School information
                        </Typography>
                      </Box>
                    </Grid>
                    <Box display={"flex"} justifyContent={"space-around"} p={1}>
                      <Grid width={350}>
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="schoolName"
                          label="School Name"
                          variant="filled"
                        />
                      </Grid>

                      <Grid width={350}>
                        <RHFTextField
                          name="schoolEmail"
                          label="Email"
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"} p={1}>
                      <Grid width={350}>
                        <RHFTextField
                          name="schoolPhoneNumber"
                          label="School Phone"
                          variant="filled"
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 1,
                          width: 350,
                        }}
                      >
                        <RHFTextField
                          name="roleInSchool"
                          label="Designation"
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                  </Box>
                ) : null}
                <Grid item sx={{ textAlign: "right" }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      width: 160,
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventSingleform;
