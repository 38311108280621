// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
// _mock_
import { _faqs } from "../../_mock";
// components
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function SchoolFaqList() {



  return (
    <>
      {_faqs.map((accordion) => (
        <Accordion key={accordion.id}>
          <AccordionSummary
            expandIcon={
              <Iconify
                icon={"eva:arrow-ios-downward-fill"}
                width={20}
                height={20}
              />
            }
          >
            <Typography variant="subtitle1">{accordion.heading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{accordion.detail}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

