import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Stack,
  Grid,
  CardActions,
  Button,
  CardActionArea,
} from "@mui/material";
import Back from "@material-ui/icons/ArrowBackIosRounded";
import SchoolIcon from "../../images/ph_house-line-thin.png";
import SupportIcon from "../../images/Mask group (4).png";
import ParentIcon from "../../images/heroicons_cube-transparent.png";
import SupplierIcon from "../../images/sun.png";
import AssessorIcon from "../../images/Vector.png";
import CollaboratorIcon from "../../images/Mask group (4).png";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
function AuditClerkDashboard() {
  return (
    <Box>
      <Typography variant="h4">Clerk Dashboard</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item>
          <Card sx={{ minWidth: 275, background: "#edf5f9" }}>
            {/* <CardActionArea href="/admin/OnboardingProcess/newFormSchool"> */}
            <CardActionArea href="/auditClerks/AuditTask">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={SchoolIcon}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Taskboards </Typography>
                </Box>
                {/* <Typography variant="h3">
                  {
                  dashboardDetails.find((x) => x.category === "Admins")
                    ?.totalNumber
                }
                </Typography> */}
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* <Grid item>
          <Card sx={{ minWidth: 275, background: "#edf5f9" }}>
            <CardActionArea href="">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={AssessorIcon}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Osp</Typography>
                </Box>
                <Typography variant="h3">
                
                </Typography>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid> */}
        <Grid item>
          <Card sx={{ minWidth: 275, background: "#d9d9d9" }}>
            <CardActionArea href="">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={SupplierIcon}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5">Knowledge Transfer </Typography>
                </Box>
                <Typography variant="h3">
                  {/* {
                  dashboardDetails.find((x) => x.category === "Admins")
                    ?.totalNumber
                } */}
                </Typography>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275, background: "#ffe2e1" }}>
            <CardActionArea href="">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={ParentIcon}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Practice Tools</Typography>
                </Box>
                <Typography variant="h3">
                  {/* {
                  dashboardDetails.find((x) => x.category === "Admins")
                    ?.totalNumber
                } */}
                </Typography>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ minWidth: 275, background: "#d9d9d9" }}>
            <CardActionArea href="">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={SupportIcon}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5">Support</Typography>
                </Box>
                <Typography variant="h3">
                  {/* {
                  dashboardDetails.find((x) => x.category === "Admins")
                    ?.totalNumber
                } */}
                </Typography>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AuditClerkDashboard;
