import React from "react";
import { Grid } from "@mui/material";
import AdminBG from "../../images/background.png";
import { Fragment, useEffect, useState } from "react";
function HomeLanding() {
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <div>
          <div class="header">
            <ul class="icon-text">
              <li>
                <a href="">
                  <i class="fa-solid fa-envelope"></i> lr@safeschoolslagos.ng
                </a>
              </li>
              <li>
                <a href="">
                  <i class="fa-solid fa-envelope"></i> lr@safeschoolslagos.ng
                </a>
              </li>
            </ul>

            <ul class="socialicon">
              <li>
                <a href="">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="">
                  <i class="fa-brands fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>

          <nav>
            <a href="" class="logo">
              <img src="images/logo.png" alt="" />
            </a>
            <ul class="navbar">
              <li>
                <a href="" class="active">
                  Home
                </a>
              </li>
              <li>
                <a href="">Market Place</a>
              </li>
              <li>
                <a href="">Events</a>
              </li>
              <li>
                <a href="">Safety Audit</a>
              </li>
              <li>
                <a href="">CJ Document</a>
              </li>
              <li>
                <a href="">FAQs</a>
              </li>
              <li>
                <a href="">Contact Us</a>
              </li>
              <li>
                <a href="">About Us</a>
              </li>
              <li class="dropdown">
                <a href="" class="dropbtn">
                  Sign Up
                </a>
                <div class="dropdown-content">
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </li>
            </ul>
          </nav>
        </div>

        <div class="home-text">
          <h1>Safe Schools Lagos</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
            Voluptas odio magni accusamus? Quo harum nihil, dignissimos,
            <br />
            Perferendis obcaecati doloribus quisquam nam?
          </p>
          <br />
          <div>
            <button>Get started</button>
            <button class="btn2">Sign In</button>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
}

export default HomeLanding;
