import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import {
  Box as Grid,
  TextField,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";
import useAuth from "../../hooks/useAuth";
import { formatRFC3339 } from "date-fns";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Next from "@material-ui/icons/ChevronRight";

import Back from "@material-ui/icons/ChevronLeft";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const curriculums = ["Nigerian", "British", "American", "French"];

function PersonnelMoeReg({ nextStep, prevStep }) {
  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();

  const { schoolId } = userData;

  const initialState = {
    // school personnel details

    roleId: "",
    title: "",
    name: "",
    email: "",
    phoneNumber: "",
    doesPersonnelHaveSafetyRecruitmentEvidence: "",
    personnelImageId: "",
    personnelImageUrl: "",
    numberOfTeachingStaff: "",
    numberOfNonTeachingStaff: "",

    // school moe
    educationalDistrict: "",
    foundingYear: "",
    doYouHaveMOEApproval: "",
    moeReferenceNumber: "",
    moeImageId: "",
    moeImageUrl: "",
    moeDateOfApproval: "",
    buildingApprovalNumber: "",
    sizeOfSchoolSite: "",
    numberOfBuildings: "",
    curriculums: [],
    curriculumNames: [],
  };

  const PersonelSchema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(PersonelSchema),
    // defaultValues,
  });

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPersonnelAndMOEInfo = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/SchoolOnboarding/GetSchoolPersonnelAndMOEDetails/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { schoolPersonnelDetails, schoolMOEDetails, schoolCurriculum } =
            response.data.data;

          const schoolCurriculumNames = [];

          if (schoolCurriculum !== null && schoolCurriculum.length > 0) {
            schoolCurriculum.forEach((item) => {
              schoolCurriculumNames.push(item.name);
            });
          }

          const {
            roleId,
            title,
            name,
            email,
            phoneNumber,
            doesPersonnelHaveSafetyRecruitmentEvidence,
            personnelImageId,
            personnelImageUrl,
            numberOfTeachingStaff,
            numberOfNonTeachingStaff,
          } = schoolPersonnelDetails;

          const {
            educationalDistrict,
            foundingYear,
            doYouHaveMOEApproval,
            moeReferenceNumber,
            moeImageId,
            moeImageUrl,
            moeDateOfApproval,
            buildingApprovalNumber,
            sizeOfSchoolSite,
            numberOfBuildings,
          } = schoolMOEDetails;

          setState({
            ...state,
            roleId: roleId ?? "",
            title: title ?? "",
            name: name ?? "",
            email: email ?? "",
            phoneNumber: phoneNumber ?? "",
            doesPersonnelHaveSafetyRecruitmentEvidence:
              doesPersonnelHaveSafetyRecruitmentEvidence === null
                ? ""
                : doesPersonnelHaveSafetyRecruitmentEvidence | 0,
            personnelImageId: personnelImageId ?? "",
            personnelImageUrl: personnelImageUrl ?? "",
            numberOfTeachingStaff: numberOfNonTeachingStaff ?? "",
            numberOfNonTeachingStaff: numberOfTeachingStaff ?? "",

            educationalDistrict:
              educationalDistrict === null ? "" : educationalDistrict,
            foundingYear: foundingYear ?? null,
            doYouHaveMOEApproval:
              doYouHaveMOEApproval === null ? "" : doYouHaveMOEApproval | 0,

            moeReferenceNumber: moeReferenceNumber ?? "",
            moeImageId: moeImageId ?? "",
            moeImageUrl: moeImageUrl ?? "",
            moeDateOfApproval: moeDateOfApproval ?? null,
            buildingApprovalNumber: buildingApprovalNumber ?? "",
            sizeOfSchoolSite: sizeOfSchoolSite ?? "",
            numberOfBuildings: numberOfBuildings ?? "",
            curriculums: schoolCurriculum,
            curriculumNames: schoolCurriculumNames,
          });
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchPersonnelAndMOEInfo();
  }, [schoolId]);

  const handleTimeChange = (newValue, type) => {
    setState({
      ...state,
      [type]: newValue,
    });
  };

  const onBlurForDoYouHaveMOEApproval = (e) => {
    if (e.target.value === 0) {
      setState({
        ...state,
        moeDateOfApproval: null,
      });
    }
  };

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  function getStyles(curriculum, schoolCurriculum, theme) {
    return {
      fontWeight:
        schoolCurriculum.indexOf(curriculum) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();

  const [schoolcurriculum, setSchoolcurriculum] = React.useState([]);

  const handleSchoolcurriculumChange = (event) => {
    const {
      target: { value },
    } = event;

    const newCurriculumNames =
      typeof value === "string" ? value.split(",") : value;

    const newCurriculum = newCurriculumNames.map((name) => {
      const nameIndex = curriculums.findIndex((x) => x === name);
      return { id: nameIndex + 1, name: name };
    });

    setState({
      ...state,
      curriculumNames: [...newCurriculumNames],
      curriculums: [...newCurriculum],
    });
  };

  const [imageSelected, setImageSelected] = useState("");
  const [recruitEvidenceUploading, setRecruitEvidenceUploading] =
    useState(false);

  const uploadRecruitmentEvidence = async () => {
    if (!imageSelected || imageSelected !== "") {
      setRecruitEvidenceUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;
          setState({
            ...state,
            personnelImageId: imageId,
            personnelImageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setRecruitEvidenceUploading(false);
        setImageSelected("");
      }
    }
  };

  const [buildingImage, setBuildingImage] = useState("");
  const [moeUploading, setMoeUploading] = useState(false);

  const uploadBuilding = async () => {
    if (!buildingImage || buildingImage !== "") {
      setMoeUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", buildingImage);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;
          setState({
            ...state,
            moeImageId: imageId,
            moeImageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setMoeUploading(false);
        setBuildingImage("");
      }
    }
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      if (state.foundingYear === null) {
        enqueueSnackbar("Year school was founded is required", {
          variant: "error",
        });
      }

      if (
        state.doYouHaveMOEApproval === 1 &&
        state.moeDateOfApproval === null
      ) {
        enqueueSnackbar(
          "Date of approval by ministry of education is required",
          {
            variant: "error",
          }
        );
      }

      const {
        roleId,
        title,
        name,
        phoneNumber,
        email,
        doesPersonnelHaveSafetyRecruitmentEvidence,
        personnelImageId,
        personnelImageUrl,
        numberOfTeachingStaff,
        numberOfNonTeachingStaff,
        educationalDistrict,
        ReferenceNumber,
        doYouHaveMOEApproval,
        moeReferenceNumber,
        moeImageId,
        moeImageUrl,
        // moeDateOfApproval,
        buildingApprovalNumber,
        sizeOfSchoolSite,
        numberOfBuildings,
        curriculums,
      } = state;

      // ---------------------------------------------------------------------
      // ----------------------------------------------------------------------

      const response = await axoisCustomAgent.put(
        `/SchoolOnboarding/UpdateSchoolPersonnelAndMoeDetails/${schoolId}`,
        {
          roleId,
          title,
          name,
          phoneNumber,
          email,
          doesPersonnelHaveSafetyRecruitmentEvidence,
          personnelImageId:
            personnelImageId.trim() === "" ? null : personnelImageId,
          personnelImageUrl:
            personnelImageUrl.trim() === "" ? null : personnelImageUrl,
          numberOfTeachingStaff,
          numberOfNonTeachingStaff,
          educationalDistrict,
          foundingYear: new Date(state.foundingYear).toISOString(),
          doYouHaveMOEApproval,
          ReferenceNumber,
          moeReferenceNumber,
          moeImageId: moeImageId.trim() === "" ? null : moeImageId,
          moeImageUrl: moeImageUrl.trim() === "" ? null : moeImageUrl,
          moeDateOfApproval:
            state.moeDateOfApproval === null
              ? null
              : new Date(state.moeDateOfApproval).toISOString(),
          buildingApprovalNumber,
          sizeOfSchoolSite,
          numberOfBuildings,
          curriculums,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      const { status } = error;
      if (status === 400) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
      } else {
        // console.log(error);
        enqueueSnackbar("", { variant: "error" });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      // console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `  url(${Bg1}) `,
          backgroundSize: "cover",
        }}
        // fullWidth
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              borderRadius: 2,
              padding: 2,
              margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
              maxHeight: 1200,
              minWidth: "md",
              background: "#ffff",
            }}
          >
            <Typography fontSize={"23px"} color={"#6495ed"}>
              MANAGEMENT AND MOE REGISTRATION
            </Typography>
            {loading ? (
              <p>Loading....</p>
            ) : (
              <Container
                sx={{
                  display: "flex",
                }}
              >
                <Grid padding={4}>
                  <FormControl
                    sx={{
                      width: 470,
                      ml: 2,
                    }}
                  >
                    <FormHelperText>Title of contact person *</FormHelperText>
                    <Select
                      labelId="Title"
                      id="Title"
                      required
                      value={state.title ?? ""}
                      label="Age"
                      onChange={(e) => handleChange(e, "title")}
                    >
                      <MenuItem value={"Mr"}>Mr</MenuItem>
                      <MenuItem value={"Mrs"}>Mrs</MenuItem>
                      <MenuItem value={"Ms"}>Ms</MenuItem>
                      <MenuItem value={"Miss"}>Miss</MenuItem>
                      <MenuItem value={"Dr"}>Dr.</MenuItem>
                    </Select>
                  </FormControl>
                  <Stack width={500} mt={1}>
                    <TextField
                      sx={{ m: 2 }}
                      id="Name"
                      label="Full name"
                      variant="outlined"
                      required
                      value={state.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                    <FormControl
                      sx={{
                        width: 470,
                        ml: 2,
                      }}
                    >
                      <FormHelperText>Designation</FormHelperText>
                      <Select
                        labelId="Designation"
                        id="Role"
                        value={state.roleId}
                        required
                        // label="School Gender Type"
                        onChange={(e) => handleChange(e, "roleId")}
                      >
                        <MenuItem value={2}>School Administrator</MenuItem>
                        <MenuItem value={3}>Principal</MenuItem>
                        <MenuItem value={4}>Vice Principal</MenuItem>
                        <MenuItem value={5}>Teacher</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack sx={{ width: 500 }}>
                    <TextField
                      sx={{ m: 2 }}
                      id="TelNo"
                      label="Mobile No"
                      type="number"
                      variant="outlined"
                      value={state.phoneNumber}
                      required
                      onChange={(e) => handleChange(e, "phoneNumber")}
                    />
                    <TextField
                      sx={{ m: 2 }}
                      id="Email"
                      label="Email"
                      variant="outlined"
                      type="email"
                      required
                      value={state.email}
                      onChange={(e) => handleChange(e, "email")}
                    />

                    <TextField
                      sx={{ m: 2 }}
                      id="Teachstaff"
                      label="No of teaching staff"
                      variant="outlined"
                      type="number"
                      required
                      value={state.numberOfTeachingStaff}
                      onChange={(e) => handleChange(e, "numberOfTeachingStaff")}
                    />
                  </Stack>
                  <TextField
                    sx={{ m: 2, width: 460 }}
                    id="NonTeachStaff"
                    label=" No non teaching staff"
                    variant="outlined"
                    type="number"
                    required
                    value={state.numberOfNonTeachingStaff}
                    onChange={(e) =>
                      handleChange(e, "numberOfNonTeachingStaff")
                    }
                  />
                  <Stack sx={{ width: 460, ml: 2 }}>
                    <FormControl>
                      <FormHelperText>
                        Does the key personnel has evidence of safe recruitment
                        checks?
                      </FormHelperText>
                      <Select
                        labelId="eviSafety"
                        id="eviSafety"
                        value={state.doesPersonnelHaveSafetyRecruitmentEvidence}
                        label="EviSafety"
                        onChange={(e) =>
                          handleChange(
                            e,
                            "doesPersonnelHaveSafetyRecruitmentEvidence"
                          )
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>

                    {state.doesPersonnelHaveSafetyRecruitmentEvidence === 1 ? (
                      <div
                        style={{
                          border: "1px solid grey",
                          width: "500",
                          marginTop: "10px",
                          padding: "13px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <FormHelperText>
                            Upload SSLAG certificate below (5MB maximum size)e.g
                            PNG,JPEG,PDF
                          </FormHelperText>
                          <input
                            type="file"
                            onChange={(event) => {
                              setImageSelected(event.target.files[0]);
                            }}
                          />
                          <button
                            onClick={async () =>
                              await uploadRecruitmentEvidence()
                            }
                            disabled={
                              recruitEvidenceUploading ||
                              state.personnelImageUrl.length > 0
                            }
                          >
                            Upload evidence
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </Stack>
                </Grid>

                <Grid padding={2}>
                  <Stack sx={{ width: 500, ml: 2 }}>
                    <FormControl sx={{ mt: 1 }}>
                      <FormHelperText>Educational district</FormHelperText>

                      <Select
                        labelId="EducateDis"
                        id="EducateDis"
                        value={state.educationalDistrict}
                        label="EducateDis"
                        onChange={(e) => handleChange(e, "educationalDistrict")}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 1, width: 500 }}>
                      <FormHelperText>
                        Curriculum (select all that apply)
                      </FormHelperText>
                      <Select
                        labelId=""
                        id="e"
                        multiple
                        variant="filled"
                        value={state.curriculumNames}
                        onChange={handleSchoolcurriculumChange}
                        input={<OutlinedInput label="Curriculum" />}
                        MenuProps={MenuProps}
                      >
                        {curriculums.map((curriculum, index) => (
                          <MenuItem
                            key={curriculum}
                            value={curriculum}
                            style={getStyles(
                              curriculum,
                              schoolcurriculum,
                              theme
                            )}
                          >
                            {curriculum}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  {/* ====================== */}

                  <Stack sx={{ width: 500, mt: 2, ml: 2 }}>
                    <FormControl>
                      <FormHelperText>
                        Do you have ministry education reference number?
                      </FormHelperText>
                      <Select
                        labelId="buildingApproval"
                        id="EduRefNo"
                        value={state.doYouHaveMOEApproval}
                        label="Ministry education ref no"
                        onBlur={onBlurForDoYouHaveMOEApproval}
                        onChange={(e) =>
                          handleChange(e, "doYouHaveMOEApproval")
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>

                  {/* ========================= */}
                  <Stack sx={{ width: 500, ml: 2 }}>
                    {/* <TextField
                      sx={{ mt: 2, width: 500 }}
                      id="EduRefNo"
                      label="Ministry education ref no"
                      variant="outlined"
                      value={state.moeReferenceNumber}
                      onChange={(e) => handleChange(e, "moeReferenceNumber")}
                    /> */}
                    <Grid width={600}>
                      {state.doYouHaveMOEApproval === 1 ? (
                        <Stack mt={3} width={500}>
                          {/* <FormHelperText>
                            Ministry education reference no
                          </FormHelperText> */}
                          <TextField
                            sx={{ width: 500 }}
                            id="EduRefNo"
                            label="Ministry education ref no"
                            variant="outlined"
                            value={state.moeReferenceNumber}
                            onChange={(e) =>
                              handleChange(e, "moeReferenceNumber")
                            }
                          />

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormHelperText>
                              {" "}
                              Date school approved by ministry of education:
                            </FormHelperText>
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              // label="Date of Birth "
                              value={state.moeDateOfApproval}
                              onChange={(value) =>
                                handleTimeChange(value, "moeDateOfApproval")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="moeDateOfApproval"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      ) : null}
                      <Stack mr={4} width={500}>
                        <FormHelperText>Date school founded</FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            // label=" Year School Founded"
                            inputFormat="dd/MM/yyyy"
                            disableFuture
                            value={state.foundingYear}
                            onChange={(value) =>
                              handleTimeChange(value, "foundingYear")
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>

                    <TextField
                      sx={{ mt: 2, width: 500 }}
                      id="SchoolSize"
                      label=" Approximate size of school site in meters sq.(only numbers )"
                      variant="outlined"
                      value={state.sizeOfSchoolSite}
                      onChange={(e) => handleChange(e, "sizeOfSchoolSite")}
                    />
                    <TextField
                      sx={{ mt: 2, width: 500 }}
                      id="NumBuild"
                      label="Number of buildings"
                      variant="outlined"
                      type="num"
                      value={state.numberOfBuildings}
                      onChange={(e) => handleChange(e, "numberOfBuildings")}
                    />
                  </Stack>
                  <Stack sx={{ width: 500, mt: 2, ml: 2 }}>
                    <FormControl>
                      <FormHelperText>
                        Does your building have approval
                      </FormHelperText>
                      <Select
                        labelId="buildingApproval"
                        id="buildingApproval"
                        // value={state.doYouHaveMOEApproval}
                        value={state.buildingApprovalNumber}
                        label="buildingApproval"
                        onChange={(e) =>
                          handleChange(e, "buildingApprovalNumber")
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  {state.buildingApprovalNumber === 1 ? (
                    <Stack sx={{ width: 500 }}>
                      <div
                        style={{
                          border: "1px solid grey",
                          width: "500",
                          marginLeft: "8px",
                          marginTop: "10px",
                          padding: "13px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <input
                            type="file"
                            onChange={(event) => {
                              setBuildingImage(event.target.files[0]);
                            }}
                          />
                          <button
                            onClick={async () => await uploadBuilding()}
                            disabled={
                              moeUploading || state.moeImageUrl.length > 0
                            }
                          >
                            Upload evidence
                          </button>
                        </div>
                      </div>
                    </Stack>
                  ) : null}

                  <Grid sx={{ mt: 12, textAlign: "right" }}>
                    <LoadingButton
                      loading={loadingSubmit}
                      label="Prev"
                      variant="contained"
                      type="submit"
                      sx={{ mr: 4 }}
                      startIcon={<Back />}
                      onClick={prevStep}
                    >
                      Back
                    </LoadingButton>

                    <LoadingButton
                      loading={loadingSubmit}
                      endIcon={<Next />}
                      variant="contained"
                      type="submit"
                      onClick={async () => await handleSubmit()}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonnelMoeReg;
