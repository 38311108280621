import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PersonnelMoeReg from "./PersonnelMoeReg";
import SchoolProvision from "./SchoolProvision";
import MandatorySafety from "./MandatorySafety";
import MandatoryInsurance from "./MandatoryInsurance";
import StarterPark from "./StarterPark";
import SchoolDirector from "./SchoolDirector";
import { Button, Typography } from "@mui/material";
import SchoolDetails from "./SchoolDetails";
import Declarations from "./Declarations";
import Logo from "src/components/Logo";
import useAuth from "src/hooks/useAuth";

const schoolDetails = {
  schoolName: "",
  Address: "",
  TypeOfSchool: "",
  SchoolEmail: "",
  LenghtOfSchooDay: "",
  StartOfSchoolDay: "",
  EndOfSchoolDay: "",
  SchoolNum: "",
  SchoolGenderType: "",
  SingleGender: "",
  BothGender: "",
  TotalOfStudent: "",
  NoOfMale: "",
  NoOfFemale: "",
  schoolProgramme: "",
};
const personnelMoeReg = {
  Tittle: "",
  Role: "",
  TelNo: "",
  Email: "",
  TeachStaff: "",
  NonTeachStaff: "",
  EducateDis: "",
  Curriculum: "",
  WapaObt: "",
  YearFound: "",
  EduRefNo: "",
  SchoolSize: "",
  NumBuild: "",
};
const mandatorySafety = {
  LsscAttendee: "",
  NoOfFirstAider: "",
  NoOfSafetyChampion: "",
  NoOfSafetyPrefect: "",
  CertificateDate: "",
};
const mandatoryInsurance = {
  nextStep: "",
  prevStep: "",
  HmoProvider: "",
  InsuranceHeld: "",
  PolicyNo: "",
  InsuranceDet: "",
  SafeSchInsPolicy: "",
};
const starterPark = {
  nextStep: "",
  prevStep: "",
  TorchLight: "",
  ResolutionJack: "",
  ComplianceManual: "",
  WetFloorSign: "",
  FireExit: "",
  FireDoor: "",
  GoSign: "",
  MusterSign: "",
  CaseOfFireStatement: "",
  firstAidSign: "",
  SmokingSign: "",
  firstAidBox: "",
  Sslag: "",
};
const declarations = {
  prevStep: "",
  submit: "",
  FullName: "",
  SchoolRole: "",
  date: "",
  AnyInfo: "",
};
const schoolDirector = {
  ownsName: "",
  OwnsEmail: "",
  OwnsNum: "",
};
const schoolProvision = {
  nextStep: "",
  prevStep: "",

  AvaGen: "",
  YearOfPur: "",
  ModelGen: "",
  DieselPet: "",
  SickBay: "",
  NurseOnSite: "",
  ScienceLab: "",
  TypeOfLab: "",
  DesPlayground: "",
  Outdoor: "",
  Dimension: "",
  TransitSystem: "",
  NoOfBuses: "",
  NoOfDriver: "",
  NoOfBusesEscorts: "",
  PupilUsingTransit: "",
  NoStaffRoom: "",
  ToiletFacstaff: "",
  PupilsFac: "",
  SchoolHall: "",
  AssemblyGround: "",
  PupilsEat: "",
  FloorType: "",
  MealServed: "",
  LunchMeal: "",
  AllLunch: "",
  PackedLunchDes: "",
  RoomOnPremise: "",
};
const initialState = {
  ...schoolDetails,
  ...personnelMoeReg,
  ...schoolDirector,
  ...schoolProvision,
  ...mandatorySafety,
  ...mandatoryInsurance,
  ...starterPark,
  ...declarations,
};

function ProfileComplete() {
  const { userData } = useAuth();
  const { currentOnboardingStage, schoolId } = userData;
  const [state, setState] = useState(initialState);
  const navigate = useNavigate();

  const [step, setStep] = useState(currentOnboardingStage); // i changed this from 1 to 5

  const nextStep = (props) => {
    let nextStep = step + 1;
    setStep(nextStep);
  };

  const prevStep = (props) => {
    let prevStep = step - 1;
    setStep(prevStep);
  };
  const submit = (props) => {
    let submit = step - 1;
    setStep(submit);
  };

  useEffect(() => {
    if (schoolId === null) {
      navigate("/auth/login");
    }
  }, [schoolId]);

  const handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  switch (step) {
    case 1:
      return <SchoolDetails nextStep={nextStep} />;
    case 2:
      return (
        <PersonnelMoeReg
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
          Tittle={state.Tittle}
          Role={state.Role}
          TelNo={state.TelNo}
          Email={state.Email}
          TeachStaff={state.TeachStaff}
          NonTeachStaff={state.NonTeachStaff}
          EducateDis={state.EducateDis}
          Curriculum={state.Curriculum}
          WapaObt={state.WapaObt}
          YearFound={state.YearFound}
          EduRefNo={state.EduRefNo}
          SchoolSize={state.SchoolSizs}
          NumBuild={state.NumBuild}
        />
      );

    case 3:
      return (
        <SchoolProvision
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
        />
      );
    case 4:
      return (
        <MandatorySafety
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
          LsscAttendee={state.LsscAttendee}
          NoOfFirstAider={state.NoOfFirstAider}
          NoOfSafetyChampion={state.NoOfSafetyChampion}
          NoOfSafetyPrefect={state.NoOfSafetyPrefect}
        />
      );
    case 5:
      return (
        <SchoolDirector
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
        />
      );
    case 6:
      return (
        <MandatoryInsurance
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
          HmoProvider={state.HmoProvider}
          InsuranceHeld={state.InsuranceHeld}
          PolicyNo={state.PolicyNo}
          InsuranceDet={state.InsuranceDet}
        />
      );
    case 7:
      return (
        <StarterPark
          nextStep={nextStep}
          prevStep={prevStep}
          submit={submit}
          TorchLight={state.HmoTorchLight}
          ResolutionJack={state.ResolutionJack}
          ComplianceManual={state.ComplianceManual}
          WetFloorSign={state.WetFloorSign}
          FireExit={state.FireExit}
          FireDoor={state.FireDoor}
          GoSign={state.GoSign}
          MusterSign={state.MusterSign}
          CaseOfFireStatement={state.CaseOfFireStatement}
          firstAidSign={state.firstAidSign}
          SmokingSign={state.SmokingSign}
          firstAidBox={state.firstAidBox}
          Sslag={state.Sslag}
        />
      );
    case 8:
      return (
        <Declarations
          prevStep={prevStep}
          submit={submit}
          FullName={state.FullName}
          SchoolRole={state.SchoolRole}
          date={state.date}
        />
      );
    default:
      return <Typography></Typography>;
  }
}

export default ProfileComplete;
