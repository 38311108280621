import React from "react";
import { Grid, Container, Box, Typography, Button } from "@mui/material";
import AdminBG from "../../../images/background.png";
import { Fragment, useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useParams } from "react-router";
import logoBg from "../../../images/sslagBookingForm.JPG";
import formatnaira from "format-to-naira";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";

function PayAuditInvoice() {
  let { tokenId } = useParams();
  const [invoiceSubmitted, setInvoiceSubmitted] = useState(false);

  const VerifyAuditPayment = async (
    invoiceReference,
    paymentReferenceId,
    paymentServiceProvider
  ) => {
    const response = await axoisCustomAgent.post(
      `/PayForAudited/VerifyPayForAuditPayment`,
      {
        invoiceReference,
        paymentReferenceId: reference,
        paymentServiceProvider,
      }
    );
    const { statusCode, message } = response.data;
    if (statusCode === 500) {
      throw new Error(message);
    } else {
    }
    // setInvoiceSubmitted(true);
  };

  const [reference, setReference] = useState(null);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        await VerifyAuditPayment(
          invoice?.invoiceReferenceId,
          reference,
          "paystack"
        );
        setInvoiceSubmitted(true);
        setInvoiceStatus();

        navigate(`/AuditPaymentCongratulation/${tokenId}`, {
          replace: true,
        });
      } catch (error) {
        enqueueSnackbar("something went wrong", { variant: "error" });
      }
    };
    if (reference !== null) {
      verifyPayment();
    }
  }, [reference]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  // const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unmounted = false;
    const getAuditInvoice = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/PayForAudited/GetInvoiceForAuditedSchool?token=${tokenId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        setInvoiceStatus(message);

        const { data } = response.data;
        if (!unmounted) setInvoice(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAuditInvoice();
    return () => {
      unmounted = true;
    };
  }, []);

  const publicKey = `${process.env.REACT_APP_SSLAG_ONBOARD}`;
  const payStackProps = {
    email: invoice?.invoiceTo.email,
    amount: invoice?.total * 100,
    split_code: `${process.env.REACT_APP_SSLAG_AUD_SPLIT_KEY}`, 
    // split_code: "SPL_Y9F0NFuvkT",
    metadata: {
      name: invoice?.invoiceTo.name,
      // name: invoice?.invoiceTo.email,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (value) => {
      const { reference } = value;
      setReference(reference);
    },
  };
  const LoginNavigate = () => {
    // navigate to Private urls
    navigate(`/payLater`);
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (!loading && invoice === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }

  if (!loading && invoice?.paymentStatus) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Audit paid </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 200,
            width: "50%",
          }}
        >
          <Box display={"flex"} justifyContent={"center"} p={2}>
            <img
              src={logoBg}
              alt=""
              style={{
                width: 300,
              }}
            />
          </Box>

          <Typography
            textAlign={"center"}
            variant="h5"
            sx={{
              color: "#00B000",
            }}
          >
            INVOICE
          </Typography>

          <Box display={"flex"} justifyContent={"space-between"} m={2}>
            <Box>
              <Typography fontSize={"12px"}>Invoice From:</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceReferenceId}
              </Typography>
              <Typography fontSize={"12px"}>Issued On</Typography>
              <Typography color={"secondary"}>
                {invoice?.createdOn.split("T")[0]}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={"12px"}>Invoice Toss</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceTo.name}
              </Typography>
              <Typography fontSize={"12px"}>Reference Number</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceReferenceId}
              </Typography>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"space-between"} m={2}>
            <Typography variant="h4">Items</Typography>
            <Typography variant="h4">Cost</Typography>
          </Box>
          {invoice?.invoiceItems.map((eachItem, index) => (
            <Box
              key={index}
              display={"flex"}
              justifyContent={"space-between"}
              m={2}
            >
              <Typography>{eachItem.description}</Typography>
              <Typography> {formatnaira(eachItem.price)} </Typography>
            </Box>
          ))}

          <Box m={2}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Total Amount</Typography>
              <Typography>{formatnaira(invoice?.total)}</Typography>
            </Box>
          </Box>

          <Grid textAlign={"center"} p={2}>
            <PaystackButton {...payStackProps} className="paystackBtn" />
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default PayAuditInvoice;
