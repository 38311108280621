import {
    Grid,
    Box,
    Alert,
    Stack,
    Typography,
    FormControl,
    Container,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as Yup from "yup";
  import {
    FormProvider,
    RHFTextField,
    RHFCheckbox,
    RHFRadioGroup,
  } from "../../../components/hook-form";
  import LoadingButton from "@mui/lab/LoadingButton";
  import { useSnackbar } from "notistack";
  import axoisCustomAgent, {
    axoisCustomAgentForAdmin,
  } from "../../../utils/axiosCustomAgent";
  import useAuthAdmin from "src/hooks/useAuthAdmin";
  import { useNavigate, useParams } from "react-router";
  import AdminBG from "../../../images/background.png";
  
  function AssemblyAdmin({ section, item, schoolId, auditId }) {
    const { enqueueSnackbar } = useSnackbar();
    // const [loading, setLoading] = useState(false);
    const { stakeholderCategory, id } = useAuthAdmin();
    const [observation, setObservation] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { taskId,clerkId  } = useParams();
  
    // localStorage.getItem('items', JSON.stringify(item));
    const items = JSON.parse(localStorage.getItem("items"));
  
    const defaultValues = {
   
  
  
      // ======================
     
      evidenceOfCrowdControl: "",
      teacherPupilsRapport: "",
      socialAwareness: "",
      evidenceOfEmergencyExist: "",
      behaviourManagement: "",
      evidenceOfSafeLearning: "",
      evidenceOfSafetyAwarenessForStaff: "",
      NumberOfStaff: "",
      assemblyTaken:"",
      
  
    };
  
    const methods = useForm({
      // resolver: yupResolver(),
      defaultValues,
    });
  
    const {
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
  
   
    const option = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  
    useEffect(() => {
        let unmounted = false;
        const GetAssembly = async () => {
          setLoading(true);
          try {
            const response = await axoisCustomAgentForAdmin.get(
              `/Audit/GetSectionOneAssembly?taskId=${taskId}&clerkId=${clerkId}`
            );
            const { statusCode, message } = response.data;
    
            if (statusCode !== 200) {
              throw new Error(message);
            }
            const { data } = response.data;
            methods.reset({ ...data });
    
            
          } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
          } finally {
            setLoading(false);
          }
        };
    
        if (id !== null && id !== "" && !unmounted) GetAssembly();
        return () => {
          unmounted = true;
        };
      }, []);
    
    // const onSubmit = () => {};
  
    const onSubmit = async (data) => {
   
    };
  
    return (
      <Fragment>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
            background: `url(${AdminBG})`,
            minHeight: "100vh",
            backgroundCover: "contain",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 1,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 600,
              width: "70%",
            }}
          >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} m={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
                <Box textAlign={"center"}>
                  <Typography
                    variant="h5"
                    sx={{
                      background: "#00bbea",
                      textAlign: "centre",
                      mt: 2,
                      p: 2,
                    }}
                  >
                    Section1A.2: Assembly
                  </Typography>
                </Box>
  
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>
                        Evidence of crowd control assessment?
                      </Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfCrowdControl"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>Teacher/Pupils Rapport?</Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="teacherPupilsRapport"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>Evidence of psycho-social awareness for discussion on assembly?</Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="socialAwareness"
                      />
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>
                       General evidence of emergency exist for safe learning?
                      </Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfEmergencyExist"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                  <Grid>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>Effective behaviour management?</Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="behaviourManagement"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>
                        General evidence of a safe learning taking place?
                      </Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfSafeLearning"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>
                      
                      </Typography>
  
                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfSafetyAwarenessForStaff"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <RHFTextField
                        name="NumberOfStaff"
                        label="Appropriate number of adult supervision on duty "
                        variant="filled"
                      />
                    </Box>
                    <Grid sx={{ textAlign: "right" }}>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        loading={isSubmitting}
                        sx={{ width: 100, color: "#fff", background: "#018fe2" }}
                        // startIcon={<Back />}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </FormProvider>
          </Container>
        </Grid>
      </Fragment>
 
    );
  }
  
  export default AssemblyAdmin;