import { Stack } from "immutable";
import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
// import TodoForm from "./DirectorForm";

function DirectorList({ directorsArray, deleteDirector }) {
  const { enqueueSnackbar } = useSnackbar();

  const [deleting, setDeleting] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");

  const hanldeDelete = async (index) => {
    setDeleting(true);
    setIndexToDelete(index);

    try {
      await deleteDirector(index);
      setDeleting(false);
      setIndexToDelete("");

      enqueueSnackbar("Director record deleted successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Unable to delete director record", { variant: "error" });
    } finally {
      setDeleting(false);
      setIndexToDelete("");
    }
  };

  return (
    <Box mt={5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ width: "25%" }}>FullName</Typography>
        <Typography sx={{ width: "25%" }}>Email</Typography>
        <Typography sx={{ width: "25%" }}>Phone Number</Typography>
        <Typography sx={{ width: "25%" }}>Upload (url)</Typography>
      </Box>
      <hr />
      <Box mt={2}>
        {directorsArray != null && directorsArray.length > 0 ? (
          directorsArray.map((item, index, array) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {item.name.length >= 10 ? (
                <Typography sx={{ width: "25%" }}>
                  {item.name.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "25%" }}>{item.name}</Typography>
              )}
              {/* <Typography sx={{ width: "20%" }}>{item.fullName}</Typography> */}

              {item.email.length >= 10 ? (
                <Typography sx={{ width: "25%" }}>
                  {item.email.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "25%" }}>{item.email}</Typography>
              )}

              {item.phoneNumber.length >= 10 ? (
                <Typography sx={{ width: "25%" }}>
                  {item.phoneNumber.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "25%" }}>
                  {item.phoneNumber}
                </Typography>
              )}

              {item.imageUrl != null && item.imageUrl.length >= 10 ? (
                <Typography sx={{ width: "25%" }}>
                  {item.imageUrl.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "25%" }}>{item.imageUrl}</Typography>
              )}

              <LoadingButton
                loading={deleting && indexToDelete === index}
                onClick={async () => await hanldeDelete(index)}
              >
                <DeleteForeverIcon />
              </LoadingButton>
            </Box>
          ))
        ) : (
          <Typography>Add a director</Typography>
        )}
      </Box>
    </Box>
  );
}

export default DirectorList;
