
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Typography, Grid, Box } from "@mui/material";
import styled from "styled-components";

import { useSnackbar } from "notistack";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AdminStaff({ schoolId }) {
  const [staffAndTheirDuties, setStaffAndTheirDuties] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingStaffAndTheirDuties, setLoadingStaffAndTheirDuties] =
    useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = staffAndTheirDuties.filter(
    (item) =>
      item.duty.name &&
      item.duty.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "staff Id",
      selector: (row) => row.registrationNumber,
    },

    // {
    //   name: "Duty",
    //   selector: (row) => row.duty.name,
    // },
    // {
    //   name: "Start Date",
    //   selector: (row) => row.duty.startDate.split("T")[0],
    // },
    // {
    //   name: "End Date",
    //   selector: (row) => row.duty.endDate.split("T")[0],
    // },
  ];

  useEffect(() => {
    let unmounted = false;
    const getStaffAndTheirDuties = async () => {
      setLoading(true);
      setLoadingStaffAndTheirDuties(true);

      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Schools/GetAllSchoolStaffAndTheirDuties/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        if (!unmounted) setStaffAndTheirDuties([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingStaffAndTheirDuties(false);
      }
    };
    if (schoolId !== "") {
      getStaffAndTheirDuties();
    }
  }, [schoolId]);

  return (
    <Fragment>
      {loadingStaffAndTheirDuties ? (
        <Typography variant="h3">Loading Staff Duties....</Typography>
      ) : (
        <Grid>
          <DataTable
            columns={columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            responsive
            //keyField={staff.map((item) => item.appUserId)}
          />
        </Grid>
      )}
    </Fragment>
  );
}

export default AdminStaff;

