import React, { useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  Modal,
  Box,
  IconButton,
  Card,
  CardHeader,
} from "@mui/material";
import { Link } from "react-router-dom";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import AdminAllStudent from "./AdminAllStudent";
import AdminAttendance from "./AdminAttendance";
import AdminStaffDuty from "./AdminStaffDuty";
import AdminStaff from "./AdminStaff";
import SchoolCertificate from "./SchoolCertificate";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By School Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function AdminSchools() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [school, setAllSchool] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;

    const getAllSchool = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Schools/GetAllSchools`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllSchool([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "") getAllSchool();
    return () => {
      unmounted = true;
    };
  }, [id]);

  const Gridimage = {
    background: "#fafafa",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = school.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },

    {
      name: "School Name",
      selector: (row) => row.schoolName,
    },

    {
      name: "School Type",
      selector: (row) => row.schoolTypeName,
    },
    {
      name: "No Of Student(s)",
      selector: (row) => row.numberOfStudents,
    },
    {
      name: "No Of Staff",
      selector: (row) => row.numberOfStaff,
    },

    { cell: (row) => <MoreMenuButton schoolId={row.schoolId} /> },
  ];

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Schools...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      ></Grid>
      <Card>
        <CardHeader title="School Management" />
        <Grid style={Gridimage}>
          {school.length === 0 ? (
            <Typography>No School Present</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
            />
          )}
        </Grid>
      </Card>
    </Grid>
  );
}

export default AdminSchools;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ isActive, schoolId }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  const [viewpage, setViewPage] = React.useState(false);
  const handleViewOpen = () => setViewPage(true);
  const handleViewClose = () => setViewPage(false);

  const [viewdutypage, setViewDutyPage] = React.useState(false);
  const handleDutyOpen = () => setViewDutyPage(true);
  const handleDutyClose = () => setViewDutyPage(false);

  const [viewstaff, setViewStaff] = React.useState(false);
  const handleStaffOpen = () => setViewStaff(true);
  const handleStaffClose = () => setViewStaff(false);

  // const [certificate, setBosCertificate] = React.useState(false);
  // const handleOpenCertificate = () => setBosCertificate(true);
  // const handleCloseCertificate = () => setBosCertificate(false);

  const BosCertificate = () => {
    navigate(`/admin/school/AdminBosCertificate/${schoolId}`);
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handlePageOpen} sx={{ color: "succes" }}>
          View Student
        </MenuItem>
        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              All Student
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminAllStudent schoolId={schoolId} />
            </Grid>
          </Box>
        </Modal>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleViewOpen} sx={{ color: "success" }}>
          View Attendance
        </MenuItem>
        <Modal
          open={viewpage}
          onClose={handleViewClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              All Student
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminAttendance schoolId={schoolId} />
            </Grid>
          </Box>
        </Modal>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleDutyOpen} sx={{ color: "success" }}>
          Staff Duties
        </MenuItem>
        <Modal
          open={viewdutypage}
          onClose={handleDutyClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              School Staff Duties
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminStaffDuty schoolId={schoolId} />
            </Grid>
          </Box>
        </Modal>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleStaffOpen} sx={{ color: "success" }}>
          View Staff
        </MenuItem>

        <Modal
          open={viewstaff}
          onClose={handleStaffClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              School Staff Duties
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminStaff schoolId={schoolId} />
            </Grid>
          </Box>
        </Modal>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={BosCertificate} sx={{ color: "success" }}>
          Manage BOS
        </MenuItem>

        {/* <Modal
          open={certificate}
          onClose={handleCloseCertificate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              Bos Certificate
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <SchoolCertificate schoolId={schoolId} />
            </Grid>
          </Box>
        </Modal> */}
      </MenuPopover>
    </>
  );
}
