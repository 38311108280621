import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Container,
  Modal,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import AuditGradingGeneral from "./AuditGrading/AuditGradingGeneral";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import LocationPin from "@material-ui/icons/Place";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AuditTask() {
  const [newAuditTask, setNewAuditTask] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  let { taskId } = useParams();
  const [role, setRole] = useState("");
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTask, setCurrentTask] = useState(undefined);

  const handleClickOpen = (taskId) => {
    setCurrentTask(taskId);
    setDialogOpen(true);
  };

  const handleClickClose = () => {
    setCurrentTask(undefined);
    setDialogOpen(false);
  };

  const AcceptTask = async (taskId) => {
    // try {
    //   const response = await axoisCustomAgent.put(
    //     // `/AandE/AcceptAuditTask/${taskId}`,
    //   );
    //   if (response.status === 500) {
    //     throw new Error("Server error");
    //   }
    //   const { statusCode, message } = response.data;
    //   if (statusCode !== 200) {
    //     throw new Error(message);
    //   }
    //   enqueueSnackbar(message, {
    //     variant: "success",
    //   });
    //     DeleteTask(taskId);
    // } catch (error) {
    //   enqueueSnackbar(error.message, { variant: "error" });
    // }
  };

  const OpenAuditGrading = (taskId) => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = newAuditTask.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "School URN",
      selector: (row) => row.school.urn,
    },

    {
      name: "Audit Date",
      selector: (row) => row.dateOfAudit.split("T")[0],
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },

    {
      name: "Validation Doc.",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(row.isValidationDocumentSent ? "Sent" : "Not Sent")}
        </Label>
      ),
      //   selector: (row) => row.email,
    },
    {
      cell: (row) => (
        <>
          {/* <Button variant="contained" onClick={() => OpenAuditGrading(row.id)}>
            Start
            
          </Button> */}
          <Button variant="contained" onClick={() => handleClickOpen(row.id)}>
            {" "}
            Start
          </Button>
        </>
      ),
    },
    // {
    //   name: "Tools",
    //   cell: (row) => <MoreMenuButton />,
    //   // selector: (row) => row.status,
    // },
  ];

  useEffect(() => {
    let unmounted = false;
    const getAllNewTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AuditClerk/GetAuditTasks?status=${1}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setNewAuditTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllNewTask();
    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Typography variant="h4">Clerk Dashboard</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          minWidth: "100%",
        }}
      >
        <Typography variant="h4">Task</Typography>
      </Box>

      <Box>
        <Card
          sx={{
            minWidth: "80%",
          }}
        >
          <Grid p={1}>
            {newAuditTask.length === 0 ? (
              <Typography>No records found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={newAuditTask}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Allow SSLAG to access your location
        </DialogTitle>
        <DialogContent>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <LocationPin
              style={{
                color: "#FF0000",
                width: "40px",
                marginTop: 3,
              }}
            />
          </Box>
          <DialogContentText id="alert-dialog-description">
            You need to be within 300 meters radius of the location of the
            school before the you can start the audit.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose}>No</Button>
          <Button onClick={() => OpenAuditGrading(currentTask)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AuditTask;
