import _mock from "./_mock";
import { randomInArray } from "./funcs";
import Logo from "src/components/Logo";

// ----------------------------------------------------------------------

export const _carouselsExample = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.text.title(index),
  image: _mock.image.feed(index),
  description: _mock.text.description(index),
}));

export const _carouselsMembers = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
  avatar: `https://minimal-assets-api.vercel.app/assets/images/members/member-${
    index + 1
  }.jpg`,
}));

// ----------------------------------------------------------------------

export const _invoice = {
  id: `${Date.now()}`,
  taxes: 5,
  discount: 10,
  status: "paid",
  invoiceFrom: {
    name: _mock.name.fullName(1),
    address: _mock.address.fullAddress(1),
    company: _mock.company(1),
    email: _mock.email(1),
    phone: _mock.phoneNumber(1),
  },
  invoiceTo: {
    name: _mock.name.fullName(2),
    address: _mock.address.fullAddress(2),
    company: _mock.company(2),
    email: _mock.email(2),
    phone: _mock.phoneNumber(2),
  },
  items: [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.text.title(index),
    description: _mock.text.description(index),
    qty: 5,
    price: _mock.number.price(index),
  })),
};

// ----------------------------------------------------------------------
// <a href='https://schoolrun.com.ng/sslag/'>sample<a/>



const FAQS = [
  {
    title:
      "I am a stakeholder (e.g School,Teacher, Pupil, Parent, Assessor, Supplier, Collaborator, Subject Matter Experts (SME) but not sure how SSLAG affects me or what the benefits are? ",
    details: `There are specific implications for stakeholders. Please, visit the Lagos State Safety Commission (LSC) website www.lasgsafety.com/sslag , for more information or contact the SSLAG Team at: safetycommission@lagosstate.gov.ng sslag@schoolrun.com.ng`,
    },
    {
      title:
        "How do I confirm onboarding onto the SSLAG digital application (SSLAG- DAPP)?",
      details: `There is plenty of information about SSLAG here: or you can contact the SSLAG Team at: sslag@schoolrun.com.ng,

            sslag@schoolrun.com.ng, or Office of the Director General/ CEO Lagos State Safety Commission (LSC): Block 18 B, 2nd & 3rd floor, The Secretariat, Alausa Ikeja, Lagos, Nigeria.`,
    },

  {
    title:
      " What is the least number of delegates are expected to attend from each school?",
    // details: <img src={Logo} alt="" />,
    details:
      " The MS (Minimum Standard) for getting a satisfactory (S) rating is 25% of the total staff population for the CBC and INSET is for all members of staff but this can be achieved over a period of time. But for BOS it must be over 75% of the Staff for the CBC",
  },
  {
    title:
      " What do I do with the notification email and unique reference number?",
    details:
      "The notification email will be sent to the email address that was used to sign-up on the SSLAG portal. The email will contain a list of additional information and/or the specific steps that should be taken to satisfy the compliance requirements for the SSLAG Framework",
  },

  {
    title: "Parents",
    details:
      "Parents are expected to join the Safe School Parents Watch Network (SSPWN) and download SSLAG App to monitor the performance of their children’s school in respect to safety. This will ensure parents are put in the driver’s seat on decisions regarding the safety of their children in schools. Parents are also obliged to proactively check for certain evidence of Safety Management Systems (SMS) such as SSLAG certificate of fitness in school, SSLAG Capacity Building Conference (CBC)attendance, SSLAG Compliance Insurance Certificate and other safety infrastructure. The membership of the SSPWN will avail parents of information and guidelines about their role in SSLAG. Safe School parents’ conferences are periodically scheduled to bring parents up to date on developments in the sector",
  },
  {
    title: "Schools",
    details:
      "Onboarding of schools on the SSLAG digital platform is mandatory. The annual renewal of schools’ operating license by the Ministry of Education is linked to compliance with all SSLAG requirements. As such, schools are expected to contact the SSLAG Team and commence the compliance journey. The Safe School Alliance (SSA) membership and members offer tremendous support to schools and you can find out about the SSA from the SSLAG Team. From the 1st of September 2022, it is the responsibility of the schools to ensure that the students come to school feeling safe and welcomed. It is essential to keep the school environment and the surroundings safe and secure. Preemployment background checks for staff must be conducted. There are a lot more guidelines that schools must follow, as such it is important to get started immediately. For more information, please contact the SSLAG Team at: sslag@schoolrun.com.ng or visit the Office of the Director General/ CEO Lagos State Safety Commission (LSC): Block 18 B, 2nd & 3rd floor, The Secretariat, Alausa, Ikeja, Lagos, Nigeria",
  },

  {
    title: "Collaborators",
    details:
      "Student safety and school security are a rising concern with increased cases of kidnapping and child abuse. In the past 8 years, the Lagos State Safety Commission (LSC) has been looking at implementing guidelines that emphasise the importance of school security and student safety. The LSC was inaugurated on the 5th of May 2009 as an office of Public Safety under the Ministry of Special Duties to set safety standards for all sectors involved in socio-economic activities in the state.It was signed into law on the 26th of July 2011; hence, charged with the duty of ensuring safety of lives and properties. Upon this background, SSLAG was established and the SSLAG Framework consists of 4 Key Elements on safety management and how to ensure safety of children in school and on school buses, among others.This calls for a proactive collaboration on establishing and monitoring safety procedures applicable to infrastructure, resources, transport, as well as the emotional and physical well-being of children to ensure a safe learning environment. Therefore, Subject Matter Experts, Corporate Nigeria, Consultants, Contractors, Manufacturers of goods and services, Safety Experts and Friends of Education now have the unique opportunity to present their products, services and/or initiatives on the SSLAG marketplace. Being listed on the SSLAG marketplace is a mark of safety quality, which should add tremendous value to your organisation’s bottom-line. To find out how you can get involved and support the Safe School Lagos, please contact the Safe School Alliance on: members@pie2you.com or the SSLAG Team on: sslag@schoolrun.com.ng.",
  },
  {
    title: "Other stakeholders",
    details:
      "Please visit the SSLAG digital platform or the Lagos State Safety Commission website for further information",
  },
  {
    title: "How do I confirm that I have been fully onboarded onto SSLAG?",
    details:
      "Once you have completed the online application form (click here), you will receive an instant email notification of a successful onboarding onto the Safety Management System (SMS) with a unique pin and further guidelines.",
  },

  {
    title:
      "How often will my establishment be safety-audited and why is safety audit necessary?",

    details:
      "Safety audit is undertaken annually except in unique circumstances where gross failings linkedagainst the SSLAG requirements have been found either in a previous audit exercise or when complaints have been sent to the LSC / SSLAG Team. This could be a one-off site inspection or series of spot checks.In addition, safety audit is necessary because it is now a requirement for obtaining the Ministry of Education approval for schools or for the annual renewal of approval.",
  },

  {
    title:
      "What is the focus of a safety audit and how can my establishment attain the best possible outcome?",

    details:
      "Schools and allied establishments will be assessed against the four Key Elements of the SSLAG Framework as shown in Table 1 and these remain the cardinal focus of each safety audit exercise.",
  },
  {
    title: "How do I know what to do to excel on the audit exercise?",
    details:
      "The SSLAG Team and its partners run a regular face-to-face training and workshop events to support every aspect of the audit criteria / SSLAG Framework (Table 1). There are also eLearning modules accessible through the SSLAG App and these become available to stakeholders once fully onboarded.Details of the training schedule can be obtained by emailing the SSLAG Team: sslag@schoolrun.com.ng",
  },
  {
    title:
      "What is the procedure, rules and regulations guiding the implementation of SSLAG?",
    details:
      " There is a Compliance Manual and SSLAG Quick Guide Handbook that is available on the SSLAG Marketplace.",
  },
  {
    title: "How does SSLAG regulate schools’ hostels?",
    details:
      "There are eight minimum safety standards under the SSLAG Framework to regulate hostels.",
  },
  {
    title:
      "I am a safety expert and will like to be involved in School Safety Audit, how do I get started?",
    details:
      "All enquiries regarding becoming a safety assessors should be directed to: Office of the Director General/ CEO Lagos State Safety Commission (LSC): Block 18 B, 2nd & 3rd floor, The Secretariat, Alausa, Ikeja, Lagos, Nigeria.",
  },
  {
    title:
      "I am a registered School Safety Assessor with LSC, how does SSLAG affect me?",
    details:
      "Assessors must be onboarded onto the SSLAG platform and attend the relevant knowledge transfer events. Please contact the SSLAG Team: sslag@schoolrun.com.ng for more information.",
  },
  {
    title:
      "How does SSLAG address insecurity issues in Schools and allied establishments?",

    details:
      " Keeping our children safe in schools is everybody’s business. Therefore, members of the public, education stakeholders, parents and others are encouraged to report any suspicious activities to the LSC or the SSLAG Team. In addition, the Standard No. 11: “Child Protection and Safeguarding” presents a holistic student safety and security requirements for schools that include access control at all entry and exit points, transport management, background screening of teaching and support staff, and Knowledge Transfer to staff and security personnel. The SSLAG digital platform is installed with a geo-enabled SOS button. ",
  },
  {
    title:
      "Is the CBC only for teaching staff or the combination of all staff categories within the College?",

    details:
      "The CBC is for combination of all staff categories within the school in the  following percentages:The MS (Minimum Standard) for getting a satisfactory (S) rating is 25% of the total staff population for the CBC and INSET is for all members of staff but this can be achieved over a period of time. But for BOS it must be over 75% of the Staff for the CBC",
  },
  {
    title:
      " Is there a fee attached to the Conference?",

    details:
      "The CBC is N15,000.00 per head or at a 25% discount rate for schools with a valid Safe School Alliance membership certificates. Contact Partners in Education for more details about this. Call SSLAG on 0803 221 222",
  },
  {
    title:
      " Some staff attended the First Aiders Specialist Training before, is this a build-up to the previous training. This is to be sure if a school would need to send another group of attendees if it were otherwise",

    details:
      "members of staff that attended any of the SSLAG Approved first aiders specialist training count towards the compliance level. However, each school needs a minimum of 5 Named first aiders per section of the school and 5 as back up first aiders for the main named ones.:that is, 5 for the academic side and 5 for the hostel and they need the appropriate nos of back up assistants.",
  },
  {
    title:
      " How to contact SSLAG approved vendors for school supplies?",

    details:
      "Visit there SSLAG portal market place on https://safeschoolslagos.ng and check the mandatory supplies page or email info@safeschoolslagos.ng requesting the supplies your school needs and the vendors will be deployed to your schoo.",
  },
  {
    title:
      "What are the other services that SSLAG provides to schools?",

    details:
      "School safety and school improvement consultation services  led by the Lead Resource person, Dr. Bisi Esuruoso,who is also  the Principal Consultant of SchoolRun Academy Venture",
  },
  {
    title:
      " Are there parents safety awareness programmes?",

    details:
      " SSLAG has a platform for parents, where we hold the Parents Hangout, once every month.  Click here for more information on how to send the comprehensive list of your parents' phone numbers  so they can be added and then participate whenever the programme comes up.  You can also invite the SSLAG Implementation Team to your school PTA meeting to educate them about the framework and expectations on Safe Schools Lagos",
  },

];

export const _faqs = FAQS.map((item, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: item.title,
  detail: item.details,
}));
// ----------------------------------------------------------------------

export const _addressBooks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  receiver: _mock.name.fullName(index),
  fullAddress: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  addressType: index === 0 ? "Home" : "Office",
  isDefault: index === 0,
}));

// ----------------------------------------------------------------------

export const _skills = [...Array(3)].map((_, index) => ({
  label: ["Development", "Design", "Marketing"][index],
  value: _mock.number.percent(index),
}));

// ----------------------------------------------------------------------

export const _accordions = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Accordion ${index + 1}`,
  subHeading: _mock.text.title(index),
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _dataGrid = [...Array(36)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  performance: _mock.number.percent(index),
  rating: _mock.number.rating(index),
  status: randomInArray(["online", "away", "busy"]),
  isAdmin: _mock.boolean(index),
  lastName: _mock.name.lastName(index),
  firstName: _mock.name.firstName(index),
  age: _mock.number.age(index),
}));

// ----------------------------------------------------------------------

export const _megaMenuProducts = [...Array(10)].map((_, index) => ({
  name: _mock.text.title(index),
  image: _mock.image.feed(index),
  path: "#",
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(["online", "offline", "away", "busy"]),
  position: _mock.role(index),
}));

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    "Your order is placed",
    "Sylvan King",
    "You have new message",
    "You have new mail",
    "Delivery processing",
  ][index],
  description: [
    "waiting for shipping",
    "answered to your comment on the Minimal",
    "5 unread messages",
    "sent from Guido Padberg",
    "Your order is being shipped",
  ][index],
  avatar: [null, _mock.image.avatar(2), null, null, null][index],
  type: [
    "order_placed",
    "friend_interactive",
    "chat_message",
    "mail",
    "order_shipped",
  ][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));

// ----------------------------------------------------------------------

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.address.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.address.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];
