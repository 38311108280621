import { Grid, Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { MandatoryData } from "../../_mock/data-mock";
import styled from "styled-components";

function MandatorySupplies() {
  const [mandatory, setMandatory] = useState(MandatoryData);

  const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
  `;

  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 28px;
  `;
  const TH = styled.th`
    background-color: rgb(117, 201, 250);
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;

  const TD = styled.td`
    background-color: rgb(205, 235, 253);
    font-size: small;
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
  `;








  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Box paddingRight={8}>
          {/* <Button
            href="/dashboard/user/documentaryEvidence"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Safety budget
          </Button>
          <Button
            href="/dashboard/user/certificateParticipation"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Certificate of participation
          </Button>

          <Button
            href="/dashboard/user/safetyInfrastructure"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safety infrastructure
          </Button> */}

          <Button
            href="/dashboard/user/manadatorySupplies"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Mandatory supplies
          </Button>
          {/* <Button
            href="/dashboard/user/safeInternet"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safe internet
          </Button>
          <Button
            href="/dashboard/user/insurance"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Insurance
          </Button>
          <Button
            href="/dashboard/user/signages"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Signages
          </Button> */}
        </Box>
      </Box>

      <Grid>
        <Box>
          <Typography variant="h3" textAlign={"center"}>
            SSLAG MANDATORY SUPPLIES
          </Typography>
        </Box>
        <AppContainer>
          <Table>
            <thead>
              <tr>
                <TH>S/N</TH>
                <TH>Name</TH>
                <TH>Size</TH>
                <TH>Price ( ₦‎)</TH>
                <TH>Image</TH>
              </tr>
            </thead>
            <tbody>
              {mandatory.map((eachData, index) => (
                <tr key={index}>
                  <TD>{eachData.index}</TD>
                  <TD>{eachData.name}</TD>
                  <TD>{eachData.size}</TD>
                  <TD>{eachData.Price}</TD>
                  <TD>
                    {" "}
                    <img src={eachData.image} width="70px" />{" "}
                  </TD>
                </tr>
              ))}
            </tbody>
          </Table>
        </AppContainer>
      </Grid>
    </Grid>
  );
}

export default MandatorySupplies;
