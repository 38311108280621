import React from "react";
import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

function InsuranceList({ brokerArray, deleteBroker }) {
  const { enqueueSnackbar } = useSnackbar();

  const [deleting, setDeleting] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");

  const hanldeDelete = async (index) => {
    setDeleting(true);
    setIndexToDelete(index);

    try {
      await deleteBroker(index); 
      setDeleting(false);
      setIndexToDelete("");

      enqueueSnackbar("Insurance record deleted successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Unable to delete broker record", {
        variant: "error",
      });
    } finally {
      setDeleting(false);
      setIndexToDelete("");
    }
  };

  //==================================
  //=================================

  return (
    <Box mt={5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ width: "25%" }}>Provider's Name</Typography>
        <Typography sx={{ width: "25%" }}>Type of Insurance</Typography>
        <Typography sx={{ width: "25%" }}>Policy No</Typography>
        <Typography sx={{ width: "25%" }}>Insurance details </Typography>
        <Typography sx={{ width: "25%" }}>Upload Docs </Typography>
      </Box>
      <hr />
      <Box mt={2}>
        {brokerArray != null && brokerArray.length > 0 ? (
          brokerArray.map((item, index, array) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {item.hmoName.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.hmoName.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.hmoName}</Typography>
              )}

              {item.insuranceType.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.insuranceType.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>
                  {item.insuranceType}
                </Typography>
              )}

              {item.policyNumber.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.policyNumber.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>
                  {item.policyNumber}
                </Typography>
              )}

              {/* <Typography sx={{ width: "25%" }}>{item.phoneNumber}</Typography> */}

              {item.insuranceDetails.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.insuranceDetails.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>
                  {item.insuranceDetails}
                </Typography>
              )}

              {item.imageUrl !== null && item.imageUrl.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.imageUrl.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.imageUrl}</Typography>
              )}

              <LoadingButton
                loading={deleting && indexToDelete === index}
                onClick={async () => await hanldeDelete(index)}
              >
                <DeleteForeverIcon />
              </LoadingButton>
            </Box>
          ))
        ) : (
          <Typography>Add a broker</Typography>
        )}
      </Box>
    </Box>
  );
}

export default InsuranceList;
