// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
// import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  school: getIcon("school"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  admin: getIcon("admin"),
  key_element: getIcon("element"),
  advocacy: getIcon("advocacy"),
  monitoring: getIcon("monitoring"),
  transfer: getIcon("transfer"),
  support: getIcon("support"),
  E_Wwallet: getIcon("e_wallet"),
  quickLink: getIcon("quick_link"),
  notifications: getIcon("notifications"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Home",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.school,

        // icon: ICONS.dashboard,
      },
    ],
  },

  //NOTIFICATION
  //==================================================
  // {
  //   subheader: "NOTIFICATIONS",
  //   items: [
  //     {
  //       title: "Notification",
  //       path: PATH_DASHBOARD.blog.root,
  //       // path: "",
  //       icon: ICONS.notifications,
  //       children: [
  //         {
  //           title: "Pending Alert",
  //           path: PATH_DASHBOARD.blog.pending,
  //         },
  //         // {
  //         //   title: "Inbox",
  //         //   path: PATH_DASHBOARD.blog.appNewinvoice,
  //         // },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   subheader: "Message",

  //   items: [
  //     {
  //       title: "Message ",
  //       path: "/coming-soon",
  //       // path: "dashboard",
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,

  //       children: [
  //         {
  //           title: "Intervention Mail",
  //           path: PATH_DASHBOARD.mail.root,
  //         },
  //         {
  //           title: "Support Mail",
  //           // path: PATH_DASHBOARD.mail.root,
  //           path: PATH_DASHBOARD.mail.supportmail,
  //         },
  //       ],
  //     },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  {
    subheader: "COMPLIANCE MONITORING (CM)",
    items: [
      // MANAGEMENT : USER
      {
        title: "Compliance Monitoring",
        path: PATH_DASHBOARD.user.root,
        // path: "",
        icon: ICONS.user,

        children: [
          {
            title: "School Management",
            path: PATH_DASHBOARD.user.staff,

            children: [
              { title: "Class Management", path: PATH_DASHBOARD.user.class },
              { title: "Staff Management", path: PATH_DASHBOARD.user.staff },
              {
                title: "Student Management",
                path: PATH_DASHBOARD.user.student,
              },
              {
                title: "Behaviour Management",
                path: PATH_DASHBOARD.user.attendance,
              },
            ],
          },

          // {
          //   title: "Framework information",
          //   path: PATH_DASHBOARD.user.quickGuide,
          //   children: [
          //     {
          //       title: "Protocols & Guidance",
          //       path: PATH_DASHBOARD.user.protocolGuidance,
          //     },
          //     {
          //       title: "Quick guide",
          //       path: PATH_DASHBOARD.user.quickGuide,
          //     },

          //     {
          //       title: "Minimum standard",
          //       path: PATH_DASHBOARD.user.miniStandard,
          //     },
          //     {
          //       title: "Documentary evidence & mandatory support",
          //       path: PATH_DASHBOARD.user.manadatorySupplies,
          //     },

          //     {
          //       title: "Terms & Glossary",
          //       path: "/coming-soon",
          //       // path: PATH_DASHBOARD.user.termsGlossary,
          //     },
          //     {
          //       title: "Implementation team info & support ",
          //       // path: PATH_DASHBOARD.user.projectImplementation,
          //       path: "/coming-soon",
          //     },
          //   ],
          // },
          {
            title: "Compliance manual",
            path: PATH_DASHBOARD.user.protocolGuidance,
            children: [
              {
                title: "Framework Information",
                // path: PATH_DASHBOARD.user.sms
                path: "/coming-soon",

                children: [
                  {
                    title: "Protocols & Guidance",
                    path: PATH_DASHBOARD.user.protocolGuidance,
                  },
                  {
                    title: "Quick guide",
                    path: PATH_DASHBOARD.user.quickGuide,
                  },

                  {
                    title: "Minimum standard",
                    path: PATH_DASHBOARD.user.miniStandard,
                  },
                  {
                    title: "Documentary evidence & mandatory support",
                    path: PATH_DASHBOARD.user.manadatorySupplies,
                  },

                  {
                    title: "Terms & Glossary",
                    path: "/coming-soon",
                    // path: PATH_DASHBOARD.user.termsGlossary,
                  },
                  {
                    title: "Implementation team info & support ",
                    // path: PATH_DASHBOARD.user.projectImplementation,
                    path: "/coming-soon",
                  },
                ],
              },
              {
                title: "Checklists ",
                path: PATH_DASHBOARD.user.checklist,
                // children: [

                // {
                //   title: "Health and safety checklist",
                //   path: PATH_DASHBOARD.user.checklist,
                // },
                // {
                //   title: "Risk assessment",
                //   path: PATH_DASHBOARD.user.assessment,
                // },
                // {
                //   title: "Incident/Accident report form",
                //   path: PATH_DASHBOARD.user.incident,
                // },
                // {
                //   title: "Outdoor play equipment safety checklist",
                //   path: PATH_DASHBOARD.user.playequipment,
                // },
                // {
                //   title: "Food vendors safety assessment questionnaire",
                //   path: PATH_DASHBOARD.user.foodvendor,
                // },
                // {
                //   title: " School safety/emergency preparedness plan",
                //   path: PATH_DASHBOARD.user.preparedness,
                // },
                // ],
              },
              {
                title: "Reports  & uploads",
                // path: PATH_DASHBOARD.user.sms
                path: "/coming-soon",
              },

              {
                title: "Applications ",
                // path: PATH_DASHBOARD.user.postAudit,
                path: "/coming-soon",
              },
              // {
              //   title: "Documentary evidence",
              //   path: PATH_DASHBOARD.user.documentaryEvidence,
              // },
            ],
          },

          {
            title: "Compliance Journey ",
            path: PATH_DASHBOARD.user.complianceMail,
            children: [
              // {
              //   title: "Stage 1-3",
              //   path: "/coming-soon",
              //   // path: PATH_DASHBOARD.user.attendance,
              // },
              {
                title: "Evaluation",
                path: "/coming-soon",
                // path: PATH_DASHBOARD.user.attendance,

                children: [
                  {
                    title: "Onboarding",
                    // path: PATH_DASHBOARD.schoolAudit.schoolInitiative,
                    path: "/coming-soon",
                  },
                  {
                    title: "Checkpoint/ self Assessment",
                    path: "/coming-soon",
                  },
                  {
                    title: "Walkthrough (SMS)",
                    path: "/coming-soon",
                  },
                ],
              },
              {
                title: "Safety Audit",
                path: "/coming-soon",
                // path: PATH_DASHBOARD.user.attendance,

                children: [
                  {
                    title: "Pre Audit",
                    path: PATH_DASHBOARD.schoolAudit.schoolInitiative,
                  },
                  {
                    title: "Audit Day",
                    path: "/coming-soon",
                    // path: PATH_DASHBOARD.schoolAudit.SchoolAuditcongrate,
                  },
                  {
                    title: "Post Audit",
                    path: "/coming-soon",
                  },
                ],
              },
            ],
          },
        ],
      },

      ///=========================================

      // {
      //   subheader: "ADVOCACY",
      //   items: [
      //     {
      //       title: "Advocacy",
      //       path: PATH_DASHBOARD.blog.root,
      //       // path: "",
      //       icon: ICONS.user,
      //       children: [
      //         {
      //           title: "SSLAG Advocacy Event",
      //           path: PATH_DASHBOARD.blog.posts,
      //         },
      //         {
      //           title: "School Advocacy Event",
      //           path: PATH_DASHBOARD.blog.postById,
      //         },
      //       ],
      //     },
      //     // {
      //     //   title: "Reports",
      //     //   path: PATH_DASHBOARD.general.analytics,
      //     //   // path: "dashboard",
      //     //   icon: ICONS.analytics,
      //     // },
      //   ],
      // },

      // {
      //   subheader: "KNOWLEDGE TRANSFERS",
      //   items: [
      //     {
      //       title: "Facilities",
      //       // path: PATH_DASHBOARD.eCommerce.root,
      //       path: "",
      //       icon: ICONS.cart,
      //       children: [
      //         {
      //           title: "SSLAG Competency Requirements",
      //           path: PATH_DASHBOARD.eCommerce.shop,
      //         },
      //         {
      //           title: "Booking Form",
      //           path: PATH_DASHBOARD.eCommerce.productById,
      //         },
      //         {
      //           title: "Knowledge Transfer calender",
      //           path: PATH_DASHBOARD.eCommerce.list,
      //         },
      //         {
      //           title: "Online E-Learning",
      //           path: PATH_DASHBOARD.eCommerce.newProduct,
      //         },
      //         // { title: "edit", path: PATH_DASHBOARD.eCommerce.editById },
      //         // { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
      //         // { title: "invoice", path: PATH_DASHBOARD.eCommerce.invoice },
      //       ],
      //     },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: "personnel",
      //   path: PATH_DASHBOARD.blog.root,
      //   // path: "",
      //   icon: ICONS.user,
      //   children: [
      //     { title: "posts", path: PATH_DASHBOARD.blog.posts },
      //     { title: "post", path: PATH_DASHBOARD.blog.postById },
      //     { title: "new post", path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
      // {
      //   title: "Reports",
      //   path: PATH_DASHBOARD.general.analytics,
      //   // path: "dashboard",
      //   icon: ICONS.analytics,
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: "ADVOCACY",

    items: [
      {
        title: "Advocacy",
        // path: PATH_DASHBOARD.blog.root,
        path: "/coming-soon",
        icon: ICONS.advocacy,
        children: [
          {
            title: "CBC",
            path: PATH_DASHBOARD.blog.schoolEvent,
            // path: "/coming-soon",
          },
          {
            title: "School Advocacy Events",
            // path: PATH_DASHBOARD.blog.postById,
            path: "/coming-soon",
          },
          {
            title: "Bos",

            path: PATH_DASHBOARD.user.ManageBos,
          },
          {
            title: "Others",
            // path: PATH_DASHBOARD.blog.postById,
            path: "/coming-soon",
          },
        ],
      },
      // {
      //   title: "Reports",
      //   path: PATH_DASHBOARD.general.analytics,
      //   // path: "dashboard",
      //   icon: ICONS.analytics,
      // },
    ],
  },

  // path: PATH_DASHBOARD.Schoolaudit.schoolInitiative,
  //-------------------------------------------------------------
  {
    subheader: "KNOWLEDGE TRANSFER",

    items: [
      {
        title: "Knowledge Transfer",
        // path: PATH_DASHBOARD.eCommerce.root,
        path: "/coming-soon",
        icon: ICONS.transfer,
        children: [
          {
            title: "Competency Requirements",
            path: PATH_DASHBOARD.knowledgeTransfer.compReq,

            // path: PATH_DASHBOARD.eCommerce.shop,
          },
          {
            title: "Booking Form",
            // path: "/coming-soon",
            path: PATH_DASHBOARD.knowledgeTransfer.bookingForm,
          },
          {
            title: "Knowledge Transfer calender",
            path: PATH_DASHBOARD.knowledgeTransfer.ktCalender,
            // path: "/coming-soon",

            // path: PATH_DASHBOARD.eCommerce.list,
          },
          {
            title: "Online Courses",
            // path: PATH_DASHBOARD.knowledgeTransfer.ktOnlineCourse,
            path: "/coming-soon",
          },
        ],
      },
    ],
  },

  //----------------------------------------------------------------------------

  {
    subheader: "Events",

    items: [
      {
        title: "Events ",
        path: "/coming-soon",
        // path: "dashboard",
        icon: ICONS.calendar,

        children: [
          {
            title: "Event Calender",
            path: "/coming-soon",
            path: PATH_DASHBOARD.calendar,
          },
          {
            title: "Past / Upcoming Events",
            path: "/coming-soon",
            // path: PATH_DASHBOARD.blog.postById,
          },
          {
            title: "Event Booking",
            path: "/coming-soon",
            //  path: PATH_DASHBOARD.blog.newPost
          },
        ],
        // info: (
        //   <Label variant="outlined" color="error">
        //     +32
        //   </Label>
        // ),
      },
      // {
      //   title: "Past / Upcoming Event",
      //   path: "",
      //   // path: PATH_DASHBOARD.chat.root,
      //   icon: ICONS.chat,
      // },
      // {
      //   title: "Book an Event",
      //   path: PATH_DASHBOARD.calendar,
      //   // path: "dashboard",
      //   icon: ICONS.calendar,
      // },
      // {
      //   title: "Support",
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },

  {
    subheader: "Marketplace",

    items: [
      {
        title: "Marketplace ",
        path: PATH_DASHBOARD.user.manadatorySupplies,

        icon: ICONS.transfer,

        children: [
          {
            title: "mandatory Supplies ",
            path: PATH_DASHBOARD.user.manadatorySupplies,
          },
          {
            title: "Outsource Service",
            path: "/coming-soon",
            // path: PATH_DASHBOARD.user.outsourceService,
          },
          {
            title: " Special Deals",
            path: "/coming-soon",
            // path: PATH_DASHBOARD.user.outsourceService,
          },
        ],
      },
    ],
  },

  {
    subheader: "E-wallet",

    items: [
      {
        title: "E-wallet ",
        path: "/coming-soon",
        // path: "dashboard",
        icon: ICONS.E_Wwallet,
      },
    ],
  },
  {
    subheader: "Quick links",

    items: [
      {
        title: "Quick links ",
        path: "/coming-soon",

        icon: ICONS.quickLink,

        children: [
          {
            title: "booking form",
            path: PATH_DASHBOARD.knowledgeTransfer.bookingForm,
          },
          {
            title: "marketplace",
            path: PATH_DASHBOARD.user.manadatorySupplies,
          },
          {
            title: "Important Dates",
            path: "/coming-soon",
          },
          {
            title: "compliance action",
            path: "/coming-soon",
          },
        ],
      },
    ],
  },
  {
    subheader: "Support",

    items: [
      {
        title: "Support ",
        // path: PATH_DASHBOARD.support.root,
        // path: "/coming-soon",
        // path: "dashboard",
        icon: ICONS.support,

        children: [
          {
            title: "Faqs/Help",
            // path: "/coming-soon",
            path: PATH_DASHBOARD.support.faqHelp,
          },
        ],
      },
    ],
  },
];

export default navConfig;
