import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Stack,
  Alert,
  Checkbox,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import useAuthAdmin from "src/hooks/useAuthAdmin";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { minHeight } from "@mui/system";

function AdminSchoolPolicies({ nextStep, prevStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();
  const { stakeholderCategory, id } = useAuthAdmin();

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");

  const handleChangeCheckBox = (event) => {
    setCheckBoxChecked(event.target.value);
  };

  const defaultValues = {
    accidentPolicy: "",
    incidentPolicy: "",
    endAndStartOfDayPolicy: "",
    OutingPolicy: "",
    firstaidPolicy: "",
    fireDrillPolicy: "",
    parentalPolicy: "",
    visitorsPolicy: "",
    safeguardingPolicy: "",
    staffPolicy: "",
    riskAssessmentPolicy: "",
    MedicationPolicy: "",
    busTransitPolicy: "",
    safetyPolicy: "",
    childPolicy: "",
    recruitmentPolicy: "",
    emergencyPolicy: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  useEffect(() => {
    let unmounted = false;
    const GetSchoolPolicies = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionFiveSafetyPolicies?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetSchoolPolicies();
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = () => {};
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "50%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 5: Safe School policies
                </Typography>
              </Box>

              <Box>
                <Typography fontSize={"13px"} textAlign={"center"}>
                  (TICK IF COMPLIANCE STANDARD IS MET, CONFORMS TO SSLAG
                  RECOMMENDATION OR FRAMEWORK)
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Grid>
                  <Box>
                    <Typography> Accident policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="accidentPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography>Incident policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="incidentPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography>End and start of day policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="endAndStartOfDayPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Outing policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="OutingPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> First aid policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="firstaidPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> fire drills policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="fireDrillPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Parental partnership policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="parentalPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> visitors policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="visitorsPolicy"
                    />
                  </Box>
                  <Box>
                    <Box>
                      <Typography> Safeguarding policy</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="safeguardingPolicy"
                      />
                    </Box>
                    <Typography> staff supervision policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="staffPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Risk Assessment Policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="riskAssessmentPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Medication & Sickness Policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="MedicationPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Bus Transit Policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="busTransitPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Safety policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="safetyPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Child protection policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="childPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography>
                      {" "}
                      Safe recruitment & induction policy
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="recruitmentPolicy"
                    />
                  </Box>
                  <Box>
                    <Typography> Emergency preparedness policy</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="emergencyPolicy"
                    />
                  </Box>

                  {/* ================================================================= */}
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminSchoolPolicies;
