import { Grid, Box, Button } from "@mui/material";
import React from "react";

function IntroductionAcknowledgement() {
  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Box paddingRight={8}>
          <Button
            href="/dashboard/user/protocolGuidance"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Input & Output
          </Button>
          {/* <Button
            // href="/dashboard/user/IntroductionAcknowledgment"
            href="/coming-soon"
            className="header active"
            disabled
            sx={{ color: "#6495ed" }}
          >
            Introduction acknowledgement
          </Button> */}

          <Button
            // href="/dashboard/user/protocols"
            href="/coming-soon"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Guidance assessment
          </Button>

          <Button
            // href="/dashboard/user/sslagEvaluation"
            href="/coming-soon"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            sslag evaluation
          </Button>
          <Button
            // href="/dashboard/user/safetyAudit"
            href="/coming-soon"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safety audit rating
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

export default IntroductionAcknowledgement;
