import {
  Container,
  Grid,
  Stack,
  Alert,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../../images/background.png";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import Iconify from "../../../components/Iconify";
import { useNavigate } from "react-router-dom";
import formatnaira from "format-to-naira";

function PublicSchoolAudit() {
  const AuditSchema = Yup.object().shape({
    cost: Yup.string().required("field  is required"),
    schoolcategory: Yup.string().required("field  is required"),
    schoolName: Yup.string().required("field  is required"),
    email: Yup.string().required("field  is required"),
    password: Yup.string().required("field  is required"),
    confirmPassword: Yup.string().required("field  is required"),

    concession: Yup.string().required("field  is required"),
  });
  useEffect(() => {
    let unmounted = false;
    const getCategory = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolAudit/GetAuditAmountForSchoolTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setSelectCategory([...selectCategory, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getCategory();
    const getAllLscFees = async () => {
      let unmounted = false;

      try {
        const response = await axoisCustomAgent.get(`SchoolAudit/GetLscFee`);
        const { statusCode, message } = response.data;
        const { data } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setLsc([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
      }
    };
    getAllLscFees();

    const getAllFacilities = async () => {
      let unmounted = false;
      setLoadingFacilities(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolAudit/GetAuditAmountForSchoolFacilities`
        );
        const { statusCode, message } = response.data;
        const { data } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        if (!unmounted) {
          let modifiedArray = data.map((eachItem) => {
            const { id, facility, amount } = eachItem;
            return {
              isChecked: false,
              amount,
              facility,
              id,
            };
          });
          setFacilities([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingFacilities(false);
      }
    };
    getAllFacilities();
    return () => {
      unmounted = true;
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [lsc, setLsc] = useState([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [facilities, setFacilities] = useState([]);

  const defaultValues = {
    cost: "",
    schoolcategory: "",
    schoolName: "",
    email: "",
    password: "",
    confirmPassword: "",

  };

  const methods = useForm({
    resolver: yupResolver(AuditSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  let watchSchoolCategory = watch("schoolcategory");

  const { formState } = methods;

  useEffect(() => {
    if (watchSchoolCategory === "") {
      setValue("cost", "");
    } else {
      let cost = selectCategory.find(
        (item) => `${item.id}` === watchSchoolCategory
      ).amount;
      setValue("cost", cost);
    }
    ReCalculateAuditTotal();
  }, [watchSchoolCategory]);

  const handleFacilitiesFormCheck = (e, index) => {
    let newFacilitiesArray = [...facilities];
    newFacilitiesArray[index].isChecked = e.target.checked;
    newFacilitiesArray[index].quantity = e.target.checked ? 1 : 0;
    setFacilities([...newFacilitiesArray]);
    ReCalculateAuditTotal();
  };
  const [selectCategory, setSelectCategory] = useState([
    {
      id: "",
      schoolTypeId: "",
      schoolTypeName: "select school catergory",
      amount: "",
    },
  ]);

  const [selectCost, setSelectCost] = useState([
    {
      id: "",
      amount: "",
    },
  ]);

  const [costValue, setCostValue] = useState();
  const [auditTotal, setAuditTotal] = useState(0);

  const ReCalculateAuditTotal = () => {
    let costOfSchoolCategory = watch("cost");
    let costOfAllSelectedFacilities = 0;
    facilities
      .filter((x) => x.isChecked)
      .forEach((item) => {
        costOfAllSelectedFacilities += item.amount;
      });
    let totalAuditAmount = costOfSchoolCategory + costOfAllSelectedFacilities;
    // console.log({totalAuditAmount})
    setAuditTotal(totalAuditAmount);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `SchoolAudit/BookAuditForPublicSchools`,
        {
          schoolName: data.schoolName,
          email: data.email,
          lscFeeId: lsc[0]?.id,
          password: data.password,
          confirmPassword: data.confirmPassword,
          schoolTypeId: data.schoolcategory,
          // schoolFacilities: facilities.filter((x) => x.isChecked),
         
        }
      );

      if (response.status === 400) {
        throw new Error("Something went wrong");
      }

      const { userId } = response.data.data;

      enqueueSnackbar("Audit submit successfully", { variant: "success" });
      methods.reset();
      navigate(`/bookAuditinvoice/${userId}`, { replace: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // if (loadingFacilities) {
  //   return (
  //     <Box justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
  //       <Box>
  //         <Typography>Loading...</Typography>
  //       </Box>
  //     </Box>
  //   );
  // }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "50%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>
            <Box textAlign={"center"}>
              <Typography
                variant="h5"
                sx={{
                  color: "#00B000",

                  mt: 2,
                  p: 2,
                }}
              >
                SCHOOL SAFETY AUDIT REQUEST FORM
              </Typography>
            </Box>

            <Box>
              <Typography>Concession</Typography>
              <Typography fontSize={"13px"}>
                There is a concession given to non fee paying schools. If your
                school falls within this category, Kindly contact{" "}
                <i>
                  <a href="audit@safeschoolslagos.ng">
                    audit@safeschoolslagos.ng
                  </a>
                  {/* audit@safeschoolslagos.ng{" "} */}
                </i>
                and request for a voucher. This is subject to verification at
                the discretion of SSLAG Please provide your concession token
                below to proceed xxxxxxxxxx
              </Typography>

              <Box m={2} width={300}>
                <RHFTextField
                  name="concession"
                  label="Concession Token"
                  variant="filled"
                />
              </Box>
            </Box>

            <Grid textAlign={"right"} p={2}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                submit
              </LoadingButton>
            </Grid>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default PublicSchoolAudit;
