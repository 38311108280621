import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Avatar,
} from "@mui/material";
import { SeoIllustration } from "../../../../assets";
// import {
//   AppWidget,

// } from "../../sections/@dashboard/general/app";
import { AppWidget } from "../../../../sections/@dashboard/general/app";
// import { StatTimer } from "pdfjs-dist/types/src/display/display_utils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string,
};

export default function AppWelcome({
  displayName,
  schoolpin,
  openingTime,
  closingTime,
  profileImageUrl,
  schoolDistrict,
}) {
  const currentDate = openingTime?.split("T")[1];
  const displayTime = currentDate?.split(":");

  // console.log(displayTime)

  const closingDate = closingTime?.split("T")[1];
  const displayClosingTime = closingDate?.split(":");

  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: "grey.800",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography gutterBottom variant="h6" mt={2}>
            Welcome to SSLAG portal,
            <br /> {!displayName ? "..." : displayName}!
          </Typography>

          <Avatar
            alt=""
            src={profileImageUrl}
            sx={{ width: 130, height: 130, mt: 5 }}
          />
        </Box>

        <Box variant="body2" marginLeft={30} mt={6}>
          <Typography variant="h7" color={"#6495ed"}>
            Unique Reference Number(URN)
          </Typography>
          <Typography variant="h3" color="GrayText">
            {schoolpin}
          </Typography>
          <Box mt={2}>
            <Typography color={"#6495ed"}> School District</Typography>
            <Typography variant="h5" color="GrayText">
              {schoolDistrict}
            </Typography>
          </Box>
          <Typography mt={3} color={"#6495ed"} variant="h7">
            School Opening Hour
          </Typography>
          <Box display={"flex"} flexDirection={"row"} color="GrayText">
            {!displayTime ? null : (
              <Typography variant="h5">
                {`${displayTime[0]}:${displayTime[1]}`}am
              </Typography>
            )}
            <Typography variant="h5">--</Typography>
            {!displayClosingTime ? null : (
              <Typography variant="h5">
                {`${displayClosingTime[0]}:${displayClosingTime[1]}`}pm
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </RootStyle>
  );
}
