import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  AppBar,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../components/Iconify";
// components
import { MotionContainer } from "../../../components/animate";
import styleds from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import React from "react";
import Label from "../../../components/Label";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../components/hook-form";
import CloseIcon from "@material-ui/icons/Close";
import { useParams } from "react-router-dom";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const TextField = styleds.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styleds(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

// ----------------------------------------------------------------------

export default function AdminUpcomingEvent() {
  const [event, setAllEvent] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const [eventAttendance, setAllEventAttendence] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const theme = useTheme();
  let { eventId } = useParams();
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  useEffect(() => {
    let unmounted = false;
    // console.log(currentEvent);
    console.log(eventId);
    const getAllUpcomingEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Events/GetUpcomingEvents`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllEvent([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getAllUpcomingEvent();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    const getAllAttendees = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Events/GetAttendees?eventId=${currentEvent}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllEventAttendence([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (currentEvent !== undefined) getAllAttendees();

    return () => {
      unmounted = true;
    };
  }, [currentEvent]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "Event Name",
      selector: (row) => row.name,
    },

    {
      name: "Description",
      selector: (row) => row.description,
    },

    {
      name: "Venue",
      selector: (row) => row.venue,
    },
    {
      name: "Date ",
      selector: (row) => row.date.split("T")[0],
      width: "80px",
      // .split("T")[0]
    },

    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            // onClick={() => handleOpen(row.id)}
            onClick={() => handleClickOpen(row.id)}
          >
            Attendee
          </Button>
        </>
      ),
    },
  ];

  //view registerd attendee
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setCurrentEvent(id);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentEvent(undefined);
    setOpen(false);
  };

  const customStylesAttendee = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };
  const filteredItems = eventAttendance.filter(
    (item) =>
      item.identification &&
      item.identification.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columnsAttendee = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "Fullname",
      selector: (row) => row.fullName,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Register As",
      selector: (row) => row.registeredAs,
    },
    {
      name: "Id ",
      selector: (row) => row.identification,
      // .split("T")[0]
    },
  ];
  // ==========================================
  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Upcoming Event...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%", minWidth: "100%" }}
      >
        <Grid>
          <Box>
            {event.length === 0 ? (
              <Typography textAlign={"center"}>No Event</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={event}
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Box>
        </Grid>

        <Box>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <Box sx={{ position: "relative" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Registered Attendee
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
                  save
                </Button> */}
            </Box>
            <Box>
              <Grid>
                <Box>
                  {eventAttendance.length === 0 ? (
                    <Typography variant="h4" textAlign={"center"}>
                      No Records
                    </Typography>
                  ) : (
                    <DataTable
                      columns={columnsAttendee}
                      // data={eventAttendance}
                      data={filteredItems}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      pagination
                      paginationResetDefaultPage={resetPaginationToggle}
                      responsive
                      customStyles={customStylesAttendee}
                    />
                  )}
                </Box>
              </Grid>
            </Box>
          </Dialog>
        </Box>
      </Container>
    </RootStyle>
  );
}
