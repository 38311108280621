import {
  Grid,
  Container,
  Box,
  Typography,
  Alert,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

function SchoolTransitSystem({ nextStep, prevStep, auditId, schoolId }) {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const defaultValues = {
    numberOfVehicleUsed: "",
    remarksOnNumberOfVehicleUsed: "",
    typeOfVehiclesUsed: "",
    remarksOnTypeOfVehiclesUsed: "",
    numberOfDriversEmployed: "",
    remarksOnNumberOfDriversEmployed: "",
    inductionEvidenceSeen: "",
    remarksOnInductionEvidenceSeen: "",
    DriversLicenses: "",
    remarksOnLicenseHeldByDrivers: "",
    driversAge: "",
    remarksOnDriversAge: "",
    driverSsCertOfAttendance: "",
    remarksOnDriverSsCertOfAttendance: "",
    confidentialRecordOfPupils: "",
    remarksOnConfidentialRecordOfPupils: "",
    isSchoolBusRulesOnBoard: "",
    remarksOnIsSchoolBusRulesOnBoard: "",
    evidenceOfRollCallOnBoard: "",
    remarksOnEvidenceOfRollCallOnBoard: "",
    isThereAdditionalStaffAssistingDriver: "",
    remarksOnIsThereAdditionalStaffAssistingDriver: "",
    isSeatBeltPresentOnAllSeat: "",
    remarksOnIsSeatBeltPresentOnAllSeat: "",

    isThereApprovedFirstAidOnBoard: "",
    remarksOnIsThereApprovedFirstAidOnBoard: "",
    areVehiclesInGoodCondition: "",
    remarksOnAreVehiclesInGoodCondition: "",
    areVehiclesParticularsInOrder: "",
    remarksOnAreVehiclesParticularsInOrder: "",
    vehicleMaintenanceSighted: "",
    remarksOnVehicleMaintenanceSighted: "",
    areDefinedRoutesAvailableOnBoard: "",
    remarksOnAreDefinedRoutesAvailableOnBoard: "",
    isThereTelephoneForContactingParents: "",
    remarksOnIsThereTelephoneForContactingParents: "",
    doesTelephoneHaveAirtime: "",
    remarksOnDoesTelephoneHaveAirtime: "",
    isPeriodicBusEmergencyDrillConducted: "",
    remarksOnIsPeriodicBusEmergencyDrillConducted: "",
    isThereOverloading: "",
    remarksOnIsThereOverloading: "",
    isThereAppropriateSeatForYoungChildren: "",
    remarksOnIsThereAppropriateSeatForYoungChildren: "",
    areChildrenSeatedInFront: "",
    remarksOnAreChildrenSeatedInFront: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

 
  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionEightSchoolTransit`,
        {
          auditTaskId: taskId,
          numberOfVehicleUsed: dataToSubmit.numberOfVehicleUsed,
          remarksOnNumberOfVehicleUsed:
            dataToSubmit.remarksOnNumberOfVehicleUsed,
          typeOfVehiclesUsed: dataToSubmit.typeOfVehiclesUsed,
          remarksOnTypeOfVehiclesUsed: dataToSubmit.remarksOnTypeOfVehiclesUsed,
          numberOfDriversEmployed: dataToSubmit.numberOfDriversEmployed,
          remarksOnNumberOfDriversEmployed:
            dataToSubmit.remarksOnNumberOfDriversEmployed,
          inductionEvidenceSeen: dataToSubmit.inductionEvidenceSeen,
          remarksOnInductionEvidenceSeen:
            dataToSubmit.remarksOnInductionEvidenceSeen,
          licenseHeldByDrivers: dataToSubmit.licenseHeldByDrivers,
          remarksOnLicenseHeldByDrivers:
            dataToSubmit.remarksOnLicenseHeldByDrivers,
          driversAge: dataToSubmit.driversAge,
          remarksOnDriversAge: dataToSubmit.remarksOnDriversAge,
          driverSsCertOfAttendance: dataToSubmit.driverSsCertOfAttendance,
          remarksOnDriverSsCertOfAttendance:
            dataToSubmit.remarksOnDriverSsCertOfAttendance,
          confidentialRecordOfPupils: dataToSubmit.confidentialRecordOfPupils,
          remarksOnConfidentialRecordOfPupils:
            dataToSubmit.remarksOnConfidentialRecordOfPupils,
          isSchoolBusRulesOnBoard: dataToSubmit.isSchoolBusRulesOnBoard,
          remarksOnIsSchoolBusRulesOnBoard:
            dataToSubmit.remarksOnIsSchoolBusRulesOnBoard,
          evidenceOfRollCallOnBoard: dataToSubmit.evidenceOfRollCallOnBoard,
          remarksOnEvidenceOfRollCallOnBoard:
            dataToSubmit.remarksOnEvidenceOfRollCallOnBoard,
          isThereAdditionalStaffAssistingDriver:
            dataToSubmit.isThereAdditionalStaffAssistingDriver,
          remarksOnIsThereAdditionalStaffAssistingDriver:
            dataToSubmit.remarksOnIsThereAdditionalStaffAssistingDriver,
          isSeatBeltPresentOnAllSeat: dataToSubmit.isSeatBeltPresentOnAllSeat,
          remarksOnIsSeatBeltPresentOnAllSeat:
            dataToSubmit.remarksOnIsSeatBeltPresentOnAllSeat,
          isThereApprovedFirstAidOnBoard:
            dataToSubmit.isThereApprovedFirstAidOnBoard,
          remarksOnIsThereApprovedFirstAidOnBoard:
            dataToSubmit.remarksOnIsThereApprovedFirstAidOnBoard,
          areVehiclesInGoodCondition: dataToSubmit.areVehiclesInGoodCondition,
          remarksOnAreVehiclesInGoodCondition:
            dataToSubmit.remarksOnAreVehiclesInGoodCondition,
          areVehiclesParticularsInOrder:
            dataToSubmit.areVehiclesParticularsInOrder,
          remarksOnAreVehiclesParticularsInOrder:
            dataToSubmit.remarksOnAreVehiclesParticularsInOrder,
          vehicleMaintenanceSighted: dataToSubmit.vehicleMaintenanceSighted,
          remarksOnVehicleMaintenanceSighted:
            dataToSubmit.remarksOnVehicleMaintenanceSighted,
          areDefinedRoutesAvailableOnBoard:
            dataToSubmit.areDefinedRoutesAvailableOnBoard,
          remarksOnAreDefinedRoutesAvailableOnBoard:
            dataToSubmit.remarksOnAreDefinedRoutesAvailableOnBoard,
          isThereTelephoneForContactingParents:
            dataToSubmit.isThereTelephoneForContactingParents,
          remarksOnIsThereTelephoneForContactingParents:
            dataToSubmit.remarksOnIsThereTelephoneForContactingParents,
          doesTelephoneHaveAirtime: dataToSubmit.doesTelephoneHaveAirtime,
          remarksOnDoesTelephoneHaveAirtime:
            dataToSubmit.remarksOnDoesTelephoneHaveAirtime,
          isPeriodicBusEmergencyDrillConducted:
            dataToSubmit.isPeriodicBusEmergencyDrillConducted,
          remarksOnIsPeriodicBusEmergencyDrillConducted:
            dataToSubmit.remarksOnIsPeriodicBusEmergencyDrillConducted,
          isThereOverloading: dataToSubmit.isThereOverloading,
          remarksOnIsThereOverloading: dataToSubmit.remarksOnIsThereOverloading,
          isThereAppropriateSeatForYoungChildren:
            dataToSubmit.isThereAppropriateSeatForYoungChildren,
          areChildrenSeatedInFront: dataToSubmit.areChildrenSeatedInFront,
          remarksOnAreChildrenSeatedInFront:
            dataToSubmit.remarksOnAreChildrenSeatedInFront,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // const Navigate = () => {
  //   navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  // };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "80%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 8: School transit system
                </Typography>
              </Box>

              <Box>
                <Typography fontSize={"15px"} textAlign={"center"}>
                  (COMPLETE THIS SECTION ONLY IF A TRANSIT SYSTEM IS OPERATED
                  FOR TRANSPORTING PUPILS/STUDENT TO AND FROM SCHOOL)
                </Typography>
              </Box>

              <ol>
                <Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Are school transit available</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="numberOfVehicleUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnNumberOfVehicleUsed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Are appropriate type of vehicles used
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="typeOfVehiclesUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnTypeOfVehiclesUsed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Appropriate number of drivers employed
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="numberOfDriversEmployed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnNumberOfDriversEmployed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Evidence of induction seen</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="inductionEvidenceSeen"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnInductionEvidenceSeen"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Are drivers’ licenses seen?</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="DriversLicenses"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnLicenseHeldByDrivers"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Are appropriate drivers age/ages seen
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="driversAge"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDriversAge"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Drivers CBC certificate of attendance seen?
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="driverCbcCertOfAttendance"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDriverSsCertOfAttendance"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Confidential records of pupils available
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="confidentialRecordOfPupils"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnConfidentialRecordOfPupils"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          School bus rules available on board
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isSchoolBusRulesOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsSchoolBusRulesOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Evidence of roll call on board</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfRollCallOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnEvidenceOfRollCallOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>

                  {/* ====== */}
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Additional staff assisting the driver
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereAdditionalStaffAssistingDriver"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereAdditionalStaffAssistingDriver"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Seat belts present on all seats</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isSeatBeltPresentOnAllSeat"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsSeatBeltPresentOnAllSeat"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Approved transit first aid available on board
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereApprovedFirstAidOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereApprovedFirstAidOnBoard "
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Vehicle in good condition of repairs
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areVehiclesInGoodCondition"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreVehiclesInGoodCondition"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Vehicles particulars in order</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areVehiclesParticularsInOrder"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreVehiclesParticularsInOrder"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Maintenance log of vehicles sighted
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="vehicleMaintenanceSighted"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnVehicleMaintenanceSighted"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Routes defined and available on board
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areDefinedRoutesAvailableOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreDefinedRoutesAvailableOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Handset for contacting parents available
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereTelephoneForContactingParents"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereTelephoneForContactingParents"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Handset with appropriate airtime credits
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="doesTelephoneHaveAirtime"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDoesTelephoneHaveAirtime"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Periodic school bus emergency drills conducted
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isPeriodicBusEmergencyDrillConducted"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsPeriodicBusEmergencyDrillConducted"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>Evidence of Overloading seen</Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereOverloading"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereOverloading"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Are appropriate car seat used for young children
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereAppropriateSeatForYoungChildren"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereAppropriateSeatForYoungChildren"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>

                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <li>
                        <Typography>
                          Do children seat in front? (Ask how many children sit
                          in front to determine this).
                        </Typography>
                      </li>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areChildrenSeatedInFront"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreChildrenSeatedInFront"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                {/* ===================================== */}
                <Box display={"flex"} justifyContent={"right"}>
                  <Grid sx={{ p: 3 }}>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      loading={isSubmitting}
                      // onClick={() => handleDirectionChange("forward")}
                      // onClick={Navigate}
                      sx={{ color: "#fff", background: "#018fe2" }}
                      // startIcon={<Back />}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Box>
              </ol>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SchoolTransitSystem;
