import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";

// @mui
import {
  Link,
  Stack,
  Alert,
  Typography,
  IconButton,
  Box,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// routes
import { PATH_AUTH } from "../../../routes/paths";
// import BackgroundImg from "../../../images/b4.jpg";
// hooks
import useAuth from "../../../hooks/useAuth";
// import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, userData } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { emailConfirmed, hasPaidForRegistration, isOnboardingCompleted } =
    userData;

  // console.log({ emailConfirmed, hasPaidForRegistration });

  // const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    stakeholderCategoryId: Yup.string().oneOf(
      ["1", "2", "3", "4", "5", "6", "7", "9", "10"],
      "select a valid category"
    ),
  });

  const defaultValues = {
    email: "",
    password: "",
    stakeholderCategoryId: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const navigate = useNavigate();

  const STAKEHOLDER_OPTIONS = [
    {
      value: "00",
      label: "Select Category",
    },
    {
      value: "1",
      label: "Schools",
    },

    {
      value: "4",
      label: "A&E",
    },
    {
      value: "5",
      label: "Suppliers & Vendors",
    },
    {
      value: "6",
      label: "collaborators & Sponsors",
    },

    {
      value: "9",
      label: "School Staff",
    },
    {
      value: "10",
      label: "Audit Clerks",
    },
  ];

  useEffect(() => {
    if (emailConfirmed && hasPaidForRegistration && isOnboardingCompleted) {
      navigate("/dashboard/app");

      //to prevent school from landing on dashboard
      // navigate("auth/request-demo-page");
    } else if (emailConfirmed === false) {
      navigate("/auth/verify");
    } else if (hasPaidForRegistration === false) {
      navigate("/auth/registration-invoice");
    } else if (isOnboardingCompleted === false) {
      navigate("/auth/profile-complete");
    }
  }, [emailConfirmed, hasPaidForRegistration, isOnboardingCompleted]);

  const onSubmit = async (data) => {
    const { stakeholderCategoryId } = data;

    try {
      await login(data);
      if (stakeholderCategoryId === "9") {
        navigate("/staff/dashboard");
      } else if (stakeholderCategoryId === "4") {
        navigate("/AandE/Dashboard");
      } else if (stakeholderCategoryId === "10") {
        navigate("/auditClerks/dashboard");
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        // background: "#fff",
        paddingTop: 2,
        width: 430,
        minHeight: 450,
        // boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
        borderRadius: 2,
        opacity: 1,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} width={400} m={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
              Sign in to your SSLAG Account
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              Kindly enter your details below.
            </Typography>
          </Box>

          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <RHFTextField
            select
            name="stakeholderCategoryId"
            required
            SelectProps={{ native: true }}
          >
            {STAKEHOLDER_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </RHFTextField>

          <Box
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            // sx={{ m: 2 }}
          >
            <RHFCheckbox name="remember" label="Remember me" />
            <Link
              component={RouterLink}
              variant="subtitle2"
              to={PATH_AUTH.resetPassword}
              // sx={{ mr: 3 }}
            >
              Forgot password?
            </Link>
          </Box>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}
