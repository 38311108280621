import {
  default as axoisCustomAgent,
  axoisCustomAgentForAdmin,
} from "./axiosCustomAgent";
import Decode from "jwt-decode";

// const isValidTokenCustom = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }

//   // ----------------------------------------------------------------------

//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;

//   return decoded.exp > currentTime;
// };

const setSessionCustom = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("SSLAGaccessToken", accessToken);
    axoisCustomAgent.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("SSLAGaccessToken");
    delete axoisCustomAgent.defaults.headers.common.Authorization;
  }
};

const setSessionCustomForAdmin = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("SSLAGaccessToken", accessToken);
    axoisCustomAgentForAdmin.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("SSLAGaccessToken");
    delete axoisCustomAgentForAdmin.defaults.headers.common.Authorization;
  }
};

export { setSessionCustom, setSessionCustomForAdmin };
