import { useState, useEffect } from "react";
import {
  Box as Grid,
  TextField,
  Stack,
  Typography,
  Button,
  Container,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";
import StaffForm from "./StaffForm";
import StaffList from "./StaffList";
// import { index } from "d3-array";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { booleanToNumber } from "src/utils/booleanNumberConverter";
import { LoadingButton } from "@mui/lab";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import Next from "@material-ui/icons/ChevronRight";

import Back from "@material-ui/icons/ChevronLeft";

function MandatorySafety({
  nextStep,
  prevStep,
  LsscAttendee,
  NoOfFirstAider,
  NoOfSafetyChampion,
  NoOfSafetyPrefect,
  CertificateDate,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;

  const initialState = {
    numberOfFirstAiders: "",
    numberOfSafetyChampions: "",
    numberOfSafetyPrefects: "",
    isStaffWithCBCTrainingAvailable: "",
    staffWithCBCertificates: [],
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fectchCBCDetails = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolOnboarding/GetSchoolSafetyConferenceDetails/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const {
            numberOfFirstAiders,
            numberOfSafetyChampions,
            numberOfSafetyPrefects,
            isStaffWithCBCTrainingAvailable,
            staffWithCBCertificates,
          } = response.data.data;

          setState({
            numberOfFirstAiders:
              numberOfFirstAiders ?? state.numberOfFirstAiders,
            numberOfSafetyChampions:
              numberOfSafetyChampions ?? state.numberOfSafetyChampions,
            numberOfSafetyPrefects:
              numberOfSafetyPrefects ?? state.numberOfSafetyPrefects,
            isStaffWithCBCTrainingAvailable: booleanToNumber(
              isStaffWithCBCTrainingAvailable
            ),
            staffWithCBCertificates,
          });
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fectchCBCDetails();
  }, [schoolId]);

  const [addStaffLoading, setAddStaffLoading] = useState(false);
  const [deleteStaffLoading, setDeleteStaffLoading] = useState(false);

  const addStaff = async ({ name, email, phoneNumber, imageUrl, imageId }) => {
    setAddStaffLoading(true);

    const newStaffData = {
      name,
      email,
      phoneNumber,
      imageUrl: imageUrl.trim() === "" ? null : imageUrl,
      imageId: imageId.trim() === "" ? null : imageId,
    };

    try {
      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/AddStaffWithCBCertificate/${schoolId}`,
        {
          ...newStaffData,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setAddStaffLoading(false);

        const { id } = response.data.data;

        setState({
          ...state,
          staffWithCBCertificates: [
            ...state.staffWithCBCertificates,
            {
              id,
              ...newStaffData,
            },
          ],
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setAddStaffLoading(false);
    }
  };

  const deleteStaff = async ({ id }) => {
    setDeleteStaffLoading(true);

    try {
      const response = await axoisCustomAgent.delete(
        `/SchoolOnboarding/${id}/RemoveStaffWithCBCertificate/${schoolId}`
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        const recordsLeft = state.staffWithCBCertificates.filter(
          (s) => s.id !== id
        );

        setState({
          ...state,
          staffWithCBCertificates: [...recordsLeft],
        });

        enqueueSnackbar("Staff record deleted successful", {
          variant: "success",
        });

        setDeleteStaffLoading(false);
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setDeleteStaffLoading(false);
    }
  };

  const handleChange = (event, type) => {
    setState({
      ...state,
      [type]: event.target.value,
    });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    try {
      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/UpdateSchoolSafetyConferenceDetails/${schoolId}`,
        {
          numberOfFirstAiders:
            state.numberOfFirstAiders === "" ? 0 : state.numberOfFirstAiders,
          numberOfSafetyChampions:
            state.numberOfSafetyChampions === ""
              ? 0
              : state.numberOfSafetyChampions,
          numberOfSafetyPrefects:
            state.numberOfSafetyPrefects === ""
              ? 0
              : state.numberOfSafetyPrefects,
          isStaffWithCBCTrainingAvailable:
            state.isStaffWithCBCTrainingAvailable,
        }
      );

      if (response.status === 400) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
        setLoadingSubmit(false);
        return;
      }

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  //====================================
  //====================================
  return (
    <Grid>
      <Grid
        // picture
        sx={{
          display: "flex",

          flexDirection: "column",
          background: `  url(${Bg1}) `,
          backgroundSize: "cover",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>

        <Grid sx={{ alignSelf: "center", justifySelf: "center" }}>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              padding: 2,
              margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
              minHeight: "md",
              minWidth: "md",
            }}
          >
            <Typography sx={{ fontSize: "23px", color: "#6495ed" }}>
              SAFETY MANAGEMENT SYSTEM
            </Typography>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid padding={1}>
                  <Stack sx={{ width: 500 }}>
                    <TextField
                      sx={{ m: 2 }}
                      id=" NoOfFirstAider"
                      label="No of First Aiders (if any)"
                      type="number"
                      variant="filled"
                      value={state.numberOfFirstAiders}
                      onChange={(e) => handleChange(e, "numberOfFirstAiders")}
                    />
                    <TextField
                      sx={{ m: 2 }}
                      id="NoOfSafetyChampion"
                      label="No of Safety Champions (if any)"
                      variant="filled"
                      type="num"
                      value={state.numberOfSafetyChampions}
                      onChange={(e) =>
                        handleChange(e, "numberOfSafetyChampions")
                      }
                    />
                    <TextField
                      sx={{ m: 2 }}
                      id="NoOfSafetyPrefects"
                      label="No of Safety Prefects (if any)"
                      variant="filled"
                      type="num"
                      value={state.numberOfSafetyPrefects}
                      onChange={(e) =>
                        handleChange(e, "numberOfSafetyPrefects")
                      }
                    />
                  </Stack>
                  <FormControl sx={{ mt: 2, width: 480 }}>
                    <FormHelperText>
                      Have members of your staff attended any SSLAG CBC (
                      Capacity Building Conferences)?
                    </FormHelperText>
                    <Select
                      labelId="LsscAttendee"
                      id=""
                      value={state.isStaffWithCBCTrainingAvailable}
                      label="lsscAttendee"
                      variant="filled"
                      onChange={(e) =>
                        handleChange(e, "isStaffWithCBCTrainingAvailable")
                      }
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                  {state.isStaffWithCBCTrainingAvailable === 1 ? (
                    <Stack sx={{ width: 600, mt: 3 }}>
                      <StaffForm
                        addStaff={addStaff}
                        addLoading={addStaffLoading}
                      />
                      <StaffList
                        staffArray={state.staffWithCBCertificates}
                        deleteStaff={deleteStaff}
                        deleteLoading={deleteStaffLoading}
                      />
                    </Stack>
                  ) : null}

                  <Grid sx={{ mt: 4, textAlign: "right" }}>
                    <LoadingButton
                      loading={loadingSubmit}
                      label="Prev"
                      variant="contained"
                      type="submit"
                      sx={{ mr: 4 }}
                      startIcon={<Back />}
                      onClick={prevStep}
                    >
                      Back
                    </LoadingButton>

                    <LoadingButton
                      loading={loadingSubmit}
                      label="next"
                      endIcon={<Next />}
                      variant="contained"
                      type="submit"
                      onClick={async () => await handleSubmit()}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MandatorySafety;
