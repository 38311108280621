import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  Alert,
  Stack,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material/";
import Iconify from "../../components/Iconify";
import Lock from "@material-ui/icons/LockOpenOutlined";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import srun from "../../images/sslagMiddle.png";
import { useNavigate } from "react-router";
import useAuthAdmin from "../../hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

//=========================================================

function AdminLogin() {
  const { loginAdmin, adminRole } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();

  const Gridimage = {
    backgroundImage: `url(${srun})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",

    backgroundPosition: "center",
  };
  const PaperStyle = {
    padding: 20,
    width: 400,
  };
  const avaterStyle = {
    backgroundColor: "#1bbd7e",
  };
  const btnstyle = {
    margin: "8px 0",
  };

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("please enter valid email").required("required"),
    password: Yup.string().required("required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  // -------------------------------------------------------

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      await loginAdmin(data);
      navigate("/admin/dashboard");
      // use navigate to admin dashboard
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  //-----------------------------------------------------------------

  return (
    <Grid container style={Gridimage} justifyContent="center">
      <Grid item alignSelf={"center"}>
        <Paper style={PaperStyle}>
          <Grid align="center" mt={3}>
            <Avatar style={avaterStyle}>
              <Lock />
            </Avatar>
            <Typography variant="h4">Sign In </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
              </Stack>

              <RHFTextField
                name="email"
                label="E-mail"
                type="email"
                variant="standard"
                fullWidth
              />
              <RHFTextField
                name="password"
                label="Password"
                variant="standard"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // type="Password"
                // fullWidth
              />

              <RHFCheckbox
                name="remember"
                color="secondary"
                label="Remember me"
              />

              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                style={btnstyle}
                loading={isSubmitting}
                fullWidth
              >
                Login
              </LoadingButton>
            </FormProvider>
          </Grid>

          <Typography sx={{ mt: 3 }}>
            <Link href="#">Forget Password</Link>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AdminLogin;
