import React, { Fragment, useEffect, useState } from "react";
// import { sentenceCase } from "change-case";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Modal,
  Card,
} from "@mui/material";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";

import { useSnackbar } from "notistack";

import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By School Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AdminPendingRequest() {
  const [intervention, setAllIntervention] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { stakeholderCategory, id } = useAuthAdmin();
  const theme = useTheme();

  useEffect(() => {
    let unmounted = false;

    const getAllInterventions = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.post(
          `SchoolIntervention/GetPendingInterventions`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllIntervention([...data]);
        console.log(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "") getAllInterventions();
    return () => {
      unmounted = true;
    };
  }, [id]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = intervention.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "School Name",
      selector: (row) => row.schoolName,
    },
    {
      name: "Status",
      // selector: (row) => (row.completed ? "Completed" : "Not Completed"),
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(row.completed && "success") || (!row.completed && "error")}
        >
          {row.completed
            ? sentenceCase("Completed")
            : sentenceCase("Not Completed")}
        </Label>
      ),
    },

    {
      name: "Date Sent",
      selector: (row) => row.dateSent.split("T")[0],
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },

    {
      cell: (row) => (
        <MoreMenuButton schoolId={row.schoolId} forms={row.singleForms} />
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Interventions...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Box mt={5}>
        <Card>
          <Grid style={Gridimage}>
            {intervention.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
}

export default AdminPendingRequest;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ forms, schoolId }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {forms.map((eachItem, index) => (
          <Box key={index}>
            <Button
              sx={{ color: "black", fontSize: "12px" }}
              href={`/admin/OnboardingProcess/adminInterventionForm/${eachItem.tokenId}/${schoolId}/${eachItem.formId}`}
            >
              {eachItem.name}
            </Button>

            <Divider sx={{ borderStyle: "dashed" }} />
          </Box>
        ))}
      </MenuPopover>
    </>
  );
}
