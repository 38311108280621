import { Grid, Container, Box, Typography, Button } from "@mui/material";
import AdminBG from "../../images/background.png";
import React, { Fragment, useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useParams } from "react-router";
import logoBg from "../../images/sslagBookingForm.JPG";
import formatnaira from "format-to-naira";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";

function AuditInvoice() {
  useEffect(() => {
    let unmounted = false;
    const getAllInvoice = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `schoolAudit/GetPendingAuditInvoice?schoolId=${schoolId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setInvoice(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAllInvoice();
    return () => {
      unmounted = true;
    };
  }, []);

  const VerifyAuditPayment = async (
    invoiceReference,
    paymentReferenceId,
    paymentServiceProvider
  ) => {
    const response = await axoisCustomAgent.post(
      `SchoolAudit/VerifyAuditPayment/${schoolId}`,
      {
        invoiceReference,
        paymentReferenceId: reference,
        paymentServiceProvider,
      }
    );
    const { statusCode, message } = response.data;
    if (statusCode === 200) {
      // navigate("/AuditCongratulation");
    } else {
      throw message;
    }
  };
  const [reference, setReference] = useState(null);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        await VerifyAuditPayment(
          invoice?.invoiceReferenceId,
          reference,
          "paystack"
        );

        navigate(`/AuditCongratulation/${schoolId}/${reference}`, {
          replace: true,
        });
      } catch (error) {
        enqueueSnackbar("something went wrong", { variant: "error" });
      }
    };
    if (reference !== null) {
      verifyPayment();
    }
  }, [reference]);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  let { schoolId } = useParams();

  const publicKey = `${process.env.REACT_APP_SSLAG_ONBOARD}`;
  const payStackProps = {
    email: invoice?.invoiceTo.email,
    amount: invoice?.payableAmount * 100,
    split_code: `${process.env.REACT_APP_SSLAG_AUD_SPLIT_KEY}`,
    // split_code: "SPL_K8TJe0aERH",
    metadata: {
      name: invoice?.invoiceTo.name,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (value) => {
      const { reference } = value;
      setReference(reference);
    },
  };
  const LoginNavigate = () => {
    // navigate to Private urls
    navigate(`/payLater`);
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          paddingInline: "20px",
          paddingTop: 15,
          background: `url(${AdminBG})`,
          minHeight: "120vh",
          backgroundCover: "contain",
        }}
      >
        <Box>
          <Button href={`/privateAudit`}>Back</Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 200,
            width: "50%",
          }}
        >
          <Box display={"flex"} justifyContent={"center"} p={2}>
            <img
              src={logoBg}
              alt=""
              style={{
                width: 300,
              }}
            />
          </Box>

          <Typography
            textAlign={"center"}
            variant="h5"
            sx={{
              color: "#00B000",
            }}
          >
            INVOICE
          </Typography>

          <Box display={"flex"} justifyContent={"space-between"} m={2}>
            <Box>
              <Typography fontSize={"12px"}>Invoice From:</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceFrom.name}
              </Typography>
              <Typography fontSize={"12px"}>Issued Date</Typography>
              <Typography color={"secondary"}>
                {invoice?.createdDate.split("T")[0]}
              </Typography>
              <Typography fontSize={"12px"}>Due Date</Typography>
              <Typography color={"error"}>
                {invoice?.dueDate.split("T")[0]}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={"12px"}>Invoice To</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceTo.name}
              </Typography>
              <Typography fontSize={"12px"}>Reference Number</Typography>
              <Typography color={"secondary"}>
                {invoice?.invoiceReferenceId}
              </Typography>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"space-between"} m={2}>
            <Typography variant="h4">Description</Typography>
            <Typography variant="h4">Cost</Typography>
          </Box>
          {invoice?.invoiceItems.map((eachItem, index) => (
            <Box
              key={index}
              display={"flex"}
              justifyContent={"space-between"}
              m={2}
            >
              <Typography>{eachItem.description}</Typography>
              <Typography> {formatnaira(eachItem.totalPrice)} </Typography>
            </Box>
          ))}

          <Box m={2}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>Total Amount</Typography>
              <Typography>{formatnaira(invoice?.totalAmount)}</Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
              <Typography color={"error"}>Concession</Typography>
              <Typography>{formatnaira(invoice?.invoiceConcession)}</Typography>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"space-between"} m={2}>
            <Typography> Payable Amounts</Typography>
            <Typography>{formatnaira(invoice?.payableAmount)}</Typography>
          </Box>

          <Box m={2}>
            <Box fontSize={"12px"}>
              <Typography variant="h6">NOTE:</Typography>
              This invoice expire in 7 days to make payment,failure to make
              payment before the due date will attract 10% surcharge
            </Box>
          </Box>
          <Box>
            <Button className="paystackBtnPayLater" onClick={LoginNavigate}>
              Pay Later
            </Button>
          </Box>
          <Grid textAlign={"center"} p={2}>
            <PaystackButton {...payStackProps} className="paystackBtn" />
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AuditInvoice;
