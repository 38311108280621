import {
  Grid,
  Container,
  Box,
  Typography,
  TextField,
  Stack,
  Alert,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import AdminBG from "../../../images/background.png";

import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

function ManagementCommitment({ nextStep, prevStep, schoolId, auditId }) {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const defaultValues = {
    isSafeRecruitmentEvidenceAvailable: "",
    areCBCCertificatesAvailable: "",
    isStaffHandbookAvailable: "",
    isVisitorsLogAvailable: "",
    areVisitorsTagged: "",
    isCMAvailable: "",
    isSSLAGMS12AndPosterAvailable: "",
    arePupilsAwareOfFirstAidAdministration: "",
    arePupilsAwareOfCovidGuidelines: "",
    arePupilsAwareOfFireDrill: "",
    arePupilsAwareOfNoRunningInCorridors: "",
    adequateSupervisionOfPupilsObserved: "",
    isSafetyCommiteePresent: "",
    isPerimeterFencePresent: "",
    safeRecruitmentForAllStaff: "",
    numberOfNonTeachingStaff: "",
    numberOfCleaners: "",
    separatePlaygroudObserved: "",
    adequateStaffForFirstAid: "",
    adequateStaffForSafeGuarding: "",
    adequateStaffForDutyRota: "",
    isComplianceManualAvailable: "",
    isGuideLinesPosterAvailable: "",
    areThereSecurityPersonnels: "",
    isStaffRoomAvailable: "",
    separateToiletsForVisitors: "",
    isHRJobDescriptionPresent: "",
    numberOfStudentsOnRoll: "",
    numberOfTeachingStaff: "",
    isSchoolGatePresent: "",
    isPTAInSchool: "",
    comments: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetManagementCommitmentSectionThree?schoolId=${schoolId}&auditId=${auditId}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         setValue(
  //           "isSafeRecruitmentEvidenceAvailable",
  //           data.isSafeRecruitmentEvidenceAvailable ?? ""
  //         );
  //         setValue(
  //           "areCBCCertificatesAvailable",
  //           data.areCBCCertificatesAvailable ?? ""
  //         );
  //         setValue(
  //           "isStaffHandbookAvailable",
  //           data.isStaffHandbookAvailable ?? ""
  //         );
  //         setValue("isVisitorsLogAvailable", data.isVisitorsLogAvailable ?? "");

  //         setValue("areVisitorsTagged", data.areVisitorsTagged ?? "");
  //         setValue("isCMAvailable", data.isCMAvailable ?? "");
  //         setValue(
  //           "isSSLAGMS12AndPosterAvailable",
  //           data.isSSLAGMS12AndPosterAvailable ?? ""
  //         );
  //         setValue(
  //           "arePupilsAwareOfFirstAidAdministration",
  //           data.arePupilsAwareOfFirstAidAdministration ?? ""
  //         );
  //         setValue(
  //           "arePupilsAwareOfCovidGuidelines",
  //           data.arePupilsAwareOfCovidGuidelines ?? ""
  //         );
  //         setValue(
  //           "arePupilsAwareOfFireDrill",
  //           data.arePupilsAwareOfFireDrill ?? ""
  //         );
  //         setValue(
  //           "arePupilsAwareOfNoRunningInCorridors",
  //           data.arePupilsAwareOfNoRunningInCorridors ?? ""
  //         );
  //         setValue(
  //           "adequateSupervisionOfPupilsObserved",
  //           data.adequateSupervisionOfPupilsObserved ?? ""
  //         );
  //         setValue(
  //           "isSafetyCommiteePresent",
  //           data.isSafetyCommiteePresent ?? ""
  //         );
  //         setValue(
  //           "isPerimeterFencePresent",
  //           data.isPerimeterFencePresent ?? ""
  //         );
  //         setValue(
  //           "safeRecruitmentForAllStaff",
  //           data.safeRecruitmentForAllStaff ?? ""
  //         );
  //         setValue(
  //           "numberOfNonTeachingStaff",
  //           data.numberOfNonTeachingStaff ?? ""
  //         );
  //         setValue("numberOfCleaners", data.numberOfCleaners ?? "");
  //         setValue(
  //           "separatePlaygroudObserved",
  //           data.separatePlaygroudObserved ?? ""
  //         );
  //         setValue(
  //           "adequateStaffForFirstAid",
  //           data.adequateStaffForFirstAid ?? ""
  //         );
  //         setValue(
  //           "isVisitorsadequateStaffForSafeGuardingLogAvailable",
  //           data.adequateStaffForSafeGuarding ?? ""
  //         );
  //         setValue(
  //           "adequateStaffForDutyRota",
  //           data.adequateStaffForDutyRota ?? ""
  //         );
  //         setValue(
  //           "isComplianceManualAvailable",
  //           data.isComplianceManualAvailable ?? ""
  //         );
  //         setValue(
  //           "isGuideLinesPosterAvailable",
  //           data.isGuideLinesPosterAvailable ?? ""
  //         );
  //         setValue(
  //           "areThereSecurityPersonnels",
  //           data.areThereSecurityPersonnels ?? ""
  //         );
  //         setValue("isStaffRoomAvailable", data.isStaffRoomAvailable ?? "");
  //         setValue(
  //           "separateToiletsForVisitors",
  //           data.separateToiletsForVisitors ?? ""
  //         );
  //         setValue(
  //           "isHRJobDescriptionPresent",
  //           data.isHRJobDescriptionPresent ?? ""
  //         );
  //         setValue("numberOfStudentsOnRoll", data.numberOfStudentsOnRoll ?? "");
  //         setValue("numberOfTeachingStaff", data.numberOfTeachingStaff ?? "");
  //         setValue("isSchoolGatePresent", data.isSchoolGatePresent ?? "");
  //         setValue("isPTAInSchool", data.isPTAInSchool ?? "");
  //         setValue("comments", data.comments ?? "");
  //       }
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId]);

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionFourManagementCommitment`,
        {
          auditTaskId: taskId,
          isSafeRecruitmentEvidenceAvailable:
            dataToSubmit.isSafeRecruitmentEvidenceAvailable,
          areCBCCertificatesAvailable: dataToSubmit.areCBCCertificatesAvailable,
          isStaffHandbookAvailable: dataToSubmit.isStaffHandbookAvailable,
          isVisitorsLogAvailable: dataToSubmit.isVisitorsLogAvailable,
          areVisitorsTagged: dataToSubmit.areVisitorsTagged,
          isCMAvailable: dataToSubmit.isCMAvailable,
          isSSLAGMS12AndPosterAvailable:
            dataToSubmit.isSSLAGMS12AndPosterAvailable,
          arePupilsAwareOfFirstAidAdministration:
            dataToSubmit.arePupilsAwareOfFirstAidAdministration,
          arePupilsAwareOfCovidGuidelines:
            dataToSubmit.arePupilsAwareOfCovidGuidelines,
          arePupilsAwareOfFireDrill: dataToSubmit.arePupilsAwareOfFireDrill,
          arePupilsAwareOfNoRunningInCorridors:
            dataToSubmit.arePupilsAwareOfNoRunningInCorridors,
          adequateSupervisionOfPupilsObserved:
            dataToSubmit.isSafadequateSupervisionOfPupilsObservedeRecruitmentEvidenceAvailable,
          isSafetyCommiteePresent: dataToSubmit.isSafetyCommiteePresent,
          isPerimeterFencePresent: dataToSubmit.isPerimeterFencePresent,
          safeRecruitmentForAllStaff: dataToSubmit.safeRecruitmentForAllStaff,
          numberOfNonTeachingStaff: dataToSubmit.numberOfNonTeachingStaff,
          numberOfCleaners: dataToSubmit.numberOfCleaners,
          separatePlaygroudObserved: dataToSubmit.separatePlaygroudObserved,
          adequateStaffForFirstAid: dataToSubmit.adequateStaffForFirstAid,
          adequateStaffForSafeGuarding:
            dataToSubmit.adequateStaffForSafeGuarding,
          adequateStaffForDutyRota: dataToSubmit.adequateStaffForDutyRota,
          isComplianceManualAvailable: dataToSubmit.isComplianceManualAvailable,
          isGuideLinesPosterAvailable: dataToSubmit.isGuideLinesPosterAvailable,
          areThereSecurityPersonnels: dataToSubmit.areThereSecurityPersonnels,
          isStaffRoomAvailable: dataToSubmit.isStaffRoomAvailable,
          separateToiletsForVisitors: dataToSubmit.separateToiletsForVisitors,
          isHRJobDescriptionPresent: dataToSubmit.isHRJobDescriptionPresent,
          numberOfStudentsOnRoll: dataToSubmit.numberOfStudentsOnRoll,
          numberOfTeachingStaff: dataToSubmit.numberOfTeachingStaff,
          isSchoolGatePresent: dataToSubmit.isSchoolGatePresent,
          isPTAInSchool: dataToSubmit.isPTAInSchool,
          comments: dataToSubmit.comments,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const Navigate = () => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };


  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 3: Management Commitment
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid width={"48%"} mt={2}>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Evidence of safe recruitment(SRC) seen?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isSafeRecruitmentEvidenceAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Staff training CBC certificates seen?(at least 6CBC must
                      be sighted to get a yes)
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areCBCCertificatesAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are staff handbook seen</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isStaffHandbookAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Do visitors signed in/out?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isVisitorsLogAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      More than 1 compliance manual(CM) sighted
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isCMAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      More than 1 SSLAG MS12 & poster sighted?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isSSLAGMS12AndPosterAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are visitors tagged for identification available?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areVisitorsTagged"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Typography variant="h5">
                    General Safety Awareness of Pupils
                  </Typography>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are pupils aware of first aid administration?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="arePupilsAwareOfFirstAidAdministration"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are pupils aware of covid-19 guildelines?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="arePupilsAwareOfCovidGuidelines"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are pupils aware of the fire drill?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="arePupilsAwareOfFireDrill"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are pupils aware of the no running in the corridor rule?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="arePupilsAwareOfNoRunningInCorridors"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are adequate supervision of pupils observed?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="adequateSupervisionOfPupilsObserved"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Typography variant="h5">
                    Evidence of Leadership and Management Commitment to Safety
                  </Typography>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Safety committee present in school</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isSafetyCommiteePresent"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Perimeter fence present?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isPerimeterFencePresent"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Safe recruitment checks conducted for all members of the
                      staff?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isThesafeRecruitmentForAllStaffreFirstAiderCert"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <TextField
                      name="numberOfNonTeachingStaff"
                      label="Number of non-teaching staff?"
                      variant="filled"
                      type="number"
                      sx={{
                        width: 400,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    mt: 3,
                    borderLeft: "2px solid  #018fe2",
                    minHeight: 70,
                    // borderleft: 6px solid green;
                  }}
                ></Grid>
                <Grid width={"48%"} mt={2}>
                  <Box sx={{ marginBlock: 2 }}>
                    <TextField
                      name="numberOfCleaners"
                      label="Number of cleaners"
                      type="number"
                      variant="filled"
                      sx={{
                        width: 400,
                      }}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Separate playground for different section observed
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="separatePlaygroudObserved"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Typography variant="h6">
                    Adequate Number of Designation Members of Staff For:
                  </Typography>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography> Are first aid kit sighted</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="adequateStaffForFirstAid"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2 }}>
                    <Typography> Are safeguarding uarding?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="adequateStaffForSafeGuarding"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      {" "}
                      Are ROTA (rooster) for staff duties sighted?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="adequateStaffForDutyRota"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are compliance manual sighted in premises
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isComplianceManualAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are guidelines MS 8 poster sighted</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isGuideLinesPosterAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are security personnel employed?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areThereSecurityPersonnels"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are staff room available</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isStaffRoomAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are they separate toilet for visitors?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="separateToiletsForVisitors"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>HR job description present sighted</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isHRJobDescriptionPresent"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <TextField
                      name="numberOfStudentsOnRoll"
                      label="Number of student on roll?"
                      variant="filled"
                      type="number"
                      sx={{
                        width: 400,
                      }}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <TextField
                      name="numberOfTeachingStaff"
                      label="Number of teaching staff?"
                      variant="filled"
                      type="number"
                      sx={{
                        width: 400,
                      }}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>School gate present</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isSchoolGatePresent"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>PTA/PTF in school</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isPTAInSchool"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <TextField
                      name="comments"
                      multiline
                      minRows={4}
                      label="Additional Comments?"
                      variant="filled"
                      sx={{
                        width: 400,
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
              <Box display={"flex"} justifyContent={"right"}>
                {/* <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    onClick={() => handleDirectionChange("backward")}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Back
                  </LoadingButton>
                </Grid> */}
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={() => handleDirectionChange("forward")}
                    // onClick={Navigate}
                    loading={isSubmitting}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                   Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default ManagementCommitment;
