import { Box, Container, Grid, Typography, Stack, Alert } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";

import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function WalkroundAdmin({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();

  const defaultValues = {
    isJuniorPlaygroundAvailable: "",
    isPlaygroundHazardSafe: "",
    areCorridorsHazardFree: "",
    isPremisesLitterFree: "",
    areDoorsObstructionFree: "",
    arePlaygroundEquipmentBroken: "",
    areClassroomsNaturallyLit: "",
    areFireDoorsInstalled: "",
    areClassroomsSpacious: "",
    areLightingFittingsStandard: "",
    areSanitaryFacilitiesAvailable: false,
    isDrinkingWaterAvailable: false,
    areSeparateStaffFacilitiesAvailable: false,
    isPerimeterFencingAvailable: false,
    isStorageForPupilsBelongingsAvailable: false,
    isFoodStorageFacilityAvailable: false,
    isSchoolTransitPolicyAvailable: false,
    doPupilsHaveAccessToRefrigerator: false,
    absensceofElectricalWiresOnFloor: false,
    clearlyMarkedMusterPoint: false,
    lollipopPersonnelPresent: false,
    areToiletsClean: false,
    areToiletsDry: false,
    tissuesOrWaterHosesForWashing: false,
    washHandBasin: false,
    comments: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };

  const onSubmit = async () => {};

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  useEffect(() => {
    let unmounted = false;
    const GetWalkround = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionTwoWalkaround?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetWalkround();
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 2: Walkround
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid width={"48%"} p={3}>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Does the school have different playgrounds for junior
                      sections?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isJuniorPlaygroundAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Is playground safe of hazards/props used?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isPlaygroundHazardSafe"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are the corridors free of hazards?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areCorridorsHazardFree"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Is the school premises litter-free?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="isPremisesLitterFree"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Door obstruction free?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areDoorsObstructionFree"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Appropriate playground equipment non defective or broken?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="arePlaygroundEquipmentBroken"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are there natural lightning of the classrooms?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areClassroomsNaturallyLit"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are fire doors installed?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areFireDoorsInstalled"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are the classrooms spacious?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areClassroomsSpacious"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are lighting fitting meet safety standard?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="areLightingFittingsStandard"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    mt: 3,
                    borderLeft: "2px solid  #018fe2",
                    minHeight: 70,
                    // borderleft: 6px solid green;
                  }}
                ></Grid>
                <Grid width={"48%"}>
                  <Box p={3}>
                    <Typography variant="h6">
                      Evidence of Safety Infrastructure
                    </Typography>
                    <Typography>*Please indicate all that are seen </Typography>
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="areSanitaryFacilitiesAvailable"
                      label="Sanitary Facilities"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="isDrinkingWaterAvailable"
                      label="Drinking Water"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="areSeparateStaffFacilitiesAvailable"
                      label="Separate Staff Facilities"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="isPerimeterFencingAvailable"
                      label="Perimeter Fencing"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="isStorageForPupilsBelongingsAvailable"
                      label="Designated Place for Storing Pupils Belongings"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="isFoodStorageFacilityAvailable"
                      label="Food Storage Facilities"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="isSchoolTransitPolicyAvailable"
                      label="School Transit Policy"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="doPupilsHaveAccessToRefrigerator"
                      label="Pupils Have Access To Refrigerators To Store Packed Lunches"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="absensceofElectricalWiresOnFloor"
                      label="Absence Of Electrical Wire Trails On The Floor"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="clearlyMarkedMusterPoint"
                      label="Clearly Marked Muster Point"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="lollipopPersonnelPresent"
                      label="Lollipop Personnel Present at the Start and End of School Day"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox name="areToiletsClean" label="Clean Toilets" />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox name="areToiletsDry" label="Dry Toilet" />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="tissuesOrWaterHosesForWashing"
                      label="Tissues in Toilet or water Hoses for Washing"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFCheckbox
                      name="washHandBasin"
                      label="Washing Hand Basin"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="comments"
                      label="Comments"
                      variant="filled"
                      type=""
                      minRows={2}
                      multiline
                    />
                  </Box>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default WalkroundAdmin;
