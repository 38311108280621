import React, { useState, useEffect } from "react";
import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Axios } from "axios";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import FormHelperText from "@mui/material/FormHelperText";
import Next from "@material-ui/icons/ChevronRight";

import Back from "@material-ui/icons/ChevronLeft";
import {
  Box as Grid,
  TextField,
  Stack as Box,
  Typography,
  Button,
  Container,
} from "@mui/material";
// import { async } from "@firebase/util";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function SchoolProvision({ nextStep, prevStep }) {
  const { userData } = useAuth();

  const { schoolId } = userData;

  const initialstate = {
    isGeneratingSetAvailable: "",
    modelOfGeneratingSet: "",
    // yearOfGeneratingSetPurchase: "",

    generatorTypeId: "",
    isSickBayAvailable: "",
    isTrainedNursePresentOnSite: "",
    howDoYouHandleMedicalCases: "",
    doYouHaveScienceLabs: "",
    numberOfScienceLabs: "",
    scienceLabs: "",
    whydontYouHaveScienceLabs: "",
    sportFacilityDescription: "",
    isPlayGroundAvailable: "",
    playGroundDimensions: "",
    alternativeOutdoorPlayAreaDescription: "",
    isSchoolTransitAvailable: "",
    numberOfSchoolBuses: "",
    numberOfDrivers: "",
    numberOfSchoolBusEscorts: "",
    numberOfStudentsUsingSchoolTransit: "",
    areYourDriversSSLAGTrained: "",
    driverImageId: "",
    driverImageUrl: "",
    numberOfStaffRooms: "",
    numberOfStaffToilets: "",
    numberOfStudentToilets: "",
    doesSchoolHaveAHall: "",
    whereDoesAssemblyHold: "",
    schoolHallFloorType: "",
    specialRooms: "",
    isSchoolMealServed: "",
    diningLocation: "",
    isPackedLunchAllowed: "",
    packedLunchSafetyDescription: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState(initialstate);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSchoolProvision = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/SchoolOnboarding/GetSchoolProvisionInfo/${schoolId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const {
            isGeneratingSetAvailable,
            modelOfGeneratingSet,
            // yearOfGeneratingSetPurchase,

            generatorTypeId,
            isSickBayAvailable,
            isTrainedNursePresentOnSite,
            howDoYouHandleMedicalCases,
            doYouHaveScienceLabs,
            numberOfScienceLabs,
            scienceLabs,
            whydontYouHaveScienceLabs,
            sportFacilityDescription,
            isPlayGroundAvailable,
            playGroundDimensions,
            alternativeOutdoorPlayAreaDescription,
            isSchoolTransitAvailable,
            numberOfSchoolBuses,
            numberOfDrivers,
            numberOfSchoolBusEscorts,
            numberOfStudentsUsingSchoolTransit,
            areYourDriversSSLAGTrained,
            driverImageId,
            driverImageUrl,
            numberOfStaffRooms,
            numberOfStaffToilets,
            numberOfStudentToilets,
            doesSchoolHaveAHall,
            whereDoesAssemblyHold,
            schoolHallFloorType,
            specialRooms,
            isSchoolMealServed,
            diningLocation,
            isPackedLunchAllowed,
            packedLunchSafetyDescription,
          } = response.data.data;

          setState({
            ...state,
            isGeneratingSetAvailable:
              isGeneratingSetAvailable === null
                ? ""
                : isGeneratingSetAvailable | 0,
            modelOfGeneratingSet: modelOfGeneratingSet ?? "",

            generatorTypeId: generatorTypeId ?? "",
            isSickBayAvailable:
              isSickBayAvailable === null ? "" : isSickBayAvailable + 0,
            isTrainedNursePresentOnSite:
              isTrainedNursePresentOnSite === null
                ? ""
                : isTrainedNursePresentOnSite | 0,
            howDoYouHandleMedicalCases: howDoYouHandleMedicalCases ?? "",
            doYouHaveScienceLabs:
              doYouHaveScienceLabs === null ? "" : doYouHaveScienceLabs + 0,
            numberOfScienceLabs: numberOfScienceLabs ?? "",
            scienceLabs: scienceLabs ?? "",
            whydontYouHaveScienceLabs: whydontYouHaveScienceLabs ?? "",
            sportFacilityDescription: sportFacilityDescription ?? "",
            isPlayGroundAvailable:
              isPlayGroundAvailable === null ? "" : isPlayGroundAvailable | 0,
            playGroundDimensions: playGroundDimensions ?? "",
            alternativeOutdoorPlayAreaDescription:
              alternativeOutdoorPlayAreaDescription ?? "",
            isSchoolTransitAvailable:
              isSchoolTransitAvailable === null
                ? ""
                : isSchoolTransitAvailable | 0,
            numberOfSchoolBuses: numberOfSchoolBuses ?? "",
            numberOfDrivers: numberOfDrivers ?? "",
            numberOfSchoolBusEscorts: numberOfSchoolBusEscorts ?? "",
            numberOfStudentsUsingSchoolTransit:
              numberOfStudentsUsingSchoolTransit ?? "",
            areYourDriversSSLAGTrained:
              areYourDriversSSLAGTrained === null
                ? ""
                : areYourDriversSSLAGTrained | 0,
            driverImageId: driverImageId ?? "",
            driverImageUrl: driverImageUrl ?? "",
            numberOfStaffRooms: numberOfStaffRooms ?? "",
            numberOfStaffToilets: numberOfStaffToilets ?? "",
            numberOfStudentToilets: numberOfStudentToilets ?? "",
            doesSchoolHaveAHall:
              doesSchoolHaveAHall === null ? "" : doesSchoolHaveAHall | 0,
            whereDoesAssemblyHold: whereDoesAssemblyHold ?? "",
            schoolHallFloorType: schoolHallFloorType ?? "",
            specialRooms: specialRooms ?? "",
            isSchoolMealServed:
              isSchoolMealServed === null ? "" : isSchoolMealServed | 0,
            diningLocation: diningLocation ?? "",
            isPackedLunchAllowed:
              isPackedLunchAllowed === null ? "" : isPackedLunchAllowed | 0,
            packedLunchSafetyDescription: packedLunchSafetyDescription ?? "",
          });
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchSchoolProvision();
  }, [schoolId]);

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const [driverImage, setDriverImage] = useState("");
  const [driverImageUploading, setDriverImageUploading] = useState(false);

  const uploadDriverImage = async () => {
    if (!driverImage || driverImage !== "") {
      setDriverImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", driverImage);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;
          setState({
            ...state,
            driverImageId: imageId,
            driverImageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setDriverImageUploading(false);
        setDriverImage("");
      }
    }
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      const {
        isGeneratingSetAvailable,
        modelOfGeneratingSet,

        generatorTypeId,
        isSickBayAvailable,
        isTrainedNursePresentOnSite,
        howDoYouHandleMedicalCases,
        doYouHaveScienceLabs,
        numberOfScienceLabs,
        scienceLabs,
        whydontYouHaveScienceLabs,
        sportFacilityDescription,
        isPlayGroundAvailable,
        playGroundDimensions,
        alternativeOutdoorPlayAreaDescription,
        isSchoolTransitAvailable,
        numberOfSchoolBuses,
        numberOfDrivers,
        numberOfSchoolBusEscorts,
        numberOfStudentsUsingSchoolTransit,
        areYourDriversSSLAGTrained,
        driverImageId,
        driverImageUrl,
        numberOfStaffRooms,
        numberOfStaffToilets,

        doesSchoolHaveAHall,
        whereDoesAssemblyHold,
        schoolHallFloorType,
        specialRooms,
        isSchoolMealServed,
        diningLocation,
        isPackedLunchAllowed,
        packedLunchSafetyDescription,
      } = state;

      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/UpdateSchoolProvisionInfo/${schoolId}`,
        {
          isGeneratingSetAvailable,
          modelOfGeneratingSet,

          generatorTypeId: generatorTypeId,
          isSickBayAvailable,
          isTrainedNursePresentOnSite,
          howDoYouHandleMedicalCases,
          doYouHaveScienceLabs,
          numberOfScienceLabs: numberOfScienceLabs,
          scienceLabs,
          whydontYouHaveScienceLabs,
          sportFacilityDescription,
          isPlayGroundAvailable,
          playGroundDimensions: playGroundDimensions,
          alternativeOutdoorPlayAreaDescription,
          isSchoolTransitAvailable,
          numberOfSchoolBuses: numberOfSchoolBuses,
          numberOfDrivers: numberOfDrivers,
          numberOfSchoolBusEscorts,
          numberOfStudentsUsingSchoolTransit,
          areYourDriversSSLAGTrained,
          driverImageId: driverImageId.trim() === "" ? null : driverImageId,
          driverImageUrl: driverImageUrl.trim() === "" ? null : driverImageUrl,
          numberOfStaffRooms: numberOfStaffRooms,
          numberOfStaffToilets: numberOfStaffToilets,
          numberOfStudentToilets: numberOfSchoolBuses,
          doesSchoolHaveAHall,
          whereDoesAssemblyHold,
          schoolHallFloorType,
          specialRooms,
          isSchoolMealServed,
          diningLocation,
          isPackedLunchAllowed,
          packedLunchSafetyDescription,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      const { status } = error;
      if (status === 400) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `  url(${Bg1}) `,
          backgroundSize: "cover",
          backgroundPosition: "contain",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              padding: 2,
              margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0. 5)",
              maxHeight: 1200,
              minWidth: "md",
            }}
          >
            <Typography fontSize={"23px"} color={"#6495ed"} fontWeight={3}>
              {" "}
              SCHOOL'S PROVISION
            </Typography>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Container
                sx={{
                  display: "flex",
                }}
              >
                <Grid>
                  <Box sx={{ width: 300 }}>
                    <FormControl sx={{ mt: 2 }}>
                      <FormHelperText>
                        Availability of alternative power?
                      </FormHelperText>
                      <Select
                        labelId="AvaGen"
                        id="AvaGen"
                        variant="filled"
                        value={state.isGeneratingSetAvailable}
                        label="AvaGen"
                        onChange={(e) =>
                          handleChange(e, "isGeneratingSetAvailable")
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes </MenuItem>
                      </Select>
                    </FormControl>
                    {state.isGeneratingSetAvailable === 1 ? (
                      <Box>
                        <TextField
                          sx={{ mr: 6, mt: 2, width: 300 }}
                          id="ModelGen"
                          label="Model of generator"
                          variant="filled"
                          value={state.modelOfGeneratingSet}
                          onChange={(e) =>
                            handleChange(e, "modelOfGeneratingSet")
                          }
                        />
                        <FormControl sx={{ mt: 2 }}>
                          <FormHelperText>
                            Type of generator engine?
                          </FormHelperText>
                          <Select
                            labelId="diepet"
                            id="diepet"
                            variant="filled"
                            value={state.generatorTypeId}
                            label="AvaGen"
                            onChange={(e) => handleChange(e, "generatorTypeId")}
                          >
                            <MenuItem value={1}>Diesel</MenuItem>
                            <MenuItem value={2}> Petrol</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}

                    <FormControl sx={{ mt: 1 }}>
                      <FormHelperText>Do you have a sick bay?</FormHelperText>
                      <Select
                        labelId="NurseOnSite"
                        id="NurseOnSite"
                        variant="filled"
                        value={state.isSickBayAvailable}
                        label="NurseOnSite"
                        onChange={(e) => handleChange(e, "isSickBayAvailable")}
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}> Yes</MenuItem>
                      </Select>
                    </FormControl>
                    {state.isSickBayAvailable === 1 ? (
                      <FormControl sx={{ mt: 2 }}>
                        <FormHelperText>
                          Any trained nurse on premises?
                        </FormHelperText>
                        <Select
                          labelId="Sickbay"
                          id="Sickbay"
                          variant="filled"
                          value={state.isTrainedNursePresentOnSite}
                          label="Sickbay"
                          onChange={(e) =>
                            handleChange(e, "isTrainedNursePresentOnSite")
                          }
                        >
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={1}> Yes</MenuItem>
                        </Select>
                      </FormControl>
                    ) : state.isSickBayAvailable === 0 ? (
                      <TextField
                        sx={{ mt: 2, mr: 6, width: 300 }}
                        id="emergency"
                        label="How do you handle medical cases?"
                        variant="filled"
                        value={state.howDoYouHandleMedicalCases}
                        onChange={(e) =>
                          handleChange(e, "howDoYouHandleMedicalCases")
                        }
                      />
                    ) : null}

                    <Box sx={{ mt: 2, width: 300 }}>
                      <FormControl sx={{}}>
                        <FormHelperText>
                          Do you have science labs?
                        </FormHelperText>

                        <Select
                          labelId="ScienceLab"
                          id=""
                          variant="filled"
                          value={state.doYouHaveScienceLabs}
                          label="scienceLaboratory"
                          onChange={(e) =>
                            handleChange(e, "doYouHaveScienceLabs")
                          }
                        >
                          <MenuItem value={0}>No</MenuItem>
                          <MenuItem value={1}> Yes</MenuItem>
                        </Select>
                      </FormControl>
                      {state.doYouHaveScienceLabs === 1 ? (
                        <Box>
                          <TextField
                            sx={{ mt: 2, mr: 6, width: 300 }}
                            id="ScienceLab"
                            label="How many science labs are on site ?"
                            type="number"
                            variant="filled"
                            value={state.numberOfScienceLabs}
                            onChange={(e) =>
                              handleChange(e, "numberOfScienceLabs")
                            }
                          />
                          <TextField
                            multiline={true}
                            minRows={3}
                            sx={{ mt: 2, mr: 6, width: 300 }}
                            id=" NoOfLab"
                            label="List type of science labs"
                            variant="filled"
                            value={state.scienceLabs}
                            onChange={(e) => handleChange(e, "scienceLabs")}
                          />
                        </Box>
                      ) : state.doYouHaveScienceLabs === 0 ? (
                        <TextField
                          sx={{ mt: 2, mr: 6, width: 300 }}
                          id=" DesOfLab"
                          label="Please explain why"
                          variant="filled"
                          value={state.whydontYouHaveScienceLabs}
                          onChange={(e) =>
                            handleChange(e, "whydontYouHaveScienceLabs")
                          }
                        />
                      ) : null}

                      <TextField
                        sx={{ mt: 2, mr: 6, width: 300 }}
                        id=" DesPlayground"
                        label="Describe school sport facilities"
                        variant="filled"
                        value={state.sportFacilityDescription}
                        onChange={(e) =>
                          handleChange(e, "sportFacilityDescription")
                        }
                      />
                    </Box>
                  </Box>
                  <FormControl sx={{ mt: 1, width: 300 }}>
                    <FormHelperText>Any outdoor play area?</FormHelperText>

                    <Select
                      labelId="outdoor"
                      id=""
                      value={state.isPlayGroundAvailable}
                      variant="filled"
                      label=""
                      onChange={(e) => handleChange(e, "isPlayGroundAvailable")}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}> Yes</MenuItem>
                    </Select>
                  </FormControl>
                  {state.isPlayGroundAvailable === 1 ? (
                    <TextField
                      sx={{ mr: 5, mt: 2, width: 300 }}
                      id=" Dimension"
                      label="Dimension of the playing ground (in meter)"
                      variant="filled"
                      type="number"
                      value={state.playGroundDimensions}
                      onChange={(e) => handleChange(e, "playGroundDimensions")}
                    />
                  ) : state.isPlayGroundAvailable === 0 ? (
                    <TextField
                      sx={{ mr: 5, mt: 2, width: 300 }}
                      id=""
                      label=" Describe alternative arrangements "
                      variant="filled"
                      value={state.alternativeOutdoorPlayAreaDescription}
                      onChange={(e) =>
                        handleChange(e, "alternativeOutdoorPlayAreaDescription")
                      }
                    />
                  ) : null}
                </Grid>
                <Grid>
                  <Box>
                    <FormControl
                      sx={{
                        width: 350,
                        ml: 5,
                        mt: 2,
                      }}
                    >
                      <FormHelperText>
                        Does the School operate a transit system?
                      </FormHelperText>

                      <Select
                        labelId="Transit"
                        id="Transit"
                        value={state.isSchoolTransitAvailable}
                        label="Transit"
                        variant="filled"
                        onChange={(e) =>
                          handleChange(e, "isSchoolTransitAvailable")
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                    {state.isSchoolTransitAvailable === 1 ? (
                      <Box
                        sx={{
                          width: 350,
                          ml: 2,
                        }}
                      >
                        <TextField
                          sx={{ ml: 3, mt: 2, width: 350 }}
                          id="NoOfBuses"
                          label="No of school buses"
                          type="number"
                          variant="filled"
                          value={state.numberOfSchoolBuses}
                          onChange={(e) =>
                            handleChange(e, "numberOfSchoolBuses")
                          }
                        />
                        <TextField
                          sx={{ ml: 3, mt: 2, width: 350 }}
                          id="NoOfDriver"
                          label="No of drivers"
                          type="number"
                          variant="filled"
                          value={state.numberOfDrivers}
                          onChange={(e) => handleChange(e, "numberOfDrivers")}
                        />
                        <TextField
                          sx={{ ml: 3, mt: 2, width: 350 }}
                          id="NoOfBusesEscorts"
                          label="Total bus escorts"
                          type="number"
                          variant="filled"
                          value={state.numberOfSchoolBusEscorts}
                          onChange={(e) =>
                            handleChange(e, "numberOfSchoolBusEscorts")
                          }
                        />
                        <TextField
                          sx={{ ml: 3, mt: 2, width: 350 }}
                          id="PupilUsingTransit"
                          label="No of students using the school transit"
                          type="number"
                          variant="filled"
                          value={state.numberOfStudentsUsingSchoolTransit}
                          onChange={(e) =>
                            handleChange(
                              e,
                              "numberOfStudentsUsingSchoolTransit"
                            )
                          }
                        />
                        <FormControl
                          sx={{
                            width: 350,
                            ml: 3,
                            mt: 2,
                          }}
                        >
                          <FormHelperText>
                            Are your drivers SSLAG trained
                          </FormHelperText>
                          <Select
                            labelId=""
                            id=""
                            variant="filled"
                            value={state.areYourDriversSSLAGTrained}
                            label="SslagTrained"
                            onChange={(e) =>
                              handleChange(e, "areYourDriversSSLAGTrained")
                            }
                          >
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Yes</MenuItem>
                          </Select>
                        </FormControl>

                        {state.areYourDriversSSLAGTrained === 1 ? (
                          <div
                            style={{
                              border: "1px solid grey",
                              width: "550",
                              marginLeft: "15px",
                              marginTop: "10px",
                              padding: "8px",
                              borderRadius: "5px",
                            }}
                          >
                            <FormHelperText>
                              Upload SSLAG certificate below (5MB maximum
                              size)e.g PNG,JPEG,PDF
                            </FormHelperText>
                            <div>
                              <input
                                type="file"
                                onChange={(event) => {
                                  setDriverImage(event.target.files[0]);
                                }}
                              />
                              <button
                                onClick={async () => await uploadDriverImage()}
                                disabled={
                                  driverImageUploading ||
                                  state.driverImageUrl.length > 0
                                }
                              >
                                Upload Cert...
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>

                  <Box>
                    <FormControl sx={{ mt: 1, width: 350, ml: 5 }}>
                      <FormHelperText>
                        Does your school have a school hall?
                      </FormHelperText>
                      <Select
                        labelId=""
                        id="S"
                        variant="filled"
                        value={state.doesSchoolHaveAHall}
                        label=""
                        onChange={(e) => handleChange(e, "doesSchoolHaveAHall")}
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}> Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {state.doesSchoolHaveAHall === 1 ? (
                    <TextField
                      sx={{ m: 2, ml: 5, width: 350 }}
                      id=" "
                      label="What type of flooring is in the schoolhall or dinning (eg:lino, terrazzo or other pls indicate) "
                      variant="filled"
                      value={state.schoolHallFloorType}
                      onChange={(e) => handleChange(e, "schoolHallFloorType")}
                    />
                  ) : state.doesSchoolHaveAHall === 0 ? (
                    <TextField
                      sx={{ m: 2, width: 350, ml: 5 }}
                      id="AssemblyGround "
                      label="Where does the morning assembly hold?"
                      variant="filled"
                      value={state.whereDoesAssemblyHold}
                      onChange={(e) => handleChange(e, "whereDoesAssemblyHold")}
                    />
                  ) : null}
                  <Box>
                    <TextField
                      sx={{ m: 2, width: 350, ml: 5 }}
                      id=" "
                      label="No of staff rooms"
                      variant="filled"
                      type="number"
                      value={state.numberOfStaffRooms}
                      onChange={(e) => handleChange(e, "numberOfStaffRooms")}
                    />
                    <TextField
                      sx={{ m: 2, width: 350, ml: 5 }}
                      id="ToiletFacstaff "
                      label="No of toilet facilities for staff"
                      variant="filled"
                      type="number"
                      value={state.numberOfStaffToilets}
                      onChange={(e) => handleChange(e, "numberOfStaffToilets")}
                    />
                    <TextField
                      sx={{ m: 2, width: 350, ml: 5 }}
                      id="PupilsFac "
                      label="No of total toilets for pupils"
                      variant="filled"
                      type="number"
                      value={state.numberOfStudentToilets}
                      onChange={(e) =>
                        handleChange(e, "numberOfStudentToilets")
                      }
                    />
                  </Box>
                </Grid>
                <Grid>
                  <Box>
                    <TextField
                      multiline={true}
                      minRows={3}
                      sx={{ width: 330, mt: 5, ml: 3 }}
                      id=" RoomOnPremise"
                      label="List special rooms on premises"
                      variant="filled"
                      value={state.specialRooms}
                      onChange={(e) => handleChange(e, "specialRooms")}
                    />
                    {/* <TextField
                      sx={{ m: 2 }}
                      id=" "
                      label="Where do pupils eat?"
                      variant="outlined"
                      value={state.diningLocation}
                      onChange={(e) => handleChange(e, "diningLocation")}
                    /> */}
                    <FormControl sx={{ mt: 1, ml: 2, width: 330 }}>
                      <FormHelperText>Are school meals served?</FormHelperText>

                      <Select
                        labelId="meals"
                        id="meals"
                        value={state.isSchoolMealServed}
                        label=""
                        variant="filled"
                        onChange={(e) => handleChange(e, "isSchoolMealServed")}
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}> Yes</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ m: 2 }}
                      id=" "
                      label="Where do pupils eat?"
                      variant="filled"
                      value={state.diningLocation}
                      onChange={(e) => handleChange(e, "diningLocation")}
                    />
                  </Box>
                  <Box>
                    <FormControl sx={{ ml: 2, width: 330 }}>
                      <FormHelperText>
                        Are packed lunched allowed?
                      </FormHelperText>

                      <Select
                        labelId="lunchMeal"
                        id="LunchMeal"
                        variant="filled"
                        value={state.isPackedLunchAllowed}
                        label=""
                        onChange={(e) =>
                          handleChange(e, "isPackedLunchAllowed")
                        }
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                      </Select>
                    </FormControl>

                    {state.isPackedLunchAllowed === 1 ? (
                      <TextField
                        sx={{ m: 2, width: 350 }}
                        id=" PackedLunchDes"
                        label="Describe safe storage of packed meals"
                        variant="filled"
                        value={state.packedLunchSafetyDescription}
                        onChange={(e) =>
                          handleChange(e, "packedLunchSafetyDescription")
                        }
                      />
                    ) : null}
                  </Box>

                  <Grid sx={{ mt: 12, textAlign: "right" }}>
                    <LoadingButton
                      loading={loadingSubmit}
                      label="Prev"
                      variant="contained"
                      type="submit"
                      startIcon={<Back />}
                      sx={{ mr: 4 }}
                      onClick={prevStep}
                    >
                      Back
                    </LoadingButton>

                    <LoadingButton
                      loading={loadingSubmit}
                      label="next"
                      variant="contained"
                      endIcon={<Next />}
                      type="submit"
                      onClick={async () => await handleSubmit()}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SchoolProvision;
