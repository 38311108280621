import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
  MotionInView,
} from "../../../components/animate";
import Vest from "../../../images/High visibility vest.png";
import AboutKid from "../../../images/aboutWallpaper.png";
import { Fragment } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
// ----------------------------------------------------------------------

export default function KeyElement() {
  return (
    <Fragment>
      <MotionInView variants={varFade().inUp}>
        <Typography mt={4} variant="h6" sx={{ textAlign: "center" }}>
          More About us
        </Typography>
      </MotionInView>
      <MotionInView variants={varFade().inUp}>
        <Typography variant="body1" sx={{  textAlign: "center", lineHeight: 2 ,p:2}}>
          The project Lead for Safety Lagos ( Lagos)  is responsible for
          training the operational team to deliver the framework to
          stakeholders. Monthly Capacity Building Conferences(CBC) are held to
          keep school administration abreast of the ultimate goals of the
          Compliance Drive, as the need for Safety compliance drive in schools
          cannot be over emphasised. It is borne out of fact that accident,
          ill-health, fatality in schools, although not anticipated, does
          create bad image for schools, affect families and may cause deaths or
          serious life changing injuries. So, it is anticipated that the Safe
          Schools Lagos (SSLAG) compliance drive would bring peace of mind to
          parents, government and stakeholders, as it promotes healthy, happy
          and motivated pupils, teachers, parents and all members of the school
          Community.
        </Typography>
        <Typography sx={{ textAlign: "center", lineHeight: 2 ,p:2}}>
          Henceforth, we would have in Lagos State stimulating school
          environment as SSLAG defines procedures, standards and ethical
          practices in safety for schools.
        </Typography>
        <Typography sx={{ textAlign: "center", lineHeight: 2,p:2 }}>
          Schools would in return have good reputation, satisfied management( No
          more frequent shutdown of schools).
        </Typography>
      </MotionInView>
    </Fragment>
  );
}
