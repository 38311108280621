import React from "react";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  FormHelperText,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

function Staff() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const StaffSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    qualifications: Yup.string().required("certificate type is required"),
    email: Yup.string()
      .email("Enter a valid Email Address")
      .required("Email is required"),

    gender: Yup.string().oneOf(["1", "2", "3"], "Select a gender type "),

    yearEmployed: Yup.string().required("year of employed is  required"),
    stateOfOrigin: Yup.string().required("state of origin is  required"),
    roleId: Yup.string().required("school role is  required"),

    lga: Yup.string().required("local government  is  required"),
    telephone: Yup.string()
      .required("required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(11, "to short")
      .max(11, "to long"),

    // telephone: Yup.string().required("telephone  is  required"),
    DOB: Yup.string().nullable().required("date of birth  is  required"),
    staffRegNo: Yup.string().nullable().required("staff id   is  required"),
  });

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    roleId: "",
    yearEmployed: "",
    stateOfOrigin: "",
    lga: "",
    qualifications: "",
    telephone: "",
    DOB: null,
    staffRegNo: "",
    residentialAddress: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `/SchoolStaff/CreateSchoolStaff/${schoolId}`,
        {
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          phoneNumber: data.telephone,
          email: data.email,
          genderId: data.gender,
          certificateId: data.qualifications,
          staffRoleId: data.roleId,
          dateOfBirth: new Date(data.DOB).toISOString,
          yearEmployed: data.yearEmployed,
          residentialAddress: data.residentialAddress,
          stateOfOrigin: data.stateOfOrigin,
          lgaOfOrigin: data.lga,
          registrationNumber: data.staffRegNo,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar("School staff created successfully", {
        variant: "success",
      });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const methods = useForm({
    resolver: yupResolver(StaffSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const GENDER = [
    {
      value: "0",
      label: "Select Gender",
    },
    {
      value: "1",
      label: "Male",
    },
    {
      value: "2",
      label: "Female",
    },
    {
      value: "3",
      label: "Other",
    },
  ];

  const QUALIFICATIONS = [
    {
      value: "0",
      label: "Certificate type ",
    },

    {
      value: "1",
      label: "SSCE",
    },
    {
      value: "2",
      label: "ND",
    },
    {
      value: "3",
      label: "HND",
    },
    {
      value: "4",
      label: "Bsc",
    },
    {
      value: "5",
      label: "Msc",
    },
    {
      value: "6",
      label: "Phd",
    },
  ];
  const ROLE = [
    {
      value: "0",
      label: "Choose Role",
    },
    {
      value: "2",
      label: "Administrator",
    },
    {
      value: "3",
      label: "Principal",
    },
    {
      value: "4",
      label: "Vice Principal",
    },
    {
      value: "5",
      label: " Teachers",
    },
    {
      value: "6",
      label: "Drivers",
    },
    {
      value: "7",
      label: "Non-teaching Staff",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Staff</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/staff"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Add Staff
          </Button>

          <Button
            href="/dashboard/user/allstaff"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            All Staff
          </Button>

          <Button
            href="/dashboard/user/addDuties"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Duty Location
          </Button>
          <Button
            href="/dashboard/user/staffDuties"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Staff Duty
          </Button>
        </Box>
      </Box>
      <hr />
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={3}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Complete Staff Data
                    </Typography>
                  </Box>
                </Grid>

                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid width={250}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="firstName"
                      label="First Name"
                      variant="filled"
                    />
                  </Grid>

                  <Grid width={250}>
                    <RHFTextField
                      name="middleName"
                      label="Middle Name"
                      variant="filled"
                    />
                  </Grid>
                  <Grid width={250}>
                    <RHFTextField
                      name="lastName"
                      label="Last Name"
                      variant="filled"
                    />
                  </Grid>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                  <Grid width={250}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      select
                      name="gender"
                      label="Gender"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {GENDER.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid width={250}>
                    <RHFTextField
                      name="email"
                      type="email"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>
                  <Grid width={250}>
                    <RHFTextField
                      name="yearEmployed"
                      label="Year Employed"
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                  <Grid width={250}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      select
                      name="qualifications"
                      label="Qualification"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {QUALIFICATIONS.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid width={250}>
                    <RHFTextField
                      select
                      name="roleId"
                      label="Role"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {ROLE.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                  <Grid width={250}>
                    <RHFTextField
                      name="telephone"
                      type="number"
                      label="Phone Number"
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                  <Grid width={250}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="stateOfOrigin"
                      label="State Of Origin"
                      variant="filled"
                    />
                  </Grid>

                  <Grid width={250}>
                    <RHFTextField
                      name="lga"
                      label="LGA Of Origin"
                      variant="filled"
                    />
                  </Grid>
                  <Grid width={250}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* <FormHelperText>Date Of Birth:</FormHelperText> */}
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        label="Date of Birth"
                        // inputFormat="dd/MM/yyyy"
                        // inputFormat="dd/mm/yyyy"
                        value={methods.watch().DOB}
                        onChange={(value) => {
                          
                          methods.setValue("DOB", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="DOB"
                          />
                        )}
                      />
                      {/* <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Date Of Birth"
                        value={methods.watch().DOB}
                        onChange={(value) => {
                          methods.setValue("DOB", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="DOB"
                          />
                        )}
                      /> */}
                    </LocalizationProvider>
                  </Grid>
                </Box>

                <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                  <Grid ml={4} width={250}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="staffRegNo"
                      label="Staff ID"
                      variant="filled"
                    />
                  </Grid>

                  <Grid mr={3} width={550}>
                    <RHFTextField
                      name="residentialAddress"
                      placeholder="Residential Address"
                      multiline={true}
                      minRows={4}
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Create Staff
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Staff;
