// routes
import { PATH_AandE, PATH_DASHBOARD } from "../../../routes/paths";
// components
// import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  school: getIcon("school"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  admin: getIcon("admin"),
  key_element: getIcon("element"),
  advocacy: getIcon("advocacy"),
  monitoring: getIcon("monitoring"),
  transfer: getIcon("transfer"),
  support: getIcon("support"),
  E_Wwallet: getIcon("e_wallet"),
  quickLink: getIcon("quick_link"),
  notifications: getIcon("notifications"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Home",
        path: "/AandE/dashboard",
        // path: PATH_AandE.Dashboard.root,
        icon: ICONS.school,

        // icon: ICONS.dashboard,
      },
    ],
  },

  //NOTIFICATION
  //==================================================

  {
    subheader: "TASKBOARD",

    items: [
      {
        title: "Taskboards ",

        path: PATH_AandE.Taskboards.root,
        icon: ICONS.analytics,
        children: [
          {
            title: "Audit",
            path: PATH_AandE.Taskboards.AandEAssignSchool,

            children: [
              { title: "Task", path: PATH_AandE.Taskboards.AandEAssignSchool },
              // {title:"Assign Clerk", path:PATH_AandE.Taskboards.auditClerks},
            ],
          },
          {
            title: "Evaluation",
            // path: PATH_AandE.Taskboards.auditClerks,
            path: "",
          },
          // {
          //   title: "Enforcement",
          //   path: "",
          //   // path: PATH_AandE.Taskboards.auditClerks,`
          // },
          {
            title: "Spot-Checks",
            path: "",
            // path: PATH_AandE.Taskboards.auditClerks,
          },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: "OSP",

  //   items: [
  //     {
  //       title: "OSP",
  //       // path: PATH_DASHBOARD.blog.root,
  //       path: "/coming-soon",
  //       icon: ICONS.advocacy,
  //     },
  //   ],
  // },

  //-------------------------------------------------------------
  {
    subheader: "KNOWLEDGE TRANSFER",

    items: [
      {
        title: "KT -Online",
        // path: PATH_DASHBOARD.eCommerce.root,
        path: "/coming-soon",
        icon: ICONS.transfer,
      },
    ],
  },

  {
    subheader: "PRACTICE TOOLS",
    items: [
      // MANAGEMENT : USER
      {
        title: "Practice Tools",
        path: "/coming-soon",
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: "SUPPORT",

    items: [
      {
        title: "Support",
        // path: PATH_DASHBOARD.blog.root,
        path: "/coming-soon",
        icon: ICONS.support,
      },
    ],
  },

  //----------------------------------------------------------------------------
];

export default navConfig;
