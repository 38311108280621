import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getMail } from "../../../redux/slices/mail";
//
import Markdown from "../../../components/Markdown";
import Scrollbar from "../../../components/Scrollbar";
import MailDetailsToolbar from "./MailDetailsToolbar";
import MailDetailsReplyInput from "./MailDetailsReplyInput";
import MailDetailsAttachments from "./MailDetailsAttachments";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import MailInterventionToolbar from "./MailInterventionToolbar";
import MailSubmit from "./MailSubmit";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});

const MarkdownStyle = styled("div")(({ theme }) => ({
  "& > p": {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function MailDetails() {
  const [loadingMessage, setLoadingMessage] = useState(false);
  const { userData } = useAuth();
  const { schoolId } = userData;

  const params = useParams();
  const { mailId } = useParams();
  const { systemLabel } = params;
  const [message, setMessage] = useState(null);

  const [showReplyInput, setShowReplyInput] = useState(false);

  useEffect(() => {
    const getMessage = async () => {
      setLoadingMessage(true);
      try {
        const response = await axoisCustomAgent.get(
          `/Schools/GetInterventionMessagesByConversationId?schoolId=${schoolId}&conversationId=${systemLabel}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        let messageValue = null;

        let messageIndex = data.findIndex((x) => x.id == mailId);

        if (messageIndex !== -1) {
          messageValue = data[messageIndex];
        }

        let showReply = messageIndex === 0;
        setShowReplyInput(showReply);
        setMessage(messageValue);
      } catch (error) {
      } finally {
        setLoadingMessage(false);
      }
    };

    if (schoolId !== "" && mailId !== undefined) getMessage();
  }, [schoolId, mailId]);

 

  if (loadingMessage) return <Typography>Loading message...</Typography>;

  if (!message) {
    return null;
  }

  return (
    <RootStyle>
      {/* <MailDetailsToolbar mail={mail} /> */}
      <MailInterventionToolbar message={message} />

      <Divider />

      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ p: { xs: 3, md: 5 } }}>
          <Typography variant="h5" gutterBottom>
           
            subject: {message.subject}
          </Typography>
          <Divider />
          <MarkdownStyle>
            
            <Markdown children={message.body} />
          </MarkdownStyle>
        </Box>
      </Scrollbar>

      {/* {isAttached && <MailDetailsAttachments mail={mail} />} */}

      <Divider />
      {/* {showReplyInput ? <MailDetailsReplyInput /> : null} */}
      {showReplyInput ? <MailSubmit conversationId={systemLabel} subject={message.subject} /> : null}
    </RootStyle>
  );
}
