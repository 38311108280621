import {
  Grid,
  Box,
  Typography as FormHelperText,
  Button,
  Container,
} from "@mui/material";
import React from "react";

import Arrow from "@material-ui/icons/ArrowRightAlt";
// import Bullet from "@material-ui/icons/Bullet";s

function protocolGuidance() {
  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",

          padding: 2,
        }}
      >
        <Box paddingRight={8}>
          <Button
            // variant="outlined"

            // color="success"
            href="/dashboard/user/protocolGuidance"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Input & Output
          </Button>
          {/* <Button
            href="/dashboard/user/IntroductionAcknowledgement"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Introduction acknowledgement
          </Button> */}

          <Button
            // href="/dashboard/user/protocols"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Guidance assessment
          </Button>

          <Button
            // href="/dashboard/user/sslagEvaluation"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            sslag evaluation
          </Button>
          <Button
            // href="/dashboard/user/safetyAudit"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safety audit rating
          </Button>
        </Box>
      </Box>

      <Grid sx={{ alignSelf: "center", padding: 4 }}>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: 600,
          }}
        >
          <Box>
            <Box
              sx={{
                minHeight: 4,
                minWidth: "200px",
                borderRadius: 1,
                border: "2px solid #6495ed",

                color: "#6495ed",
                textAlign: "center",
              }}
            >
              <Box>
                <FormHelperText>Inputs</FormHelperText>
              </Box>
            </Box>

            <Box
              sx={{
                minHeight: "250px",
                minWidth: "300px",
                borderRadius: 3,
                mt: 5,
                border: "2px solid #6495ed",

                color: "#6495ed",
              }}
            >
              <FormHelperText padding={1}>
                Self Regulation Based on Sslag Guidelines: MS12 & 8
              </FormHelperText>
              <Grid>
                <Box padding={1}>
                  <FormHelperText>1: Checklists</FormHelperText>
                  <FormHelperText>2: Safety Plan with budgets</FormHelperText>
                  <FormHelperText>3: Data on pupil wellbeing</FormHelperText>
                  <FormHelperText>4: Daily routine log </FormHelperText>
                  <FormHelperText>5: Incident reporting</FormHelperText>
                  <FormHelperText>6: Risk assessment</FormHelperText>
                  <FormHelperText>7: Emergency preparedness</FormHelperText>
                  <FormHelperText>8: Safe recruitment checks</FormHelperText>
                  <FormHelperText>
                    9: Staff CPD, status and movement notifications
                  </FormHelperText>
                  <FormHelperText>10: Safety infrastruture</FormHelperText>
                  <FormHelperText>11: Road infrastruture</FormHelperText>
                  <FormHelperText>12: mandatory supplies</FormHelperText>
                </Box>
                <Box padding={2}>
                  <FormHelperText>Self evaluation</FormHelperText>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Box>
              <Arrow style={{ fontSize: 40, marginBottom: 60 }} />
            </Box>
          </Box>

          {/* ==================================== */}
          <Box>
            <Box
              sx={{
                minHeight: 4,
                minWidth: "200px",
                borderRadius: 1,
                border: "2px solid #6495ed",

                color: "#6495ed",
                textAlign: "center",
              }}
            >
              <FormHelperText>Focus</FormHelperText>
            </Box>
            <Box>
              <Box
                sx={{
                  minHeight: "150px",
                  minWidth: "300px",
                  borderRadius: 3,
                  mt: 5,
                  border: "2px solid #6495ed",

                  color: "#6495ed",
                }}
              >
                <Box padding={1}>
                  <FormHelperText>
                    {" "}
                    a) How well is the school performing on management of safety
                  </FormHelperText>
                  <FormHelperText>
                    b) What are the key factors? Behaviour management, safety
                    infrastruture, risk assessments, emergency preparedness
                  </FormHelperText>
                  <FormHelperText>
                    c) How will the school achieve them?
                  </FormHelperText>
                </Box>
              </Box>
              <Box
                sx={{
                  minHeight: "150px",
                  minWidth: "200px",
                  borderRadius: 3,
                  mt: 5,
                  border: "2px solid #6495ed",

                  color: "#6495ed",
                }}
              >
                <Box padding={1}>
                  <FormHelperText>
                    Senior management commitments and safety management system
                  </FormHelperText>
                  <FormHelperText>
                    How effective are the school's SMS and policies
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Arrow style={{ fontSize: 40, marginBottom: 60 }} />
            </Box>
          </Box>
          {/* ===================================== */}
          <Box>
            <Box
              sx={{
                minHeight: 4,
                minWidth: "300px",
                borderRadius: 1,
                border: "2px solid #6495ed",

                color: "#6495ed",
                textAlign: "center",
              }}
            >
              <FormHelperText>Outputs</FormHelperText>
            </Box>

            <Box
              sx={{
                minHeight: "250px",
                minWidth: "200px",
                borderRadius: 3,
                mt: 5,
                border: "2px solid #6495ed",

                color: "#6495ed",
              }}
            >
              <FormHelperText padding={1}>
                Reports to LSC Through SSLAG on
              </FormHelperText>

              <Box padding={1}>
                <FormHelperText>1: Checklists</FormHelperText>
                <FormHelperText>2: Safety Plan with budgets</FormHelperText>
                <FormHelperText>3: Data on pupil wellbeing</FormHelperText>
                <FormHelperText>4: Daily routine log </FormHelperText>
                <FormHelperText>5: Incident reporting</FormHelperText>
                <FormHelperText>6: Risk assessment</FormHelperText>
                <FormHelperText>7: Emergency preparedness</FormHelperText>
                <FormHelperText>8: Safe recruitment checks</FormHelperText>
                <FormHelperText>
                  9: Staff CPD, status and movement notifications
                </FormHelperText>
                <FormHelperText>10: Safety infrastruture</FormHelperText>
                <FormHelperText>11: Road infrastruture</FormHelperText>
                <FormHelperText>12: mandatory supplies</FormHelperText>

                <Box mt={4}>
                  <FormHelperText>
                    SSLAG Rating And License Issued
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}

export default protocolGuidance;
