import { Box, padding } from "@mui/system";
import { Children, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const AdminNavDiv = styled.div`
  display: flex;

  ${"" /* color: black; */}
  justify-content: space-between;
  align-items: center;

  list-style: none;
  position:"relative"

  text-decoration: none;
  font-size: 12px;
 

  &:hover {
    ${"" /* background: #ffff */}
    color: "#ffff";

    cursor: pointer;
  }
`;

const AdminNavLink = styled(Link)`
  display: flex;
  
  justify-content: space-between;
  align-items: center;
  padding:2,
 
  list-style: none;

  
  text-decoration: none;
  font-size: 12px;

  
  &:hover {
  
    color: "#018fe2";
       cursor: pointer;
   
  } 
  
}
`;

const Sidebarlabel = styled.span`
  margin-left: 16px;
`;
const DropdownLink = styled(Link)`
  color: black;
 

  display: flex;
  align-items: center;
  text-decoration: none;

  font-size: 13px;

  &:hover {
    cursor: pointer;
    fontsize: 1rem;
    background: "#ffff";
  }
`;

function AdminNav({ item }) {
  const [children, setChildren] = useState(false);

  const showChildren = () => setChildren(!children);

  if (item.Children === null || item.Children === undefined) {
    return (
      <Box>
        <AdminNavLink to={item.path} onClick={item.Children && showChildren}>
          <div>{item.icon}</div>
          <div>
            <Sidebarlabel>{item.title}</Sidebarlabel>
          </div>
        </AdminNavLink>
      </Box>
    );
  }

  return (
    <Box display={"block"} position={"relative"}>
      <AdminNavDiv to={item.path} onClick={item.Children && showChildren}>
        <div>{item.icon}</div>
        <div>
          <Sidebarlabel>{item.title}</Sidebarlabel>
        </div>
        <div>
          {/* {item.open} */}
          {item.Children && children
            ? item.iconOpened
            : item.Children
            ? item.iconClosed
            : null}
        </div>
      </AdminNavDiv>

      <div
        style={{
          position: "absolute",
          left: "0",
          backgroundColor: "white",
          borderRadius: "2px",
          zIndex: "1",
          minWidth:250,
          // padding:1,
          boxShadow:"2px 2px"
        }}
      >
        {item.Children !== undefined && children
          ? item.Children.map((element, index) => (
              <DropdownLink key={index} to={element.path}>
                {element.icon}
                <Sidebarlabel>{element.title}</Sidebarlabel>
              </DropdownLink>
            ))
          : null}
      </div>
    </Box>
  );
}

export default AdminNav;
