// import { capitalCase } from 'change-case';
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Button,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";

// hooks

import useResponsive from "../../hooks/useResponsive";

// components
import Page from "../../components/Page";
import LogoBg from "../../components/Logo";
import Bg1 from "../../images/sslagNewSample.png";
import School from "@material-ui/icons/SchoolOutlined";
import Group from "@material-ui/icons/PeopleAlt";
import Supplier from "@material-ui/icons/Work";
import Assessors from "@material-ui/icons/Assessment";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";

// sections
import { LoginForm } from "../../sections/auth/login";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    backgroundImage: `url(${Bg1})`,
    minHeight: "100vh",
    backgroundCover: "contain",
    // backgroundSize: "contain",

    backgroundRepeat: "no-repeat",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <LogoBg />

          {smUp && (
            <Box variant="body2" sx={{ display: "flex" }}>
              <Typography>Don't have an account? {""}</Typography>

              <Box ml={2}>
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {(popupState) => (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#6495ed" }}
                        {...bindTrigger(popupState)}
                      >
                        Get Started
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Button
                            sx={{ color: "#6495ed" }}
                            href="/auth/register"
                            startIcon={<School />}
                          >
                            Schools
                          </Button>

                          <Button
                            sx={{ color: "#6495ed" }}
                            href="/auth/register"
                            startIcon={<Assessors />}
                          >
                            Assessors
                          </Button>
                          <Button
                            sx={{ color: "#6495ed" }}
                            href="/auth/register"
                            startIcon={<Supplier />}
                            // startIcon={}
                          >
                            Suppliers and Vendors
                          </Button>
                          <Button
                            sx={{ color: "#6495ed" }}
                            href="/auth/register"
                            startIcon={<Group />}
                          >
                            Collaborators & sponsors
                          </Button>
                        </Box>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </Box>
            </Box>
          )}
        </HeaderStyle>

        {/* {mdUp && ( */}
        <SectionStyle
          sx={{
            background: "none",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
            // color: "#fff",
            // padding: 1,
            color: "Black",
          }}
        >
          <LoginForm />
        </SectionStyle>
        {/* )} */}

        {/* <Container maxWidth="sm"></Container> */}
      </RootStyle>
    </Page>
  );
}
