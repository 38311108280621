import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Alert,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import styleds from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import React from "react";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const TextField = styleds.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styleds(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

// ----------------------------------------------------------------------

export default function Pending() {
  const [schoolConcession, setAllSchoolConcession] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [currentConcession, setCurrentConcession] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [open, setOpen] = React.useState(false);

  const defaultValues = {
    // new========================
    reason: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  // const filteredItems = schoolConcession.filter(
  //   (item) =>
  //     item.schoolConcession &&
  //     item.schoolConcession.toLowerCase().includes(filterText.toLowerCase())
  // );

  // const subHeaderComponentMemo = React.useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <FilterComponent
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width:"60px",
    },
    {
      name: "School Name",
      selector: (row) => row.user.name,
    },

    {
      name: "Purpose",
      selector: (row) => row.concession.name,
    },
    {
      name: "Date Created",
      selector: (row) => row.createdOn.split("T")[0],
    },

    {
      name: "Status",
      selector: (row) => row.status,
    },

    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            // onClick={() => handleOpen(row.id)}
            onClick={() => handleClickOpen(row.id)}
          >
            {" "}
            Accept
          </Button>
        </>
      ),
    },
    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => handleOpenReject(row.id)}
          >
            {" "}
            Reject
          </Button>
        </>
      ),
    },
  ];

  const AcceptConcession = async () => {
    try {
      const response = await axoisCustomAgentForAdmin.put(
        `Concessions/ApproveConcessionUsage?id=${currentConcession}`
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleClickClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const RejectConcession = async (data) => {
    try {
      const response = await axoisCustomAgentForAdmin.put(
        `/Concessions/RejectConcessionUsage?id=${currentConcession}
        `,
        {
          reason: data.reason,
        }
      );
      // /api/AandE/k/{taskId}
      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleCloseReject();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleClickOpen = (taskId) => {
    setCurrentConcession(taskId);
    setOpen(true);
  };

  const handleClickClose = () => {
    setCurrentConcession(undefined);
    setOpen(false);
  };

  const handleOpenReject = (taskId) => {
    setCurrentConcession(taskId);
    setDialogOpen(true);
  };

  const handleCloseReject = () => {
    setCurrentConcession(undefined);
    setDialogOpen(false);
  };

  useEffect(() => {
    let unmounted = false;

    const getAllConcession = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Concessions/GetConcessionUsage?approvalStatus=${1}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllSchoolConcession([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getAllConcession();

    return () => {
      unmounted = true;
    };
  }, [id]);

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Concession...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%", minWidth: "100%" }}
      >
        <Grid>
          <Box>
            {schoolConcession.length === 0 ? (
              <Typography>No Concession</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={schoolConcession}
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Box>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClickClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
            Accept Concession
          </DialogTitle>
          <DialogContent
            sx={{
              mt: 3,
            }}
          >
            <DialogContentText id="alert-dialog-description">
              Do you want to approve this concession?.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose}>No</Button>
            <Button
              onClick={() => AcceptConcession(currentConcession)}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Reject concession */}

        <Dialog
          open={dialogOpen}
          onClose={handleCloseReject}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {/* {"Use Google's location service?"} */}
            Reject
          </DialogTitle>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(RejectConcession)}
          >
            <DialogContent
              sx={{
                mt: 3,
              }}
            >
              <DialogContentText id="alert-dialog-description">
                Do you want to reject this concession?.
              </DialogContentText>

              <Box>
                <Stack spacing={1} m={3}>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <RHFTextField
                  name="reason"
                  label="Reason"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={handleCloseReject}>
                No
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Yes
              </Button>
            </DialogActions>
          </FormProvider>
        </Dialog>
      </Container>
    </RootStyle>
  );
}
