import { Grid, Box, Typography, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { useSnackbar } from "notistack";

import axoisCustomAgent from "../../utils/axiosCustomAgent";

function ViewStudent({ studentId, schoolId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [dataAccess, setDataAccess] = useState(false);
  const [initialstate, setInitialstate] = useState(null);
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(),
  });
  const {
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const watchAlleg = watch("alleg");
  const watchAllergyType = watch("allergyType");

  const watchCondition = watch("medicalCond");
  const watchConditionType = watch("medicalType");

  useEffect(() => {
    const getAllStudentProfile = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Students/GetStudentById?schoolId=${schoolId}&studentId=${studentId} `
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setInitialstate({ ...data });
        setDataAccess(true);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStudentProfile();
  }, [schoolId, studentId]);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Student...</Typography>
        </Box>
      </Box>
    );
  }

  if (loading || !dataAccess) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Student...</Typography>
        </Box>
      </Box>
    );
  }

  if (!loading && initialstate == null && dataAccess) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Staff Not Found</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Grid>
      <FormProvider methods={methods}>
        <Grid container spacing={5} display={"flex"}>
          <Grid padding={3}>
            <Grid item>
              <FormHelperText>First Name :</FormHelperText>
              <RHFTextField
                name="firstName"
                value={initialstate.firstName}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>Middle Name :</FormHelperText>
              <RHFTextField
                name="middleName"
                value={initialstate.otherName}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>Last Name :</FormHelperText>
              <RHFTextField
                name="lastName"
                value={initialstate.lastName}
                disabled
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item>
              <FormHelperText>Year Of Admission :</FormHelperText>
              <RHFTextField
                name="yearOfAdmission"
                value={initialstate.yearOfAdmission}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>State Of Origin :</FormHelperText>
              <RHFTextField
                name="stateOfOrigin"
                value={initialstate.stateOfOrigin}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormHelperText>Date Of Birth :</FormHelperText>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={initialstate.dateOfBirth}
                  disabled
                  // label="Date of Birth "
                  // value={methods.watch().DOB}
                  // onChange={(value) => {
                  //   methods.setValue("DOB", value, {
                  //     shouldValidate: true,
                  //   });
                  // }}
                  renderInput={(params) => (
                    <RHFTextField {...params} name="DOB" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <FormHelperText>LGA:</FormHelperText>
              <RHFTextField
                name="lga"
                value={initialstate.lgaOfOrigin}
                disabled
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid padding={3} mt={1}>
            <Grid item>
              <FormHelperText>Hospital Name :</FormHelperText>
              <RHFTextField
                name="hospitalName"
                value={initialstate.hospitalNameForEmergencies}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>Gender :</FormHelperText>
              <RHFTextField
                name="gender"
                value={initialstate.genderName}
                disabled
                required
              ></RHFTextField>
            </Grid>
            <Grid item>
              <FormHelperText>hospital Telephone :</FormHelperText>
              <RHFTextField
                name="hospitalNumber"
                value={initialstate.hospitalPhoneNumberForEmergencies}
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item>
              <FormHelperText>Student ID :</FormHelperText>
              <RHFTextField
                name="studentRegNo"
                value={initialstate.registrationNumber}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>Select Blood Group :</FormHelperText>
              <RHFTextField
                name="bloodGroup"
                value={initialstate.bloodGroupName}
                disabled
                required
              ></RHFTextField>
            </Grid>
          </Grid>

          <Grid>
            <Grid item mt={5}>
              <FormHelperText>Do You Have Any Allegeries :</FormHelperText>
              <RHFTextField
                name="alleg"
                value={initialstate.doesStudenthaveAnyAllergy}
                disabled
                required
                SelectProps={{ native: true }}
              ></RHFTextField>
            </Grid>
            {watchAlleg === "1" ? (
              <Grid item>
                <RHFTextField
                  name="allergyType"
                  value={initialstate.allergyName}
                  disabled
                  required
                ></RHFTextField>
              </Grid>
            ) : null}

            {watchAllergyType === "Others" ? (
              <Grid item>
                <FormHelperText>Kindly specify the allergy*</FormHelperText>
                <RHFTextField
                  name="specificAllergy"
                  value={initialstate.specificAllergy}
                  disabled
                  variant="outlined"
                />
              </Grid>
            ) : null}

            <Grid item>
              <FormHelperText>
                Do You Have Any Medical Condition :
              </FormHelperText>
              <RHFTextField
                name="medicalCond"
                value={initialstate.doesStudentHaveAnyMedicalCondition}
                disabled
              ></RHFTextField>
            </Grid>
            {watchCondition === "1" ? (
              <Grid item mt={1}>
                <RHFTextField
                  name="medicalType"
                  value={initialstate.medicalConditionName}
                  disabled
                ></RHFTextField>
              </Grid>
            ) : null}
            {watchConditionType === "Others" ? (
              <Grid item>
                <RHFTextField
                  name="specifymedical"
                  value={initialstate.specificMedicalCondition}
                  disabled
                  label="Kindly specify the medical condition*"
                  variant="outlined"
                />
              </Grid>
            ) : null}
            <Grid item>
              <FormHelperText>Home Address </FormHelperText>
              <RHFTextField
                name="homeAddress"
                value={initialstate.homeAddress}
                disabled
                placeholder="Home Address"
                variant="outlined"
                multiline={true}
                minRows={3}
              />
            </Grid>
            <Grid item>
              <FormHelperText>Hospital Address </FormHelperText>
              <RHFTextField
                name="hospitalAddress"
                placeholder="Hospital Address"
                variant="outlined"
                multiline={true}
                minRows={3}
              />
            </Grid>
          </Grid>
          {/* ============================ */}
        </Grid>
      </FormProvider>
    </Grid>
  );
}

export default ViewStudent;
