import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function Sport({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // new====================
    sportEquipment: "",
    ageRangeEquipment: "",
    safetySignOnDisplay: "",
    coachPresent: "",
    freeOfDebris: "",
    studentSpace: "",
    spaceProvided: "",
    behaviourManagement: "",
    groundCoverage: "",
    evidenceOfStudentInSport: "",
    firstAid: "",
    sportRiskAssessment: "",
    groupObserved: "",
    sportObserved: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetObservationsStageOne?schoolId=${items[0]}&auditId=${items[1]}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         const currentSectionData = data.find(
  //           (x) => x.section === section && x.item === item
  //         );
  //         console.log(currentSectionData);
  //         console.log(section);
  //         console.log(item);
  //         if (currentSectionData !== undefined) {
  //           setValue("section", currentSectionData.section ?? "");
  //           setValue("item", currentSectionData.item ?? "");
  //           setValue("focus", currentSectionData.focus ?? "");
  //           setValue("loContext", currentSectionData.loContext ?? "");
  //           setValue(
  //             "yearGroupObserved",
  //             currentSectionData.yearGroupObserved ?? ""
  //           );
  //           setValue(
  //             "subjectObserved",
  //             currentSectionData.subjectObserved ?? ""
  //           );
  //           setValue("genderMix", currentSectionData.genderMix ?? "");
  //           setValue("schoolType", currentSectionData.schoolType ?? "");
  //           setValue(
  //             "otherUsefulObservation",
  //             currentSectionData.otherUsefulObservation ?? ""
  //           );

  //           setValue(
  //             "groupInformationPropsUsed",
  //             currentSectionData.groupInformationPropsUsed ?? ""
  //           );
  //           setValue(
  //             "isThereFireActionNotice",
  //             currentSectionData.isThereFireActionNotice ?? ""
  //           );
  //           setValue(
  //             "areSafeGuidesOnDisplay",
  //             currentSectionData.areSafeGuidesOnDisplay ?? ""
  //           );
  //           setValue(
  //             "teacherPupilsRapport",
  //             currentSectionData.teacherPupilsRapport ?? ""
  //           );
  //           setValue(
  //             "isRoomFreeOfHazard",
  //             currentSectionData.isRoomFreeOfHazard ?? ""
  //           );
  //           setValue(
  //             "isThereAccessToWater",
  //             currentSectionData.isThereAccessToWater ?? ""
  //           );
  //           setValue(
  //             "isClassTooLarge",
  //             currentSectionData.isClassTooLarge ?? ""
  //           );
  //           setValue("classControl", currentSectionData.classControl ?? "");
  //           setValue(
  //             "appropriateTeacherPupilRatio",
  //             currentSectionData.appropriateTeacherPupilRatio ?? ""
  //           );

  //           setValue(
  //             "evidenceOfSafeLearning",
  //             currentSectionData.evidenceOfSafeLearning ?? ""
  //           );
  //           setValue(
  //             "evidenceOfSafetyAwarenessForStaff",
  //             currentSectionData.evidenceOfSafetyAwarenessForStaff ?? ""
  //           );
  //           setValue(
  //             "evidenceOfRiskAssessmentInLessons",
  //             currentSectionData.evidenceOfRiskAssessmentInLessons ?? ""
  //           );
  //         }
  //       }
  //       //setGeneralSection(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId, section, item]);

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {};

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SubmitObservationsStageOne",
        {
          auditTaskId: taskId,
          sportEquipment: data.sportEquipment,
          ageRangeEquipment: data.ageRangeEquipment,
          safetySignOnDisplay: data.safetySignOnDisplay,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          coachPresent: data.coachPresent,
          freeOfDebris: data.freeOfDebris,
          studentSpace: data.studentSpace,
          spaceProvided: data.spaceProvided,
          behaviourManagemen: data.behaviourManagemen,
          groundCoverage: data.groundCoverage,
          evidenceOfStudentInSport: data.evidenceOfStudentInSport,
          firstAid: data.firstAid,
          sportRiskAssessment: data.sportRiskAssessment,
          groupObserved: data.groupObserved,
          sportObserved: data.sportObserved,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1B: Sport
                </Typography>
              </Box>

              <ol>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>Sporting equipment present?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="sportEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Equipment appropriate for sporting for the age ranges
                          of pupils?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="ageRangeEquipment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography> Safety signs on Display?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="safetySignOnDisplay"
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>Instructor/coach present?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="coachPresent"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Sporting ground/ hall free of debris/hazards or
                          obstructions?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="freeOfDebris"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box>
                      <li>
                        <Typography>
                          Space appropriate to students population?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="studentSpace"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Appropriate groups to space provided?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="spaceProvided"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                    <Box>
                      <li>
                        <Typography>
                          Evidence of effective behaviour management seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="behaviourManagement"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Appropriate ground coverings for the range of sporting
                          activities?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="groundCoverage"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          General evidence of pupils/students engagement in safe
                          sport ?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfStudentInSport"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>Evidence of first aid provision</Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="firstAid"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <li>
                        <Typography>
                          Evidence of risk assessment for sport on display?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="sportRiskAssessment"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="groupObserved"
                          label="Year Group Observed "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="sportObserved"
                          label="Types of sport observed"
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="comment"
                          label="Comments?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </li>
                    </Box>
                    <Grid sx={{ textAlign: "right" }}>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        // onClick={Navigate
                        loading={isSubmitting}
                        sx={{
                          width: 100,
                          color: "#fff",
                          background: "#018fe2",
                        }}
                        // startIcon={<Back />}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </ol>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default Sport;
