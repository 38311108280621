import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import { MotionInView } from "../../../components/animate";
import Vest from "../../../images/High visibility vest.png";
import AboutKid from "../../../images/aboutWallpaper.png";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function AboutUsSLider() {
  return (
    <Container sx={{ mt: 10, width: "100%" }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12}>
          <Card
            sx={{
              // width: "100%",
              minHeight: 100,
              backgroundColor: "#edf6fa",
              borderRadius: 0,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                // color={"#eef7fb"}
                sx={{ textAlign: "center" }}
              >
                ...Creating Safety Culture and Compliance
              </Typography>
              <Typography
                variant="body2"
                textAlign={"center"}
                component="div"
                sx={{
                  lineHeight: 2,
                }}
              >
                <span
                  style={{
                    color: "#FF0000",
                  }}
                >
                  A first of its kind
                </span>{" "}
                in Nigeria Quality Improvement (QI) programme towards the
                achievement of Sustainable Development Goal 4 (SDG 4) for
                education. More than half of preventable deaths can be averted
                by employing an effective safety management system.
              </Typography>
            </CardContent>
          </Card>

          <Card
            sx={{
              minWidth: 400,
              minHeight: 100,
              mt: 4,
              backgroundColor: "#edf6fa",
              borderRadius: 0,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                color={"red"}
                sx={{ textAlign: "center" }}
              >
                RATIONALE FOR SSLAG
              </Typography>
              <Box lineHeight={2}>
                <Typography fontSize={"13px"} textAlign={"center"} mt={1}>
                  Providing safe and conducive environment in schools is now law
                  in Lagos State
                </Typography>

                <Typography
                  textAlign={"center"}
                  fontSize={"13px"}
                  color={"error"}
                  mt={1}
                >
                  SSLAG is backed by the Lagos State Safety Law number C187 of
                  July 2011, Section 9A-1
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
