import React from "react";
import { useState } from "react";
import { Box as Grid, Typography } from "@mui/material";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { LoadingButton } from "@mui/lab";

function AdminAnalytic({ consultantArray, consultantDelete, deleteLoading }) {
  const [indexToDelete, setIndexToDelete] = useState("");

  const handleDelete = async (indexToDelete, consultantToDelete) => {
    setIndexToDelete(indexToDelete);
    await consultantDelete(consultantToDelete);
  };
  return (
    <Grid mt={5}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ width: "25%" }}>Title</Typography>
        <Typography sx={{ width: "25%" }}>First Name</Typography>
        <Typography sx={{ width: "25%" }}>Last Name</Typography>
      </Grid>
      <hr />
      <Grid mt={2}>
        {consultantArray != null && consultantArray.length > 0 ? (
          consultantArray.map((item, index, array) => (
            <Grid
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {item.title.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.title.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.title}</Typography>
              )}
              {/* <Typography sx={{ width: "20%" }}>{item.fullName}</Typography> */}

              {item.firstName.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.firstName.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.firstName}</Typography>
              )}

              {item.lastName.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.lastName.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.lastName}</Typography>
              )}

              {/* <Typography sx={{ width: "20%" }}>{item.imageUrl}</Typography> */}
              <LoadingButton
                loading={deleteLoading && indexToDelete === index}
                onClick={async () => await handleDelete(index, item)}
              >
                {" "}
                <DeleteForeverIcon />{" "}
              </LoadingButton>
            </Grid>
          ))
        ) : (
          <Typography>Add Training </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default AdminAnalytic;
