import { Box, Container, Grid, Typography, Stack, Alert } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function AdminCC2({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();


  const defaultValues = {
    safeRecruitment: "",
    commentOnSafeRecruitment: "",
    riskAssessmentPolicy: "",
    commentOnRiskAssessmentPolicy: "",
    safetyLogs: "",
    commentOnSafetyLogs: "",
    inductionForStaff: "",
    commentOnInductionForStaff: "",
    emergencyEvacuationPlan: "",
    commentOnEmergencyEvacuationPlan: "",
    accessControlAndSurveillance: "",
    commentOnAccessControlAndSurveillance: "",
    safetyProvisionBudget: "",
    commentOnSafetyProvisionBudget: "",
    outOfClassDutyRota: "",
    commentOnOutOfClassDutyRota: "",
    schoolSafetyDisplay: "",
    commentOnSchoolSafetyDisplay: "",
    appropriateToiletFacilities: "",
    commentOnAppropriateToiletFacilities: "",
    dailyActivityManagement: "",
    commentOnDailyActivityManagement: "",
    safeEquipmentUse: "",
    commentOnSafeEquipmentUse: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const option = [
    { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];
  const optionOne = [
    // { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];
  const optionTwo = [
    { label: "U", value: "U" },
    // { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];
  const optionThree = [
    { label: "U", value: "U" },

    { label: "E", value: "E" },
  ];
  const optionFour = [
    { label: "S", value: "S" },

    { label: "G", value: "G" },
  ];
  const optionFive = [
    { label: "E", value: "E" },

    { label: "G", value: "G" },
  ];

    
  useEffect(() => {
    let unmounted = false;
    const GetStartOfDay = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionNineCcTwo?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetStartOfDay();
    return () => {
      unmounted = true;
    };
  }, []);  
    
    
    
  const onSubmit =  () => {

  };
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  CC2: COMPLIANCE CRITERION 2
                </Typography>
              </Box>

              <Box sx={{ marginBlock: 1 }}>
                <Typography>Evidence of Safe Recruitment</Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Completed Schools own protocol for recruitment but not
                    contacted the safety commission is U, Safety Commission must
                    be notified of all new staff and staff disengagements ='S'
                    comnination of both 0+1 above =G,0+1+2+ the true identity or
                    Class valve =E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="safeRecruitment"
                    getOptionLabel={option.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSafeRecruitment"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              {/* 1 */}
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  A risk assessment policy statement with names of the staff
                  responsible for the monitoring of safety in the school
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Policy seen =S, policy + names-G ,1+2 Completed risk
                    assessments for activities carried out =E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="riskAssessmentPolicy"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnRiskAssessmentPolicy"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>

              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Appropriate safety logs kept & completed Checklists
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    3 amongst items listed below-S, 4-5-G ,3: 6 and above = E
                    1+2+ Fire drills, fire equipment maintenance log,
                    Generator's maintenance logs, completed SS checklists;
                    record of SS training attended by members of staff: Incident
                    logs, accidents logs Pupils registrar kept; medicine log,
                    visitors log.
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="safetyLogs"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSafetyLogs"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of Induction for both academic and non-academic staff
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    policy on Induction -S , evidence of induction log kept plus
                    I above-G, 1+2+ items and evidence of appropriate support
                    for staff to develop relevant Competences = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="inductionForStaff"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnInductionForStaff"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of appropriate emergency evacuation plan
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    This is either U or E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionThree.map((x) => x.value)}
                    name="emergencyEvacuationPlan"
                    getOptionLabel={optionThree.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnEmergencyEvacuationPlan"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of access control & Surveillance
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  Gated access with uniformed guard = S, 1+ control for pupil
                  pick = G, 1+2+ technology employed for enhanced security = E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="accessControlAndSurveillance"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnAccessControlAndSurveillance"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence for Safety provisions in the school budget
                </Typography>

                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    staff training budget on Curriculum, SSLAG Compliance items,
                    Parental Seminars to get Satisfactory mark all 3 must be
                    included in the school budget to get G rating: Evidence of
                    past events on 1,2 & 3 plus performance reviews log and
                    Emergency preparedness training for E rating: all the above
                    plus Evidence of celebration of achievements in either 1,2 3
                    and pupils or all
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionFive.map((x) => x.value)}
                    name="safetyProvisionBudget"
                    getOptionLabel={optionFive.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSafetyProvisionBudget"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Duty roster for out of class pupils monitoring
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    at least 2 rotas specific to common areas within the school
                    - S, 1+ rotas +G, 1+4 and above = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="outOfClassDutyRota"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnOutOfClassDutyRota"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Evidence of appropriate displays on school safety
                </Typography>
                <Box fontSize={"13px"} p={2}>
                  SSLAG MS12 (Day) = S, 1+ MS-8 (Boarding)/ SSLAG Compliance
                  Insurance on display, other appropriate display =G, 1+2+
                  information for visitors/ pupils on display-E
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="schoolSafetyDisplay"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSchoolSafetyDisplay"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  {" "}
                  Evidence of appropriate toilet facilities
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Staff toilets separate from pupils Gender specific toilet
                    facilities Approved Under eight Toilet facilities (1: 10
                    pupils) appropriate no of hand washing facilities provided
                    in the toilets All must be demonstrated to get S otherwise
                    is U, For Grating: Visitors Toilets must be available in
                    addition to criteria for S, E: Visitors Toilets and Disabled
                    access toilet must be available in addition to
                    the criteria for S
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="appropriateToiletFacilities"
                    getOptionLabel={option.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnAppropriateToiletFacilities"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              {/* 22 */}
              <Box sx={{ marginBlock: 1 }}>
                <Typography>
                  Space allocation and management of Daily activities
                </Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Space allocation and management of Daily activities Evidence
                    of Adequate space for specific activities e.g. Library,
                    Science laboratory, café or canteen, kitchen/food
                    preparation, Home economics lab, outdoor play area; sporting
                    pitch; Arts & craft; etc Chemicals/ toxic substances are
                    stored in clearly labeled jar/bottles and locked away from
                    unauthorised access; Evidence of an appropriate safety
                    policy in each specialist room S 1+ policy seen on display
                    and evidence of communication to members of staff-G; 1+2+
                    evidence of Risk assessment in practical activities planned
                    in specialist rooms
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="dailyActivityManagement"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnDailyActivityManagement"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <Typography> Evidence of Safe equipment use</Typography>
                <Box p={2}>
                  <Typography fontSize={"13px"}>
                    Evidence that All equipment checked regularly and logged in
                    specialist rooms/labs-S ,1+ evidence of training for Staff
                    operating special equipment and informationon its safe
                    operation, 1+2+ maintenance log/defective equipment are
                    logged and packed away out of the reach of students = E
                  </Typography>
                </Box>

                <Box>
                  <RHFRadioGroup
                    options={optionOne.map((x) => x.value)}
                    name="safeEquipmentUse"
                    getOptionLabel={optionOne.map((x) => x.label)}
                  />
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  name="commentOnSafeEquipmentUse"
                  label="Comment"
                  variant="filled"
                  multiline
                  minRows={2}
                />
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminCC2;
