import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/Iconify";

function Ministandard() {
  const [expanded, setExpanded] = useState(false);

  const Top = {
    // position: "absolute",
    width: "40px",
    height: "40px",
    background: "#6495ed",
    borderRadius: "20px",
    color: "#fff",
  };

  const Blue = {
    background: "#6495ed",
    width: "30px",
    height: "30px",
    color: "#fff",
  };

  const Boarding = [
    {
      no: "1",
      id: "Declaration of Hostel Safety Principles and Practice",
      value:
        " main focus An adequate declaration of the school's hostel safety principles and practices made available to key stakeholders with demonstrable evidence of implementation in real life.  ",
    },
    {
      no: "2",
      id: "Orientation and Support for Freshers",
      value: "",
    },
    {
      no: "3",
      id: "Health and Wellbeing of Pupils",
      value: "",
    },
    {
      no: "4",
      id: "Pupils Safety i9nn Hostels",
      value: "",
    },
    {
      no: "5",
      id: "Behaviour Management and Anti-bullying Strategy",
      value: "",
    },
    {
      no: "6",
      id: "Stakeholders Engagements, Prefect System and Leadership",
      value: "",
    },
    {
      no: "7",
      id: "Contact with Parent and Guardians",
      value: "",
    },
    {
      no: "8",
      id: "Hostel Accomodation",
      value: "",
    },
  ];

  return (
    <>
      <Grid display={"flex"}>
        <Box container style={Blue} textAlign={"center"}>
          <Typography variant="h5">#</Typography>
        </Box>
        <Box paddingLeft={2}>
          <Typography variant="h4">Boarding Standard</Typography>
        </Box>
      </Grid>
      {/* {QuickguideList.map((accordion) => (
        <Accordion key={accordion.id}>
          <AccordionSummary
            expandIcon={
              <Iconify
                icon={"eva:arrow-ios-downward-fill"}
                width={20}
                height={20}
              />
            }
          >
            <Typography variant="subtitle1">{accordion.id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <img
              src={accordion.image}
              style={{ width: "600px" }}
              alt="sampling alt"
            />
          </AccordionDetails>
        </Accordion>
      ))} */}

      {Boarding.map((accordion) => (
        <Accordion sx={{ padding: 2 }} key={accordion.id}>
          {/* <Box style={Top}>
            <Typography textAlign={"center"} mt={1}>
              {accordion.no}
            </Typography>
          </Box> */}

          <Accordion>
            <AccordionSummary
              expandIcon={
                <Iconify
                  icon={"eva:arrow-ios-downward-fill"}
                  width={20}
                  height={20}
                />
              }
            >
              <Typography variant="subtitle1">{accordion.id}</Typography>
            </AccordionSummary>
            <AccordionDetails>{accordion.value}</AccordionDetails>
          </Accordion>
        </Accordion>
      ))}
    </>
  );
}

export default Ministandard;
