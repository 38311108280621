import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";

function LessonObservationAdmin({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // new====================
    groupInformationPropsUsed: "",
    subjectObserved: "",
    otherUsefulObservation: "",
    numberOfSupportStaff: "",
    genderMix: "",
    evidenceOfSafetyAwarenessForStaff: "",
    yearGroupObserved: "",
    evidenceOfRiskAssessmentInLessons: "",
    isThereFireActionNotice: "",
    areSafeGuidesOnDisplay: "",
    teacherPupilsRapport: "",
    isRoomFreeOfHazard: "",
    isThereAccessToWater: "",
    isClassTooLarge: "",
    classControl: "",
    evidenceOfSafeLearning: "",
    appropriateTeacherPupilRatio: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  useEffect(() => {
    let unmounted = false;
    const GetLessonObservation = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionOneLessonObservation?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetLessonObservation();
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = () => {};
  return (
    <Fragment>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} m={2}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <Box textAlign={"center"} mt={5}>
            <Typography
              variant="h5"
              sx={{
                background: "#00bbea",
                textAlign: "centre",
                // mt: 2,
                p: 2,
              }}
            >
              Section 1A: 1 Lesson Observation
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
              <Box>
                <Typography>Group Information/ Props Used?</Typography>
                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="groupInformationPropsUsed"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>

              <Box>
                <Typography>Is fire action notice available?</Typography>
                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="isThereFireActionNotice"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>Are safety guidelines on display?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  getOptionLabel={option.map((x) => x.label)}
                  name="areSafeGuidesOnDisplay"
                />
              </Box>
              <Box>
                <Typography>Teacher/Pupils Rapport?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="teacherPupilsRapport"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>Is room free of physical hazards?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="isRoomFreeOfHazard"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>Is access to water available?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="isThereAccessToWater"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>Are class size too large?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="isClassTooLarge"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
            </Box>

            <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
              <Box>
                <Typography>
                  Effective behaviour management/class control?
                </Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="classControl"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box sx={{ marginBlock: 1, width: 400 }}>
                <Typography>
                  Appropriate teacher/pupil ratio maintained?
                </Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="appropriateTeacherPupilRatio"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>
                  General evidence of a safe learning taking place?
                </Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="evidenceOfSafeLearning"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <Typography>
                  Evidence of safety awareness &approved professional
                  development for staff on school safety?
                </Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="evidenceOfSafetyAwarenessForStaff"
                    getOptionLabel={option.map((x) => x.label)}
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography>Evidence of risk assessment in lesson?</Typography>

                <RHFRadioGroup
                  options={option.map((x) => x.value)}
                  name="evidenceOfRiskAssessmentInLessons"
                  getOptionLabel={option.map((x) => x.label)}
                />
              </Box>
              <Box>
                <RHFTextField
                  name="yearGroupObserved"
                  label="Year Group Observed"
                  variant="filled"
                />
              </Box>
            </Box>

            {/* 222222222222222222222222222 */}
            <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
              <Box>
                <RHFTextField
                  name="subjectObserved"
                  label="Subject Observed"
                  variant="filled"
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <RHFTextField
                  name="genderMix"
                  label="gender eg (male, female, both)"
                  variant="filled"
                />
              </Box>
              <Box sx={{ marginBlock: 1 }}>
                <RHFTextField
                  name="numberOfSupportStaff"
                  label="Number of support staff"
                  variant="filled"
                  type="number"
                />
              </Box>

              <Box sx={{ marginBlock: 1 }}>
                <RHFTextField
                  name="comment"
                  label="Comments?"
                  variant="filled"
                  multiline
                  minRows={3}
                />
              </Box>
            </Box>

            {/* ================================================================= */}
          </Box>
        </Stack>
      </FormProvider>
    </Fragment>
  );
}

export default LessonObservationAdmin;
