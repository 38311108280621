import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography, Container, TextField } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";

import { useSnackbar } from "notistack";
import { useParams } from "react-router";
// import logoBg from "../../images/sslagBookingForm";

function AdminJourneyBookingForm() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [cbcBookingForm, setAllCBCBookingForm] = useState(null);

  useEffect(() => {
    const getAllJourneyBookingForm = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/SchoolIntervention/GetInsetBookingInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setAllCBCBookingForm(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllJourneyBookingForm();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (cbcBookingForm === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (cbcBookingForm === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found....</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Box width={500} textAlign={"center"}>
          <Box>
            <Grid ml={17}>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 300,
                }}
              />
            </Grid>
          </Box>
          <Typography variant="h4">SSLAG CBC Booking Form</Typography>
        </Box>

        <Container
          sx={{
            background: "#ffff",
            borderRadius: 2,
            boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box width={"50%"}>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>1.</Typography>
                  <Typography>Your Full Name</Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.fullName}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>2.</Typography>
                  <Typography>Name of School</Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  placeholder="Write"
                  value={cbcBookingForm.schoolName}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>3.</Typography>
                  <Typography>
                    School Email Address & TelePhone Number
                  </Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.schoolEmailAddress}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>3.</Typography>
                  <Typography>School TelePhone Number</Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.schoolTelephoneNumber}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>4.</Typography>
                  <Typography>Your Personnal Contact Number</Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.personnalContactAddress}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
            </Box>

            <Box width={"50%"}>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>5.</Typography>
                  <Typography>Your Job Role</Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.jobRole}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>6.</Typography>
                  <Typography>
                    No. of delegates expected to attend from your school
                  </Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.numberOfDelegates}
                  variant="filled"
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>7.</Typography>
                  <Typography>
                    Please, list all the name of all the delegates that will be
                    attending the CBC. it is vital that the name are written
                    correctly as it will be used to issue certificate of
                    attendance
                  </Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.namesOfDelegates}
                  placeholder="Describe Visit"
                  multiline
                  variant="filled"
                  minRows={4}
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>

              <Box sx={{ marginBlock: 2 }}>
                <Box display={"flex"}>
                  <Typography>8.</Typography>
                  <Typography ml={1}>
                    If you any additional information,please state here
                  </Typography>{" "}
                </Box>

                <TextField
                  id="filled-textarea"
                  value={cbcBookingForm.anyAdditionalInformation}
                  placeholder="Describe Visit"
                  multiline
                  variant="filled"
                  minRows={4}
                  sx={{ width: 400, mt: 2 }}
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminJourneyBookingForm;
