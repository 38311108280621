import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Alert,
  Stack,
  Card,
  CardActions,
  CardContent,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../components/Iconify";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import styleds from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import React from "react";
import Label from "../../../components/Label";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../components/hook-form";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const TextField = styleds.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styleds(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

// ----------------------------------------------------------------------

export default function PastEvnt() {
  const [event, setAllEvent] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [currentConcession, setCurrentConcession] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const theme = useTheme();
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  useEffect(() => {
    let unmounted = false;

    const getAllPastEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Events/GetPastEvents`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllEvent([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getAllPastEvent();

    return () => {
      unmounted = true;
    };
  }, [id]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "50px",
    },
    {
      name: "Event Name",
      selector: (row) => row.name,
    },

    {
      name: "Description",
      selector: (row) => row.description,
    },

    {
      name: "Venue",
      selector: (row) => row.venue,
    },
    {
      name: "Date ",
      selector: (row) => row.date.split("T")[0],
      // .split("T")[0]
    },

    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <Label
    //       variant={theme.palette.mode === "light" ? "ghost" : "filled"}
    //       color={(row.isPaid && "success") || (!row.isPaid && "error")}
    //     >
    //       {row.isPaid ? sentenceCase("Paid") : sentenceCase("Not Paid")}
    //     </Label>
    //   ),
    // },
  ];

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Event...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%", minWidth: "100%" }}
      >
        <Grid>
          <Box>
            {event.length === 0 ? (
              <Typography textAlign={"center"}>No Event</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={event}
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Box>
        </Grid>
      </Container>
    </RootStyle>
  );
}
