import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  FormControlLabel,
  Radio,
  FormControl,
  Stack,
  Alert,
} from "@mui/material";
import logoBg from "../../images/sslag.png";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  RHFTextField,
  FormProvider,
  RHFRadioGroup,
} from "../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";

function SchoolCJSaferecruiment() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const defaultValues = {
    doYouHaveBackgroundVettingProcedure: "",
    areReferencesSought: "",
    requiredBackgroundChecksForStaff: "",
    doYouHaveRecruitmentPolicy: "",
    recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl: "",
    reasonForNoRecruitmentPolicy: "",
    totalNumOfTeachingStaff: "",
    totalNumOfNonTeachingStaff: "",
    doYouEngageVolunteersInSchool: "",
    doYouHaveVendorsInPremises: "",
    howDoYouEngageVendors: "",
    vendorEngagementProcedureImageId: "",
    vendorEngagementProcedureImageUrl: "",
    doYouEngageBackgroundVettingCompany: "",
    backgroundVettingCompanyDetails: "",
  };
  const [state, setState] = useState({
    recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl: "",
  });

  const methods = useForm({
    // resolver: yupResolver(SummmarySheetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { recruitmentPolicyImageId, recruitmentPolicyImageUrl } =
            response.data.data;

          setState({
            ...state,
            recruitmentPolicyImageId: recruitmentPolicyImageId,
            recruitmentPolicyImageUrl: recruitmentPolicyImageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [vettingProcedure, setVettingProceduce] = React.useState("");
  const [policyAttachment, setPolicyAttachment] = React.useState("");
  const [schoolVolunter, setSchoolVolunter] = React.useState("");
  const [thirdPartyVendor, setThirdPartyVendor] = React.useState("");
  const [vettingCompany, setVettingCompany] = React.useState("");

  const handleChangeVetting = (event) => {
    setVettingProceduce(event.target.value);
  };
  const handleChangePolicy = (event) => {
    setPolicyAttachment(event.target.value);
  };
  const handleChangeVolunter = (event) => {
    setSchoolVolunter(event.target.value);
  };
  const handleChangepartyVendor = (event) => {
    setThirdPartyVendor(event.target.value);
  };
  const handleChangeCompany = (event) => {
    setVettingCompany(event.target.value);
  };

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    { label: "Don't Know", value: "don't know" },
  ];

  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    // { label: "Don't", value: "don't know" },
  ];

  const watchProcedure = watch("doYouHaveBackgroundVettingProcedure");
  const watchAttachment = watch("doYouHaveRecruitmentPolicy");
  const watchVendors = watch("doYouHaveVendorsInPremises");
  const watchVetting= watch("doYouEngageBackgroundVettingCompany");




  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitSafeRecruitmentInterventionForm`,
        {
          schoolId,
          token,
          doYouHaveBackgroundVettingProcedure:
            data.doYouHaveBackgroundVettingProcedure,
          areReferencesSought: data.areReferencesSought,
          requiredBackgroundChecksForStaff:
            data.requiredBackgroundChecksForStaff,
          doYouHaveRecruitmentPolicy: data.doYouHaveRecruitmentPolicy,
          recruitmentPolicyImageId: data.recruitmentPolicyImageId,
          recruitmentPolicyImageUrl: data.recruitmentPolicyImageUrl,
          reasonForNoRecruitmentPolicy: data.reasonForNoRecruitmentPolicy,
          totalNumOfTeachingStaff: data.totalNumOfTeachingStaff,
          totalNumOfNonTeachingStaff: data.totalNumOfNonTeachingStaff,
          doYouEngageVolunteersInSchool: data.doYouEngageVolunteersInSchool,
          doYouHaveVendorsInPremises: data.doYouHaveVendorsInPremises,
          howDoYouEngageVendors: data.howDoYouEngageVendors,
          vendorEngagementProcedureImageId:
            data.vendorEngagementProcedureImageId,
          vendorEngagementProcedureImageUrl:
            data.vendorEngagementProcedureImageUrl,
          doYouEngageBackgroundVettingCompany:
            data.doYouEngageBackgroundVettingCompany,
          backgroundVettingCompanyDetail: data.backgroundVettingCompanyDetail,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid>
          <Box textAlign={"center"}>
            <Box>
              <Grid>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 200,
                  }}
                />
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
              }}
            >
              Safe Recruitment Evaluation
            </Typography>
          </Box>
        </Grid>
        <Container>
          <Box mt={3}>
            <Typography variant="h6">School Name</Typography>
          </Box>
          <Grid>
            <Box
              sx={{
                background: "#097969",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                Safe Recruitment Evaluation Form?
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Typography mt={2}>
              The Lagos State Government is committed to ensuring safety culture
              and compliance in schools and as such Safe Schools Lagos framework
              sets out the guidelines for safe recruitment of all members of
              staff, vendors and volunteers in schools. Please fill in this
              questionnaire to determine how well your establishment is doing in
              this regard. Thank you.
            </Typography>
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Box p={2}>
              <Box sx={{ marginBlock: 2 }}>
                <Typography>
                  Do you have a background vetting procedure?
                </Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="doYouHaveBackgroundVettingProcedure"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  {watchProcedure === "no" ? (
                    <Grid>
                      <Typography>state if references are sought?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areReferencesSought"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Grid>
                  ) : null}
                </FormControl>
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField
                  name="requiredBackgroundChecksForStaff"
                  label="What background checks do you require of new 
                  members of staff? List all that apply"
                  variant="filled"
                  multiline
                  minRows={3}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Typography>Do you have a recruitment policy??</Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={options.map((x) => x.value)}
                    name="doYouHaveRecruitmentPolicy"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  {watchAttachment === "no" ? (
                    <Grid>
                      <Typography>
                        If not attached , briefly state why??
                      </Typography>
                      <Box sx={{ marginBlock: 2 }}>
                        <RHFTextField
                          name="reasonForNoRecruitmentPolicy"
                          label="If not attached , briefly state why?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                  {/* recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl */}
                  {/* {watchAttachment === "yes" ? (
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "400px",
                        marginTop: "10px",
                        padding: "6px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "12px",
                      }}
                    >
                      <div>
                        <input
                          type="file"
                          onChange={(event) => {
                            setImageSelected(event.target.files[0]);
                          }}
                        />
                        <button
                          onClick={async () => await uploadImage()}
                          disabled={
                            imageUploading ||
                            state.recruitmentPolicyImageUrl.length > 0
                          }
                        >
                          Upload Evidence
                        </button>
                      </div>
                    </div>
                  ) : null} */}
                </FormControl>
              </Box>
              <Box width={400}>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="totalNumOfTeachingStaff"
                    label="Please state the total number of teaching staff"
                    variant="filled"
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="totalNumOfNonTeachingStaff"
                    label="Please state the total number of non-teaching staff"
                    variant="filled"
                  />
                </Box>
              </Box>

              <Box sx={{ marginBlock: 2 }}>
                <Typography>Do you engage volunteers in the school?</Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={options.map((x) => x.value)}
                    name="doYouEngageVolunteersInSchool"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  <Grid>
                    <Typography>
                      Do you have vendors or 3rd parties / vendors employed
                      within the school premises
                    </Typography>
                    <Box sx={{ marginBlock: 2 }}>
                      <RHFRadioGroup
                        options={options.map((x) => x.value)}
                        name="doYouHaveVendorsInPremises"
                        getOptionLabel={option.map((x) => x.label)}
                      />

                      {watchVendors === "yes" ? (
                        <Grid>
                          <Box sx={{ marginBlock: 2 }}>
                            <RHFTextField
                              name="howDoYouEngageVendors"
                              label="How do you engage them?"
                              variant="filled"
                              multiline
                              minRows={3}
                            />
                          </Box>
                        </Grid>
                      ) : null}
                    
                    </Box>
                  </Grid>

                  <Grid>
                    <Typography>
                      Do you engage a Background vetting company?
                    </Typography>
                    <Box sx={{ marginBlock: 2 }}>
                      <RHFRadioGroup
                        options={options.map((x) => x.value)}
                        name="doYouEngageBackgroundVettingCompany"
                        getOptionLabel={option.map((x) => x.label)}
                      />

                      {watchVetting === "yes" ? (
                        <Grid>
                          <Box sx={{ marginBlock: 2 }}>
                            <RHFTextField
                              name="backgroundVettingCompanyDetail"
                              label="if yes give contact details of the 
                          organisation?"
                              variant="filled"
                              multiline
                              minRows={3}
                            />
                          </Box>
                        </Grid>
                      ) : null}
                    </Box>
                  </Grid>
                </FormControl>
              </Box>
              <Grid item sx={{ textAlign: "right" }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    width: 160,
                  }}
                >
                  Sumbit
                </LoadingButton>
              </Grid>
            </Box>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SchoolCJSaferecruiment;
