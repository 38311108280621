import React, { useEffect } from "react";
import {
  TextField,
  Typography,
  FormControl,
  Grid,
  Button,
  Container,
  Select,
  MenuItem,
  FormHelperText,
  InputBase,
  InputLabel,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Stack from "@mui/material/Stack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";

import useAuth from "../../hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { formatRFC3339 } from "date-fns";
// import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import Next from "@material-ui/icons/ChevronRight";

function SchoolDetails({ nextStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useAuth();
  const [address, setAddress] = useState("");
  const [latLng, setLatLng] = useState({});

  const { email, schoolTypeName, schoolName, schoolId } = userData;

  const initialstate = {
    address: "",
    startOfDay: "",
    endOfDay: "",
    numberOfMaleStudents: "",
    numberOfFemaleStudents: "",
    genderType: "",
    genderTypeId: "",
    contactNumber: "",
    schoolProgramId: "",
  };

  const [state, setState] = useState(initialstate);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const handleTimeChange = (value, type) => {
    setState({
      ...state,
      [type]: formatRFC3339(value),
    });
  };

  const handleGenderChange = (e) => {
    // setGender(event.target.value);
    setState({
      ...state,
      genderTypeId: e.target.value,
    });
  };
  const handleProgramChange = (e) => {
    setState({
      ...state,
      schoolProgramId: e.target.value,
    });
  };

  // ============================================================
  // ============================================================

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    try {
      const {
        startOfDay,
        endOfDay,
        numberOfMaleStudents,
        numberOfFemaleStudents,
        genderTypeId,
        contactNumber,
        address,
        schoolProgramId,
      } = state;

      // ---------------------------------------------------------------------
      const splitArray1 = startOfDay.split("T");
      const splitArray2 = endOfDay.split("T");

      const time1 = `${splitArray1[0]} ${splitArray1[1].split("+")[0]}`;
      const time2 = `${splitArray2[0]} ${splitArray2[1].split("+")[0]}`;
      // ----------------------------------------------------------------------
      const response = await axoisCustomAgent.put(
        `/SchoolOnboarding/UpdateSchoolDetails/${schoolId}`,
        {
          address,
          startOfDay: time1,
          endOfDay: time2,
          schoolProgramId,
          genderTypeId,
          numberOfMaleStudents: genderTypeId === 2 ? 0 : numberOfMaleStudents,
          numberOfFemaleStudents:
            genderTypeId === 1 ? 0 : numberOfFemaleStudents,
          contactNumber,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      const { status } = error;
      if (status === 400) {
        enqueueSnackbar("Please fill all available fields", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("check again", { variant: "error" });
      }
    }
    setLoadingSubmit(false);
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

  const FormContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: 40,

      "& > div": {
        width: "50%",
      },
    },
  }));

  // const getLatLngFromAddress = (e) => {
  //   const geocoder = new window.google.maps.Geocoder();

  //   return new Promise((resolve, reject) => {
  //     geocoder.geocode({ e.target.value }, (results, status) => {
  //       if (status === "OK") {
  //         const { lat, lng } = results[0].geometry.location;
  //         resolve({ lat, lng });
  //       } else {
  //         reject(new Error(`Geocode failed: ${status}`));
  //       }
  //     });
  //   });
  // };

  // console.log(getLatLngFromAddress);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingInline: "24px",
        background: ` url(${Bg1}) `,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // maxWidth: "min(1200px, 100%)",
          width: "100%",
          marginBottom: 3,
        }}
      >
        <Grid sx={{ justifyContent: "left", padding: 2 }}>
          <Grid>
            <Logo />
          </Grid>
        </Grid>

        <Grid sx={{ padding: 1, justifyContent: "right" }}>
          <Button
            // variant="outlined"
            color="primary"
            href="/auth/login"
            onClick={async () => await handleLogout()}
            sx={{ fontSize: 20 }}
          >
            Sign Out
          </Button>
        </Grid>
      </Grid>

      <Grid sx={{ alignSelf: "center" }}>
        <FormControl>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              padding: 4,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
              minWidth: "md",
              mb: 30,
            }}
          >
            <Typography color={"#6495ed"} paddingInline={3}>
              SCHOOL DETAILS{" "}
            </Typography>

            {loading ? (
              <p>Loading.........</p>
            ) : (
              <Grid
                // spacing={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid mt={2}>
                  <Stack width={350}>
                    <TextField
                      sx={{ marginBlock: 2 }}
                      id="SchoolName"
                      label="School name"
                      variant="filled"
                      required
                      value={schoolName ?? ""}
                      onChange={(e) => handleChange(e, "schoolName")}
                      disabled
                    />
                    <TextField
                      sx={{ marginBlock: 2 }}
                      id="Address"
                      label="Address"
                      variant="filled"
                      required
                      value={state.address}
                      // onBlur={getLatLngFromAddress}
                      onChange={(e) => handleChange(e, "address")}
                    />
                    <TextField
                      sx={{ marginBlock: 2 }}
                      id="TypeOfSchool"
                      label="Type of school"
                      variant="filled"
                      required
                      value={schoolTypeName ?? ""}
                      onChange={(e) => handleChange(e, "schoolTypeName")}
                      disabled
                    />
                    <TextField
                      sx={{ marginBlock: 2 }}
                      id="SchoolEmail"
                      label="School email"
                      variant="filled"
                      required
                      value={email ?? ""}
                      onChange={(e) => handleChange(e, "email")}
                      disabled
                    />
                  </Stack>
                </Grid>
                <Grid mt={4} paddingInline={5}>
                  <Stack direction="row" width={400} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Start of Day"
                        value={state.startOfDay}
                        disableMaskedInput
                        onChange={(value) =>
                          handleTimeChange(value, "startOfDay")
                        }
                        renderInput={(params) => (
                          <TextField variant="filled" {...params} />
                        )}
                        on
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="End of school day"
                        value={state.endOfDay}
                        disableMaskedInput
                        onChange={(value) =>
                          handleTimeChange(value, "endOfDay")
                        }
                        renderInput={(params) => (
                          <TextField variant="filled" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack>
                    <TextField
                      sx={{ mt: 4 }}
                      id="SchoolNum"
                      label="School telephone number"
                      variant="filled"
                      type="number"
                      required
                      value={state.contactNumber}
                      onChange={(e) => handleChange(e, "contactNumber")}
                    />

                    {/* ============================= */}

                    <FormControl
                      variant="filled"
                      label="School Programme"
                      sx={{ marginBlock: 4 }}
                    >
                      {/* <FormHelperText>School Programme</FormHelperText> */}
                      <InputLabel id="demo-simple-select-filled-label">
                        School Programme
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={state.schoolProgramId}
                        onChange={handleProgramChange}
                      >
                        <MenuItem value="" selected>
                          Select Categories
                        </MenuItem>
                        <MenuItem value={1}>Boarding </MenuItem>
                        <MenuItem value={2}>Day</MenuItem>
                      </Select>
                    </FormControl>

                    {/* ============================ */}
                    {/* <FormControl variant="filled">
                      <FormHelperText>School Programme</FormHelperText> */}

                    {/* <Select
                        labelId="SchoolProgramId"
                        id="SchoolProgramId"
                        value={state.schoolProgramId}
                        label="School Gender Type"
                        onChange={handleProgramChange}
                        input={<BootstrapInput />}
                      >
                        <MenuItem value="" selected>
                          Select Categories
                        </MenuItem>

                        {/* <MenuItem value={0}>Select Categories </MenuItem> */}
                    {/* <MenuItem value={1}>Boarding </MenuItem>
                        <MenuItem value={2}>Day</MenuItem> */}
                    {/* </Select>  */}

                    {/* <Select
                        labelId="SchoolProgramId"
                        id="SchoolProgramId"
                        value={state.schoolProgramId}
                        label="School programme"
                        onChange={handleProgramChange}
                        required
                      >
                        {" "}
                        <MenuItem value={0}>Select Categories </MenuItem>
                        <MenuItem value={1}>Boarding </MenuItem>
                        <MenuItem value={2}>Day</MenuItem>
                      </Select> */}
                    {/* </FormControl> */}
                    {/* ======================================= */}

                    <FormControl variant="filled" sx={{}}>
                      <InputLabel id="demo-simple-select-filled-label">
                        State if co-educational or single gender
                      </InputLabel>
                      {/* <InputLabel id="demo-simple-select-filled-label"> */}

                      {/* </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={state.genderTypeId}
                        onChange={handleGenderChange}
                      >
                        <MenuItem value="" selected>
                          Select Categories
                        </MenuItem>
                        <MenuItem value={1}>Single (male)</MenuItem>
                        <MenuItem value={2}>Single (female)</MenuItem>
                        <MenuItem value={3}>Co-educational </MenuItem>
                      </Select>
                    </FormControl>

                    {/* ========================================= */}

                    {/* <FormControl variant="filled">
                      <FormHelperText>
                        State if co-educational or single gender
                      </FormHelperText>

                      <Select
                        labelId="SchoolGenderType"
                        id="SchoolGenderType"
                        value={state.genderTypeId}
                        label="School Gender Type"
                        onChange={handleGenderChange}
                        input={<BootstrapInput />}
                      >
                        <MenuItem value="" selected>
                          Select Categories
                        </MenuItem>

                        <MenuItem value={1}>Single (male)</MenuItem>
                        <MenuItem value={2}>Single (female)</MenuItem>
                        <MenuItem value={3}>Co-educational </MenuItem>
                      </Select>
                    </FormControl> */}

                    {state.genderTypeId === 1 ? (
                      <TextField
                        sx={{ mt: 2, width: 400 }}
                        id="NoOfStudent"
                        label="No of Student"
                        type="number"
                        variant="filled"
                        required
                        value={state.numberOfMaleStudents}
                        onChange={(e) =>
                          handleChange(e, "numberOfMaleStudents")
                        }
                      />
                    ) : state.genderTypeId === 2 ? (
                      <TextField
                        sx={{ mt: 2, width: 400 }}
                        id="NoOfStudent"
                        label="No of Student"
                        type="number"
                        variant="filled"
                        required
                        value={state.numberOfFemaleStudents}
                        onChange={(e) =>
                          handleChange(e, "numberOfFemaleStudents")
                        }
                      />
                    ) : state.genderTypeId === 3 ? (
                      <Stack>
                        <TextField
                          sx={{ mt: 2, mr: 2, width: 400 }}
                          id="No OfFemale"
                          label="No of female"
                          type="number"
                          variant="filled"
                          required
                          value={state.numberOfFemaleStudents}
                          onChange={(e) =>
                            handleChange(e, "numberOfFemaleStudents")
                          }
                        />
                        <TextField
                          sx={{ mt: 2, mr: 2, width: 400 }}
                          id="NoOfMale"
                          label="No of male"
                          type="number"
                          variant="filled"
                          required
                          value={state.numberOfMaleStudents}
                          onChange={(e) =>
                            handleChange(e, "numberOfMaleStudents")
                          }
                        />
                      </Stack>
                    ) : null}
                  </Stack>

                  <Grid sx={{ mt: 6, textAlign: "right" }}>
                    <LoadingButton
                      loading={loadingSubmit}
                      endIcon={<Next />}
                      variant="contained"
                      type="submit"
                      onClick={async () => await handleSubmit()}
                      // onClick={testSubmit}
                    >
                      Continue
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Container>
        </FormControl>
      </Grid>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "YOUR_API_KEY" }}
        // center={latLng}
        // defaultZoom={15}
      >
        <Marker lat={latLng.lat} lng={latLng.lng} />
      </GoogleMapReact> */}
    </Grid>
  );
}

export default SchoolDetails;
