import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
  Typography,
  Modal,
  Stack,
  Alert,
} from "@mui/material";
import AdminBG from "../../../images/background.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import { RHFTextField, FormProvider } from "../../../components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import RecommendedModal from "./RecommendedModal";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

function Recommendation({ nextStep, prevStep, auditId, schoolId }) {
  // const onSubmit = () => {};
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [recommendation, setAllRecommendation] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");

  const [direction, setDirection] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const defaultValues = {
    title: "",
    actions: "",
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const response = await axoisCustomAgentForAdmin
  const deleteRecommendation = async (indexToDelete, recommendationId) => {
    await axoisCustomAgentForAdmin.delete(
      `/Audit/DeleteRecommendationSectionEight?schoolId=${schoolId}&auditId=${auditId}&recommendationId=${recommendationId}`
    );

    const newArray = recommendation.filter(
      (s, index) => index !== indexToDelete
    );
    setAllRecommendation([...newArray]);
  };

  function handleDeleteTodo(index) {
    const newTodos = [...recommendation];
    newTodos.splice(index, 1);
    setDeleting(newTodos);
  }

  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetRecommendationsSectionEight?schoolId=${schoolId}&auditId=${auditId}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;

  //       setAllRecommendation(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId]);

  const addRecommendation = (newRecommendation) => {
    console.log(newRecommendation);
    let updatedRecommendations = [...recommendation];
    updatedRecommendations.push(newRecommendation);
    setAllRecommendation(updatedRecommendations);
    handleClose();
  };

  const navigate = useNavigate();
  let { taskId } = useParams();

  const onSubmit = async (e) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionTenRecommendation`,
        {
          auditTaskId: taskId,
          recommendations: [...recommendation],
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 300,
            width: "60%",
          }}
        >
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Box textAlign={"center"}>
            <Typography
              variant="h5"
              sx={{
                background: "#00bbea",
                // textAlign: "centre",
                mt: 2,
                p: 2,
              }}
            >
              SECTION 8:RECOMMENDATIONS
            </Typography>
          </Box>

          <Box
            sx={{
              textAlign: "center",
              mt: 2,
            }}
          >
            <Add
              onClick={handleOpen}
              style={{
                cursor: "pointer",

                width: "30px",
                height: "30px",
                color: "#018fe2",
              }}
            />

            <Typography>Add Recommendations</Typography>
          </Box>

          <Box mt={3}>
            {recommendation.map((eachItem, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box display={"flex"}>
                    <Box>
                      <Typography>{eachItem.title}</Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{eachItem.actions}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          <Box display={"flex"} justifyContent={"right"}>
            <Grid sx={{ p: 3 }}>
              <LoadingButton
                variant="outlined"
                loading={isSubmitting}
                onClick={async (e) => await onSubmit(e)}
                // onClick={nextStep}
                // onClick={Navigate}
                sx={{ color: "#fff", background: "#018fe2" }}
                // startIcon={<Back />}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Box>
        </Container>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(addRecommendation)}
          >
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Recommendation
              </Typography>
              <Box id="modal-modal-description" sx={{ mt: 2 }}>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="title"
                    label="Title"
                    variant="filled"
                    sx={{
                      width: 400,
                    }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="actions"
                    label=" Recommended Actions"
                    multiline
                    minRows={3}
                    variant="filled"
                    sx={{
                      width: 400,
                    }}
                  />
                </Box>

                <Box display={"flex"}>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Start Date "
                        disablePast
                        value={methods.watch().fromDate}
                        onChange={(value) => {
                          methods.setValue("fromDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="fromDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box ml={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Due Date"
                        disablePast
                        value={methods.watch().toDate}
                        onChange={(value) => {
                          methods.setValue("toDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="toDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Grid sx={{ p: 3, textAlign: "center" }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={}
                    sx={{ color: "#fff", width: 160, background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Add
                  </LoadingButton>
                </Grid>
                {/* <RecommendedModal
                    auditId={auditId}
                    schoolId={schoolId}
                    addRecommendation={addRecommendation}
                  /> */}
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default Recommendation;
