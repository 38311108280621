import React, { useCallback, useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Button,
  Modal,
  Box,
  Alert,
  Stack,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../components/hook-form";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// const TextField = styled.input`
//   height: 32px;
//   width: 200px;
//   border-radius: 3px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   border: 1px solid #e5e5e5;
//   padding: 0 32px 0 16px;
//   0 &:hover {
//     cursor: pointer;
//   }
// `;
// const ClearButton = styled(Button)`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    {/* <TextField
      id="search"
      type="text"
      placeholder="Filter By School Id"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton> */}
  </>
);

function SchoolCertificate() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [certificate, setAllCertificate] = useState([]);
  const [loading, setLoading] = useState(false);
  let { schoolId } = useParams();
  const [getCertificate, setGetCertificate] = useState(undefined);
  const [descriptionModal, setDescriptionModal] = React.useState(false);
  const [uploadmodal, setUploadModal] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTask, setCurrentTask] = useState(undefined);
  const [newCertificate, setNewCertificate] = useState([]);
  const [getschoolName, setGetSchoolName] = useState("");

  const [page, setPage] = React.useState(false);

  const defaultValues = {
    // new========================
    image: null,
  };

  const UploadSchema = Yup.object().shape({
    image: Yup.mixed().required("field  is required"),
  });
  const methods = useForm({
    resolver: yupResolver(UploadSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      console.log("xxxxxxxxxxxxxxxxxxxxxxxxx", file);

      if (file) {
        setValue(
          "image",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleClickOpenRegistration = () => {
    setUploadModal(true);
  };

  const handleCloseRegistration = () => {
    setUploadModal(false);
  };

  const handleOpenReject = (id) => {
    setCurrentTask(id);
    setDialogOpen(true);
  };

  const handleCloseReject = () => {
    setCurrentTask(undefined);
    setDialogOpen(false);
  };

  const DeleteTask = (id) => {
    const Task = [...newCertificate];
    const updatedTask = Task.filter((x) => x.id !== id);

    setAllCertificate(updatedTask);
  };

  const handlePageOpen = (certificate) => {
    setGetCertificate(certificate);
    setPage(true);
  };

  const handlePageClose = () => {
    setGetCertificate(undefined);
    setPage(false);
  };

  useEffect(() => {
    let unmounted = false;

    const getAllCertificate = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgentForAdmin.get(
          `BosCertificate/GetBosCertificates?schoolId=${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { bosCertificates, schoolName } = response.data.data;

        setAllCertificate([...bosCertificates]);
        setGetSchoolName(schoolName);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null && schoolId !== "") getAllCertificate();
    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const DeleteCert = async (idToDelete) => {
    try {
      const response = await axoisCustomAgentForAdmin.delete(
        `/BosCertificate/DeleteBosCertificate?id=${idToDelete}`
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleCloseReject();
      window.location.reload();
      // DeleteTask(idToDelete);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const Gridimage = {
    background: "#fafafa",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = certificate.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },

    {
      name: "School Name",
      selector: (row) => getschoolName,
    },
    {
      name: "Date Uploaded",
      selector: (row) => row.uploadedOn.split("T")[0],
    },
    {
      name: "View certificate",
      cell: (row) => (
        <Button variant="outlined" onClick={() => handlePageOpen(row)}>
          View
        </Button>
      ),
      // ),
    },
    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => handleOpenReject(row.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const onSubmit = async (data) => {
    //
    const formData = new FormData();
    formData.append("file", data.image);

    formData.append("schoolId", schoolId);

    try {
      const response = await axoisCustomAgentForAdmin.post(
        "BosCertificate/UploadSchoolBosCertificate",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }

    window.location.reload();
  };

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Certificate ...</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      ></Grid>

      <Grid textAlign={"right"}>
        <Button
          variant="contained"
          onClick={handleClickOpenRegistration}
          startIcon={<Iconify icon={"eva:plus-fill"} width={20} height={20} />}
        >
          Upload
        </Button>
      </Grid>
      <Card p={2} mt={4}>
        <CardHeader title="Schools Certificate" />
        <Grid style={Gridimage}>
          {certificate.length === 0 ? (
            <Typography>No School Present</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={certificate}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
            />
          )}
        </Grid>
      </Card>

      {/* ====================upload dialog */}

      <Dialog open={uploadmodal} onClose={handleCloseRegistration}>
        <DialogTitle> BOS Certification </DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <DialogContent
              sx={{
                mt: 3,
              }}
            >
              <DialogContentText>Upload certificate below</DialogContentText>

              <Box>
                <Stack spacing={1} m={3}>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Stack spacing={3}>
                  <Box>
                    <span>Upload BOS certificate for schools</span>

                    <Box sx={{ border: "1px dashed #333", p: 2 }}>
                      <Typography variant="body2">
                        Maximum file size: 1mb,jpeg,png
                      </Typography>
                      <Typography variant="body2">
                        file type: jpeg, png
                      </Typography>
                      <RHFUploadSingleFile
                        name="image"
                        accept="image/*"
                        maxSize={1024000}
                        onDrop={handleDrop}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </DialogContent>

            <Box>
              <LoadingButton
                size="large"
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      {/* -========================================== */}

      {/* ========Delete Certificate========================= */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Delete Certificate
        </DialogTitle>
        <DialogContent
          sx={{
            mt: 3,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this action?.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseReject}>No</Button>
          <Button onClick={() => DeleteCert(currentTask)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* ========================================= */}

      {/* =======view certificate===================== */}
      <Modal
        open={page}
        onClose={handlePageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          >
            BOS Certificate
          </Typography>

          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            {/*  */}
            <Box m={2}>
              <Box>
                <img
                  alt="sslag certificate"
                  src={!getCertificate ? "" : getCertificate.file}
                  style={{
                    height: 510,
                    width: 800,
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>
      {/* =================================== */}
    </Grid>
  );
}

export default SchoolCertificate;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ isActive, schoolId }) {
  const [open, setOpen] = useState(null);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTask, setCurrentTask] = useState(undefined);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  const [viewpage, setViewPage] = React.useState(false);
  const handleViewOpen = () => setViewPage(true);
  const handleViewClose = () => setViewPage(false);

  const [viewdutypage, setViewDutyPage] = React.useState(false);
  const handleDutyOpen = () => setViewDutyPage(true);
  const handleDutyClose = () => setViewDutyPage(false);

  const [viewstaff, setViewStaff] = React.useState(false);
  const handleStaffOpen = () => setViewStaff(true);
  const handleStaffClose = () => setViewStaff(false);

  const handleOpenReject = (taskId) => {
    // setCurrentTask(taskId);
    setDialogOpen(true);
  };

  const handleCloseReject = () => {
    // setCurrentTask(undefined);
    setDialogOpen(false);
  };

  // return (
  //   <>
  //     <IconButton size="large" onClick={handleOpen}>
  //       <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
  //     </IconButton>

  //     <MenuPopover
  //       open={Boolean(open)}
  //       anchorEl={open}
  //       onClose={handleClose}
  //       anchorOrigin={{ vertical: "top", horizontal: "left" }}
  //       transformOrigin={{ vertical: "top", horizontal: "right" }}
  //       arrow="right-top"
  //       sx={{
  //         mt: -0.5,
  //         width: 160,
  //         "& .MuiMenuItem-root": {
  //           px: 1,
  //           typography: "body2",
  //           borderRadius: 0.75,
  //         },
  //       }}
  //     >
  //       <MenuItem onClick={handlePageOpen} sx={{ color: "succes" }}>
  //         View
  //       </MenuItem>
  //       <Modal
  //         open={page}
  //         onClose={handlePageClose}
  //         aria-labelledby="modal-modal-title"
  //         aria-describedby="modal-modal-description"
  //       >
  //         <Box sx={style}>
  //           <Typography
  //             id="modal-modal-title"
  //             variant="h6"
  //             component="h2"
  //             color={"#6495ed"}
  //           >
  //         View
  //           </Typography>

  //           <Grid id="modal-modal-description" sx={{ mt: 2 }}>
  //             {/* <AdminAllStudent schoolId={schoolId} /> */}
  //           </Grid>
  //         </Box>
  //       </Modal>

  //     </MenuPopover>
  //   </>
  // );
}
