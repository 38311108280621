import React, { FormHelperText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableContainer,
  Paper,
  Alert,
  Stack,
} from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { useSnackbar } from "notistack";

function TeacherView() {
  const { userData } = useAuth();

  const {
    schoolStaff: { staffSchoolId },
    userId,
  } = userData;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [viewattendance, setViewAttendance] = useState([]);
  const [loadingAttendance, setLoadingAttendance] = useState(false);
  const [isTeacherAssignedToClass, setIsTeacherAssignedToClass] =
    useState(false);

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "50vh",
  };

  const ViewAttendance = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.string().required("class Arm is required"),
    AOD: Yup.string().nullable().required("attendance date  is required"),
  });

  const defaultValues = {
    classArm: "",
    class: "",
    classId: "",
    classArmId: "",
    AOD: null,
  };

  const methods = useForm({
    resolver: yupResolver(ViewAttendance),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { formState, setValue } = methods;

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `YearGroups/GetClassAssignedToTeacher?schoolId=${staffSchoolId}&teacherId=${userId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        if (!unmounted) {
          if (data.class !== null) {
            setIsTeacherAssignedToClass(true);
            setValue("class", data.class.yearGroupName);
            setValue("classId", data.class.yearGroupId);
            setValue("classArm", data.class.className);
            setValue("classArmId", data.class.classId);
          }
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (staffSchoolId != null) {
      getClasses();
    }

    return () => {
      unmounted = true;
    };
  }, [staffSchoolId]);

  const onSubmit = async (data) => {
    setLoadingAttendance(true);
    try {
      const date = new Date(data.AOD).toISOString();

      const response = await axoisCustomAgent.get(
        `/Students/GetAllStudentAndAttendanceDetails?schoolId=${staffSchoolId}&yearGroupId=${data.classId}&classId=${data.classArmId}&attendanceDate=${date}`
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("successful", {
        variant: "success",
      });

      setViewAttendance([...response.data.data]);

      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingAttendance(false);
    }
  };
  // ==============================

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Attendance...</Typography>
        </Box>
      </Box>
    );
  }

  if (!isTeacherAssignedToClass) {
    <Grid>
      <Typography>You have not been assigned to a class</Typography>
    </Grid>;
  }

  // ===============================

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Attendance</Typography>

        <Box paddingRight={5}>
          <Button
            // variant="outlined"

            color="primary"
            href="/staff/teacher/mark-attendance"
            className="header  "
          >
            Mark Attendance
          </Button>

          <Button
            // variant="outlined"
            color="primary"
            href="/staff/teacher/view-attendance"
            className="header active"
          >
            View Attendance
          </Button>
        </Box>
      </Box>

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Typography>View All Attendance</Typography>
                <hr />
                <Grid container spacing={5} mt={2}>
                  <Grid item width={300}>
                    <FormHelperText>Select Year Group :</FormHelperText>
                    <RHFTextField
                      name="class"
                      required
                      SelectProps={{ native: true }}
                      disabled
                    />
                  </Grid>

                  <Grid item width={300}>
                    <FormHelperText>Select class :</FormHelperText>
                    <RHFTextField
                      name="classArm"
                      required
                      disabled
                      SelectProps={{ native: true }}
                    />
                  </Grid>

                  <Grid item width={260}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormHelperText>Date :</FormHelperText>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Attendance Date "
                        value={methods.watch().AOD}
                        onChange={(value) => {
                          methods.setValue("AOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField {...params} name="AOD" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    View Attendance
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* ==============Table================= */}

      <Fragment>
        {loadingAttendance ? (
          <Typography variant="h3">Loading Attendance....</Typography>
        ) : (
          <Grid style={Gridimage}>
            {viewattendance.length === 0 ? (
              <Typography padding={2}>No Attendance</Typography>
            ) : (
              <Card sx={{ mt: 5, minWidth: 1000 }}>
                <CardHeader title="All Attendance" sx={{ mb: 3 }} />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          {/* <TableCell>Year Group</TableCell>
                          <TableCell>Class</TableCell> */}
                          <TableCell>Morning</TableCell>
                          <TableCell>Afternoon</TableCell>

                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewattendance.map(
                          (eachStudentInAttendance, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {eachStudentInAttendance.firstName}
                              </TableCell>
                              <TableCell>
                                {eachStudentInAttendance.lastName}
                              </TableCell>

                              <TableCell>
                                {eachStudentInAttendance.attendance === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .morningAttendanceStatus === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .morningAttendanceStatus}
                              </TableCell>

                              <TableCell>
                                {eachStudentInAttendance.attendance === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .afternoonAttendanceStatus === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .afternoonAttendanceStatus}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <Divider />

                <Box sx={{ p: 2, textAlign: "right" }}>
                  <Button
                    size="small"
                    color="inherit"
                    endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                  >
                    View All
                  </Button>
                </Box>
              </Card>
            )}
          </Grid>
        )}
      </Fragment>
    </Box>
  );
}

export default TeacherView;

function MoreMenuButton({ isActive }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem sx={{ color: "succes" }}>
          {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
          Edit
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
