import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function OutdoorActivitiesAdmin({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId,clerkId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    //   -=========================
    groupPropsUsed: "",
    appropriateEquipment: "",
    safetySignOnDisplay: "",
    coachPresent: "",
    freeOfDebris: "",
    appropriatePropsForActivities: "",
    spaceProvided: "",
    behaviourManagement: "",
    groundCoverage: "",
    evidenceOfSafeStudent: "",
    evidenceOfTeacherMastery: "",
    riskAssessment: "",
    groupObserved: "",
    activityObserved: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  useEffect(() => {
    let unmounted = false;
    const GetOutdoorAct = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionOneOutdoorActivities?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetOutdoorAct();
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = () => {};

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1B: Outdoor Activites
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <Typography>group information /props used?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="groupPropsUsed"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Equipment appropriate for activity observed and for the
                      age ranges of pupils?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="appropriateEquipment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography> Safety signs on Display?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="safetySignOnDisplay"
                    />
                  </Box>

                  <Box>
                    <Typography>Instructor/coach present?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="coachPresent"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      ground free of debris/hazards or obstructions?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="freeOfDebris"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box>
                    <Typography>
                      Are Props provided appropriate to activities intended for?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="appropriatePropsForActivities"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Appropriate groups to space provided?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="spaceProvided"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <Typography>
                      Evidence of effective behaviour management seen?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="behaviourManagement"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Appropriate ground coverings for the range of activities?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="groundCoverage"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      General evidence of pupils/students. Safe engagement in
                      the activity?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfSafeStudent"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Evidence of Teacher mastery of the activity
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfTeacherMastery"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Evidence of risk assessment for activity on display?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="riskAssessment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="groupObserved"
                      label="Year Group Observed "
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="activityObserved"
                      label="Types of activity being observed"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="comment"
                      label="Comments?"
                      variant="filled"
                      multiline
                      minRows={3}
                    />
                  </Box>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default OutdoorActivitiesAdmin;
