import {
  Container,
  Grid,
  Stack,
  Alert,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../../images/background.png";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import Iconify from "../../../components/Iconify";
import { useNavigate } from "react-router-dom";
import formatnaira from "format-to-naira";

function BookAAuditOrder() {
  const AuditSchema = Yup.object().shape({
    cost: Yup.string().required("field  is required"),
    schoolcategory: Yup.string().required("field  is required"),
    schoolName: Yup.string().required("field  is required"),
    email: Yup.string().required("field  is required"),
    password: Yup.string().required("field  is required"),
    confirmPassword: Yup.string().required("field  is required"),
    // password: Yup.string().required("field  is required"),
  });
  useEffect(() => {
    let unmounted = false;
    const getCategory = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolAudit/GetAuditAmountForSchoolTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setSelectCategory([...selectCategory, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getCategory();

    const getAllFacilities = async () => {
      let unmounted = false;
      setLoadingFacilities(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolAudit/GetAuditAmountForSchoolFacilities`
        );
        const { statusCode, message } = response.data;
        const { data } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        if (!unmounted) {
          let modifiedArray = data.map((eachItem) => {
            const { id, facility, amount } = eachItem;
            return {
              isChecked: false,
              amount,
              facility,
              id,
            };
          });
          setFacilities([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingFacilities(false);
      }
    };
    getAllFacilities();
    return () => {
      unmounted = true;
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [facilities, setFacilities] = useState([]);

  const defaultValues = {
    cost: "",
    schoolcategory: "",
    schoolName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(AuditSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  let watchSchoolCategory = watch("schoolcategory");

  const { formState } = methods;

  useEffect(() => {
    if (watchSchoolCategory === "") {
      setValue("cost", "");
    } else {
      let cost = selectCategory.find(
        (item) => `${item.id}` === watchSchoolCategory
      ).amount;
      setValue("cost", cost);
    }
    ReCalculateAuditTotal();
  }, [watchSchoolCategory]);

  const handleFacilitiesFormCheck = (e, index) => {
    let newFacilitiesArray = [...facilities];
    newFacilitiesArray[index].isChecked = e.target.checked;
    newFacilitiesArray[index].quantity = e.target.checked ? 1 : 0;
    setFacilities([...newFacilitiesArray]);
    ReCalculateAuditTotal();
  };
  const [selectCategory, setSelectCategory] = useState([
    {
      id: "",
      schoolTypeId: "",
      schoolTypeName: "select school catergory",
      amount: "",
    },
  ]);

  const [selectCost, setSelectCost] = useState([
    {
      id: "",
      amount: "",
    },
  ]);

  const [costValue, setCostValue] = useState();
  const [auditTotal, setAuditTotal] = useState(0);

  const ReCalculateAuditTotal = () => {
    let costOfSchoolCategory = watch("cost");
    let costOfAllSelectedFacilities = 0;
    facilities
      .filter((x) => x.isChecked)
      .forEach((item) => {
        costOfAllSelectedFacilities += item.amount;
      });
    let totalAuditAmount = costOfSchoolCategory + costOfAllSelectedFacilities;
    // console.log({totalAuditAmount})
    setAuditTotal(totalAuditAmount);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(`SchoolAudit/BookAudit`, {
        schoolName: data.schoolName,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        schoolTypeId: data.schoolcategory,
        schoolFacilities: facilities.filter((x) => x.isChecked),
        concessionCode: data.concession,
      });

      if (response.status === 400) {
        throw new Error("Something went wrong");
      }

      const { userId } = response.data.data;

      enqueueSnackbar("Audit submit successfully", { variant: "success" });
      methods.reset();
      navigate(`/bookAuditinvoice/${userId}`, { replace: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loadingFacilities) {
    return (
      <Box justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "50%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>
            <Box textAlign={"center"}>
              <Typography
                variant="h5"
                sx={{
                  color: "#00B000",

                  mt: 2,
                  p: 2,
                }}
              >
                SCHOOL SAFETY AUDIT REQUEST FORM
              </Typography>
            </Box>
            <Box>
              <Typography m={2} fontSize={"14px"}>
                kindly use your school information to fill the following *
              </Typography>
              <Box display={"flex"} justifyContent={"space-around"} ml={2}>
                <Box width={300}>
                  <RHFTextField
                    name="schoolName"
                    label="School Name"
                    variant="filled"
                  />
                </Box>

                <Box width={300}>
                  <RHFTextField
                    name="email"
                    label="Email address"
                    variant="filled"
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"} m={2}>
                <Box width={300}>
                  <RHFTextField
                    name="password"
                    label="Password"
                    variant="filled"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box width={300}>
                  <RHFTextField
                    name="confirmPassword"
                    label="Confirm Password"
                    variant="filled"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"} m={2}>
                <Box width={300}>
                  <RHFTextField
                    select
                    name="schoolcategory"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                  >
                    {selectCategory.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.schoolTypeName}
                      </option>
                    ))}
                  </RHFTextField>
                </Box>

                <Box width={300}>
                  <RHFTextField
                    name="cost"
                    // value={costValue}
                    disabled
                    label="Cost"
                    variant="filled"
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={4}>
              <Box display={"flex"} justifyContent={"space-between"} m={2}>
                <Box display={"flex"}>
                  <Typography>Facilities</Typography>
                  <Typography fontSize={"12px"} ml={1} mt={"4px"}>
                    (please tick all that applies)
                  </Typography>
                </Box>

                <Typography color={"#00B000"}>Cost</Typography>
              </Box>
            </Box>

            {facilities.map((eachItem, index) => (
              <Box mt={4} key={index}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography mr={1}>{index + 1}</Typography>
                    <input
                      type="checkbox"
                      name={eachItem.facility}
                      value={eachItem.isChecked}
                      onChange={(e) => handleFacilitiesFormCheck(e, index)}
                    />
                    <Typography fontSize={"15px"} ml={1}>
                      {eachItem.facility}
                    </Typography>
                  </Box>

                  <Typography>{formatnaira(eachItem.amount)}</Typography>
                </Box>
              </Box>
            ))}

            <Box m={2}>
              <Typography fontSize={"13px"}>
                if you select (7) above please provide the number of large
                hydrocarbon equipment above 5hp or KVA
              </Typography>
              <Box m={2} width={300}>
                <RHFTextField
                  name="hydroCarbon"
                  label="Number"
                  variant="filled"
                  value={facilities.find((x) => x.id === 7)?.quantity ?? ""}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Concession</Typography>
              <Typography fontSize={"13px"}>
                There is a concession given to non fee paying schools. If your
                school falls within this category, Kindly contact{" "}
                <i>
                  <u
                    style={{
                      color: "blue",
                    }}
                  >
                    audit@safeschoolslagos.ng{" "}
                  </u>
                </i>{" "}
                and request for a voucher. This is subject to verification at
                the discretion of SSLAG Please provide your concession token
                below to proceed
              </Typography>

              <Box m={2} width={300}>
                <RHFTextField
                  name="concession"
                  label="Concession Token"
                  variant="filled"
                />
              </Box>
            </Box>

            <Box mt={4}>
              <Box textAlign={"right"} m={2}>
                <Typography color={"#00B000"}>Payable Amount</Typography>
                <Typography>{formatnaira(auditTotal)}</Typography>
              </Box>
            </Box>
            <Grid textAlign={"center"} p={2}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                Confirm Order
              </LoadingButton>
            </Grid>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default BookAAuditOrder;
