import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Button, Modal, Box,Card } from "@mui/material";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import DataTable from "react-data-table-component";
// import { useSnackbar } from "notistack";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useParams } from "react-router";
import AdminInterve from "./AdminInterve";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import Back from "@material-ui/icons/ArrowBackIosRounded";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="School Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AdminAllAlert() {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth:"60%",
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  let { schoolId } = useParams();

  const { stakeholderCategory, id } = useAuthAdmin();
  const Gridimage = {
    background: "#fafafa",
  };
  const [schoolAlert, setAllSchoolAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

      const customStyles = {
        headCells: {
          style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
            // background: "#34a8e1",
            fontSize: "19px",
            borderRadius: 2,
          },
        },
      };


  useEffect(() => {
    let unmounted = false;

    const getAllAlert = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolAlerts/GetSchoolAlerts?schoolId=${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        let mappedData = [];

        if (data !== null && data.length > 0) {
          mappedData = data.map((eachElement, index) => {
            return {
              id: index + 1,
              alertId: eachElement.id,
              description: eachElement.description,
              alertStatus: eachElement.alertStatus,
            };
          });
        }

        setAllSchoolAlert([...mappedData]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "") getAllAlert();
    return () => {
      unmounted = true;
    };
  }, [id]);
 const columns = [
    {
      name: "#",

      cell: (row, index) => <p>{index + 1}</p>,
      width: "40px",
    },

    {
      name: "Description",
      selector: (row) => row.description,
    },

    {
      name: " Status",

      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.alertStatus==="Active" && "error") || (row.alertStatus && "success")
          }
        >
          {sentenceCase(row.alertStatus)}
        </Label>
      ),

      // selector: (row) => row.alertStatus,
      width: "300px",
    },
    {
      cell: (row) => (
        <>
          <Button onClick={() => openAlertModal(row.description)}>
            Intervene
          </Button>
        </>
      ),
      width: "400px",
    },
  ];
 

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Alerts...</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Fragment>
      <Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
            padding: 2,
          }}
        ></Grid>
        <Button
          startIcon={<Back />}
          name="Back"
          href="/admin/OnboardingProcess/newFormSchool"
        >
          Back
        </Button>

        <Card>
          <Grid p={2} style={Gridimage}>
            {schoolAlert.length === 0 ? (
              <Typography>No Alert Present</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={schoolAlert}
                subHeader
                //   subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Grid>

      <Modal
        open={isAlertModalOpen}
        onClose={closeAlertModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Intervene
          </Typography>
          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            <AdminInterve
              schoolId={schoolId}
              alertMessage={activeAlertMessageOpenInModal}
              closeAlertModal={closeAlertModal}
            />
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default AdminAllAlert;
