import { Stack } from "immutable";
import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { LoadingButton } from "@mui/lab";

function StaffList({ staffArray, deleteStaff, deleteLoading }) {
  const [indexToDelete, setIndexToDelete] = useState("");

  const handleDelete = async (indexToDelete, staffToDelete) => {
    setIndexToDelete(indexToDelete);
    await deleteStaff(staffToDelete);
  };

  //======================================

  return (
    <Box mt={5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ width: "25%" }}>FullName</Typography>
        <Typography sx={{ width: "25%" }}>Email</Typography>
        <Typography sx={{ width: "25%" }}>Phone Number</Typography>
        <Typography sx={{ width: "25%" }}>Upload (url)</Typography>
      </Box>
      <hr />
      <Box mt={2}>
        {staffArray != null && staffArray.length > 0 ? (
          staffArray.map((item, index, array) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {item.name.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.name.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.name}</Typography>
              )}
              {/* <Typography sx={{ width: "20%" }}>{item.fullName}</Typography> */}

              {item.email.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.email.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.email}</Typography>
              )}

              {item.phoneNumber.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.phoneNumber.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>
                  {item.phoneNumber}
                </Typography>
              )}
              {item.imageUrl !== null && item.imageUrl.length >= 10 ? (
                <Typography sx={{ width: "20%" }}>
                  {item.imageUrl.slice(0, 10)}
                  {"..."}
                </Typography>
              ) : (
                <Typography sx={{ width: "20%" }}>{item.imageUrl}</Typography>
              )}

              {/* <Typography sx={{ width: "20%" }}>{item.imageUrl}</Typography> */}
              <LoadingButton
                loading={deleteLoading && indexToDelete === index}
                onClick={async () => await handleDelete(index, item)}
              >
                {" "}
                <DeleteForeverIcon />{" "}
              </LoadingButton>
            </Box>
          ))
        ) : (
          <Typography>Add a staff</Typography>
        )}
      </Box>
    </Box>
  );
}

export default StaffList;
