import PropTypes from "prop-types";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { Stack, Box, styled, IconButton,
    InputAdornment,} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
// hooks
import useIsMountedRef from "../../hooks/useIsMountedRef";
// components
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import Iconify from "../../components/Iconify";
import { useParams, } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

ConfirmPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func,
};
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    // display: 'flex',
    // color: '#fff',
  },
}));

export default function ConfirmPasswordForm({

}) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userData } = useAuth();
  let { code, email } = useParams();
  



  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(9, "password must be at least 9 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref("password"), null], "confirm password does not match"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: "" },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

 






  const onSubmit = async (data) => {
    console.log("vvvvvvv")
    try {
      const response = await axoisCustomAgent.post(`AandE/CreatePassword`, {
        code,
        password: data.password,
        email,
       
      });

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate("/auth/login")
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          background: "#fff",
          width: 500,
          minHeight: 300,
          boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
          borderRadius: 2,
          paddingTop: 1,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} width={470} padding={2} mt={3} ml={2}>
          

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack spacing={3} width={470} padding={2} ml={2}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
            Submit
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </RootStyle>
  );
}
