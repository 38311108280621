import React, { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTable from "react-data-table-component";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  Alert,
  Modal,
  Card,
} from "@mui/material";
import Button from "@mui/material/Button";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import logoBg from "../../images/sslagBookingForm.JPG";
import Add from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import styled from "styled-components";
import SchoolCjresourcesModel from "./SchoolCjresourcesModel";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SchoolCjresourcesTable() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const [dataStatus, setDataStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [resourceData, setResourceData] = useState([]);

  const [bookingform, setBookingform] = useState([]);
  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    0 &:hover {
      cursor: pointer;
    }
  `;
  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // const filteredItems = bookingform.filter(
  //   (item) =>
  //     item.firstName &&
  //     item.firstName.toLowerCase().includes(filterText.toLowerCase())
  // );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "Staff details",
      selector: (row) => row.staffDetails,
    },
    {
      name: "Role",
      selector: (row) => row.staffRole,
    },
    {
      name: "Date",
      selector: (row) => row.dateStarted.toISOString().split("T")[0],
    },
    {
      name: "Designated duty",
      selector: (row) => row.designatedDuty,
    },
    {
      name: "Recruitment check",
      selector: (row) => row.safeRecruitmentCheckConducted,
    },
    {
      name: "Remarks",
      selector: (row) => row.remarks.slice(0, 10),
    },
  ];

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "Human Resource Log Table 5A",
            token,
          }
        );

        const { statusCode, message, data } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }
        setDataStatus(data);
        setLinkStatus(message);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const addhumanResourceData = (newhumanResourceData) => {
    let updatedResources = [...resourceData];
    updatedResources.push(newhumanResourceData);
    setResourceData(updatedResources);
  };

  const methods = useForm({
    // resolver: yupResolver(),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitHumanResourceLogInterventionForm`,
        {
          schoolId,
          token,
          humanResourceLogs: resourceData,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box textAlign={"center"}>
          <Box>
            <Grid>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 200,
                }}
              />
            </Grid>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
            }}
          >
            Human Resource Log Table.
          </Typography>
        </Box>
      </Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 5,
            minHeight: 400,
          }}
        >
        

          <Grid p={2}>
            <Grid item sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={handleOpen}
                startIcon={<Add />}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Card mt={2}>
            <Grid p={3}>
              {resourceData.length === 0 ? (
                <Typography>No record found</Typography>
              ) : (
                <DataTable
                  columns={columns}
                  data={resourceData}
                  editModde="row"
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                  customStyles={customStyles}
                />
              )}
            </Grid>
          </Card>
          <Grid item sx={{ textAlign: "right", p: 2 }}>
            <LoadingButton
              type="submit"
              color="secondary"
              // endIcon={<Send />}
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Container>
      </FormProvider>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#097969"}
          >
            Add to human resource Log Table.
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <SchoolCjresourcesModel addData={addhumanResourceData} />
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default SchoolCjresourcesTable;

