import React from "react";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import BackgroundImage from "../../images/bg1.png";
import srun from "../../images/bg1.png";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  FormHelperText,
} from "@mui/material";

function AdminCreateKT() {
  const PaperStyle = {
    padding: 40,
    width: 600,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };

  const defaultValues = {
    firstName: "",
    lastName: "",
    middleName: "",
  };
const Image = {
  background: ` url(${BackgroundImage}) `,
};
  const CONSULTANT = [
    {
      value: "",
      label: "Select Consultant",
    },
    {
      value: "0",
      label: "Mrs Doc",
    },
    {
      value: "1",
      label: "Mr Babatunde",
    },
    {
      value: "3",
      label: "Mr fisayo",
    },
  ];
  const CreateKTSchema = Yup.object().shape({});
  const methods = useForm({
    resolver: yupResolver(CreateKTSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const onSubmit = () => {};
  return (
    <Box sx={{ BackgroundImage: `url(${srun})` }}>
      <Typography>SCHOOL KNOWLEDGE TRANSFER</Typography>
      <Grid container style={Gridimage} justifyContent="center">
        <Paper style={PaperStyle}>
          <Grid align="center" mt={3}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
              </Stack>
              <Box>
                <FormHelperText>Title:</FormHelperText>
                <RHFTextField
                  name="title"
                  // label="Title"
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box>
                <FormHelperText>Description of Training</FormHelperText>
                <RHFTextField
                  name="description"
                  placeholder="Description of training"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  minRows={3}
                />
              </Box>
              <Grid item>
                <FormHelperText>Amount(#)</FormHelperText>
                <RHFTextField name="amount" variant="outlined" fullWidth />
              </Grid>
              <Grid item>
                <FormHelperText>Consultant:</FormHelperText>
                <RHFTextField
                  select
                  name="consultant"
                  required
                  SelectProps={{ native: true }}
                >
                  {CONSULTANT.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </RHFTextField>
              </Grid>

              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Event
                    Date"
                    value={methods.EventDate}
                    onChange={(value) => {
                      methods.setValue("EventDate", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField {...params} name="EventDate" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box mt={2}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="secondary"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
            </FormProvider>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}

export default AdminCreateKT;
