import {
  Grid,
  Alert,
  Stack,
  FormHelperText,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FormProvider,
  RHFTextField as RHFTextField,
} from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";

function EditStaff({ schoolId, staffId }) {
  const EditStaff = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),
    qualifications: Yup.string().required("certificate type is required"),
    email: Yup.string()
      .email("Enter a valid Email Address")
      .required("Email is required"),

    gender: Yup.string().oneOf(["1", "2", "3"], "Select a gender type "),

    yearEmployed: Yup.string().required("year of employed is  required"),
    stateOfOrigin: Yup.string().required("state of origin is  required"),
    roleId: Yup.string().required("school role is  required"),

    lga: Yup.string().required("local government  is  required"),

    telephone: Yup.string().required("telephone  is  required"),
    DOB: Yup.string().nullable().required("date of birth  is  required"),
    staffRegNo: Yup.string().nullable().required("staff id   is  required"),
    status: Yup.string().required("please select"),
    reasonForLiving: Yup.string().required("please fill"),
  });

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    roleId: "",
    yearEmployed: "",
    stateOfOrigin: "",
    lga: "",
    qualifications: "",
    telephone: "",
    DOB: null,
    staffRegNo: "",
    residentialAddress: "",
    status: "",
    reasonForLiving: "",
  };
  const methods = useForm({
    resolver: yupResolver(EditStaff),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = methods;

  const GENDER = [
    {
      value: "0",
      label: "Select Gender",
    },
    {
      value: "1",
      label: "Male",
    },
    {
      value: "2",
      label: "Female",
    },
    {
      value: "3",
      label: "Other",
    },
  ];

  const QUALIFICATIONS = [
    {
      value: "0",
      label: "Certificate type ",
    },

    {
      value: "1",
      label: "SSCE",
    },
    {
      value: "2",
      label: "ND",
    },
    {
      value: "3",
      label: "HND",
    },
    {
      value: "4",
      label: "Bsc",
    },
    {
      value: "5",
      label: "Msc",
    },
    {
      value: "6",
      label: "Phd",
    },
  ];

  const STATUS = [
    {
      value: "0",
      label: "Choose Status",
    },
    {
      value: "1",
      label: "Active",
    },
    {
      value: "2",
      label: "Left",
    },
  ];
  const ROLE = [
    {
      value: "0",
      label: "Choose Role",
    },
    {
      value: "2",
      label: "Administrator",
    },
    {
      value: "3",
      label: "Principal",
    },
    {
      value: "4",
      label: "Vice Principal",
    },
    {
      value: "5",
      label: " Teachers",
    },
    {
      value: "6",
      label: "Drivers",
    },
    {
      value: "7",
      label: "Non-teaching Staff",
    },
  ];

  const watchreason = watch("status");

  useEffect(() => {
    const getAllStudentProfile = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `SchoolStaff/${schoolId}/getstaffbyId/${staffId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setValue("firstName", data.firstName);
        setValue("lastName", data.lastName);
        setValue("middleName", data.middleName);
        setValue("telephone", data.phoneNumber);

        setValue("gender", data.gender);
        setValue("yearEmployed", data.yearEmployed);
        setValue("qualifications", data.certificateType);

        setValue("stateOfOrigin", data.stateOfOrigin);
        setValue("roleId", data.staffRole);
        setValue("DOB", data.dateOfBirth);
        setValue("lga", data.lgaOfOrigin);
        setValue("staffRegNo", data.registrationNumber);
        setValue("homeAddress", data.residentialAddress);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStudentProfile();
  }, [schoolId, staffId]);

  const onSubmit = async (data) => {
    const editedStudentData = {
      schoolId,
      staffId,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,

      phoneNumber: data.telephone,
      genderId: data.gender,
      yearEmployed: data.yearEmployed,
      certificateId: data.qualifications,
      stateOfOrigin: data.stateOfOrigin,
      staffRole: data.roleId,
      dateOfBirth: new Date(data.DOB).toISOString(),
      lgaOfOrigin: data.lga,
      registrationNumber: data.staffRegNo,
      residentialAddress: data.homeAddress,
      statusId: data.status,
    };

    try {
      const response = await axoisCustomAgent.put(
        `SchoolStaff/${schoolId}/EditStaff/${staffId}`,
        {
          ...editedStudentData,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Students...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Box display={"flex"} justifyContent={"space-around"}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField
              name="firstName"
              label="First Name"
              variant="filled"
              required
            />
          </Grid>

          <Grid width={250}>
            <RHFTextField
              name="middleName"
              required
              label="Middle Name"
              variant="filled"
            />
          </Grid>
          <Grid width={250}>
            <RHFTextField
              name="lastName"
              required
              label="Last Name"
              variant="filled"
            />
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField
              select
              name="gender"
              label="Gender"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {GENDER.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>

          <Grid width={250}>
            <RHFTextField
              name="email"
              required
              type="email"
              label="Email"
              variant="filled"
            />
          </Grid>
          <Grid width={250}>
            <RHFTextField
              name="yearEmployed"
              label="Year Employed"
              variant="filled"
            />
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField
              select
              name="qualifications"
              label="Qualification"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {QUALIFICATIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>

          <Grid width={250}>
            <RHFTextField
              select
              name="roleId"
              label="Role:"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {ROLE.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>
          <Grid width={250}>
            <RHFTextField name="telephone" label="Telephone" variant="filled" />
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField
              name="stateOfOrigin"
              label="State Of Origin"
              variant="filled"
            />
          </Grid>

          <Grid width={250}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* <FormHelperText>Date Of Birth:</FormHelperText> */}
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="Date of Birth "
                required
                value={methods.watch().DOB}
                onChange={(value) => {
                  methods.setValue("DOB", value, {
                    shouldValidate: true,
                  });
                }}
                renderInput={(params) => (
                  <RHFTextField variant="filled" {...params} name="DOB" />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid width={250}>
            <RHFTextField
              name="staffRegNo"
              required
              label="Staff ID"
              variant="filled"
            />
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            <RHFTextField
              name="residentialAddress"
              required
              multiline={true}
              minRows={3}
              label="residential Address"
              variant="filled"
            />
          </Grid>

          <Grid width={250}>
            <RHFTextField
              select
              name="status"
              label="Status"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {STATUS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>

          {watchreason === "2" ? (
            <Grid width={250}>
              <FormHelperText>Reason for living</FormHelperText>
              <RHFTextField
                name="reasonForLiving"
                required
                multiline={true}
                minRows={3}
                // label="Staff ID"
                variant="filled"
              />
            </Grid>
          ) : null}
        </Box>

        <Grid item sx={{ textAlign: "right", mt: 3 }}>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={isSubmitting}
          >
            Update
          </LoadingButton>
        </Grid>

        {/* ========================================================== */}
        
      </FormProvider>
    </Grid>
  );
}

export default EditStaff;
