import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils

// import axios from "axios";

import { isValidToken, setSession } from "../utils/jwt";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { setSessionCustom } from "src/utils/jwtCustomAgent";

// ----------------------------------------------------------------------

const userInitialData = {
  // common data
  email: "",
  userId: null,
  emailConfirmed: null,
  isAccountActive: null,
  stakeholderId: 1,
  stakeholderCategory: null,

  // for schoolStaff
  schoolStaff: {
    staffRole: "",
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    staffSchoolName: "",
    staffSchoolId: "",
  },

  // for schools
  schoolName: null,
  schoolTypeName: "",
  schoolTypeId: null,
  schoolId: null,
  isOnboardingCompleted: null,
  isOnboardingTrainingDone: null,
  role: null,
  currentOnboardingStage: 1,
  hasPaidForRegistration: null,
  regisrationAmount: null,
  schoolPin: null,
  fullName: null,
  email: null,
  roleInSchool: null,

  // for Assessors
  assessors: {
    firstName: "",
    lastName: "",
    middleName: "",
  },
  //for Audit clerk

  Clerks: {
    firstName: "",
    lastName: "",
    middleName: "",
  },
};

const initialState = {
  isAuthenticated: false,
  isVerified: false,
  isInitialized: false,
  userData: { ...userInitialData },
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { stakeholderCategory } = action.payload;

    if (stakeholderCategory === "School Staff") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,

        firstName,
        lastName,
        middleName,
        staffRole,
        gender,
        staffSchoolName,
        staffSchoolId,
      } = action.payload;

      return {
        ...state,
        isInitialized: true,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          schoolStaff: {
            ...state.userData.schoolStaff,
            firstName,
            lastName,
            middleName,
            staffRole,
            gender,
            staffSchoolName,
            staffSchoolId,
          },
        },
      };
    } else if (stakeholderCategory === "Assessors") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,

        firstName,
        lastName,
        middleName,
      } = action.payload;

      return {
        ...state,
        isInitialized: true,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          assessors: {
            ...state.userData.assessors,
            firstName,
            lastName,
            middleName,
          },
        },
      };
    } else if (stakeholderCategory === "Audit Clerks") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,

        firstName,
        lastName,
        middleName,
      } = action.payload;

      return {
        ...state,
        isInitialized: true,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          assessors: {
            ...state.userData.assessors,
            firstName,
            lastName,
            middleName,
          },
        },
      };
    } else {
      const {
        isAuthenticated,
        user,
        userId,
        emailConfirmed,
        email,
        role,
        schoolId,
        hasPaidForRegistration,
        schoolTypeName,
        schoolTypeId,
        registrationAmount,
        currentOnboardingStage,
        isOnboardingCompleted,
        isOnboardingTrainingDone,
        schoolPin,
        schoolName,
        isAccountActive,
        stakeholderCategory,
        stakeholderId,
      } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          role,
          schoolId,
          hasPaidForRegistration,
          schoolTypeName,
          schoolTypeId,
          registrationAmount,
          currentOnboardingStage,
          isOnboardingCompleted,
          isOnboardingTrainingDone,
          schoolPin,
          schoolName,
          isAccountActive,
          stakeholderCategory,
          stakeholderId,
        },
      };
    }
  },

  LOGIN: (state, action) => {
    const { stakeholderCategory } = action.payload;

    if (stakeholderCategory === "Schools") {
      const {
        userId,
        emailConfirmed,
        email,
        role,
        schoolId,
        hasPaidForRegistration,
        schoolTypeName,
        schoolTypeId,
        registrationAmount,
        currentOnboardingStage,
        isOnboardingCompleted,
        isOnboardingTrainingDone,
        schoolPin,
        schoolName,
        stakeholderId,
        isAccountActive,
      } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isVerified: true,
        isInitialized: true,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          role,
          schoolId,
          hasPaidForRegistration,
          schoolTypeName,
          schoolTypeId,
          registrationAmount,
          currentOnboardingStage,
          isOnboardingCompleted,
          isOnboardingTrainingDone,
          schoolPin,
          schoolName,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          ...state.userData.schoolStaff,
        },
      };
    } else if (stakeholderCategory === "School Staff") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,

        firstName,
        lastName,
        middleName,
        staffRole,
        gender,
        staffSchoolName,
        staffSchoolId,
      } = action.payload;

      return {
        ...state,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          schoolStaff: {
            ...state.userData.schoolStaff,
            firstName,
            lastName,
            middleName,
            staffRole,
            gender,
            staffSchoolName,
            staffSchoolId,
          },
        },
      };
    } else if (stakeholderCategory === "Assessors") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,
        firstName,
        lastName,
        middleName,
      } = action.payload;

      return {
        ...state,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          assessors: {
            ...state.userData.assessors,
            firstName,
            lastName,
            middleName,
          },
        },
      };
    } else if (stakeholderCategory === "Audit Clerks") {
      const {
        userId,
        emailConfirmed,
        email,
        stakeholderId,
        stakeholderCategory,
        isAccountActive,
        firstName,
        lastName,
        middleName,
      } = action.payload;

      return {
        ...state,
        userData: {
          ...state.userData,
          userId,
          emailConfirmed,
          email,
          stakeholderId,
          stakeholderCategory,
          isAccountActive,
          assessors: {
            ...state.userData.assessors,
            firstName,
            lastName,
            middleName,
          },
        },
      };
    }
  },

  LOGOUT: (state) => ({
    ...state,
    ...initialState,
    // isAuthenticated: false,
    // user: null,
  }),

  REGISTER: (state, action) => {
    const { email, schoolName, schoolTypeId, userId, emailConfirmed } =
      action.payload;

    return {
      ...state,
      isAuthenticated: false,
      userData: {
        ...initialState.userData,
        email,
        schoolName,
        schoolTypeId,
        userId,
        emailConfirmed,
      },
    };
  },

  VERIFYEMAIL: (state, action) => {
    const {
      // email,
      emailConfirmed,
      role,
      schoolId,
      registrationAmount,
      hasPaidForRegistration,
      schoolTypeName,
    } = action.payload;
    return {
      ...state,
      isVerified: false,
      isAuthenticated: false,
      userData: {
        ...state.userData,
        role,
        emailConfirmed,
        schoolId,
        schoolTypeName,
        registrationAmount,
        hasPaidForRegistration,
      },
    };
  },

  VERIFYREGISTRATIONPAYMENT: (state, action) => {
    return {
      ...state,
      isVerified: false,
      isAuthenticated: false,
      userData: {
        ...state.userData,
        hasPaidForRegistration: true,
      },
    };
  },

  SETONBOARDINGCOMPLETED: (state, action) => {
    return {
      ...state,
      userData: {
        ...state.userData,
        isOnboardingCompleted: true,
      },
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  verifyRegistrationPayment: () => Promise.resolve(),
  setIsOnboardingCompleted: () => {},
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const sslagAccessToken =
          window.localStorage.getItem("SSLAGaccessToken");

        if (sslagAccessToken && isValidToken(sslagAccessToken)) {
          setSessionCustom(sslagAccessToken);

          const customResponse = await axoisCustomAgent.get(
            "/Accounts/GetCurrentUser"
          );

          //const { statusCode, message } = customResponse.data;

          const {
            userId,
            stakeholderId,
            stakeholderCategory,
            emailConfirmed,
            isAccountActive,
            email,
          } = customResponse.data.data;

          //console.log(customResponse);

          if (stakeholderCategory === "Schools") {
            const { role, school, onboardingInfo } = customResponse.data.data;
            const {
              schoolId,
              hasPaidForRegistration,
              schoolTypeName,
              schoolTypeId,
              registrationAmount,
              schoolPin,
              isOnboardingTrainingDone,
              schoolName,
            } = school;

            const { currentOnboardingStage, isOnboardingCompleted } =
              onboardingInfo;

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                // user: theirUser,
                userId,
                emailConfirmed,
                email,
                role,
                schoolId,
                hasPaidForRegistration,
                schoolTypeName,
                schoolTypeId,
                registrationAmount,
                currentOnboardingStage,
                isOnboardingCompleted,
                isOnboardingTrainingDone,
                schoolPin,
                schoolName,
                isAccountActive,
                stakeholderCategory,
                stakeholderId,
              },
            });
          } else if (stakeholderCategory === "School Staff") {
            const {
              firstName,
              lastName,
              middleName,
              staffRole,
              gender,
              schoolId,
              schoolName,
            } = customResponse.data.data;

            dispatch({
              type: "INITIALIZE",
              payload: {
                userId,
                emailConfirmed,
                email,
                stakeholderId,
                stakeholderCategory,
                isAccountActive,
                firstName,
                lastName,
                middleName,
                staffRole,
                gender,
                staffSchoolName: schoolName,
                staffSchoolId: schoolId,
              },
            });
          } else if (stakeholderCategory === "Assessors") {
            const {
              userId,
              emailConfirmed,
              email,
              stakeholderId,
              stakeholderCategory,
              isAccountActive,
              firstName,
              lastName,
              middleName,
            } = customResponse.data.data;
            dispatch({
              type: "INITIALIZE",
              payload: {
                userId,
                emailConfirmed,
                email,
                stakeholderId,
                stakeholderCategory,
                isAccountActive,
                firstName,
                lastName,
                middleName,
              },
            });
          } else if (stakeholderCategory === "Audit Clerks") {
            const {
              userId,
              emailConfirmed,
              email,
              stakeholderId,
              stakeholderCategory,
              isAccountActive,
              firstName,
              lastName,
              middleName,
            } = customResponse.data.data;
            dispatch({
              type: "INITIALIZE",
              payload: {
                userId,
                emailConfirmed,
                email,
                stakeholderId,
                stakeholderCategory,
                isAccountActive,
                firstName,
                lastName,
                middleName,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                user: null,
                userId: null,
                emailConfirmed: null,
                email: null,
                role: null,
                schoolId: null,
                hasPaidForRegistration: null,
                schoolTypeName: null,
                schoolTypeId: null,
                registrationAmount: null,
                currentOnboardingStage: null,
                isOnboardingCompleted: null,
                isOnboardingTrainingDone: null,
                schoolPin: null,
                schoolName: null,
              },
            });
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              userId: null,
              emailConfirmed: null,
              email: null,
              role: null,
              schoolId: null,
              hasPaidForRegistration: null,
              schoolTypeName: null,
              schoolTypeId: null,
              registrationAmount: null,
              currentOnboardingStage: null,
              isOnboardingCompleted: null,
              isOnboardingTrainingDone: null,
              schoolPin: null,
              schoolName: null,
            },
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            userId: null,
            emailConfirmed: null,
            email: null,
            role: null,
            schoolId: null,
            hasPaidForRegistration: null,
            schoolTypeName: null,
            schoolTypeId: null,
            registrationAmount: null,
            currentOnboardingStage: null,
            isOnboardingCompleted: null,
            isOnboardingTrainingDone: null,
            schoolPin: null,
            schoolName: null,
          },
        });
      } finally {
      }
    };

    initialize();
  }, []);

  const login = async ({ email, password, stakeholderCategoryId }) => {
    const response = await axoisCustomAgent.post("/accounts/login", {
      email,
      password,
      stakeholderCategory: stakeholderCategoryId,
    });

    // if (response.status === 500) {
    //   throw new Error("Something went wrong");
    // }

    const { statusCode, message } = response.data;

    if (statusCode === 200) {
      const { token } = response.data;

      setSessionCustom(token);

      const {
        userId,
        stakeholderId,
        stakeholderCategory,
        emailConfirmed,
        isAccountActive,
        email,
      } = response.data.data;

      if (stakeholderCategory === "Schools") {
        const { role, school, onboardingInfo } = response.data.data;
        const {
          schoolId,
          hasPaidForRegistration,
          schoolTypeName,
          schoolTypeId,
          registrationAmount,
          schoolPin,
          isOnboardingTrainingDone,
          schoolName,
        } = school;
        const { currentOnboardingStage, isOnboardingCompleted } =
          onboardingInfo;

        // console.log({ schoolId });

        dispatch({
          type: "LOGIN",
          payload: {
            userId,
            emailConfirmed,
            email,
            role,
            schoolId,
            hasPaidForRegistration,
            schoolTypeName,
            schoolTypeId,
            registrationAmount,
            currentOnboardingStage,
            isOnboardingCompleted,
            isOnboardingTrainingDone,
            schoolPin,
            schoolName,
            stakeholderId,
            stakeholderCategory,
            isAccountActive,
          },
        });
      } else if (stakeholderCategory === "School Staff") {
        const {
          firstName,
          lastName,
          middleName,
          staffRole,
          gender,
          schoolId,
          schoolName,
        } = response.data.data;

        dispatch({
          type: "LOGIN",
          payload: {
            userId,
            emailConfirmed,
            email,
            stakeholderId,
            stakeholderCategory,
            isAccountActive,
            firstName,
            lastName,
            middleName,
            staffRole,
            gender,
            staffSchoolName: schoolName,
            staffSchoolId: schoolId,
          },
        });
      } else if (stakeholderCategory === "Assessors") {
        const {
          id,
          stakeholderId,
          stakeholderCategory,
          email,
          isEmailConfirmed,
          isAccountActive,
          isAdmin,
          firstName,
          middleName,
          lastName,
        } = response.data.data;

        dispatch({
          type: "LOGIN",
          payload: {
            userId,
            emailConfirmed,
            email,
            stakeholderId,
            stakeholderCategory,
            isAccountActive,
            firstName,
            lastName,
            middleName,
          },
        });
      } else if (stakeholderCategory === "Audit Clerks") {
        const {
          id,
          stakeholderId,
          stakeholderCategory,
          email,
          isEmailConfirmed,
          isAccountActive,
          isAdmin,
          firstName,
          middleName,
          lastName,
        } = response.data.data;

        dispatch({
          type: "LOGIN",
          payload: {
            userId,
            emailConfirmed,
            email,
            stakeholderId,
            stakeholderCategory,
            isAccountActive,
            firstName,
            lastName,
            middleName,
          },
        });
      }
    } else {
      throw new Error(message);
    }
  };

  const register = async ({
    email,
    password,
    schoolName,
    schoolTypeId,
    confirmPassword,
  }) => {
    const response = await axoisCustomAgent.post("/schools/registerschool", {
      email,
      password,
      schoolName,
      schoolTypeId,
      confirmPassword,
    });

    const { statusCode, message, userId, emailConfirmed } = response.data;

    if (statusCode === 200) {
      setSessionCustom(null);
      dispatch({
        type: "REGISTER",
        payload: {
          email,
          schoolTypeId,
          schoolName,
          userId,
          emailConfirmed,
        },
      });
    } else {
      throw message;
    }
  };

  const verify = async (code) => {
    const { email, stakeholderId } = state.userData;

    const response = await axoisCustomAgent.put("/accounts/activateAccount", {
      email,
      code,
      stakeholderCategory: stakeholderId,
    });

    const { statusCode, message } = response.data;

    if (statusCode === 200) {
      const { emailConfirmed, role, school, email } = response.data.data;
      const {
        schoolId,
        registrationAmount,
        hasPaidForRegistration,
        schoolTypeName,
      } = school;
      dispatch({
        type: "VERIFYEMAIL",
        payload: {
          email,
          emailConfirmed,
          role,
          schoolId,
          registrationAmount,
          hasPaidForRegistration,
          schoolTypeName,
        },
      });
    } else {
      throw message;
    }
  };

  const verifyRegistrationPayment = async (
    invoiceReference,
    reference,
    paymentServiceProvider
  ) => {
    const { schoolId } = state.userData;

    const response = await axoisCustomAgent.post(
      `/schools/VerifyRegistrationPayment/${state.userData.schoolId}`,
      {
        invoiceReference,
        paymentReferenceId: reference,
        paymentServiceProvider,
      }
    );

    const { statusCode, message } = response.data;
    if (statusCode === 200) {
      dispatch({
        type: "VERIFYREGISTRATIONPAYMENT",
        payload: {},
      });
    } else {
      throw message;
    }
  };

  const logout = async () => {
    setSession(null);
    setSessionCustom(null);
    dispatch({ type: "LOGOUT" });
  };

  const setIsOnboardingCompleted = () => {
    dispatch({
      type: "SETONBOARDINGCOMPLETED",
      payload: {},
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        verify,
        verifyRegistrationPayment,
        setIsOnboardingCompleted,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
