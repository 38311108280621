import {
    Grid,
    Container,
    Box,
    Typography,
    Alert,
    Stack,
    TextField,
  } from "@mui/material";
  import React, { Fragment, useState, useEffect } from "react";
  import AdminBG from "../../../images/background.png";
  import Back from "@material-ui/icons/ArrowBackIosOutlined";
  import LoadingButton from "@mui/lab/LoadingButton";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import {
    FormProvider,
    RHFRadioGroup,
    RHFTextField,
    RHFCheckbox,
  } from "../../../components/hook-form";
  import axoisCustomAgent, {
    axoisCustomAgentForAdmin,
  } from "../../../utils/axiosCustomAgent";
  import useAuthAdmin from "src/hooks/useAuthAdmin";
  import { useSnackbar } from "notistack";
  import { useNavigate, useParams } from "react-router";
  
  function AdminSchoolTransit({ nextStep, prevStep, auditId, schoolId }) {
    const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
    const { stakeholderCategory, id } = useAuthAdmin();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    let { taskId, clerkId } = useParams();
  
    const defaultValues = {
      numberOfVehicleUsed: "",
      remarksOnNumberOfVehicleUsed: "",
      typeOfVehiclesUsed: "",
      remarksOnTypeOfVehiclesUsed: "",
      numberOfDriversEmployed: "",
      remarksOnNumberOfDriversEmployed: "",
      inductionEvidenceSeen: "",
      remarksOnInductionEvidenceSeen: "",
      DriversLicenses: "",
      remarksOnLicenseHeldByDrivers: "",
      driversAge: "",
      remarksOnDriversAge: "",
      driverSsCertOfAttendance: "",
      remarksOnDriverSsCertOfAttendance: "",
      confidentialRecordOfPupils: "",
      remarksOnConfidentialRecordOfPupils: "",
      isSchoolBusRulesOnBoard: "",
      remarksOnIsSchoolBusRulesOnBoard: "",
      evidenceOfRollCallOnBoard: "",
      remarksOnEvidenceOfRollCallOnBoard: "",
      isThereAdditionalStaffAssistingDriver: "",
      remarksOnIsThereAdditionalStaffAssistingDriver: "",
      isSeatBeltPresentOnAllSeat: "",
      remarksOnIsSeatBeltPresentOnAllSeat: "",
  
      isThereApprovedFirstAidOnBoard: "",
      remarksOnIsThereApprovedFirstAidOnBoard: "",
      areVehiclesInGoodCondition: "",
      remarksOnAreVehiclesInGoodCondition: "",
      areVehiclesParticularsInOrder: "",
      remarksOnAreVehiclesParticularsInOrder: "",
      vehicleMaintenanceSighted: "",
      remarksOnVehicleMaintenanceSighted: "",
      areDefinedRoutesAvailableOnBoard: "",
      remarksOnAreDefinedRoutesAvailableOnBoard: "",
      isThereTelephoneForContactingParents: "",
      remarksOnIsThereTelephoneForContactingParents: "",
      doesTelephoneHaveAirtime: "",
      remarksOnDoesTelephoneHaveAirtime: "",
      isPeriodicBusEmergencyDrillConducted: "",
      remarksOnIsPeriodicBusEmergencyDrillConducted: "",
      isThereOverloading: "",
      remarksOnIsThereOverloading: "",
      isThereAppropriateSeatForYoungChildren: "",
      remarksOnIsThereAppropriateSeatForYoungChildren: "",
      areChildrenSeatedInFront: "",
      remarksOnAreChildrenSeatedInFront: "",
    };
  
    const methods = useForm({
      // resolver: yupResolver(),
      defaultValues,
    });
  
    const {
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
  
    const [direction, setDirection] = useState("");
  
    const handleDirectionChange = (direction) => {
      setDirection(direction);
    };
    const option = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  
      
    useEffect(() => {
        let unmounted = false;
        const GetSchoolTransit = async () => {
          setLoading(true);
          try {
            const response = await axoisCustomAgentForAdmin.get(
              `/Audit/GetSectionEightSchoolTransit?taskId=${taskId}&clerkId=${clerkId}`
            );
            const { statusCode, message } = response.data;
    
            if (statusCode !== 200) {
              throw new Error(message);
            }
            const { data } = response.data;
            methods.reset({ ...data });
    
            //setGeneralSection(response.data.data);
          } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
          } finally {
            setLoading(false);
          }
        };
    
        if (id !== null && id !== "" && !unmounted) GetSchoolTransit();
        return () => {
          unmounted = true;
        };
      }, []);
   
    const onSubmit =  () => {
   
    };
  
  
    return (
      <Fragment>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
            background: `url(${AdminBG})`,
            minHeight: "100vh",
            backgroundCover: "contain",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 1,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 600,
              width: "80%",
            }}
          >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} m={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
  
                <Box textAlign={"center"}>
                  <Typography
                    variant="h5"
                    sx={{
                      background: "#00bbea",
                      // textAlign: "centre",
                      mt: 2,
                      p: 2,
                    }}
                  >
                    SECTION 8: School transit system
                  </Typography>
                </Box>
  
                <Box>
                  <Typography fontSize={"15px"} textAlign={"center"}>
                    (COMPLETE THIS SECTION ONLY IF A TRANSIT SYSTEM IS OPERATED
                    FOR TRANSPORTING PUPILS/STUDENT TO AND FROM SCHOOL)
                  </Typography>
                </Box>
  
                <Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <Typography>Are school transit available</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="numberOfVehicleUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnNumberOfVehicleUsed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <Typography>Type of vehicles used</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="typeOfVehiclesUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnTypeOfVehiclesUsed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <Typography>Number of drivers employed</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="numberOfDriversEmployed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnNumberOfDriversEmployed"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <Typography>Evidence of induction seen</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="inductionEvidenceSeen"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnInductionEvidenceSeen"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Box width={"40%"}>
                      <Typography>Drivers’ licenses seen?</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="DriversLicenses"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnLicenseHeldByDrivers"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Drivers age/ages</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="driversAge"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDriversAge"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Drivers CBC certificate of attendance seen?
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="driverCbcCertOfAttendance"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDriverSsCertOfAttendance"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Confidential records of pupils available
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="confidentialRecordOfPupils"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnConfidentialRecordOfPupils"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>School bus rules available on board</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isSchoolBusRulesOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsSchoolBusRulesOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Evidence of roll call on board</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfRollCallOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnEvidenceOfRollCallOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
  
                  {/* ====== */}
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Additional staff assisting the driver
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereAdditionalStaffAssistingDriver"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereAdditionalStaffAssistingDriver"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Seat belts present on all seats</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isSeatBeltPresentOnAllSeat"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsSeatBeltPresentOnAllSeat"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Approved transit first aid available on board
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereApprovedFirstAidOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereApprovedFirstAidOnBoard "
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Vehicle in good condition of repairs
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areVehiclesInGoodCondition"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreVehiclesInGoodCondition"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Vehicles particulars in order</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areVehiclesParticularsInOrder"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreVehiclesParticularsInOrder"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Maintenance log of vehicles sighted</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="vehicleMaintenanceSighted"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnVehicleMaintenanceSighted"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Routes defined and available on board
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areDefinedRoutesAvailableOnBoard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreDefinedRoutesAvailableOnBoard"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Handset for contacting parents available
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereTelephoneForContactingParents"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereTelephoneForContactingParents"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Handset with appropriate airtime credits
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="doesTelephoneHaveAirtime"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnDoesTelephoneHaveAirtime"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Periodic school bus emergency drills conducted
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isPeriodicBusEmergencyDrillConducted"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsPeriodicBusEmergencyDrillConducted"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>Evidence of Overloading seen</Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereOverloading"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereOverloading"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Are appropriate car seat used for young children
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereAppropriateSeatForYoungChildren"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnIsThereAppropriateSeatForYoungChildren"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
  
                  <Box display={"flex"} justifyContent={"space-between"} mt={4}>
                    <Box width={"40%"}>
                      <Typography>
                        Do children seat in front? (Ask how many children sit in
                        front to determine this).
                      </Typography>
                    </Box>
                    <Box width={"20%"}>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areChildrenSeatedInFront"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box width={"40%"}>
                      <TextField
                        name="remarksOnAreChildrenSeatedInFront"
                        multiline
                        minRows={3}
                        label="Additional Comments?"
                        variant="filled"
                        sx={{
                          width: 400,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                {/* ===================================== */}
             
              </Stack>
            </FormProvider>
          </Container>
        </Grid>
      </Fragment>
    );
  }
  
  export default AdminSchoolTransit;