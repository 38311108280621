import { useRef, useState } from "react";
// @mui
import {
  Box,
  Stack,
  Alert,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import {
  RHFEditor,
  FormProvider,
  RHFTextField,
} from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Send from "@material-ui/icons/SendOutlined";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useNavigate } from "react-router";

function MailSubmit({ conversationId, subject }) {
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { userData } = useAuth();
  const { schoolId } = userData;

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const defaultValues = {
    reply: "",
  };

  const EditorSchema = Yup.object().shape({
    reply: Yup.string().required("message is required"),
  });

  const methods = useForm({
    resolver: yupResolver(EditorSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleBack = () => {
    const baseUrl = PATH_DASHBOARD.mail.root;
    if (conversationId) {
      return navigate(`${baseUrl}/${conversationId}`);
    }
    return navigate(`${baseUrl}/`);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `Schools/ReplyInterventionConversation?schoolId=${schoolId}`,
        {
          schoolId,
          conversationId,
          subject,
          body: data.reply,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      methods.reset();
      handleBack()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <RHFTextField
          name="reply"
          fullWidth
          multiline
          minRows={2}
          maxRows={8}
          placeholder="Type a message"
          sx={{ "& fieldset": { border: "none !important" } }}
        />

        <Box
          sx={{
            mr: 3,
            mb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            type="submit"
            endIcon={<Send />}
            loading={isSubmitting}
            variant="contained"
            sx={{
              mt: 2,
            }}
          >
            Send
          </LoadingButton>
          {/* <Button type="submit">Send</Button> */}
        </Box>
      </FormProvider>
    </>
  );
}

export default MailSubmit;
