import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Box, Button, Container, Typography, Stack, Link } from "@mui/material";
import Telnet from "../../images/telnet logo.jpg";
// components
import Logo from "../../components/Logo";
//
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === "/";

  return (
    <Stack sx={{ minHeight: 1, minWidth: 100 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: "center",
            position: "relative",
            bgcolor: "background.default",
          }}
        >
          <Container>
            <Typography variant="caption" component="p">
              © All rights reserved by SSLAG
              <br /> Designed and Developed &nbsp; by .
            </Typography>
            <Box display={"flex"} justifyContent={"center"}>
              <Link
                color="#4169e1"
                underline="always"
                target="_blank"
                rel="noopener"
                href="https://telnetng.com/"
                sx={{
                  typography: "body2",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Telnet}
                  alt=""
                  style={{
                    width: 120,
                    height: 60,
                    // textAlign: "center",
                  }}
                />
              </Link>
            </Box>

            <Button
              // variant="outlined"
              color="primary"
              href="/"
              className="header"
            >
              Back to top
            </Button>
          </Container>
        </Box>
      )}
    </Stack>
  );
}
