// this was borne out of tiredness/frustration
const booleanToNumber = (value) => {
  if (value === null) {
    return "";
  } else if (value === undefined) {
    return "";
  } else if (value === "") {
    return "";
  } else if (value === true) {
    return 1;
  } else if (value === false) {
    return 0;
  }
};

export { booleanToNumber };
