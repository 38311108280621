import { Box } from "@mui/system";
import { Children, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const AdminNavDiv = styled.div`
  display: flex;

  color: "#9bbef9";
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    background: #ffff;
    color: "#9bbef9";
    border-left: 4px solid #6b6b6b;
    cursor: pointer;
  }
`;

const AdminNavLink = styled(Link)`
  display: flex;
  color: "#9bbef9";
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 12px;

  &:hover {
    background: #ffff;
    color: #228b22;
    border-left: 4px solid #6b6b6b;
    cursor: pointer;
  }
`;

const Sidebarlabel = styled.span`
  margin-left: 16px;
`;
const DropdownLink = styled(Link)`
  background: #9bbef9;
  color: #ffff;
  height: 50px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  font-size: 12px;

  &:hover {
    background: #6b6b6b;
    cursor: pointer;
  }
`;

function TeacherNav({ item }) {
  const [children, setChildren] = useState(false);

  const showChildren = () => setChildren(!children);

  if (item.Children === null || item.Children === undefined) {
    return (
      <Box>
        <AdminNavLink to={item.path} onClick={item.Children && showChildren}>
          <div>
            {item.icon}
            <Sidebarlabel>{item.title}</Sidebarlabel>
          </div>
        </AdminNavLink>
      </Box>
    );
  }

  return (
    <Box>
      <AdminNavDiv to={item.path} onClick={item.Children && showChildren}>
        <div>
          {item.icon}
          <Sidebarlabel>{item.title}</Sidebarlabel>
        </div>
        <div>
          {/* {item.open} */}
          {item.Children && children
            ? item.iconOpened
            : item.Children
            ? item.iconClosed
            : null}
        </div>
      </AdminNavDiv>

      {item.Children !== undefined && children
        ? item.Children.map((element, index) => (
            <DropdownLink key={index} to={element.path}>
              {element.icon}
              <Sidebarlabel>{element.title}</Sidebarlabel>
            </DropdownLink>
          ))
        : null}
    </Box>
  );
}

export default TeacherNav;
