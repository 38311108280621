import React, { Fragment, useState } from "react";
import {
  Typography,
  Box,
  List,
  Card,
  ListItemText,
  ListItemButton,
  CardHeader,
  Stack,
  Grid,
  Button,
  CardActions,
  ListItemIcon,
} from "@mui/material";
import { EventData3, EventData2, EventData1 } from "../../_mock/data-mock";
import { borderRadius } from "@mui/system";

function AdminDashboard4() {
  const [event3, setEvent3] = useState(EventData3);
  const [event2, setEvent2] = useState(EventData2);
  const [event1, setEvent1] = useState(EventData1);

  const font = {
    fontSize: "10px",
  };

  return (
    <Fragment>
      <Card>
        <CardActions
          sx={{ justifyContent: "space-between", backgroundColor: "#edf5f9" }}
        >
          <Typography
            style={{
              backgroundColor: "#edf5f9",
            }}
            ml={5}
            variant="h6"
          >
       Event
          </Typography>
          <Button
            size="small"
            style={{ color: "#1396e3" }}
            href="/admin/event/school-report"
          >
            View All
          </Button>
        </CardActions>

        <Grid display={"flex"} justifyContent={"space-around"}>
          <Grid item xs={12} md={3}>
            <Stack spacing={3}>
              <List
                style={font}
                component="nav"
                aria-label="main mailbox folders"
              >
                <Typography
                  variant="h6"
                  sx={{
                    background: "#edf5f9",
                    borderRadius: "3px",
                    mb: "5px",
                    p: 1,
                    borderRadius: "15px",
                  }}
                >
                  Upcoming Offsite Trip
                </Typography>
                {EventData2.map((eachData, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: "#edf5f9",
                      borderRadius: "3px",
                      minHeight: "60px",
                      borderRadius: "5px",
                    }}
                  >
                    <ListItemButton>
                      <Box
                        sx={{
                          minWidth: "40px",
                          minHeight: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                        }}
                      >
                        <Typography textAlign={"center"} mt={1}>
                          {" "}
                          {eachData.index}
                        </Typography>
                      </Box>

                      <Typography ml={1} bgcolor="#edf5f9">
                        {eachData.event}
                      </Typography>
                    </ListItemButton>
                  </Box>
                ))}
              </List>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack spacing={3}>
              <List component="nav" aria-label="main mailbox folders">
                <Typography
                  variant="h6"
                  sx={{
                    background: "#edf5f9",
                    borderRadius: "3px",
                    mb: "5px",
                    p: 1,
                    borderRadius: "15px",
                  }}
                >
                  Upcoming school Training
                </Typography>

                {EventData3.map((eachData, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: "#edf5f9",
                      borderRadius: "3px",
                      minHeight: "60px",
                      borderRadius: "5px",
                    }}
                  >
                    <ListItemButton>
                      <Box
                        sx={{
                          minWidth: "40px",
                          minHeight: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                        }}
                      >
                        <Typography textAlign={"center"} mt={1}>
                          {" "}
                          {eachData.index}
                        </Typography>
                      </Box>
                      <Typography bgcolor="#edf5f9" p={1}>
                        {eachData.event}
                      </Typography>
                    </ListItemButton>
                  </Box>
                ))}
              </List>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack spacing={3}>
              <Card>
                <List component="nav" aria-label="main mailbox folders">
                  <Typography
                    variant="h6"
                    sx={{
                      background: "#edf5f9",
                      borderRadius: "3px",
                      mb: "5px",
                      p: 1,
                      borderRadius: "15px",
                    }}
                  >
                    Upcoming School Event
                  </Typography>

                  {EventData2.map((eachData, index) => (
                    <Box
                      key={index}
                      sx={{
                        background: "#edf5f9",
                        borderRadius: "3px",
                        minHeight: "60px",
                        borderRadius: "5px",
                      }}
                    >
                      <ListItemButton>
                        <Box
                          sx={{
                            minWidth: "40px",
                            minHeight: "40px",
                            borderRadius: "50%",
                            background: "#fff",
                          }}
                        >
                          <Typography textAlign={"center"} mt={1}>
                            {" "}
                            {eachData.index}
                          </Typography>
                        </Box>
                        <Typography p={1} bgcolor="#edf5f9">
                          {eachData.event}
                        </Typography>
                      </ListItemButton>
                    </Box>
                  ))}
                </List>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}

export default AdminDashboard4;






























// =============================


