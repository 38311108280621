import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Grid } from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage:
    "url(https://res.cloudinary.com/dhojrtqwz/image/upload/v1692211989/Picture1_icg5ly.jpg)",
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function SSLAGTeam() {
  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%" }}
      >
        <Box sx={{ bottom: 30, position: "absolute" }}>
          <Typography
            variant="h4"
            alignSelf={"flex-end"}
            textAlign={"center"}
            color={"#ffff"}
          >
            SSLAG IMPLEMENTATION TEAM
          </Typography>
        </Box>
      </Container>
    </RootStyle>
  );
}
