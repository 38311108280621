import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Stack,
  Alert,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Card,
  CardHeader,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import AdminBG from "../../images/background.png";
import { useSnackbar } from "notistack";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../components/hook-form";
import { useForm } from "react-hook-form";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import EditConcessionCode from "./EditConcessionCode";
import { BoxMask } from "src/components/settings";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="School Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function DiscountManagement() {
  useEffect(() => {
    let unmounted = false;
    const getAllDiscountType = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetConcessionTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setDiscountType([...discountType, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllDiscountType();

    const getAllDiscountValue = async () => {
      let unmounted = false;
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetConcessionValueTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setDiscountValue([...discountValue, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllDiscountValue();
    return () => {
      unmounted = true;
    };
  }, []);

  const DiscountSchema = Yup.object().shape({
    discountValue: Yup.string().required("field  is required"),
    discountName: Yup.string().required("field  is required"),
    discountType: Yup.string().required("field  is required"),
    percentageRate: Yup.string().when("discountValue", {
      is: "1",
      then: Yup.string().required("field is required"),
    }),
    targetedemail: Yup.string().when("discountType", {
      is: "1",
      then: Yup.string().required("field is required"),
    }),
    flatRate: Yup.string().when("discountValue", {
      is: "2",
      then: Yup.string().required("field is required"),
    }),
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [getToken, setGetToken] = useState([]);
  const theme = useTheme();

  const defaultValues = {
    targetedemail: "",
    discountType: "",
    discountValue: "",
    discountName: "",
    percentageRate: "",
    flatRate: "",
  };

  const [discountValue, setDiscountValue] = useState([
    {
      id: "",
      name: "select discount value",
    },
  ]);

  const [discountType, setDiscountType] = useState([
    {
      id: "",
      name: "select discount type",
    },
  ]);

  const methods = useForm({
    resolver: yupResolver(DiscountSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const watchDiscount = watch("discountType");
  const watchDiscounts = watch("discountValue");
  const [status, Status] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState("");

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  useEffect(() => {
    let unmounted = false;
    const getAllConcession = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetAllConcessions `
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setGetToken([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllConcession();
    return () => {
      unmounted = true;
    };
  }, []);

  const columns = [
    {
      name: "S/N",

      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },

    {
      name: "Name",
      selector: (row) => row.name,
    },

    {
      name: " Type",
      selector: (row) => row.type,
    },
    {
      name: " Value",
      selector: (row) => row.value,
    },
    {
      name: " Code",
      selector: (row) => row.code,
    },

    {
      name: "Date created",

      selector: (row) => row.dateCreated.split("T")[0],
    },
    {
      name: "Status",
   
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={(row.isActive && "success") || (!row.isActive && "error")}
        >
          {row.isActive ? sentenceCase("Active") : sentenceCase("Inactive")}
        </Label>
      ),
    },

    {
      cell: (row) => <MoreMenuButton ChangeStatus={ChangeStatus} id={row.id} />,
    },
  ];

  const onSubmit = async (data) => {
    let valueToSubmit = 0;
    if (data.discountValue == 1) {
      valueToSubmit = data.percentageRate;
    } else if (data.discountValue == 2) {
      valueToSubmit = data.flatRate;
    }

    try {
      const response = await axoisCustomAgentForAdmin.post(
        `Audit/CreateConcession`,
        {
          name: data.discountName,
          typeId: data.discountType,
          valueTypeId: data.discountValue,
          value: valueToSubmit,
          targetEmails: data.targetedemail.split(","),
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      let newDiscount = response.data.data;
      const UpdatedDiscount = [...getToken];
      UpdatedDiscount.push(newDiscount);
      setGetToken(UpdatedDiscount);
      enqueueSnackbar(message, {
        variant: "success",
      });

      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const ChangeStatus = async (id) => {
    try {
      const response = await axoisCustomAgentForAdmin.put(
        `/Audit/DeactivateConcession?concessionId=${id}`
      );
      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      let newArray = [...getToken];
      newArray.find((x) => x.id == id).isActive = false;
      setGetToken([...newArray]);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Records ....</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Fragment>
      <Typography variant="h4" mt={3}>
        Admin Dashboard
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 3,
          background: `url(${AdminBG})`,
          minHeight: "120vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",

            minHeight: 200,
            width: "80%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Box display={"flex"} justifyContent={"space-between"}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <RHFTextField
                    name="discountName"
                    label="Discount Name"
                    variant="filled"
                  />
                </Grid>

                <Grid item xs={3}>
                  <RHFTextField
                    select
                    name="discountType"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                  >
                    {discountType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </RHFTextField>
                </Grid>
                {watchDiscount === "1" ? (
                  <Grid item xs={3}>
                    <RHFTextField
                      name="targetedemail"
                      minRows={3}
                      multiline
                      label="targeted Email"
                      variant="filled"
                    />
                  </Grid>
                ) : null}

                <Grid item xs={3}>
                  <RHFTextField
                    select
                    name="discountValue"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                  >
                    {discountValue.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </RHFTextField>
                </Grid>

                {watchDiscounts === "1" ? (
                  <Grid item xs={3}>
                    <RHFTextField
                      name="percentageRate"
                      label="Percentage Rate (please enter value range 1-100) "
                      variant="filled"
                    />
                  </Grid>
                ) : null}

                {watchDiscounts === "2" ? (
                  <Grid item xs={3}>
                    <RHFTextField
                      name="flatRate"
                      label="flat Rate (please this should be in naira)"
                      variant="filled"
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            <Grid textAlign={"right"} p={2}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Grid>
          </FormProvider>
        </Container>

        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 400,
            width: "80%",
            mt: 10,
          }}
        >
          <Card>
            <CardHeader title="Discount Management" />

            <Grid p={2}>
              {getToken.length === 0 ? (
                <Typography>No Record</Typography>
              ) : (
                <DataTable
                  columns={columns}
                  data={getToken}
                  subHeader
                  editModde="row"
                  // subHeaderComponent={subHeaderComponentMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                  customStyles={customStyles}
                />
              )}
            </Grid>
          </Card>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default DiscountManagement;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ isActive, ChangeStatus, id }) {
  const [open, setOpen] = useState(null);

  // for popover
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  // close popover
  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [modalopen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {/* ====edit details */}
        <MenuItem onClick={handleModalOpen} sx={{ color: "succes" }}>
          <Iconify icon={"eva:edit-2-outline"} sx={{ ...ICON }} />
          Edit
        </MenuItem>

        {/* <Modal
          open={modalopen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Concession Code
            </Typography>
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <EditConcessionCode />
            </Box>
          </Grid>
        </Modal> */}

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* =========view detail */}

        <MenuItem
          onClick={async () => await ChangeStatus(id)}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Deactive
        </MenuItem>
      </MenuPopover>
    </>
  );
}
