import React from 'react'

function AdminAuditReport() {
  return (
    <div>AdminAuditReport</div>
  )
}

export default AdminAuditReport


