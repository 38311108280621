import React from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Alert,
  FormHelperText,
  Modal,
  Button,
} from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { formatRFC3339 } from "date-fns";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminAnalytic from "./AdminAnalytic";

function Training({ addConsultant }) {
  const AccountSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    title: Yup.string().required("title is required"),
    lastName: Yup.string().required("First name is required"),
    ConsultantTitle: Yup.string().required("Consultant title is required"),
  });

  const PaperStyle = {
    padding: 40,
    width: 700,
    marginTop: 8,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
    //
  };

  const onSubmit = (data) => {};

  const defaultValues = {
    title: "",
    description: "",
    trainingDay: "",
  };

  const methods = useForm({
    resolver: yupResolver(AccountSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
  } = methods;

  const initialstate = {
    title: "",
    description: "",
    fees: "",
  };

  const [state, setState] = useState(initialstate);

  const handleFeesChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const handleTimeChange = (value, type) => {
    setState({
      ...state,
      [type]: formatRFC3339(value),
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //==========================================
  // console.log(touchedFields);
  //==========================================

  const { enqueueSnackbar } = useSnackbar();
  const handle = async () => {
    if (state.firstName.trim() === "" || state.lastName.trim() === "") {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } else {
      await addConsultant(state);
      setState({
        title: "",
        firstName: "",
        lastName: "",
      });
    }
  };

  return (
    <Grid>
      <Typography variant="h4">Assign Resource</Typography>

      <Grid container style={Gridimage} justifyContent="center">
        <Paper style={PaperStyle}>
          <Grid align="center" mt={3}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
              </Stack>
              <Box>
                <FormHelperText>Title:</FormHelperText>
                <RHFTextField
                  name="title"
                  // label="Title"
                  variant="outlined"
                  fullWidth
                />
              </Box>
              <Box>
                <FormHelperText>Description of Training</FormHelperText>
                <RHFTextField
                  name="description"
                  placeholder="Description of training"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  minRows={3}
                />
              </Box>
              {/* <Box>
                <FormHelperText> Is Inset Training Free</FormHelperText>
                <RHFTextField
                  select
                  name="fees"
                  required
                  SelectProps={{ native: true }}
                >
                  {FEES_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </RHFTextField>
                {state.value === 2 ? (
                  <Box mt={2}>
                    <RHFTextField
                      name="TrainingFees"
                      label="Title"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                ) : null}
              </Box> */}

              <Box mt={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Training Day "
                    value={state.trainingDay}
                    disableMaskedInput
                    onChange={(value) => handleTimeChange(value, "trainingDay")}
                    renderInput={(params) => <RHFTextField {...params} />}
                    on
                  />
                </LocalizationProvider>
              </Box>

              <Button sx={{ mt: 5 }} onClick={handleOpen}>
                Add Resource
              </Button>

              <AdminAnalytic />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Consultant
                  </Typography>
                  <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    <Box mt={2}>
                      <RHFTextField
                        name="ConsultantTitle"
                        label="Title"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box mt={2}>
                      <RHFTextField
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box mt={2}>
                      <RHFTextField
                        name="lastName"
                        label="Middle Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box mt={2}>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        color="secondary"
                        loading={isSubmitting}
                      >
                        ADD
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </FormProvider>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Training;
