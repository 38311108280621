import { Grid, Box, Typography, Button } from "@mui/material";
import React from "react";

function DocumentaryEvidence() {
  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Box paddingRight={8}>
          <Button
            href="/dashboard/user/documentaryEvidence"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Safety budget
          </Button>
          <Button
            href="/dashboard/user/certificateParticipation"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Certificate of participation
          </Button>

          <Button
            href="/dashboard/user/safetyInfrastructure"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safety infrastructure
          </Button>

          <Button
            href="/dashboard/user/manadatorySupplies"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Mandatory supplies
          </Button>
          <Button
            href="/dashboard/user/safeInternet"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safe internet
          </Button>
          <Button
            href="/dashboard/user/insurance"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Insurance
          </Button>
          <Button
            href="/dashboard/user/signages"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Signages
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

export default DocumentaryEvidence;

// import { Box, Typography } from "@mui/material";
// import { useState } from "react";
// // import { Document } from "react-pdf/dist/esm/entry.webpack";
// // import PDF from "./document.pdf";
// import { Document, Page, pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// function DocumentaryEvidence() {
//   const [numPages, setNumPages] = useState(null);
//   function onDocumentLoadSuccess({ numPages: nextNumPages }) {
//     setNumPages(nextNumPages);
//   }

//   return (
//     <Box>
//       <Typography>SSLAG Documentary Evidence Documentation</Typography>

//       <Document
//         file="https://res.cloudinary.com/peax3/image/upload/v1658289863/Documentary_Evidence_wzzwlz.pdf"
//         onLoadSuccess={onDocumentLoadSuccess}
//       >
//         {Array.from(new Array(numPages), (el, index) => (
//           <Box border={100}>
//             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//           </Box>
//         ))}

//         <Page pageNumber={1} />
//       </Document>
//     </Box>
//   );
// }

// export default DocumentaryEvidence;
