import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
  Avatar,
  Stack,
  Alert,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import { deepOrange, deepPurple } from "@mui/material/colors";
import DatePicker from "@mui/lab/DatePicker";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";

function OnSiteProtocol() {
  const [completedTask, setCompletedTask] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [getAandEDetails, setGetAandEDetails] = useState("");
  const [taskIdToUpdate, setTaskIdToUpdate] = useState("");
  const [viewClerk, setViewClerk] = useState([]);
  const navigate = useNavigate();

  const theme = useTheme();
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
  };

  const methods = useForm({
    // resolver: yupResolver(DateSchema),
    // defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = () => {};

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = completedTask.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    let unmounted = false;
    const getAllTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetAuditTasks?status=${3}&source=${2}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setCompletedTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllTask();
    return () => {
      unmounted = true;
    };
  }, []);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "School Name",
      selector: (row) => row.school?.schoolName,
    },
    // {
    //   name: "Date Assigned",
    //   selector: (row) => row.aandE?.dateAssiged.split("T")[0],
    // },
    {
      name: "Audit Date",
      // selector: (row) => row.dateOfAudit,
      cell: (row) =>
        // row.dateOfAudit === null ? (
        //   <>
        //     <Button
        //       variant="contained"
        //       disabled={row.dateOfAudit}
        //       onClick={() => handleOpen(row.id)}
        //     >
        //       Add Date
        //     </Button>
        //   </>
        // ) : (
        row.dateOfAudit.split("T")[0],
      // ),
    },
    {
      name: "AE(URN)",
      cell: (row) => (
   
        <Button variant="outlined" onClick={() => handlePageOpen(row.aandE)}>
          {row.aandE?.urn}
        </Button>
      ),
      // ),
    },

    {
      name: "Clerks (URN)",
      cell: (row) =>
        row.auditClerks === null || row.auditClerks.length === 0 ? (
          <Typography></Typography>
        ) : (
          <Button
            variant="outlined"
            onClick={() => handlePageClerk(row.id, row.auditClerks)}
          >
            {/* {row.auditClerk?.urn} */}
            View
          </Button>
        ),
      // selector: (row) => row.auditClerk?.urn,
    },

    // {
    //   name: "Validation Document",
    //   // selector: (row) => row.isValidationDocumentSent,
    //   cell: (row) => (
    //     <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
    //       {sentenceCase(row.isValidationDocumentSent ? "True" : "false")}
    //     </Label>
    //   ),
    // },

    {
      name: "Payment Status",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(row.invoice.paymentStatus ? "paid" : "not paid")}
        </Label>
      ),
    },
    {
      name: "Validation Document",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(
            row.isValidationDocumentSent ? "recieved" : "Not received"
          )}
        </Label>
      ),
      //   cell: (row) => (
      //     <>
      //       <Button
      //         variant="contained"
      //         disabled={row.isValidationDocumentSent || !row.auditClerk}
      //         onClick={() => isValidationDocument(row.id)}
      //       >
      //         {" "}
      //         Send
      //       </Button>
      //     </>
      //   ),
    },
  ];

  const updateAandEForTask = (taskId, aeinfo) => {
    const newTask = [...completedTask];
    const taskIndex = newTask.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      newTask[taskIndex].aandE = {
        ...aeinfo,
      };
      setCompletedTask(newTask);
    }
  };

  const updatedTask = (taskId) => {
    const newTask = [...completedTask];
    const taskIndex = newTask.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      newTask[taskIndex].statusId = 5;
      newTask[taskIndex].status = "validation document Sent";
      newTask[taskIndex].isValidationDocumentSent = true;

      setCompletedTask(newTask);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = (taskId) => {
    setTaskIdToUpdate(taskId);
    setOpen(true);
  };
  const handleClose = () => {
    setTaskIdToUpdate("");
    methods.setValue("auditDate", "", {
      shouldValidate: true,
    });
    setOpen(false);
  };

  const [page, setPage] = React.useState(false);
  const [value, setValue] = React.useState();

  const handlePageOpen = (taskId) => {
    setGetAandEDetails(taskId);
    setPage(true);
  };

  const handlePageClose = () => {
    setGetAandEDetails("");
    setPage(false);
  };

  const [task, setTask] = useState([]);
  const [currentTaskId, setCurrentTaskId] = useState("");

  const DeleteTask = (taskId) => {
    const newTask = [...task];
    const updatedTask = newTask.filter((x) => x.id !== taskId);

    setTask(updatedTask);
  };

  const [pageAssign, setPageAssign] = React.useState(false);

  const handlePageOpenAssign = (taskId) => {
    setCurrentTaskId(taskId);
    setPageAssign(true);
  };

  const handlePageCloseAssign = () => {
    setCurrentTaskId("");
    setPageAssign(false);
  };

  const [openClerk, setOpenClerk] = React.useState(false);
  // const [value, setValue] = React.useState();

  const handlePageClerk = (taskId, clerks) => {
    setCurrentTaskId(taskId);
    setViewClerk(clerks);
    setOpenClerk(true);
  };

  const handlePageClerkClose = () => {
    setViewClerk([]);
    setCurrentTaskId("");
    setOpenClerk(false);
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",
            padding: 2,
          }}
        >
          <Typography variant="h4">On-Site Protocols</Typography>
        </Box>
        {/* <Box>
    <Typography>Completed Audit</Typography>
</Box> */}
        <Card
          sx={{
            width: "80%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {completedTask.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={completedTask}
                filteredItems
                editModde="row"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>

        {/* Assessors Model */}
        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              Assessor Information
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <Card
                sx={{
                  width: "93%",
                  height: 220,
                  mt: 4,

                  alignContent: "center",
                }}
              >
                <Box m={2} display={"flex"} justifyContent={"space-around"}>
                  <Box>
                    {/* <Avatar alt="Remy Sharp" src={proficeIcon} style={{ height: 120, width: "90%" }} /> */}
                    <Avatar
                      sx={{ bgcolor: deepOrange[500] }}
                      style={{ height: 150, width: 150 }}
                    >
                      N
                    </Avatar>
                  </Box>
                  <Box mt={2}>
                    <Box display={"flex"}>
                      <Typography>URN:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.urn}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> First Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.firstName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Middle Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.middleName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Last Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.lastName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>District:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.district}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Phone Number:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.phoneNumber}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Address:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {getAandEDetails.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Modal>

        {/* view clerk modal */}

        <Modal
          open={openClerk}
          onClose={handlePageClerkClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Clerks Information
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div>
                {viewClerk.map((eachData, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    justifyContent={"space-around"}
                  >
                    <Box>
                      {/* <Avatar alt="Remy Sharp" src={proficeIcon} style={{ height: 120, width: "90%" }} /> */}
                      <Avatar
                        sx={{ bgcolor: deepOrange[500] }}
                        style={{ height: 150, width: 150 }}
                      >
                        N
                      </Avatar>
                    </Box>
                    <Box mt={2}>
                      <Box display={"flex"}>
                        <Typography>URN:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.urn}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> First Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.firstName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> Middle Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.middleName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography>Last Name:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.lastName}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography> Phone Number:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.phoneNumber}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography>Address:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.address}
                        </Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Typography>Role:</Typography>
                        <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                          {eachData.role}
                        </Typography>
                      </Box>
                      {/* gradingForm */}

                      <Button
                        href={`/admin/audit/gradingForm/${currentTaskId}/${eachData.id}/${eachData.roleId}`}
                      >
                        View Forms
                      </Button>
                    </Box>
                  </Box>
                ))}
              </div>
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </Fragment>
  );
}

export default OnSiteProtocol;
