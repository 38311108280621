import { useState, useEffect } from "react";
import {
  Box as Grid,
  Stack,
  Container,
  Typography,
  Button,
  Link,
} from "@mui/material";
import Logo from "src/components/Logo";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Bg1 from "../../images/sslagLandingImg.jpg";
import InsuranceForm from "./InsuranceForm";
import InsuranceList from "./InsuranceList";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "../../hooks/useAuth";
import { booleanToNumber } from "src/utils/booleanNumberConverter";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import Next from "@material-ui/icons/ChevronRight";
import Back from "@material-ui/icons/ChevronLeft";

function MandatoryInsurance({ nextStep, prevStep }) {
  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;

  const initialState = {
    doesSchoolHaveInsuranceWithSSLAGBroker: "",
    schoolHmoAndInsurances: [],
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetctBrokers = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolOnboarding/GetSchoolHMOAndInsuranceInfo/${schoolId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const {
            doesSchoolHaveInsuranceWithSSLAGBroker,
            schoolHmoAndInsurance,
          } = response.data.data;

          let schoolHmoAndInsurancesCustom = [];

          if (schoolHmoAndInsurance.length > 0) {
            schoolHmoAndInsurancesCustom = schoolHmoAndInsurance.map(
              (eachItem, index) => {
                return {
                  ...eachItem,
                  status: "old",
                };
              }
            );
          }

          let boolToNumber = booleanToNumber(
            doesSchoolHaveInsuranceWithSSLAGBroker
          );
          setState({
            doesSchoolHaveInsuranceWithSSLAGBroker: boolToNumber,
            schoolHmoAndInsurances: schoolHmoAndInsurancesCustom,
          });
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    fetctBrokers();
  }, [schoolId]);

  const addBroker = ({
    insuranceType,
    insuranceDetails,
    policyNumber,
    hmoName,
    imageId,
    imageUrl,
  }) => {
    const customId = state.schoolHmoAndInsurances.length + 1;
    setState({
      ...state,
      schoolHmoAndInsurances: [
        ...state.schoolHmoAndInsurances,
        {
          customId,
          insuranceType,
          insuranceDetails,
          policyNumber,
          hmoName,
          imageId: imageId === "" ? null : imageId,
          imageUrl: imageUrl === "" ? null : imageUrl,
          status: "new",
        },
      ],
    });
  };

  const deleteBroker = async (indexToDelete) => {
    const brokerToDelete = state.schoolHmoAndInsurances[indexToDelete];

    if (brokerToDelete.status === "old") {
      await axoisCustomAgent.delete(
        `SchoolOnboarding/${brokerToDelete.id}/RemoveSchoolHMOAndInsurance/${schoolId}`
      );
    }

    const newArray = state.schoolHmoAndInsurances.filter(
      (s, index) => index !== indexToDelete
    );
    setState({
      ...state,
      schoolHmoAndInsurances: [...newArray],
    });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      doesSchoolHaveInsuranceWithSSLAGBroker: event.target.value,
    });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // =======================================

  const handleSubmit = async () => {
    const newBrokers = state.schoolHmoAndInsurances
      .filter((sd) => sd.status === "new")
      .map((item) => {
        return {
          insuranceType: item.insuranceType,
          insuranceDetails: item.insuranceDetails,
          policyNumber: item.policyNumber,
          hmoName: item.hmoName,
          imageId: item.imageId,
          imageUrl: item.imageUrl,
        };
      });

    const { doesSchoolHaveInsuranceWithSSLAGBroker } = state;

    setLoadingSubmit(true);

    try {
      const response = await axoisCustomAgent.post(
        `SchoolOnboarding/CreateSchoolHMOAndInsurance/${schoolId}`,
        {
          doesSchoolHaveInsuranceWithSSLAGBroker,
          schoolHmoAndInsurances: newBrokers,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      // console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };
  // =======================================

  //========================================

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `url(${Bg1}) `,
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
        // fullWidth
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              MinWidth: 100,
              background: "#ffff",
              borderRadius: 2,
              padding: 2,
              // margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
              maxHeight: 800,
            }}
          >
            <Typography
              sx={{ padding: 5, color: "#6495ed", textAlign: "center" }}
            >
              {" "}
              MANDATORY INSURANCE
            </Typography>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Container sx={{ p: 3 }}>
                <Stack width={700}>
                  <FormControl width={450}>
                    <FormHelperText>
                      Do you have SSLAG compliance insurance?
                    </FormHelperText>
                    <Select
                      labelId="SafeSchInsPolicy"
                      variant="filled"
                      id="safe school compliance insurance plan"
                      value={state.doesSchoolHaveInsuranceWithSSLAGBroker}
                      label="safe school compliance insurance plan"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>yes</MenuItem>
                    </Select>
                  </FormControl>

                  {state.doesSchoolHaveInsuranceWithSSLAGBroker === 0 ? (
                    <Stack>
                      <Typography mt={2}>
                        Please, provide details of alternative insurance plans
                      </Typography>

                      <InsuranceForm addBroker={addBroker} />
                      <InsuranceList
                        brokerArray={state.schoolHmoAndInsurances}
                        deleteBroker={deleteBroker}
                      ></InsuranceList>
                    </Stack>
                  ) : null}
                </Stack>

                <Grid sx={{ mt:7, textAlign: "right" }}>
                  <LoadingButton
                    loading={loadingSubmit}
                    label="Prev"
                    variant="contained"
                    type="submit"
                    sx={{ mr: 4 }}
                    startIcon={<Back />}
                    onClick={prevStep}
                  >
                    Back
                  </LoadingButton>

                  <LoadingButton
                    loading={loadingSubmit}
                    endIcon={<Next />}
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Next
                  </LoadingButton>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MandatoryInsurance;
