import {
  Grid,
  FormHelperText,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import {
//   FormProvider,
//   RHFTextField as TextField,
// } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

function ViewStaff({ schoolId, staffId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [dataAccess, setDataAccess] = useState(false);
  const [initialstate, setInitialstate] = useState(null);

  const methods = useForm({
    resolver: yupResolver(),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const getAllStaffProfile = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `SchoolStaff/${schoolId}/GetStaffById/${staffId} `
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setInitialstate({ ...data });
        setDataAccess(true);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStaffProfile();
  }, [schoolId, staffId]);

  if (loading || !dataAccess) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Staff...</Typography>
        </Box>
      </Box>
    );
  }

  if (!loading && initialstate == null && dataAccess) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Staff Not Found</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Grid>
      <Grid container spacing={5} mt={2} display={"flex"}>
        <Grid padding={3}>
          <Grid item>
            <FormHelperText>First Name:</FormHelperText>
            <TextField
              name="firstName"
              variant="outlined"
              value={initialstate.firstName}
              disabled
            />
          </Grid>
          <Grid item>
            <FormHelperText>Middle Name:</FormHelperText>
            <TextField
              name="middleName"
              value={initialstate.middleName}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormHelperText>Last Name:</FormHelperText>
            <TextField
              name="lastName"
              value={initialstate.lastName}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormHelperText>Gender:</FormHelperText>
            <TextField
              name="gender"
              value={initialstate.gender}
              disabled
              required
            ></TextField>
          </Grid>

          <Grid item>
            <FormHelperText>Email:</FormHelperText>
            <TextField
              name="email"
              value={initialstate.email}
              disabled
              type="email"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormHelperText>Year Employed:</FormHelperText>
            <TextField
              name="yearEmployed"
              value={initialstate.yearEmployed}
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid padding={3}>
          <Grid item>
            <FormHelperText>Qualification:</FormHelperText>
            <TextField
              name="qualifications"
              value={initialstate.certificateType}
              disabled
              required
            ></TextField>
          </Grid>
          <Grid item>
            <FormHelperText>Role:</FormHelperText>
            <TextField
              name="roleId"
              value={initialstate.staffRole}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormHelperText>Telephone:</FormHelperText>
            <TextField
              name="telephone"
              value={initialstate.phoneNumber}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <FormHelperText>State Of Origin:</FormHelperText>
            <TextField
              name="stateOfOrigin"
              value={initialstate.stateOfOrigin}
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid padding={3}>
          <Grid item>
            <FormHelperText>LGA</FormHelperText>
            <TextField
              name="lga"
              value={initialstate.lgaOfOrigin}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item width={220}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormHelperText>Date Of Birth:</FormHelperText>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={initialstate.dateOfBirth}
                disabled
                // value={methods.watch().DOB}
                // onChange={(value) => {
                //   methods.setValue("DOB", value, {
                //     shouldValidate: true,
                //   });
                // }}
                renderInput={(params) => <TextField {...params} name="DOB" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <FormHelperText>Staff ID:</FormHelperText>
            <TextField
              name="staffRegNo"
              value={initialstate.registrationNumber}
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>

        {/* ====== */}
      </Grid>
    </Grid>
  );
}

export default ViewStaff;



