import React, { Checkbox, FormHelperText } from "@mui/material";
import {
  RHFTextField,
  FormProvider,
  RHFCheckbox,
} from "../../components/hook-form";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import LoadingButton from "@mui/lab/LoadingButton";

function TeacherAttendance() {
  const { userData } = useAuth();

  const {
    schoolStaff: { staffSchoolId },
    userId,
  } = userData;
  const { enqueueSnackbar } = useSnackbar();

  // console.log({ userId, schoolId });

  const AttedenaceSchema = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.string().required("class Arm is required"),
    TOD: Yup.string().nullable().required("attendance date  is required"),
    attendanceTime: Yup.string().required(
      "morning / afternoon kindly select one "
    ),
  });

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "50vh",
  };

  const defaultValues = {
    classArm: "",
    class: "",
    classId: "",
    classArmId: "",
    attendanceTime: "",
    TOD: new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(AttedenaceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const Time = [
    {
      value: "",
      label: "Select Period",
    },
    {
      value: "1",
      label: "Morning",
    },
    {
      value: "2",
      label: "Afternoon",
    },
  ];

  const [studentsInClass, setStudentsInClass] = useState([]);
  const [loadingStudentInClass, setLoadingStudentInClass] = useState(false);
  const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState("");

  const [loading, setLoading] = useState(false);
  const [isTeacherAssignedToClass, setIsTeacherAssignedToClass] =
    useState(false);

  // const [classes, setClasses] = useState([
  //   {
  //     id: "",
  //     yearGroupName: "Select Year Group",
  //   },
  // ]);

  // const [classArms, setClassArms] = useState([
  //   {
  //     id: "",
  //     className: "Select Class",
  //   },
  // ]);

  const watchClass = methods.watch("class");
  const { formState, setValue } = methods;

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `YearGroups/GetClassAssignedToTeacher?schoolId=${staffSchoolId}&teacherId=${userId}`
        );

        const { status } = response.data;

        if (status === 500) {
          throw new Error("Something went wrong");
        }

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        if (!unmounted) {
          if (data.class !== null) {
            setIsTeacherAssignedToClass(true);
            setValue("class", data.class.yearGroupName);
            setValue("classId", data.class.yearGroupId);
            setValue("classArm", data.class.className);
            setValue("classArmId", data.class.classId);
          }
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (staffSchoolId != null) {
      getClasses();
    }

    return () => {
      unmounted = true;
    };
  }, [staffSchoolId]);

  const onViewStudents = async (data) => {
    setLoadingStudentInClass(true);
    try {
      const date = new Date(data.TOD).toISOString();

      const response = await axoisCustomAgent.get(
        `/Students/GetAllStudentAndAttendanceDetails?schoolId=${staffSchoolId}&yearGroupId=${data.classId}&classId=${data.classArmId}&attendanceDate=${date}`
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("successful", {
        variant: "success",
      });

      let customStudentsInClass = [];

      if (response.data.data.length > 0) {
        customStudentsInClass = response.data.data.map((eachData) => {
          const { attendance } = eachData;
          return {
            ...eachData,

            currentAttendance: {
              morningAttendanceStatus:
                attendance === null ||
                attendance.morningAttendanceStatus === null
                  ? ""
                  : attendance.morningAttendanceStatusId,

              afternoonAttendanceStatus:
                attendance === null ||
                attendance.afternoonAttendanceStatus === null
                  ? ""
                  : attendance.afternoonAttendanceStatusId,
            },
          };
        });
      }

      setStudentsInClass([...customStudentsInClass]);

      const period = Time.find((t) => t.value === data.attendanceTime);

      setCurrentSelectedPeriod(period.label);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingStudentInClass(false);
    }
  };

  const cancelAttendance = () => {
    methods.resetField("attendanceTime");
    setStudentsInClass([]);
  };

  const onRadioChange = (e, id) => {
    const newValue = e.target.value;

    const currentStudentsAttendance = [...studentsInClass];

    let indexOfStudent = currentStudentsAttendance.findIndex(
      (s) => s.studentId === id
    );

    if (currentSelectedPeriod === "Morning") {
      currentStudentsAttendance[
        indexOfStudent
      ].currentAttendance.morningAttendanceStatus = newValue;
    } else {
      currentStudentsAttendance[
        indexOfStudent
      ].currentAttendance.afternoonAttendanceStatus = newValue;
    }

    setStudentsInClass([...currentStudentsAttendance]);
  };
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async () => {
    setLoadingSubmit(true);

    try {
      if (currentSelectedPeriod === "Morning") {
        let dataNotValid = studentsInClass.some(
          (s) => s.currentAttendance.morningAttendanceStatus === ""
        );

        if (dataNotValid) {
          enqueueSnackbar("Select an attendance for all students", {
            variant: "error",
          });
          return;
        }
      }

      if (currentSelectedPeriod === "Afternoon") {
        let dataNotValid = studentsInClass.some(
          (s) => s.currentAttendance.afternoonAttendanceStatus === ""
        );
        if (dataNotValid) {
          enqueueSnackbar("Select an attendance for all students", {
            variant: "error",
          });
          return;
        }
      }

      let studentsData = studentsInClass.map((eachStudentData) => {
        const {
          studentId,
          currentAttendance: {
            morningAttendanceStatus,
            afternoonAttendanceStatus,
          },
        } = eachStudentData;
        return {
          studentId,
          attendanceStatus:
            currentSelectedPeriod === "Morning"
              ? morningAttendanceStatus
              : afternoonAttendanceStatus,
        };
      });

      const watchFields = methods.watch([
        "classId",
        "classArmId",
        "TOD",
        "attendanceTime",
      ]);

      const date = new Date(watchFields[2]).toISOString();

      const response = await axoisCustomAgent.post(
        `/Students/takeStudentAttendance`,
        {
          schoolId: staffSchoolId,
          yearGroupId: watchFields[0],
          classId: watchFields[1],
          attendancePeriod: watchFields[3],
          attendanceDate: date,
          studentsData,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("students attendance submitted successfully", {
        variant: "success",
      });

      methods.resetField("attendanceTime");
      setStudentsInClass([]);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingSubmit(false);
    }
  };

  //==================
  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading...</Typography>
        </Grid>
      </Grid>
    );
  }

  if (!isTeacherAssignedToClass) {
    <Grid>
      <Typography>You have not been assigned to a class</Typography>
    </Grid>;
  }
  //==================
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Attendance</Typography>

        <Box paddingRight={5}>
          <Button
            // variant="outlined"

            color="primary"
            href="/staff/teacher/mark-attendance"
            className="header active "
          >
            Mark Attendance
          </Button>

          <Button
            // variant="outlined"
            color="primary"
            href="/staff/teacher/view-attendance"
            className="header"
          >
            View Attendance
          </Button>
        </Box>
      </Box>

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={6}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onViewStudents)}
              >
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Typography>Mark Attendance</Typography>
                <hr />
                <Grid container spacing={5} mt={2}>
                  <Grid item width={240}>
                    <FormHelperText>Select Year Group</FormHelperText>

                    <RHFTextField
                      name="class"
                      required
                      SelectProps={{ native: true }}
                      disabled
                    />
                  </Grid>

                  <Grid item width={200}>
                    <FormHelperText>Select Class</FormHelperText>
                    <RHFTextField
                      name="classArm"
                      required
                      SelectProps={{ native: true }}
                    />
                  </Grid>

                  <Grid item width={260}>
                    <FormHelperText>Select Period</FormHelperText>
                    <RHFTextField
                      select
                      name="attendanceTime"
                      required
                      SelectProps={{ native: true }}
                      disabled={studentsInClass.length > 0}
                    >
                      {Time.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={260}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormHelperText>Date</FormHelperText>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        // label="Attendance Date "
                        value={methods.watch().TOD}
                        disabled
                        onChange={(value) => {
                          methods.setValue("TOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField {...params} name="TOD" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={studentsInClass.length > 0}
                  >
                    View Students
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* =====Table========================== */}

      <Fragment>
        {loadingStudentInClass ? (
          <Typography variant="h3">Loading Students....</Typography>
        ) : (
          <Grid style={Gridimage}>
            {studentsInClass.length === 0 ? (
              <Typography padding={2}>No Students Present</Typography>
            ) : (
              <Card sx={{ mt: 5, minWidth: 1000 }}>
                <CardHeader title="Attendance" sx={{ mb: 3 }} />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>
                            {`${currentSelectedPeriod}`} Attendance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentsInClass.map((eachStudent, index) => (
                          <TableRow key={index}>
                            {/* <TableCell>{index + 1}</TableCell> */}
                            <TableCell>{eachStudent.firstName}</TableCell>
                            <TableCell>{eachStudent.lastName}</TableCell>

                            {currentSelectedPeriod === "Morning" ? (
                              <TableCell>
                                <FormControl>
                                  <FormLabel id="attendance-controlled-radio-buttons-group"></FormLabel>
                                  <RadioGroup
                                    aria-labelledby="attendance-controlled-radio-buttons-group"
                                    name="attendance-radio-buttons-group"
                                    row
                                    value={
                                      eachStudent.currentAttendance
                                        .morningAttendanceStatus
                                    }
                                    onChange={(e) =>
                                      onRadioChange(e, eachStudent.studentId)
                                    }
                                  >
                                    <FormControlLabel
                                      value={1}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Present"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Sick"
                                    />
                                    <FormControlLabel
                                      value={3}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Late"
                                    />
                                    <FormControlLabel
                                      value={4}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Absent"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <FormControl>
                                  <FormLabel id="attendance-controlled-radio-buttons-group"></FormLabel>
                                  <RadioGroup
                                    aria-labelledby="attendance-controlled-radio-buttons-group"
                                    name="attendance-radio-buttons-group"
                                    row
                                    value={
                                      eachStudent.currentAttendance
                                        .afternoonAttendanceStatus
                                    }
                                    disabled={
                                      eachStudent.attendance !== null &&
                                      eachStudent.attendance
                                        .afternoonAttendanceStatus !== null
                                    }
                                    onChange={(e) =>
                                      onRadioChange(e, eachStudent.studentId)
                                    }
                                  >
                                    <FormControlLabel
                                      value={1}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Present"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Sick"
                                    />
                                    <FormControlLabel
                                      value={3}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Late"
                                    />
                                    <FormControlLabel
                                      value={4}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Absent"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            )}
                          </TableRow>
                          // <Card sx={{ mt: 5, minWidth: 1000 }}>
                          //   <CardHeader title="Attendance" sx={{ mb: 3 }} />
                          //   <Scrollbar>
                          //     <TableContainer sx={{ minWidth: 720 }}>
                          //       <Table>
                          //         <TableHead>
                          //           <TableRow>
                          //             <TableCell>First Name</TableCell>
                          //             <TableCell>Last Name</TableCell>
                          //             <TableCell>Year Group</TableCell>
                          //             <TableCell>Class</TableCell>
                          //             <TableCell> Period</TableCell>
                          //             <TableCell>Present / Absent</TableCell>

                          //             <TableCell />
                          //           </TableRow>
                          //         </TableHead>
                          //         <TableBody>
                          //           {studentsInClass.map((eachStudent, index) => (
                          //             <TableRow key={index}>
                          //               {/* <TableCell>{index + 1}</TableCell> */}
                          //               <TableCell>{eachStudent.firstName}</TableCell>
                          //               <TableCell>{eachStudent.lastName}</TableCell>
                          //               <TableCell>{eachStudent.yearGroupName}</TableCell>
                          //               <TableCell>{eachStudent.className}</TableCell>
                          //               <TableCell>{currentSelectedPeriod}</TableCell>

                          //               {currentSelectedPeriod === "Morning" ? (
                          //                 <TableCell>
                          //                   <Checkbox
                          //                     disabled={
                          //                       eachStudent.attendance !== null &&
                          //                       eachStudent.attendance.isPresentMorning !==
                          //                         null
                          //                     }
                          //                     checked={
                          //                       eachStudent.currentAttendance
                          //                         .isPresentMorning
                          //                     }
                          //                     onChange={(e) =>
                          //                       onCheckChange(e, eachStudent.studentId)
                          //                     }
                          //                   />
                          //                 </TableCell>
                          //               ) : (
                          //                 <TableCell>
                          //                   <Checkbox
                          //                     disabled={
                          //                       eachStudent.attendance !== null &&
                          //                       eachStudent.attendance
                          //                         .isPresentAfternoon !== null
                          //                     }
                          //                     checked={
                          //                       eachStudent.currentAttendance
                          //                         .isPresentAfternoon
                          //                     }
                          //                     onChange={(e) =>
                          //                       onCheckChange(e, eachStudent.studentId)
                          //                     }
                          //                   />
                          //                 </TableCell>
                          //               )}

                          //             </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Divider />
                <Box sx={{ p: 2, textAlign: "right" }}>
                  <Button
                    size="small"
                    color="inherit"
                    endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                  >
                    View All
                  </Button>
                </Box>

                <Grid
                  container
                  spacing={5}
                  sx={{ justifyContent: "center", mb: 2 }}
                >
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      color="error"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={cancelAttendance}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      color="secondary"
                      variant="contained"
                      loading={loadingSubmit}
                      onClick={async () => await onSubmit()}
                      // sx={{  ml: }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        )}
      </Fragment>

      {/* ============================== */}
    </Box>
  );
}

export default TeacherAttendance;
