import PropTypes from "prop-types";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { Stack, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { email } from "src/_mock/email";

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func,
};
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    // display: 'flex',
    // color: '#fff',
  },
}));

export default function ResetPasswordForm({
  onSent,
  onGetEmail,
  onGetStakeholderCategoryId,
}) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    stakeholderCategoryId: Yup.string()
      .oneOf(
        ["1", "2", "3", "4", "5", "6", "7", "9"],
        "select a valid category"
      )
      .required(),
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: "" },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const STAKEHOLDER_OPTIONS = [
    {
      value: "00",
      label: "Select Categories",
    },
    {
      value: "1",
      label: "Schools",
    },
    {
      value: "2",
      label: "Students",
    },

    {
      value: "3",
      label: "Parents",
    },
    {
      value: "4",
      label: "Assessors",
    },
    {
      value: "5",
      label: "Suppliers",
    },
    {
      value: "6",
      label: "collaborators & SME",
    },
    {
      value: "7",
      label: "General public",
    },
    {
      value: "9",
      label: "School Staff",
    },
  ];

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.get(
        `/Accounts/ForgotPassword?userEmail=${data.email}&stakeholderCategoryId=${data.stakeholderCategoryId}`
      );
      const { statusCode, message } = response.data;
      if (response.status === 500) {
        throw new Error("Server error");
      }
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      if (isMountedRef.current) {
        onSent();
        onGetEmail(data.email);
        onGetStakeholderCategoryId(data.stakeholderCategoryId);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }

    // try {
    //   await new Promise((resolve) => setTimeout(resolve, 500));
    //   if (isMountedRef.current) {
    //     onSent();
    //     onGetEmail(data.email);
    //   }
    // } catch (error) {
    //   enqueueSnackbar(error.message, { variant: "error" });
    // }
  };

  return (
    <RootStyle>
      <Box
        sx={{
          background: "#fff",
          width: 500,
          minHeight: 300,
          boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
          borderRadius: 2,
          paddingTop: 1,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} width={470} padding={2} mt={3} ml={2}>
            <RHFTextField name="email" label="Email address" />

            <RHFTextField
              select
              name="stakeholderCategoryId"
              required
              SelectProps={{ native: true }}
            >
              {STAKEHOLDER_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Stack>
          <Stack spacing={3} width={470} padding={2} ml={2}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Reset Password
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </RootStyle>
  );
}
