import React, { useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Card,
  CardHeader,
} from "@mui/material";

import { axoisCustomAgentForAdmin } from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import Label from "../../components/Label";
import styled from "styled-components";
import DataTable from "react-data-table-component";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AdminList() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  useEffect(() => {
    const getAllAdmins = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          "/admins/getAllAdmins"
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAdmins([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAllAdmins();
  }, []);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = admins.filter(
    (item) =>
      item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Admin Role",
      selector: (row) => row.adminRole,
    },
    {
      name: "Email Comfirmed",
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.isEmailConfirmed && "success") ||
            (!row.isEmailConfirmed && "error")
          }
        >
          {row.isEmailConfirmed ? sentenceCase("True") : sentenceCase("False")}
        </Label>
      ),
      // selector: (row) => row.status,
    },

    {
      name: "Status",
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.isAccountActive && "success") ||
            (!row.isAccountActive && "error")
          }
        >
          {row.isAccountActive
            ? sentenceCase("Active")
            : sentenceCase("Inactive")}
        </Label>
      ),
      // selector: (row) => row.yearOfAdmission,
    },

    { cell: (row) => <MoreMenuButton /> },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Admins...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 4,
        }}
      >
        <Typography variant="h4">Admin Management</Typography>

        <Box paddingRight={5}>
          <Button
            // variant="outlined"

            color="primary"
            href="/admin/users/createAdmins"
            className="header"
          >
            Create Admin
          </Button>

          <Button
            // variant="outlined"

            color="primary"
            href="/admin/users/adminList"
            className="header active  "
          >
            Manage Admin
          </Button>
        </Box>
      </Box>
      <Card>
        <CardHeader title="User Management" />
        <Grid p={2} style={Gridimage}>
          {admins.length === 0 ? (
            <Typography>No Admin present</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default AdminList;

function MoreMenuButton({ isActive, isDeactive }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        {isActive ? (
          <MenuItem sx={{ color: "error.main" }}>
            <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
            Deactivate
          </MenuItem>
        ) : null}
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Deactivate
        </MenuItem>
      </MenuPopover>
    </>
  );
}
