import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Divider, Box, Typography, Grid } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getMails } from "../../../redux/slices/mail";
//
import Scrollbar from "../../../components/Scrollbar";
import EmptyContent from "../../../components/EmptyContent";

import MailToolbar from "./MailToolbar";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import MailInterventionItem from "./MailInterventionItem";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  flexGrow: 1,
  display: "flex",
  overflow: "hidden",
  flexDirection: "column",
});

// ----------------------------------------------------------------------

MailList.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function MailList({ onOpenSidebar }) {
  const params = useParams();
  const { systemLabel } = params;

  const [loadingMessages, setLoadingMessages] = useState(false);
  const { userData } = useAuth();
  const { schoolId } = userData;

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const getCoversationId = async () => {
      setLoadingMessages(true);
      try {
        const response = await axoisCustomAgent.get(
          `/Schools/GetInterventionMessagesByConversationId?schoolId=${schoolId}&conversationId=${systemLabel}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setMessages([...data]);
      } catch (error) {
      } finally {
        setLoadingMessages(false);
      }
    };

    if (schoolId !== "" && systemLabel !== undefined) getCoversationId();
  }, [schoolId, systemLabel]);



 if (loadingMessages) return <Typography>Loading message...</Typography>;

 

  return (
    <RootStyle>
      {/* <MailToolbar
        mails={mails.allIds.length}
        selectedMails={selectedMails.length}
        onOpenSidebar={onOpenSidebar}
      /> */}

      <Divider />

      {messages.length > 0 ? (
        <Scrollbar>
          <Box sx={{ minWidth: { md: 800 } }}>
            {messages.map((message) => (
              <MailInterventionItem
                key={message.id}
                message={message}
              />
            ))}
          </Box>
        </Scrollbar>
      ) : (
        <Grid display={"flex"} justifyContent={"center"}>
          <Typography>No messages</Typography>
        </Grid>
      )}
    </RootStyle>
  );
}
