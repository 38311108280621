import React, { useEffect, useState, Fragment } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Modal,
  Card,
} from "@mui/material";

import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import AuditClerksModel from "./AuditClerksModel";

const Gridimage = {
  background: "#fafafa",
};

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AandEAuditClerks() {
  const [loading, setLoading] = useState(false);
  const [assignedClerk, setAssignedClerk] = useState([]);
  const { userData } = useAuth();
  const { userId  } = userData;
  const { enqueueSnackbar } = useSnackbar();
  const [currentSchoolId, setCurrentSchoolId] = useState("");

  console.log(userData);


  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = assignedClerk.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    let unmounted = false;
    const getAllAssignAuditClerks = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AandE/GetAssignedAuditClerks?assessorId=${userId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAssignedClerk([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (userId !== null) getAllAssignAuditClerks();
    return () => {
      unmounted = true;
    };
  }, [userId]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Middle Name",
      selector: (row) => row.middleName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      cell: (row) => (
        <Button variant="contained" onClick={() => handlePageOpen(row.userId)}>
          {" "}
          Assgin
        </Button>
      ),
    },
  ];

  const [page, setPage] = React.useState(false);
  const handlePageOpen = (schoolId) => {
    setCurrentSchoolId(schoolId);
    setPage(true);
  };
  const handlePageClose = () => {
    setCurrentSchoolId("");
    setPage(false);
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Typography variant="h5">Record of Assigned Clerk</Typography>
      <Card>
        <Grid style={Gridimage} p={3}>
          {assignedClerk.length === 0 ? (
            <Typography>No Record Found</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              editModde="row"
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>

      <Modal
        open={page}
        onClose={handlePageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          ></Typography>

          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            <AuditClerksModel userId={currentSchoolId} />
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default AandEAuditClerks;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
