import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Grid,
  CardContent,
  Card,
  Link,
  Modal,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
// components

import BOSimage from "../../../images/crest.jpeg";
import FirstIcon from "../../../images/firstImage.jpg";
import SecondIcon from "../../../images/SecondImage.jpg";
import ThirdIcon from "../../../images/thirdImage.jpg";
import FourthIcon from "../../../images/fouthImage.jpg";
import Trophy from "../../../images/BosTrophy.png";
import { Document, Page } from "react-pdf";
import Bullets from "@material-ui/icons/RadioButtonUnchecked";
import React, { Fragment, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const bull = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "2px",
      transform: "scale(0.8)",
    }}
  >
    •
  </Box>
);

// ----------------------------------------------------------------------

export default function Safety() {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [isSchool, setIsSchool] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentEventId, setCurrentEventId] = useState("");
  const [event, setEvent] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigateToSchool = () => {
    // navigate to public url
    navigate(`/events-school/5`);
  };

  const navigateToIndividual = () => {
    // navigate to Private url
    navigate(`/events-individual/5`);
  };

  const handleOnAttend = (e, eventId) => {
    setCurrentEventId(eventId);
    handleOpen();
  };

  const chooseNavigation = () => {
    if (isSchool === null) {
    } else if (isSchool) {
      navigateToIndividual();
    } else if (!isSchool) {
      navigateToSchool();
    }
  };

  useEffect(() => {
    let unmounted = false;

    const getEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(`/Events/GetEvents`);

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setEvent(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getEvent();

    return () => {
      unmounted = true;
    };
  }, []);

  const pdfFile = [
    {
      image:
        "https://res.cloudinary.com/dhojrtqwz/image/upload/v1693915043/bos_pdf_tpsixb.pdff",
    },
  ];
  return (
    <Fragment>
      <Box p={3}>
        <Box
          sx={{
            display: "grid",
            // marginBottom: 10,

            p: 2,
            fontSize: "16px",
            gap: { xs: 2, lg: 5 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          <Box color={"#8fbc8f"}>
            <Typography mt={6} textAlign={"center"}>
              <span>Venue :</span> Civic Centre Ozumba Mbadiwe <br /> Road,
              Victoria Island Lagos
            </Typography>
          </Box>
          <Box>
            <img
              src={BOSimage}
              alt="sslag event"
              style={{
                height: 360,
                width: 410,
              }}
            />
          </Box>

          <Box mt={6} textAlign={"center"} color={"#8fbc8f"}>
            <Typography textAlign={"center"}>Date : 26 Sept 2023</Typography>
            <Typography textAlign={"center"}>
              Conference and Exhibition
            </Typography>
            <Typography textAlign={"center"}>Time : 9am - 4pm</Typography>
            <Typography textAlign={"center"}>
              {" "}
              Dinner & Awards Ceremony{" "}
            </Typography>
            <Typography textAlign={"center"}>6pm Prompt</Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="h4" textAlign={"center"} mt={2}>
            Beacons of Safety Award 2022-2023
          </Typography>

          <Box
            sx={{
              display: "grid",
              marginBottom: 10,
              p: 2,
              fontSize: "16px",
              gap: { xs: 2, lg: 5 },
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                md: "repeat(4, 1fr)",
              },
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                p: 1,
                backgroundColor: "#edf6fa",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Box display={"flex"} justifyContent={"center"}>
                  <img
                    src={FourthIcon}
                    alt="sslag event"
                    style={{
                      height: 50,
                      width: 50,
                    }}
                  />
                </Box>

                <Typography variant="body2" mt={2}>
                  Information for collaboration <br /> and sponsorship
                  <br />
                </Typography>
                <Box mt={4} textAlign={"center"}>
                  <Link
                    color="#4169e1"
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    href=" https://pie2you.com/wp-content/uploads/2023/08/Conditions-of-Collaboration-and-Participation.pdf"
                  >
                    click here for more info
                  </Link>
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                minWidth: 275,
                pl: 2,
                backgroundColor: "#edf6fa",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <img
                      src={ThirdIcon}
                      alt="sslag event"
                      style={{
                        height: 50,
                        width: 50,
                      }}
                    />
                  </Box>
                </Box>

                <Typography variant="body2" textAlign={"center"} mt={2}>
                  Information for schools
                </Typography>
                <Box mt={4} textAlign={"center"}>
                  <Link
                    color="#4169e1"
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    href="https://pie2you.com/wp-content/uploads/2023/08/BOSAAC-Participation-info-for-schools.pdf"
                  >
                    click here for more info
                  </Link>
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                minWidth: 275,
                pl: 2,
                backgroundColor: "#edf6fa",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Box display={"flex"} justifyContent={"center"}>
                  <img
                    src={SecondIcon}
                    alt="sslag event"
                    style={{
                      height: 50,
                      width: 50,
                    }}
                  />
                </Box>

                <Typography variant="body2" textAlign={"center"} mt={2}>
                  Nomination Information
                  <br />
                </Typography>
                <Box mt={4} textAlign={"center"}>
                  <Link
                    color="#4169e1"
                    underline="always"
                    target="_blank"
                    rel="noopener"
                    href="https://pie2you.com/nominate-stakeholder/"
                    // sx={{
                    //   typography: "body2",
                    //   display: "flex",
                    //   alignItems: "center",
                    //   // ml: "3px",
                    // }}
                  >
                    click here for more info
                  </Link>
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                minWidth: 275,
                pl: 2,
                backgroundColor: "#edf6fa",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Box display={"flex"} justifyContent={"center"}>
                  <img
                    src={FirstIcon}
                    alt="sslag event"
                    style={{
                      height: 50,
                      width: 50,
                    }}
                  />
                </Box>
                <Typography variant="body2" textAlign={"center"} mt={2}>
                  Event Registration
                  <br />
                </Typography>
                <Box mt={4} textAlign={"center"}>
                  {event.length === 0 ? (
                    <Typography>No event</Typography>
                  ) : (
                    <Button
                      onClick={(e) => handleOnAttend(e, event.id)}
                      color="secondary"
                    >
                      {" "}
                      Register
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h4" p={2}>
            BOS (BEACON OF SATEFY) STATUS
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ lineHeight: 2, p: 2 }}>
            This is the GOLD standaard for SSLAG and it is primarily to showcase
            schools that are constantly demonstrating exceptional standards for
            school safety protocols. It is an award that such schools would be
            decorated with and earn the right to be listed in the SSLAG League
            Table of the first 100 outstanding schools in terms of
            safety and well-being.
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ lineHeight: 2, pl: 2, mt: 4 }}>
          Amongst other things.
        </Typography>
        <Typography variant="body2" sx={{ lineHeight: 1, pl: 2, mt: 1 }}>
          The SSLAG compliance framework states that to earn this status such
          schools must belong to an advocacy group for safety, why we recommend
          the safe school Alliance Membership.
        </Typography>

        <Box mt={2} pl={2}>
          <Typography variant="body2" sx={{ lineHeight: 2 }}>
            Therefore, Schools desirous to achieve the BOS status must be
          </Typography>

          <Typography variant="body2" mt={1}>
            1. Passionate about safety in schools.
          </Typography>
          <Typography variant="body2" mt={1}>
            2. Support their pupils and school teams to remain safe and healthy.
          </Typography>
          <Typography variant="body2" mt={1}>
            3. Follow SSLAG BOS route for the compliance journey and show
            genuine commitments to stakeholder engagement.
          </Typography>
        </Box>

        <Typography variant="body2" sx={{ lineHeight: 2, p: 2 }}>
          There are specific requirements to follow which in turn give huge
          benefits to the school.
        </Typography>
        <Typography variant="h4" sx={{ lineHeight: 2, p: 2 }}>
          BENEFITS OF BOS STATUS
        </Typography>
        <Card
          sx={{
            minWidth: 50,
            p: 2,
            backgroundColor: "#edf6fa",
            borderRadius: 0,
          }}
        >
          <CardContent>
            <Box display={"flex"} mt={1}>
              <Bullets
                style={{
                  fontSize: "18px",
                }}
              />
              <Typography variant="body2">
                You will have the privilege of using the sobriquet Beacon of
                Safety Status School on your official letterheads. PR
                material and Signboard.
              </Typography>
            </Box>
            <Box display={"flex"} mt={1}>
              <Bullets
                style={{
                  fontSize: "18px",
                }}
              />
              <Typography variant="body2">
                Beacon of safety Award plaque at the annual SSLAG Awards
                ceremony This is recommended to be mounted at
                the school entrance
              </Typography>
            </Box>

            <Box display={"flex"} mt={1}>
              <Bullets
                style={{
                  fontSize: "18px",
                }}
              />
              <Typography variant="body2">
                Free listing on the Safe School Lagos League Table
              </Typography>
            </Box>
            <Box display={"flex"} mt={1}>
              <Bullets
                style={{
                  fontSize: "18px",
                }}
              />
              <Typography variant="body2">
                Access to students from neigbouring schools that are closed due
                to safety infractions
              </Typography>
            </Box>

            <Box display={"flex"} mt={1}>
              <Bullets
                style={{
                  fontSize: "18px",
                }}
              />
              <Typography variant="body2">
                No annual school safety audits Beacon schools are audited
                once in 4 years!
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <Box mt={2}>
          <Typography variant="h4" sx={{ lineHeight: 2, pl: 2 }}>
            BOS AWARDS
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ lineHeight: 2, pl: 2 }}>
          Will your school make the maiden SSLAG Awards! Which school will be in
          the safe schools League Table? Are you working towards
          SSLAG GOLD STANDARD?
        </Typography>
        <Box mt={2}>
          <Typography
            variant="body1"
            sx={{ lineHeight: 2, pl: 2, color: "red" }}
          >
            How does your school measure up to the SSLAG Obligations for
            Beacon of safety?
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{ lineHeight: 2, pl: 2 }}
          >
            See below the SSLAG Obligations for BOS Status conferment:
          </Typography>
        </Box>

        <Card
          sx={{
            minWidth: 50,
            p: 2,
            backgroundColor: "#edf6fa",
            borderRadius: 0,
          }}
        >
          <CardContent>
            <Box display={"flex"} justifyContent={"space-between"}>
             
              <Box>
                  <Box display={"flex"}>
                    <Bullets
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <Typography variant="body2">
                      Management commitment evidenced by the number of staff
                      development activities and advocacy.
                    </Typography>
                  </Box>
                  <Box display={"flex"} mt={1}>
                    <Bullets
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <Typography variant="body2">
                      Appropriate levels of Safety infrastructure and displays.
                    </Typography>
                  </Box>
                  <Box display={"flex"} mt={1}>
                    <Bullets
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <Typography variant="body2">
                      Evidence of NIA (Nigeria Insurance Association
                      )compliance-driven insurance policy.
                    </Typography>
                  </Box>
                  <Box display={"flex"} mt={1}>
                    <Bullets
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <Typography variant="body2">
                      Evidence of Safe recruitment clearances.
                    </Typography>
                  </Box>
                  <Box display={"flex"} mt={1}>
                    <Bullets
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    <Typography variant="body2">
                      Implementation of a safety management system.
                    </Typography>
                  </Box>
              </Box>
              <Box>
                <img
                  src={Trophy}
                  alt="Bos Trophy"
                  style={{
                    height: 200,
                    width: 250,
                  }}
                />
              
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box mt={3} display={"flex"}>
          <Typography
            variant="body2"
            sx={{ lineHeight: 2, p: 2, color: "red" }}
          >
            If you want to know more about BOS kindly
          </Typography>
          {/* <Button href="/bos-pdf"> click here</Button> */}

          {/* <div>
            <Document file={pdfFile}>
              <Page pageNumber={1} />
            </Document>
          </div> */}
          <Link
            color="#4169e1"
            underline="always"
            target="_blank"
            rel="noopener"
            href="https://res.cloudinary.com/dhojrtqwz/image/upload/v1693915013/bos_pdf_bzleat.pdf "
            sx={{
              typography: "body2",
              display: "flex",
              alignItems: "center",
              ml: "3px",
            }}
          >
            click here
          </Link>
        </Box>
        <Box mt={3}>
          <Typography variant="body2" sx={{ lineHeight: 2, p: 2 }}>
            Please Contact:{" "}
            <span
              style={{
                marginLeft: 2,
              }}
            >
              <a href="mailto:members@pie2you.com m">members@pie2you.com </a>
            </span>
            to find out how your school can achieve the beacon of safety status
          </Typography>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you registering as an individual or school
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="personnel"
                    control={<Radio />}
                    label="School"
                    onChange={() => setIsSchool(false)}
                  />
                  <FormControlLabel
                    value="govt"
                    control={<Radio />}
                    label="Individual"
                    onChange={() => setIsSchool(true)}
                  />
                </RadioGroup>
              </FormControl>

              <Grid item sx={{ textAlign: "right" }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  onClick={chooseNavigation}
                  variant="contained"
                  sx={{
                    width: 160,
                  }}
                >
                  Continue
                </LoadingButton>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>




{/* 
      registrationInvoice: path(ROOTS_AUTH, "/registration-invoice"),
  termsNdConditions: path(ROOTS_AUTH, "/termsandcondition"),
  DecTermNdCondition: path(ROOTS_AUTH, "/DecTermNdCondition"),
  profileComplete: path(ROOTS_AUTH, "/profile-complete"),
  requestDemo: path(ROOTS_AUTH, "/request-demo-page"),
  adminLogin: path(ROOTS_AUTH, "/admin-login"),
  AandELogin: path(ROOTS_AUTH, "/A&E/AandELogin"),
  AandERegister: path(ROOTS_AUTH, "/A&E/AandERegister"), */}
  {/* onSuccess: path(ROOTS_AUTH, "onSuccess"), */}
    </Fragment>
  );
}
