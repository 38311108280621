import React, { Fragment, useState } from "react";
import AdminBG from "../../images/sslagLandingImg.jpg";
import {
  Grid,
  Card,
  CardActions,
  Alert,
  Stack,
  CardContent,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../components/hook-form";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";

function RequestDemo() {
  const { enqueueSnackbar } = useSnackbar();
  const [isDemoRequested, setIsDemoRequested] = useState(false);
  const methods = useForm({
    // resolver: yupResolver(LoginSchema),
    // defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  const RequestDemo = async () => {
    try {
      const response = await axoisCustomAgent.post(
        `Schools/SendOnboardingTrainingRequest`
      );
      const { statusCode, message } = response.data;
      if (statusCode === 409) {
        enqueueSnackbar(message, { variant: "success" });
      } else if (statusCode === 200) {
        enqueueSnackbar(message, { variant: "success" });
        setIsDemoRequested(true);
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    } catch (error) {}
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //   minHeight: "100%",
          height: "100vh",
          width: "100%",
          background: `url(${AdminBG})`,

          backgroundCover: "contain",
        }}
      >
        {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} m={3}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )} */}
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: "23px" }}
              color="#6495ed"
              textAlign={"center"}
              gutterBottom
            >
              {isDemoRequested ? "Demo Request Sent" : "Request Training Demo"}
            </Typography>

            <Typography
              sx={{ mb: 1.5, fontSize: "14px" }}
              color="text.secondary"
            >
              {isDemoRequested
                ? "An email as been sent to you. Our team will contact you within two  working days with further information to help you get started"
                : " Kindly request a demo and an officer of safe school will contact you within two(2) working days"}
            </Typography>

            <Box textAlign={"center"}>
              <Button
                variant="contained"
                loading={isDemoRequested}
                onClick={async () => await RequestDemo()}
                sx={{
                  background: "#0293da",
                }}
                disabled={isDemoRequested}
              >
                Request Demo
              </Button>
            </Box>
          </CardContent>

          {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
        </Card>
        {/* </Stack>
        </FormProvider> */}
      </Grid>
    </Fragment>
  );
}

export default RequestDemo;
