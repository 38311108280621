import { useContext } from "react";
//
import { AdminContext } from "../contexts/AdminJWT";
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

const useAuthAdmin = () => {
  const context = useContext(AdminContext);

  if (!context) throw new Error("Auth context must be use inside AuthProvider");

  return context;
};

export default useAuthAdmin;
