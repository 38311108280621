import { Grid, Typography, Button, Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useEffect, useState } from "react";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AdminAttendance({ schoolId }) {
  const [attendence, setAllStudent] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = attendence.filter(
    (item) =>
      item.studentId &&
      item.studentId.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "studentId",
      selector: (row) => row.registrationNumber,
    },

    {
      name: "Year Group",
      selector: (row) => row.yeargroup,
    },
    {
      name: "class",
      selector: (row) => row.className,
    },
    {
      name: "Day(s) Present",
      selector: (row) => row.morningAttendance.daysPresent,
    },
    {
      name: "Day(s) Absent",
      selector: (row) => row.morningAttendance.daysAbsent,
    },

    // { cell: (row) => <MoreMenuButton /> },
  ];

  useEffect(() => {
    let unmounted = false;

    const getAttendance = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Schools/GetAttendanceForStudentsInSchool/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllStudent([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getAttendance();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  return (
    <Grid>
      <Box>
        {attendence.length === 0 ? (
          <Typography>No Student Present</Typography>
        ) : (
          <DataTable
            columns={columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            responsive
          />
        )}
      </Box>
    </Grid>
  );
}

export default AdminAttendance;
