import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Alert,
  FormHelperText,
  Button,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

function AddClass() {
  const PaperStyle = {
    padding: 40,
    width: 400,
  };

  const { userData } = useAuth();
  const { schoolId } = userData;

  const { enqueueSnackbar } = useSnackbar();

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };

  const ARM = [
    {
      value: "",
      label: "Is class  applicable",
    },
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const defaultValues = {
    className: "",
  };

  const AddClassSchema = Yup.object().shape({
    className: Yup.string().required("class Name is required"),
    arm: Yup.number().required("class arm is required"),
  });

  const methods = useForm({
    resolver: yupResolver(AddClassSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/yeargroups/Createyeargroup",
        {
          schoolId,
          yearGroupName: data.className,
          isClassAvailable: data.arm,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  // console.log(schoolId);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> School Management</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/addClass"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Add Year Group
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/classArm"
            className="header"
          >
            Add Class
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/assign"
            className="header"
          >
            Assign Class Tutor
          </Button>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/class"
            className="header"
          >
            All Year Group
          </Button>
        </Box>
      </Box>
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={7}>
          <Paper style={PaperStyle}>
            <Grid align="center">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                {/* <Typography>Add Year Group</Typography>
                <hr /> */}
                <Grid>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Add Year Group
                    </Typography>
                  </Box>
                </Grid>

                <Grid container mt={2}>
                  <Grid width={300} ml={1}>
                    <RHFTextField
                      id="filled-basic"
                      name="className"
                      label="Year Group"
                      sx={{
                        marginBlock: 2,
                      }}
                      variant="filled"
                    />
                  </Grid>

                  <Grid item width={300} mt={2} ml={1}>
                    <FormHelperText>Is class applicable</FormHelperText>
                    <RHFTextField
                      select
                      name="arm"
                      variant="filled"
                      //required
                      SelectProps={{ native: true }}
                    >
                      {ARM.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddClass;
