import {
  Container,
  Card,
  Button,
  CardActions,
  CardContent,
  Box,
  Typography,
  Divider,
  Stack,
  Grid,
} from "@mui/material";
import React from "react";
import Crest from "../../../images/5.png";
import Rating3 from "../../../images/success.png";
// import Rating2 from "../../images/Rating2.jpg";
// import Rating1 from "../../images/rating1.jpg";
import { MotionInView, varFade } from "../../../components/animate";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(12, 0),
}));

function PublicAuditInvoice() {
  return (
    <RootStyle>
      <Container sx={{ position: "relative", textAlign: "center" }}>
        <MotionInView variants={varFade().inUp}></MotionInView>
        <MotionInView variants={varFade().inDown}>
          <MotionInView variants={varFade().inUp}>
            <Box>
              <Grid display={"grid"} justifyContent={"center"} mt={5}>
                <Card>
                  <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                      <img src={Rating3} alt="" style={{ width: 500 }} />
                    </Box>
                    <Typography sx={{ fontSize: 17 }} color={"#018f3e"} mt={2}>
                      A school account has been created for you, and you will
                      receive an email <br /> to continue with your onboarding.
                      Your audit have been booked and require pending approval
                      from admin
                    </Typography>

                    <Button variant="contained" href="/">
                      Back Home
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          </MotionInView>
        </MotionInView>
      </Container>
      {/* <Divider sx={{ borderStyle: "10px solid" }} /> */}
    </RootStyle>
  );
}

export default PublicAuditInvoice;

// import React, { Fragment, useState, useEffect } from "react";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useSnackbar } from "notistack";
// import axoisCustomAgent from "src/utils/axiosCustomAgent";
// import { useParams } from "react-router";
// import logoBg from "../../../images/sslagBookingForm.JPG";
// import formatnaira from "format-to-naira";
// import { PaystackButton } from "react-paystack";
// import { useNavigate } from "react-router-dom";
// import AdminBG from "../../../images/background.png";

// function PublicAuditInvoice() {
//   useEffect(() => {
//     let unmounted = false;
//     const getAllInvoice = async () => {
//       setLoading(true);

//       try {
//         const response = await axoisCustomAgent.get(
//           `schoolAudit/GetPendingAuditInvoice?schoolId=${schoolId}`
//         );
//         const { status } = response.data;

//         const { statusCode, message } = response.data;

//         if (statusCode !== 200) {
//           throw new Error(message);
//         }

//         const { data } = response.data;
//         if (!unmounted) setInvoice(data);
//       } catch (error) {
//         enqueueSnackbar(error.message, { variant: "error" });
//       } finally {
//         setLoading(false);
//       }
//     };

//     getAllInvoice();
//     return () => {
//       unmounted = true;
//     };
//   }, []);

//   const { enqueueSnackbar } = useSnackbar();
//   const [loading, setLoading] = useState(false);
//   const [invoice, setInvoice] = useState(null);

//   const navigate = useNavigate();
//   // const { enqueueSnackbar } = useSnackbar();

//   let { schoolId } = useParams();

//   const LoginNavigate = () => {
//     // navigate to Private urls
//     navigate(`/auth/login`);
//   };
//   if (loading) {
//     return (
//       <Box
//         minHeight={"100vh"}
//         display={"flex"}
//         justifyContent={"center"}
//         alignItems={"center"}
//       >
//         <Box>
//           <Typography>Loading ...</Typography>
//         </Box>
//       </Box>
//     );
//   }
//   return (
//     <Fragment>
//       <Grid
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           paddingInline: "20px",
//           paddingTop: 5,
//           background: `url(${AdminBG})`,
//           minHeight: "120vh",
//           backgroundCover: "contain",
//         }}
//       >
//         <Container
//           sx={{
//             background: "#ffff",
//             borderRadius: 1,
//             boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
//             mt: 2,
//             minHeight: 200,
//             width: "50%",
//           }}
//         >
//           <Box display={"flex"} justifyContent={"center"} p={2}>
//             <img
//               src={logoBg}
//               alt=""
//               style={{
//                 width: 300,
//               }}
//             />
//           </Box>

//           <Typography
//             textAlign={"center"}
//             variant="h5"
//             sx={{
//               color: "#00B000",
//             }}
//           >
//             INVOICE
//           </Typography>

//           <Box display={"flex"} justifyContent={"space-between"} m={2}>
//             <Box>
//               <Typography fontSize={"12px"}>Invoice From:</Typography>
//               <Typography color={"secondary"}>
//                 {invoice?.invoiceFrom.name}
//               </Typography>
//               <Typography fontSize={"12px"}>Issued Date</Typography>
//               <Typography color={"secondary"}>
//                 {invoice?.createdDate.split("T")[0]}
//               </Typography>
//               <Typography fontSize={"12px"}>Due Date</Typography>
//               <Typography color={"error"}>
//                 {invoice?.dueDate.split("T")[0]}
//               </Typography>
//             </Box>
//             <Box>
//               <Typography fontSize={"12px"}>Invoice To</Typography>
//               <Typography color={"secondary"}>
//                 {invoice?.invoiceTo.name}
//               </Typography>
//               <Typography fontSize={"12px"}>Reference Number</Typography>
//               <Typography color={"secondary"}>
//                 {invoice?.invoiceReferenceId}
//               </Typography>
//             </Box>
//           </Box>

//           <Box display={"flex"} justifyContent={"space-between"} m={2}>
//             <Typography variant="h4">Description</Typography>
//             <Typography variant="h4">Cost</Typography>
//           </Box>
//           {invoice?.invoiceItems.map((eachItem, index) => (
//             <Box
//               key={index}
//               display={"flex"}
//               justifyContent={"space-between"}
//               m={2}
//             >
//               <Typography>{eachItem.description}</Typography>
//               <Typography> {formatnaira(eachItem.totalPrice)} </Typography>
//             </Box>
//           ))}

//           <Box m={2}>
//             <Box display={"flex"} justifyContent={"space-between"}>
//               <Typography>Total Amount</Typography>
//               <Typography>{formatnaira(invoice?.totalAmount)}</Typography>
//             </Box>
//             <Box display={"flex"} justifyContent={"space-between"} mt={1}>
//               <Typography color={"error"}>Concession</Typography>
//               <Typography>{formatnaira(invoice?.invoiceConcession)}</Typography>
//             </Box>
//           </Box>

//           <Box display={"flex"} justifyContent={"space-between"} m={2}>
//             <Typography> Payable Amount</Typography>
//             <Typography>{formatnaira(invoice?.payableAmount)}</Typography>
//           </Box>

//           <Box m={2}>
//             <Box fontSize={"15px"}>
//               <Typography variant="h6">NOTE:</Typography>
//               Your concession token has covered for the audit payment. Kindly
//               click on " Continue" to proceed.
//             </Box>
//           </Box>

//           <Grid textAlign={"center"} p={2}>
//             <Button
//               onClick={LoginNavigate}
//               variant="contained"
//               sx={{ width: "100%" }}
//             >
//               Continue
//             </Button>
//           </Grid>
//         </Container>
//       </Grid>
//     </Fragment>
//   );
// }

// export default PublicAuditInvoice;
