import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper, Box, Stack, Alert } from "@mui/material/";
import srun from "../../images/bg1.png";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import useAuthAdmin from "../../hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

const Gridimage = {
  backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${srun})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  minHeight: "100vh",

  backgroundPosition: "center",
};
const PaperStyle = {
  padding: 30,
  width: 400,
};

function CreatePassword() {
  let { token } = useParams();

  const navigate = useNavigate();

  //   const { emailComfirmed, password } = userData;
  const { activateNewAdmin, loginAdmin } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required("required"),
    confirmPassword: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref("password"), null], "confirm password does not match"),
  });

  const defaultValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  // -------------------------------------------------------

  const onSubmit = async (data) => {
    try {
      await activateNewAdmin({ ...data, token });
      enqueueSnackbar("Password creation successful", { variant: "success" });
      navigate("/auth/admin-login");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  // -------------------------------------------------------

  return (
    <Grid container style={Gridimage} justifyContent="center">
      <Grid item alignSelf={"center"}>
        <Paper style={PaperStyle}>
          <Box>Kindly Create your Password</Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <RHFTextField
              name="password"
              label="Password"
              variant="filled"
              type="Password"
              fullWidth
            />
            <Stack sx={{ mt: 2 }}>
              <RHFTextField
                name="confirmPassword"
                label="Comfirm Password"
                variant="filled"
                type="Password"
                fullWidth
              />
            </Stack>

            <LoadingButton
              sx={{ mt: 2, ml: 29 }}
              type="submit"
              color="secondary"
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreatePassword;
