import React from 'react'

function AdminDirectorsImage(imageId, imageUrl) {
  return (
    <div>
      <img
        src={imageUrl}
        alt=""
        style={{
          width: 400,
        }}
      />
    </div>
  );
}

export default AdminDirectorsImage
