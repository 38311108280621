import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  FormControlLabel,
  Radio,
  FormControl,
  Stack,
  Alert,
} from "@mui/material";
import logoBg from "../../images/sslag.png";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import {
  RHFTextField,
  FormProvider,
  RHFRadioGroup,
} from "../../components/hook-form";

import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";

import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";

function AdminSafeRecruitment() {
  const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();

  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [safeRecruitment, setSafeRecruitment] = useState(null);

  const defaultValues = {
    doYouHaveBackgroundVettingProcedure: "",
    areReferencesSought: "",
    requiredBackgroundChecksForStaff: "",
    doYouHaveRecruitmentPolicy: "",
    recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl: "",
    reasonForNoRecruitmentPolicy: "",
    totalNumOfTeachingStaff: "",
    totalNumOfNonTeachingStaff: "",
    doYouEngageVolunteersInSchool: "",
    doYouHaveVendorsInPremises: "",
    howDoYouEngageVendors: "",
    vendorEngagementProcedureImageId: "",
    vendorEngagementProcedureImageUrl: "",
    doYouEngageBackgroundVettingCompany: "",
    backgroundVettingCompanyDetails: "",
  };
  const [state, setState] = useState({
    recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl: "",
  });

  const methods = useForm({
    // resolver: yupResolver(SummmarySheetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { recruitmentPolicyImageId, recruitmentPolicyImageUrl } =
            response.data.data;

          setState({
            ...state,
            recruitmentPolicyImageId: recruitmentPolicyImageId,
            recruitmentPolicyImageUrl: recruitmentPolicyImageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [vettingProcedure, setVettingProceduce] = React.useState("");
  const [policyAttachment, setPolicyAttachment] = React.useState("");
  const [schoolVolunter, setSchoolVolunter] = React.useState("");
  const [thirdPartyVendor, setThirdPartyVendor] = React.useState("");
  const [vettingCompany, setVettingCompany] = React.useState("");

  const handleChangeVetting = (event) => {
    setVettingProceduce(event.target.value);
  };
  const handleChangePolicy = (event) => {
    setPolicyAttachment(event.target.value);
  };
  const handleChangeVolunter = (event) => {
    setSchoolVolunter(event.target.value);
  };
  const handleChangepartyVendor = (event) => {
    setThirdPartyVendor(event.target.value);
  };
  const handleChangeCompany = (event) => {
    setVettingCompany(event.target.value);
  };

  const onSubmit = () => {};
  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    { label: "Don't Know", value: "don't know" },
  ];

  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
    // { label: "Don't", value: "don't know" },
  ];

  const watchProcedure = watch("doYouHaveBackgroundVettingProcedure");
  const watchAttachment = watch("doYouHaveRecruitmentPolicy");
  const watchVendors = watch("doYouHaveVendorsInPremises");
  const watchVetting = watch("doYouEngageBackgroundVettingCompany");

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/SchoolIntervention/GetSafeRecruitmentInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        methods.reset();
        setSafeRecruitment(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (safeRecruitment === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (safeRecruitment === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found....</Typography>;
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid>
          <Box textAlign={"center"}>
            <Box>
              <Grid>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 200,
                  }}
                />
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
              }}
            >
              Safe Recruitment Evaluation
            </Typography>
          </Box>
        </Grid>
        <Container>
          <Box mt={3}>
            <Typography variant="h6">School Name</Typography>
          </Box>
          <Grid>
            <Box
              sx={{
                background: "#097969",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                Safe Recruitment Evaluation Form?
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Typography mt={2}>
              The Lagos State Government is committed to ensuring safety culture
              and compliance in schools and as such Safe Schools Lagos framework
              sets out the guidelines for safe recruitment of all members of
              staff, vendors and volunteers in schools. Please fill in this
              questionnaire to determine how well your establishment is doing in
              this regard. Thank you.
            </Typography>
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Box p={2}>
              <Box sx={{ marginBlock: 2 }}>
                <Typography>
                  Do you have a background vetting procedure?
                </Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={option.map((x) => x.value)}
                    name="doYouHaveBackgroundVettingProcedure"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  {watchProcedure === "no" ? (
                    <Grid>
                      <Typography>state if references are sought?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areReferencesSought"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Grid>
                  ) : null}
                </FormControl>
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField
                  name="requiredBackgroundChecksForStaff"
                  label="What background checks do you require of new 
                  members of staff? List all that apply"
                  variant="filled"
                  multiline
                  minRows={3}
                />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <Typography>Do you have a recruitment policy??</Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={options.map((x) => x.value)}
                    name="doYouHaveRecruitmentPolicy"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  {watchAttachment === "no" ? (
                    <Grid>
                      <Typography>
                        If not attached , briefly state why??
                      </Typography>
                      <Box sx={{ marginBlock: 2 }}>
                        <RHFTextField
                          name="reasonForNoRecruitmentPolicy"
                          label="If not attached , briefly state why?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </Box>
                    </Grid>
                  ) : null}
                  {/* recruitmentPolicyImageId: "",
    recruitmentPolicyImageUrl */}
                  {/* {watchAttachment === "yes" ? (
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "400px",
                        marginTop: "10px",
                        padding: "6px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "12px",
                      }}
                    >
                      <div>
                        <input
                          type="file"
                          onChange={(event) => {
                            setImageSelected(event.target.files[0]);
                          }}
                        />
                        <button
                          onClick={async () => await uploadImage()}
                          disabled={
                            imageUploading ||
                            state.recruitmentPolicyImageUrl.length > 0
                          }
                        >
                          Upload Evidence
                        </button>
                      </div>
                    </div>
                  ) : null} */}
                </FormControl>
              </Box>
              <Box width={400}>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="totalNumOfTeachingStaff"
                    label="Please state the total number of teaching staff"
                    variant="filled"
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <RHFTextField
                    name="totalNumOfNonTeachingStaff"
                    label="Please state the total number of non-teaching staff"
                    variant="filled"
                  />
                </Box>
              </Box>

              <Box sx={{ marginBlock: 2 }}>
                <Typography>Do you engage volunteers in the school?</Typography>

                <FormControl>
                  <RHFRadioGroup
                    options={options.map((x) => x.value)}
                    name="doYouEngageVolunteersInSchool"
                    getOptionLabel={option.map((x) => x.label)}
                  />

                  <Grid>
                    <Typography>
                      Do you have vendors or 3rd parties / vendors employed
                      within the school premises
                    </Typography>
                    <Box sx={{ marginBlock: 2 }}>
                      <RHFRadioGroup
                        options={options.map((x) => x.value)}
                        name="doYouHaveVendorsInPremises"
                        getOptionLabel={option.map((x) => x.label)}
                      />

                      {watchVendors === "yes" ? (
                        <Grid>
                          <Box sx={{ marginBlock: 2 }}>
                            <RHFTextField
                              name="howDoYouEngageVendors"
                              label="How do you engage them?"
                              variant="filled"
                              multiline
                              minRows={3}
                            />
                          </Box>
                        </Grid>
                      ) : null}
                    </Box>
                  </Grid>

                  <Grid>
                    <Typography>
                      Do you engage a Background vetting company?
                    </Typography>
                    <Box sx={{ marginBlock: 2 }}>
                      <RHFRadioGroup
                        options={options.map((x) => x.value)}
                        name="doYouEngageBackgroundVettingCompany"
                        getOptionLabel={option.map((x) => x.label)}
                      />

                      {watchVetting === "yes" ? (
                        <Grid>
                          <Box sx={{ marginBlock: 2 }}>
                            <RHFTextField
                              name="backgroundVettingCompanyDetail"
                              label="if yes give contact details of the 
                          organisation?"
                              variant="filled"
                              multiline
                              minRows={3}
                            />
                          </Box>
                        </Grid>
                      ) : null}
                    </Box>
                  </Grid>
                </FormControl>
              </Box>
            </Box>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminSafeRecruitment;
