import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import AdminInterve from "./AdminInterve";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import AdminDirectorsImage from "./AdminDirectorsImage";
// import { Box } from "@mui/system";

function AdminSchoolMandatoryInsurance() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
  const [activeImageMessageOpenInModal, setActiveImageMessageOpenInModal] =
    React.useState(null);

  const openImageModal = (imageMessage) => {
    setActiveImageMessageOpenInModal(imageMessage);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setActiveImageMessageOpenInModal(null);
    setIsImageModalOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();
  const [schoolDirectors, setAllSchoolDirectors] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

  
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "FullName",
      selector: (row) => row.name,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },

    {
      cell: (row) => (
        <>
          <Button  onClick={() => openImageModal(row.description)}>
            view 
          </Button>
        </>
      ),
    },
  ];

    useEffect(() => {
      const getAllSchoolDirectors = async () => {
        setLoading(true);
        try {
          const response = await axoisCustomAgentForAdmin.get(
            `SchoolOnboarding/GetSchoolDirectors/${schoolId}`
          );

          const { status } = response.data;

          const { statusCode, message } = response.data;

          if (statusCode !== 200) {
            throw new Error(message);
          }

          // const { data } = response.data;
          setAllSchoolDirectors(response.data.data);
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } finally {
          setLoading(false);
        }
      };

      getAllSchoolDirectors();
    }, []);

    if (schoolDirectors === null && loading) {
      return <Typography>Loading School Director....</Typography>;
    }

    if (schoolDirectors === null) {
      return <Typography>No Record Found....</Typography>;
    }


  return (
    <Box mt={7} padding={2}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> School Directors</Typography>
            </Grid>
            <Box>
              <Grid>
                <FormHelperText>
                  Evidence of SSLAG Fit Person Checks
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="SafeSchInsPolicy"
                  variant="outlined"
                  value={
                    schoolDirectors.doesSchoolDirectorsHaveQAFitPersonClearance
                  }
                  disabled
                />
              </Grid>

              <Box>
                <DataTable
                  columns={columns}
                  data={schoolDirectors.schoolDirectors}
                  subHeader
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                />
              </Box>

              <Grid item sx={{ textAlign: "right", mt: 5 }}>
                <Button
                  type="submit"
                  color="secondary"
                  endIcon={<Send />}
                  variant="contained"
                  onClick={() => openAlertModal()}
                >
                  Intervene
                </Button>
              </Grid>
              <Grid>..</Grid>
            </Box>
          </Container>
        </Grid>
        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
        {/* view image */}
        <Modal
          open={isImageModalOpen}
          onClose={closeImageModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              School Directors Image
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminDirectorsImage
                imageMessage={activeImageMessageOpenInModal}
                closeImageModal={closeImageModal}
                imageId={schoolDirectors.imageId}
                imageUrl={schoolDirectors.imageUrl}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolMandatoryInsurance;









   





