import React from "react";
import { Box, Grid, Paper, Alert, Stack, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import LoadingButton from "@mui/lab/LoadingButton";

function StudentForm() {
  const PaperStyle = {
    padding: 40,
    // width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };
  const StudentFormSchema = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.number().required("class arm is required"),
  });

  const defaultValues = {
    class: "",
    classArm: "",
  };

  const methods = useForm({
    resolver: yupResolver(StudentFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Classes/${data.class}/CreateClassArm`,
        {
          schoolId,
          classArmName: data.classArm,
        }
      );

      console.log(response);

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [classes, setClasses] = useState([
    {
      id: "",
      className: "Select Class",
    },
  ]);
  const [classarm, setClassArm] = useState([
    {
      id: "",
      classArm: "Select Class Arm",
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/Classes/GetAllClassesBySchoolId/${schoolId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId != null) getClasses();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  //allocate class arm

  useEffect(() => {
    let unmounted = false;
    const getClassArm = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/Classes/GetAllClassesBySchoolId/${schoolId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClassArm([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId != null) getClassArm();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  return (
    <Box>
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item alignSelf={"center"}>
          <Paper style={PaperStyle}>
            <Grid align="cener">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Typography>Allocate to class</Typography>
                <hr />

                <Grid container spacing={5} mt={2}>
                  <Grid item width={300}>
                    <RHFTextField
                      select
                      name="class"
                      required
                      SelectProps={{ native: true }}
                    >
                      {classes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.className}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={300}>
                    <RHFTextField
                      select
                      name="class"
                      required
                      SelectProps={{ native: true }}
                    >
                      {classarm.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.className}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ mt: 3 }}
                >
                  Allocate
                </LoadingButton>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StudentForm;
