import React, { useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Link,
  Grid,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableContainer,
  Checkbox,
  Avatar,
} from "@mui/material";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";
import StudentForm from "./StudentForm";

function Unallocate() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);

  const Divider = {
    background: "#fafafa",
    display: "flex",
    marginTop: "5px",
  };

  const StudentTable = {
    width: "74%",
  };

  const StudentForms = {
    width: "25%",
    background: "#008000",
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Unallocated Student...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">School Management</Typography>

        <Box paddingRight={5}>
          <Link
            to="/dashboard/user/unallocate"
            style={{ marginRight: "20px", textDecoration: "none" }}
          >
            Unallocated Student |
          </Link>

          <Link
            to="/dashboard/user/allocate"
            style={{ marginRight: "20px", textDecoration: "none" }}
          >
            Allocated Student |
          </Link>

          <Link
            to="/dashboard/user/allParent"
            style={{ textDecoration: "none" }}
          >
            xxxxxxxxx |
          </Link>
        </Box>
      </Box>
      <hr />
      <Grid style={Divider}>
        <Grid style={StudentTable}>
          {Unallocate.length === 0 ? (
            <Typography>No Unallocated Student</Typography>
          ) : (
            <Card sx={{ mt: 5, minWidth: 1000 }}>
              <CardHeader title="All Staff" sx={{ mb: 3 }} />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 720 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <Avatar src="/broken-image.jpg" />{" "}
                        </TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Admission Year </TableCell>
                        <TableCell>
                          <Checkbox />
                        </TableCell>

                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {staff.map((eachSchool, index) => (
                        <TableRow key={eachSchool.studentId}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{eachSchool.firstName}</TableCell>
                          <TableCell>{eachSchool.lastName}</TableCell>
                          <TableCell>{eachSchool.gender}</TableCell>
                          <TableCell>{eachSchool.yearEmployed}</TableCell>

                          <TableCell align="right">
                            <MoreMenuButton />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Divider />

              <Box sx={{ p: 2, textAlign: "right" }}>
                <Button
                  size="small"
                  color="inherit"
                  endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                >
                  View All
                </Button>
              </Box>
            </Card>
          )}
        </Grid>
        <Grid style={StudentForms}>
          {" "}
          <StudentForm />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Unallocate;

function MoreMenuButton({ isActive }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem sx={{ color: "succes" }}>
          {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
          Edit
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
