import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
  Button,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function ComplianceCriterion2({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const defaultValues = {
    safeRecruitment: "",
    commentOnSafeRecruitment: "",
    riskAssessmentPolicy: "",
    commentOnRiskAssessmentPolicy: "",
    safetyLogs: "",
    commentOnSafetyLogs: "",
    inductionForStaff: "",
    commentOnInductionForStaff: "",
    emergencyEvacuationPlan: "",
    commentOnEmergencyEvacuationPlan: "",
    accessControlAndSurveillance: "",
    commentOnAccessControlAndSurveillance: "",
    safetyProvisionBudget: "",
    commentOnSafetyProvisionBudget: "",
    outOfClassDutyRota: "",
    commentOnOutOfClassDutyRota: "",
    schoolSafetyDisplay: "",
    commentOnSchoolSafetyDisplay: "",
    appropriateToiletFacilities: "",
    commentOnAppropriateToiletFacilities: "",
    dailyActivityManagement: "",
    commentOnDailyActivityManagement: "",
    safeEquipmentUse: "",
    commentOnSafeEquipmentUse: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const option = [
    { label: "U", value: "U" },
    { label: "S", value: "S" },
    { label: "G", value: "G" },
    { label: "E", value: "E" },
  ];

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionNineCcTwo`,
        {
          schoolId,
          auditId,
          auditTaskId: taskId,
          safeRecruitment: dataToSubmit.safeRecruitment,
          commentOnSafeRecruitment: dataToSubmit.commentOnSafeRecruitment,
          riskAssessmentPolicy: dataToSubmit.riskAssessmentPolicy,
          commentOnRiskAssessmentPolicy:
            dataToSubmit.commentOnRiskAssessmentPolicy,
          safetyLogs: dataToSubmit.safetyLogs,
          commentOnSafetyLogs: dataToSubmit.commentOnSafetyLogs,
          inductionForStaff: dataToSubmit.inductionForStaff,
          commentOnInductionForStaff: dataToSubmit.commentOnInductionForStaff,
          emergencyEvacuationPlan: dataToSubmit.emergencyEvacuationPlan,
          commentOnEmergencyEvacuationPlan:
            dataToSubmit.commentOnEmergencyEvacuationPlan,
          accessControlAndSurveillance:
            dataToSubmit.accessControlAndSurveillance,
          commentOnAccessControlAndSurveillance:
            dataToSubmit.commentOnAccessControlAndSurveillance,
          safetyProvisionBudget: dataToSubmit.safetyProvisionBudget,
          commentOnSafetyProvisionBudget:
            dataToSubmit.commentOnSafetyProvisionBudget,
          outOfClassDutyRota: dataToSubmit.outOfClassDutyRota,
          commentOnOutOfClassDutyRota: dataToSubmit.commentOnOutOfClassDutyRota,
          schoolSafetyDisplay: dataToSubmit.schoolSafetyDisplay,
          commentOnSchoolSafetyDisplay:
            dataToSubmit.commentOnSchoolSafetyDisplay,
          appropriateToiletFacilities: dataToSubmit.appropriateToiletFacilities,
          commentOnAppropriateToiletFacilities:
            dataToSubmit.commentOnAppropriateToiletFacilities,
          dailyActivityManagement: dataToSubmit.dailyActivityManagement,
          commentOnDailyActivityManagement:
            dataToSubmit.commentOnDailyActivityManagement,
          safeEquipmentUse: dataToSubmit.safeEquipmentUse,
          commentOnSafeEquipmentUse: dataToSubmit.commentOnSafeEquipmentUse,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  CC2: COMPLIANCE CRITERION 2
                </Typography>
              </Box>

              <ol>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>Evidence of Safe Recruitment</Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Completed Schools own protocol for recruitment but not
                      contacted the safety commission is U, Safety Commission
                      must be notified of all new staff and staff disengagements
                      ='S' comnination of both 0+1 above =G,0+1+2+ the true
                      identity or Class valve =E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safeRecruitment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafeRecruitment"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                {/* 1 */}
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      A risk assessment policy statement with names of the staff
                      responsible for the monitoring of safety in the school
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Policy seen =S, policy + names-G ,1+2 Completed risk
                      assessments for activities carried out =E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="riskAssessmentPolicy"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnRiskAssessmentPolicy"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>

                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Appropriate safety logs kept & completed Checklists
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      3 amongst items listed below-S, 4-5-G ,3: 6 and above = E
                      1+2+ Fire drills, fire equipment maintenance log,
                      Generator's maintenance logs, completed SS checklists;
                      record of SS training attended by members of staff:
                      Incident logs, accidents logs Pupils registrar kept;
                      medicine log, visitors log.
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyLogs"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyLogs"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence of Induction for both academic and non-academic
                      staff
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      policy on Induction -S , evidence of induction log kept
                      plus I above-G, 1+2+ items and evidence of appropriate
                      support for staff to develop relevant Competences = E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="inductionForStaff"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnInductionForStaff"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence of appropriate emergency evacuation plan
                    </Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      This is either U or E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="emergencyEvacuationPlan"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnEmergencyEvacuationPlan"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence of access control & Surveillance
                    </Typography>
                  </li>
                  <Box fontSize={"13px"} p={2}>
                    Gated access with uniformed guard = S, 1+ control for pupil
                    pick = G, 1+2+ technology employed for enhanced security = E
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="accessControlAndSurveillance"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnAccessControlAndSurveillance"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography>
                      Evidence for Safety provisions in the school budget
                    </Typography>
                  </li>

                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      staff training budget on Curriculum, SSLAG Compliance
                      items, Parental Seminars to get Satisfactory mark all 3
                      must be included in the school budget to get G rating:
                      Evidence of past events on 1,2 & 3 plus performance
                      reviews log and Emergency preparedness training for E
                      rating: all the above plus Evidence of celebration of
                      achievements in either 1,2 3 and pupils or all
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safetyProvisionBudget"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafetyProvisionBudget"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <Typography>
                    <li> Duty roster for out of class pupils monitoring</li>
                  </Typography>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      at least 2 rotas specific to common areas within the
                      school - S, 1+ rotas +G, 1+4 and above = E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="outOfClassDutyRota"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnOutOfClassDutyRota"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <Typography>
                    <li> Evidence of appropriate displays on school safety</li>
                  </Typography>
                  <Box fontSize={"13px"} p={2}>
                    SSLAG MS12 (Day) = S, 1+ MS-8 (Boarding)/ SSLAG Compliance
                    Insurance on display, other appropriate display =G, 1+2+
                    information for visitors/ pupils on display-E
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="schoolSafetyDisplay"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSchoolSafetyDisplay"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <Typography>
                    <li> Evidence of appropriate toilet facilities</li>
                  </Typography>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Staff toilets separate from pupils Gender specific toilet
                      facilities Approved Under eight Toilet facilities (1: 10
                      pupils) appropriate no of hand washing facilities provided
                      in the toilets All must be demonstrated to get S otherwise
                      is U, For Grating: Visitors Toilets must be available in
                      addition to criteria for S, E: Visitors Toilets and
                      Disabled access toilet must be available in addition to
                      the criteria for S
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="appropriateToiletFacilities"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnAppropriateToiletFacilities"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                {/* 22 */}
                <Box sx={{ marginBlock: 1 }}>
                  <Typography>
                    <li>
                      {" "}
                      Space allocation and management of Daily activities
                    </li>
                  </Typography>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Space allocation and management of Daily activities
                      Evidence of Adequate space for specific activities e.g.
                      Library, Science laboratory, café or canteen, kitchen/food
                      preparation, Home economics lab, outdoor play area;
                      sporting pitch; Arts & craft; etc Chemicals/ toxic
                      substances are stored in clearly labeled jar/bottles and
                      locked away from unauthorised access; Evidence of an
                      appropriate safety policy in each specialist room S 1+
                      policy seen on display and evidence of communication to
                      members of staff-G; 1+2+ evidence of Risk assessment in
                      practical activities planned in specialist rooms
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="dailyActivityManagement"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnDailyActivityManagement"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
                <Box sx={{ marginBlock: 1 }}>
                  <li>
                    {" "}
                    <Typography> Evidence of Safe equipment use</Typography>
                  </li>
                  <Box p={2}>
                    <Typography fontSize={"13px"}>
                      Evidence that All equipment checked regularly and logged
                      in specialist rooms/labs-S ,1+ evidence of training for
                      Staff operating special equipment and informationon its
                      safe operation, 1+2+ maintenance log/defective equipment
                      are logged and packed away out of the reach of students =
                      E
                    </Typography>
                  </Box>

                  <Box>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="safeEquipmentUse"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                <Box>
                  <RHFTextField
                    name="commentOnSafeEquipmentUse"
                    label="Comment"
                    variant="filled"
                    multiline
                    minRows={2}
                  />
                </Box>
              </ol>
              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={() => handleDirectionChange("forward")}
                    loading={isSubmitting}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default ComplianceCriterion2;
