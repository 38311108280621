import { Typography, Box, Stack, Link } from "@mui/material";
import React from "react";

function TermNdCondition() {
  return (
    <Box sx={{ padding: 5 }}>
      <Link underline="always" color="#056608" href="./register">
        Go Back{" "}
      </Link>
      <Typography variant="h3">
        SSLAG Digital Platform/Portal Terms of Use & Privacy policy
      </Typography>
      <Typography>
        This Site and any other site owned or operated by SSLAG are linked to
        these Terms and Conditions (“SSLAG” Safe Schools, Lagos” “the SSLAG
        Portal” or “this Site”). By visiting this Site, you specifically and
        automatically agree to abide by these Terms and Conditions. The SSLAG
        team reserves the right to modify these Terms and Conditions at any
        time. You agree that neither SSLAG nor any affiliate of the project team
        (collectively, “Affiliated Entities”) shall be liable to you or to any
        third party for any modification, suspension or discontinuance of this
        Site, in whole or in part, or of any service, content, feature, or
        product offered through this Site. Your continued use of the Site after
        such changes, including but not limited to any changes to these Terms
        and Conditions, will indicate your acceptance of such changes.
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">Use of Materials on this Site</Typography>
        <br />
        <Stack>
          In keeping with the SSLAG goals and mission, virtually all of the
          materials posted on this Site, except for (a) images, and (b)
          materials that contain a copyright notice for a third party other than
          SSLAG or the Lagos State Government, are licensed to the public
          through the Creative Commons Attribution-Non-commercial-No Derivatives
          license. This license allows users to download those materials from
          this Site (that are not specifically restricted) and to share them as
          long as (a) the particular article, section, audio, or video clip is
          reproduced in its entirety in its original form and is not edited or
          modified in any way; (b) all copies include attribution to the
          original author (if noted) and SSLAG and contain a link back to the
          SSLAG Site as the original source of the work; and (c) the copies are
          distributed only for non-commercial purposes and are distributed at no
          charge. Other than as specifically described in the preceding
          paragraph, you may not copy, reproduce, distribute, modify, display,
          republish, transmit, repost, or otherwise use the content on this Site
          without prior written permission from SSLAG copyright owners. To
          request such permission, please contact us. The name “SSLAG” Safe
          Schools, Lagos; and all related indicia are the trademarked properties
          of the Lagos State Government and in no event may they be reproduced
          separately from the textual content associated with them on this
          website. Nothing contained on the Site should be construed as
          granting, by implication, estoppel, or otherwise, any license or right
          to use any of SSLAG trade names, trademarks, or service marks without
          express prior written consent. All trademarks and service marks on the
          Site not owned by SSLAG are the property of their respective owners.
          The trade names, trademarks, and service marks owned by SSLAG, whether
          registered or unregistered, may not be used in connection with any
          product or service that is not ours.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">Jurisdiction</Typography>
        <br />
        <Stack>
          The Site is controlled and operated by the SSLAG Project Team, from
          Lagos-Nigeria, and is not intended to subject the Foundation to the
          laws or jurisdiction of any state, country, or territory other than
          that of Nigeria. SSLAG does not represent or warrant that the Site or
          any part thereof is appropriate or available for use in any particular
          jurisdiction other than Nigeria. Those who choose to access the Site
          do so on their own initiative and at their own risk, and are
          responsible for complying with all local laws, rules and regulations.
          You are also subject to Nigeria export controls and are responsible
          for any violations of such controls, including any Nigerian embargoes
          or other federal rules and regulations restricting exports. We may
          limit the Site's availability, in whole or in part, to any person,
          geographic area, or jurisdiction we choose, at any time and in our
          sole discretion.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">
          Information Submitted Through the Site
        </Typography>
        <br />
        <Stack>
          Be aware that your decision to send personally identifiable
          information through your use of this Site will subject such
          information to the privacy laws and standards of Nigeria. Your
          submission of information through the Site is governed by Lagos
          State’s Privacy Policy, (the “Privacy Policy”). Your use of the Site
          is also subject to our Cookie Policy (the “Cookie Policy”). These
          Terms & Conditions incorporate by reference the terms and conditions
          of the Privacy Policy and Cookie Policy. You represent and warrant
          that any information you provide in connection with your use of the
          Site is and shall remain true, accurate, and complete, and that you
          will maintain and update such information regularly. You agree that if
          any information you provide is false, inaccurate, obsolete, or
          incomplete, we may terminate your use of the Site and/or any of the
          services. In addition, SSLAG and the Affiliated Entities have no
          control over, and shall have no liability for, any damages resulting
          from the use (including without limitation republication) or misuse by
          any third party of information voluntarily made public through your
          profile, a forum or any other part of the Site. If you choose to make
          any of your personally identifiable or other information publicly
          available on the Site, you do so at your own risk.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">Rules of Conduct</Typography>
        <br />
        <Stack>
          While using the Site you will comply with all applicable laws, rules,
          and regulations, and with our stakeholders’ Guidelines. In addition,
          we expect users of the Site to respect the rights and dignity of
          others. Your use of the Site is conditioned on your compliance with
          the following rules of conduct. You will not post, transmit, or
          otherwise make available, through or in connection with the Site,
          anything that is or may be :
          <br />
          (a) threatening, harassing, degrading, hateful, or intimidating;
          <br />
          (b) defamatory;
          <br />
          (c) fraudulent or tortious;
          <br /> (d) obscene, indecent, pornographic, or otherwise
          objectionable; or
          <br />
          (e) protected by copyright,
          <br />
          <Stack>
            trademark, trade secret, right of publicity, or any other
            proprietary right without the express prior consent of the owner of
            such right;
            <br />
            Any material that would give rise to criminal or civil liability;
            that encourages conduct that constitutes a criminal offense; or that
            encourages or provides instructional information about illegal
            activities or activities such as “hacking,” “cracking,” or
            “phreaking”;
            <br />
            Any virus, worm, Trojan horse, Easter egg, time bomb, spyware, or
            other computer code, file, or program that is harmful or invasive or
            may or is intended to damage or hijack the operation of, or to
            monitor the use of, any hardware, software, or equipment;
            <br />
            Any unsolicited or unauthorised advertising, promotional material,
            “junk mail,” “spam,” “chain letter,” “pyramid scheme,” or investment
            opportunity, or any other form of solicitation;
            <br />
            use the Site for any fraudulent or unlawful purpose;
            <br />
            Use the Site to defame, abuse, harass, stalk, threaten, or otherwise
            violate the legal rights of others, including without limitation
            others' privacy rights or rights of publicity;
            <br />
            Use the Site to harvest or collect personally identifiable
            information about other users of the Site;
            <br />
            impersonate any person or entity, including without limitation any
            representative of SSLAG or an Affiliated Entity; falsely state or
            otherwise misrepresent your affiliation with any person or entity in
            connection with the Site; or express or imply that we endorse any
            statement you make;
            <br />
            Interfere with or disrupt the operation of the Site or the servers
            or networks used to make the Site available; or violate any
            requirements, procedures, policies, or regulations of such networks;
            <br />
            Restrict or inhibit any other person from using the Site, including
            without limitation by hacking or defacing any portion of the Site;
            <br />
            Use the Site to advertise or offer to sell or buy any goods or
            services without SSLAG’ express prior written consent;
            <br />
            Reproduce, duplicate, copy, sell, resell, or otherwise exploit for
            any commercial purposes, any portion of, use of, or access to the
            Site;
            <br />
            Modify, adapt, translate, reverse engineer, decompile, or
            disassemble any portion of the Site;
            <br />
            Remove any copyright, trademark, or other proprietary rights notice
            from the Site or materials originating from the Site;
            <br />
            Frame or mirror any part of the Site without SSLAG’s express prior
            written consent;
            <br />
            Create a database by systematically downloading and storing Site
            content;
            <br />
            Use any robot, spider, site search/retrieval application or other
            manual or automatic device to retrieve, index, “scrape,” “data
            mine,” or in any way gather Site content or reproduce or circumvent
            the navigational structure or presentation of the Site without
            SSLAG’s express prior written consent; or
            <br />
            post, email, or otherwise make available via the Site any of the
            following: “lobbying” or “partisan political activities” materials
            within the meaning of the appropriate Nigerian Internal Revenue
            Code.
          </Stack>
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h3" color={"red"}>
          Privacy & Security
        </Typography>
        <br />
        <Stack>
          SSLAG works to maintain the privacy of any personally identifiable
          information (name, mailing address, email address, etc.) that may be
          collected through our websites. Please note, however, that, though
          this Site has security measures in place, we do not represent,
          warrant, or guarantee that personal information will be protected
          against unauthorised access, loss, misuse, or alterations. Similarly,
          we disclaim liability for personal information submitted through this
          Site. Users are hereby advised that they submit such personal
          information at their own risk.
        </Stack>
        <br />
        <Typography variant="h3">
          No Liability for Third Party Content
        </Typography>
        <br />
        <Stack>
          Site visitors may not post messages or make statements in connection
          with profiles and/or forums that are inaccurate, misleading, or
          deceptive. SSLAG and the Affiliated Entities neither endorse nor are
          responsible for any opinion, advice, information or statements made in
          profiles and/or forums by third parties. Without limitation, SSLAG and
          the Affiliated Entities are not responsible for any information or
          materials made available through profiles and/or forums (including
          without limitation errors or omissions in postings or links or images
          embedded in messages) or results obtained by using any such
          information or materials. Under no circumstances will SSLAG, the
          Affiliated Entities, or their respective sponsors, employees,
          officers, directors, stakeholders, affiliates, agents,
          representatives, suppliers, or members be liable for any loss or
          damage caused by your reliance on such information or materials. The
          opinions expressed in profiles and/or forums reflect solely the
          opinions of the individuals who submitted such opinions, and may not
          reflect the opinions of SSLAG project team or LSC.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">
          Registration: Usernames and Passwords
        </Typography>
        <br />
        <Stack>
          You may be required to register with SSLAG in order to access certain
          Services or areas of the Site. With respect to any such registration,
          we may refuse to grant you, and you may not use, a username (or email
          address) that is already being used by someone else; that may be
          construed as impersonating another person; that belongs to another
          person; that violates the intellectual property or other rights of any
          person; that is offensive; or that we reject for any other reason in
          our sole discretion.
          <br />
          Your username and password are for your personal use only, and not for
          use by any other person. You are responsible for maintaining the
          confidentiality of any password you may use to access the Site, and
          agree not to transfer your password or username, or lend or otherwise
          transfer your use of or access to the Site, to any third party. You
          are fully responsible for all interaction with the Site that occurs in
          connection with your password or username. You agree to immediately
          notify SSLAG of any unauthorised use of your password or username or
          any other breach of security related to your account or the Site, and
          to ensure that you log off/ exit from your account with the Site (if
          applicable) at the end of each session. We are not liable for any loss
          or damage arising from your failure to comply with any of the
          foregoing obligations.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4">License</Typography>
        <br />
        <Stack>
          For purposes of clarity, you will retain ownership of any materials
          that you submit through the Site (each, a “Submission”). You hereby
          grant to SSLAG, the Affiliated Entities, and their designees a
          worldwide, nonexclusive, transferable, royalty-free, perpetual,
          irrevocable right and license, without compensation to you: (a) to
          use, reproduce, distribute, adapt (including without limitation edit,
          modify, translate, and reformat), create derivative works of,
          transmit, publicly display, and publicly perform such Submission, in
          any media now known or hereafter developed, for SSLAG’s and the
          Affiliated Entities' non-profit purposes, and (b) to sublicense the
          foregoing rights, through multiple tiers of distribution (i.e., viral
          media), to the maximum extent permitted by applicable law. In
          addition, if you provide to us any ideas, proposals, suggestions or
          other materials (“Feedback”), whether related to this Site or
          otherwise, such Feedback will be deemed a Submission, and you hereby
          acknowledge and agree that such Feedback is not confidential, and that
          your provision of such Feedback is gratuitous, unsolicited, and
          without restriction, and does not place SSLAG or the Affiliated
          Entities under any fiduciary or other obligation. For each Submission,
          you represent and warrant that you have all rights necessary for you
          to grant the license granted in this section, and that such
          Submission, and your provision thereof, to and through the Site are
          complete and accurate, and are not fraudulent, tortious, or otherwise
          in violation of any applicable laws, rules, or regulations, or any
          right of any third party. You further irrevocably waive any “moral
          rights” or other rights with respect to attribution of authorship or
          integrity of materials regarding each Submission that you may have
          under any applicable law under any legal theory.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4" color={"red"}>
          Monitoring
        </Typography>
        <br />
        <Stack>
          You acknowledge and agree that (a) we reserve the right (but have no
          obligation) to evaluate each Submission before allowing it to be
          posted on the Site or any forum; and (b) we may do one or all of the
          following, at our discretion: (i) monitor Submissions; (ii) alter,
          remove, or refuse to post or allow to be posted any Submission; and/or
          (iii) disclose any Submissions, and the circumstances surrounding
          their transmission, to any third party in order to operate the Site;
          to protect SSLAG, the Affiliated Entities, and their respective
          sponsors, employees, officers, directors, shareholders, affiliates,
          agents, representatives, suppliers and members, and the Site's users
          and visitors; to comply with legal obligations or governmental
          requests; to enforce this Agreement; or for any other reason or
          purpose.
        </Stack>
        <br />
        <Typography variant="h4">Links</Typography>
        <br />
        <Stack>
          The Site may provide links to other web sites and online resources.
          Because SSLAG has no control over such sites and resources, you
          acknowledge and agree that neither SSLAG nor the Affiliated Entities
          are responsible for the availability of such external sites or
          resources, and neither SSLAG nor the Affiliated Entities endorse or
          are responsible or liable for any content, advertising, products, or
          other materials on or available through such sites or resources. Other
          websites may provide links to the Site with or without our
          authorisation. You acknowledge and agree that SSLAG and the Affiliated
          Entities do not endorse such sites, and are not and shall not be
          responsible or liable for any links from those sites to the Site, any
          content, advertising, products, or other materials available on or
          through such other sites, or any loss or damages incurred in
          connection therewith.
        </Stack>
        <br />
        <Stack>
          YOU AGREE THAT YOUR USE OF THIRD PARTY WEB SITES AND RESOURCES,
          INCLUDING WITHOUT LIMITATION, YOUR USE OF ANY CONTENT, INFORMATION,
          DATA, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE
          THROUGH SUCH WEB SITES AND RESOURCES, IS AT YOUR OWN RISK AND IS
          SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES
          AND RESOURCES.
          <br />
          <Stack>
            SSLAG shall have the right, at any time and in its sole discretion,
            to block links to the Site through technological or other means
            without prior notice
          </Stack>
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4" color={"red"}>
          Disclaimer of Warranties and Limitations of Liability
        </Typography>
        <br />
        <Stack>
          THE SITE AND ANY MATERIALS OR SERVICES MADE AVAILABLE THROUGH THE
          SITE, INCLUDING WITHOUT LIMITATION, ARE PROVIDED TO YOU ON AN “AS IS,”
          “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY
          KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY. YOU AGREE THAT YOU MUST
          EVALUATE, AND THAT YOU BEAR ALL RISKS ASSOCIATED WITH THE USE OF THE
          SITE, INCLUDING WITHOUT LIMITATION, ANY RELIANCE ON THE ACCURACY,
          COMPLETENESS OR USEFULNESS OF ANY MATERIALS AVAILABLE THROUGH THE SITE
          (INCLUDING ANY THIRD-PARTY CONTENT SUBMITTED). SSLAG AND THE
          AFFILIATED ENTITIES DISCLAIM ALL WARRANTIES WITH RESPECT TO THE SITE
          AND ANY PRODUCT, SERVICE (INCLUDING WITHOUT LIMITATION, THIRD-PARTY
          PRODUCTS AND SERVICES) OR MATERIALS OBTAINED THROUGH THE SITE, TO THE
          FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, INCLUDING THE
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NONINFRINGEMENT, AND TITLE.
          <br />
          NEITHER SSLAG NOR ANY AFFILIATED ENTITY WILL BE LIABLE FOR ANY
          INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE
          DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY OR OTHER THEORY, INCLUDING WITHOUT LIMITATION,
          DAMAGES FOR LOSS OF PROFITS, USE, DATA, LOSS OF OTHER INTANGIBLES,
          LOSS OF SECURITY OF INFORMATION YOU HAVE PROVIDED IN CONNECTION WITH
          YOUR USE OF THE SITE, OR UNAUTHORISED INTERCEPTION OF ANY SUCH
          INFORMATION BY THIRD PARTIES, EVEN IF ADVISED IN ADVANCE OF SUCH
          DAMAGES OR LOSSES. IN PARTICULAR, AND WITHOUT LIMITATION, NEITHER
          SSLAG NOR ANY AFFILIATED ENTITY WILL BE LIABLE FOR DAMAGES OF ANY KIND
          RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SITE OR FROM ANY
          MATERIALS POSTED ON THE SITE BY SSLAG OR ANY THIRD PARTY. YOUR SOLE
          AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP
          USING THE SITE. THE MAXIMUM LIABILITY OF SSLAG FOR ALL DAMAGES, LOSSES
          AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT
          LIMITATION NEGLIGENCE) OR OTHERWISE, SHALL BE THE GREATER OF THE TOTAL
          AMOUNT, IF ANY, PAID BY YOU TO SSLAG TO ACCESS AND USE THE SITE, OR
          Three thousand naira (N3,000.00) only.
          <br />
          While we try to maintain the integrity and security of the Site and
          the servers from which the Site is operated, we do not guarantee that
          the Site will be or remain secure, complete or correct, or that access
          to the Site will be uninterrupted. The Site may include inaccuracies,
          errors, and materials that violate or conflict with this Agreement.
          Additionally, third parties may make unauthorised alterations to the
          Site. If you become aware of any unauthorised third party alteration
          to the Site, contact us at 284B Ajose Adeoguns Street, Victoria
          Island, Lagos, Nigeria or by email at sslag@schoolrun.com.ng, with a
          description of the material(s) at issue and the URL or location on the
          Site where such material(s) appear.
        </Stack>
      </Typography>
      <br />
      <Typography>
        <Typography variant="h4" color={"red"}>
          Indemnity
        </Typography>
        <br />
        <Stack>
          You agree to defend, indemnify and hold harmless SSLAG and the
          Affiliated Entities and their respective sponsors, employees,
          officers, directors, shareholders, affiliates, agents,
          representatives, suppliers and members from and against all claims,
          losses, costs, and expenses (including attorneys fees) arising out of
          (a) your use of, or activities in connection with, the Site; or (b)
          any violation of this Agreement by you or anyone using your username
          and/or password
        </Stack>
        <br />
        <Typography variant="h4">Termination</Typography>
        <br />
        <Stack>
          <br />
          This Agreement is effective until terminated. SSLAG, in its sole
          discretion, may terminate your access to or use of the Site, at any
          time and for any reason, including if SSLAG believes that you have
          violated or acted inconsistently with the letter or spirit of this
          Agreement. Upon any such termination, your right to use the Site will
          immediately cease. You agree that any termination of your access to or
          use of the Site may be effected without prior notice, and that SSLAG
          may immediately deactivate or delete your password and user-name, and
          all related information and files associated with it, and/or bar any
          further access to such information or files. You agree that SSLAG and
          the Affiliated Entities shall not be liable to you or any third party
          for any termination of your access to the Site or to any such
          information or files, and shall not be required to make such
          information or files available to you after any such termination.
        </Stack>
        <br />
        <Typography variant="h4">Governing Law Venue</Typography>
        <br />
        <Stack>
          This Agreement is governed by and shall be construed in accordance
          with the laws of the Federal Republic of Nigeria, without regards to
          its principles of conflicts of law. You agree to exclusive
          jurisdiction by the federal and state courts located in Nigeria, and
          waive any jurisdictional, venue or inconvenient forum objections to
          such courts.
        </Stack>
        <br />
        <Typography variant="h4">Miscellaneous</Typography>
        <br />
        <Stack>
          This Agreement does not, and shall not be construed to, create any
          partnership, joint venture, employer-employee, agency, or
          franchisor-franchisee relationship between you and SSLAG or any
          Affiliated Entities. If any provision of this Agreement is found to be
          unlawful, void, or for any reason unenforceable, that provision will
          be deemed severable from this Agreement and will not affect the
          validity and enforceability of any remaining provision. You may not
          assign, transfer, or sublicense any or all of your rights or
          obligations under this Agreement without our express prior written
          consent. SSLAG may assign, transfer, or sublicense any or all of its
          rights or obligations under this Agreement without restriction. No
          waiver by either party of any breach or default hereunder will be
          deemed to be a waiver of any preceding or subsequent breach or
          default. Any heading, caption, or section title contained herein is
          inserted only as a matter of convenience, and in no way defines or
          explains any section or provision hereof. This, together with all
          policies referred to herein, is the entire Agreement between you and
          SSLAG relating to the subject matter herein and supersedes any and all
          prior or contemporaneous written or oral agreements or understandings
          between you and SSLAG relating to such subject matter. Notices to you
          may be made via posting to the Site, by email, or by regular mail, in
          SSLAG's discretion. The Site may also provide notices of changes to
          this Agreement or other matters by displaying such notices or by
          providing links to such notices. Without limitation, you agree that a
          printed version of this Agreement and of any notice given in
          electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this Agreement to the same
          extent and subject to the same conditions as other business documents
          and records originally generated and maintained in printed form. SSLAG
          will not be responsible for failures to fulfill any obligations due to
          causes beyond its control
        </Stack>
      </Typography>
      <Link underline="always" color="#056608" href="./register">
        Go Back{" "}
      </Link>
    </Box>
  );
}

export default TermNdCondition;
