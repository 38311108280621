import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useForm } from "react-hook-form";
import logoBg from "../../images/sslagBookingForm.JPG";

import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";

function AdminjourneystaffReport() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [staffReport, setStaffReport] = useState(null);

  const defaultValues = {
    employeeName: "",
    empoyeeId: "",
    employeeRole: "",
    employeeDateOfBirth: "",
    employeeStartDate: "",
    employeeEndDate: "",
    reasonsForStatusChange: "",
    reasonsForRecruitment: "",
    effectiveResumptionDate: "",
    reasonsForLeaving: "",
    effectiveEndDate: "",
    disciplinaryActionFaced: "",
    disciplinaryDueDate: "",
    safeRecruitmentChecksCompleted: "",
  };

  const methods = useForm({
    // resolver: yupResolver(CreateAdminSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = () => {};
  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolIntervention/GetStaffStatusReportInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        methods.reset();
        setStaffReport(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (staffReport === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (staffReport === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found....</Typography>;
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
          }}
        >
          <Box width={500} textAlign={"center"}>
            <Box>
              <Grid ml={17}>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 200,
                  }}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Container>
            <Box mt={2}>
              <Typography variant="h5">
                SSLAG (SSR) STAFF STATUS REPORT
              </Typography>
            </Box>

            <Grid>
              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Employee Information
                </Typography>
              </Box>

              <Box display={"flex"} justifyContent={"space-between"} p={2}>
                <Grid minWidth={"45%"}>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      //   name="employeeName"
                      value={staffReport.employeeName}
                      label="Employee Name"
                      variant="filled"
                      disabled
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      name="employeeRole`"
                      label="Role"
                      variant="filled"
                      disabled
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Start Date"
                        // disablePast
                        disabled
                        value={staffReport.employeeStartDate}
                        onChange={(value) => {
                          methods.setValue("employeeStartDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeStartDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid minWidth={"45%"}>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      disabled
                      name="empoyeeId"
                      value={staffReport.employeeId}
                      label="Employee ID"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Date of birth"
                        disablePast
                        disabled
                        value={staffReport.employeeDateOfBirth}
                        onChange={(value) => {
                          methods.setValue("employeeDateOfBirth", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeDateOfBirth"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Report end date"
                        disablePast
                        value={staffReport.employeeEndDate}
                        disabled
                        onChange={(value) => {
                          methods.setValue("employeeEndDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeEndDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Box>

              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Reasons for status change
                </Typography>
              </Box>
              <Box p={2}>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="reasonsForRecruitment"
                      value={staffReport.reasonsForRecruitment}
                      label="Enter reason for recruitment"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                      disabled
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter effective 
                        resumption date"
                        disablePast
                        value={staffReport.effectiveResumptionDate}
                        disabled
                        onChange={(value) => {
                          methods.setValue("effectiveResumptionDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="effectiveResumptionDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      // name="reasonsForLeaving"
                      value={staffReport.reasonsForLeaving}
                      label="Enter reason for leaving"
                      multiline={true}
                      minRows={3}
                      variant="filled"
                      disabled
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter effective end 
                        date"
                        disablePast
                        disabled
                        value={staffReport.effectiveEndDate}
                        onChange={(value) => {
                          methods.setValue("effectiveEndDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="effectiveEndDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="disciplinaryActionFaced"
                      value={staffReport.disciplinaryActionFaced}
                      label="State if staff has faced any disciplinary actions
                      In this employment (if reporting end date)"
                      variant="filled"
                      multiline={true}
                      disabled
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter due date"
                        disablePast
                        disable
                        value={staffReport.disciplinaryDueDate}
                        onChange={(value) => {
                          methods.setValue("disciplinaryDueDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="disciplinaryDueDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="safeRecruitmentChecksCompleted"
                      disabled
                      value={staffReport.safeRecruitmentChecksCompleted}
                      label="State if safe recruitment checks are completed in 
                      respect of this employment (if reporting start date)"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter date"
                        disablePast
                        disabled
                        value={staffReport.startDateForSafeRecruitmentCheck}
                        onChange={(value) => {
                          methods.setValue(
                            "startDateForSafeRecruitmentCheck",
                            value,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="startDateForSafeRecruitmentCheck"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      // name="fullName"
                      value={staffReport.fullName}
                      label=" Full name"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                      disabled
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Date SSR Completed"
                        disablePast
                        value={staffReport.toDate}
                        disabled
                        onChange={(value) => {
                          methods.setValue("toDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="toDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Container>
        </FormProvider>
      </Box>
    </Fragment>
  );
}

export default AdminjourneystaffReport;
