import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Alert,
  FormHelperText,
  Button,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

function AddClassArm() {
  const Class = [
    {
      value: "0",
      label: "Select Class",
    },
    {
      value: "1",
      label: "jss 1",
    },
    {
      value: "2",
      label: "Ss 1",
    },
    {
      value: "3",
      label: "Basic 3",
    },
  ];

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "Select Year Group",
    },
  ]);

  const PaperStyle = {
    padding: 40,
  };

  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllyeargroupsBySchoolId/${schoolId}?isClassArmAvailable=true`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId != null) getClasses();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };

  const defaultValues = {
    classArm: "",
    class: "",
  };

  const AddClassArmSchema = Yup.object().shape({
    classArm: Yup.string().required("Class Arm  is required"),
    class: Yup.string().required("Class is required"),
  });

  const methods = useForm({
    resolver: yupResolver(AddClassArmSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `/yeargroups/${data.class}/CreateClass`,
        {
          schoolId,
          className: data.classArm,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // ===========================================

  //============================================

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> School Management</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/addClass"
            className="header"
          >
            Add Year Group
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/classArm"
            className="header active"
          >
            Add Class
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/assign"
            className="header"
          >
            Assign Class Tutor
          </Button>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/class"
            className="header"
          >
            All Year Group
          </Button>
        </Box>
      </Box>
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                {/* <Typography>Add Class </Typography>
                <hr /> */}

                <Grid>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Add Class
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item width={300}>
                    <FormHelperText>Year Group</FormHelperText>
                    <RHFTextField
                      select
                      name="class"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {classes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.yearGroupName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                </Grid>
                <Grid mt={2}>
                  <FormHelperText>Class Name</FormHelperText>
                  <Grid item>
                    <RHFTextField
                      name="classArm"
                      // label="Class "
                      variant="filled"
                     
                    />
                  </Grid>
                </Grid>

                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddClassArm;
