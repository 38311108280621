import React, { useState } from "react";
import {
  Typography,
  Box,
  List,
  Card,
  ListItemButton,
  Button,
  CardActions,
  ListItemIcon,
} from "@mui/material";
import { Incident, Accident, Compliant } from "../../_mock/data-mock";
import { borderRadius } from "@mui/system";

function AdminDashboard4() {
  const [incident, setIncident] = useState(Incident);
  const [accident, setAccident] = useState(Accident);
  const [compliant, setCompliant] = useState(Compliant);

  const font = {
    fontSize: "10px",
  };

  return (
    <Card>
      <CardActions
        sx={{ justifyContent: "space-between", backgroundColor: "#edf5f9" }}
      >
        <Typography
          style={{
            backgroundColor: "#edf5f9",
          }}
          ml={5}
          variant="h6"
        >
           Analytics
        </Typography>
        <Button
          size="small"
          style={{ color: "#1396e3" }}
          href="/admin/event/school-report"
        >
          View All
        </Button>
      </CardActions>
      <Box
        spacing={2}
        sx={{ p: 3 }}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <List component="nav" aria-label="main mailbox folders">
          <Typography
            variant="h6"
            sx={{
              background: "#6495ED",
              borderRadius: "3px",
              mb: "5px",
              p: 1,
              borderRadius: "15px",
            }}
          >
            Complaints
          </Typography>
          {compliant.map((eachData, index) => (
            <Box
              key={index}
              sx={{
                background: "#edf5f9",
                borderRadius: "3px",
                minHeight: "60px",
                borderRadius: "5px",
              }}
            >
              <ListItemButton>
                <Box
                  sx={{
                    minWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                >
                  <Typography textAlign={"center"} mt={1}>
                    {eachData.index}
                  </Typography>
                </Box>

                <Typography bgcolor="#edf5f9">{eachData.event}</Typography>
              </ListItemButton>
            </Box>
          ))}
        </List>
        <List component="nav" aria-label="main mailbox folders">
          <Typography
            variant="h6"
            sx={{
              background: "#DE3163",
              borderRadius: "3px",
              mb: "5px",
              p: 1,
              borderRadius: "15px",
            }}
          >
            Incidents
          </Typography>

          {incident.map((eachData, index) => (
            <Box
              key={index}
              sx={{
                background: "#edf5f9",
                borderRadius: "3px",
                minHeight: "60px",
                borderRadius: "5px",
              }}
            >
              <ListItemButton>
                <Box
                  sx={{
                    minWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                >
                  <Typography textAlign={"center"} mt={1}>
                    {" "}
                    {eachData.index}
                  </Typography>
                </Box>
                <Typography ml={1} bgcolor="#edf5f9">
                  {eachData.event}
                </Typography>
              </ListItemButton>
            </Box>
          ))}
        </List>
        <List component="nav" aria-label="main mailbox folders">
          <Typography
            variant="h6"
            sx={{
              background: "#CD5C5C",
              borderRadius: "3px",

              mb: "5px",
              p: 1,
              borderRadius: "15px",
            }}
          >
            Accidents Log
          </Typography>

          {accident.map((eachData, index) => (
            <Box
              key={index}
              sx={{
                background: "#edf5f9",
                borderRadius: "3px",
                minHeight: "60px",
                borderRadius: "5px",
              }}
            >
              <ListItemButton>
                <Box
                  sx={{
                    minWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                >
                  <Typography textAlign={"center"} mt={1}>
                    {" "}
                    {eachData.index}
                  </Typography>
                </Box>
                <Typography ml={1} bgcolor="#edf5f9">
                  {eachData.event}
                </Typography>
              </ListItemButton>
            </Box>
          ))}
        </List>
      </Box>
    </Card>
  );
}

export default AdminDashboard4; // import React, { useState } from "react";
