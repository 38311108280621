import {
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  Alert,
  Button,
  Divider,
  MenuItem,
  IconButton,
  FormHelperText,
  Modal,
  Card,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";

import { FormProvider, RHFTextField } from "src/components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState, Fragment } from "react";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function AssignClass() {
  const PaperStyle = {
    padding: 40,
  };
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;
  const [loadingAttendance, setLoadingAttendance] = useState(false);
  const [assignteacher, setAssignTeacher] = useState([]);
  const [loadingTeachersAndDuties, setLoadingTeacherAndDuties] =
    useState(false);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };

  const AddClassTutor = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.string().required("class Arm is required"),
    classTeacher: Yup.string().required("class Teacher is required"),
  });

  const defaultValues = {
    class: "",
    classArm: "",
    classTeacher: "",
  };

  const UndoDuty = async (schoolId, classId, yearGroupId, appUserId) => {
    try {
      const response = await axoisCustomAgent.delete(
        `YearGroups/UnAssignTeacherFromClass?teacherId=${appUserId}&schoolId=${schoolId}&yearGroupId=${yearGroupId}&classId=${classId}`
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      window.location.reload();

      enqueueSnackbar(message, { variant: "success" });
      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = assignteacher.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Year Group",
      selector: (row) => row.class.yearGroupName,
    },
    {
      name: "Class Name",
      selector: (row) => row.class.className,
    },

    {
      cell: (row) => (
        <button
          className="unassign"
          onClick={() =>
            UndoDuty(
              schoolId,
              row.class.classId,
              row.class.yearGroupId,
              row.appUserId
            )
          }
          // schoolId={schoolId}
          // teacherId={row.appUserId}
          // yearGroupId={row.class.yearGroupId}
          // classId={row.class.classId}
        >
          Unassign
        </button>
      ),
    },
  ];

  const methods = useForm({
    resolver: yupResolver(AddClassTutor),
    defaultValues,
  });

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "Select Year Group",
      classes: [],
    },
  ]);

  const [teachers, setTeachers] = useState([
    {
      id: "",
      fullName: "Select Teacher",
    },
  ]);

  const [classArms, setClassArms] = useState([
    {
      id: "",
      className: "Select Class",
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [loadingTeachers, setLoadingTeachers] = useState(false);

  const watchClass = methods.watch("class");

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { formState, setValue } = methods;

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllyeargroupsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    const getTeachers = async () => {
      setLoadingTeachers(true);

      try {
        const response = await axoisCustomAgent.get(
          `/SchoolStaff/GetAllTeachers/${schoolId}?isTeacherAssignedToClass=false`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;

        let customTeachers = [];

        if (data.length > 0) {
          customTeachers = data.map((item) => {
            return {
              id: item.appUserId,
              fullName: `${item.firstName} ${item.lastName}`,
            };
          });
        }

        if (!unmounted) setTeachers([...teachers, ...customTeachers]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingTeachers(false);
      }
    };

    if (schoolId != null) {
      getTeachers();
      getClasses();
    }

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  if (schoolId != null) {
    // getAssignedTeacher();
  }

  useEffect(() => {
    const getAssignedTeacher = async () => {
      let unmounted = false;
      setLoadingTeacherAndDuties(true);

      try {
        const response = await axoisCustomAgent.get(
          `/SchoolStaff/Getallteachers/${schoolId}?isTeacherAssignedToClass=true`
        );

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        if (!unmounted) setAssignTeacher([...assignteacher, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingTeacherAndDuties(false);
      }
    };

    if (schoolId != null) {
      getAssignedTeacher();
    }
  }, [schoolId]);

  useEffect(() => {
    if (formState.dirtyFields.class && watchClass) {
      let classArms = classes.find(
        (item) => `${item.id}` === watchClass
      ).classes;
      setClassArms([
        {
          id: "",
          className: "Select Class",
        },
        ...classArms,
      ]);
    }
  }, [watchClass]);

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `/yeargroups/assignTeacherToClass`,
        {
          schoolId,
          teacherId: data.classTeacher,
          yearGroupId: data.class,
          classId: data.classArm,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      methods.reset();
      enqueueSnackbar("Teacher assigned successfully", { variant: "success" });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> School Management</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/addClass"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Add Year Group
          </Button>

          <Button
            href="/dashboard/user/classArm"
            sx={{ color: "#6495ed" }}
            className="header"
          >
            Add Class
          </Button>

          <Button href="/dashboard/user/assign" className="header active">
            Assign Class Tutor
          </Button>
          <Button
            href="/dashboard/user/class"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            All Year Group
          </Button>
        </Box>
      </Box>

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={4}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                {/* <Typography>Assign Class Teacher</Typography>
                <hr /> */}
                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Assign Class Teacher
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={5} mt={2}>
                  <Grid item width={300}>
                    <FormHelperText>Year Group</FormHelperText>
                    <RHFTextField
                      select
                      name="class"
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {classes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.yearGroupName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={300}>
                    <FormHelperText>Select Class</FormHelperText>
                    <RHFTextField
                      select
                      name="classArm"
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {classArms.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.className}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={300}>
                    <FormHelperText>Select Teacher</FormHelperText>
                    <RHFTextField
                      select
                      name="classTeacher"
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {teachers.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.fullName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Assign Teacher
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* ===========Table=================================== */}
      <Box paddingTop={5}>
        <Card>
          {loadingAttendance ? (
            <Typography variant="h3">Loading Assign Teacher....</Typography>
          ) : (
            <Grid p={3} style={Gridimage}>
              <DataTable
                columns={columns}
                data={filteredItems}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            </Grid>
          )}
        </Card>
      </Box>
    </Box>
  );
}

export default AssignClass;

function MoreMenuButton({
  isActive,
  teacherId,
  yearGroupId,
  schoolId,
  classId,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  return (
    <>
      {/* <Button onClick={UndoDuty}>
       Unassign
    </Button> */}
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handlePageOpen} sx={{ color: "succes" }}>
          <Iconify icon={"eva:edit-2-outline"} sx={{ ...ICON }} />
          Unassign
        </MenuItem>
        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}></Box>
        </Modal>
        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}
