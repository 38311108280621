import { Grid, Stack, Alert, FormHelperText, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { LoadingButton } from "@mui/lab";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";

function Log({ schoolId, studentId }) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const {
  //   userData: { schoolId },
  // } = useAuth();

  const LogSchema = Yup.object().shape({});
  const defaultValues = {
    behaviourId: "",
    actionTakenId: "",

    locationForDetention: "",
    detentionStartTime: "",
    detentionEndTime: "",
    reward: "",
  };

  const methods = useForm({
    resolver: yupResolver(LogSchema),

    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const BEHAVIOUR = [
    {
      value: "0",
      label: "Select Behaviour",
    },
    {
      value: "1",
      label: "Rude and agression to staff",
    },
    {
      value: "2",
      label: "Refuse instructions",
    },
    {
      value: "3",
      label: "Disruptive in lession and moving around",
    },
    {
      value: "4",
      label: "Swearing",
    },
    {
      value: "5",
      label: "Wrong uniform",
    },

    {
      value: "6",
      label: "No tools to engage in lesson",
    },
    {
      value: "7",
      label: "Fighting other students",
    },
    {
      value: "8",
      label: "Helpful and kind to staff and mate",
    },
    {
      value: "9",
      label: "Complete all tasks",
    },
    {
      value: "10",
      label: "Attentive and obeyed all instructions",
    },
  ];

  const ACTION = [
    {
      value: "0",
      label: "Select Action",
    },
    {
      value: "1",
      label: "Lunch break detention",
    },
    {
      value: "2",
      label: "Inform parent",
    },
    {
      value: "3",
      label: "After school detention",
    },
    {
      value: "4",
      label: "Exclusion",
    },
  ];

  const BadBehaviour = watch("actionTakenId");
  const GoodBehaviour = watch("behaviour");
  const ActionBehaviour = watch("behaviour");

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Log...</Typography>
        </Box>
      </Box>
    );
  }

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        `Students/LogStudentBehaviour?schoolId=${schoolId}`,
        {
          behaviourId: data.behaviour,
          actionTakenId: data.actionTakenId,
          studentId,
          locationForDetention: data.locationForDetention,
          detentionStartTime: data.detentionStartTime,
          detentionEndTime: data.detentionEndTime,
          reward: data.reward,
        }
      );
      // console.log(data);
      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Grid>
          <Grid>
            <FormHelperText>Student behaviour</FormHelperText>
          </Grid>

          <RHFTextField
            select
            name="behaviour"
            required
            variant="filled"
            SelectProps={{ native: true }}
          >
            {BEHAVIOUR.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </RHFTextField>
        </Grid>

        {ActionBehaviour === "1" ||
        ActionBehaviour === "2" ||
        ActionBehaviour === "3" ||
        ActionBehaviour === "4" ||
        ActionBehaviour === "5" ||
        ActionBehaviour === "6" ||
        ActionBehaviour === "7" ? (
          <Grid>
            <Grid>
              <FormHelperText></FormHelperText>
              <RHFTextField
                select
                name="actionTakenId"
                required
                variant="filled"
                SelectProps={{ native: true }}
              >
                {ACTION.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>

            {BadBehaviour === "1" || BadBehaviour === "3" ? (
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormHelperText>Start of detention :</FormHelperText>
                  <TimePicker
                    // label="Date of Birth "
                    value={methods.watch().detentionStartTime}
                    onChange={(value) => {
                      methods.setValue("detentionStartTime", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        {...params}
                        variant="filled"
                        name="detentionStartTime"
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <FormHelperText>End of Detention :</FormHelperText>
                  <TimePicker
                    // label="Date of Birth "
                    value={methods.watch().detentionEndTime}
                    onChange={(value) => {
                      methods.setValue("detentionEndTime", value, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <RHFTextField
                        {...params}
                        variant="filled"
                        name="detentionEndTime"
                      />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText>Location of detention :</FormHelperText>
                <RHFTextField
                  name="locationForDetention"
                  placeholder="Location of detention"
                  variant="filled"
                  fullWidth
                  multiline={true}
                  minRows={3}
                />
              </Grid>
            ) : null}
          </Grid>
        ) : null}

        <Grid></Grid>
        <Grid>
          {GoodBehaviour === "8" || GoodBehaviour === "9" || GoodBehaviour === "10" ? (
            <Grid>
              <FormHelperText>Reward:</FormHelperText>
              <RHFTextField
                name="reward"
                placeholder="Reward"
                variant="filled"
                fullWidth
                multiline={true}
                minRows={3}
              />
            </Grid>
          ) : null}
        </Grid>

        <Grid item sx={{ textAlign: "right", mt: 3 }}>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Grid>
      </FormProvider>
    </Grid>
  );
}

export default Log;
