import { Grid, Alert, Stack, FormHelperText, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";

function EditStudent({ studentId, schoolId }) {
  const EditStudentSchmema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),

    stateOfOrigin: Yup.string().required("state of origin is  required"),
    yearOfAdmission: Yup.string().required("year of admissoin is  required"),
    DOB: Yup.string().nullable().required("date of birth  is  required"),
    studentRegNo: Yup.string().nullable().required("student id  is  required"),

    lga: Yup.string().required("local government  is  required"),
    gender: Yup.string().oneOf(["1", "2"], "Select a gender type "),
    status: Yup.string().required("status is required"),
  });
  const { enqueueSnackbar } = useSnackbar();
  // const { userData } = useAuth();
  // const { schoolId } = userData;
  const theme = useTheme();

  const [students, setStudents] = useState(null);

  const [loading, setLoading] = useState(false);

  const defaultValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    yearOfAdmission: "",
    stateOfOrigin: "",
    DOB: null,
    lga: "",
    gender: "",
    hospitalName: "",
    studentRegNo: "",
    medicalType: "",
    specifymedical: "",
    medicalCond: "",
    class: "",
    classArm: "",

    homeAddress: "",
    hospitalNumber: "",
    hospitalAddress: "",
    alleg: "",
    allergyType: "",
    specificAllergy: "",
    bloodGroup: "",
    status: "",
  };

  const methods = useForm({
    resolver: yupResolver(EditStudentSchmema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const GENDER = [
    {
      value: "0",
      label: "Select Gender",
    },
    {
      value: "1",
      label: "Male",
    },
    {
      value: "2",
      label: "Female",
    },
  ];

  const MEDIC = [
    {
      value: "",
      label: "Any medical condition",
    },
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  const MEDIC_TYPE = [
    {
      value: "",
      label: "Select  you have any",
    },
    {
      value: "Cancer",
      label: "Cancer",
    },
    {
      value: "Diabetes",
      label: "Diabetes",
    },
    {
      value: "Heart disease",
      label: "Heart disease",
    },
    {
      value: "Conjunctivitis",
      label: "Conjunctivitis",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];

  const ALLEGS = [
    {
      value: "",
      label: "Allegeries",
    },
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  const TYPE_ALLEGS = [
    {
      value: "",
      label: "Select Allegergy",
    },
    {
      value: "Asthma",
      label: "Asthma",
    },

    {
      value: "Food allergy",
      label: "Food allergy",
    },
    {
      value: "Anaphylaxis",
      label: "Anaphylaxis",
    },

    {
      value: "Others",
      label: "Others",
    },
  ];

  const RELATIONS = [
    {
      value: "",
      label: "Choose relation type",
    },
    {
      value: 1,
      label: "Father",
    },
    {
      value: 2,
      label: "Mother",
    },
    {
      value: 3,
      label: "Guardian",
    },
  ];
  const STATUS = [
    {
      value: "0",
      label: "Choose Status",
    },
    {
      value: "1",
      label: "Active",
    },
    {
      value: "2",
      label: "Left",
    },
    {
      value: "3",
      label: "Graduated",
    },
  ];

  const BLOOD_GROUP = [
    {
      value: "0",
      label: "Choose blood group",
    },
    {
      value: "1",
      label: "A+",
    },
    {
      value: "2",
      label: "A-",
    },
    {
      value: "3",
      label: "B+",
    },
    {
      value: "4",
      label: "B-",
    },
    {
      value: "5",
      label: "AB+",
    },
    {
      value: "6",
      label: "AB-",
    },
    {
      value: "7",
      label: "O+",
    },
    {
      value: "8",
      label: "O-",
    },
  ];

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "Select Year Group",
    },
  ]);

  const [classArms, setClassArms] = useState([
    {
      id: "",
      className: "Select Class",
    },
  ]);

  const watchAlleg = watch("alleg");
  const watchAllergyType = watch("allergyType");

  const watchCondition = watch("medicalCond");
  const watchConditionType = watch("medicalCond");

  useEffect(() => {
    const getAllStudentProfile = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Students/GetStudentById?schoolId=${schoolId}&studentId=${studentId} `
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setValue("firstName", data.firstName);
        setValue("lastName", data.lastName);
        setValue("middleName", data.otherName);
        setValue("yearOfAdmission", data.yearOfAdmission);
        setValue("stateOfOrigin", data.stateOfOrigin);
        setValue("DOB", data.dateOfBirth);
        setValue("lga", data.lgaOfOrigin);
        setValue("hospitalName", data.hospitalAddressForEmergencies);
        setValue("gender", data.genderId);
        setValue("hospitalNumber", data.hospitalPhoneNumberForEmergencies);
        setValue("studentRegNo", data.registrationNumber);
        setValue("bloodGroup", data.bloodGroupId);
        setValue("alleg", data.doesStudentHaveAnyAllergy ? 1 : 0);
        setValue("allergyType", data.allergies);
        setValue("specificAllergy", data.specificAllergy);
        setValue("medicalCond", data.doesStudentHaveAnyMedicalCondition ? 1 : 0);
        setValue("medicalType", data.specificMedicalConditionName);
        setValue("specifymedical", data.specificMedicalCondition);
        setValue("homeAddress", data.homeAddress);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStudentProfile();
  }, [schoolId, studentId]);

  const onSubmit = async (data) => {
    const updatedStudentData = {
      schoolId,
      studentId,
      firstName: data.firstName,
      lastName: data.lastName,
      otherName: data.middleName,
      genderId: data.gender,
      homeAddress: data.homeAddress,
      dateOfBirth: new Date(data.DOB).toISOString(),
      yearOfAdmission: data.yearOfAdmission,
      stateOfOrigin: data.stateOfOrigin,
      lgaOfOrigin: data.lga,
      doesStudentHaveAnyAllergy: data.alleg === "1" ? true : false,
      allergies: data.allergyType === "Others" ? data.specificAllergy : data.allergyType,

      hospitalNameForEmergencies: data.hospitalName,
      hospitalNumberForEmergencies: data.hospitalNumber,
      hospitalAddressForEmergencies: data.hospitalAddress,

      doesStudentHaveAnyMedicalCondition: data.medicalCond === "1" ? true : false,
      medicalCondition:
        data.medicalType === "Others" ? data.specifymedical : data.medicalType,

      bloodGroupId: data.bloodGroup,
      registrationNumber: data.studentRegNo,
      statusId: data.status,
    };

    try {
      const response = await axoisCustomAgent.put(`Students/EditStudent`, {
        ...updatedStudentData,
      });

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading All Students...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Box display={"flex"} justifyContent={"space-around"}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField name="firstName" label="First Name" variant="filled" />
          </Grid>

          <Grid width={250}>
            <RHFTextField name="middleName" label="Middle Name" variant="filled" />
          </Grid>
          <Grid width={250}>
            <RHFTextField name="lastName" label="Last Name" variant="filled" />
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            <RHFTextField
              name="yearOfAdmission"
              label="Year Of Admission"
              variant="filled"
            />
          </Grid>

          <Grid width={250}>
            <RHFTextField name="stateOfOrigin" label="State Of Origin" variant="filled" />
          </Grid>
          <Grid width={250}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* <FormHelperText>Date Of Birth :</FormHelperText> */}
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label="Date of Birth "
                value={methods.watch().DOB}
                onChange={(value) => {
                  methods.setValue("DOB", value, {
                    shouldValidate: true,
                  });
                }}
                renderInput={(params) => (
                  <RHFTextField variant="filled" {...params} name="DOB" />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            {/* <FormHelperText>Name</FormHelperText> */}
            <RHFTextField name="lga" label="L G A" variant="filled" />
          </Grid>

          <Grid width={250}>
            <RHFTextField name="hospitalName" label="Hospital Name" variant="filled" />
          </Grid>
          <Grid width={250}>
            <RHFTextField
              select
              variant="filled"
              name="gender"
              required
              SelectProps={{ native: true }}
            >
              {GENDER.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            <RHFTextField
              name="hospitalNumber"
              variant="filled"
              label="Hospital Telephone"
            />
          </Grid>

          <Grid width={250}>
            <RHFTextField name="studentRegNo" label="Student ID" variant="filled" />
          </Grid>
          <Grid width={250}>
            <RHFTextField
              select
              name="bloodGroup"
              label="Select Blood Group"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {BLOOD_GROUP.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={250}>
            <RHFTextField
              select
              name="status"
              label="Student status"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {STATUS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>

          <Grid width={250}>
            <RHFTextField
              name="homeAddress"
              variant="filled"
              placeholder="Home Address"
              label="Home Address"
              multiline={true}
              minRows={3}
            />
          </Grid>
          <Grid width={250}>
            <RHFTextField
              name="hospitalAddress"
              placeholder="Hospital Address"
              label="Hospital Address"
              variant="filled"
              multiline={true}
              minRows={3}
            />
          </Grid>
        </Box>

        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          <Grid width={400}>
            <RHFTextField
              select
              name="alleg"
              required
              label="Do you have any allegeries"
              variant="filled"
              SelectProps={{ native: true }}
            >
              {ALLEGS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>

          <Grid width={400}>
            <RHFTextField
              select
              name="medicalCond"
              label="Do you have any medical conditions"
              variant="filled"
              required
              SelectProps={{ native: true }}
            >
              {MEDIC.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </RHFTextField>
          </Grid>
        </Box>
        <Box display={"flex"} justifyContent={"space-around"} mt={2}>
          {watchAlleg === "1" ? (
            <Grid width={400}>
              <RHFTextField
                select
                name="allergyType"
                variant="filled"
                label="Allergy type"
                required
                SelectProps={{ native: true }}
              >
                {TYPE_ALLEGS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
          ) : null}

          {watchAllergyType === "Others" ? (
            <Grid width={400}>
              <RHFTextField
                name="specificAllergy"
                label="Kindly specify the allergy*"
                variant="filled"
              />
            </Grid>
          ) : null}
        </Box>
        <Box>
          {watchCondition === "1" ? (
            <Grid width={250}>
              <RHFTextField
                select
                name="medicalType"
                variant="filled"
                required
                SelectProps={{ native: true }}
              >
                {MEDIC_TYPE.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
          ) : null}
        </Box>

        <Box display={"flex"}>
          {watchConditionType === "others" ? (
            <RHFTextField>
              <Grid
                name="specifymedical"
                label=" Kindly specify the medical condition"
                variant="filled"
              ></Grid>
            </RHFTextField>
          ) : null}
        </Box>

        {/* <Grid item sx={{ textAlign: "right", mt: 3 }}>
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              loading={isSubmitting}
            >
              Update
            </LoadingButton>
          </Grid> */}

        {/* ================================================================= */}
        {/* <Grid container spacing={5} display={"flex"}>
          <Grid padding={3}>
            <Grid item>
              <FormHelperText>First Name :</FormHelperText>
              <RHFTextField name="firstName" variant="outlined" />
            </Grid>
            <Grid item>
              <FormHelperText>Middle Name :</FormHelperText>
              <RHFTextField name="middleName" variant="outlined" />
            </Grid>
            <Grid item>
              <FormHelperText>Last Name :</FormHelperText>
              <RHFTextField name="lastName" variant="outlined" fullWidth />
            </Grid>
            <Grid item>
              <FormHelperText>Year Of Admission :</FormHelperText>
              <RHFTextField
                name="yearOfAdmission"
                // label="Year Of Admission"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormHelperText>State Of Origin :</FormHelperText>
              <RHFTextField
                name="stateOfOrigin"
                // label="State Of Origin"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormHelperText>Date Of Birth :</FormHelperText>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  // label="Date of Birth "
                  value={methods.watch().DOB}
                  onChange={(value) => {
                    methods.setValue("DOB", value, {
                      shouldValidate: true,
                    });
                  }}
                  renderInput={(params) => (
                    <RHFTextField {...params} name="DOB" />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item>
              <FormHelperText>LGA:</FormHelperText>
              <RHFTextField
                name="lga"
                // label="L G A"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid item mt={4}>
              <FormHelperText>Hospital Name :</FormHelperText>
              <RHFTextField name="hospitalName" variant="outlined" />
            </Grid>

            <Grid item>
              <FormHelperText>Gender :</FormHelperText>
              <RHFTextField
                select
                name="gender"
                required
                SelectProps={{ native: true }}
              >
                {GENDER.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item>
              <FormHelperText>hospital Telephone :</FormHelperText>
              <RHFTextField name="hospitalNumber" variant="outlined" />
            </Grid>

            <Grid item>
              <FormHelperText>Student ID :</FormHelperText>
              <RHFTextField name="studentRegNo" variant="outlined" />
            </Grid>
            <Grid item>
              <FormHelperText>Select Blood Group :</FormHelperText>
              <RHFTextField
                select
                name="bloodGroup"
                required
                SelectProps={{ native: true }}
              >
                {BLOOD_GROUP.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
          </Grid>
          <Grid padding={3}>
            <Grid item>
              <FormHelperText>Do You Have Any Allegeries :</FormHelperText>
              <RHFTextField
                select
                name="alleg"
                required
                SelectProps={{ native: true }}
              >
                {ALLEGS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>

            {watchAlleg === "1" ? (
              <Grid item mt={1}>
                <RHFTextField
                  select
                  name="allergyType"
                  required
                  SelectProps={{ native: true }}
                >
                  {TYPE_ALLEGS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </RHFTextField>
              </Grid>
            ) : null}

            {watchAllergyType === "Others" ? (
              <Grid item mt={1}>
                <RHFTextField
                  name="specificAllergy"
                  label="Kindly specify the allergy*"
                  variant="outlined"
                />
              </Grid>
            ) : null}

            <Grid item>
              <FormHelperText>
                Do You Have Any Medical Condition :
              </FormHelperText>
              <RHFTextField
                select
                name="medicalCond"
                required
                SelectProps={{ native: true }}
              >
                {MEDIC.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            {watchCondition === "1" ? (
              <Grid item mt={1}>
                <RHFTextField
                  select
                  name="medicalType"
                  required
                  SelectProps={{ native: true }}
                >
                  {MEDIC_TYPE.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </RHFTextField>
              </Grid>
            ) : null}
            {watchConditionType === "Others" ? (
              <Grid item mt={1}>
                <RHFTextField
                  name="specifymedical"
                  label="Kindly specify the medical condition*"
                  variant="outlined"
                />
              </Grid>
            ) : null}

            <Grid item>
              <FormHelperText>Status:</FormHelperText>
              <RHFTextField
                select
                name="status"
                required
                SelectProps={{ native: true }}
              >
                {STATUS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item>
              <FormHelperText>Home Address </FormHelperText>
              <RHFTextField
                name="homeAddress"
                placeholder="Home Address"
                variant="outlined"
                multiline={true}
                minRows={3}
              />
            </Grid>
            <Grid item>
              <FormHelperText>Hospital Address </FormHelperText>
              <RHFTextField
                name="hospitalAddress"
                placeholder="Hospital Address"
                variant="outlined"
                multiline={true}
                minRows={3}
              />
            </Grid>
            <Grid item sx={{ textAlign: "right", mt: 3 }}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid> */}
      </FormProvider>
    </Grid>
  );
}

export default EditStudent;
