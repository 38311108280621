import React, { Fragment,  } from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";

function AdminJourneyImportanceNote() {
  return (
    <Fragment>
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingInline: "20px",
        paddingTop: 5,
      }}
    >
      <Box textAlign={"center"}>
        <Box>
          <Grid>
            <img
              src={logoBg}
              alt=""
              style={{
                width: 400,
              }}
            />
          </Grid>
        </Box>
      </Box>

      <Container
        sx={{
          background: "#ffff",
          borderRadius: 0.5,
          boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
          mt: 5,
          minHeight: 800,
        }}
      >
        <Box mt={2}>
          <Typography variant="h6" color={"#FF0000"}>
            Important Notes about Setting up a Safety Management System (SMS).
          </Typography>
        </Box>

        <ol style={{ padding: "10px" }}>
          <li style={{ marginTop: 2 }}>
            <strong>
              {" "}
              The commitment of senior management is important:
            </strong>{" "}
            Setting up one of these systems takes between 12 and 24 months,
            and it could be another 18 months before you start to see results.
            Senior management commitment is necessary to keep the project
            going.
          </li>

          <li style={{ marginTop: 2 }}>
            <strong> Audit existing safety procedures and practices</strong>
            against those that will be required under the standard, so you
            know what to focus on first.
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>Set up an implementation team</strong>
            who can focus on the project and keep it moving.
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>
              Make sure roles, timelines and responsibilities are clearly
              outlined and tracked.
            </strong>
          </li>
          <li style={{ marginTop: 2 }}>
            <strong> All members of the school staff must be involved</strong>
            by offering communication, training, and incentives.
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>
              management system must be reviewed on an ongoing basis
            </strong>
            so that improvement becomes continuous.
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>
              SSLAG Framework guidelines for all the 20 MS understood
            </strong>
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>Designated Safety teams put in place</strong>
          </li>
          <li style={{ marginTop: 2 }}>
            <strong>
              Above all, the safety committee must be constituted:
            </strong>
            This committee must consist of the parent rep, teachers’ rep,
            students’ rep and a rep from each of the non-teaching staff teams
          </li>
        </ol>

        <Box>
          <Typography variant="h6" color={"#FF0000"}>
            SSLAG Framework listed mandatory items per school section
          </Typography>
        </Box>

        <ul>
          <li>2 copies of the compliance manuals</li>
          <li>4 copies of the Minimum standards guidelines’ posters</li>
          <li>6 copies of the Safe School Lagos Quick Guides</li>
          <li>10 High visibility Vests</li>
          <li>2 LSC Rule Books</li>
          <li>Fire extinguishers (1x 2kg hydrant cylinder per 200m2) </li>
          <li>
            Specific signs: exit signs (running man); in case of fire
            information; Fire Action, please wash your hands, first aid; fire
            door keep shut; wet floor sign; Lollipop Sign (Stop/GO);{" "}
          </li>
        </ul>
      </Container>
    </Grid>
  </Fragment>
  )
}

export default AdminJourneyImportanceNote