import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";

function RegisterSchoolModal({
  taskId,
  DeleteTask,
  updateAandEForTask,
  handlePageCloseAssign,
  educationalDistrict,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [assessors, setAssessors] = useState([]);

  useEffect(() => {
    let unmounted = false;
    const getAllAssessors = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/AandE/GetAllAandE/${educationalDistrict} `
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setAssessors([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllAssessors();
    return () => {
      unmounted = true;
    };
  }, []);


  // /AandE/GetAllAandE

  const AssignAuditTask = async (aandEId) => {
    try {
      const response = await axoisCustomAgentForAdmin.post(
        `AandE/AssignAuditTask`,
        {
          aandEId,
          taskId,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      let aeinfo = assessors.find((x) => x.id === aandEId);
      enqueueSnackbar(message, { variant: "success" });
      // DeleteTask(taskId);

      updateAandEForTask(taskId, aeinfo);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = assessors.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Middle Name",
      selector: (row) => row.middleName,
    },

    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      cell: (row) => (
        <Button variant="contained" onClick={() => AssignAuditTask(row.id)}>
          {" "}
          Assgin
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Typography variant="h4">Records of Assessors</Typography>
        <Card
          sx={{
            width: "96%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {assessors.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={assessors}
                filteredItems
                editModde="row"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Grid>
    </Fragment>
  );
}

export default RegisterSchoolModal;
