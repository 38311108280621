// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from "../../routes/paths";
// components
import { PATH_AFTER_LOGIN } from "../../config";
// components
import Iconify from "../../components/Iconify";
import Home from "@material-ui/icons/Home";
import Group from "@material-ui/icons/Group";
import People from "@material-ui/icons/People";
import Assessment from "@material-ui/icons/Assessment";
import School from "@material-ui/icons/School";
import Work from "@material-ui/icons/Work";
import Bullets from "@material-ui/icons/FiberManualRecordOutlined";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Home",
    icon: <Home color="primary" />,
    path: "/",
    // path: PATH_DOCS,

    children: [
      {
        items: [
          {
            isScroll: true,
            title: "Statement of Purpose",
            path: "statmentPurpose",
            // icon: <Bullets color="primary" />,
          },
          {
            isScroll: true,
            title: "Safety Ratings",
            path: "safetyRating",
            // icon: <Bullets color="primary" />,
          },
          {
            isScroll: true,
            title: "Foreword By Mr.Gov.",
            path: "gov",
            // icon: <Bullets color="primary" />,
          },
          {
            isScroll: true,
            title: "Theme Song",
            path: "themeSong",
            // icon: <Bullets color="primary" />,
          },
          {
            isScroll: true,
            title: "Inter-disciplinary  Collaborators",
            path: "InterDiscipilinary",
            // icon: <Bullets color="primary" />,
          },
          {
            title: "About Us",
            path: "/about-sslag",
            // icon: <Bullets color="primary" />,
          },
        ],
      },
    ],
  },
  {
    title: "Marketplace",
    icon: <Home color="primary" />,
    path: "/market-place",
  
  },

  {
    title: "Events",
    icon: <Home color="primary" />,
    // path: "/Event",
    path: "/",
    children: [
      {
        items: [
          {
            title: "BOS",
            path: "/BOS",
            // icon: <Bullets color="primary" />,
          },
          {
            title: "Advocacy",
            path: "/Advocacy",
            // icon: <Bullets color="primary" />,
          },
          {
            title: "Upcoming Events.",
            path: "/Event",
            // icon: <Bullets color="primary" />,
          },
          {
            title: "Gallery",
            path: "/Gallery",
            // icon: <Bullets color="primary" />,
          },
        ],
      },
    ],
  },
  //

  {
    title: "Request Safety Audit",
    path: "/initiativeNote",
    icon: <Home color="primary" />,
    // children: [
    //   {
    //     items: [
    //       {
    //         title: "Request Safety Audit",
    //         path: "/initiativeNote",
    //         icon: <Bullets color="primary" />,
    //       },
    //       {
    //         title: "Pay for Audit",
    //         path: "/pay-audit",
    //         icon: <Bullets color="primary" />,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    title: "Compliance Obligations",
    icon: <Home color="primary" />,
    path: "/complianceObligations",
  },
  {
    title: "Safe School League",
    icon: <Home color="primary" />,
    path: "/coming-soon",
  },
  // {
  //   title: "Gallery",
  //   icon: <Home color="primary" />,
  //   path: "/gallery",
  // },

  {
    title: "FAQs",
    icon: <Home color="primary" />,
    path: "/faqs",
  },
  {
    title: "Contact Us",
    icon: <Home color="primary" />,
    path: "/contact-us",
  },

  // {
  //   title: "Sign Up",
  //   path: "/auth/register",
  //   icon: <Iconify icon={"eva:file-fill"} {...ICON_SIZE} />,

  // },
];

export default menuConfig;
