import { Grid, Box, Typography, Button } from "@mui/material";
import React from "react";

function Certificateparticipation() {
  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Box paddingRight={8}>
          <Button
            href="/dashboard/user/documentaryEvidence"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Safety budget
          </Button>
          <Button
            href="/dashboard/user/certificateParticipation"
            className="header  "
            sx={{ color: "#6495ed" }}
          >
            Certificate of participation
          </Button>

          <Button
            href="/dashboard/user/safetyInfrastructure"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safety infrastructure
          </Button>

          <Button
            href="/dashboard/user/manadatorySupplies"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Mandatory supplies
          </Button>
          <Button
            href="/dashboard/user/safeInternet"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Safe internet
          </Button>
          <Button
            href="/dashboard/user/insurance"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Insurance
          </Button>
          <Button
            href="/dashboard/user/signages"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Signages
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

export default Certificateparticipation;
