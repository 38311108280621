import React, { useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Modal,
  Card,
  Dialog,
  DialogActions,
  CardContent,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";

function ManageBos() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [getcertificate, setGetcertificate] = useState([]);
  const [page, setPage] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [viewCertificate, setViewCertificate] = useState(undefined);

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const Gridimage = {
    background: "#fafafa",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const DialogStyles = {
    width: "80%",
  };

  const handlePageOpen = (certificate) => {
    setViewCertificate(certificate);
    setPage(true);
  };

  const handlePageClose = () => {
    setPage(false);
    setViewCertificate(undefined);
  };

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "Date Uploaded",
      selector: (row) => row.uploadedOn.split("T")[0],
    },

    {
      name: "View certificate",
      cell: (row) => (
        <Button variant="outlined" onClick={() => handlePageOpen(row)}>
          View
        </Button>
      ),
      // ),
    },
  ];

  useEffect(() => {
    let unmounted = false;
    const getAllSchoolCertificate = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/BosCertificate/GetBosCertificates`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setGetcertificate([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllSchoolCertificate();
    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const DownloadImage = async (base64ImageString, fileType) => {
    try {
      let extension = fileType.split("/")[1];
      let actualBase64String = base64ImageString.split(",")[1];
      const buffer = Buffer.from(actualBase64String, "base64");
      const blob = new Blob([buffer], { type: `${fileType}` });

      saveAs(blob, `BOS Certificate.${extension}`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  // const handleDownload = (base64ImageString, fileType) => {
  //   const link = document.createElement("a");
  //   link.href = `image/${fileType}`;
  //   link.download = `image/${fileType}`;
  //   link.click();
  // };

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Certificate ...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      ></Box>
      <Card>
        <Grid style={Gridimage} p={3}>
          {getcertificate.length === 0 ? (
            <Typography>No record found</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={getcertificate}
              editModde="row"
              subHeader
              //   subHeaderComponent={subHeaderComponentMemo}
              pagination
              //   paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>
      {/* ========= view certificate======================s */}
      <Modal
        open={page}
        onClose={handlePageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          >
            BOS Certificate
          </Typography>

          <Box display={"flex"} justifyContent={"right"}>
            <MenuItem
              onClick={async () =>
                await DownloadImage(
                  viewCertificate.file,
                  viewCertificate.fileType
                )
              }
              sx={{ color: "primary" }}
            >
              <Iconify icon={"eva:download-fill"} sx={{ ...ICON }} />
              Download
            </MenuItem>

            {/* <button onClick={async () => await handleDownload(viewCertificate.file, viewCertificate.fileType)}>Download</button> */}
          </Box>
          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            {/*  */}
            <Box m={2}>
              <Box>
                <img
                  alt="sslag certificate"
                  src={!viewCertificate ? "" : viewCertificate.file}
                  style={{
                    height: 510,
                    width: 800,
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </Box>

    // =================ssss========
  );
}

export default ManageBos;
