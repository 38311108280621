import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  CardContent,
  CardActions,
  Card,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
// _mock_
import { _homePlans } from "../../../_mock";
// components
import Iconify from "../../../components/Iconify";
import EventBannerOne from "../../../images/sslag-event4.jpg";
import logo from "../../../images/Group 129.png";
import { MotionInView, varFade } from "../../../components/animate";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import formatnaira from "format-to-naira";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2, 0),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function OptionalDeals() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [descriptionModal, setDescriptionModal] = React.useState(false);
  const [registrationmodal, setRegistrationModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isSchool, setIsSchool] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [event, setEvent] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [currentEventId, setCurrentEventId] = useState("");
  const [learnMoreEvent, setLearnMoreEvent] = useState(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  useEffect(() => {
    let unmounted = false;

    const getEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Marketplace/GetOptionalSupplies`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setEvent(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getEvent();

    return () => {
      unmounted = true;
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const option = [
    { label: "School", value: true },
    { label: "Individual", value: false },
  ];

  const defaultValues = {
    // new========================
    reason: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;
  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading optional deals...</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <RootStyle>
      <Container>
        <Box sx={{ mb: 10, textAlign: "center" }}>
          <MotionInView variants={varFade().inDown}>
            <Typography variant="h3" sx={{ mb: 3 }}></Typography>
          </MotionInView>

          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Grid container spacing={2}>
              {event.map((eachItem, index) => (
                <Grid item xs={4}>
                  <Card key={index} sx={{ maxWidth: 300 }}>
                    <CardActions>
                      <img
                        src={eachItem.image}
                        alt="sslag event"
                        style={{
                          height: 360,
                          width: 360,
                        }}
                      />
                    </CardActions>
                    <CardContent>
                      <Typography textAlign={"left"} fontSize={"17px"} pl={1}>
                        {eachItem.name}
                      </Typography>
                      <Box display={"flex"}>
                        <Typography textAlign={"left"}>
                          {formatnaira(eachItem.price)}
                        </Typography>
                      </Box>

                      <Grid
                        display={"flex"}
                        textAlign={"left"}
                        justifyContent={"space-between"}
                        pl={1}
                      >
                        <Typography>{eachItem.time}</Typography>
                      </Grid>
                      <Grid display={"flex"} justifyContent={"right"}>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleOnAttend(e, 1)}
                          endIcon={
                            <Iconify icon={"eva:checkmark-circle-2-fill"} />
                          }
                        >
                          Add
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </RootStyle>
  );
}
