import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Alert,
  Stack,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../components/Iconify";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import styleds from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import React from "react";
import Label from "../../../components/Label";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../components/hook-form";
import { MotionInView } from "../../../components/animate";
import { LoadingButton } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Location from "@material-ui/icons/LocationOn";
import DateIcon from "@material-ui/icons/CalendarToday";
import TimeIcon from "@material-ui/icons/AccessAlarm";
import Eye from "@material-ui/icons/RemoveRedEyeOutlined";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const TextField = styleds.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styleds(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

// ----------------------------------------------------------------------

export default function TodayEvent() {
  const [event, setAllEvent] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [currentConcession, setCurrentConcession] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const theme = useTheme();
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [newEvent, setNewEvent] = React.useState(false);
  const [descriptionModal, setDescriptionModal] = React.useState(false);
  const [registrationmodal, setRegistrationModal] = React.useState(false);
  const [currentEventId, setCurrentEventId] = useState("");
  const [learnMoreEvent, setLearnMoreEvent] = useState(null);
  const EventSchema = Yup.object().shape({
    image: Yup.mixed().required("field  is required"),

    name: Yup.string().required("field  is required"),
    eventTypeId: Yup.string().required("field  is required"),

    venue: Yup.string().required("field  is required"),
    description: Yup.string().required("field  is required"),
    date: Yup.string().required("field  is required"),
    time: Yup.string().required("field  is required"),
    // password: Yup.string().required("field  is required"),
  });

  const defaultValues = {
    // new========================
    image: null,
    name: "",
    eventTypeId: "",
    venue: "",
    description: "",
    date: "",
    time: "",
  };

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const EventType = [
    { label: "Select event type", value: 0 },
    { label: "BOS", value: 1 },
    { label: "CBC", value: 2 },
    { label: "Other ", value: 3 },
  ];

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "image",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  useEffect(() => {
    let unmounted = false;

    const getTodayEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Events/GetTodayEvents`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllEvent([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getTodayEvent();

    return () => {
      unmounted = true;
    };
  }, [id]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const handleOnAttend = (e, eventId) => {
    setCurrentEventId(eventId);
  };

  // const columns = [
  //   {
  //     name: "s/n",
  //     cell: (row, index) => <p>{index + 1}</p>,
  //   },
  //   {
  //     name: "Event Name",
  //     selector: (row) => row.name,
  //   },

  //   {
  //     name: "Description",
  //     selector: (row) => row.description,
  //   },

  //   {
  //     name: "Venue",
  //     selector: (row) => row.venue,
  //   },
  //   {
  //     name: "Date ",
  //     selector: (row) => row.date.split("T")[0],
  //     // .split("T")[0]
  //   },

  //   {
  //     name: "Status",
  //     cell: (row) => (
  //       <Label
  //         variant={theme.palette.mode === "light" ? "ghost" : "filled"}
  //         color={(row.isPaid && "success") || (!row.isPaid && "error")}
  //       >
  //         {row.isPaid ? sentenceCase("Paid") : sentenceCase("Not Paid")}
  //       </Label>
  //     ),
  //     // selector: (row) => row.status,
  //   },
  //   // {
  //   //   cell: (row) => (
  //   //     <>
  //   //       <Button
  //   //         variant="contained"
  //   //         // onClick={() => handleOpen(row.id)}
  //   //         onClick={() => handleClickOpen(row.id)}
  //   //       >
  //   //         {" "}
  //   //         Manages
  //   //       </Button>
  //   //     </>
  //   //   ),
  //   // },
  // ];

  const handleClickOpenRegistration = () => {
    setRegistrationModal(true);
  };

  const handleCloseRegistration = () => {
    setRegistrationModal(false);
  };
  const handleClickOpenDescription = (event) => {
    setDescriptionModal(true);
    setLearnMoreEvent(event);
  };

  const handleCloseDescription = () => {
    setDescriptionModal(false);
    setLearnMoreEvent(null);
  };

  const onSubmit = async (data) => {
    // console.log(data);

    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("venue", data.venue);
    formData.append("eventTypeId", data.eventTypeId);
    formData.append("date", new Date(data.date).toISOString());
    formData.append("time", new Date(data.time).toISOString().split("T")[1]);
    try {
      const response = await axoisCustomAgentForAdmin.post(
        "Events/AddEvent",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
   
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }

    window.location.reload();
  };

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Event...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%", minWidth: "100%" }}
      >
        <Grid>
          <Grid textAlign={"right"}>
            <Button
              variant="contained"
              onClick={handleClickOpenRegistration}
              startIcon={
                <Iconify icon={"eva:plus-fill"} width={20} height={20} />
              }
              // onClick={handleAddEvent}
            >
              Add Event
            </Button>
          </Grid>

          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Grid container spacing={2}>
              {event.map((eachItem, index) => (
                <Grid key={index} item xs={4}>
                  <Card sx={{ maxWidth: 400 }}>
                    <CardActions>
                      <img
                        src={eachItem.image}
                        alt="sslag event"
                        style={{
                          height: 360,
                          width: 360,
                        }}
                      />
                    </CardActions>

                    <CardContent>
                      <Typography textAlign={"left"} pl={1}>
                        {eachItem.name}
                      </Typography>

                      <Box display={"flex"}>
                        <Box>
                          <Location color="action" />
                        </Box>
                        <Typography textAlign={"left"}>
                          {eachItem.venue}
                        </Typography>
                      </Box>

                      <Grid
                        display={"flex"}
                        textAlign={"left"}
                        justifyContent={"space-between"}
                        pl={1}
                      >
                        <Box>
                          <Box>
                            <DateIcon color="primary" />
                          </Box>
                          <Typography>
                            {" "}
                            {eachItem.date.split("T")[0]}
                          </Typography>
                        </Box>
                        <Box>
                          <Box>
                            <TimeIcon color="secondary" />
                          </Box>{" "}
                          <Typography>{eachItem.time}</Typography>
                        </Box>
                      </Grid>

                      <Grid display={"flex"} justifyContent={"space-between"}>
                        <Button
                          onClick={() => handleClickOpenDescription(eachItem)}
                          size="small"
                        >
                          Learn More
                        </Button>

                        <Button
                          variant="contained"
                          href={`/admin/event/manage-attendence/${eachItem.id}`}
                          // onClick={(e) => handleOnAttend(e, 1)}
                          endIcon={
                            // <Iconify icon={"eva:checkmark-circle-2-fill"} />
                            <Eye />
                          }
                        >
                          Attendance
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        <Dialog open={registrationmodal} onClose={handleCloseRegistration}>
          <DialogTitle> Add Event </DialogTitle>
          <DialogContent>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <DialogContent
                sx={{
                  mt: 3,
                }}
              >
                <DialogContentText>
                  To add an Event, please enter the following information below
                  .
                </DialogContentText>

                <Box>
                  <Stack spacing={1} m={3}>
                    {!!errors.afterSubmit && (
                      <Alert severity="error">
                        {errors.afterSubmit.message}
                      </Alert>
                    )}
                  </Stack>

                  <Stack spacing={3}>
                    <MotionInView variants={varFade().inUp}>
                      <RHFTextField fullWidth label="Event Name*" name="name" />
                    </MotionInView>
                    <MotionInView variants={varFade().inUp}>
                      <RHFTextField
                        select
                        name="eventTypeId"
                        required
                        variant="filled"
                        SelectProps={{ native: true }}
                        // value={DistrictId}

                        sx={{
                          width: 300,
                        }}
                      >
                        {EventType.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </RHFTextField>
                    </MotionInView>
                    <MotionInView variants={varFade().inUp}>
                      <RHFTextField fullWidth label="Venue*" name="venue" />
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            label="Date "
                            disablePast
                            value={methods.watch().date}
                            onChange={(value) => {
                              methods.setValue("date", value, {
                                shouldValidate: true,
                              });
                            }}
                            renderInput={(params) => (
                              <RHFTextField
                                variant="filled"
                                {...params}
                                name="date"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            label="Event time "
                            value={methods.watch().time}
                            onChange={(value) => {
                              methods.setValue("time", value, {
                                shouldValidate: true,
                              });
                            }}
                            renderInput={(params) => (
                              <RHFTextField
                                variant="filled"
                                {...params}
                                name="time"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <RHFTextField
                        fullWidth
                        label="Description..."
                        name="description"
                        multiline
                        rows={4}
                      />
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <span>Upload image for event</span>

                      <Box sx={{ border: "1px dashed #333", p: 2 }}>
                        <Typography variant="body2">
                          Maximum file size: 1mb,jpeg,png
                        </Typography>
                        <Typography variant="body2">
                          file type: jpeg, png
                        </Typography>
                        <RHFUploadSingleFile
                          name="image"
                          accept="image/*"
                          maxSize={1024000}
                          onDrop={handleDrop}
                        />
                      </Box>
                    </MotionInView>
                  </Stack>
                </Box>
              </DialogContent>

              <MotionInView variants={varFade().inUp}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </MotionInView>
            </FormProvider>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        <Dialog open={descriptionModal} onClose={handleCloseDescription}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box display={"flex"} justifyContent={"center"} p={2}>
                <Box>
                  <img
                    src={learnMoreEvent?.image}
                    alt="sslag event"
                    style={{
                      height: 360,
                      width: 400,
                      p: 3,
                    }}
                  />
                </Box>
              </Box>
              <Box pl={2}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Typography color={"blue"}>Topic: </Typography>
                    <Typography> {learnMoreEvent?.name}</Typography>
                    <Box>
                      <Typography color={"blue"}>Description :</Typography>
                      <Typography fontSize="16px">
                        {learnMoreEvent?.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </Container>
    </RootStyle>
  );
}
