import {
  Box,
  Card,
  CardHeader,
  Stack,
  Grid,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../components/Iconify";
import { styled } from "@mui/material/styles";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import Label from "../../components/Label";
import { Fragment } from "react";

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

function AdminDashboard3({ newAdmin }) {
  const theme = useTheme();

  const Gridimage = {
    background: "#edf5f9",
    borderRadius: "10px",
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Admin Role",
      selector: (row) => row.adminRole,
    },
    {
      name: "Email Confirmed",
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.isEmailConfirmed && "success") ||
            (!row.isEmailConfirmed && "error")
          }
        >
          {row.isEmailConfirmed ? sentenceCase("True") : sentenceCase("False")}
        </Label>
      ),
      // selector: (row) => row.isEmailConfirmed,
    },
    {
      name: "Status",
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.isAccountActive && "success") ||
            (!row.isAccountActive && "error")
          }
        >
          {row.isAccountActive ? sentenceCase("True") : sentenceCase("False")}
        </Label>
      ),
      // selector: (row) => row.isAccountActive,
    },
  ];
  return (
    <Fragment>
      <Typography variant="h4">Admin Dashboard </Typography>
      <Card
        style={{
          background: "#edf5f9",
        }}
      >
        <CardHeader />
        <CardActions sx={{ justifyContent: "space-between" }}>
          {/* <Typography variant="h6">User Management</Typography> */}
          <Grid>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  borderRadius: "3px",
                  background: "018fe2",
                  color: "#fff",
                }}
              >
                <Button
                  size="small"
                  style={{ color: "#1396e3" }}
                  href="/admin/users/createAdmins"
                >
                  Create User
                </Button>
              </Box>

              <Button
                size="small"
                style={{ color: "#1396e3" }}
                href="/admin/users/createAdmins"
              >
                View
              </Button>
            </Box>
          </Grid>
        </CardActions>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Grid>
            <Grid style={Gridimage}>
              <DataTable
                columns={columns}
                data={newAdmin}
                subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                // pagination
                // paginationResetDefaultPage={resetPaginationToggle}
                responsive
              />
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Fragment>
  );
}

export default AdminDashboard3;
