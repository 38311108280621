import PropTypes, { element } from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  List,
  Drawer,
  Button,
  Divider,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
// redux
import { useSelector } from "../../../redux/store";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
// import { NAVBAR } from "../../../config";
// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import { SkeletonMailSidebarItem } from "../../../components/skeleton";
//
import { PATH_DASHBOARD } from "../../../routes/paths";
import MailSidebarItem from "./MailSidebarItem";

// ----------------------------------------------------------------------

MailSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenCompose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

const NAVBAR = {
  BASE_WIDTH: 300,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export default function MailSidebar({
  isOpenSidebar,
  onOpenCompose,
  onCloseSidebar,
  interventions,
}) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "md");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenCompose = () => {
    onCloseSidebar();
    onOpenCompose();
  };

  const renderContent = (
    <Scrollbar>
      <Box sx={{ p: 3, height: 84 }}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          // onClick={handleOpenCompose}
       
          disabled
        >
          Intervention
        </Button>
      </Box>

      <Divider />

      <List disablePadding>
        {(interventions.length > 0 ? interventions : [...Array(8)]).map(
          (element, index) =>
            element ? (
              <SidebarItem
                key={element.conversationId}
                intervention={element}
              />
            ) : (
              <SkeletonMailSidebarItem key={index} />
            )
        )}
      </List>
    </Scrollbar>
  );



  return (
    <>
      {isDesktop ? (
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: { width: NAVBAR.BASE_WIDTH, position: "relative" },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: NAVBAR.BASE_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

const linkToConversationMessages = (intervention) => {
  const baseUrl = PATH_DASHBOARD.mail.root;
  if (intervention.conversationId !== "") {
    return `${baseUrl}/${intervention.conversationId}`;
  }
  return baseUrl;
};

function SidebarItem({ intervention, ...other }) {
  const isUnread = intervention.unreadMessages > 0;

  return (
    <ListItemButton
      to={linkToConversationMessages(intervention)}
      component={RouterLink}
      sx={{
        px: 3,
        height: 48,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        "&.active": {
          color: "text.primary",
          fontWeight: "fontWeightMedium",
          bgcolor: "action.selected",
        },
      }}
      {...other}
    >
      <ListItemText disableTypography primary={intervention.alertMessage} />

      {isUnread && (
        <Typography variant="caption">{intervention.unreadMessages}</Typography>
      )}
    </ListItemButton>
  );
}
