import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import RegisterSchoolModal from "./RegisterSchoolModal";

// const TextField = styled.input`
//   height: 32px;
//   width: 200px;
//   border-radius: 3px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   border: 1px solid #e5e5e5;
//   padding: 0 32px 0 16px;
//   0 &:hover {
//     cursor: pointer;
//   }
// `;
// const ClearButton = styled(Button)`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const FilterComponent = ({ filterText, onFilter, onClear }) => (
//   <>
//     <TextField
//       id="search"
//       type="text"
//       placeholder="Filter By Name"
//       aria-label="Search Input"
//       value={filterText}
//       onChange={onFilter}
//     />
//     <ClearButton type="button" onClick={onClear}>
//       X
//     </ClearButton>
//   </>
// );
function AdminAuditRegisterSchool() {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const [task, setTask] = useState([]);
  const [currentTaskId, setCurrentTaskId] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    let unmounted = false;
    const getAllTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetAuditTasks?status=${1}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllTask();
    return () => {
      unmounted = true;
    };
  }, []);




  const DeleteTask = (taskId) => {
    const newTask = [...task];
    const updatedTask =newTask.filter((x)=>x.id!== taskId)
    
    setTask(updatedTask)
  }

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [auditInvoice, setAuditInvoice] = useState([]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = auditInvoice.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "School Name",
      selector: (row) => row.school.schoolName,
    },
    {
      name: "Date Created",
      selector: (row) => row.dateCreated.split("T")[0],
    },

    {
      name: "Audit Date",
      selector: (row) => row.dateOfAudit,
    },

    {
      name: "Payment Status",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(row.invoice.paymentStatus ? "paid" : "not paid")}
        </Label>
      ),
    },
    {
      cell: (row) => (
        <Button
          variant="contained"
          onClick={() => handlePageOpen(row.id)}
        >
          {" "}
          Assign
        </Button>
      ),
    },
  ];

  const [page, setPage] = React.useState(false);
  const handlePageOpen = (taskId) => {
    setCurrentTaskId(taskId);
    setPage(true);
  };
  const handlePageClose = () => {
    setCurrentTaskId("");
    setPage(false);
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Typography variant="h4">
          Schools to be assigned to Assessors.
        </Typography>
        <Card
          sx={{
            width: "80%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {task.length === 0 ? (
              <Typography>No record found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={task}
                filteredItems
                editModde="row"
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>

        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            ></Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <RegisterSchoolModal taskId={currentTaskId}
                DeleteTask={ DeleteTask} />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Fragment>
  );
}

export default AdminAuditRegisterSchool;
