import {
  Container,
  Grid,
  Box,
  Typography,
  Modal,
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../../images/background.png";
import LagosLogo from "../../../images/sslag.png";
import LscLogo from "../../../images/lagosLogo_old.jpeg";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

function InitiativePage() {
  const [open, setOpen] = React.useState(false);
  const [isPrivate, setIsPrivate] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const navigateToPublic = () => {
    // navigate to public url
    navigate(`/publicAudit`);
  };

  const navigateToPrivate = () => {
    // navigate to Private url
    navigate(`/privateAudit`);
  };

  const chooseNavigation = () => {
    if (isPrivate === null) {
    } else if (isPrivate) {
      navigateToPrivate();
    } else if (!isPrivate) {
      navigateToPublic();
    }
  };

  const today = new Date();
  const todayDate = today.toDateString();
  const option = [
    { label: "Government School", value: true },
    { label: "Private School", value: false },
  ];
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 6,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Grid p={3}>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            padding: 2,
            minHeight: 600,
            width: "60%",
          }}
        >
          <Box display={"flex"} justifyContent={"center"} p={2}>
            <img
              src={LagosLogo}
              alt=""
              style={{
                width: 160,
              }}
            />
          </Box>

          <Box mt={2} display={"flex"} justifyContent={"space-between"}>
            <Typography>Dear Stakeholder,</Typography>
            <Typography>{todayDate}</Typography>
          </Box>

          <Grid display={"flex"} justifyContent={"center"} mt={3}>
            <Typography variant="h6">
              <u>
                P-001 Notice of Annual Safety Audit for <br /> Issuance of the
                Safety License for Schools:
              </u>
            </Typography>
          </Grid>

          <Box mt={2}>
            <Typography variant="body2">
              You will be aware of the Lagos State Safety Commission’s
              responsibility to undertake formal annual safety audits of all
              schools in the State. If you decide to complete your audit
              exercise booking online, after payment you will receive a copy of
              the audit notification letter from the DG/CEO of the LSC for the
              said purpose and a payment receipt.
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                The payment must be made before the audit exercise. Please note
                that if any payment relating to this exercise is outstanding
                after the audit has been conducted, SSLAG is not obliged to send
                a post-audit report needed for the LSC to issue your statutory
                safety license
              </span>{" "}
              . Kindly pay into the appropriate accounts as stated on the letter
              and invoice or if paying online, the payment is automatically
              processed appropriately. Please note that the Day section is
              considered separate from the boarding section. As such a notice of
              audit will be sent soon for this if applicable.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="h6">PURPOSE OF THE AUDIT</Typography>
            <Typography variant="body2">
              The Audit process identifies what is good about the management of
              safety in the school and what needs to be improved. This
              contributes towards creating a safety culture and compliance in
              schools for the issuance of the
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginLeft: 2,
                }}
              >
                Annual Safety License to operate. As from Sept 2023 all schools
                must obtain a minimum SSLAG Standards to be issue a safety
                license which is a prerequisite for schools annual MOE renewal.
              </span>
              <span
                style={{
                  marginLeft: 2,
                }}
              >
                As such, schools are measured against the 20 Minimum standards
                as summarised in the SSLAG MS guidelines poster. Each of the
                standards will be observed and assessed. The principles of the
                safety management systems used for this audit are in accordance
                with the SSLAG Framework V0.1 of 2022, Compliance Manual
                “Principles of Safe Practices",
              </span>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">
              VALIDATION OF ASSESSORS & EVALUATORS (A&Es)
            </Typography>
            <Typography variant="body2">
              The A&Es assigned to carry out the audit exercise must produce a
              valid identification and produce a security code that syncs with
              the code sent earlier to the school. You may also contact the
              office of the Director General, Lagos State Safety Commission
              (LSC) via
              <span
                style={{
                  marginLeft: 2,
                }}
              >
                <a href="mailto:info@lasgsafety.com">info@lasgsafety.com</a>
              </span>
            </Typography>
          </Box>
         
          <Box mt={4}>
            <Typography>Your sincerely,</Typography>
            <Typography> Bisi Esuruoso PhD.</Typography>
            <Typography>SSLAG, Lead Resource.</Typography>
          </Box>
          <Grid p={3}>
            <Grid item sx={{ textAlign: "right" }}>
              <LoadingButton
                type="submit"
                color="secondary"
                // href="/bookAudit"
                onClick={handleOpen}
                variant="contained"
                sx={{
                  width: 160,
                }}
              >
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
        </Grid>
        
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please select category of school
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <Box>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="pubSchool"
                    control={<Radio />}
                    label="Government School"
                    onChange={() => setIsPrivate(false)}
                  />
                  <FormControlLabel
                    value="priSchool"
                    control={<Radio />}
                    label="Private School"
                    onChange={() => setIsPrivate(true)}
                  />
                </RadioGroup>
              </FormControl>

              <Grid item sx={{ textAlign: "right" }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  onClick={chooseNavigation}
                  variant="contained"
                  sx={{
                    width: 160,
                  }}
                >
                  Continue
                </LoadingButton>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default InitiativePage;
