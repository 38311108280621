import { Grid, Container, Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AdminBG from "../../../images/background.png";
import React, { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useParams } from "react-router";
import logoBg from "../../../images/loogoo.png";
import { PATH_AUTH } from "../../../routes/paths";

function AuditClerkCongrat() {
  let { reference } = useParams();
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            // mt: 14,
            minHeight: 200,
            width: "46%",
          }}
        >
          <Box display={"flex"} justifyContent={"center"} mt={3}>
            <img
              src={logoBg}
              alt=""
              style={{
                width: 200,
              }}
            />
          </Box>
          <Box>
            <Typography variant="h4" color={"primary"} textAlign={"center"}>
              SUCCESS !
            </Typography>
            <Typography textAlign={"center"} p={2}>
              You have successfully completed the audit task.
              <span
                style={{
                  color: "#00A36C",
                }}
              >
                {" "}
                {/* {reference} */}
              </span>{" "}
            </Typography>
          </Box>
          <Grid textAlign={"center"} p={2}>
            <Button
              size="large"
              variant="contained"
              href="auditClerks/AuditTask"
              sx={{ mt: 5 }}
            >
              Back
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AuditClerkCongrat;
