import { noCase } from "change-case";
// _mock
import _mock from "./_mock";
import { randomNumberRange, randomInArray } from "./funcs";

// ----------------------------------------------------------------------

export const _appRelated = [
  "Chrome",
  "Drive",
  "Dropbox",
  "Evernote",
  "Github",
].map((appName, index) => ({
  id: _mock.id(index),
  name: appName,
  system: (index === 2 && "Windows") || (index === 4 && "Windows") || "Mac",
  price:
    index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
  rating: _mock.number.rating(index),
  review: randomNumberRange(999, 99999),
  shortcut: `https://minimal-assets-api.vercel.app/assets/icons/ic_${noCase(
    appName
  )}.svg`,
}));

export const _appInstalled = ["de", "en", "fr", "kr", "us"].map(
  (country, index) => ({
    id: _mock.id(index),
    name: ["Germany", "England", "France", "Korean", "USA"][index],
    android: randomNumberRange(999, 99999),
    windows: randomNumberRange(999, 99999),
    apple: randomNumberRange(999, 99999),
    // flag: `https://minimal-assets-api.vercel.app/assets/icons/ic_flag_${country}.svg`,
  })
);

export const _appAuthors = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  favourite: randomNumberRange(9999, 19999),
}));

export const _appInvoices = [...Array(5)].map((_, index) => ({
  id: `${Date.now() + index}`,
  price: _mock.number.price(index),
  category: randomInArray(["Android", "Mac", "Windows"]),
  status: randomInArray(["paid", "out_of_date", "in_progress"]),
}));

export const _appFeatured = [...Array(3)].map((_, index) => ({
  id: _mock.id(index),
  title: ["Gov. Mr Babajide Sanwo-Olu", "SSLAG latest news", "SSLAG rules"][
    index
  ],
  description: _mock.text.title(index),
  image: _mock.image.feed(index),
}));


const sslagImage = [
  {
    id: 1,
    title: "Governor message",
    description: "",

    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667549913/Views/gov_message_yzalxo.jpg",
  },
  {
    id: 2,
    title: "Implementation team",
    description: "",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667549912/Views/implementation_team_id4zzy.jpg",
  },
  {
    id: 3,
    title: "Safe schools lagos ",
    description: "",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666956428/Views/Visual_4_os2fve.jpg",
  },
  {
    id: 4,
    title: "Beacon of safety ",
    description: "",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667549913/Views/beacon_of_safety_wvnvkv.jpg",
  },
  // {
  //   id: 5,
  //   title: "SSLAG award",
  //   description: "",
  //   image:
  //     "https://res.cloudinary.com/peax3/image/upload/v1667549912/Views/sslag_award_ldt5il.jpg",
  // },
  {
    id: 5,
    title: "All on board",
    description: "",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667549912/Views/all_on_board1_bq0dmv.jpg",
  },
  {
    id: 6,
    title: "All on board",
    description: "",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667549912/Views/all_on_board2_fojtrx.jpg",
  },
 
];
export default sslagImage;