import React, { useState, useEffect, Fragment } from "react";

import { Grid, Typography, Box, Checkbox, Paper, Button } from "@mui/material";

import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Label from "src/components/Label";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { method } from "lodash";

function Outservice() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    userData: { schoolId },
  } = useAuth();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingEquipments, setLoadingEquipments] = useState(false);
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    let unmounted = false;

    const getAllEquipments = async () => {
      setLoadingEquipments(true);
      try {
        const response = await axoisCustomAgent.get(
          `SchoolChecklist/GetChecklistSM202/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setEquipment([...data.equipment]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingEquipments(false);
      }
    };

    if (schoolId !== null) getAllEquipments();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const handleChange = (e, index) => {
    let customQuestions = [...equipment];
    customQuestions[index].doesSchoolHaveEquipment = e.target.checked;
    setEquipment([...customQuestions]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(equipment);
    setLoadingSubmit(true);
    try {
      const response = await axoisCustomAgent.post(
        `SchoolChecklist/SubmitChecklistSM202/${schoolId}`,
        [...equipment]
      );

      if (response.status === 500) {
        throw new Error("Server Error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, { variant: "Checklist submitted successfully" });
      // method.reset()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingSubmit(false);
    }
  };
  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };

  if (loadingEquipments) {
    return (
      <Box
        minHeight={"100vh"}
        // display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> Checklist</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/checklist"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            CJ-SM201
          </Button>

          <Button
            href="/dashboard/user/outsourceService"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            CJ-SM202
          </Button>
          <Button
            href="/dashboard/user/riskAssessment"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            CJ-R001A
          </Button>
          {/* <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-SM203
          </Button>
          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R002
          </Button>
          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R003
          </Button> */}
        </Box>
      </Box>
      <Grid>
        <Box
          sx={{
            background: "#34a8e1",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            color: "#ffff",
            minHeight: 40,
          }}
        >
          <Typography variant="h6" textAlign={"center"} padding={2}>
            HEALTH AND SAFETY CHECKLIST FOR CLASSROOMS
          </Typography>
        </Box>
        {/* <Typography color={"#6495ed"} mt={2}>
          OUTDOOR PLAY EQUIPMENT SAFETY CHECKLIST
        </Typography> */}
      </Grid>

      <Grid
        container
        style={Gridimage}
        mt={2}
        padding={2}
        justifyContent={"center"}
        // alignItems={"center"}
      >
        <Paper sx={{ width: 400, padding: 5, height: "400px" }}>
          {equipment.length === 0 ? (
            <Typography>No questions</Typography>
          ) : (
            <form onSubmit={async (e) => await onSubmit(e)}>
              {equipment
                // .filter((item) => item.focusAreaId === 1)
                .map((eachEquipment, index) => (
                  <Grid container key={index}>
                    <Grid
                      item
                      xs={6}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Grid>
                        <Label>{eachEquipment.name}</Label>
                      </Grid>

                      <Grid>
                        <Checkbox
                          checked={eachEquipment.doesSchoolHaveEquipment}
                          onChange={(e) => handleChange(e, index)}
                        >
                          {" "}
                        </Checkbox>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              <Grid item sx={{ mt: 3, textAlign: "right" }}>
                <LoadingButton
                  loading={loadingSubmit}
                  label="next"
                  variant="outlined"
                  type="submit"
                >
                  Sumbit
                </LoadingButton>
              </Grid>
            </form>
          )}
        </Paper>
      </Grid>
    </Fragment>
  );
}

export default Outservice;
