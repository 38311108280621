import { FormControl, FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { Stack, TextField, Button, Box, Grid } from "@mui/material";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

function DirectorForm({ addDirector }) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    imageUrl: "",
    imageId: "",
  });

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      state.name.trim() === "" ||
      state.email.trim() === "" ||
      state.phoneNumber.trim() === ""
    ) {
      enqueueSnackbar("Please fill all available fields", { variant: "error" });
    } else {
      addDirector(state);
      setState({
        name: "",
        email: "",
        phoneNumber: "",
        imageUrl: "",
        imageId: "",
      });
    }
  };

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;
          setState({
            ...state,
            imageId: imageId,
            imageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            // mt: 2,
            mt: 3,
          }}
        >
          <Box>
            <TextField
              label="FullName"
              variant="filled"
              value={state.name}
              onChange={(e) => handleChange(e, "name")}
            />
          </Box>
          <Box ml={2}>
            <TextField
              label="Email"
              type="email"
              variant="filled"
              value={state.email}
              onChange={(e) => handleChange(e, "email")}
            />
          </Box>
          <Box ml={2}>
            <TextField
              label="Phone Number"
              type="number"
              variant="filled"
              value={state.phoneNumber}
              onChange={(e) => handleChange(e, "phoneNumber")}
            />
          </Box>
        </Box>
        <FormHelperText>
          Upload evidence of SSLAG fit person check below (5MB maximum size)
        </FormHelperText>
        <div
          style={{
            border: "1px solid grey",
            width: "400px",
            marginTop: "10px",
            padding: "6px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <div>
            <FormHelperText>
              Upload SSLAG evidence below (5MB maximum size)e.g PNG,JPEG,PDF
            </FormHelperText>
            <input
              type="file"
              onChange={(event) => {
                setImageSelected(event.target.files[0]);
              }}
            />
            <button
              onClick={async () => await uploadImage()}
              disabled={imageUploading || state.imageUrl.length > 0}
            >
              Upload Evidence
            </button>
          </div>
        </div>
        <Box mt={2}>
          <Button variant="contained" onClick={handleSubmit}>
            ADD
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default DirectorForm;
