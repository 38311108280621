import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Alert,
  Stack,
  Card,
  CardActions,
  CardContent,
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../components/Iconify";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import styleds from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import React from "react";
import Label from "../../../components/Label";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import {
  FormProvider,
  RHFTextField,
  RHFUploadSingleFile,
} from "../../../components/hook-form";
import { MotionInView } from "../../../components/animate";
import { LoadingButton } from "@mui/lab";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EventBannerOne from "../../../images/sslag-event2.jpg";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

const TextField = styleds.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styleds(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Id"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

// ----------------------------------------------------------------------

export default function ManageEvent() {
  const [eventAttendance, setAllEventAttendence] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [currentConcession, setCurrentConcession] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const theme = useTheme();

  let { eventId } = useParams();
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  useEffect(() => {
    let unmounted = false;

    const getAllAttendees = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Events/GetAttendees?eventId=${eventId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllEventAttendence([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null) getAllAttendees();

    return () => {
      unmounted = true;
    };
  }, [id]);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };
  const filteredItems = eventAttendance.filter(
    (item) =>
      item.identification &&
      item.identification.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "Fullname",
      selector: (row) => row.fullName,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Register As",
      selector: (row) => row.registeredAs,
    },
    {
      name: "Id ",
      selector: (row) => row.identification,
      // .split("T")[0]
    },
    {
      name: "Status ",
      selector: (row) => row.status,
      // .split("T")[0]
    },

    {
      cell: (row, index) => (
        <>
          <Button
            disabled={row.status === "present"}
            variant="contained"
            onClick={() => handleClickOpen(row.id, index)}
          >
            Present
          </Button>
        </>
      ),
    },
  ];

  const MarkAsPresent = async (attendeeId, index) => {
    try {
      const response = await axoisCustomAgentForAdmin.get(
        `/Events/MarkAttendeeAsPresent?attendeeId=${currentAttendee.id}&eventId=${eventId}`
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      let modifyEventAttenance = [...eventAttendance];
      modifyEventAttenance[currentAttendee.index].status = "present";
      setAllEventAttendence([...modifyEventAttenance]);
      handleClickClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  const [currentAttendee, setCurrentAttendee] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (attendeeId, attendeeIndex) => {
    setCurrentAttendee({
      id: attendeeId,
      index: attendeeIndex,
    });
    setOpen(true);
  };

  const handleClickClose = () => {
    setCurrentAttendee(undefined);
    setOpen(false);
  };

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Records...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%", minWidth: "100%" }}
      >
        <Grid mt={10}>
          <Box>
            {eventAttendance.length === 0 ? (
              <Typography variant="h4" textAlign={"center"}>
                No Records
              </Typography>
            ) : (
              <DataTable
                columns={columns}
                // data={eventAttendance}
                data={filteredItems}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Box>
        </Grid>
      </Container>

      {/*  */}

      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Attendance
        </DialogTitle>
        <DialogContent
          sx={{
            mt: 3,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Do you want to mark as present.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickClose}>No</Button>
          <Button onClick={MarkAsPresent} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
