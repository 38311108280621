import React, { useState } from "react";
import { data } from "../../../_mock/data-mock";
import styled from "styled-components";


function SchoolEvent() {
  const [timetable, setTimetable] = useState(data);

  const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
  `;

  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 28px;
  `;
  const TH = styled.th`
    background-color: rgb(117, 201, 250);
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
    
  `;

  const TD = styled.td`
    background-color: rgb(205, 235, 253);
    font-size: small;
    border: 1px solid #ffffff;
    text-align: left;
    padding: 8px;
    
  `;

  return (
    <AppContainer >
      <Table>
        <thead>
          <tr>
            <TH>S/N</TH>
            <TH>Course description</TH>
            <TH>Code</TH>
            <TH>Modules</TH>
            <TH>Date</TH>
            <TH>Target part</TH>
            <TH>Venue</TH>
            <TH>Time</TH>
          </tr>
        </thead>
        <tbody>
          {timetable.map((eachData, index) => (
            <tr key={index}>
              <TD >{eachData.index}</TD>
              <TD >{eachData.courseDesc}</TD>
              <TD>{eachData.code}</TD>
              <TD >{eachData.modules}</TD>
              <TD >{eachData.date}</TD>
              <TD>{eachData.targetPart}</TD>
              <TD >{eachData.venue}</TD>
              <TD>{eachData.time}</TD>
            </tr>
          ))}
        </tbody>
      </Table>
    </AppContainer>
  );
}

export default SchoolEvent;
