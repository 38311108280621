import React, { Fragment,useState } from "react";
import { Grid, Box, Typography, Container, Stack, Alert } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
function InsetBookingForm() {

  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitted, setFormSubmitted] = useState(false);



  const defaultValues = {
    schoolName: "",
    schoolAddress: "",
    schoolEducationDistrict: "",
    schoolPhoneNumber: "",
    schoolEmailAddress: "",
    invoiceTo: "",
    courseTitle: "",
    invoicetotalNumOfStaffTo: "",
    totalNumOfStudentsOnRoll: "",
  };
  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitInsetBookingInterventionForm`,
        {
          schoolId,
          token,
          schoolName: data.schoolName,
          schoolAddress: data.schoolAddress,
          schoolEducationDistrict: data.schoolEducationDistrict,
          schoolPhoneNumber: data.schoolPhoneNumber,
          schoolEmailAddress: data.schoolEmailAddress,
          invoiceTo: data.invoiceTo,
          courseTitle: data.courseTitle,
          invoicetotalNumOfStaffTo: data.invoicetotalNumOfStaffTo,
          totalNumOfStudentsOnRoll: data.totalNumOfStudentsOnRoll,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
          }}
        >
          <Grid>
            <Box textAlign={"center"}>
              <Box>
                <Grid>
                  <img
                    src={logoBg}
                    alt=""
                    style={{
                      width: 400,
                    }}
                  />
                </Grid>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "#6495ED",
                  mt: 2,
                }}
              >
                Compliance Journey Station 5 Safety INSET Booking Form
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 800,
          }}
        >
          <Box>
            <Typography>Date:</Typography>
          </Box>
          <Grid>
            <Box
              sx={{
                background: "#6495ED",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                SCHOOL DETAILS
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="schoolName"
                  label="Name"
                  variant="filled"
                  sx={{
                    marginBlock: 2,
                    minWidth: 500,
                  }}
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="schoolAddress"
                  label="Address"
                  sx={{
                    marginBlock: 2,
                    minWidth: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="schoolEducationDistrict"
                  label="Educational district"
                  sx={{
                    marginBlock: 2,
                    minWidth: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="schoolPhoneNumber"
                  label="Phone Number"
                  sx={{
                    marginBlock: 2,
                    minWidth: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="schoolEmailAddress"
                  label="Email"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
          </Box>
          <Grid mt={1}>
            <Box
              sx={{
                background: "#6495ED",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                PAYMENT DETAILS
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="invoiceTo"
                  label="Name invoice should be addressed to"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
              
                  name="courseTitle"
                  label="Course Title"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                
                  name="invoicetotalNumOfStaffTo"
                  label="Total No of Staff"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                
                  name="totalNumOfStudentsOnRoll"
                  label="Total No of students/pupils on roll"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
          </Box>

          <Grid item sx={{ textAlign: "right", mt: 3 }}>
            <LoadingButton
              type="submit"
              color="secondary"
              endIcon={<Send />}
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Container>
      </FormProvider>
    </Fragment>
  );
}

export default InsetBookingForm;
