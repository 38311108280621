import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  List,
  Stack,
  Alert,
  Avatar,
  Button,
  Box,
  ListItem,
} from "@mui/material";
import AdminBG from "../../../images/background.png";
import Add from "@material-ui/icons/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import LessonObservation from "../../../sections/Admin/IdentificationModal";
import { useSnackbar } from "notistack";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import FolderIcon from "@material-ui/icons/Folder";
import { useNavigate, useParams } from "react-router";
import AdminInvoice from "../../../sections/Admin/AdminInvoice";
import * as Yup from "yup";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

import axoisCustomAgent from "../../../utils/axiosCustomAgent";
import Pending from "src/pages/dashboard/Pending";

function AuditGradingGeneral({
  nextStep,
  schoolId,
  auditId,
  dateOfAudit,
  schoolURN,
}) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [gradingSheet, setGradingSheet] = useState([]);
  const [school, setSchool] = useState({});
  const [role, setRole] = useState("");

  // const [gradingSheetUrn, setGradingSheetUrn] = useState({});
  let { taskId } = useParams();
  const navigate = useNavigate();

  const AcceptSchema = Yup.object().shape({
    checkbox: Yup.bool().oneOf([true], "Accept terms is required"),
  });

  const defaultValues = {
    checkbox: false,
  };
  const methods = useForm({
    resolver: yupResolver(AcceptSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const isChecked = watch("checkbox");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "86%",

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 2,
    p: 4,
  };

  const [item, setItem] = useState([schoolId, auditId]);
  localStorage.setItem("items", JSON.stringify(item));

  // const ChangeOfSection = (e, section, item) => {
  //   setSection(section);
  //   setSectionItem(item);
  //   handleOpen();
  // };

  // const [openLesson, setOpenLesson] = useState(false);

  // const handlePageLessonObservation = () => {
  //   setOpenLesson(true);
  //   setPage(true);
  // };

  // const handlePageCloseLessonObservation = () => {
  //   setOpenLesson();
  //   setPage(false);
  // };

  useEffect(() => {
    let unmounted = false;
    const getAllGradingSheet = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AuditClerk/GetAuditGradingForms/${taskId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setGradingSheet((prev) => data.forms);
        setSchool((prev) => data.school);
        setRole((prev) => data.role); //
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllGradingSheet();
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = async (e) => {
    if (isChecked === false) {
      enqueueSnackbar("Accept the terms and conditions to continue", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await axoisCustomAgent.post(
        `Audit/CompleteAudit?taskId=${taskId}&declaration=${true}`
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/auditClerkCongrat/:taskId`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleNavigation = (path) => {
    let to = `${path}/${taskId}`;

    navigate(to);
  };
  // const onSubmit = () => {};

  const today = new Date();
  const date = today.toLocaleDateString();

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "120vh",
          backgroundCover: "contain",
        }}
      >
        <Card
          sx={{
            minWidth: "60%",
            mt: 4,
          }}
        >
          <Box m={3}>
            <Typography variant="h5"> Audit Grading Sheet</Typography>
          </Box>

          <Box p={1} display={"flex"}>
            <Typography>School Urn:</Typography>
            <Typography ml={2} color={"secondary"}>
              {school.urn}
            </Typography>
          </Box>

          <Box p={1} display={"flex"}>
            <Typography>School Address:</Typography>
            <Typography ml={2} color={"secondary"}>
              {school.address}
            </Typography>
          </Box>

          <Box p={1} display={"flex"}>
            <Typography>Role:</Typography>
            <Typography ml={2} color={"secondary"}>
              {role}
            </Typography>
          </Box>

          {/* <Box mt={2}>
            <Typography fontSize={"16px"}>{school.address}</Typography>
          </Box> */}

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <List sx={{ p: 1 }}>
                <Typography variant="h6">Click on a link to fill </Typography>
                {gradingSheet.map((eachItem, index) => (
                  <ListItem key={index} sx={{ display: "flex" }}>
                    <Typography
                      m={2}
                      width={20}
                      height={20}
                      backgroundColor={
                        eachItem.accessId === 1
                          ? "Purple"
                          : eachItem.accessId === 2
                          ? "Red"
                          : "blue"
                      }
                    ></Typography>
                    <Button
                      onClick={() => handleNavigation(eachItem.path)}
                      disabled={eachItem.isCompleted}
                      style={{
                        display: "block",
                      }}
                    >
                      {eachItem.name}
                    </Button>
                  </ListItem>
                ))}
              </List>
              <Box display={"flex"}>
                <RHFCheckbox
                  name="checkbox"
                  label=""
                  sx={{ mb: 5, color: "black" }}
                />
                <Box>
                  {/* s */}
                  <Typography fontSize={"15px"}>
                    I certify that this episode of the Safety Audit conducted at
                    <span
                      style={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        marginLeft: 2,
                      }}
                    >
                      {school.urn}
                    </span>
                    <span
                      style={{
                        marginLeft: 2,
                      }}
                    >
                      on the
                    </span>
                    <span
                      style={{
                        fontSize: "19px",
                        fontWeight: "bold",
                        marginLeft: 2,
                      }}
                    >
                      {date}
                    </span>
                    <span
                      style={{
                        marginLeft: 2,
                      }}
                    >
                      was conducted <br />
                      by according to SSLAG Framework v0.1, 2022 and all
                      protocols were carried out in line with the relevant codes{" "}
                      <br /> and the observations.
                    </span>
                  </Typography>
                </Box>
              </Box>

              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={() => handleDirectionChange("forward")}
                    loading={isSubmitting}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Fragment>
  );
}

export default AuditGradingGeneral;
