import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Stack,
  Alert,
} from "@mui/material";
import logoBg from "../../images/sslag.png";
import Scrollbar from "../../components/Scrollbar";

import { useForm } from "react-hook-form";
import {
  RHFTextField,
  FormProvider,
  RHFCheckbox,
} from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";

import axiosCustomAgent from "../../utils/axiosCustomAgent";

function SchoolCJSummary() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const SummarySheet = [
    {
      index: 1,
      item: "PSL application form",
    },
    {
      index: 2,
      item: "CBC online registration link;",
    },
    {
      index: 3,
      item: "the booking form for the INSET day on safety",
    },
    {
      index: 4,
      item: "the data extraction form",
    },
    {
      index: 5,
      item: "information aboutm establishing a safety committee",
    },

    {
      index: 6,
      item: "information about DSOs’ (designated Safety officers)",
    },
    {
      index: 7,
      item: "information about first aiders",
    },
    {
      index: 8,
      item: "information about conducting safe recruitment checks for all members of staff",
    },
    {
      index: 9,
      item: " information about having a safety budget",
    },
    {
      index: 10,
      item: "information about operating a transport system and if you do have a transit system, what must be done to get the operator checked by SSLAG",
    },
    {
      index: 11,
      item: "information about having a PTA (or parents forum)",
    },
    {
      index: 12,
      item: " Information on how to order the mandatory safety supplies",
    },
    {
      index: 13,
      item: "Information about booking the specialist knowledge transfer training for the 3rd parties vendors such as security personnel, kitchen staff, etc",
    },
  ];

  const [summarysheet, Summarysheet] = useState(SummarySheet);

  const defaultValues = {
    declarationName: "",
    declarationAcceptance: "",
  };

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "Combined Evaluation Stages 1-3 Summary Sheet",
            token,
          }
        );

        const { statusCode, message } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }

        setLinkStatus(message);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const methods = useForm({
    // resolver: yupResolver(SummmarySheetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitCombinedEvaluationSummaryInterventionForm`,
        {
          schoolId,
          token,
          declarationAcceptance: data.declarationAcceptance,
          declarationName: data.declarationName,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Grid>
          <Box textAlign={"center"}>
            <Box>
              <Grid>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 200,
                  }}
                />
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
              }}
            >
              COMBINED EVALUATION STAGES 1-3 SUMMARY SHEET
            </Typography>
          </Box>
        </Grid>
        <Container>
          <Box mt={3}>
            <Typography variant="h6">KEY POINTS FOR SCHOOLS.</Typography>
          </Box>

          <ol type="A">
            <li>
              {" "}
              Once you received the Compliance Journey (CJ) Email , the school
              must follow all the instructions. It is important that the
              Provisional Safety Licence form is completed and all the
              information required is given in all the accompanied
              documentations received.
              <div>
                Schools may send an email to request and Evaluation visit to
                present the opportunities for them to be given hands on
                information about the Compliance requirements for schools and
                support them to get started on the Compliance Journey (CJ).
              </div>
            </li>
            <li>
              <strong>ensure the official school email </strong>
              used is correct and checked regularly to avoid late responses.
            </li>
            <li>
              Please <strong> Compliance Drive note that the entire</strong> is
              a 2 step processes for Schools <strong>Step.1</strong> is the
              Evaluation which consists of CJ 1-3 and
              <strong>step 2 </strong> is the School Safety Audit.
            </li>
            <div>
              The Evaluation consists of 3 stages which is marked by the email
              just received now from SSLAG project Directorate office. These
              documents are the CJ ( Compliance Journey) initiation documents,
              these are a)PSL Provisional Safety Licence) Application form, b)
              Data extraction form, c) CBC (Capacity Building Conference)
              registration form and d) the booking form for INSET (in service
              education training) Day on Safety.
              <Typography variant="h6" color={"#FF0000"}>
                These four items must be completed and submitted by email to
                PD@safeschoolslagos.ng or LR@safeschoolslagos.ng
              </Typography>
            </div>
          </ol>
          <Box p={2}>
            <Typography variant="h6">
              Step 1 (Evaluation) Consists of 3 stages :
            </Typography>
            <Typography>
              Stage 1 is the CJ INITIATION & SSLAG ONBOARDING
            </Typography>
            <Box mt={3}>
              <Typography>
                This is what the CJ Email is about, and it also gives the SMS
                (Safety management System) Walk-through to assist schools
                implement a safety management system. An Mandatory Safety
                Supplies proforma invoice; and other instructions for the
                implementation of the SMS are included.
              </Typography>

              <Typography>
                The Mandatory safety supplies include the following:
              </Typography>
              <Box padding={2}>
                <ol type="i">
                  <li>The compliance manual</li>
                  <li>The SSLAG Quick guide,</li>
                  <li>
                    {" "}
                    The 12 minimum standard guidelines poster for Day Schools;
                  </li>
                  <li>The 8 MS guidelines poster for schools hostels;</li>
                  <li>The high visibility vests; and so on</li>
                </ol>
              </Box>
              <Typography>
                Finally there is the school safety checkpoint where you use the
                13 points checklist, See the table below for the checklist.
              </Typography>

              <Box mt={2}>
                <Typography variant="h5">
                  Before sending your response, ensure
                </Typography>
                <Box padding={3}>
                  <ol type="1">
                    <li>
                      you have given the correct details of the main contact
                      person on the documents that you are sending
                    </li>
                    <li>the evaluation visit summary sheet for school teams</li>
                  </ol>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={3}>
            <Typography variant="h5" textAlign={"center"}>
              EVALUATION VISITS SUMMARY SHEET FOR SCHOOL TEAMS
            </Typography>
            <Box display={"flex"} mt={2}>
              <Typography>Dear,</Typography>
              <Typography>stakeholder</Typography>
            </Box>
            <Typography fontSize={"15px"} mt={1}>
              SSLAG Implementation team has sent you relevant information about
              Safe Schools Lagos, compliance requirements. The summary of which
              is listed in the table below.
            </Typography>

            <Grid>
              <Card sx={{ mt: 5, minWidth: 1000 }}>
                <CardHeader title="Summary sheet" sx={{ mb: 3 }} />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableRow>
                        <TableCell
                          style={{
                            fontSize: "19px",
                            fontWeight: "bolder",
                          }}
                        >
                          Item no
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "19px",
                            fontWeight: "bolder",
                          }}
                        >
                          Information Given
                        </TableCell>
                      </TableRow>
                      <TableBody>
                        {summarysheet.map((eachData, index) => (
                          <TableRow key={index}>
                            <TableCell>{eachData.index}</TableCell>
                            <TableCell>{eachData.item}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Divider />
                <Box sx={{ p: 2, textAlign: "right" }}>
                  <Button
                    size="small"
                    color="inherit"
                    // endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                  >
                    .
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Box>

          <Grid mt={2}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
                <Grid>
                  <RHFCheckbox
                    name="declarationAcceptance"
                    label="  This is to confirm that the SSLAG Compliance Journey email was
              received and information for completing the required
              documentations were read by me"
                    sx={{ ml: 2, color: "black" }}
                  />
                  <Grid width={400}>
                    <RHFTextField
                      name="DeclarationName"
                      label="Name of school contact"
                      variant="filled"
                    />
                  </Grid>
                  {/* <Grid ml={2} width={400}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            label="Date  "
                            value={methods.watch().DOB}
                            onChange={(value) => {
                              methods.setValue("DOB", value, {
                                shouldValidate: true,
                              });
                            }}
                            renderInput={(params) => (
                              <RHFTextField
                                variant="filled"
                                {...params}
                                name="DOB"
                              />
                            )}
                          />
                        </LocalizationProvider>
                  </Grid> */}
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      width: 160,
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Stack>
            </FormProvider>
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SchoolCJSummary;
