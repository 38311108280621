import React, { Fragment, useState } from "react";
import { Grid, Box, Typography, Container, Stack, Alert } from "@mui/material";
import logoBg from "../../images/sslag.png";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { RHFTextField, FormProvider } from "../../components/hook-form";

function AuditStructure() {
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Box textAlign={"center"} mt={7}>
          <Box>
            <Grid>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 300,
                }}
              />
            </Grid>
          </Box>
        </Box>

        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 800,
          }}
        >
          <Box p={3}>
            <Typography variant="h6">Our Reference:AU-PN001</Typography>
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AuditStructure;
