import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuth from "src/hooks/useAuth";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Modal,
  Card,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import Log from "./Log";
import { useParams } from "react-router";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
function BehaviouStudent() {
  const { userData } = useAuth();

  const { schoolId } = userData;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );
  let { yearGroupId, classId } = useParams();

  useEffect(() => {
    const getAllStudent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/Students/GetAllStudentByClassId?schoolId=${schoolId}&yearGroupId=${yearGroupId}&classId=${classId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setStudents([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStudent();
  }, [schoolId]);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = students.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "OtherName",
      selector: (row) => row.otherName,
    },

    {
      name: "Year Group",
      selector: (row) => row.yearGroupName,
    },

    {
      cell: (row) => (
        <MoreMenuButton schoolId={schoolId} studentId={row.studentId} />
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Students...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Button
          href="/dashboard/user/Behaviour"
          className="header"
          sx={{ color: "#6495ed" }}
        >
          Back
        </Button>
      </Box>
      <Card>
        <Grid p={3} style={Gridimage}>
          {students.length === 0 ? (
            <Typography>No Student Record</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default BehaviouStudent;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ isActive, schoolId, studentId }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handlePageOpen} sx={{ color: "succes" }}>
          {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
          Log Behaviour
        </MenuItem>

        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color={"#6495ed"}
            >
              Behaviour Status
            </Typography>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <Log schoolId={schoolId} studentId={studentId} />
            </Grid>
          </Box>
        </Modal>
        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}
