import React, { useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Card,
} from "@mui/material";
import { Link } from "react-router-dom";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";

import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import EditParent from "./EditParent";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function AllParent() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(false);

    const customStyles = {
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
          // background: "#34a8e1",
          fontSize: "19px",
          borderRadius: 2,
        },
      },
    };

  useEffect(() => {
    const getAllParents = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/Parents/GetAllParentsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setParents([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllParents();
  }, [schoolId]);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = parents.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },

    // { cell: (row) => <MoreMenuButton /> },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Parents...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">School Management</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/student"
            className="headers"
          >
            Create Student
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/allStudent"
            className="header"
          >
            All Student
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/allParent"
            className="header active"
          >
            All Parent
          </Button>
        </Box>
      </Box>
      <Card>
        <Grid p={3} style={Gridimage}>
          {parents.length === 0 ? (
            <Typography>No Parent records found</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default AllParent;

function MoreMenuButton({ isActive }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [modalopen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handleModalOpen} sx={{ color: "succes" }}>
          Edit
        </MenuItem>
        <Modal
          open={modalopen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update profle
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <EditParent />
            </Typography>
          </Grid>
        </Modal>
        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Delete
        </MenuItem> */}
      </MenuPopover>
    </>
  );
}
