import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  CardContent,
  CardActions,
  Card,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
} from "@mui/material";

// _mock_
import { _homePlans } from "../../../_mock";
// components
import Image from "../../../components/Image";
import Iconify from "../../../components/Iconify";

import { MotionInView, varFade } from "../../../components/animate";
import { LoadingButton } from "@mui/lab";
import ObligationChecklist from "./ObligationChecklist";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(7, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4, 0),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function ComplianceObligations() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <RootStyle>
      <Container>
        <MotionInView variants={varFade().inDown}></MotionInView>

        <Box textAlign={"center"} mt={5}>
          <Typography variant="h3" color={"#4169e1"}>
            THE COMPLETE <br /> SSLAG AUDIT CHECKLIST
          </Typography>
        </Box>

        <Container sx={{ mt: 2, mb: 10 }}>
          <Typography variant="body1" sx={{ mb: 5 }}>
            Having the right paperwork and knowing where it is means you won't
            have to scramble when the SSLAG Assessor shows up for an audit.
            Showing attention to detail can be the difference between passing
            and failing your school safety audit.
            <br />
            Here's a checklist of the documents and paperwork that you should
            have readily available in the event of your annual
            school safety audit:
          </Typography>

          <Grid container spacing={10}>
            <Grid item xs={12} md={12}>
              <ObligationChecklist />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </RootStyle>
  );
}
