import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
  Typography,
  Modal,
  Stack,
  Alert,
} from "@mui/material";
import AdminBG from "../../../images/background.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import { RHFTextField, FormProvider } from "../../../components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
// import RecommendedModal from "./RecommendedModal";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

function AdminRecommendation({ nextStep, prevStep, auditId, schoolId }) {
  // const onSubmit = () => {};
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [recommendation, setAllRecommendation] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const [getRecommendation, setGetRecommendation] = useState([]);

  const [direction, setDirection] = useState("");

  const defaultValues = {
    title: "",
    actions: "",
    fromDate: "",
    fromTime: "",
    toDate: "",
    toTime: "",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const response = await axoisCustomAgentForAdmin

  function handleDeleteTodo(index) {
    const newTodos = [...recommendation];
    newTodos.splice(index, 1);
    setDeleting(newTodos);
  }

  const methods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const addRecommendation = (newRecommendation) => {
    let updatedRecommendations = [...recommendation];
    updatedRecommendations.push(newRecommendation);
    setAllRecommendation(updatedRecommendations);
    handleClose();
  };

  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();

  useEffect(() => {
    let unmounted = false;
    const GetRecommendation = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionTenRecommendation?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        // methods.reset({ ...data });
        setGetRecommendation(data);

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetRecommendation();
    return () => {
      unmounted = true;
    };
  }, []);

  console.log(getRecommendation);

  const onSubmit = async (e) => {};

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 300,
            width: "60%",
          }}
        >
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Box textAlign={"center"}>
            <Typography
              variant="h5"
              sx={{
                background: "#00bbea",
                // textAlign: "centre",
                mt: 2,
                p: 2,
              }}
            >
              SECTION 8:RECOMMENDATIONS
            </Typography>
          </Box>

          <Box mt={3}>
            {getRecommendation.length === 0 ? (
              <Typography>No Record Found</Typography>
            ) : (
              <Box mt={3}>
                {getRecommendation.map((eachItem, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box display={"flex"}>
                        <Box>
                          <Typography>{eachItem.title}</Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{eachItem.actions}</Typography>

                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                          <Typography variant="h5">Date Frame</Typography>
                          <Box fontSize={"13px"}>
                            <Typography fontSize={"13px"}>
                              {" "}
                              From: {eachItem.fromDate.split("T")[0]}{" "}
                            </Typography>
                            <Typography fontSize={"13px"}>
                              To: {eachItem.toDate.split("T")[0]}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant="h5">Time Frame</Typography>
                          <Box>
                            <Typography fontSize={"13px"}>
                              From:{" "}
                              {eachItem.fromTime.split("T")[1].split(".")[0]}{" "}
                            </Typography>
                            <Typography fontSize={"13px"}>
                              {" "}
                              To: {eachItem.toTime.split("T")[1].split(".")[0]}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminRecommendation;
