import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
// import { Box } from "@mui/system";

function AdminSchoolDetail() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();
  const [starterPack, setAllSchoolStarterPack] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  useEffect(() => {
    const getAllSchoolStarterPack = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolOnboarding/GetSchoolStarterPacks/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        // const { data } = response.data;
        setAllSchoolStarterPack(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAllSchoolStarterPack();
  }, []);

  if (starterPack === null && loading) {
    return <Typography>Loading Stater Pack....</Typography>;
  }

  if (starterPack === null) {
    return <Typography>No Record Found....</Typography>;
  }

  console.log(starterPack);

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> Starter Pack</Typography>
            </Grid>
            <Box display={"flex"}>
              <Grid>
                <FormHelperText> Torchlight</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[0].schoolQty}
                  disabled
                />
                <FormHelperText> High Visibility Vest</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[1].schoolQty}
                  disabled
                />
                <FormHelperText> Compliance Manual</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[2].schoolQty}
                  disabled
                />
                <FormHelperText> Wet Floor Sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[3].schoolQty}
                  disabled
                />
                <FormHelperText> Fire Exit Sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[4].schoolQty}
                  disabled
                />
                <FormHelperText> Fire Extinguishers</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[13].schoolQty}
                  disabled
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText> Fire Door Sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[5].schoolQty}
                  disabled
                />
                <FormHelperText> Lollipop stop/Go sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[6].schoolQty}
                  disabled
                />
                <FormHelperText> Muster point statement</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[7].schoolQty}
                  disabled
                />
                <FormHelperText> Case of fire statement</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[8].schoolQty}
                  disabled
                />

                <FormHelperText> SSLAG poster</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[17].schoolQty}
                  disabled
                />
                <FormHelperText> CBC certificate</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[16].schoolQty}
                  disabled
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText> Wash your hands sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[9].schoolQty}
                  disabled
                />
                <FormHelperText> First aid sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[10].schoolQty}
                  disabled
                />
                <FormHelperText> No smoking sign</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[11].schoolQty}
                  disabled
                />
                <FormHelperText> First aid boxes</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[12].schoolQty}
                  disabled
                />
                <FormHelperText> SSLAG quick guide</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Qty"
                  variant="outlined"
                  value={starterPack[14].schoolQty}
                  disabled
                />

                <Grid item sx={{ textAlign: "right", mt: 5 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => openAlertModal()}
                  >
                    Intervene
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolDetail;
