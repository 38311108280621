import React from "react";
import {
  Link,
  Card,
  CardHeader,
  Stack,
  Grid,
  Typography,
  Button,
  Box,
  Drawer,
  Divider,
  CardActions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AdminData } from "./Admindata";
import AdminNav from "./AdminNav";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";

function AdminDashboard2() {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Card
      style={{
        background: "#edf5f9",
      }}
    >
      <CardHeader title="All Links" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Box sx={{ color: "" }}>
          {AdminData.map((item, index) => {
            return <AdminNav item={item} key={index} />;
          })}
        </Box>
      </Stack>
    </Card>
  );
}

export default AdminDashboard2;
