import {
  Container,
  Grid,
  Stack,
  Alert,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../../images/background.png";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import Iconify from "../../../components/Iconify";
import { useNavigate } from "react-router-dom";
import formatnaira from "format-to-naira";

function PublicAudit() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [lsc, setLsc] = useState([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loadedSchool, setLoadedSchool] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const AuditSchema = Yup.object().shape({
    email: Yup.string().required("field  is required"),
    password: Yup.string().required("field  is required"),
    confirmPassword: Yup.string().required("field  is required"),

    //schoolcategory: Yup.string().required("field  is required"),
    //educationalDistrict: Yup.string().required("field  is required"),
    school: Yup.object()
      .shape({
        name: Yup.string().required("field  is required"),
      })
      .typeError("select your school"),
  });

  const defaultValues = {
    //schoolcategory: "",
    email: "",
    password: "",
    confirmPassword: "",
    //educationalDistrict: "",
    school: null,
  };

  const methods = useForm({
    resolver: yupResolver(AuditSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = methods;
  const { formState } = methods;
  const values = watch();

  let SchoolNameId = watch("schoolName");
  let Schoolxx = watch("school");

  const [DistrictId, setDistrictId] = useState(0);
  const [schoolTypeId, setschoolTypeId] = useState("");
  const [toFetchSchools, setToFetchSchools] = useState(false);

  // console.log({ DistrictId });
  // console.log({ schoolTypeId });
  // console.log({ toFetchSchools });
  // console.log({ SchoolNameId });
  // console.log(Schoolxx?.id);

  const getPreloadedSchool = async () => {
    setLoadingSchools(true);
    try {
      const response = await axoisCustomAgent.get(
        `/SchoolAudit/GetPreloadedPublicSchoolInfo?districtId=${DistrictId}&schoolTypeId=${schoolTypeId}`
      );

      const { status } = response.data;

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      const { data } = response.data;
      setLoadedSchool([...data]);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingSchools(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    const getCategory = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolAudit/GetAuditAmountForSchoolTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setSelectCategory([...selectCategory, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getCategory();
    // const getAllLscFees = async () => {
    //   let unmounted = false;

    //   try {
    //     const response = await axoisCustomAgent.get(`SchoolAudit/GetLscFee`);
    //     const { statusCode, message } = response.data;
    //     const { data } = response.data;

    //     if (statusCode !== 200) {
    //       throw new Error(message);
    //     }
    //     setLsc([...data]);
    //   } catch (error) {
    //     enqueueSnackbar(error.message, { variant: "error" });
    //   } finally {
    //   }
    // };
    // getAllLscFees();

    const getAllFacilities = async () => {
      let unmounted = false;
      setLoadingFacilities(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolAudit/GetAuditAmountForSchoolFacilities`
        );
        const { statusCode, message } = response.data;
        const { data } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        if (!unmounted) {
          let modifiedArray = data.map((eachItem) => {
            const { id, facility, amount } = eachItem;
            return {
              isChecked: false,
              amount,
              facility,
              id,
            };
          });
          setFacilities([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingFacilities(false);
      }
    };
    getAllFacilities();
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if (toFetchSchools === true) {
      getPreloadedSchool();
      setToFetchSchools(false);
    }

    return () => {};
  }, [toFetchSchools]);

  const District = [
    { label: "Select district", value: 0 },
    { label: "District 1", value: 1 },
    { label: "District 2", value: 2 },
    { label: "District 3", value: 3 },
    { label: "District 4", value: 4 },
    { label: "District 5", value: 5 },
    { label: "District 6", value: 6 },
  ];

  const handleDistrictChange = (e) => {
    setDistrictId(e.target.value);
    setLoadedSchool([]);
    setValue("school", null, { shouldDirty: true });
    if (e.target.value > 0 && schoolTypeId !== "") {
      setToFetchSchools(true);
    }
  };

  const handleSchoolTypeChange = (e) => {
    setLoadedSchool([]);
    setValue("school", null, { shouldDirty: true });
    setschoolTypeId(e.target.value);
    if (DistrictId > 0 && e.target.value !== "") {
      setToFetchSchools(true);
    }
  };

  const [selectCategory, setSelectCategory] = useState([
    {
      id: "",
      schoolTypeId: "",
      schoolTypeName: "select school catergory",
      amount: "",
    },
  ]);

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const response = await axoisCustomAgent.post(
        `SchoolAudit/BookAuditForPublicSchools`,
        {
          schoolId: data.school?.id,
          email: data.email,
          lscFeeId: lsc[0]?.id,
          password: data.password,
          confirmPassword: data.confirmPassword,
          schoolTypeId: data.schoolcategory,
          educationalDistrict: data.educationalDistrict,
        }
      );

      if (response.status === 400) {
        throw new Error("Something went wrong");
      }

      const { userId } = response.data.data;

      enqueueSnackbar("Audit submit successfully", { variant: "success" });
      methods.reset();
      navigate(`/publicAuditInvoice/${userId}`, { replace: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loadingFacilities) {
    return (
      <Box justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "50%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>
            <Box textAlign={"center"}>
              <Typography
                variant="h5"
                sx={{
                  color: "#00B000",

                  mt: 2,
                  p: 2,
                }}
              >
                SCHOOL SAFETY AUDIT REQUEST FORM
              </Typography>
            </Box>
            <Box>
              <Typography m={2} fontSize={"14px"}>
                kindly use your school information to fill the following *
              </Typography>

              <Box display={"flex"} justifyContent={"space-around"} m={2}>
                <Box
                  display={"flex"}
                  justifyContent={"space-around"}
               
                >
                  <TextField
                    select
                    name="schoolcategory"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                    value={schoolTypeId}
                    onChange={handleSchoolTypeChange}
                  >
                    {selectCategory.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.schoolTypeName}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box display={"flex"} justifyContent={"space-around"} ml={4}>
                  <TextField
                    select
                    name="educationalDistrict"
                    required
                    variant="filled"
                    SelectProps={{ native: true }}
                    value={DistrictId}
                    onChange={handleDistrictChange}
                    sx={{
                      width: 270,
                    }}
                  >
                    {District.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"} m={2}>
                <Box width={300}>
                  <Controller
                    name="school"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        freeSolo
                        onChange={(event, newValue) => {
                          console.log({ newValue });
                          field.onChange(newValue);
                        }}
                        getOptionLabel={(option) => option.name}
                        //options={Top100Films.map((option) => option)}
                        options={loadedSchool.map((option) => option)}
                        renderInput={(params) => (
                          <RHFTextField
                            {...params}
                            variant="filled"
                            label="School Name"
                            name="school"
                          />
                        )}
                      />
                    )}
                  />
                </Box>

                <Box width={300} ml={2}>
                  <RHFTextField
                    name="email"
                    label="Email Address"
                    variant="filled"
                  />
                </Box>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"} m={2}>
                <Box width={300}>
                  <RHFTextField
                    name="password"
                    label="Password"
                    variant="filled"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box width={300} ml={2}>
                  <RHFTextField
                    name="confirmPassword"
                    label="Confirm Password"
                    variant="filled"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <Iconify
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

          
            </Box>

            <Grid textAlign={"right"} p={2}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                submit
              </LoadingButton>
            </Grid>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default PublicAudit;
