import {
    Grid,
    Box,
    Alert,
    Stack,
    Typography,
    FormControl,
    Container,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as Yup from "yup";
  import {
    FormProvider,
    RHFTextField,
    RHFCheckbox,
    RHFRadioGroup,
  } from "../../../components/hook-form";
  import LoadingButton from "@mui/lab/LoadingButton";
  import { useSnackbar } from "notistack";
  import axoisCustomAgent, {
    axoisCustomAgentForAdmin,
  } from "../../../utils/axiosCustomAgent";
  import useAuthAdmin from "src/hooks/useAuthAdmin";
  import { useNavigate, useParams } from "react-router";
  import AdminBG from "../../../images/background.png";
  
  function EndOfDayAdmin({ section, item, schoolId, auditId }) {
    const { enqueueSnackbar } = useSnackbar();
    // const [loading, setLoading] = useState(false);
    const { stakeholderCategory, id } = useAuthAdmin();
    const [observation, setObservation] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { taskId, clerkId } = useParams();
  
    // localStorage.getItem('items', JSON.stringify(item));
    const items = JSON.parse(localStorage.getItem("items"));
  
    const defaultValues = {
      // new========================
      groupInformationPropsUsed: "",
      safeHandOver: "",
      teacherParentHandOver: "",
      controlManagement: "",
      behaviourManagement: "",
      comment: "",
      evidenceOfSafeLearning: "",
      evidenceOfSafetyAwarenessForStaff: "",
      numberOfHandOver: "",
    };
  
    const methods = useForm({
      // resolver: yupResolver(),
      defaultValues,
    });
  
    const {
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
  
   
      
 
  
  
    const option = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  

    useEffect(() => {
      let unmounted = false;
      const GetEndOfDay = async () => {
        setLoading(true);
        try {
          const response = await axoisCustomAgentForAdmin.get(
            `/Audit/GetSectionOneEndOfDay?taskId=${taskId}&clerkId=${clerkId}`
          );
          const { statusCode, message } = response.data;
  
          if (statusCode !== 200) {
            throw new Error(message);
          }
          const { data } = response.data;
          methods.reset({ ...data });
  
          //setGeneralSection(response.data.data);
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        } finally {
          setLoading(false);
        }
      };
  
      if (id !== null && id !== "" && !unmounted) GetEndOfDay();
      return () => {
        unmounted = true;
      };
    }, []);
  
    const onSubmit = () => {};
  
  
  
  
    return (
      <Fragment>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
            background: `url(${AdminBG})`,
            minHeight: "100vh",
            backgroundCover: "contain",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 1,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 600,
              width: "70%",
            }}
          >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} m={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
                <Box textAlign={"center"}>
                  <Typography
                    variant="h5"
                    sx={{
                      background: "#00bbea",
                      textAlign: "centre",
                      mt: 2,
                      p: 2,
                    }}
                  >
                    Section 1A:4 End of Day Routine
                  </Typography>
                </Box>
  
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>Appropriate lollipop Used?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="groupInformationPropsUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography> Are safe hand over seen?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="safeHandOver"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>
                        Appropriate teacher/ parent hand over supervision?
                      </Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="teacherParentHandOver"
                      />
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>
                        Access to school premises in control management?
                      </Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="controlManagement"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                  <Grid>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <Typography>Effective behaviour management?</Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="behaviourManagement"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>
                        General evidence of a safe learning taking place?
                      </Typography>
  
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfSafeLearning"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <Typography>
                        Evidence of safety awareness & approved professional
                        development for staff on school safety?
                      </Typography>
  
                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfSafetyAwarenessForStaff"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
  
                    <Box sx={{ marginBlock: 1, width: 300 }}>
                      <RHFTextField
                        name="numberOfHandOver"
                        label="Number of Teacher at hand over "
                        variant="filled"
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <RHFTextField
                        name="comment"
                        label="Comments?"
                        variant="filled"
                        multiline
                        minRows={3}
                      />
                    </Box>
                  
                  </Grid>
                </Box>
              </Stack>
            </FormProvider>
          </Container>
        </Grid>
      </Fragment>
    );
  }
  
  export default EndOfDayAdmin;