import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import AdminAuditGradingIdentification from "./AdminAuditGradingIdentification";
import AdminAuditGradingManagement from "./ManagementCommitment";
import SafeSchoolPolicies from "./SafeSchoolPolicies";
import Recommendation from "./Recommendation";
import Safeguarding from "./Safeguarding";
import SchoolTransitSystem from "./SchoolTransitSystem";
import WalkRound from "./Walkround";
import AdminAuditGradingStaffRole from "./StaffRoleAndResponsibility";
import AuditGradingStarter from "./AuditGradingStarter";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import ComplianceCriterion from "./ComplianceCriterion";
import ComplianceCriterion2 from "./ComplianceCriterion2";
import complianceCriterion1 from "./ComplianceCriterion1";
import AdminAuditPage from "../../../sections/Admin/AdminAuditPage";

function AdminAuditGrading() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [auditData, setAuditData] = useState(null);
  // const[ dateOfAudit, setDateOfAudit] = useState(null);
  // const[ schoolUrn, setSchoolUrn] = useState(null);
  let { schoolId } = useParams();

  // useEffect(() => {
  //   let unmounted = false;
  //   const InitiateAudit = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.post(
  //         `Audit/InitiateAudit?schoolId=${schoolId} `
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       setAuditData(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) InitiateAudit();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId]);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    let newStep = step + 1;
    setStep(newStep);
  };

  const prevStep = () => {
    let newStep = step - 1;
    setStep(newStep);
  };

  // if (loading || auditData === null) {
  //   return <h3>Loading...</h3>;
  // }

  switch (step) {
    case 1:
      return (
        <AdminAuditGradingIdentification
        // schoolId={schoolId}
        // auditId={auditData?.auditId}
        // nextStep={nextStep}
        // dateOfAudit={auditData?.dateOfAudit}
        // schoolURN={auditData?.schoolURN}
        />
      );
    case 2:
      //   return (
      //     <AdminAuditGradingSection2
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // case 3:
      //   return (
      //     <AdminAuditGradingManagement
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 4:
      //   return (
      //     <AdminAuditGradingStarter
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 5:
      //   return (
      //     <AdminAuditGradingPolicies
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 6:
      //   return (
      //     <AdminAuditGradingSafeB
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 7:
      //   return (
      //     <AdminAuditGradingStaffRole
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 8:
      //   return (
      //     <AdminAuditGradingSchoolTransit
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );

      // case 9:
      //   return (
      //     <AdminAuditGradingRecommendation
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // case 10:
      //   return (
      //     <AdminAuditSchoolSafetyCC1
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // case 11:
      //   return (
      //     <AdminAuditSchoolSafetyCC2
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // case 12:
      //   return (
      //     <AdminAuditSchoolSafety
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // case 13:
      //   return (
      //     <AdminAuditPage
      //       // schoolId={schoolId}
      //       // auditId={auditData?.auditId}
      //       // nextStep={nextStep}
      //       // prevStep={prevStep}
      //     />
      //   );
      // default:
      return <Typography></Typography>;
  }
}

export default AdminAuditGrading;
