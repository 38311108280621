import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import AdminMoeImage from "./AdminMoeImage";
// import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  // width:"700px"
};

function AdminMoe() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();
  const [schoolPersonelMoe, setAllSchoolPersonelMoe] = useState(null);

     const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);
     const [activeImageMessageOpenInModal, setActiveImageMessageOpenInModal] =
       React.useState(null);

     const openImageModal = (imageMessage) => {
       setActiveImageMessageOpenInModal(imageMessage);
       setIsImageModalOpen(true);
     };

     const closeImageModal = () => {
       setActiveImageMessageOpenInModal(null);
       setIsImageModalOpen(false);
     };


  useEffect(() => {
    const getSchoolPersonelMoe = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolOnboarding/GetSchoolPersonnelAndMOEDetails/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        // const { data } = response.data;
        setAllSchoolPersonelMoe(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    console.log(schoolPersonelMoe);

    getSchoolPersonelMoe();
  }, [schoolId]);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  if (schoolPersonelMoe === null && loading) {
    return <Typography>Loading School Personnel Moe....</Typography>;
  }

  if (schoolPersonelMoe === null) {
    return <Typography mt={10}>No Record Found....</Typography>;
  }

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">Back</Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography>Personnel & Moe</Typography>
            </Grid>
            <Box display={"flex"}>
              <Grid>
                <FormHelperText>Title name</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Title"
                  variant="outlined"
                  required
                  disabled
                  value={schoolPersonelMoe.schoolPersonnelDetails.title}
                />
                <FormHelperText>Full name</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Name"
                  variant="outlined"
                  required
                  disabled
                  value={schoolPersonelMoe.schoolPersonnelDetails.name}
                />
                <FormHelperText>Designation</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Role"
                  variant="outlined"
                  required
                  disabled
                  value={schoolPersonelMoe.schoolPersonnelDetails.staffRole}
                />
                <FormHelperText>Mobile num</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="TelNo"
                  variant="outlined"
                  required
                  disabled
                  value={schoolPersonelMoe.schoolPersonnelDetails.phoneNumber}
                />
                <FormHelperText>Email</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Email"
                  variant="outlined"
                  required
                  disabled
                  value={schoolPersonelMoe.schoolPersonnelDetails.email}
                />
                <FormHelperText>No of non-teaching staff</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Email"
                  variant="outlined"
                  required
                  disabled
                  value={
                    schoolPersonelMoe.schoolPersonnelDetails
                      .numberOfNonTeachingStaff
                  }
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText>No of teaching staff</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Teachstaff"
                  variant="outlined"
                  disabled
                  value={
                    schoolPersonelMoe.schoolPersonnelDetails
                      .numberOfTeachingStaff
                  }
                />
                <FormHelperText>Educational district</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="EducateDis"
                  label=""
                  variant="outlined"
                  disabled
                  value={schoolPersonelMoe.schoolMOEDetails.educationalDistrict}
                />

                <FormHelperText>
                  Does personnel have safety recruitment check
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Email"
                  variant="outlined"
                  required
                  disabled
                  value={
                    schoolPersonelMoe.schoolPersonnelDetails
                      .doesPersonnelHaveSafetyRecruitmentEvidence
                  }
                />

                {schoolPersonelMoe.doesPersonnelHaveSafetyRecruitmentEvidence ===
                "true" ? (
                  <Grid>
                    <Button onClick={() => openImageModal()}>view</Button>

                    {/* view image */}
                    <Modal
                      open={isImageModalOpen}
                      onClose={closeImageModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Personel Moe
                        </Typography>
                        <Grid id="modal-modal-description" sx={{ mt: 2 }}>
                          <AdminMoeImage
                            imageMessage={activeImageMessageOpenInModal}
                            closeImageModal={closeImageModal}
                          />
                        </Grid>
                      </Box>
                    </Modal>

                    {/* view image */}
                  </Grid>
                ) : null}

                <FormHelperText>Curriculum</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="curriculum"
                  // label="No of teaching staff"
                  variant="outlined"
                  disabled
                  value={schoolPersonelMoe.schoolCurriculum
                    .map((x) => x.name)
                    .join(", ")}
                />
                <FormHelperText>
                  Do you have ministry education reference number?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="EduRefNo"
                  label=""
                  variant="outlined"
                  disabled
                  value={schoolPersonelMoe.schoolMOEDetails.moeReferenceNumber}
                />
              </Grid>
              <Grid ml={10}>
                <FormHelperText>
                  Date school approved by ministry of education:
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="moeDateOfApproval"
                  label=""
                  variant="outlined"
                  disabled
                  // value={
                  //   schoolPersonelMoe.schoolMOEDetails.moeDateOfApproval.split(
                  //     "T"
                  //   )[0]
                  // }
                  // value={
                  //   schoolPersonelMoe.schoolMOEDetails.moeDateOfApproval.split(
                  //     "T"
                  //   )[0]
                  // }
                />
                <FormHelperText>Year school founded:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="foundingYear"
                  label=""
                  variant="outlined"
                  disabled
                  value={
                    schoolPersonelMoe.schoolMOEDetails.foundingYear.split(
                      "T"
                    )[0]
                  }
                />
                <FormHelperText>
                  Approximate size of school site in meters sq.(only numbers )
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="SchoolSize"
                  label=""
                  variant="outlined"
                  disabled
                  value={schoolPersonelMoe.schoolMOEDetails.sizeOfSchoolSite}
                />
                <FormHelperText>Number of buildings:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="NumBuild"
                  label=""
                  variant="outlined"
                  disabled
                  value={
                    schoolPersonelMoe.schoolMOEDetails.buildingApprovalNumber
                  }
                />
                <FormHelperText>
                  {" "}
                  Does your building have approval:
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="buildingApproval"
                  label=""
                  variant="outlined"
                  disabled
                  value={
                    schoolPersonelMoe.schoolMOEDetails.doYouHaveMOEApproval
                  }
                />

                <Grid item sx={{ textAlign: "right", mt: 5 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => openAlertModal()}
                  >
                    Intervene
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminMoe;
