import { FormControl, FormHelperText, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

function StaffForm({ addStaff, addLoading }) {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    imageId: "",
    imageUrl: "",
  });

  const [imageSelected, setImageSelected] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const handleChange = (e, type) => {
    setState({
      ...state,
      [type]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (
      state.name.trim() === "" ||
      state.email.trim() === "" ||
      state.phoneNumber.trim() === ""
    ) {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } else {
      await addStaff(state);
      setState({
        name: "",
        email: "",
        phoneNumber: "",
        imageId: "",
        imageUrl: "",
      });
      setImageSelected("");
    }
  };

  const uploadImage = async () => {
    if (!!imageSelected || imageSelected !== "") {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("File", imageSelected);

        const response = await axoisCustomAgent.post("/uploads", formData, {
          headers: { "Content-type": "multipart/form-data" },
        });

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { imageId, imageUrl } = response.data.data;
          setState({
            ...state,
            imageId: imageId,
            imageUrl: imageUrl,
          });
          enqueueSnackbar("image upload successful", { variant: "success" });
        } else {
          enqueueSnackbar("image upload unsuccessful", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("image upload unsuccessful", { variant: "error" });
      } finally {
        setImageUploading(false);
        setImageSelected("");
      }
    }
  };

  // ========================================

  return (
    <Box>
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "space-evenly",
          // border: "1px solid",
          // borderRadius: 2,
          // paddingTop: 2,
          // paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            label="Full Name"
            value={state.name}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            sx={{
              ml: 2,
            }}
            label="Email"
            type="email"
            value={state.email}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            sx={{
              ml: 2,
            }}
            label="Phone Number"
            // type="number"
            value={state.phoneNumber}
            onChange={(e) => handleChange(e, "phoneNumber")}
          />
        </Box>
        <FormHelperText>
          Upload SSLAG certificate below (5MB maximum size)e.g PNG,JPEG,PDF
        </FormHelperText>
        <div
          style={{
            border: "1px solid grey",
            width: "500",
            marginTop: "10px",
            padding: "13px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            marginLeft: "10spx",
          }}
        >
          <div>
            <input
              type="file"
              onChange={(event) => {
                setImageSelected(event.target.files[0]);
              }}
            />
            <button
              onClick={async () => await uploadImage()}
              disabled={imageUploading || state.imageUrl.length > 0}
            >
              Upload SSLAG Cert.
            </button>
          </div>
        </div>
        <Box mt={2}>
          <LoadingButton
            variant="contained"
            loading={addLoading}
            onClick={async () => await handleSubmit()}
          >
            ADD
          </LoadingButton>
        </Box>
      </FormControl>
    </Box>
  );
}

export default StaffForm;
