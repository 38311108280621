import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Alert,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Stack,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
  RHFRadioGroup,
} from "../../components/hook-form";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { async } from "@firebase/util";

function AdminInterve({ schoolId, alertMessage, closeAlertModal }) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [intervention, setIntervention] = useState([]);
  const [interventionCjForm, setInterventionCjForm] = useState([]);

  const [form, setForm] = React.useState("");

  const InterventionAction = Yup.object().shape({
    dueDate: Yup.string().when("formValidate", {
      is: "2",
      then: Yup.string().required("field is required"),
    }),
    subject: Yup.string().when("formValidate", {
      is: "2",
      then: Yup.string().required("field is required"),
    }),
    message: Yup.string().when("formValidate", {
      is: "2",
      then: Yup.string().required("field is required"),
    }),
  });

  // const [doubleForm, setDoubleForm] = React.useState("");
  const handleChangeCJform = (event) => {
    setForm(event.target.value);
  };

  const defaultValues = {
    dueDate: "",
    subject: "",
    messagebody: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    const getInterventionAction = async () => {
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolIntervention/GetInterventionForms`
        );

        const { statusCode, message } = response.data;
        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) {
          let modifiedArray = data.map((row) => {
            const { id, name } = row;
            return {
              interventionFormId: id,
              name,
              isChecked: false,
            };
          });
          setIntervention([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (!unmounted) {
      getInterventionAction();
    }
    return () => {
      unmounted = true;
    };
  }, []);
  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    const getCjForms = async () => {
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolIntervention/GetCjForms`
        );

        const { statusCode, message } = response.data;
        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) {
          let modifiedArray = data.map((row) => {
            const { id, name } = row;
            return {
              interventionFormId: id,
              name,
              isChecked: false,
            };
          });
          setInterventionCjForm([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (!unmounted) {
      getCjForms();
    }
    return () => {
      unmounted = true;
    };
  }, []);

  const handleInterventionCjFormCheck = (e, index) => {
    let newInterventionCjArrary = [...interventionCjForm];
    newInterventionCjArrary[index].isChecked = e.target.checked;
    setInterventionCjForm([...newInterventionCjArrary]);
  };

  const handleInterventionFormCheck = (e, index) => {
    let newInterventionArray = [...intervention];
    newInterventionArray[index].isChecked = e.target.checked;
    setIntervention([...newInterventionArray]);
  };

  const onSubmit = async (data) => {
    try {
      console.log(data.dueDate);
      //const date = new Date(data.dueDate).toISOString();
      const checkedIntervention = intervention.filter((x) => x.isChecked);

      // if (checkedIntervention.length === 0) {
      //   enqueueSnackbar("select a form", { variant: "error" });

      //   // snackbar - tell the user to select intervention form
      //   return;
      // }

      if (form === undefined || form === "") {
        enqueueSnackbar("select a form", { variant: "error" });

        // snackbar - tell the user to select intervention form
        return;
      }

      if (form === "2" && (data.dueDate === undefined || data.dueDate === "")) {
        enqueueSnackbar("date is required", { variant: "error" });

        // snackbar - tell the user to select intervention form
        return;
      }

      if (form === "1") {
        var checkedCjForm = interventionCjForm.find((x) => x.isChecked);
        //console.log(checkedCjForm)
        if (checkedCjForm === undefined) {
          enqueueSnackbar("select a CJ form ", { variant: "error" });

          // snackbar - tell the user to select intervention form
          return;
        }
      }

      const response = await axoisCustomAgentForAdmin.post(
        `SchoolIntervention/Intervene`,
        {
          schoolId,
          interventionTypeId: form,
          interventionForms: checkedIntervention,
          subject: data.subject,
          message: data.message,
          dueDate: form === "2" ? new Date(data.dueDate).toISOString() : null,
          cjFormId:form === "1" ? interventionCjForm.find((x) => x.isChecked).interventionFormId : 0,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      closeAlertModal();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Intervention Action...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>
          <Box sx={{ marginBlock: 2 }}>
            <Typography variant="h5">
              Please pick which forms to send
            </Typography>

            <FormControl>
              <RadioGroup name="formValidate" row onChange={handleChangeCJform}>
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="CJ 1-3"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Single Forms"
                />
              </RadioGroup>

              {form === "2" ? (
                <Box>
                  <Grid container spacing={1}>
                    {intervention.map((row, index) => (
                      <Grid item xs={4} key={index}>
                        <input
                          type="checkbox"
                          name={row.name}
                          value={row.isChecked}
                          onChange={(e) =>
                            handleInterventionFormCheck(e, index)
                          }
                        />
                        <div fontSize={"12px"}>{row.name}</div>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid item mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Due Date"
                        disablePast
                        value={methods.watch().dueDate}
                        onChange={(value) => {
                          methods.setValue("dueDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="dueDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item mt={2}>
                    <RHFTextField
                      multiline={true}
                      variant="filled"
                      minRows={1}
                      placeholder="subject ..."
                      name="subject"
                    />
                  </Grid>

                  <Grid item mt={2}>
                    <RHFTextField
                      minRows={3}
                      multiline={true}
                      placeholder="message..."
                      name="message"
                      variant="filled"
                    />
                  </Grid>
                </Box>
              ) : null}

              {form === "1" ? (
                <Box>
                  <Grid container spacing={1}>
                    {interventionCjForm.map((row, index) => (
                      <Grid item xs={7}>
                        <input
                          type="radio"
                          name={row.name}
                          value={row.isChecked}
                          onChange={(e) =>
                            handleInterventionCjFormCheck(e, index)
                          }
                        />
                        <div fontSize={"12px"}>{row.name}</div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null}
            </FormControl>
            <Grid item sx={{ textAlign: "right", mt: 3 }}>
              <LoadingButton
                type="submit"
                color="secondary"
                endIcon={<Send />}
                variant="contained"
                loading={isSubmitting}
              >
                Send
              </LoadingButton>
            </Grid>
          </Box>
        </FormProvider>
      </Grid>
    </Fragment>
  );
}

export default AdminInterve;
