import PropTypes from "prop-types";
import { useParams, Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Tooltip, Typography, Checkbox } from "@mui/material";
// redux
import { useSelector } from "../../../redux/store";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// utils
import { fDate } from "../../../utils/formatTime";
import createAvatar from "../../../utils/createAvatar";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import Avatar from "../../../components/Avatar";
import Iconify from "../../../components/Iconify";
//
import MailItemAction from "./MailItemAction";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(0, 2),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up("md")]: { display: "flex", alignItems: "center" },
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z24,
    "& .showActions": { opacity: 1 },
  },
}));

const WrapStyle = styled(Link)(({ theme }) => ({
  minWidth: 0,
  display: "flex",
  padding: theme.spacing(2, 0),
  transition: theme.transitions.create("padding"),
}));

// ----------------------------------------------------------------------

const linkToMessage = (params, messageId) => {
  const { systemLabel } = params;
  const baseUrl = PATH_DASHBOARD.mail.root;

  if (systemLabel) {
    return `${baseUrl}/${systemLabel}/${messageId}`;
  }
  return baseUrl;
};

export default function MailInterventionItem({ message, ...other }) {
  const params = useParams();

  const { labels } = useSelector((state) => state.mail);

  const isDesktop = useResponsive("up", "md");

  //const isAttached = mail.files.length > 0;

  return (
    <RootStyle
      sx={{
        ...(!message.isRead && {
          color: "text.primary",
          backgroundColor: "background.paper",
        }),
      }}
      {...other}
    >
      <WrapStyle
        color="inherit"
        underline="none"
        component={RouterLink}
        to={linkToMessage(params, message.id)}
        // sx={{ ...(isDense && { py: 1 }) }}
      >
        <Avatar
          alt={"avatar"}
          src={""}
          color={createAvatar("avatar").color}
          sx={{ width: 32, height: 32 }}
        >
          {createAvatar(message.body).name}
        </Avatar>

        <Box
          sx={{
            ml: 2,
            minWidth: 0,
            alignItems: "center",
            display: { md: "flex" },
          }}
        >
          <Typography
            variant="body2"
            noWrap
            sx={{
              pr: 2,
              minWidth: 200,
              ...(message.isRead && { fontWeight: "fontWeightBold" }),
            }}
          >
            {message.isMessageFromSchool ? "You" : "Admin"}
          </Typography>

          <Typography
            noWrap
            variant="body2"
            sx={{
              pr: 2,
            }}
          >
            <Box
              component="span"
              sx={{ ...(message.isRead && { fontWeight: "fontWeightBold" }) }}
            >
              {message.subject}
            </Box>
            &nbsp;-&nbsp;
            <Box
              component="span"
              sx={{
                ...(message.isRead && { color: "text.secondary" }),
              }}
            >
              {message.body}
            </Box>
          </Typography>

          {isDesktop && (
            <>
              {/* {isAttached && (
                <Iconify
                  icon={"eva:link-fill"}
                  sx={{
                    mx: 2,
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                  }}
                />
              )} */}
            </>
          )}

          <Typography
            variant="caption"
            sx={{
              flexShrink: 0,
              minWidth: 120,
              textAlign: "right",
              ...(message.isRead && { fontWeight: "fontWeightBold" }),
            }}
          >
            {fDate(message.dateSent)}
          </Typography>
        </Box>
      </WrapStyle>

      {/* <MailItemAction className="showActions" /> */}
    </RootStyle>
  );
}
