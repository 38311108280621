// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_ADMIN = "/admin";
const ROOTS_STAFF = "/teacher";
const ROOTS_AandE = "/AandE";
const ROOTS_CLERK = "/auditClerks";
const ROOTS_EVENTMANAGER = "/eventManager";
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
  registrationInvoice: path(ROOTS_AUTH, "/registration-invoice"),
  termsNdConditions: path(ROOTS_AUTH, "/termsandcondition"),
  DecTermNdCondition: path(ROOTS_AUTH, "/DecTermNdCondition"),
  profileComplete: path(ROOTS_AUTH, "/profile-complete"),
  requestDemo: path(ROOTS_AUTH, "/request-demo-page"),
  adminLogin: path(ROOTS_AUTH, "/admin-login"),
  AandELogin: path(ROOTS_AUTH, "/A&E/AandELogin"),
  AandERegister: path(ROOTS_AUTH, "/A&E/AandERegister"),
  onSuccess: path(ROOTS_AUTH, "onSuccess"),
  createPassword: path(ROOTS_AUTH, "/admin-createPassword/:token"),
  // AandEPassword:path(ROOTS_AUTH),"/AandEPassword"),
  AandEPassword: path(ROOTS_AUTH, "/aande-createpassword/:code/:email"),
  AuditClerkPassword: path(
    ROOTS_AUTH,
    "/auditclerk-createpassword/:code/:email"
  ),
  initiativeNote: path(ROOTS_AUTH, "/initiativeNote"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
  bookAudit: "/bookAudit",
  publicAudit: "/publicAudit",
  privateAudit: "/privateAudit",
  sslagAboutUs: "/sslagAboutUs",
  bookAuditInvoice: "/bookAuditInvoice",
  publicAuditInvoice: "/publicAuditInvoice",
  AuditCongratulation: "AuditCongratulation/:schoolId/:reference",
  AuditStructure: "/AuditStructure",
  initiativeNote: "/initiativeNote",
  complianceObligations: "/complianceObligations",
  payLater: "/payLater",
  gallery: "/gallery",
  sslagHome: "/sslagHome",
  payAudit: "/pay-audit",
  currentEvent: "coming-event",
  payAuditInvoice: "/pay-audit-invoice/:tokenId",
  AuditPaymentCongratulation: "AuditPaymentCongratulation/:tokenId",
  marketplace: "market-place",
  optional: "Optional",
  comingEvent: "coming-event",

  eventSingle: "events-individual/:eventId",
  eventSchool: "events-school/:eventId",
  eventCongratulation: "event-congratulation",
  eventRegistration: "registration/page",
  bospdf: "bos-pdf",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    supportmail: path(ROOTS_DASHBOARD, "/supportmail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    conversation: path(ROOTS_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    student: path(ROOTS_DASHBOARD, `/user/student`),
    allStudent: path(ROOTS_DASHBOARD, "/user/allStudent"),
    allParent: path(ROOTS_DASHBOARD, "/user/allParent"),
    allStaff: path(ROOTS_DASHBOARD, "/user/allStaff"),
    staff: path(ROOTS_DASHBOARD, `/user/staff`),
    class: path(ROOTS_DASHBOARD, `/user/class`),
    aasign: path(ROOTS_DASHBOARD, `/user/assign`),
    classArm: path(ROOTS_DASHBOARD, `/user/classArm`),
    unallocate: path(ROOTS_DASHBOARD, `/user/unallocate `),
    attendance: path(ROOTS_DASHBOARD, `/user/attendance `),
    staffDuties: path(ROOTS_DASHBOARD, `/user/staffDuties `),
    addDuties: path(ROOTS_DASHBOARD, `/user/addDuties `),
    allAttendance: path(ROOTS_DASHBOARD, `/user/allAttendance `),
    studentBehaviour: path(ROOTS_DASHBOARD, `/user/Behaviour`),
    behaviourStudent: path(
      ROOTS_DASHBOARD,
      `/user/behaviourStudent/:yearGroupId/:classId`
    ),

    quickGuide: path(ROOTS_DASHBOARD, `/user/quickGuide `),
    miniStandard: path(ROOTS_DASHBOARD, `/user/miniStandard `),
    protocolGuidance: path(ROOTS_DASHBOARD, `/user/protocolGuidance `),
    sms: path(ROOTS_DASHBOARD, `/user/sms `),
    checklist: path(ROOTS_DASHBOARD, `/user/checklist`),
    ManageBos: path(ROOTS_DASHBOARD, `/user/ManageBos`),

    assessment: path(ROOTS_DASHBOARD, `/user/riskAssessment`),
    incident: path(ROOTS_DASHBOARD, `/user/checklist`),
    playequipment: path(ROOTS_DASHBOARD, `/user/checklist`),
    foodvendor: path(ROOTS_DASHBOARD, `/user/checklist`),
    preparedness: path(ROOTS_DASHBOARD, `/user/checklist`),
    support: path(ROOTS_DASHBOARD, `/user/support`),

    safetyAudit: path(ROOTS_DASHBOARD, `/user/safetyAudit`),
    framework: path(ROOTS_DASHBOARD, `/user/framework`),
    // IntroductionAcknowledgment: path(
    //   ROOTS_DASHBOARD,
    //   `/user/IntroductionAcknowledgment`
    // ),
    IntroductionAcknowledgment: path(ROOTS_DASHBOARD, ` /coming-soon`),

    postAudit: path(ROOTS_DASHBOARD, `/user/postAudit `),
    sslagEvaluation: path(ROOTS_DASHBOARD, `/user/sslagEvaluation `),
    protocols: path(ROOTS_DASHBOARD, `/user/protocols `),
    termsGlossary: path(ROOTS_DASHBOARD, `/user/termsGlossary `),
    documentaryEvidence: path(ROOTS_DASHBOARD, `/user/documentaryEvidence`),
    projectImplementation: path(
      ROOTS_DASHBOARD,
      `/user/projectImplementation `
    ),

    manadatorySupplies: path(ROOTS_DASHBOARD, `/user/manadatorySupplies `),
    certificateParticipation: path(
      ROOTS_DASHBOARD,
      `/user/certificateParticipation `
    ),
    safetyInfrastructure: path(ROOTS_DASHBOARD, `/user/safetyInfrastructure `),
    insurance: path(ROOTS_DASHBOARD, `/user/insurance `),
    safeInternet: path(ROOTS_DASHBOARD, `/user/safeInternet `),
    signages: path(ROOTS_DASHBOARD, `/user/signages `),

    outsourceService: path(ROOTS_DASHBOARD, `/user/outsourceService `),

    profile: path(ROOTS_DASHBOARD, "/user/classes"),
    cards: path(ROOTS_DASHBOARD, "/user/student"),
    new: path(ROOTS_DASHBOARD, "/user/parent"),
    profileSetting: path(ROOTS_DASHBOARD, "/user/profileSetting"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    complianceMail: path(ROOTS_DASHBOARD, "/user/complianceMail"),
  },

  knowledgeTransfer: {
    root: path(ROOTS_DASHBOARD, "/knowledgeTransfer"),
    bookingForm: path(ROOTS_DASHBOARD, "/knowledgeTransfer/bookingForm"),
    ktCalender: path(ROOTS_DASHBOARD, "/knowledgeTransfer/ktCalender"),
    ktOnlineCourse: path(ROOTS_DASHBOARD, "/knowledgeTransfer/ktOnlineCourse"),
    compReq: path(ROOTS_DASHBOARD, "/knowledgeTransfer/compReq"),
  },

  schoolCompliancejourney: {
    root: path(ROOTS_DASHBOARD, "/schoolComplianceJourney"),
    InsetBookingForm: path(ROOTS_DASHBOARD, "/InsetBookingForm/:token"),
    CbcBookingForm: path(ROOTS_DASHBOARD, "/CbcBookingForm/:token"),
    cj001letter: path(ROOTS_DASHBOARD, "/cj001letter/:token"),
    provisonalsafetylicenseapplication: path(
      ROOTS_DASHBOARD,
      "/provisonalsafetylicenseapplication/:token"
    ),
    SchoolCJSms: path(ROOTS_DASHBOARD, "/importantnotesaboutsms/:token"),
    DataExtractionTable: path(ROOTS_DASHBOARD, "/DataExtractionTable/:token"),
    StaffStatusReport: path(ROOTS_DASHBOARD, "/StaffStatusReport/:token"),
    humanresourcelogtable5a: path(
      ROOTS_DASHBOARD,
      "/humanresourcelogtable5a/:token"
    ),
    FeedbackForm: path(ROOTS_DASHBOARD, "/FeedbackForm/:token"),
    ktcoursebookingformapp5b: path(
      ROOTS_DASHBOARD,
      "/ktcoursebookingformapp5b/:token"
    ),
    combinedEvaluationSummary: path(
      ROOTS_DASHBOARD,
      "/combinedEvaluationSummary/:token"
    ),
    SslagInvoice: path(ROOTS_DASHBOARD, "/SslagInvoice"),
    saferecruitmentevaluationform: path(
      ROOTS_DASHBOARD,
      "/saferecruitmentevaluationform/:token"
    ),
  },
  support: {
    root: path(ROOTS_DASHBOARD, "/support"),
    faqHelp: path(ROOTS_DASHBOARD, "/support/faqHelp"),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    product: path(ROOTS_DASHBOARD, "/e-commerce/product/:name"),
    productById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    newProduct: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    editById: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    invoice: path(ROOTS_DASHBOARD, "/e-commerce/invoice"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    alerts: path(ROOTS_DASHBOARD, "/blog/alerts"),
    appNewinvoice: path(ROOTS_DASHBOARD, "/blog/appNewinvoice"),
    pending: path(ROOTS_DASHBOARD, "/blog/pending"),
    accident: path(ROOTS_DASHBOARD, "/blog/accident"),
    schoolEvent: path(ROOTS_DASHBOARD, "/blog/schoolEvent"),

    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    post: path(ROOTS_DASHBOARD, "/blog/post/:title"),
    postById: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
    newPost: path(ROOTS_DASHBOARD, "/blog/new-post"),
  },

  schoolAudit: {
    root: path(ROOTS_DASHBOARD, "/schoolAudit/"),
    schoolInitiative: path(ROOTS_DASHBOARD, "/schoolAudit/Audit-Notice"),
    schoolAuditOrder: path(ROOTS_DASHBOARD, "/schoolAudit/school-auditOrder"),
    privateSchool: path(ROOTS_DASHBOARD, "/schoolAudit/PrivateSchool"),
    publicSchool: path(ROOTS_DASHBOARD, "/schoolAudit/PublicSchool"),
    schoolAuditInvoice: path(
      ROOTS_DASHBOARD,
      "/schoolAudit/schoolAuditInvoice/:schoolId"
    ),
    SchoolAuditcongrate: path(
      ROOTS_DASHBOARD,
      "schoolAudit/SchoolAuditcongrate/:schoolId"
    ),
  },
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  Dashboard: {
    admin: path(ROOTS_ADMIN, "dashboard"),
  },
  UserManagement: {
    root: path(ROOTS_ADMIN, "/users"),
    createAdmins: path(ROOTS_ADMIN, "/createAdmins"),
    manageAdmin: path(ROOTS_ADMIN, "/manageAdmins"),
  },
  SchoolManagement: {
    root: path(ROOTS_ADMIN, "/school"),
    training: path(ROOTS_ADMIN, "/training"),
    AdminAttendance: path(ROOTS_ADMIN, "/admin-attendance"),
    staffManagement: path(ROOTS_ADMIN, "/staff-management"),
    discountManagement: path(ROOTS_ADMIN, "/discountManagement"),
    schoolsAllstudent: path(ROOTS_ADMIN, "/schools-allstudent"),
    SchoolsAllschools: path(ROOTS_ADMIN, "/schools/allschools"),
    AdminBosService: path(ROOTS_ADMIN, "/AdminBosCertificate"),
  },

  marketPlace: {
    root: path(ROOTS_ADMIN, "/marketPlace"),
    ManageCollaSME: path(ROOTS_ADMIN, "/ManageCollaSME"),
    Order: path(ROOTS_ADMIN, "/Order"),
  },
  Event: {
    root: path(ROOTS_ADMIN, "/marketPlace"),
    schoolEvent: path(ROOTS_ADMIN, "school-event"),
    sslagEvent: path(ROOTS_ADMIN, "sslag-event"),
    manageEvent: path(ROOTS_ADMIN, "manage-attendence/:eventId"),
    invoices: path(ROOTS_ADMIN, "invoices"),
    analytics: path(ROOTS_ADMIN, "analytics"),
  },
  knowledgeTransfer: {
    root: path(ROOTS_ADMIN, "/knowledgeTransfer"),
    createKnowledgeTransfer: path(ROOTS_ADMIN, "create"),
    manageKnowledgeTransfer: path(ROOTS_ADMIN, "manage"),
    KnowledgeTransferCalender: path(ROOTS_ADMIN, "calender"),
  },

  audit: {
    root: path(ROOTS_ADMIN, "/audit"),
    preAuditProtocol: path(ROOTS_ADMIN, "pre-audit-protocol"),
    onSiteProtocol: path(ROOTS_ADMIN, "onSiteProtocol"),
    Identification: path(ROOTS_ADMIN, "Identification"),
    assessors: path(ROOTS_ADMIN, "assessors/:schoolId"),
    onboarded: path(ROOTS_ADMIN, "OnboardedSchool/:schoolId"),
    postAuditProtocol: path(ROOTS_ADMIN, "auditReport"),
    AuditRegister: path(ROOTS_ADMIN, "AuditRegister"),
    auditedSchool: path(ROOTS_ADMIN, "audited-school"),
    gradingForm: path(ROOTS_ADMIN, "gradingForm/:taskId/:clerkId/:roleId"),
    startOfDay: path(ROOTS_ADMIN, "startOdDay/:taskId/:clerkId/:roleId"),
    endOfday: path(ROOTS_ADMIN, "endOfday/:taskId/:clerkId/:roleId"),
    sport: path(ROOTS_ADMIN, "sport/:taskId/:clerkId/:roleId"),

    LessonObservation: path(ROOTS_ADMIN, "LessonObservation/:taskId"),
    walkround: path(ROOTS_ADMIN, "walkround/:taskId/:clerkId/:roleId"),
    MealTime: path(ROOTS_ADMIN, "MealTime/:taskId/:clerkId/:roleId"),
    SickBay: path(ROOTS_ADMIN, "SickBay/:taskId/:clerkId/:roleId"),
    MealPreparation: path(
      ROOTS_ADMIN,
      "MealPreparation/:taskId/:clerkId/:roleId"
    ),
    playground: path(ROOTS_ADMIN, "playground/:taskId/:clerkId/:roleId"),
    outdoorActivites: path(
      ROOTS_ADMIN,
      "outdoorActivites/:taskId/:clerkId/:roleId"
    ),
    specialRoom: path(ROOTS_ADMIN, "SpecialRoom/:taskId/:clerkId/:roleId"),
    complianceCriterion1: path(
      ROOTS_ADMIN,
      "complianceCriterion1/:taskId/:clerkId/:roleId"
    ),
    complianceCriterion2: path(
      ROOTS_ADMIN,
      "complianceCriterion2/:taskId/:clerkId/:roleId"
    ),
    complianceCriterion3: path(
      ROOTS_ADMIN,
      "complianceCriterion3/:taskId/:clerkId/:roleId"
    ),
    staffRoles: path(ROOTS_ADMIN, "staffRoles/:taskId /:clerkId/:roleId"),
    safeSchoolPolicies: path(
      ROOTS_ADMIN,
      "safeSchoolPolicies/:taskId/:clerkId/:roleId"
    ),
    Safeguarding: path(ROOTS_ADMIN, "Safeguarding/:taskId"),
    mandatorySupplies: path(
      ROOTS_ADMIN,
      "mandatorySupplies/:taskId/:clerkId/:roleId"
    ),
    schoolTransit: path(ROOTS_ADMIN, "schoolTransit/:taskId/:clerkId/:roleId"),
    recommdation: path(ROOTS_ADMIN, "recommdation/:taskId/:clerkId/:roleId"),
    assembly: path(ROOTS_ADMIN, "assembly/:taskId/:clerkId/:roleId"),
  },
  OnboardingProcess: {
    root: path(ROOTS_ADMIN, "/OnboardingProcess"),
    newform: path(ROOTS_ADMIN, "newform"),
    pendingRequest: path(ROOTS_ADMIN, "/OnboardingProcess/pendingRequest"),
    requestIntervention: path(ROOTS_ADMIN, "requestIntervention"),
    newFormSchool: path(ROOTS_ADMIN, "newFormSchool"),
    adminOnboarding: path(ROOTS_ADMIN, "adminOnboarding"),
    adminInvoice: path(ROOTS_ADMIN, "adminInvoice"),

    adminschooldetails: path(ROOTS_ADMIN, "adminschooldetails/:schoolId"),
    adminschoolPersonelmoe: path(
      ROOTS_ADMIN,
      "adminschoolPersonelmoe/:schoolId"
    ),
    adminschoolprovision: path(ROOTS_ADMIN, "adminschoolprovision/:schoolId"),
    adminschoolmandatoryInsurance: path(
      ROOTS_ADMIN,
      "adminschoolmandatory-insurance/:schoolId"
    ),
    adminschoolstarterPack: path(
      ROOTS_ADMIN,
      "adminschoolstarterPack/:schoolId"
    ),
    adminschooldeclarations: path(ROOTS_ADMIN, "adminschooldeclarations"),
    adminschoolsafetyManagement: path(
      ROOTS_ADMIN,
      "adminschoolsafety-management/:schoolId"
    ),

    adminAllAlert: path(ROOTS_ADMIN, "adminAllAlert/:schoolId"),

    adminInterventionForm: path(
      ROOTS_ADMIN,
      "adminInterventionForm/:tokenId/:schoolId/:formId"
    ),
  },

  complianceJourney: {
    root: path(ROOTS_ADMIN, "/complianceJourney"),
    AdminFeedBackForm: path(ROOTS_ADMIN, "AdminFeedBackForm"),
    AdminCJLetter: path(ROOTS_ADMIN, "AdminCJLetter"),
    KnowledgeTransferBooking: path(ROOTS_ADMIN, "KnowledgeTransferBooking"),
    JourneyBookingForm: path(ROOTS_ADMIN, "JourneyBookingForm"),
    JourneyStaffReport: path(ROOTS_ADMIN, "JourneyStaffReport"),
    JourneyProvisionalSafety: path(ROOTS_ADMIN, "JourneyProvisionalSafety"),
    JourneyImportantNote: path(ROOTS_ADMIN, "JourneyImportantNote"),
    JourneyInsetbooking: path(ROOTS_ADMIN, "JourneyInsetbooking"),
    JourneyDataExtractionTable: path(ROOTS_ADMIN, "JourneyDataExtractionTable"),
    JourneyHumanResources: path(ROOTS_ADMIN, "JourneyHumanResources"),
  },
  analytics: {
    root: path(ROOTS_ADMIN, "/admin/analytic"),
  },
  report: {
    root: path(ROOTS_ADMIN, "/admin/reports"),
  },
  support: {
    // root: path(ROOTS_ADMIN, "/admin/support"),
    root: path(ROOTS_ADMIN, "/admin/concession"),
  },
};

export const PATH_STAFF = {
  root: ROOTS_STAFF,
  Dashboard: {
    admin: path(ROOTS_STAFF, "dashboard"),
  },
  // attendance: {
  //   root: path(ROOTS_TEACHER, "/teacher/attendance"),
  // },

  school: {
    root: path(ROOTS_STAFF, "/attendance"),
    markAttendance: path(ROOTS_STAFF, "/mark-attendance"),
    viewAttendance: path(ROOTS_STAFF, "/view-attendance"),
  },

  staffDuty: {
    root: path(ROOTS_STAFF, "/staff/staffDuty"),
  },
};

export const PATH_AandE = {
  root: ROOTS_AandE,
  Dashboard: {
    admin: path(ROOTS_AandE, "dashboard"),
  },

  Taskboards: {
    root: path(ROOTS_AandE, "/taskboards"),
    auditClerks: path(ROOTS_AandE, "/AuditClerks"),
    AandEAssignSchool: path(ROOTS_AandE, "/AandEAssignSchool"),
  },

  Support: {
    root: path(ROOTS_AandE, "/support"),
    support: path(ROOTS_AandE, "/SupportUsForm"),
  },
};

export const PATH_CLERK = {
  root: ROOTS_CLERK,
  Dashboard: {
    admin: path(ROOTS_CLERK, "dashboard"),
  },

  Osp: {
    root: path(ROOTS_CLERK, "/Osp"),

    auditTask: path(ROOTS_CLERK, "/AuditTask"),
    AuditGradingGeneral: path(ROOTS_CLERK, "/AuditGradingGeneral/:taskId"),
    LessonObservation: path(ROOTS_CLERK, "/LessonObservation/:taskId"),
    Assembly: path(ROOTS_CLERK, "/Assembly/:taskId"),
    StartOfDay: path(ROOTS_CLERK, "/StartOfDay/:taskId"),
    MealTime: path(ROOTS_CLERK, "/MealTime/:taskId"),
    SickBay: path(ROOTS_CLERK, "/SickBay/:taskId"),
    MealPreparation: path(ROOTS_CLERK, "/MealPreparation/:taskId"),
    EndOfday: path(ROOTS_CLERK, "/EndOfday/:taskId"),
    Playground: path(ROOTS_CLERK, "/Playground/:taskId"),
    Sport: path(ROOTS_CLERK, "/Sport/:taskId"),
    OutdoorActivites: path(ROOTS_CLERK, "/OutdoorActivites/:taskId"),
    SpecialRoom: path(ROOTS_CLERK, "/SpecialRoom/:taskId"),
    LabSession: path(ROOTS_CLERK, "/LabSession/:taskId"),
    starter: path(ROOTS_CLERK, "/StarterPack&RecommendedSafety/:taskId"),
    GradingPolicies: path(ROOTS_CLERK, "/GradingPolicies/:taskId"),
    Safeguarding: path(ROOTS_CLERK, "/Safeguarding/:taskId"),
    StaffRoles: path(ROOTS_CLERK, "/StaffRoles/:taskId"),
    Recommendation: path(ROOTS_CLERK, "Recommendation/:taskId"),
    SchoolTransit: path(ROOTS_CLERK, "/SchoolTransit"),
    GradingManagement: path(ROOTS_CLERK, "/gradingManagement"),
    SchoolTransit: path(ROOTS_CLERK, "/SchoolTransit"),
    walkround: path(ROOTS_CLERK, "/walkround"),
    complianceCriterion1: path(ROOTS_CLERK, "/complianceCriterion1"),
    complianceCriterion2: path(ROOTS_CLERK, "/complianceCriterion2"),
    auditClerkCongrat: path(ROOTS_CLERK, "/auditClerkCongrat/:taskId"),

    SchoolSafety: path(ROOTS_CLERK, "SchoolSafety"),
  
  },
};
export const PATH_EVENTMANAGER= {
  root: ROOTS_EVENTMANAGER,
  Dashboard: {
    admin: path(ROOTS_EVENTMANAGER, "dashboard"),
  },

  Osp: {
    root: path(ROOTS_EVENTMANAGER, "/Osp"),
    auditTask: path(ROOTS_EVENTMANAGER, "/AuditTask"),
    SchoolSafety: path(ROOTS_EVENTMANAGER, "SchoolSafety"),
 
  },
};
export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
