import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
  Button,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";

import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function WalkRound({ nextStep, prevStep, schoolId, auditId }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const defaultValues = {
    isJuniorPlaygroundAvailable: "",
    isPlaygroundHazardSafe: "",
    areCorridorsHazardFree: "",
    isPremisesLitterFree: "",
    areDoorsObstructionFree: "",
    arePlaygroundEquipmentBroken: "",
    areClassroomsNaturallyLit: "",
    areFireDoorsInstalled: "",
    areClassroomsSpacious: "",
    areLightingFittingsStandard: "",
    areSanitaryFacilitiesAvailable: false,
    isDrinkingWaterAvailable: false,
    areSeparateStaffFacilitiesAvailable: false,
    isPerimeterFencingAvailable: false,
    isStorageForPupilsBelongingsAvailable: false,
    isFoodStorageFacilityAvailable: false,
    isSchoolTransitPolicyAvailable: false,
    doPupilsHaveAccessToRefrigerator: false,
    absensceofElectricalWiresOnFloor: false,
    clearlyMarkedMusterPoint: false,
    lollipopPersonnelPresent: false,
    areToiletsClean: false,
    areToiletsDry: false,
    tissuesOrWaterHosesForWashing: false,
    washHandBasin: false,
    comments: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `Audit/SumbitSectionTwoWalkaround`,
        {
          auditTaskId: taskId,
          isJuniorPlaygroundAvailable: dataToSubmit.isJuniorPlaygroundAvailable,
          isPlaygroundHazardSafe: dataToSubmit.isPlaygroundHazardSafe,
          areCorridorsHazardFree: dataToSubmit.areCorridorsHazardFree,
          isPremisesLitterFree: dataToSubmit.isPremisesLitterFree,
          areDoorsObstructionFree: dataToSubmit.areDoorsObstructionFree,
          arePlaygroundEquipmentBroken:
            dataToSubmit.arePlaygroundEquipmentBroken,
          areClassroomsNaturallyLit: dataToSubmit.areClassroomsNaturallyLit,
          areFireDoorsInstalled: dataToSubmit.areFireDoorsInstalled,
          areClassroomsSpacious: dataToSubmit.areClassroomsSpacious,
          areLightingFittingsStandard: dataToSubmit.areLightingFittingsStandard,
          areSanitaryFacilitiesAvailable:
            dataToSubmit.areSanitaryFacilitiesAvailable,
          isDrinkingWaterAvailable: dataToSubmit.isDrinkingWaterAvailable,
          areSeparateStaffFacilitiesAvailable:
            dataToSubmit.areSeparateStaffFacilitiesAvailable,
          isPerimeterFencingAvailable: dataToSubmit.isPerimeterFencingAvailable,
          isStorageForPupilsBelongingsAvailable:
            dataToSubmit.isStorageForPupilsBelongingsAvailable,
          isFoodStorageFacilityAvailable:
            dataToSubmit.isFoodStorageFacilityAvailable,
          isSchoolTransitPolicyAvailable:
            dataToSubmit.isSchoolTransitPolicyAvailable,
          doPupilsHaveAccessToRefrigerator:
            dataToSubmit.doPupilsHaveAccessToRefrigerator,
          absensceofElectricalWiresOnFloor:
            dataToSubmit.absensceofElectricalWiresOnFloor,
          clearlyMarkedMusterPoint: dataToSubmit.clearlyMarkedMusterPoint,
          lollipopPersonnelPresent: dataToSubmit.lollipopPersonnelPresent,
          areToiletsClean: dataToSubmit.areToiletsClean,
          areToiletsDry: dataToSubmit.areToiletsDry,
          tissuesOrWaterHosesForWashing:
            dataToSubmit.tissuesOrWaterHosesForWashing,
          washHandBasin: dataToSubmit.washHandBasin,
          comments: dataToSubmit.comments,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            minWidth: 500,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 2: Walkround
                </Typography>
              </Box>
              <ol>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid width={"48%"} p={3}>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Does the school have different playgrounds for junior
                          sections?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isJuniorPlaygroundAvailable"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Is playground safe of hazards/props used?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isPlaygroundHazardSafe"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Are the corridors free of hazards?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areCorridorsHazardFree"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Is the school premises litter-free?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isPremisesLitterFree"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Door obstruction free?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areDoorsObstructionFree"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Appropriate playground equipment non defective or
                          broken?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="arePlaygroundEquipmentBroken"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Are there natural lightning of the classrooms?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areClassroomsNaturallyLit"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Are fire doors installed?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areFireDoorsInstalled"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        {" "}
                        <Typography>Are the classrooms spacious?</Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areClassroomsSpacious"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Are lighting fitting meet safety standard?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="areLightingFittingsStandard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                  </Grid>
                  {/* <Grid
                    sx={{
                      mt: 3,
                      borderLeft: "2px solid  #018fe2",
                      minHeight: 70,
                      // borderleft: 6px solid green;
                    }}
                  ></Grid> */}
                  <Grid width={"48%"}>
                    <Box p={3}>
                      <Typography variant="h6">
                        Evidence of Safety Infrastructure
                      </Typography>
                      <Typography>
                        *Please indicate all that are seen{" "}
                      </Typography>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="areSanitaryFacilitiesAvailable"
                          label="Sanitary Facilities"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="isDrinkingWaterAvailable"
                          label="Drinking Water"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="areSeparateStaffFacilitiesAvailable"
                          label="Separate Staff Facilities"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="isPerimeterFencingAvailable"
                          label="Perimeter Fencing"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="isStorageForPupilsBelongingsAvailable"
                          label="Designated Place for Storing Pupils Belongings"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="isFoodStorageFacilityAvailable"
                          label="Food Storage Facilities"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="isSchoolTransitPolicyAvailable"
                          label="School Transit Policy"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="doPupilsHaveAccessToRefrigerator"
                          label="Pupils Have Access To Refrigerators To Store Packed Lunches"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="absensceofElectricalWiresOnFloor"
                          label="Absence Of Electrical Wire Trails On The Floor"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="clearlyMarkedMusterPoint"
                          label="Clearly Marked Muster Point"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="lollipopPersonnelPresent"
                          label="Lollipop Personnel Present at the Start and End of School Day"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="areToiletsClean"
                          label="Clean Toilets"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox name="areToiletsDry" label="Dry Toilet" />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="tissuesOrWaterHosesForWashing"
                          label="Tissues in Toilet or water Hoses for Washing"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFCheckbox
                          name="washHandBasin"
                          label="Washing Hand Basin"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="comments"
                          label="Comments"
                          variant="filled"
                          type=""
                          minRows={2}
                          multiline
                        />
                      </li>
                    </Box>
                  </Grid>
                </Box>
              </ol>

              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    loading={isSubmitting}
                    // onClick={() => handleDirectionChange("forward")}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default WalkRound;
