import React from 'react'
import {

  Box, Card, CardActionArea, CardContent, Typography,
  
} from "@mui/material";
import onboardingImg from "../../images/Mask group.png";
import InterventionImg from "../../images/Mask group (1).png";
import AuditImg from "../../images/Mask group (2).png";
import EventImg from "../../images/Mask group (3).png"

// import logoBg from "../../images/sslag.png";

function AdminDashboardTop({
  numberOfSchoolInterventions,
  numberOfPendingRequests,
}) {


// const handleNavigate= 


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Card
        style={{
          background: "#edf5f9",
          height: "200px",
          width: "200px",
          textAlign: "center",
        }}
      >
        <CardActionArea href="/admin/OnboardingProcess/newForm">
          <Box
            sx={{
              background: "#008000",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={onboardingImg}
              alt=""
              style={{
                paddind: 8,
                width: 40,
              }}
            />
          </Box>
          <Box>
            <Typography padding={4} variant="h5">
              Onboarding
            </Typography>
            <Typography> 0</Typography>
          </Box>
          <CardContent></CardContent>
        </CardActionArea>
      </Card>
      <Card
        style={{
          background: "#edf5f9",
          height: "200px",
          width: "200px",
          textAlign: "center",
        }}
      >
        <CardActionArea href="/admin/OnboardingProcess/pendingRequest">
          <Box
            sx={{
              background: "#6F8FAF",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={InterventionImg}
              alt=""
              style={{
                paddind: 8,
                width: 40,
              }}
            />

            {/* <Camera /> */}
          </Box>
          <Box>
            <Typography padding={4} variant="h5">
              Intervention
            </Typography>

            <Typography> {numberOfSchoolInterventions}</Typography>
          </Box>
          <CardContent></CardContent>
        </CardActionArea>
      </Card>
      <Card
        style={{
          background: "#edf5f9",
          height: "200px",
          width: "200px",
          textAlign: "center",
        }}
      >
        <CardActionArea href="/admin/event/school-event">
          <Box
            sx={{
              background: "gray",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={EventImg}
              alt=""
              style={{
                paddind: 9,
                width: 35,
              }}
            />
          </Box>{" "}
          <Box>
            <Typography padding={4} variant="h5">
              Events
            </Typography>
            <Typography> 0</Typography>
          </Box>
          <CardContent></CardContent>
        </CardActionArea>
      </Card>
      <Card
        style={{
          background: "#edf5f9",
          height: "200px",
          width: "200px",
          textAlign: "center",
        }}
      >
        <CardActionArea href="/admin/audit/managementEducation">
          <Box
            sx={{
              background: "#FF5733",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={AuditImg}
              alt=""
              style={{
                paddind: 8,
                width: 40,
              }}
            />
          </Box>{" "}
          <Box>
            <Typography padding={4} variant="h5">
              Audit
            </Typography>
            <Typography> 0</Typography>
          </Box>
          <CardContent></CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default AdminDashboardTop