import React, { useState, useEffect } from "react";
import Bg1 from "../../images/sslagLandingImg.jpg";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box as Grid, Typography, Button, Container } from "@mui/material";
import Logo from "src/components/Logo";
import DirectorForm from "./DirectorForm";
import DirectorList from "./DirectorList";
import useAuth from "../../hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { booleanToNumber } from "src/utils/booleanNumberConverter";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import Next from "@material-ui/icons/ChevronRight";
import Back from "@material-ui/icons/ChevronLeft";

function SchoolDirector({ nextStep, prevStep }) {
  const { enqueueSnackbar } = useSnackbar();

  const { userData } = useAuth();
  const { schoolId } = userData;

  const initialState = {
    doesSchoolDirectorsHaveQAFitPersonClearance: "",
    schoolDirectors: [],
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDirectors = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `SchoolOnboarding/GetSchoolDirectors/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const {
            doesSchoolDirectorsHaveQAFitPersonClearance,
            schoolDirectors,
          } = response.data.data;

          let schoolDirectorsCustom = [];

          if (schoolDirectors.length > 0) {
            schoolDirectorsCustom = schoolDirectors.map((eachItem, index) => {
              return {
                ...eachItem,
                status: "old",
              };
            });
          }

          let boolToNumber = booleanToNumber(
            doesSchoolDirectorsHaveQAFitPersonClearance
          );

          setState({
            doesSchoolDirectorsHaveQAFitPersonClearance: boolToNumber,
            schoolDirectors: schoolDirectorsCustom,
          });
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchDirectors();
  }, [schoolId]);

  const addDirector = ({ name, email, phoneNumber, imageUrl, imageId }) => {
    const Id = state.schoolDirectors.length + 1;
    setState({
      ...state,
      schoolDirectors: [
        ...state.schoolDirectors,
        {
          Id,
          name,
          email,
          phoneNumber,
          imageUrl: imageUrl === "" ? null : imageUrl,
          imageId: imageId === "" ? null : imageId,
          status: "new",
        },
      ],
    });
  };

  const deleteDirector = async (indexToDelete) => {
    const directorToDelete = state.schoolDirectors[indexToDelete];

    if (directorToDelete.status === "old") {
      await axoisCustomAgent.delete(
        `/SchoolOnboarding/${directorToDelete.directorId}/RemoveSchoolDirector/${schoolId}`
      );
    }

    const newArray = state.schoolDirectors.filter(
      (s, index) => index !== indexToDelete
    );
    setState({
      ...state,
      schoolDirectors: [...newArray],
    });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      doesSchoolDirectorsHaveQAFitPersonClearance: event.target.value,
    });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const testSubmit = async () => {
    const newDirectors = state.schoolDirectors
      .filter((sd) => sd.status === "new")
      .map((item) => {
        return {
          name: item.name,
          email: item.email,
          phoneNumber: item.phoneNumber,
          imageId: item.imageId,
          imageUrl: item.imageUrl,
        };
      });

    const { doesSchoolDirectorsHaveQAFitPersonClearance } = state;

    setLoadingSubmit(true);

    try {
      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/CreateSchoolDirectors/${schoolId}`,
        {
          doesSchoolDirectorsHaveQAFitPersonClearance,
          schoolDirectors: newDirectors,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: `  url(${Bg1}) `,
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              background: "#ffff",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 2,
              padding: 2,
              marginTop: 15,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
              maxHeight: 800,
              MinWidth: 50,
            }}
          >
            <Typography color={"#6495ed"} sx={{ padding: 1, fontSize: "20px" }}>
              ELIGIBILITY OF SCHOOL DIRECTORS / OWNERS
            </Typography>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid>
                  <FormControl
                    sx={{
                      width: 400,
                      // ml: 2,
                    }}
                  >
                    <FormHelperText>
                      Evidence of SSLAG Fit Person Checks
                    </FormHelperText>
                    <Select
                      labelId="Title"
                      variant="filled"
                      id="PersonCheck"
                      value={state.doesSchoolDirectorsHaveQAFitPersonClearance}
                      // label="Age"
                      onChange={(e) => handleChange(e, "")}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                  {state.doesSchoolDirectorsHaveQAFitPersonClearance === 1 ? (
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <div>
                        <DirectorForm addDirector={addDirector} />
                      </div>
                      <div>
                        <DirectorList
                          directorsArray={state.schoolDirectors}
                          deleteDirector={deleteDirector}
                        />
                      </div>
                    </div>
                  ) : null}

                  <Grid sx={{ mt: 5, textAlign: "right" }}>
                    <LoadingButton
                      loading={loadingSubmit}
                      label="Prev"
                      variant="contained"
                      type="submit"
                      sx={{ mr: 4 }}
                      startIcon={<Back />}
                      onClick={prevStep}
                    >
                      Back
                    </LoadingButton>

                    <LoadingButton
                      loading={loadingSubmit}
                      label="next"
                      variant="contained"
                      type="submit"
                      endIcon={<Next />}
                      onClick={testSubmit}
                    >
                      Continue
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SchoolDirector;
