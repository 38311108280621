import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import outsource from "../../../images/outsource.jpg";
import mandatorySupply from "../../../images/mandatorySupply.jpg";
import optionals from "../../../images/optional service.jpg";
import otherService from "../../../images/otherService.jpg";
import specialDeal from "../../../images/specialDeal.jpg";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function MarketCategory() {
  return (
    <RootStyle>
      <Container
        component={MotionContainer}
        sx={{ position: "relative", height: "100%" }}
      >
        <Grid
          display={"grid"}
          justifyContent={"space-evenly"}
          mt={5}
          sx={{
            gap: { xs: 5, lg: 5 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
            },
          }}
        >
          <Card sx={{ minWidth: 275, background: "#edf5f9" }}>
            <CardActionArea href="/mandatory-supply">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={mandatorySupply}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Mandatory Supply </Typography>
                </Box>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ minWidth: 275, background: "#F3EB9A" }}>
            <CardActionArea href="/optional">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={optionals}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Optional Supply</Typography>
                </Box>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid
          display={"grid"}
          justifyContent={"space-evenly"}
          mt={5}
          sx={{
            gap: { xs: 5, lg: 5 },
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          <Card sx={{ minWidth: 275, background: "#edf5f9" }}>
            <CardActionArea href="/other-service">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={otherService}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Other Service</Typography>
                </Box>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ minWidth: 275, background: "#D8CDCB" }}>
            <CardActionArea href="/special-deal">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={specialDeal}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Special Deals </Typography>
                </Box>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ minWidth: 275, background: "#CFF39A" }}>
            <CardActionArea href="/outsource-service">
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={outsource}
                    alt=""
                    style={{
                      width: 60,
                    }}
                  />
                </Box>
                <Box sx={{ fontSize: 14, mt: 3 }} color="text.secondary">
                  <Typography variant="h5"> Outsource Service </Typography>
                </Box>
              </CardContent>
              <CardContent></CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Container>
    </RootStyle>
  );
}
