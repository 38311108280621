// @mui
import { styled } from "@mui/material/styles";
import { Divider, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AboutHero,
  AboutWhat,
  AboutTeam,
  AboutVision,
  AboutTestimonials,
} from "../sections/about";
import { SSlagAboutHero } from "./auth/AboutSSlag";
import AboutUsFramework from "./auth/AboutSSlag/AboutUsFramework";
import FrameworkTwo from "./auth/AboutSSlag/FrameworkTwo";
import AboutUsSLider from "./auth/AboutSSlag/AboutUsSLider";
import KeyElement from "./auth/AboutSSlag/KeyElement";
import SSLAGTeam from "./auth/AboutSSlag/SSLAGTeam";
import AdvocacyEvent from "./dashboard/Advocacys/AdvocacyEvent";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(11),
  },
}));

// ----------------------------------------------------------------------

export default function Advocacy() {
  return (
    <Page title="About us">
      <RootStyle>
     <AdvocacyEvent/>
        

        <Divider
          orientation="vertical"
          sx={{ my: 10, mx: "auto", width: 2, height: 40 }}
        />
      </RootStyle>
    </Page>
  );
}
