import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import DataTable from "react-data-table-component";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
// import { Box } from "@mui/system";

function AdminSchoolDetail() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();
  const [safetyManagement, setAllSafetyManagement] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "FullName",
      selector: (row) => row.name,
    },

    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },

    {
      cell: (row) => (
        <>
          <Button className="image">Image</Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const setAllSchoolSafetyManagement = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolOnboarding/GetSchoolSafetyConferenceDetails/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        // const { data } = response.data;
        setAllSafetyManagement(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    setAllSchoolSafetyManagement();
  }, []);

  if (safetyManagement === null && loading) {
    return <Typography>Loading School Safety Management....</Typography>;
  }

  if (safetyManagement === null) {
    return <Typography>No Record Found....</Typography>;
  }

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> School Management Safety</Typography>
            </Grid>
            <Box>
              <Grid>
                <Grid display={"flex"}>
                  <Grid>
                    <FormHelperText>No of First Aiders:</FormHelperText>
                    <TextField
                      sx={{ marginBlock: 1, width: 250 }}
                      id="NoOfFirstAider"
                      variant="outlined"
                      disabled
                      value={safetyManagement.numberOfFirstAiders}
                    />
                  </Grid>
                  <Grid ml={5}>
                    <FormHelperText>No of Safety Champions:</FormHelperText>
                    <TextField
                      sx={{ marginBlock: 1, width: 250 }}
                      id="NoOfSafetyChampion"
                      variant="outlined"
                      disabled
                      value={safetyManagement.numberOfSafetyChampions}
                    />
                  </Grid>
                </Grid>

                <Grid display={"flex"}>
                  <Grid>
                    <FormHelperText>No of Safety Prefects:</FormHelperText>
                    <TextField
                      sx={{ marginBlock: 1, width: 250 }}
                      id="NoOfSafetyPrefects"
                      variant="outlined"
                      value={safetyManagement.numberOfSafetyPrefects}
                      disabled
                    />
                  </Grid>

                  <Grid ml={5}>
                    <FormHelperText>
                      {" "}
                      Have members of your staff attended any SSLAG CBC (
                      Capacity Building Conferences)
                    </FormHelperText>
                    <TextField
                      sx={{ marginBlock: 1, width: 250 }}
                      id="LsscAttendee"
                      variant="outlined"
                      value={safetyManagement.isStaffWithCbctrainingAvailable}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                <DataTable
                  columns={columns}
                  data={safetyManagement.staffWithCBCertificates}
                  subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                />
              </Box>
              <Grid item sx={{ textAlign: "right", mt: 5 }}>
                <Button
                  type="submit"
                  color="secondary"
                  endIcon={<Send />}
                  variant="contained"
                  onClick={() => openAlertModal()}
                >
                  Intervene
                </Button>
              </Grid>
              ..
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolDetail;
