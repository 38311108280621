import { createContext, useEffect, useReducer } from "react";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "src/utils/axiosCustomAgent";
import { isValidToken } from "src/utils/jwt";
import {
  setSessionCustom,
  setSessionCustomForAdmin,
} from "../utils/jwtCustomAgent";

const initialState = {
  adminRole: "",
  adminRoleId: "",
  email: "",
  id: "",
  isAccountActive: "",
  isAdmin: "",
  isEmailConfirmed: "",
  stakeholderId: 8,
  stakeholderCategory: "",
  isInitialized: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      adminRole,
      adminRoleId,
      email,
      id,
      isAccountActive,
      isAdmin,
      isEmailConfirmed,
      stakeholderCategory,
    } = action.payload;
    return {
      ...state,
      adminRole,
      adminRoleId,
      email,
      id,
      isAccountActive,
      isAdmin,
      isEmailConfirmed,
      stakeholderCategory,
      isInitialized: true,
    };
  },
  LOGIN: (state, action) => {
    const {
      adminRole,
      adminRoleId,
      email,
      id,
      isAccountActive,
      isAdmin,
      isEmailConfirmed,
      stakeholderCategory,
    } = action.payload;
    return {
      ...state,
      adminRole,
      adminRoleId,
      email,
      id,
      isAccountActive,
      isAdmin,
      isEmailConfirmed,
      stakeholderCategory,
      isInitialized: true,
    };
  },
  LOGOUT: (state) => ({
    // ...state,
    ...initialState,
 
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AdminContext = createContext({
  ...initialState,
  method: "jwt",
  loginAdmin: () => Promise.resolve(),
  activateNewAdmin: () => Promise.resolve(),
  logoutAdmin: () => Promise.resolve(),
});

//==================================================================

function AdminProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      const sslagAccessToken = window.localStorage.getItem("SSLAGaccessToken");

      if (sslagAccessToken && isValidToken(sslagAccessToken)) {
        setSessionCustom(sslagAccessToken);

        const response = await axoisCustomAgent.get("/Accounts/GetCurrentUser");

        const { stakeholderCategory } = response.data.data;

        if (stakeholderCategory === "Admins") {
          setSessionCustomForAdmin(sslagAccessToken);

          dispatch({
            type: "INITIALIZE",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: { ...initialState },
          });
        }
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: { ...initialState },
        });
      }
    };
    initialize();
  }, []);

  const loginAdmin = async (data) => {
    const response = await axoisCustomAgent.post("/accounts/login", {
      email: data.email,
      password: data.password,
      stakeholderCategory: state.stakeholderId,
    });

    const { statusCode, message } = response.data;

    if (statusCode !== 200) {
      throw new Error(message);
    }

    const { token } = response.data;
    setSessionCustomForAdmin(token);

    dispatch({
      type: "LOGIN",
      payload: response.data.data,
    });
  };

  const activateNewAdmin = async (data) => {
    const response = await axoisCustomAgentForAdmin.put(
      "/admins/activateAdminAccount",
      {
        password: data.password,
        token: data.token,
      }
    );

    if (response.status === 500) {
      throw new Error("Something went wrong");
    }

    const { statusCode, message } = response.data;

    if (statusCode !== 200) {
      throw new Error(message);
    }
  };

  const logoutAdmin = async () => {
    // setSession(null);
    setSessionCustomForAdmin(null);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AdminContext.Provider
      value={{ ...state, loginAdmin, activateNewAdmin, logoutAdmin }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export { AdminContext, AdminProvider };
