import axios from "axios";

// ----------------------------------------------------------------------

const axoisCustomAgent = axios.create({
  // old one
  baseURL: "https://safeschoolslagos.ng:44308/api",
 
});

export const axoisCustomAgentForAdmin = axios.create({
  baseURL: "https://safeschoolslagos.ng:44006/api",

});

axoisCustomAgent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status, data, config } = error.response;

    if (
      status === 400 &&
      (config.method === "post" || config.method === "put")
    ) {
      const modelErrors = [];
      for (const key in data.errors) {
        if (data.errors[key]) {
          modelErrors.push(data.errors[key]);
        }
      }

      const flattenedErrors = modelErrors.flat();
      throw new Error(flattenedErrors[0]);
    }

    if (status === 401 || status === 403) {
      throw new Error("Unauthoried user");
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

axoisCustomAgentForAdmin.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status, data, config } = error.response;

    if (
      status === 400 &&
      (config.method === "post" || config.method === "put")
    ) {
      const modelErrors = [];
      for (const key in data.errors) {
        if (data.errors[key]) {
          modelErrors.push(data.errors[key]);
        }
      }

      const flattenedErrors = modelErrors.flat();
      throw new Error(flattenedErrors[0]);
    }

    if (status === 401 || status === 403) {
      throw new Error("Unauthoried user");
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axoisCustomAgent;

// const axoisCustomAgent001 = (type) => {
//   if(type === "school") {
//     // axios.create({
//     //   baseURL: "http://38.242.253.175:5058/api",
//     // });
//   }else {
//     // axios.create({
//     //   baseURL: "http://38.242.253.175:5020/api",
//     // });
//   }
// }
