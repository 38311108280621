import React from "react";
import SchoolFaqList from "./SchoolFaqList";
import { Box } from "@mui/material";
// @mui
import { Button, Typography, TextField, Stack } from "@mui/material";
//
import { varFade, MotionInView } from "../../components/animate";




function SchoolFaqhelp() {
  return (
    <Box>
      <SchoolFaqList />

      <Stack spacing={3} mt={5} padding={2} width={500}>
        <MotionInView variants={varFade().inUp}>
          <Typography variant="h4">What can we help you with?</Typography>
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <TextField fullWidth label="Name" />
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <TextField fullWidth label=" School email" />
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <TextField fullWidth label="Subject" />
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <TextField
            fullWidth
            label="Enter your message here."
            multiline
            rows={4}
          />
        </MotionInView>

        <MotionInView variants={varFade().inUp}>
          <Button size="large" variant="contained">
            Submit Now
          </Button>
        </MotionInView>
      </Stack>
    </Box>
  );
}

export default SchoolFaqhelp;
