import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
import { useParams } from "react-router";

function AdminSchoolProvision() {
  const [page, setPage] = React.useState(false);
  const [schoolProvision, setAllSchoolProvision] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  useEffect(() => {
    const getAllSchoolProvision = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolOnboarding/GetSchoolProvisionInfo/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        // const { data } = response.data;
        setAllSchoolProvision(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAllSchoolProvision();
  }, []);
  console.log(schoolProvision);

  if (schoolProvision === null && loading) {
    return <Typography>Loading School Provision....</Typography>;
  }

  if (schoolProvision === null) {
    return <Typography>No Record Found....</Typography>;
  }

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> School Provision</Typography>
            </Grid>
            <Box display={"flex"}>
              <Grid>
                <FormHelperText>
                  {" "}
                  Availability of alternative power?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="AvaGen"
                  variant="outlined"
                  value={schoolProvision.isGeneratingSetAvailable}
                  disabled
                />
                <FormHelperText> Model of generator?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="ModelGen"
                  variant="outlined"
                  disabled
                  value={schoolProvision.modelOfGeneratingSet}
                />
                <FormHelperText> Type of generator engine?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="AvaGen"
                  variant="outlined"
                  disabled
                  value={schoolProvision.generatorType}
                />
                <FormHelperText> Do you have a sick bay?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="Sickbay"
                  variant="outlined"
                  disabled
                  value={schoolProvision.isSickBayAvailable}
                />
                <FormHelperText> Any trained nurse on premises?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" NurseOnSite"
                  variant="outlined"
                  disabled
                  value={schoolProvision.isTrainedNursePresentOnSite}
                />
                <FormHelperText>
                  {" "}
                  How do you handle medical cases?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" emergency"
                  variant="outlined"
                  disabled
                  value={schoolProvision.howDoYouHandleMedicalCases}
                />
                <FormHelperText>Do you have science labs? </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" ScienceLab"
                  variant="outlined"
                  disabled
                  value={schoolProvision.doYouHaveScienceLabs}
                />
                <FormHelperText>
                  {" "}
                  How many science labs are on site?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" ScienceLab"
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfScienceLabs}
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText>List type of science labs? </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" NoOfLab"
                  variant="outlined"
                  multiline={true}
                  minRows={2}
                  disabled
                  value={schoolProvision.scienceLabs}
                />
                <FormHelperText>Please explain why? </FormHelperText>
                <TextField
                  multiline={true}
                  minRows={2}
                  sx={{ marginBlock: 1 }}
                  id=" DesOfLab"
                  variant="outlined"
                  disabled
                  value={schoolProvision.whyDontYouHaveScienceLabs}
                />
                <FormHelperText>
                  Describe school sport facilities?{" "}
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" DesPlayground"
                  variant="outlined"
                  disabled
                  value={schoolProvision.sportFacilityDescription}
                />
                <FormHelperText>Any outdoor play area? </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" DesOfLab"
                  variant="outlined"
                  disabled
                  value={schoolProvision.isPlayGroundAvailable}
                />
                <FormHelperText>
                  Dimension of the playing ground (in meter)
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" Dimension"
                  variant="outlined"
                  disabled
                  value={schoolProvision.playGroundDimensions}
                />
                <FormHelperText>
                  Describe alternative arrangements{" "}
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" DesOfLab"
                  variant="outlined"
                  disabled
                  value={schoolProvision.alternativeOutdoorPlayAreaDescription}
                />
                <FormHelperText>
                  Does the School operate a transit system?{" "}
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" Transit"
                  variant="outlined"
                  disabled
                  value={schoolProvision.isSchoolTransitAvailable}
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText>No of school buses? </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" NoOfBuses"
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfSchoolBuses}
                />
                <FormHelperText>No of drivers </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" NoOfDriver"
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfDrivers}
                />
                <FormHelperText>Total bus escorts </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" NoOfBusesEscorts"
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfSchoolBusEscorts}
                />

                <FormHelperText>
                  Where does the morning assembly hold?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="AssemblyGround "
                  variant="outlined"
                  disabled
                  value={schoolProvision.whereDoesAssemblyHold}
                />
                <FormHelperText>No of staff rooms</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=" "
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfStaffRooms}
                />
                <FormHelperText>
                  {" "}
                  No of toilet facilities for staff
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="ToiletFacstaff "
                  variant="outlined"
                  value={schoolProvision.numberOfStaffToilets}
                  disabled
                />
                <FormHelperText>No of total toilets for pupils</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="PupilsFac"
                  variant="outlined"
                  disabled
                  value={schoolProvision.numberOfStudentToilets}
                />
                <FormHelperText>List special rooms on premises</FormHelperText>
                <TextField
                  multiline={true}
                  minRows={2}
                  sx={{ marginBlock: 1 }}
                  id="RoomOnPremise"
                  variant="outlined"
                  value={schoolProvision.specialRooms}
                  disabled
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText>
                  Where does the morning assembly hold
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="RoomOnPremise"
                  variant="outlined"
                  value={schoolProvision.whereDoesAssemblyHold}
                  disabled
                />
                <FormHelperText>
                  What type of flooring is in the schoolhall or dinning
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="RoomOnPremise"
                  variant="outlined"
                  value={schoolProvision.specialRooms}
                  disabled
                />
                <FormHelperText>
                  Does your school have a school hall?
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  variant="outlined"
                  value={schoolProvision.doesSchoolHaveAHall}
                  disabled
                />
                <FormHelperText>Are school meals served?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="meals"
                  variant="outlined"
                  value={schoolProvision.isSchoolMealServed}
                  disabled
                />
                <FormHelperText>Where do pupils eat?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id=""
                  variant="outlined"
                  value={schoolProvision.diningLocation}
                  disabled
                />
                <FormHelperText>Are packed lunched allowed?</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="PupilsFac"
                  variant="outlined"
                  value={schoolProvision.isPackedLunchAllowed}
                  disabled
                />
                <FormHelperText>
                  Describe safe storage of packed meals
                </FormHelperText>
                <TextField
                  sx={{ marginBlock: 1 }}
                  id="PackedLunchDes"
                  variant="outlined"
                  value={schoolProvision.packedLunchSafetyDescription}
                  disabled
                />
                <Grid item sx={{ textAlign: "right", mt: 5 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => openAlertModal()}
                  >
                    Intervene
                  </Button>
                </Grid>
                ;
              </Grid>
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolProvision;
