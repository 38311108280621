import { useState, useEffect } from "react";
import { sentenceCase } from "change-case";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableContainer,
} from "@mui/material";
// utils
import { fCurrency } from "../../../../utils/formatNumber";
// _mock_
import { _appInvoices } from "../../../../_mock";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { setSessionCustom } from "src/utils/jwtCustomAgent";

// components
import Label from "src/components/Label";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import MenuPopover from "../../../../components/MenuPopover";
import formatNaira from "format-to-naira";
import useAuth from "src/hooks/useAuth";
import useSettings from "src/hooks/useSettings";

// ----------------------------------------------------------------------

export default function Alerts({ alerts, heading }) {
  const theme = useTheme();

  return (
    <Card sx={{ mt: 5, minWidth: 1000 }}>
      <CardHeader title={heading} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.alertDescription}</TableCell>
                  {/* <TableCell>{sentenceCase(row.alertStatus)}</TableCell> */}
                  <TableCell>
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color={
                        (row.alertStatus === "Active" && "error") ||
                        (!row.alertStatus === "" && "success")
                      }
                    >
                      {sentenceCase(row.alertStatus)}
                    </Label>
                  </TableCell>
                  {/* <TableCell align="right">
                    <MoreMenuButton />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
        >
          View All
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

function MoreMenuButton() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <p>working</p>
    // <>
    //   <IconButton size="large" onClick={handleOpen}>
    //     <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
    //   </IconButton>

    //   <MenuPopover
    //     open={Boolean(open)}
    //     anchorEl={open}
    //     onClose={handleClose}
    //     anchorOrigin={{ vertical: "top", horizontal: "left" }}
    //     transformOrigin={{ vertical: "top", horizontal: "right" }}
    //     arrow="right-top"
    //     sx={{
    //       mt: -0.5,
    //       width: 160,
    //       "& .MuiMenuItem-root": {
    //         px: 1,
    //         typography: "body2",
    //         borderRadius: 0.75,
    //       },
    //     }}
    //   >
    //     <MenuItem>
    //       <Iconify icon={"eva:download-fill"} sx={{ ...ICON }} />
    //       Download
    //     </MenuItem>

    //     <MenuItem>
    //       <Iconify icon={"eva:printer-fill"} sx={{ ...ICON }} />
    //       Print
    //     </MenuItem>

    //     <MenuItem>
    //       <Iconify icon={"eva:share-fill"} sx={{ ...ICON }} />
    //       Share
    //     </MenuItem>

    //     <Divider sx={{ borderStyle: "dashed" }} />

    //     {/* <MenuItem sx={{ color: "error.main" }}>
    //       <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
    //       Delete
    //     </MenuItem> */}
    //   </MenuPopover>
    // </>
  );
}
