import React, { Checkbox, FormHelperText } from "@mui/material";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Fragment, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import LoadingButton from "@mui/lab/LoadingButton";

function Attendance() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const { enqueueSnackbar } = useSnackbar();

  const AttedenaceSchema = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.string().required("class Arm is required"),
    TOD: Yup.string().nullable().required("attendance date  is required"),
    attendanceTime: Yup.string().required(
      "morning / afternoon kindly select one "
    ),
  });

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "50vh",
  };

  const defaultValues = {
    classArm: "",
    class: "",
    attendanceTime: "",
    TOD: new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(AttedenaceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const Time = [
    {
      value: "",
      label: "Select Period",
    },
    {
      value: "1",
      label: "Morning",
    },
    {
      value: "2",
      label: "Afternoon",
    },
  ];

  const [studentsInClass, setStudentsInClass] = useState([]);
  const [loadingStudentInClass, setLoadingStudentInClass] = useState(false);
  const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState("");

  const [loading, setLoading] = useState(false);

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "Select Year Group",
    },
  ]);

  const [classArms, setClassArms] = useState([
    {
      id: "",
      className: "Select Class",
    },
  ]);

  const watchClass = methods.watch("class");

  const { formState, setValue } = methods;

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllyeargroupsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        if (status === 500) {
          throw new Error("Something went wrong");
        }

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (schoolId != null) {
      getClasses();
    }

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  useEffect(() => {
    if (formState.dirtyFields.class && watchClass) {
      let classArms = classes.find(
        (item) => `${item.id}` === watchClass
      ).classes;
      setClassArms([
        {
          id: "",
          className: "Select Class",
        },
        ...classArms,
      ]);
    }
  }, [watchClass]);

  const onViewStudents = async (data) => {
    setLoadingStudentInClass(true);
    try {
      const date = new Date(data.TOD).toISOString();

      const response = await axoisCustomAgent.get(
        `/Students/GetAllStudentAndAttendanceDetails?schoolId=${schoolId}&yearGroupId=${data.class}&classId=${data.classArm}&attendanceDate=${date}`
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("successful", {
        variant: "success",
      });

      let customStudentsInClass = [];

      if (response.data.data.length > 0) {
        customStudentsInClass = response.data.data.map((eachData) => {
          const { attendance } = eachData;
          return {
            ...eachData,

            currentAttendance: {
              morningAttendanceStatus:
                attendance === null ||
                attendance.morningAttendanceStatus === null
                  ? ""
                  : attendance.morningAttendanceStatusId,

              afternoonAttendanceStatus:
                attendance === null ||
                attendance.afternoonAttendanceStatus === null
                  ? ""
                  : attendance.afternoonAttendanceStatusId,
            },
          };
        });
      }

      setStudentsInClass([...customStudentsInClass]);

      const period = Time.find((t) => t.value === data.attendanceTime);

      setCurrentSelectedPeriod(period.label);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingStudentInClass(false);
    }
  };

  const cancelAttendance = () => {
    methods.reset();
    setStudentsInClass([]);
  };

  const onRadioChange = (e, id) => {
    const newValue = e.target.value;

    const currentStudentsAttendance = [...studentsInClass];

    let indexOfStudent = currentStudentsAttendance.findIndex(
      (s) => s.studentId === id
    );

    if (currentSelectedPeriod === "Morning") {
      currentStudentsAttendance[
        indexOfStudent
      ].currentAttendance.morningAttendanceStatus = newValue;
    } else {
      currentStudentsAttendance[
        indexOfStudent
      ].currentAttendance.afternoonAttendanceStatus = newValue;
    }

    setStudentsInClass([...currentStudentsAttendance]);
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async () => {
    setLoadingSubmit(true);

    try {
      if (currentSelectedPeriod === "Morning") {
        let dataNotValid = studentsInClass.some(
          (s) => s.currentAttendance.morningAttendanceStatus === ""
        );

        if (dataNotValid) {
          enqueueSnackbar("Select an attendance for all students", {
            variant: "error",
          });
          return;
        }
      }

      if (currentSelectedPeriod === "Afternoon") {
        let dataNotValid = studentsInClass.some(
          (s) => s.currentAttendance.afternoonAttendanceStatus === ""
        );
        if (dataNotValid) {
          enqueueSnackbar("Select an attendance for all students", {
            variant: "error",
          });
          return;
        }
      }

      let studentsData = studentsInClass.map((eachStudentData) => {
        const {
          studentId,
          currentAttendance: {
            morningAttendanceStatus,
            afternoonAttendanceStatus,
          },
        } = eachStudentData;
        return {
          studentId,
          attendanceStatus:
            currentSelectedPeriod === "Morning"
              ? morningAttendanceStatus
              : afternoonAttendanceStatus,
        };
      });

      const watchFields = methods.watch([
        "class",
        "classArm",
        "TOD",
        "attendanceTime",
      ]);

      const date = new Date(watchFields[2]).toISOString();

      const response = await axoisCustomAgent.post(
        `/Students/takeStudentAttendance`,
        {
          schoolId,
          yearGroupId: watchFields[0],
          classId: watchFields[1],
          attendancePeriod: watchFields[3],
          attendanceDate: date,
          studentsData,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("students attendance submitted successfully", {
        variant: "success",
      });

      methods.reset();
      setStudentsInClass([]);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingSubmit(false);
    }
  };

  //==================
  //==================

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Attendance</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/attendance"
            className="header active "
            sx={{ color: "#6495ed" }}
          >
            Mark Attendance
          </Button>

          <Button
            href="/dashboard/user/allAttendance"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            View Attendance
          </Button>
          <Button
            href="/dashboard/user/Behaviour"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Student Behaviour
          </Button>
        </Box>
      </Box>

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={6}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onViewStudents)}
              >
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Mark Attendance
                    </Typography>
                  </Box>
                </Grid>

                <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                  <Grid width={220}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      select
                      name="class"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                      disabled={studentsInClass.length > 0}
                    >
                      {classes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.yearGroupName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                  <Grid width={220}>
                    <RHFTextField
                      select
                      name="classArm"
                      variant="filled"
                      required
                      SelectProps={{ native: true }}
                      disabled={studentsInClass.length > 0}
                    >
                      {classArms.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.className}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>
                  <Grid width={220}>
                    <RHFTextField
                      select
                      name="attendanceTime"
                      required
                      variant="filled"
                      SelectProps={{ native: true }}
                      disabled={studentsInClass.length > 0}
                    >
                      {Time.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid width={220}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      
                      <DatePicker
                        // label="Attendance Date "
                        value={methods.watch().TOD}
                        disabled
                        onChange={(value) => {
                          methods.setValue("TOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="TOD"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Box>

            
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={studentsInClass.length > 0}
                  >
                    View Students
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* =====Table========================== */}

      <Fragment>
        {loadingStudentInClass ? (
          <Typography variant="h3">Loading Students....</Typography>
        ) : (
          <Grid style={Gridimage}>
            {studentsInClass.length === 0 ? (
              <Typography variant="h5" padding={2} textAlign={"center"}>
                No Students Record
              </Typography>
            ) : (
              <Card sx={{ mt: 5, minWidth: 1000 }}>
                <CardHeader title="Attendance" sx={{ mb: 3 }} />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell width={600}>
                            {`${currentSelectedPeriod}`} Attendance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentsInClass.map((eachStudent, index) => (
                          <TableRow key={index}>
                            {/* <TableCell>{index + 1}</TableCell> */}
                            <TableCell>{eachStudent.firstName}</TableCell>
                            <TableCell>{eachStudent.lastName}</TableCell>

                            {currentSelectedPeriod === "Morning" ? (
                              <TableCell>
                                <FormControl>
                                  <FormLabel id="attendance-controlled-radio-buttons-group"></FormLabel>
                                  <RadioGroup
                                    aria-labelledby="attendance-controlled-radio-buttons-group"
                                    name="attendance-radio-buttons-group"
                                    row
                                    value={
                                      eachStudent.currentAttendance
                                        .morningAttendanceStatus
                                    }
                                    onChange={(e) =>
                                      onRadioChange(e, eachStudent.studentId)
                                    }
                                  >
                                    <FormControlLabel
                                      value={1}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Present"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Sick"
                                    />
                                    <FormControlLabel
                                      value={3}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Late"
                                    />
                                    <FormControlLabel
                                      value={4}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .morningAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Absent"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <FormControl>
                                  <FormLabel id="attendance-controlled-radio-buttons-group"></FormLabel>
                                  <RadioGroup
                                    aria-labelledby="attendance-controlled-radio-buttons-group"
                                    name="attendance-radio-buttons-group"
                                    row
                                    value={
                                      eachStudent.currentAttendance
                                        .afternoonAttendanceStatus
                                    }
                                    disabled={
                                      eachStudent.attendance !== null &&
                                      eachStudent.attendance
                                        .afternoonAttendanceStatus !== null
                                    }
                                    onChange={(e) =>
                                      onRadioChange(e, eachStudent.studentId)
                                    }
                                  >
                                    <FormControlLabel
                                      value={1}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Present"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Sick"
                                    />
                                    <FormControlLabel
                                      value={3}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Late"
                                    />
                                    <FormControlLabel
                                      value={4}
                                      disabled={
                                        eachStudent.attendance !== null &&
                                        eachStudent.attendance
                                          .afternoonAttendanceStatus !== null
                                      }
                                      control={<Radio />}
                                      label="Absent"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <Divider />
                <Box sx={{ p: 2, textAlign: "right" }}>
                  <Button
                    size="small"
                    color="inherit"
                    // endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                  >
                    .
                  </Button>
                </Box>

                <Grid
                  container
                  spacing={5}
                  sx={{ justifyContent: "center", mb: 2 }}
                >
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      color="error"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={cancelAttendance}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      type="submit"
                      color="secondary"
                      variant="contained"
                      loading={loadingSubmit}
                      onClick={async () => await onSubmit()}
                      // sx={{  ml: }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        )}
      </Fragment>

      {/* ============================== */}
    </Box>
  );
}

export default Attendance;


