import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
 
  Button,
  Container,
  Typography,
  Paper,
  Grid,
} from "@mui/material";

// _mock_
import { _homePlans } from "../../../_mock";
import Page from "../../../components/Page";
import { Divider } from "@mui/material";
// components
import Image from "../../../components/Image";
import Iconify from "../../../components/Iconify";

import { MotionInView, varFade } from "../../../components/animate";


import SongOne from "../../../images/sslag gallery.mp4";
import SongTwo from "../../../images/Lagoon School Compliance Journey.mp4";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(11, 0),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function Gallery() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";

  return (
    <Page title="Gallery">
      <RootStyle>
        <Container>
          <Box sx={{ mb: 10, textAlign: "center" }}>
            <MotionInView variants={varFade().inDown}></MotionInView>

            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Box sx={{ minWidth: 300, mt: 3 }}>
                  <video
                    // autoPlay
                    controls
                    loop
                    style={{
                      borderRadius: 3,
                      width: 500,
                    }}

                    // poster="https://assets.codepen.io/6093409/river.jpg"
                  >
                    <source src={SongOne} type="video/mp4" />
                  </video>
                  <Typography variant="h6">SSLAG Journey</Typography>
                </Box>
              </Box>
              <Box m={3}>
                <Box sx={{ minWidth: 300 }}>
                  <video
                    // autoPlay
                    controls
                    loop
                    style={{
                      // height: 500,
                      width: 450,
                      borderRadius: 3,
                    }}

                    // poster="https://assets.codepen.io/6093409/river.jpg"
                  >
                    <source src={SongTwo} type="video/mp4" />
                  </video>
                  <Typography variant="h6">
                    Lagoon School Compliance Journey
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        <Divider
          orientation="vertical"
          sx={{ my: 10, mx: "auto", width: 2, height: 40 }}
        />
      </RootStyle>
    </Page>
  );
}
