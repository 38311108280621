import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useState } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../utils/formatTime";
// _mock_
import { _notifications } from "../../_mock";
// components
import Iconify from "../../components/Iconify";
// import Box from "../../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { IconButtonAnimate } from "../../components/animate";
import * as signalR from "@microsoft/signalr";
import { useEffect } from "react";
import useAuth from "src/hooks/useAuthAdmin";
import { Link } from "react-router-dom";
// ----------------------------------------------------------------------

export default function AdminNotification() {
  const [pastNotifications, setPastNotifications] = useState(_notifications);
  const [notifications, setNotifications] = useState([]);
  const [connection, setConnection] = useState(null);

  const { id } = useAuth();
  // const { schoolId, userId } = userData;

  const totalUnRead = notifications.filter(
    (item) => item.isRead === false
  ).length;

  useEffect(() => {
    const connectToHub = async () => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`http://38.242.253.175:6033/notifications?userId=${id}`)
        .build();

      try {
        connection.on("LoadNotifications", (notifications) => {
          setNotifications((previousNotifications) => notifications);
        });

        await connection.start();
        console.log("SignalR Connected!");

        setConnection(connection);
      } catch (err) {
        console.log("SignalR Connection Error: ", err);
      }
    };
    if (id !== null && id !== undefined && id !== "") {
      connectToHub();
    }
    // connectToHub();

    return () => {
      // console.log("xxxxxx");
      // console.log(connection);
      if (connection) {
        // console.log("yyyyyyyy");
        connection.stop();
        console.log("SignalR Disconnected!");
      }
    };
  }, [id]);

  // console.log(connection);
  useEffect(() => {
    if (connection !== null) {
      connection.on("ReceiveNotification", (notification) => {
        setNotifications((previousNotifications) => [
          notification,
          ...previousNotifications,
        ]);
      });
    }
  }, [connection]);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setPastNotifications(
      pastNotifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <IconButtonAnimate
        // disableEffect
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ overflowY: "scroll", height: "70vh" }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <Link to={notification.path} key={notification.id}>
                <NotificationItem notification={notification} />
              </Link>
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Previous message
              </ListSubheader>
            }
          >
            {notifications
              .slice(2, notifications.length - 1)
              .map((notification) => (
                <Link to={notification.path} key={notification.id}>
                  <NotificationItem notification={notification} />
                </Link>
              ))}
          </List>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    // isUnRead: PropTypes.bool,
    isRead: PropTypes.bool,
    // title: PropTypes.string,
    heading: PropTypes.string,
    // description: PropTypes.string,
    body: PropTypes.string,
    path: PropTypes.string,
    type: PropTypes.string,
    // avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { heading } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.isRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        {/* <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar> */}
      </ListItemAvatar>
      <ListItemText
        primary={heading}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const heading = (
    <Typography variant="subtitle2">
      {notification.heading}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification.body)}
      </Typography>
    </Typography>
  );

  
  return {
    avatar: notification?.avatar ? (
      <img alt={notification.heading} src={notification.avatar} />
    ) : null,
    heading,
  };
}
