import React, { Fragment } from "react";
import {
  TextAnimate,
  MotionContainer,
  varFade,
} from "../../components/animate";
import AdminBG from "../../images/background.png";
import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Grid } from "@mui/material";
import { Button, TextField, Stack, Card } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logoBg from "../../images/loogoo.png";
// components
import { MotionInView } from "../../components/animate";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../components/hook-form";
import { LoadingButton } from "@mui/lab";

function SupportForm() {
  const RootStyle = styled("div")(({ theme }) => ({
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${AdminBG})`,
    padding: theme.spacing(0, 0),
    [theme.breakpoints.up("md")]: {
      height: 690,
      padding: 0,
    },
  }));

  const ContentStyle = styled("div")(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      position: "absolute",
      bottom: theme.spacing(10),
    },
  }));

  const defaultValues = {};
  const SupportSchema = Yup.object().shape({
    schoolName: Yup.string().required("field  is required"),
    email: Yup.string().required("field  is required"),
    subject: Yup.string().required("field  is required"),
  });
  const methods = useForm({
    resolver: yupResolver(SupportSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;
  const onSubmit = () => {};
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            // mt: 14,
            minHeight: 200,
            width: "46%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box m={2}>
              <Typography variant="h5">Contact Us</Typography>
            </Box>
            <Stack spacing={3} p={2}>
              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField fullWidth label="School Name" name="schoolName" />
              </Box>

              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField name="email" fullWidth label="Email" />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField fullWidth label="Subject" name="subject" />
              </Box>
              <Box sx={{ marginBlock: 2 }}>
                <RHFTextField
                  fullWidth
                  label="Enter your message here."
                  name="message"
                  multiline
                  rows={4}
                />
              </Box>
            </Stack>

            <Grid textAlign={"right"} p={2}>
              <LoadingButton
                size="large"
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Submit Now
              </LoadingButton>
            </Grid>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SupportForm;
