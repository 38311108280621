import React, { Fragment } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  MenuItem,
  Button,
  Divider,
  IconButton,
  FormHelperText,
  Modal,
} from "@mui/material";
import DataTable from "react-data-table-component";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";

import { useEffect, useState } from "react";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import useAuth from "src/hooks/useAuth";
import styled from "styled-components";
import EditDuties from "./EditDuties";
import { id } from "date-fns/locale";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function StaffDuties() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "50vh",
  };

  const PaperStyle = {
    padding: 40,
    width: 1200,
  };

  const { enqueueSnackbar } = useSnackbar();
  const [staffAndTheirDuties, setStaffAndTheirDuties] = useState([]);
  const [loadingStaffAndTheirDuties, setLoadingStaffAndTheirDuties] = useState(false);

  const { userData } = useAuth();
  const { schoolId } = userData;

  const StaffDuties = Yup.object().shape({
    //firstName: Yup.string().required("First name is required"),
    staffDuties: Yup.string().required("duty is required"),
    location: Yup.string().required("location is required"),
    SOD: Yup.string().nullable().required("start date is required"),
    EOD: Yup.string().nullable().required("end date is required"),
  });

  const [staff, setStaff] = useState([
    {
      id: "",
      firstName: "",
      lastName: "",
      middleName: "",
      fullName: "select staff",
      staffRole: "",
      SOD: "",
      EOD: "",
    },
  ]);

  const [duties, setDuties] = useState([
    {
      id: "",
      name: "select duty",
      // createdAt: "",
    },
  ]);

  const [location, setLocation] = useState([
    {
      id: "",
      location: "select location",
    },
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    const getDuties = async () => {
      try {
        const response = await axoisCustomAgent.get(`/Duties/GetDuties`);

        //const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setDuties([...duties, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    const getAllLocation = async () => {
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolLocation/GetAllLocations?schoolId=${schoolId}`
        );

        //const { status } = response.data;
        const { statusCode, message } = response.data;
        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        if (!unmounted) setLocation([...location, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    const getStaffAndTheirDuties = async () => {
      setLoadingStaffAndTheirDuties(true);
      try {
        const response = await axoisCustomAgent.get(
          `/Duties/GetAllStaffOnDuty?schoolId=${schoolId}`
        );
        const { statusCode, message } = response.data;
        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        if (!unmounted) setStaffAndTheirDuties([...staffAndTheirDuties, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingStaffAndTheirDuties(false);
      }
    };

    if (schoolId != null) {
      getDuties();
      getAllLocation();
      getStaffAndTheirDuties();
    }

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  useEffect(() => {
    let unmounted = false;
    const getAllStaff = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolStaff/GetAllAcademicStaff/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        let newStaff = [];

        if (data.length > 0) {
          newStaff = data.map((eachData) => {
            const {
              firstName,
              lastName,
              middleName,
              appUserId,
              locations,
              startDate,
              endDate,
            } = eachData;

            return {
              id: appUserId,
              fullName: `${firstName} ${lastName}`,
              firstName,
              lastName,
              middleName,
              locations,
              startDate,
              endDate,
            };
          });
        }

        setStaff([...staff, ...newStaff]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getAllStaff();
    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const filteredItems = staffAndTheirDuties.filter(
    (item) =>
      item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
    },

    {
      name: "Duty",
      selector: (row) => row.duty,
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate.split("T")[0],
    },
    {
      name: "End Date",
      selector: (row) => row.endDate.split("T")[0],
    },

    {
      cell: (row) => <MoreMenuButton schoolId={schoolId} dutyId={row.dutyId} />,
    },
  ];

  const defaultValues = {
    staffName: "",
    duty: "",
    staff: "",
    SOD: null,
    EOD: null,
  };

  const methods = useForm({
    resolver: yupResolver(StaffDuties),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(`/Duties/AssignDutyToSchoolStaff`, {
        schoolId,
        dutyId: data.staffDuties,
        locations: [data.location],
        startDate: new Date(data.SOD).toISOString(),
        endDate: new Date(data.EOD).toISOString(),
        staffId: `${data.staffName}`,
      });

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("Staff Duties Assign Successfully", {
        variant: "success",
      });

      const staffAssigned = staff.find((s) => s.id === data.staffName);
      const dutyAssigned = duties.find((d) => `${d.id}` === data.duty);

      setStaffAndTheirDuties([
        ...staffAndTheirDuties,
        {
          userId: staffAssigned.id,
          firstName: staffAssigned.firstName,
          lastName: staffAssigned.lastName,
          dutyId: dutyAssigned.id,
          duty: dutyAssigned.name,
          staffRole: staffAssigned.staffRole,
          startDate: new Date(data.SOD).toISOString(),
          endDate: new Date(data.EOD).toISOString(),
        },
      ]);

      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Staff...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Staff</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/staff"
            className="header "
            sx={{ color: "#6495ed" }}
          >
            Add Staff
          </Button>

          <Button
            href="/dashboard/user/allstaff"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            All Staff
          </Button>

          <Button
            href="/dashboard/user/addDuties"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Duty Location
          </Button>
          <Button
            href="/dashboard/user/staffDuties"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Staff Duty
          </Button>
        </Box>
      </Box>
      <hr />

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={7}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>

                <Grid container spacing={5} mt={2}>
                  <Grid item width={260}>
                    <FormHelperText> Staff Name :</FormHelperText>
                    <RHFTextField
                      select
                      name="staffName"
                      //required
                      SelectProps={{ native: true }}
                    >
                      {staff.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.fullName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={230}>
                    <FormHelperText>Staff Duties</FormHelperText>
                    <RHFTextField
                      select
                      name="staffDuties"
                      required
                      SelectProps={{ native: true }}
                    >
                      {duties.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={230}>
                    <FormHelperText>Select Location:</FormHelperText>
                    <RHFTextField
                      select
                      name="location"
                      multiple
                      //required
                      SelectProps={{ native: true }}
                    >
                      {location.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.location}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={200}>
                    <FormHelperText>Start of duty : </FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // inputFormat="dd/mm/yyyy"
                        value={methods.watch().SOD}
                        disablePast
                        onChange={(value) => {
                          methods.setValue("SOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => <RHFTextField {...params} name="SOD" />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item width={200}>
                    <FormHelperText> End of duty:</FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // inputFormat="DD/MM/YYYY"
                        // inputFormat="dd/mm/yyyy"
                        value={methods.watch().EOD}
                        disablePast
                        onChange={(value) => {
                          methods.setValue("EOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => <RHFTextField {...params} name="EOD" />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Assign Duty
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Fragment>
        {loadingStaffAndTheirDuties ? (
          <Typography variant="h3">Loading Staff Duties....</Typography>
        ) : (
          <Grid>
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive

              // keyField={staff.map((item) => item.appUserId)}
            />
          </Grid>
        )}
      </Fragment>
    </Box>
  );
}

export default StaffDuties;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MoreMenuButton({ isActive, schoolId, dutyId }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setOpen(false);

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handlePageOpen} sx={{ color: "succes" }}>
          <Iconify icon={"eva:edit-2-outline"} sx={{ ...ICON }} />
          Edit
        </MenuItem>

        <Modal
          open={page}
          onClose={handlePageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box id="modal-modal-title" variant="h6" component="h2" color={"#6495ed"}>
              Update Staff Duties
            </Box>

            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <EditDuties schoolId={schoolId} dutyId={dutyId} />
            </Grid>
          </Box>
        </Modal>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
