import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  List,
  Stack,
  Alert,
  Avatar,
  Button,
  Box,
  ListItem,
} from "@mui/material";
import AdminBG from "../../../images/background.png";
import Add from "@material-ui/icons/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import LessonObservation from "../../../sections/Admin/IdentificationModal";
import { useSnackbar } from "notistack";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import FolderIcon from "@material-ui/icons/Folder";
import { useNavigate, useParams } from "react-router";
import AdminInvoice from "../../../sections/Admin/AdminInvoice";
import * as Yup from "yup";
import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";

import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import Pending from "src/pages/dashboard/Pending";

function GradingForm({ nextStep, schoolId, auditId, dateOfAudit, schoolURN }) {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [gradingSheet, setGradingSheet] = useState([]);
  const [school, setSchool] = useState({});
  // const [gradingSheetUrn, setGradingSheetUrn] = useState({});
  let { taskId, roleId, clerkId } = useParams();
  const navigate = useNavigate();

  const defaultValues = {
    // checkbox: false,
  };
  const methods = useForm({
    // resolver: yupResolver(AcceptSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const isChecked = watch("checkbox");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "86%",

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 2,
    p: 4,
  };

  const [item, setItem] = useState([schoolId, auditId]);
  localStorage.setItem("items", JSON.stringify(item));

  useEffect(() => {
    let unmounted = false;
    const getAllGradingForm = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetAuditGradingForms?clerkAccessId=${roleId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setGradingSheet((prev) => data);
        setSchool((prev) => data.school);

        //
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllGradingForm();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleNavigation = (path) => {
    let to = `${path}/${taskId}/${clerkId}/${roleId}`;

    navigate(to);
  };
  // const onSubmit = () => {};

  const today = new Date();
  const date = today.toLocaleDateString();
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "120vh",
          backgroundCover: "contain",
        }}
      >
        <Card
          sx={{
            minWidth: "60%",
            mt: 4,
          }}
        >
          <Box m={3}>
            <Typography variant="h5"> Completed Audit Grading Sheet</Typography>
          </Box>

          {/* <Box p={2} display={"flex"}>
            <Typography>School Urn:</Typography>
            <Typography ml={2} color={"secondary"}>
              {school?.urn}
            </Typography>
          </Box>

          <Box p={2} display={"flex"}>
            <Typography>Audit Clerk:</Typography>
            <Typography ml={2} color={"secondary"}>
              {school?.access}
            </Typography>
          </Box> */}

          {/* <Box mt={2}>
            <Typography fontSize={"16px"}>{school.address}</Typography>
          </Box> */}

          <List sx={{ p: 1 }}>
            {/* <Typography variant="h6">Completed Form </Typography> */}
            {gradingSheet.map((eachItem, index) => (
              <ListItem key={index}>
                <Button
                  onClick={() => handleNavigation(eachItem.path)}
                  //   disabled={eachItem.isCompleted}
                  style={{
                    display: "block",
                  }}
                >
                  {eachItem.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
    </Fragment>
  );
}

export default GradingForm;
