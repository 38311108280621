import React from "react";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  FormHelperText,
  Button,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

function Student() {
  const { userData } = useAuth();
  const { schoolId } = userData;

  const CreateStudentSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    middleName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("last name is required"),

    stateOfOrigin: Yup.string().required("state of origin is  required"),
    yearOfAdmission: Yup.string().required("year of admissoin is  required"),
    DOB: Yup.string().nullable().required("date of birth  is  required"),
    studentRegNo: Yup.string().nullable().required("student id  is  required"),

    lga: Yup.string().required("local government  is  required"),
    gender: Yup.string().oneOf(["1", "2"], "Select a gender type "),
    class: Yup.string().required("Select year group"),
    classArm: Yup.string().required("Select class"),

    parentfirstname: Yup.string().required("parent first name is required"),
    parentlastname: Yup.string().required("parent last name is required"),
    parentgender: Yup.string().required("parent gender is required"),

    parentstateoforigin: Yup.string().required(
      "parent state of origin is required"
    ),
    parentlga: Yup.string().required(
      "parent local government origin is required"
    ),

    email: Yup.string()
      .email("Enter a valid Email Address")
      .required("Email is required"),
  });
  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Hover = {
    marginRight: "20px",
    color: "black",
    // fontFamily: "Times New Roman",
    fontSize: "15px",
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };
  const defaultValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    yearOfAdmission: "",
    stateOfOrigin: "",
    DOB: null,
    lga: "",
    gender: "",
    hospitalName: "",
    studentRegNo: "",
    medicalType: "",
    specifymedical: "",
    medicalCond: "",
    class: "",
    classArm: "",

    homeAddress: "",
    hospitalNumber: "",
    hospitalAddress: "",
    alleg: "",
    allergyType: "",
    specificAllergy: "",
    bloodGroup: "",
    parentfirstname: "",
    parentlastname: "",
    parentgender: "",

    parentstateoforigin: "",
    parentlga: "",
    email: "",
    parentNo: "",
    home: "",
    guardians: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(CreateStudentSchema),
    defaultValues,
  });

  const { formState, setValue, resetField } = methods;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const GENDER = [
    {
      value: "0",
      label: "Select Gender",
    },
    {
      value: "1",
      label: "Male",
    },
    {
      value: "2",
      label: "Female",
    },
  ];

  const MEDIC = [
    {
      value: "",
      label: "Any medical condition",
    },
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  const MEDIC_TYPE = [
    {
      value: "",
      label: " ",
    },
    {
      value: "Cancer",
      label: "Cancer",
    },
    {
      value: "Diabetes",
      label: "Diabetes",
    },
    {
      value: "Heart disease",
      label: "Heart disease",
    },
    {
      value: "Conjunctivitis",
      label: "Conjunctivitis",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];

  const ALLEGS = [
    {
      value: "",
      label: "",
    },
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ];

  const TYPE_ALLEGS = [
    {
      value: "",
      label: "  ",
    },
    {
      value: "Asthma",
      label: "Asthma",
    },

    {
      value: "Food allergy",
      label: "Food allergy",
    },
    {
      value: "Anaphylaxis",
      label: "Anaphylaxis",
    },

    {
      value: "Others",
      label: "Others",
    },
  ];

  const RELATIONS = [
    {
      value: "",
      label: "Choose relation type",
    },
    {
      value: 1,
      label: "Father",
    },
    {
      value: 2,
      label: "Mother",
    },
    {
      value: 3,
      label: "Guardian",
    },
  ];

  const BLOOD_GROUP = [
    {
      value: "0",
      label: "Choose blood group",
    },
    {
      value: "1",
      label: "A+",
    },
    {
      value: "2",
      label: "A-",
    },
    {
      value: "3",
      label: "B+",
    },
    {
      value: "4",
      label: "B-",
    },
    {
      value: "5",
      label: "AB+",
    },
    {
      value: "6",
      label: "AB-",
    },
    {
      value: "7",
      label: "O+",
    },
    {
      value: "8",
      label: "O-",
    },
  ];

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "",
    },
  ]);

  const [classArms, setClassArms] = useState([
    {
      id: "",
      className: "",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const watchAlleg = watch("alleg");
  const watchAllergyType = watch("allergyType");

  const watchCondition = watch("medicalCond");
  const watchConditionType = watch("medicalType");

  useEffect(() => {
    if (formState.touchedFields.alleg && watchAlleg !== "1") {
      setValue("allergyType", "", { shouldDirty: true });
      setValue("specificAllergy", "", { shouldDirty: true });
    }
  }, [watchAlleg]);

  useEffect(() => {
    if (formState.touchedFields.medicalCond && watchCondition !== "1") {
      setValue("medicalType", "", { shouldDirty: true });
      setValue("specifymedical", "", { shouldDirty: true });
    }
  }, [watchCondition]);

  const watchClass = methods.watch("class");

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllyeargroupsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) getClasses();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  useEffect(() => {
    if (formState.dirtyFields.class && watchClass) {
      let classArms = classes.find(
        (item) => `${item.id}` === watchClass
      ).classes;

      setClassArms([
        {
          id: "",
          className: "Select Class",
        },
        ...classArms,
      ]);
    }
  }, [watchClass]);

  //=============
  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(`/students/CreateStudent`, {
        schoolId,
        firstName: data.firstName,
        lastName: data.lastName,
        otherName: data.middleName,
        genderId: data.gender,
        homeAddress: data.homeAddress,
        dateOfBirth: new Date(data.DOB).toISOString,
        yearOfAdmission: data.yearOfAdmission,
        stateOfOrigin: data.stateOfOrigin,
        lgaOfOrigin: data.lga,

        doesStudentHaveAnyAllergy: data.alleg === "1" ? true : false,
        specificAllergy: data.specificAllergy,

        allergyId:
          data.alleg === "1"
            ? TYPE_ALLEGS.findIndex((x) => x.value === data.allergyType)
            : null,

        hospitalNameForEmergencies: data.hospitalName,
        hospitalNumberForEmergencies: data.hospitalNumber,
        hospitalAddressForEmergencies: data.hospitalAddress,

        doesStudentHaveAnyMedicalCondition:
          data.medicalCond === "1" ? true : false,

        specificMedicalCondition: data.specifymedical,

        medicalConditionId:
          data.medicalCond === "1"
            ? MEDIC_TYPE.findIndex((x) => x.value === data.medicalType)
            : null,

        bloodGroupId: data.bloodGroup,
        registrationNumber: data.studentRegNo,

        yearGroupId: data.class,
        classId: data.classArm,

        parentFirstName: data.parentfirstname,
        parentLastName: data.parentlastname,
        phoneNumber: data.parentNo,
        parentEmail: data.email,
        parentGenderId: data.parentgender,
        parentHomeAddress: data.home,
        parentStateOfOrigin: data.parentstateoforigin,
        parentLGAOfOrigin: data.parentlga,
        relationshipType: data.guardians,
      });

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });

      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  //===========

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">School Management</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/student"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            Create Student |
          </Button>

          <Button
            href="/dashboard/user/allStudent"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            All Student |
          </Button>

          <Button
            href="/dashboard/user/allParent"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            All Parent |
          </Button>
        </Box>
      </Box>
      <hr />
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item mt={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Complete Student Data
                    </Typography>
                  </Box>
                </Grid>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5">Student Record</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid width={250}>
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="firstName"
                          label="First Name"
                          variant="filled"
                        />
                      </Grid>

                      <Grid width={250}>
                        <RHFTextField
                          name="middleName"
                          label="Middle Name"
                          variant="filled"
                        />
                      </Grid>
                      <Grid width={250}>
                        <RHFTextField
                          name="lastName"
                          label="Last Name"
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="yearOfAdmission"
                          label="Year Of Admission"
                          variant="filled"
                        />
                      </Grid>

                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          name="stateOfOrigin"
                          label="State Of Origin"
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            label="Date of Birth "
                            value={methods.watch().DOB}
                            onChange={(value) => {
                              methods.setValue("DOB", value, {
                                shouldValidate: true,
                              });
                            }}
                            renderInput={(params) => (
                              <RHFTextField
                                variant="filled"
                                {...params}
                                name="DOB"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="lga"
                          label="L G A"
                          variant="filled"
                        />
                      </Grid>

                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          name="hospitalName"
                          label="Hospital Name"
                          variant="filled"
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          select
                          name="gender"
                          label="Gender"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {GENDER.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid width={250}>
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="hospitalNumber"
                          label="Hospital mobile number"
                          variant="filled"
                        />
                      </Grid>

                      <Grid width={250}>
                        <RHFTextField
                          select
                          name="class"
                          label="Select Year Group"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {classes.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.yearGroupName}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                      <Grid width={250}>
                        <RHFTextField
                          select
                          name="classArm"
                          label="Select class"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {classArms.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.className}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="studentRegNo"
                          label="Student ID"
                          variant="filled"
                        />
                      </Grid>

                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          select
                          name="bloodGroup"
                          label="Select Blood Group "
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {BLOOD_GROUP.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          select
                          name="alleg"
                          label="Do You Have Any Allegeries"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {ALLEGS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-around"}>
                      {watchAlleg === "1" ? (
                        <Grid
                          sx={{
                            marginBlock: 2,
                            width: 400,
                          }}
                        >
                          {/* <FormHelperText>Name</FormHelperText> */}
                          <RHFTextField
                            select
                            name="allergyType"
                            label="Select Allegeries"
                            required
                            variant="filled"
                            SelectProps={{ native: true }}
                          >
                            {TYPE_ALLEGS.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </RHFTextField>
                        </Grid>
                      ) : null}

                      {watchAllergyType === "Others" ? (
                        <Grid
                          sx={{
                            marginBlock: 2,
                            width: 400,
                          }}
                        >
                          {/* <FormHelperText>Name</FormHelperText> */}
                          <RHFTextField
                            name="specificAllergy"
                            label="State Allegeries"
                            variant="filled"
                          />
                        </Grid>
                      ) : null}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 600,
                        }}
                      >
                        <RHFTextField
                          select
                          name="medicalCond"
                          label="Do You Have Any Medical Condition"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {ALLEGS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>

                    <Box display={"flex"} justifyContent={"space-around"}>
                      {watchCondition === "1" ? (
                        <Grid
                          sx={{
                            marginBlock: 2,
                            width: 400,
                          }}
                        >
                          {/* <FormHelperText>Name</FormHelperText> */}
                          <RHFTextField
                            select
                            name="medicalType"
                            label="Select medical condition"
                            required
                            variant="filled"
                            SelectProps={{ native: true }}
                          >
                            {MEDIC_TYPE.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </RHFTextField>
                        </Grid>
                      ) : null}

                      {watchConditionType === "Others" ? (
                        <Grid
                          sx={{
                            marginBlock: 2,
                            width: 400,
                          }}
                        >
                          {/* <FormHelperText>Name</FormHelperText> */}
                          <RHFTextField
                            name="specifymedical"
                            label="Kindly specify the medical condition*"
                            variant="filled"
                          />
                        </Grid>
                      ) : null}
                    </Box>

                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 400,
                        }}
                      >
                        <RHFTextField
                          multiline={true}
                          minRows={3}
                          name="homeAddress"
                          label="Home Address"
                          placeholder="Home Address ....."
                          variant="filled"
                        />
                      </Grid>

                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 400,
                        }}
                      >
                        <RHFTextField
                          multiline={true}
                          minRows={3}
                          name="hospitalAddress"
                          label="Hospital Address"
                          placeholder="Hospital Address ....."
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      Complete Parent Data
                    </Typography>
                  </Box>
                </Grid>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography variant="h5">Parent/Guardian Record</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid width={250}>
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="parentfirstname"
                          label="First Name"
                          variant="filled"
                        />
                      </Grid>

                      <Grid width={250}>
                        <RHFTextField
                          name="parentlastname"
                          label="Surname"
                          variant="filled"
                        />
                      </Grid>
                      <Grid width={250}>
                        <RHFTextField
                          select
                          name="parentgender"
                          label="Gender"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {GENDER.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="parentstateoforigin"
                          label="State Of Origin"
                          variant="filled"
                        />
                      </Grid>

                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          name="parentlga"
                          label="Local Government"
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 250,
                        }}
                      >
                        <RHFTextField
                          name="email"
                          type="email"
                          label="Email"
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-around"}>
                      <Grid width={400}>
                        {/* <FormHelperText>Name</FormHelperText> */}
                        <RHFTextField
                          name="parentNo"
                          label="Phone Number"
                          type="number"
                          variant="filled"
                        />
                      </Grid>
                      {/* variant="filled" */}
                      <Grid width={400}>
                        <RHFTextField
                          select
                          name="guardians"
                          label="Relationship Type"
                          required
                          variant="filled"
                          SelectProps={{ native: true }}
                        >
                          {RELATIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </RHFTextField>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid
                        sx={{
                          marginBlock: 2,
                          width: 500,
                        }}
                      >
                        <RHFTextField
                          multiline={true}
                          minRows={4}
                          name="home"
                          label="Residential Address"
                          placeholder="Residential  Address ....."
                          variant="filled"
                        />
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Grid item sx={{ textAlign: "right" }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      width: 160,
                    }}
                  >
                    Create
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Student;







