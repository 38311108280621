import { Grid, Stack, Alert, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";

function EditDuties({ schoolId, dutyId }) {
  const StaffDuties = Yup.object().shape({
    SOD: Yup.string().nullable().required("start date is required"),
    EOD: Yup.string().nullable().required("end date is required"),
  });
  const defaultValues = {
    SOD: null,
    EOD: null,
  };

  const methods = useForm({
    resolver: yupResolver(StaffDuties),

    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = methods;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    const editedDuties = {
      schoolId,
      dutyId,
      EOD: data.endDate,
      SOD: data.startDate,
    };

    try {
      const response = await axoisCustomAgent.put(
        `SchoolStaff/${schoolId}/EditDutyAssignedToStaff/${dutyId}`,
        {
          ...editedDuties,
        }
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "success" });
      //methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return (
    <Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>
        <Grid item width={300}>
          <FormHelperText>Start of duty : </FormHelperText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={methods.watch().SOD}
              disablePast
              onChange={(value) => {
                methods.setValue("SOD", value, {
                  shouldValidate: true,
                });
              }}
              renderInput={(params) => <RHFTextField {...params} name="SOD" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item width={300}>
          <FormHelperText> End of duty:</FormHelperText>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              // label="End of duty "
              inputFormat="dd/MM/yyyy"
              value={methods.watch().EOD}
              disablePast
              onChange={(value) => {
                methods.setValue("EOD", value, {
                  shouldValidate: true,
                });
              }}
              renderInput={(params) => <RHFTextField {...params} name="EOD" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{ textAlign: "right", mt: 3 }}>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={isSubmitting}
          >
            Update duties
          </LoadingButton>
        </Grid>
      </FormProvider>
    </Grid>
  );
}

export default EditDuties;
