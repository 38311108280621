import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function MealTime({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // new========================
    sectionObserved: "",
    isDiningFurnitureSeen: "",
    safetySignOnDisplay: "",
    staffRoster: "",
    roomFreeOfDebris: "",
    isWaterAvailable: "",
    populationObserved: "",
    effectiveBehaviourManagement: "",
    cutlerySetDisplay: "",
    evidenceOfMealServed: "",
    foodHygieneServed: "",
    balanceDietServed: "",
    playObserved: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {};

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SumbitSectionOneMealTime",

        {
          auditTaskId: taskId,
          sectionObserved: data.sectionObserved,
          isDiningFurnitureSeen: data.isDiningFurnitureSeen,
          safetySignOnDisplay: data.safetySignOnDisplay,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          staffRoster: data.staffRoster,
          roomFreeOfDebris: data.roomFreeOfDebris,
          isWaterAvailable: data.isWaterAvailable,
          populationObserved: data.populationObserved,
          effectiveBehaviourManagement: data.effectiveBehaviourManagement,
          cutlerySetDisplay: data.cutlerySetDisplay,
          evidenceOfMealServed: data.evidenceOfMealServed,
          foodHygieneServed: data.foodHygieneServed,
          balanceDietServed: data.balanceDietServed,
          playObserved: data.playObserved,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Typography variant="h4">Clerk Dashboard</Typography>
          </Box>

          <Button href={`/auditClerks/AuditGradingGeneral/${taskId}`}>
            Back
          </Button>
        </Box>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1C: 1 Meal Time
                </Typography>
              </Box>
              <ol>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          {" "}
                          Is appropriate school dining furniture seen?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="isDiningFurnitureSeen"
                      />
                    </Box>

                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>Safety signs on Display?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="safetySignOnDisplay"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Staff on supervision duties/ duties roster seen?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="staffRoster"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Room free of debris/hazards or obstructions?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="roomFreeOfDebris"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>Is access to water available?</Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="isWaterAvailable"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Room space appropriate for the children population
                          observed?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="populationObserved"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Evidence of effective behaviour management seen?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="effectiveBehaviourManagement"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Appropriate cutlery sets/ serving display units used?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="cutlerySetDisplay"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Grid>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          General evidence of orderliness and timely serving of
                          meals observed?
                        </Typography>
                      </li>
                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfMealServed"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Evidence of food hygiene knowledge in catering staff
                          observed?
                        </Typography>
                      </li>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="foodHygieneServed"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <Typography>
                          Evidence of balanced diets being served?
                        </Typography>
                      </li>
                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="balanceDietServed"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        {" "}
                        <Typography>
                          Whole school sections observed ?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="sectionObserved"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </Box>

                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <RHFTextField
                          name="yearGroupObserved"
                          label="Appropriate number of adult supervision on duty "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1, width: 400 }}>
                      <li>
                        <RHFTextField
                          name="playObserved"
                          label="Types of play observed "
                          variant="filled"
                        />
                      </li>
                    </Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="comment"
                          label="Comments?"
                          variant="filled"
                          multiline
                          minRows={3}
                        />
                      </li>
                    </Box>
                    <Grid sx={{ textAlign: "right" }}>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        loading={isSubmitting}
                        // onClick={Navigate}
                        sx={{
                          width: 100,
                          color: "#fff",
                          background: "#018fe2",
                        }}
                        // startIcon={<Back />}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </ol>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default MealTime;
