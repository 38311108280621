import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";

import BOSimage from "../../../images/crest.jpeg";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function FrameworkTwo() {
  return (
    <Container sx={{ mt: 10 }}>
      <Grid container justifyContent="center">
        <Card
          sx={{ minWidth: 300, minHeight: 200, backgroundColor: "#edf6fa" }}
        >
          <CardContent>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Compliance Monitoring and Enforcement
            </Typography>

            <Typography
              variant="body2"
              textAlign="center"
              sx={{ lineHeight: 2, p: 1 }}
            >
              MS(Minimum standard ) for Day and Boarding, Compliance Manual,
              Quick guides. Mandatory supplies PSL (Provisional Safety Licence),
              SRC(Safe Recruitment Clearance), CJ(Compliance Journey): SDD
              (Standard Due Diligence) EDD (Enhance Due
              diligence)/BOS(Beacon of Safety) SSLAG Digital Application: an App
              integrating all agencies involved in safety and security with
              Geo-enable SOS incident alert system for schools.
            </Typography>
          </CardContent>
        </Card>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          mt={5}
          width={"100%"}
        >
          <Box width={"50%"}>
            <Card
              sx={{
                minWidth: 300,
                minHeight: 300,
                p: 2,
                backgroundColor: "#edf6fa",
                mr: 15,
              }}
            >
              <CardContent>
                <Box textAlign={"center"} mt={5}>
                  <Typography variant="h5">Events</Typography>
                </Box>
                <Box textAlign={"center"}>
                  <Typography variant="body2">
                    BOS (Beacon Awards and recognition)
                  </Typography>
                  <Typography variant="body2">
                    SSLAG Annual Conference and Exhibition
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box width={"50%"}>
            <img
              src={BOSimage}
              alt="sslag event"
              style={{
                height: 360,
                width: 360,
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
