import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import InsetBookingForm from "src/pages/dashboard/InsetBookingForm";
import AdminJourneyBookingForm from "./AdminJourneyBookingForm";
import AdminJourneyInsetBooking from "./AdminJourneyInsetBooking";
import AdminFeedbackForm from "./AdminFeedbackForm";
import AdminCJLetter from "./AdminCJLetter";
import AdminKnowledgeTransferBooking from "./AdminKnowledgeTransferBooking";
import AdminjourneystaffReport from "./AdminjourneystaffReport";
import AdminJourneyProvisionSafety from "./AdminJourneyProvisionSafety";
import AdminJourneyImportanceNote from "./AdminJourneyImportanceNote";
import AdminJourneyHumanResources from "./AdminJourneyHumanResources";
import AdminJourneyDataExtractionTable from "./AdminJourneyDataExtractionTable";
import AdminJourneyLetter from "./AdminJourneyLetter";
import AdminSafeRecruitment from "./AdminSafeRecruitment";
import AdminKTBookingForm from "./AdminKTBookingForm";

function AdminInterventionForm() {
  let { tokenId, schoolId, formId } = useParams();

  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx");

  switch (formId) {
    case "1":
      return <AdminJourneyLetter schoolId={schoolId} tokenId={tokenId} />;
    case "2":
      <AdminJourneyProvisionSafety schoolId={schoolId} tokenId={tokenId} />;
    //
    case "3":
      return <AdminJourneyInsetBooking schoolId={schoolId} tokenId={tokenId} />;
    case "4":
      return <AdminJourneyBookingForm schoolId={schoolId} tokenId={tokenId} />;
    case "5":
      return (
        <AdminJourneyImportanceNote schoolId={schoolId} tokenId={tokenId} />
      );
    case "6":
      return <AdminSafeRecruitment schoolId={schoolId} tokenId={tokenId} />;
    case "7":
      return <AdminjourneystaffReport schoolId={schoolId} tokenId={tokenId} />;
    case "8":
      return (
        <AdminJourneyHumanResources schoolId={schoolId} tokenId={tokenId} />
      );
    case "9":
      return <AdminKTBookingForm schoolId={schoolId} tokenId={tokenId} />;
    case "10":
      return <AdminKTBookingForm schoolId={schoolId} tokenId={tokenId} />;
    case "11":
      return (
        <AdminJourneyDataExtractionTable
          schoolId={schoolId}
          tokenId={tokenId}
        />
      );

    default:
      return <Typography></Typography>;
  }
}

export default AdminInterventionForm;
