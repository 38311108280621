import React, { Fragment, useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTable from "react-data-table-component";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  Alert,
  Modal,
  Card,
} from "@mui/material";
import Button from "@mui/material/Button";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import logoBg from "../../images/sslagBookingForm.JPG";
import Add from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";

import { useParams } from "react-router";

import styled from "styled-components";
import useAuth from "src/hooks/useAuth";

function AdminKTBookingForm() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [bookingform, setBookingform] = useState([]);
  const { stakeholderCategory, id } = useAuthAdmin();

  let { tokenId, formId, schoolId } = useParams();
  const [cbcBookingForm, setAllCBCBookingForm] = useState(null);

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    0 &:hover {
      cursor: pointer;
    }
  `;
  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter By Name"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = bookingform.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Teaching staff",
      selector: (row) => row.numOfNonTeachingStaff,
    },
    {
      name: "Non-teaching staff",
      selector: (row) => row.numOfTeachingStaff,
    },
    {
      name: "Total quantity booked",
      selector: (row) => row.quantity,
    },
    {
      name: "Unit price",
      selector: (row) => row.unitPrice,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
  ];

  const defaultValues = {
    schoolName: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    educationDistrict: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const addKtData = (newKtData) => {
    let updatedKtData = [...bookingform];
    updatedKtData.push(newKtData);
    setBookingform(updatedKtData);
  };

  const onSubmit = () => {};

  useEffect(() => {
    const getAllJourneyBookingForm = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/SchoolIntervention/GetKtBookingInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
          }
        //   method.reset()
        setAllCBCBookingForm(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllJourneyBookingForm();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (cbcBookingForm === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (cbcBookingForm === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found....</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box textAlign={"center"}>
          <Box>
            <Grid>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 200,
                }}
              />
            </Grid>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
            }}
          >
            SSLAG Knowledge Transfer Course Booking Form
          </Typography>
        </Box>
      </Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 5,
            minHeight: 400,
          }}
        >
          <Grid mt={3}>
            <Box
              sx={{
                background: "#097969",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
                minWidth: "70%",
              }}
            >
              <Typography textAlign={"center"} variant="h6" padding={2}>
                Knowledge Transfer Course Booking Form
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} p={2}>
              <Box width={400}>
                <Grid>
                  <RHFTextField
                    name="schoolName"
                    label="School Name"
                    variant="filled"
                  />
                </Grid>

                <Grid mt={2}>
                  <RHFTextField
                    name="phoneNumber"
                    label="phone Number"
                    variant="filled"
                  />
                </Grid>
                <Grid mt={2}>
                  <RHFTextField
                    name="emailAddress"
                    label="Email"
                    variant="filled"
                  />
                </Grid>
              </Box>
              <Box width={400}>
                <Grid mt={2}>
                  <RHFTextField
                    name="educationDistrict"
                    label="Education District"
                    variant="filled"
                  />
                </Grid>
                <Grid mt={2}>
                  <RHFTextField
                    name="address"
                    label="Address"
                    variant="filled"
                  />
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Card>
            <Grid p={3}>
              {bookingform.length === 0 ? (
                <Typography>No record found</Typography>
              ) : (
                <DataTable
                  columns={columns}
                  data={bookingform}
                  editModde="row"
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                  customStyles={customStyles}
                />
              )}
            </Grid>
          </Card>
        </Container>
      </FormProvider>
    </Fragment>
  );
}

export default AdminKTBookingForm;
