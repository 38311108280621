// routes
import { PATH_CLERK, PATH_DASHBOARD } from "../../../routes/paths";
// components
// import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  school: getIcon("school"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  admin: getIcon("admin"),
  key_element: getIcon("element"),
  advocacy: getIcon("advocacy"),
  monitoring: getIcon("monitoring"),
  transfer: getIcon("transfer"),
  support: getIcon("support"),
  E_Wwallet: getIcon("e_wallet"),
  quickLink: getIcon("quick_link"),
  notifications: getIcon("notifications"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Dashboard",
    items: [
      {
        title: "Home",
        path: "/auditClerks/dashboard",
        icon: ICONS.school,

        // icon: ICONS.dashboard,
      },
    ],
  },

  //NOTIFICATION
  //==================================================

  // {
  //   subheader: "TASKBOARD",

  //   items: [
  //     {
  //       title: "Taskboard ",

  //       path: PATH_CLERK.Taskboard.root,
  //       icon: ICONS.analytics,
  //       children: [
  //         {
  //           title: "Audit",
  //           path: PATH_CLERK.Taskboard.auditTask,

  //           children: [

  //               {title:"Task", path:PATH_CLERK.Taskboard.auditTask},
  //               // {title:"Assign Clerk", path:PATH_CLERK.Taskboards.auditClerks},

  //           ],

  //         }
  //       ]
  //     },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: "TASKBOARD",

    items: [
      {
        title: "Taskboard",
        path: PATH_CLERK.Osp.root,
        icon: ICONS.analytics,
        children: [
          {
            title: "Audit",
            path: PATH_CLERK.Osp.auditTask,

            children: [
              { title: "Task", path: PATH_CLERK.Osp.auditTask },
              // {title:"sample", path:PATH_CLERK.Osp.sample},
              // {title:"Assign Clerk", path:PATH_CLERK.Taskboards.auditClerks},
            ],
          },
          {
            title: "Evaluation",
            // path: "/auditClerks/dashboard",
            path: "/coming-soon",
          },
          {
            title: "Spot Checks",
            path: "/coming-soon",
            // path: "/auditClerks/dashboard",
          },
        ],
      },
    ],
  },

  //-------------------------------------------------------------
  {
    subheader: "KNOWLEDGE TRANSFER",

    items: [
      {
        title: "KT -Online",
        // path: PATH_DASHBOARD.eCommerce.root,
        path: "/coming-soon",
        icon: ICONS.transfer,
      },
    ],
  },

  {
    subheader: "PRACTICE TOOLS",
    items: [
      // MANAGEMENT : USER
      {
        title: "Practice Tools",
        // path: PATH_DASHBOARD.user.root,
        path: "/coming-soon",
        // path: "",
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: "SUPPORT",

    items: [
      {
        title: "Support",
        // path: PATH_DASHBOARD.eCommerce.root,
        path: "/coming-soon",
        icon: ICONS.support,
      },
    ],
  },

  //----------------------------------------------------------------------------
];

export default navConfig;
