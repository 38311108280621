import {
    Box,
    Divider,
    Container,
    Grid,
    Typography,
    Stack,
    Alert,
  } from "@mui/material";
  import React, { Fragment, useState, useEffect } from "react";
  import useResponsive from "../../../hooks/useResponsive";
  import { styled } from "@mui/material/styles";
  import LoadingButton from "@mui/lab/LoadingButton";
  import AdminBG from "../../../images/background.png";
  import Back from "@material-ui/icons/ArrowBackIosOutlined";
  import { useForm } from "react-hook-form";
  import axoisCustomAgent, {
    axoisCustomAgentForAdmin,
  } from "../../../utils/axiosCustomAgent";
  import useAuthAdmin from "src/hooks/useAuthAdmin";
  import { useSnackbar } from "notistack";
  
  import {
    FormProvider,
    RHFRadioGroup,
    RHFTextField,
    RHFCheckbox,
  } from "../../../components/hook-form";
  import { useNavigate, useParams } from "react-router";
  
  function SafeguardingAdmin({ nextStep, prevStep, schoolId, auditId }) {
    const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
    const { stakeholderCategory, id } = useAuthAdmin();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    let { taskId, clerkId } = useParams();
  
    const handleChangeCheckBox = (event) => {
      setCheckBoxChecked(event.target.value);
    };
  
    const [direction, setDirection] = useState("");
  
    const handleDirectionChange = (direction) => {
      setDirection(direction);
    };
    const defaultValues = {
      policySighted: "",
      staffTeamsIdentified: "",
      policyStatementObserved: "",
      areStudentsAware: "",
      isInformationSharedWithParents: "",
      areStaffAware: "",
      isCommunityAwareOfLockInformation: "",
      isStaffAwareOfChildAbuse: "",
      isStaffAwareOfConfidentialityClause: "",
      safetyPrecautionsInOnlineLearning: "",
      onlineSafetyprecautions: "",
    };
    useEffect(() => {
        let unmounted = false;
        const GetStartOfDay = async () => {
          setLoading(true);
          try {
            const response = await axoisCustomAgentForAdmin.get(
              `/Audit/GetSectionSixSafeguarding?taskId=${taskId}&clerkId=${clerkId}`
            );
            const { statusCode, message } = response.data;
    
            if (statusCode !== 200) {
              throw new Error(message);
            }
            const { data } = response.data;
            methods.reset({ ...data });
    
            //setGeneralSection(response.data.data);
          } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
          } finally {
            setLoading(false);
          }
        };
    
        if (id !== null && id !== "" && !unmounted) GetStartOfDay();
        return () => {
          unmounted = true;
        };
      }, []);
    const methods = useForm({
      // resolver: yupResolver(),
      defaultValues,
    });
    const sample = [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  
    const {
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting },
    } = methods;
  
    const onSubmit = () => {};
  
    return (
      <Fragment>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
            background: `url(${AdminBG})`,
            minHeight: "100vh",
            backgroundCover: "contain",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 1,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 600,
              width: "60%",
            }}
          >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={1} m={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
  
                <Box textAlign={"center"}>
                  <Typography
                    variant="h4"
                    sx={{
                      background: "#00bbea",
                      // textAlign:"centre",
                      mt: 2,
                      p: 2,
                    }}
                  >
                    SECTION 6: Strategies adopted for safeguarding
                  </Typography>
                </Box>
  
                <Typography fontSize={"15px"}>
                  *Please indicate all that are seen
                </Typography>
  
                <Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography> Safeguarding policy sighted?</Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="policySighted"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are staff terms identified?</Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="staffTeamsIdentified"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are staff code of conduct Observed?</Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="policyStatementObserved"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>Are students aware?</Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="areStudentsAware"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Is information about this shared with parents?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isInformationSharedWithParents"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are most staff aware of safeguarding(and know what to do to
                      uphold it)?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="areStaffAware"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Is lockdown information known to the school community?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isCommunityAwareOfLockInformation"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are staff aware of sign of child abuse and know what to do
                      if children are risk ?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isStaffAwareOfChildAbuse"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are online safety precautions utilised in learning platforms
                      within the school / Safe Online internet deployed?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="onlineSafetyprecautions"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Is the obligations for confidentiality shared with members
                      of staff ?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isStaffAwareOfConfidentialityClause"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <Typography>
                      Are online safety precautions are utilised in learning
                      platforms with the school ?
                    </Typography>
  
                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="safetyPrecautionsInOnlineLearning"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                </Box>
                
              </Stack>
            </FormProvider>
          </Container>
        </Grid>
      </Fragment>
    );
  }
  export default SafeguardingAdmin;
  