import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Modal,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";



function AuditClerksModel({schoolId}) {
  const { enqueueSnackbar } = useSnackbar();
  const [schoolAssigned, setSchoolAssigned] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const { userId  } = userData;


  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = schoolAssigned.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);
    
    
    
  const columns = [
    {
      name: "#",
      cell: (row, index) => <p>{index + 1}</p>,
      width: "60px",
    },
    {
      name: "School Name",
      selector: (row) => row.schoolName,
    },
    {
      name: "School Type",
      selector: (row) => row.schoolTypeName,
    },

    {
      name: "Date Assigned",
      selector: (row) => row.dateAssiged.split("T")[0],
    },
    {
        cell: (row) =>( <Button variant="contained" onClick={() => AssignAuditClerk(row.id)}> Assgin</Button>),
      },
  ];

  useEffect(() => {
    let unmounted = false;
    const getAllAssignedSchool = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AandE/GetAssignedSchools?assessorId=${userId}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setSchoolAssigned([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllAssignedSchool();
    return () => {
      unmounted = true;
    };
  }, []);
    
    
    
  const AssignAuditClerk = async ( auditClerkId) => {
    try {
      const response = await axoisCustomAgent.post(
          `AandE/AssignSchoolToAuditClerk`,
          {
            auditClerkId,
            schoolId,
          }

      );

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
     

      enqueueSnackbar(message, { variant: "success" });
    //   methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };


  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return <Fragment> <Grid
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingInline: "20px",
    paddingTop: 5,
    background: `url(${AdminBG})`,
    minHeight: "100vh",
    backgroundCover: "contain",
  }}
>
  <Typography variant="h4">
   Records of Assigned Clerks
  </Typography>
  <Card
    sx={{
      width: "80%",
      mt: 4,
    }}
  >
    <Grid p={3}>
      {schoolAssigned.length === 0 ? (
        <Typography>No record found</Typography>
      ) : (
        <DataTable
          columns={columns}
          data={schoolAssigned}
          filteredItems
          editModde="row"
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          responsive
          customStyles={customStyles}
        />
      )}
    </Grid>
  </Card>
  
</Grid></Fragment>;
}

export default AuditClerksModel;
