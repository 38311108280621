import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CardActions,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function StaffDasboard() {
  // const {
  //   userData: { stakeholderCategory, schoolStaff },
  // } = useAuth();

  return (
    <Box>
      <Typography fontSize={30} fontFamily={"lato"}>
        Staff Dashboard
      </Typography>
      <hr />

      <Grid container spacing={2} mt={2}>
        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Total Student</Typography>
              </Box>
              <Typography variant="h3">357</Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Total Number Subject Taken</Typography>
              </Box>
              <Typography variant="h3">2</Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Total Number of Teachers</Typography>
                <Typography variant="h3">17</Typography>
              </Box>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Total Number of Students </Typography>
              </Box>
              <Typography variant="h3">1,200</Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Next Event</Typography>
              </Box>
              <Typography variant="h3">Sport</Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <Typography>Staff Duty</Typography>
              </Box>
              <Typography variant="h3">Lunch Duties</Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StaffDasboard;
