import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// import GuestGuard from "../guards/GuestGuard";
// import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
import SchoolStaffGuard from "../guards/SchoolStaffGuard";
import AdminGuard from "../guards/AdminGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

import ProfileComplete from "src/pages/auth/ProfileComplete";
import TermNdCondition from "src/pages/auth/TermNdCondition";
import Admin from "src/sections/Admin/Admin";
import AdminAnalytic from "src/sections/Admin/AdminAnalytic";
import AdminReport from "src/sections/Admin/AdminStaffDuty";
import AdminSupport from "src/sections/Admin/AdminSupport";

import CreateAdmin from "src/sections/Admin/CreateAdmin";
import Training from "src/sections/Admin/Training";
import StaffManagemnt from "src/sections/Admin/StaffManagement";
import AdminDashboard from "src/sections/Admin/AdminDashboard";
import AdminLogin from "src/sections/Admin/AdminLogin";
import CreatePassword from "src/sections/Admin/CreatePassword";
import Student from "../pages/dashboard/Student";
import Staff from "../pages/dashboard/Staff.js";
import AllStudent from "../pages/dashboard/AllStudent";
import AdminList from "src/sections/Admin/AdminList";
import AllParent from "../pages/dashboard/AllParent";
import Classes from "src/pages/dashboard/Classes";
import AddClass from "src/pages/dashboard/AddClass";
import AddClassArm from "src/pages/dashboard/AddClassArm";
import AssignClass from "src/pages/dashboard/AssignClass";
import AllStaff from "src/pages/dashboard/AllStaff";

import Unallocate from "src/pages/dashboard/Unallocate";
import Attendance from "src/pages/dashboard/Attendance";
import StaffDuties from "src/pages/dashboard/StaffDuties";
import Location from "src/pages/dashboard/Location";
import ViewAttendance from "src/pages/dashboard/ViewAttendance";
import TeacherAdmin from "src/sections/TeacherDashboard/TeacherAdmin";
import TeacherAttendance from "src/sections/TeacherDashboard/TeacherAttendance";
import StaffDasboard from "src/sections/TeacherDashboard/StaffDashboard";
import TeacherDuty from "src/sections/TeacherDashboard/TeacherDuty";
import TeacherCreatePassword from "src/sections/TeacherDashboard/TeacherCeatePassword";
import TeacherView from "src/sections/TeacherDashboard/TeacherView";
import AdminAttendance from "src/sections/Admin/AdminAttendance";
import AdminAllStudent from "src/sections/Admin/AdminAllStudent";
import AdminSchools from "src/sections/Admin/AdminSchools";
import Quickguide from "src/pages/dashboard/Quickguide";
import Ministandard from "src/pages/dashboard/Ministandard";
import ProtocolGuidance from "src/pages/dashboard/ProtocolGuidance";
import Sms from "src/pages/dashboard/Sms";
import Checklist from "src/pages/dashboard/Checklist";
import Postaudit from "src/pages/dashboard/Postaudit";
import TermsGlossary from "src/pages/dashboard/TermsGlossary";
import ProjectImplementation from "src/pages/dashboard/ProjectImplementation";
import MandatorySupplies from "src/pages/dashboard/MandatorySupplies";
import Outservice from "src/pages/dashboard/Outservice";
import Protocols from "src/pages/dashboard/Protocols";

import Pending from "src/pages/dashboard/Pending";
import SchoolDashboardGuard from "src/guards/SchoolDashboardGuard";
import DecTermNdCondition from "src/pages/auth/DecTermNdCondition";
import DocumentaryEvidence from "src/pages/dashboard/DocumentaryEvidence";
import Assessment from "src/pages/dashboard/Assessment";
import Incident from "src/pages/dashboard/Incident";
import Playequipment from "src/pages/dashboard/Playequipment";
import Foodvendor from "src/pages/dashboard/Foodvendor";
import Preparedness from "src/pages/dashboard/Preparedness";
import { Alerts, AppNewInvoice } from "src/sections/@dashboard/general/app";
import Accident from "src/pages/dashboard/Accident";
import Behavior from "src/pages/dashboard/Behavior";
import BehaviouStudent from "src/pages/dashboard/BehaviouStudent";
import Support from "src/pages/dashboard/Support";
import SafetyAudit from "src/pages/dashboard/SafetyAudit";
import Framework from "src/pages/dashboard/Framework";
import IntroductionAcknowledgement from "src/pages/dashboard/IntroductionAcknowledgement";
import Certificateparticipation from "src/pages/dashboard/Certificateparticipation";
import Signages from "src/pages/dashboard/Signages";
import Insurance from "src/pages/dashboard/Insurance";
import SafeInternet from "src/pages/dashboard/SafeInternet";
import SafetyInfrastructure from "src/pages/dashboard/SafetyInfrastructure";
import SslagEvaluation from "src/pages/dashboard/SslagEvaluation";
import OnSuccess from "src/pages/auth/OnSuccess";
import SchoolEvent from "src/sections/@dashboard/blog/SchoolEvent";
import AdminSme from "src/sections/Admin/AdminSme";
import AdminOrder from "src/sections/Admin/AdminOrder";
import AdminSchoolEvent from "src/sections/Admin/AdminSchoolEvent";
import AdminSSLAGEvent from "src/sections/Admin/AdminSSLAGEvent";
import AdminInvoice from "src/sections/Admin/AdminInvoice";
import PreAudit from "src/sections/Admin/PreAudit";
import AdminAssessors from "src/sections/Admin/AdminAssessors";
import AdminAuditReport from "src/sections/Admin/AdminAuditReport";
import AdminCreateKT from "src/sections/Admin/AdminCreateKT";
import AdminManageKT from "src/sections/Admin/AdminManageKT";
import AdminCalenderKT from "src/sections/Admin/AdminCalenderKT";
import KtBookingForm from "src/pages/dashboard/KtBookingForm";
import KtOnline from "src/pages/dashboard/KtOnline";
import KtCalender from "src/pages/dashboard/KtCalender";
import KtComReq from "src/pages/dashboard/KtComReq";
import AdminNewForm from "src/sections/Admin/AdminNewForm";
import AdminPendingRequest from "src/sections/Admin/AdminPendingRequest";
import AdminIntervention from "src/sections/Admin/AdminIntervention";
import SchoolFaqhelp from "src/pages/dashboard/SchoolFaqhelp";
import AdminNewFormSchool from "src/sections/Admin/AdminNewFormSchool";
import AdminOnboarding from "src/sections/Admin/AdminOnboarding";
import AdminInterve from "src/sections/Admin/AdminInterve";
import AdminAllAlert from "src/sections/Admin/AdminAllAlert";
import MailSupport from "src/pages/dashboard/MailSupport";
import AdminSchoolDetail from "src/sections/Admin/AdminSchoolDetail";
import AdminMoe from "src/sections/Admin/AdminMoe";
import AdminDeclaration from "src/sections/Admin/AdminDeclaration";
import AdminStarterPack from "src/sections/Admin/AdminStarterPack";
import AdminSchoolDirector from "src/sections/Admin/AdminSchoolDirector";
import AdminSchoolProvision from "src/sections/Admin/AdminSchoolProvision";
import AdminSchoolMandatoryInsurance from "src/sections/Admin/AdminSchoolMandatoryInsurance";
import AdminSchoolMandatorySafety from "src/sections/Admin/AdminSchoolMandatorySafety";
import AdminFeedbackForm from "src/sections/Admin/AdminFeedbackForm";
import AdminCJLetter from "src/sections/Admin/AdminCJLetter";
import AdminKnowledgeTransferBooking from "src/sections/Admin/AdminKnowledgeTransferBooking";
import AdminJourneyBookingForm from "src/sections/Admin/AdminJourneyBookingForm";
import AdminJourneyLetter from "src/sections/Admin/AdminJourneyLetter";
import AdminJourneyProvisionSafety from "src/sections/Admin/AdminJourneyProvisionSafety";
import AdminJourneyInsetBooking from "src/sections/Admin/AdminJourneyInsetBooking";
import AdminJourneyDataExtractionTable from "src/sections/Admin/AdminJourneyDataExtractionTable";
import InsetBookingForm from "src/pages/dashboard/InsetBookingForm";
import CBCBookingForm from "src/pages/dashboard/CBCBookingForm";
import AdminInterventionForm from "src/sections/Admin/AdminInterventionForm";
import SchoolComplianceMail from "src/pages/dashboard/SchoolComplianceMail";

import AdminAuditGrading from "src/pages/AuditClerks/AuditGrading/AdminAuditGrading";
import BookAAuditOrder from "src/pages/auth/AuditRequest/BookAAuditOrder";
import AuditInvoice from "src/pages/auth/AuditInvoice";
import DiscountManagement from "src/sections/Admin/DiscountManagement";
import AuditCongratulation from "src/pages/auth/AuditRequest/AuditCongratulation";
import AdminAuditRegisterSchool from "src/sections/Admin/AdminAuditRegisterSchool";
import SchoolCJLetter from "src/pages/auth/SchoolCJLetter";
import SchoolProvisionalSafety from "src/pages/auth/SchoolProvisionalSafety";
import SchoolCJSms from "src/pages/auth/SchoolCJSms";
import SchoolCJExtractionTable from "src/pages/auth/SchoolCJExtractionTable";
import SchoolCJStaffReport from "src/pages/auth/SchoolCJStaffReport";
import SchoolCJresourcesTable from "src/pages/auth/SchoolCJresourcesTable";
import SchoolCJSslagInvoice from "src/pages/auth/SchoolCJSslagInvoice";
import SchoolCJSummary from "src/pages/auth/SchoolCJSummary";
import SchoolCJKtBookingForm from "src/pages/auth/SchoolCJKtBookingForm";
import SchoolCJFeedbackForm from "src/pages/auth/SchoolCJFeedbackForm";
import SchoolCJSaferecruiment from "src/pages/auth/SchoolCJSaferecruiment";
import AdminJourneyHumanResources from "src/sections/Admin/AdminJourneyHumanResources";
import AdminJourneyImportanceNote from "src/sections/Admin/AdminJourneyImportanceNote";
import AdminjourneystaffReport from "src/sections/Admin/AdminjourneystaffReport";
import AandEDashboardLayout from "src/pages/A&E";
// import SslagAboutUs from "src/pages/auth/SslagAboutUs";
import ConfirmPassword from "src/pages/A&E/ConfirmPassword";
import ClerksDashboardLayout from "src/pages/AuditClerks";
import AuditStructure from "src/pages/auth/AuditStructure";
import AandEAuditClerks from "src/pages/A&E/AandEAuditClerks";
import AandESchool from "src/pages/A&E/AandESchool";
import AandEDashboard from "src/pages/A&E/AandEDashboard";
import AuditClerkDashboard from "src/pages/AuditClerks/AuditClerkDashboard";
import ClerkSchool from "src/pages/AuditClerks/ClerkSchool";
import TaskPending from "src/pages/A&E/TaskPending";
import TaskCompleted from "src/pages/A&E/TaskCompleted";
import AuditTask from "src/pages/AuditClerks/AuditTask";
import ClerkConfirmPassword from "src/pages/AuditClerks/ClerkConfirmPassword";
import InitiativePage from "src/pages/auth/AuditRequest/InitiativePage";
import PrivateAudit from "src/pages/auth/AuditRequest/PrivateAudit";
import PublicAudit from "src/pages/auth/AuditRequest/PublicAudit";
import OnboardedSchool from "src/sections/Admin/OnboardedSchool";
import SchoolInitiativePage from "src/pages/dashboard/SchoolAudit/SchoolInitiativePage";
import PublicSchoolAudit from "src/pages/dashboard/SchoolAudit/PublicSchoolAudit";
import PrivateSchoolAudit from "src/pages/dashboard/SchoolAudit/PrivateSchoolAudit";
// import LessonObservation from "src/sections/";
import AuditGradingGeneral from "src/pages/AuditClerks/AuditGrading/AuditGradingGeneral";
import LessonObservation from "src/pages/AuditClerks/AuditGrading/LessonObservation";
import StartOfDay from "src/pages/AuditClerks/AuditGrading/StartOfDay";
import AdminAuditGradingManagement from "src/pages/AuditClerks/AuditGrading/ManagementCommitment";
import SafeSchoolPolicies from "src/pages/AuditClerks/AuditGrading/SafeSchoolPolicies";
import Recommendation from "src/pages/AuditClerks/AuditGrading/Recommendation";
import AdminAuditGradingStaffRole from "src/pages/AuditClerks/AuditGrading/StaffRoleAndResponsibility";
import SchoolTransitSystem from "src/pages/AuditClerks/AuditGrading/SchoolTransitSystem";
import ComplianceCriterion from "src/pages/AuditClerks/AuditGrading/ComplianceCriterion";
import EndOfDay from "src/pages/AuditClerks/AuditGrading/EndOfDay";
import ManagementCommitment from "src/pages/AuditClerks/AuditGrading/ManagementCommitment";
import StaffRoleAndResponsibility from "src/pages/AuditClerks/AuditGrading/StaffRoleAndResponsibility";
import MealTime from "src/pages/AuditClerks/AuditGrading/MealTime";
import SickBay from "src/pages/AuditClerks/AuditGrading/SickBay";
import MealPreparation from "src/pages/AuditClerks/AuditGrading/MealPreparation";
import OutdoorActivities from "src/pages/AuditClerks/AuditGrading/OutdoorActivities";
import Sport from "src/pages/AuditClerks/AuditGrading/Sport";
import Playground from "src/pages/AuditClerks/AuditGrading/Playground";
import Laboratory from "src/pages/AuditClerks/AuditGrading/Laboratory";
import SpecialRoom from "src/pages/AuditClerks/AuditGrading/SpecialRoom";
import AuditGradingStarter from "src/pages/AuditClerks/AuditGrading/AuditGradingStarter";
import Safeguarding from "src/pages/AuditClerks/AuditGrading/Safeguarding";
import WalkRound from "src/pages/AuditClerks/AuditGrading/Walkround";
import ComplianceCriterion1 from "src/pages/AuditClerks/AuditGrading/ComplianceCriterion1";
import ComplianceCriterion2 from "src/pages/AuditClerks/AuditGrading/ComplianceCriterion2";
import Assembly from "src/pages/AuditClerks/AuditGrading/Assembly";
import OnSiteProtocol from "src/sections/Admin/OnSiteProtocol";
import GradingForm from "src/sections/Admin/AdminForm/GradingForm";
import StartOfDayAdmin from "src/sections/Admin/AdminForm/StartOfDayAdmin";
// import labSessionAdmin from "src/sections/Admin/AdminForm/labSessionAdmin";
import WalkroundAdmin from "src/sections/Admin/AdminForm/WalkroundAdmin";
import LessonObservationAdmin from "src/sections/Admin/AdminForm/LessonObservationAdmin";
import PayLater from "src/pages/auth/AuditRequest/PayLater";
import EndOfDayAdmin from "src/sections/Admin/AdminForm/EndOfDayAdmin";
import SportAdmin from "src/sections/Admin/AdminForm/SportAdmin";
import MealTimeAdmin from "src/sections/Admin/AdminForm/MealTimeAdmin";
import MealPreparationAdmin from "src/sections/Admin/AdminForm/MealPreparationAdmin";
import PlaygroundAdmin from "src/sections/Admin/AdminForm/PlaygroundAdmin";
import OutdoorActivitiesAdmin from "src/sections/Admin/AdminForm/OutdoorActivitiesAdmin";
import SpecialRoomAdmin from "src/sections/Admin/AdminForm/SpecialRoomAdmin";
import AssemblyAdmin from "src/sections/Admin/AdminForm/AssemblyAdmin";
import AdminCC1 from "src/sections/Admin/AdminForm/AdminCC1";
import AdminCC2 from "src/sections/Admin/AdminForm/AdminCC2";
import AdminCC3 from "src/sections/Admin/AdminForm/AdminCC3";
import AdminSchoolPolicies from "src/sections/Admin/AdminForm/AdminSchoolpolicies";
import SafeguardingAdmin from "src/sections/Admin/AdminForm/SafeguardingAdmin";
import AdminSchoolTransit from "src/sections/Admin/AdminForm/AdminSchoolTransit";
import AdminRecommendation from "src/sections/Admin/AdminForm/AdminRecommendation";
import SickBayAdmin from "src/sections/Admin/AdminForm/SickBayAdmin";
import MandatorySuppliesAdmin from "src/sections/Admin/AdminForm/MandatorySuppliesAdmin";
import SchoolAuditOrder from "src/pages/dashboard/SchoolAudit/SchoolAuditOrder";
import AuditClerkCongrat from "src/pages/AuditClerks/AuditGrading/AuditClerkCongrat";
import HomeLanding from "src/pages/auth/HomeLanding";
import PublicAuditInvoice from "src/pages/auth/AuditRequest/PublicAuditInvoice";
import SchoolAuditCongrate from "src/pages/dashboard/SchoolAudit/SchoolAuditCongrate";
import SchoolAuditInvoice from "src/pages/dashboard/SchoolAudit/SchoolAuditInvoice";
import RequestDemo from "src/pages/auth/RequestDemo";
import RequestPageGuard from "src/guards/RequestPageGuard";
import SupportForm from "src/pages/A&E/SupportForm";
import PayAudit from "src/pages/auth/AuditRequest/PayAudit";
import PayAuditInvoice from "src/pages/auth/AuditRequest/PayAuditInvoice";
import AuditPaymentCongratulation from "src/pages/auth/AuditRequest/AuditPaymentCongratulation";
import UpcomingEvent from "src/pages/auth/Event/UpcomingEvent";
import PastAuditedSchool from "src/sections/Admin/PastAuditedSchool";
import ComplianceObligations from "src/pages/auth/Obligations.js/ComplianceObligations";
import Gallery from "src/pages/auth/Event/Gallery";
import SSlagAboutHero from "src/pages/auth/AboutSSlag/SSlagAboutHero";
import SslagAbout from "src/pages/SslagAbouts";
import Marketplace from "src/pages/Marketplace";
import AdminConcession from "src/sections/Admin/Concession/AdminConcession";
import SslagEvent from "src/sections/Admin/Event/SslagsEvent";
import EventSingleform from "src/pages/auth/Event/EventSingleform";
import EventSchoolform from "src/pages/auth/Event/EventSchoolform";
import EventCongratulation from "src/pages/auth/Event/EventCongratulation";
import Advocacy from "src/pages/Advocacy";
import ManageEvent from "src/sections/Admin/Event/ManageEvent";
import Bos from "src/pages/dashboard/Bos/Bos";
import OptionalDeals from "src/pages/auth/MarketPlace.js/OptionalDeals";
import Optional from "src/pages/dashboard/OptionalMarket/Optional";
import CurrentEvent from "src/pages/auth/Event/CurrentEvent";
import EventRegistrationPages from "src/pages/auth/Event/EventRegistrationPages";
import BosPdf from "src/pages/dashboard/Bos/BosPdf";
import EventManagerDashboardLayout from "src/pages/AuditClerks";
import EventManagerDashboard from "src/pages/EventManager/EventManagerDashboard";
import SchoolCertificate from "src/sections/Admin/SchoolCertificate";
import ManageBos from "src/pages/dashboard/ManageBos";
// import SslagAbout from "../pages/SSlagAbout";
// import LabSessionAdmin from "src/sections/Admin/AdminForm/labSessionAdmin";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Register />,
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
        { path: "registration-invoice", element: <RegistrationInvoice /> },
        { path: "profile-complete", element: <ProfileComplete /> },
        { path: "Request-Demo-page", element: <RequestDemo /> },
        { path: "onSuccess", element: <OnSuccess /> },

        { path: "termsandcondition", element: <TermNdCondition /> },
        { path: "DecTermNdCondition", element: <DecTermNdCondition /> },
        { path: "admin-login", element: <AdminLogin /> },

        { path: "admin-createPassword/:token", element: <CreatePassword /> },
        {
          path: "aande-createpassword/:code/:email",
          element: <ConfirmPassword />,
        },
        {
          path: "auditclerk-createpassword/:code/:email",
          element: <ClerkConfirmPassword />,
        },
        // { path: "auditinvoice/:schoolId", element: <AuditInvoice /> },
        {
          path: "staff/activateaccount/:token",
          element: <TeacherCreatePassword />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <SchoolDashboardGuard>
          <RequestPageGuard>
            <DashboardLayout />
          </RequestPageGuard>
        </SchoolDashboardGuard>
      ),

      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        { path: "ecommerce", element: <GeneralEcommerce /> },
        { path: "analytics", element: <GeneralAnalytics /> },
        { path: "banking", element: <GeneralBanking /> },
        { path: "booking", element: <GeneralBooking /> },

        // {
        //   path: "e-commerce",
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/e-commerce/shop" replace />,
        //       index: true,
        //     },
        //     { path: "shop", element: <EcommerceShop /> },
        //     { path: "product/:name", element: <EcommerceProductDetails /> },
        //     { path: "list", element: <EcommerceProductList /> },
        //     { path: "product/new", element: <EcommerceProductCreate /> },
        //     { path: "product/:name/edit", element: <EcommerceProductCreate /> },
        //     { path: "checkout", element: <EcommerceCheckout /> },
        //     { path: "invoice", element: <EcommerceInvoice /> },
        //   ],
        // },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/" replace />,
              index: true,
            },
            { path: "student", element: <Student /> },
            { path: "staff", element: <Staff /> },
            { path: "allParent", element: <AllParent /> },
            { path: "allStudent", element: <AllStudent /> },
            { path: "staff", element: <Staff /> },
            { path: "allStaff", element: <AllStaff /> },
            { path: "addClass", element: <AddClass /> },
            { path: "classArm", element: <AddClassArm /> },
            { path: "assign", element: <AssignClass /> },
            { path: "class", element: <Classes /> },
            { path: "unallocate", element: <Unallocate /> },
            { path: "attendance", element: <Attendance /> },
            { path: "staffDuties", element: <StaffDuties /> },
            { path: "addDuties", element: <Location /> },
            { path: "allAttendance", element: <ViewAttendance /> },
            { path: "Behaviour", element: <Behavior /> },
            {
              path: "behaviourStudent/:yearGroupId/:classId",
              element: <BehaviouStudent />,
            },

            { path: "quickGuide", element: <Quickguide /> },
            { path: "miniStandard", element: <Ministandard /> },
            { path: "protocolGuidance", element: <ProtocolGuidance /> },
            { path: "sms", element: <Sms /> },
            { path: "checklist", element: <Checklist /> },

            { path: "riskAssessment", element: <Assessment /> },
            { path: "incident", element: <Incident /> },
            { path: "playEquipment", element: <Playequipment /> },
            { path: "foodvendor", element: <Foodvendor /> },
            { path: "preparendness", element: <Preparedness /> },
            { path: "support", element: <Support /> },
            { path: "safetyAudit", element: <SafetyAudit /> },
            { path: "framework", element: <Framework /> },
            { path: "sslagEvaluation", element: <SslagEvaluation /> },

            {
              path: "certificateParticipation",
              element: <Certificateparticipation />,
            },
            { path: "safetyInfrastructure", element: <SafetyInfrastructure /> },
            { path: "safeInternet", element: <SafeInternet /> },
            { path: "insurance", element: <Insurance /> },
            { path: "signages", element: <Signages /> },
            {
              path: "IntroductionAcknowledgement",
              element: <IntroductionAcknowledgement />,
            },

            { path: "postAudit", element: <Postaudit /> },
            { path: "protocols", element: <Protocols /> },
            { path: "termsGlossary", element: <TermsGlossary /> },
            { path: "documentaryEvidence", element: <DocumentaryEvidence /> },
            {
              path: "projectImplementation",
              element: <ProjectImplementation />,
            },
            { path: "manadatorySupplies", element: <MandatorySupplies /> },
            { path: "outsourceService", element: <Outservice /> },
            { path: "ManageBos", element: <ManageBos /> },

            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "newUser", element: <AddClass /> },
            { path: "profileSetting", element: <UserAccount /> },
            { path: "complianceMail", element: <SchoolComplianceMail /> },
          ],
        },

        {
          path: "knowledgeTransfer",
          children: [
            {
              element: <Navigate to="/knowledgeTransfer" replace />,
              index: true,
            },
            { path: "bookingForm", element: <KtBookingForm /> },
            { path: "ktCalender", element: <KtCalender /> },
            { path: "ktOnlineCourse", element: <KtOnline /> },
            { path: "compReq", element: <KtComReq /> },
          ],
        },

        {
          path: "schoolComplianceJourney",
          children: [
            {
              element: <Navigate to="/schoolComplianceJourney" replace />,
              index: true,
            },
            { path: "InsetBookingForm/:token", element: <InsetBookingForm /> },
            { path: "CbcBookingForm/:token", element: <CBCBookingForm /> },
            { path: "cj001letter/:token", element: <SchoolCJLetter /> },
            {
              path: "provisonalsafetylicenseapplication/:token",
              element: <SchoolProvisionalSafety />,
            },
            { path: "importantnotesaboutsms/:token", element: <SchoolCJSms /> },
            {
              path: "DataExtractionTable/:token",
              element: <SchoolCJExtractionTable />,
            },
            {
              path: "StaffStatusReport/:token",
              element: <SchoolCJStaffReport />,
            },
            {
              path: "humanresourcelogtable5a/:token",
              element: <SchoolCJresourcesTable />,
            },
            { path: "FeedbackForm/:token", element: <SchoolCJFeedbackForm /> },
            {
              path: "ktcoursebookingformapp5b/:token",
              element: <SchoolCJKtBookingForm />,
            },
            {
              path: "combinedEvaluationSummary/:token",
              element: <SchoolCJSummary />,
            },
            { path: "SslagInvoice", element: <SchoolCJSslagInvoice /> },

            {
              path: "saferecruitmentevaluationform/:token",
              element: <SchoolCJSaferecruiment />,
            },
          ],
        },
        {
          path: "support",
          children: [
            {
              element: <Navigate to="/support" replace />,
              index: true,
            },
            { path: "faqHelp", element: <SchoolFaqhelp /> },
          ],
        },

        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/" replace />,
              index: true,
            },
            { path: "alerts", element: <Alerts /> },
            { path: "appNewinvoice", element: <AppNewInvoice /> },
            { path: "pending", element: <Pending /> },
            // { path: "accident", element: <Accident /> },
            { path: "schoolEvent", element: <SchoolEvent /> },

            { path: "posts", element: <BlogPosts /> },
            { path: "post/:title", element: <BlogPost /> },
            { path: "new-post", element: <BlogNewPost /> },
          ],
        },

        {
          path: "schoolAudit",
          children: [
            {
              element: <Navigate to="/dashboard/schoolAudit/" replace />,
              index: true,
            },

            { path: "Audit-Notice", element: <SchoolInitiativePage /> },
            { path: "School-auditOrder", element: <SchoolAuditOrder /> },
            { path: "PrivateSchool", element: <PrivateSchoolAudit /> },
            { path: "PublicSchool", element: <PublicSchoolAudit /> },
            {
              path: "SchoolAuditcongrate/:schoolId",
              element: <SchoolAuditCongrate />,
            },
            {
              path: "SchoolAuditInvoice/:schoolId",
              element: <SchoolAuditInvoice />,
            },
          ],
        },

        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },

            { path: "supportmail", element: <MailSupport /> },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        // {
        //   path: "chat",
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: "new", element: <Chat /> },
        //     { path: ":conversationKey", element: <Chat /> },
        //   ],
        // },
        // { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
      ],
    },

    //admin dashboard

    {
      path: "admin",
      element: (
        <AdminGuard>
          <Admin />
        </AdminGuard>
      ),

      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        //{ element: <Navigate to="admin/dashboard" replace />, index: true },
        {
          path: "dashboard",
          element: <AdminDashboard />,
        },
        {
          path: "users",
          children: [
            {
              element: <Navigate to="/admin/user-management/" replace />,
              index: true,
            },
            { path: "createAdmins", element: <CreateAdmin /> },
            { path: "adminList", element: <AdminList /> },
          ],
        },
        {
          path: "school",
          children: [
            {
              element: <Navigate to="/admin/sch-management/" replace />,
              index: true,
            },
            { path: "training", element: <Training /> },
            { path: "admin-attendance", element: <AdminAttendance /> },
            { path: "staff-management", element: <StaffManagemnt /> },
            { path: "discountManagement", element: <DiscountManagement /> },
            { path: "schools-allstudent", element: <AdminAllStudent /> },
            { path: "schools-allschools", element: <AdminSchools /> },
            {
              path: "AdminBosCertificate/:schoolId",
              element: <SchoolCertificate />,
            },
          ],
        },

        {
          path: "knowledgeTransfer",
          children: [
            {
              element: <Navigate to="/admin/knowledgeTransfer" replace />,
              index: true,
            },
            { path: "create", element: <AdminCreateKT /> },
            { path: "manage", element: <AdminManageKT /> },
            { path: "calender", element: <AdminCalenderKT /> },
          ],
        },

        {
          path: "marketPlace",
          children: [
            {
              element: <Navigate to="/admin/marketPlace/" replace />,
              index: true,
            },
            // { path: "ManageCollaSME", element: <AdminSme /> },
            { path: "Order", element: <AdminOrder /> },
          ],
        },

        {
          path: "Event",
          children: [
            {
              element: <Navigate to="/admin/event/" replace />,
              index: true,
            },
            { path: "school-event", element: <AdminSchoolEvent /> },
            { path: "sslag-event", element: <SslagEvent /> },
            { path: "invoices", element: <AdminInvoice /> },
            { path: "analytics", element: <AdminAnalytic /> },
            { path: "manage-attendence/:eventId", element: <ManageEvent /> },
          ],
        },

        {
          path: "Audit",
          children: [
            {
              element: <Navigate to="/admin/audit/" replace />,
              index: true,
            },
            {
              path: "pre-Audit-Protocol",
              element: <PreAudit />,
            },
            {
              path: "OnboardedSchool",
              element: <OnboardedSchool />,
            },
            {
              path: "onSiteProtocol",
              element: <OnSiteProtocol />,
            },
            { path: "postAuditProtocol", element: <AdminAuditReport /> },
            {
              path: "gradingForm/:taskId/:clerkId/:roleId",
              element: <GradingForm />,
            },
            {
              path: "startOfDay/:taskId/:clerkId/:roleId",
              element: <StartOfDayAdmin />,
            },
            {
              path: "endOfday/:taskId/:clerkId/:roleId",
              element: <EndOfDayAdmin />,
            },
            { path: "sport/:taskId/:clerkId/:roleId", element: <SportAdmin /> },
            // { path: "LabSession/:taskId", element: <LabSessionAdmin /> },
            {
              path: "LessonObservation/:taskId/:clerkId/:roleId",
              element: <LessonObservationAdmin />,
            },
            {
              path: "walkround/:taskId/:clerkId/:roleId",
              element: <WalkroundAdmin />,
            },
            {
              path: "MealTime/:taskId/:clerkId/:roleId",
              element: <MealTimeAdmin />,
            },
            {
              path: "MealPreparation/:taskId/:clerkId/:roleId",
              element: <MealPreparationAdmin />,
            },
            {
              path: "playground/:taskId/:clerkId/:roleId",
              element: <PlaygroundAdmin />,
            },
            {
              path: "outdoorActivites/:taskId/:clerkId/:roleId",
              element: <OutdoorActivitiesAdmin />,
            },
            {
              path: "specialRoom/:taskId/:clerkId/:roleId",
              element: <SpecialRoomAdmin />,
            },
            {
              path: "assembly/:taskId/:clerkId/:roleId",
              element: <AssemblyAdmin />,
            },
            {
              path: "complianceCriterion1/:taskId/:clerkId/:roleId",
              element: <AdminCC1 />,
            },
            {
              path: "complianceCriterion2/:taskId/:clerkId/:roleId",
              element: <AdminCC2 />,
            },
            {
              path: "complianceCriterion3/:taskId/:clerkId/:roleId",
              element: <AdminCC3 />,
            },
            {
              path: "safeSchoolPolicies/:taskId/:clerkId/:roleId",
              element: <AdminSchoolPolicies />,
            },
            {
              path: "Safeguarding/:taskId/:clerkId/:roleId",
              element: <SafeguardingAdmin />,
            },
            {
              path: "mandatorySupplies/:taskId/:clerkId/:roleId",
              element: <MandatorySuppliesAdmin />,
            },
            {
              path: "schoolTransit/:taskId/:clerkId/:roleId",
              element: <AdminSchoolTransit />,
            },
            {
              path: "recommdation/:taskId/:clerkId/:roleId",
              element: <AdminRecommendation />,
            },
            {
              path: "sickBay/:taskId/:clerkId/:roleId",
              element: <SickBayAdmin />,
            },
            {
              path: "staffRoles/:taskId/:clerkId/:roleId",
              element: <GradingForm />,
            },

            { path: "AuditRegister", element: <AdminAuditRegisterSchool /> },
            { path: "assessors/:schoolId", element: <AdminAssessors /> },
            { path: "audited-school", element: <PastAuditedSchool /> },
          ],
        },

        {
          path: "OnboardingProcess",
          children: [
            {
              element: <Navigate to="/admin/OnboardingProcess/" replace />,
              index: true,
            },
            {
              path: "newForm",
              element: <AdminNewForm />,
            },
            {
              path: "pendingRequest",
              element: <AdminPendingRequest />,
              children: [
                {
                  path: "label/:customLabel",
                  element: <AdminPendingRequest />,
                },
                {
                  path: "label/:customLabel/:mailId",
                  element: <AdminPendingRequest />,
                },
                { path: ":systemLabel", element: <AdminPendingRequest /> },
                {
                  path: ":systemLabel/:mailId",
                  element: <AdminPendingRequest />,
                },
              ],
            },

            {
              path: "adminInterventionForm/:tokenId/:schoolId/:formId",
              element: <AdminInterventionForm />,
            },
            {
              path: "requestIntervention",
              element: <AdminIntervention />,
            },
            {
              path: "newFormSchool",
              element: <AdminNewFormSchool />,
            },
            {
              path: "adminOnboarding",
              element: <AdminOnboarding />,
            },
            {
              path: "adminInvoice",
              element: <AdminInvoice />,
            },
            {
              path: "adminschooldetails/:schoolId",
              element: <AdminSchoolDetail />,
            },
            { path: "adminschoolPersonelmoe/:schoolId", element: <AdminMoe /> },
            {
              path: "adminschoolprovision/:schoolId",
              element: <AdminSchoolProvision />,
            },
            {
              path: "adminschoolsafety-management/:schoolId",
              element: <AdminSchoolMandatorySafety />,
            },
            {
              path: "adminschooldirector/:schoolId",
              element: <AdminSchoolDirector />,
            },
            {
              path: "adminschoolmandatory-insurance/:schoolId",
              element: <AdminSchoolMandatoryInsurance />,
            },
            {
              path: "adminschoolstarterPack/:schoolId",
              element: <AdminStarterPack />,
            },
            { path: "adminschooldeclarations", element: <AdminDeclaration /> },
            { path: "adminAllAlert/:schoolId", element: <AdminAllAlert /> },
          ],
        },

        {
          path: "complianceJourney",
          children: [
            {
              element: <Navigate to="/admin/complianceJourney/" replace />,
              index: true,
            },
            {
              path: "AdminFeedBackForm",
              element: <AdminFeedbackForm />,
            },

            {
              path: "KnowledgeTransferBooking",
              element: <AdminKnowledgeTransferBooking />,
            },
            {
              path: "JourneyBookingForm",
              element: <AdminJourneyBookingForm />,
            },
            {
              path: "JourneyStaffReport",
              element: <AdminjourneystaffReport />,
            },
            {
              path: "JourneyProvisionalSafety",
              element: <AdminJourneyProvisionSafety />,
            },
            { path: "AdminCJLetter", element: <AdminJourneyLetter /> },
            {
              path: "JourneyImportantNote",
              element: <AdminJourneyImportanceNote />,
            },
            {
              path: "JourneyHumanResources",
              element: <AdminJourneyHumanResources />,
            },
            {
              path: "JourneyInsetbooking",
              element: <AdminJourneyInsetBooking />,
            },
            {
              path: "JourneyDataExtractionTable/:tokenId/:schoolId/:formId",
              element: <AdminJourneyDataExtractionTable />,
            },
          ],
        },

        { path: "reports", element: <AdminReport /> },

        { path: "analytic", element: <AdminAnalytic /> },
        { path: "support", element: <AdminSupport /> },
        { path: "concession", element: <AdminConcession /> },
      ],
    },

    // staff dashboard=================================

    {
      path: "staff",
      element: (
        <SchoolStaffGuard>
          <TeacherAdmin />
        </SchoolStaffGuard>
      ),

      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        //{ element: <Navigate to="admin/dashboard" replace />, index: true },
        {
          path: "dashboard",
          element: <StaffDasboard />,
        },
        {
          path: "teacher",
          children: [
            {
              element: <Navigate to="/staff/teacher/" replace />,
              index: true,
            },
            { path: "mark-attendance", element: <TeacherAttendance /> },
            { path: "view-attendance", element: <TeacherView /> },
          ],
        },

        { path: "staffDuty", element: <TeacherDuty /> },
        { path: "analytic", element: <AdminAnalytic /> },
        { path: "support", element: <AdminSupport /> },
      ],
    },

    //A&E dashboard============
    {
      path: "AandE",
      element: <AandEDashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: <AandEDashboard />,
        },
        { path: "AuditClerks", element: <AandEAuditClerks /> },
        { path: "AandEAssignSchool", element: <AandESchool /> },
        { path: "pendingTask", element: <TaskPending /> },
        { path: "completeTask", element: <TaskCompleted /> },
        { path: "SupportUsForm", element: <SupportForm /> },
      ],
    },

    //Audit clerk dashboard

    {
      path: "AuditClerks",
      element: <ClerksDashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: <AuditClerkDashboard />,
        },
        { path: "auditTask", element: <AuditTask /> },
        {
          path: "AuditGradingGeneral/:taskId",
          element: <AuditGradingGeneral />,
        },
        { path: "LessonObservation/:taskId", element: <LessonObservation /> },
        { path: "Assembly/:taskId", element: <Assembly /> },
        // { path: "Assembly/:taskId", element: <AdminAuditGrading /> },
        { path: "StartOfDay/:taskId", element: <StartOfDay /> },
        { path: "EndOfday/:taskId", element: <EndOfDay /> },
        { path: "MealTime/:taskId", element: <MealTime /> },
        { path: "SickBay/:taskId", element: <SickBay /> },
        { path: "MealPreparation/:taskId", element: <MealPreparation /> },
        { path: "Playground/:taskId", element: <Playground /> },
        { path: "Sport/:taskId", element: <Sport /> },
        { path: "OutdoorActivites/:taskId", element: <OutdoorActivities /> },
        { path: "SpecialRoom/:taskId", element: <SpecialRoom /> },
        { path: "LabSession/:taskId", element: <Laboratory /> },
        { path: "Walkround/:taskId", element: <WalkRound /> },
        { path: "auditClerkCongrat/:taskId", element: <AuditClerkCongrat /> },
        {
          path: "complianceCriterion1/:taskId",
          element: <ComplianceCriterion1 />,
        },
        {
          path: "complianceCriterion2/:taskId",
          element: <ComplianceCriterion2 />,
        },
        {
          path: "StarterPack&RecommendedSafety/:taskId",
          element: <AuditGradingStarter />,
        },
        {
          path: "GradingPolicies/:taskId",
          element: <SafeSchoolPolicies />,
        },
        {
          path: "Safeguarding/:taskId",
          element: <Safeguarding />,
        },

        { path: "StaffRoles/:taskId", element: <StaffRoleAndResponsibility /> },
        {
          path: "Recommendation/:taskId",
          element: <Recommendation />,
        },

        {
          path: "SchoolTransit/:taskId",
          element: <SchoolTransitSystem />,
        },

        {
          path: "GradingManagement/:taskId",
          element: <ManagementCommitment />,
        },

        { path: "SchoolSafety/:taskId", element: <ComplianceCriterion /> },
        { path: "congratulation/:taskId", element: <AuditCongratulation /> },
        // { path: "AuditClerkSchool",  element: <A />, },
        // {
        //   path: "School/:schoolId",
        //   element: <AdminAuditGrading />,
        // },
      ],
    },

    //Event Manager dashboard

    {
      path: "EventManager",
      element: <EventManagerDashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: <EventManagerDashboard />,
        },
        { path: "auditTask", element: <AuditTask /> },
        {
          path: "AuditGradingGeneral/:taskId",
          element: <AuditGradingGeneral />,
        },
      ],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        // { path: "sslagAboutUs", element: <SslagAboutUs /> },
        // { path: "sslagAboutUs", element: <SSlagAboutHero /> },
        { path: "initiativeNote", element: <InitiativePage /> },
        { path: "bookAudit", element: <BookAAuditOrder /> },
        { path: "privateAudit", element: <PrivateAudit /> },
        { path: "bos-pdf", element: <BosPdf /> },
        { path: "publicAudit", element: <PublicAudit /> },
        { path: "pay-audit", element: <PayAudit /> },
        {
          path: "registration/page",
          element: <EventRegistrationPages />,
        },
        { path: "Event", element: <UpcomingEvent /> },
        { path: "event-congratulation", element: <EventCongratulation /> },

        { path: "events-individual/:eventId", element: <EventSingleform /> },
        { path: "events-school/:eventId", element: <EventSchoolform /> },
        { path: "Gallery", element: <Gallery /> },
        { path: "complianceObligations", element: <ComplianceObligations /> },
        {
          path: "pay-audit-invoice/:tokenId",
          element: <PayAuditInvoice />,
        },
        {
          path: "pay-audit-invoice/:tokenId",
          element: <PayAuditInvoice />,
        },
        {
          path: "AuditPaymentCongratulation/:tokenId",
          element: <AuditPaymentCongratulation />,
        },
        // { path: "sslagHome", element: <HomeLanding /> },

        { path: "bookAuditInvoice/:schoolId", element: <AuditInvoice /> },
        {
          path: "publicAuditInvoice/:schoolId",
          element: <PublicAuditInvoice />,
        },
        { path: "AuditStructure", element: <AuditStructure /> },
        { path: "payLater", element: <PayLater /> },
        {
          path: "AuditCongratulation/:schoolId/:reference",
          element: <AuditCongratulation />,
        },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <About /> },
        { path: "about-sslag", element: <SslagAbout /> },
        { path: "Bos", element: <Bos /> },
        { path: "Optional", element: <Optional /> },
        { path: "Event", element: <CurrentEvent /> },
        { path: "Gallery", element: <Gallery /> },

        { path: "advocacy", element: <Advocacy /> },
        { path: "market-place", element: <Marketplace /> },

        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
const RegistrationInvoice = Loadable(
  lazy(() => import("../pages/auth/RegistrationInvoice"))
);

// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);
const EcommerceInvoice = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceInvoice"))
);
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/Student")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/AllParent")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/AllStudent"))
);
const UserCreate = Loadable(lazy(() => import("../pages/dashboard/Staff.js")));
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));
// Main
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
// const SlagAbout = Loadable(lazy(() => import("../pages/SSlagAbout")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
