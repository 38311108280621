import React from "react";
import { RHFTextField, FormProvider } from "../../../components/hook-form";

import {
  Box,
  Typography,
  Grid,
  Paper,
  Alert,
  Stack,
  Button,
  Modal,
} from "@mui/material";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";

import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import Iconify from "../../../components/Iconify";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/AddCircleOutline";

function EventSchoolform() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [delegation, setAllDelegation] = useState([]);
  let { eventId } = useParams();
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const [indexToDelete, setIndexToDelete] = useState("");
  const [newEvent, setNewEvent] = useState([]);

  useEffect(() => {
    let unmounted = false;

    const getEventById = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Events/GetEventById?id=${eventId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setNewEvent(data);
      
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getEventById();

    return () => {
      unmounted = true;
    };
  }, []);

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Hover = {
    marginRight: "20px",
    color: "black",
    // fontFamily: "Times New Roman",
    fontSize: "15px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "100vh",
  };
  const defaultValues = {
    schoolName: "",
    schoolEmail: "",
    schoolPhoneNumber: "",
    personalFullName: "",
    personalEmail: "",
    personalPhoneNumber: "",
    roleInSchool: "",
  };

  const delegationDefaultValues = {
    name: "",
    email: "",
    phoneNumber: "",
  };

  const delegationMethods = useForm({
    // resolver: yupResolver(SchoolSchema),
    delegationDefaultValues,
  });

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    // resolver: yupResolver(SchoolSchema),
    defaultValues,
  });

  const { formState, setValue, resetField } = methods;

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const deleteRecommendation = async (indexToDelete, recommendationId) => {
    await axoisCustomAgentForAdmin.delete(``);

    const newArray = delegation.filter((s, index) => index !== indexToDelete);
    setAllDelegation([...newArray]);
  };

  function handleDeleteTodo(index) {
    const newTodos = [...delegation];
    newTodos.splice(index, 1);
    setDeleting(newTodos);
  }

  const addDelegation = (newDelegation) => {
    console.log(newDelegation);
    let updatedDelegations = [...delegation];
    updatedDelegations.push(newDelegation);
    setAllDelegation(updatedDelegations);
    handleClose();
  };

  //=============
  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(`/Events/RegisterAsSchool`, {
        eventId,
        schoolName: data.schoolName,
        schoolEmail: data.schoolEmail,
        schoolPhoneNumber: data.schoolPhoneNumber,
        personalFullName: data.personalFullName,
        personalEmail: data.personalEmail,
        personalPhoneNumber: data.personalPhoneNumber,
        roleInSchool: data.roleInSchool,
        delegates: [...delegation],
      });

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      //   enqueueSnackbar(message, {
      //     variant: "success",
      //   });
      navigate(`/registration/page`, { replace: true });

      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  //===========

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading Event...</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      ></Box>
      <hr />
      <Grid container style={Gridimage} justifyContent="center">
        <Grid item mt={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Typography variant="h4">
                  {" "}
                  Registration for {newEvent?.name}
                </Typography>
                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      //   minHeight: 10,
                    }}
                  >
                    <Typography variant="body2" p={1}>
                      Complete School information
                    </Typography>
                  </Box>
                </Grid>
                <Box display={"flex"} justifyContent={"space-around"}>
                  <Grid width={280}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="schoolName"
                      label="School Name"
                      variant="filled"
                    />
                  </Grid>

                  <Grid width={280}>
                    <RHFTextField
                      name="schoolEmail"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>
                  <Grid width={280}>
                    <RHFTextField
                      name="schoolPhoneNumber"
                      label="School Phone"
                      variant="filled"
                    />
                  </Grid>
                </Box>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      //   minHeight: 10,
                    }}
                  >
                    <Typography variant="body2" p={1}>
                      Complete personnel information
                    </Typography>
                  </Box>
                </Grid>

                <Box display={"flex"} justifyContent={"space-around"} p={2}>
                  <Grid width={270}>
                    {/* <FormHelperText>Name</FormHelperText> */}
                    <RHFTextField
                      name="personalFullName"
                      label="Full Name"
                      variant="filled"
                    />
                  </Grid>

                  <Grid width={270}>
                    <RHFTextField
                      name="personalEmail"
                      label="Email"
                      variant="filled"
                    />
                  </Grid>
                  <Grid width={270}>
                    <RHFTextField
                      name="personalPhoneNumber"
                      label="Phone Number"
                      variant="filled"
                    />
                  </Grid>
                </Box>
                <Grid
                  sx={{
                    marginBlock: 2,
                    width: 370,
                  }}
                >
                  <Grid>
                    <RHFTextField
                      name="roleInSchool"
                      label="Designation"
                      variant="filled"
                    />
                  </Grid>
                </Grid>

                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      //   minHeight: 10,
                    }}
                  >
                    <Typography variant="body2" p={1}>
                      Delegates
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    // endIcon={<Iconify icon={"eva:checkmark-circle-2-fill"} />}
                    endIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                  <Typography> Click to add delegate</Typography>
                  <Box mt={3}>
                    {delegation.map((eachItem, index) => (
                      <Box>
                        <Box
                          key={index}
                          display={"flex"}
                          border={"1px solid #333"}
                          p={1}
                        >
                          <Typography>{`${index + 1}.`}</Typography>
                          <Typography pl={2}>{eachItem.name}</Typography>
                          <Typography pl={2}>{eachItem.phoneNumber}</Typography>
                          <Typography pl={2}>{eachItem.email}</Typography>
                          {/* <Typography pl={2}>
                            <Button color="error">Remove</Button>
                          </Typography> */}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                <Grid item sx={{ textAlign: "right", mt: 2 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{
                      width: 160,
                    }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <FormProvider
            methods={delegationMethods}
            onSubmit={delegationMethods.handleSubmit(addDelegation)}
          >
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Delegate
              </Typography>
              <Typography variant="body2" mt={2}>
                Input the full name as you want it written on the certificate.
              </Typography>
              <Box id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid width={300} m={2}>
                  <RHFTextField
                    name="name"
                    label="Full Name"
                    variant="filled"
                  />
                </Grid>

                <Grid width={300} m={2}>
                  <RHFTextField name="email" label="Email" variant="filled" />
                </Grid>
                <Grid width={300} m={2}>
                  <RHFTextField
                    name="phoneNumber"
                    label="Phone Number"
                    variant="filled"
                  />
                </Grid>

                <Grid sx={{ p: 3, textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    type="submit"
                    //   onClick={}
                    sx={{
                      color: "#fff",
                      width: 260,
                      background: "#018fe2",
                    }}
                    // startIcon={<Back />}
                  >
                    Add
                  </Button>
                </Grid>
              </Box>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </Box>
  );
}

export default EventSchoolform;
