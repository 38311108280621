import React from "react";

function AdminInsuranceImage(imageId, imageUrl) {
  return (
    <div>
      <img
        src={imageUrl}
        alt=""
        style={{
          width: 400,
        }}
      />
    </div>
  );
}

export default AdminInsuranceImage;
