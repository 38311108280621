import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Stack,
  Grid,
  CardActions,
  Button,
} from "@mui/material";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useEffect } from "react";
import { useState } from "react";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import Back from "@material-ui/icons/ChevronLeft";
import AdminDashboard1 from "./AdminDashboard1";
import AdminDashboard2 from "./AdminDashboard2";
import AdminDashboard3 from "./AdminDashboard3";
import AdminDashboard4 from "./AdminDashboard4";
import AdminDashboard5 from "./AdminDashboard5";
import AdminDashboardAlert from "./AdminDashboardAlert";
import AdminDashboardTop from "./AdminDashboardTop";
import AdminDashboardPie from "./AdminDashboardPie";
import AdminDashboardReport from "./AdminDashboardReport";
import AdminDashboardReportPie from "./AdminDashboardReportPie";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

function AdminDashboard() {
  const { stakeholderCategory } = useAuthAdmin();

  const [loading, setLoading] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState([]);

  const [schools, setSchools] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [schoolIntervention, setSchoolIntervention] = useState({
    numberOfSchoolInterventions: 0,
    numberOfPendingRequests: 0,
  });

  useEffect(() => {
    const getDashboardDetails = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          "/Admins/GetDashboardDetails"
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { newSchool, newAdmin, schoolIntervention } = response.data.data;

        setSchools([...newSchool]);
        setAdmins([...newAdmin]);
        setSchoolIntervention({
          numberOfSchoolInterventions:
            schoolIntervention.numberOfSchoolInterventions,
          numberOfPendingRequests: schoolIntervention.numberOfPendingRequests,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (stakeholderCategory !== "") getDashboardDetails();
  }, [stakeholderCategory]);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Dashboard...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid mt={10}>
        <Typography variant="h4" ml={2}>
          Admin Dashboard
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} mt={5}>
        <AdminDashboardTop
          numberOfSchoolInterventions={
            schoolIntervention.numberOfSchoolInterventions
          }
        />
      </Grid>
      <Grid item xs={12} md={8} mt={10}>
        <AdminDashboardReport />
      </Grid>
      <Grid item xs={12} md={4} mt={10}>
        <AdminDashboardReportPie />
      </Grid>

      <Grid item xs={12} md={8}>
        <AdminDashboard1 schools={schools} />
      </Grid>
      <Grid item xs={12} md={4}>
        <AdminDashboardPie />
      </Grid>

      <Grid item xs={12} md={12}>
        <Stack spacing={3}>
          {/* <AdminDashboard3 newAdmin={admins} /> */}

          <AdminDashboard4 />
        </Stack>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack spacing={3}>
          <AdminDashboardAlert />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;
