import React, { useState, useEffect, Fragment } from "react";

import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Box,
  Button,
} from "@mui/material";

import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";

import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";

function Checklist() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    userData: { schoolId },
  } = useAuth();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    let unmounted = false;

    const getAllQuestions = async () => {
      setLoadingQuestions(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolChecklist/GetChecklistSM201/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setQuestions([...data.questions]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoadingQuestions(false);
      }
    };

    if (schoolId !== null) getAllQuestions();

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const handleChange = (e, index) => {
    let customQuestions = [...questions];
    customQuestions[index].answer = e.target.value;
    setQuestions([...customQuestions]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoadingSubmit(true);
    try {
      const response = await axoisCustomAgent.post(
        `SchoolChecklist/SubmitChecklistSM201/${schoolId}`,
        [...questions]
      );

      if (response.status === 500) {
        throw new Error("Server Error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar(message, { variant: "Checklist submitted successfully" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingSubmit(false);
    }
  };

  if (loadingQuestions) {
    return (
      <Box
        minHeight={"100vh"}
        // display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4"> Checklist</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/checklist"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            CJ-SM201
          </Button>

          <Button
            href="/dashboard/user/outsourceService"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            CJ-SM202
          </Button>
          <Button
            href="/dashboard/user/riskAssessment"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            CJ-R001A
          </Button>
          {/* <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-SM203
          </Button>

          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R002
          </Button>
          <Button
            // variant="outlined"
            color="primary"
            href="/dashboard/user/checklist"
            className="header"
          >
            CJ-R003
          </Button> */}
        </Box>
      </Box>
      {/* <Grid justifyContent={"center"}>
        <Typography justifyContent={"center"} color={"#6495ed"} mt={2}>
          HEALTH AND SAFETY CHECKLIST FOR CLASSROOMS
        </Typography>
      </Grid> */}

      <Grid p={2}>
        <Box
          sx={{
            background: "#34a8e1",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            color: "#ffff",
            minHeight: 40,
          }}
        >
          <Typography variant="h6" textAlign={"center"} padding={2}>
            HEALTH AND SAFETY CHECKLIST FOR CLASSROOMS
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p:2
          }}
        >
          <Grid>
            <Box>
              {questions.length === 0 ? (
                <Typography>No questions</Typography>
              ) : (
                <form onSubmit={async (e) => await onSubmit(e)}>
                  <Grid container spacing={3} >
                    {questions.map((eachQuestion, index) => (
                      <Grid item xs={6} >
                        <Typography >{eachQuestion.question}</Typography>

                        <FormControl required sx={{ m: 2, width: 350 }}>
                          <Select
                            value={eachQuestion.answer}
                            onChange={(e) => handleChange(e, index)}
                          >
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Yes"}> Yes</MenuItem>
                            <MenuItem value={"Further Action Needed"}>
                              {" "}
                              Further Action Needed
                            </MenuItem>
                            <MenuItem value={"Not Applicable"}>
                              Not Applicable
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid padding={2} textAlign={"right"}>
                    <LoadingButton
                      loading={loadingSubmit}
                      label="next"
                      variant="outlined"
                      type="submit"
                      sx={{
                        width:150
                      }}
                    >
                      Sumbit
                    </LoadingButton>
                  </Grid>
                </form>
              )}
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  );
}

export default Checklist;
