import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Grid,
  Box,
  Typography,
  Card,
  Avatar,
  Button,
  Modal,
  Divider,
  MenuItem,
  IconButton,
} from "@mui/material";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
import ClerkCredential from "./ClerkCredential";
import { deepOrange, deepPurple } from "@mui/material/colors";
import GradingModalSelect from "./GradingModalSelect";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function TaskPending() {
  const [pendingTask, setPendingTask] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [getClerksDetails, setGetClerksDetails] = useState([]);
  const theme = useTheme();

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const UpdateClerkToTask = (clerkInfo, taskId) => {
    let tasks = [...pendingTask];
    let taskIndex = tasks.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      tasks[taskIndex].auditClerks.push(clerkInfo);
      setPendingTask(tasks);
    }
  };

  const RemoveClerkFromTask = (clerkInfo, taskId) => {
    let tasks = [...pendingTask];
    let taskIndex = tasks.findIndex((x) => x.id == taskId);
    if (taskIndex !== -1) {
      let newClerks = tasks[taskIndex].auditClerks.filter(
        (x) => x.id !== clerkInfo.id
      );
      tasks[taskIndex].auditClerks = newClerks;
      setPendingTask(tasks);
    }
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = pendingTask.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "School Name",
      selector: (row) => row.school.schoolName,
    },
    {
      name: "Audit Date",
      selector: (row) => row.dateOfAudit.split("T")[0],
    },
    // {
    //   name: "Date Created",
    //   selector: (row) => row.dateCreated.split("T")[0],
    // },
    {
      name: "VD Sent.",
      cell: (row) => (
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (row.isValidationDocumentSent && "success") ||
            (!row.isValidationDocumentSent && "error")
          }
        >
          {row.isValidationDocumentSent
            ? sentenceCase("Active")
            : sentenceCase("Inactive")}
        </Label>
      ),
      // cell: (row) => (
      //   <Label
      //     variant={theme.palette.mode === "secondary" ? "error" : "filled"}
      //   >
      //     {sentenceCase(row.isValidationDocumentSent ? "Sent" : "Not Sent")}
      //   </Label>
      // ),
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    // {
    //   name: "Clerk assigned",
    //   selector: (row) => row.auditClerk?.id,
    // },

    {
      name: "OSP",
      cell: (row) => (
        <MoreMenuButton
          clerkInfo={row.auditClerks}
          taskId={row.id}
          UpdateClerkToTask={UpdateClerkToTask}
          RemoveClerkFromTask={RemoveClerkFromTask}
        />
      ),
    },
  ];

  useEffect(() => {
    let unmounted = false;
    const getAllNewTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AandE/GetAuditTasks?status=${2}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setPendingTask([...data]);
        //
        if (!unmounted) {
          let modifiedArray = data.map((eachItem) => {
            const { id, facility, amount } = eachItem;
            return {
              amount,
              facility,
              id,
            };
          });
          setGetClerksDetails([...modifiedArray]);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllNewTask();
    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Typography variant="h4">Assessor Dashboard</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Task</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="AandEAssignSchool"
            className="header "
          >
            New Task
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/pendingTask"
            className="header active"
          >
            PendingTask
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/completeTask"
            className="header "
          >
            Completed task
          </Button>
        </Box>
      </Box>

      <Box>
        <Card
          sx={{
            minWidth: "80%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {pendingTask.length === 0 ? (
              <Typography>No records found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={pendingTask}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Box>
    </Fragment>
  );
}

export default TaskPending;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  height: "80vh",
  p: 4,
};

function MoreMenuButton({
  isActive,
  clerkInfo,
  taskId,
  UpdateClerkToTask,
  RemoveClerkFromTask,
}) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // console.log(taskId);

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const [page, setPage] = React.useState(false);
  const handlePageOpen = () => setPage(true);
  const handlePageClose = () => setPage(false);

  // credential model
  const [openCredential, setOpenCredential] = useState(false);

  const handlePageOpenCredential = () => {
    setOpenCredential(true);
    setPage(true);
  };

  const handlePageCloseCredential = () => {
    setOpenCredential();
    setPage(false);
  };

  // assign clerk
  const [openAssignClerk, setOpenAssignClerk] = useState(false);

  const handlePageOpenAssignClerk = () => {
    setOpenAssignClerk(true);
    setCurrentTaskId(taskId);
    setPage(true);
  };

  const handlePageCloseAssignClerk = () => {
    setOpenAssignClerk();
    setCurrentTaskId("");
    setPage(false);
  };

  // Tools modal

  const [openauditGrading, setOpenAuditGrading] = useState(false);

  const handlePageOpenAuditGrading = () => {
    setOpenAuditGrading(true);
    setCurrentTaskId(taskId);
    setPage(true);
  };

  const handlePageCloseAuditGrading = () => {
    setOpenAuditGrading();
    setCurrentTaskId("");
    setPage(false);
  };

  const [currentTaskId, setCurrentTaskId] = useState("");

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={handlePageOpenAuditGrading}>
          {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
          Tools
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          onClick={handlePageOpenCredential}
          disabled={clerkInfo === null || clerkInfo.length === 0}
        >
          {/* <Iconify icon={"eva:-2-outline"} sx={{ ...ICON }} /> */}
          Clerk Credentials
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handlePageOpenAssignClerk}>
          {/* <Iconify icon={"eva:-2-outline"} sx={{ ...ICON }} /> */}
          Assign Clerk
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
      {/* view clerk credential */}
      <Modal
        open={openCredential}
        onClose={handlePageCloseCredential}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          >
            Clerks Information
          </Typography>

          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            {clerkInfo.map((eachItem, index) => (
              <Card
                key={index}
                sx={{
                  width: "93%",
                  height: 220,
                  mt: 4,

                  alignContent: "center",
                }}
              >
                {/* {clerkInfo.map((eachItem, index) => ( */}
                <Box
                  key={index}
                  m={2}
                  display={"flex"}
                  justifyContent={"space-around"}
                >
                  <Box>
                    {/* <Avatar alt="Remy Sharp" src={proficeIcon} style={{ height: 120, width: "90%" }} /> */}
                    <Avatar
                      sx={{ bgcolor: deepOrange[500] }}
                      style={{ height: 150, width: 150 }}
                    >
                      N
                    </Avatar>
                  </Box>
                  <Box mt={2}>
                    <Box display={"flex"}>
                      <Typography>URN:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.urn}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> First Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.firstName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Middle Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.middleName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Last Name:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.lastName}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography> Phone Number:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.phoneNumber}
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <Typography>Address:</Typography>
                      <Typography color={"#0000FF"} fontSize={"15px"} ml={2}>
                        {eachItem.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            ))}
          </Grid>
        </Box>
      </Modal>
      {/* Assign clerk modal */}

      <Modal
        open={openAssignClerk}
        onClose={handlePageCloseAssignClerk}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          >
            Records of Audit Clerk
          </Typography>

          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            <ClerkCredential
              taskId={taskId}
              assignedClerks={clerkInfo}
              handlePageCloseAssignClerk={handlePageCloseAssignClerk}
              UpdateClerkToTask={UpdateClerkToTask}
              RemoveClerkFromTask={RemoveClerkFromTask}
            />
            {/* <RegisterSchoolModal
                handlePageCloseAssign={handlePageCloseAssign}
                updateAandEForTask={updateAandEForTask}
                taskId={currentTaskId}
                DeleteTask={DeleteTask}
              /> */}
          </Grid>
        </Box>
      </Modal>

      {/* clerks Tools modal */}

      <Modal
        open={openauditGrading}
        onClose={handlePageCloseAuditGrading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"#6495ed"}
          >
            Audit Grading Sheet
          </Typography>

          <Grid id="modal-modal-description" sx={{ mt: 2 }}>
            <GradingModalSelect
              taskId={taskId}
              handlePageCloseAuditGrading={handlePageCloseAuditGrading}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
