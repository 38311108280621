import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import Vest from "../../../images/High visibility vest.png";
import AboutKid from "../../../images/aboutWallpaper.png";
import { Fragment } from "react";
import BOSimage from "../../../images/safetyLogo.jpeg";
import MamaDoc from "../../../images/IMG_0501 1.png";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function AboutUsFramework() {
  return (
    <Container sx={{ mt: 10 }}>
      <Grid container justifyContent="center">
        <Typography variant="h4">
          SAFE SCHOOLS LAGOS FRAMEWORK V.01, 2022
        </Typography>

        <Box display={"flex"} justifyContent={"space-between"} mt={3}>
          <Box>
            <Card
              sx={{
                minWidth: 300,
                minHeight: 300,
                p: 2,
                backgroundColor: "#edf6fa",
                mr: 15,
              }}
            >
              <CardContent>
                <Box textAlign={"center"} mt={5}>
                  <Typography variant="h5">Advocacy</Typography>
                </Box>
                <Box>
                  <Typography variant="body2" textAlign={"center"}>
                    The parents hangouts.
                  </Typography>
                  <Typography variant="body2" textAlign={"center"}>
                    SSA( Safe Schools Alliance).
                  </Typography>
                  <Typography variant="body2" textAlign={"center"}>
                    Collaborators beyond the school boundaries.
                  </Typography>
                  <Typography variant="body2" textAlign={"center"} s>
                    The Mandatory CBC(Capacity Building Conference).
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Box>
            <img
              src={BOSimage}
              alt="sslag event"
              style={{
                height: 330,
                width: 360,
              }}
            />
          </Box>
        </Box>

        <Card
          sx={{
            width: "95%",
            minHeight: 200,
            backgroundColor: "#edf6fa",
            borderRadius: 0,
            mt: 2,
          }}
        >
          <CardContent>
            <Typography variant="h5" textAlign="center" gutterBottom>
              Knowledge Transfer
            </Typography>

            <Typography
              variant="body2"
              textAlign={"center"}
              sx={{ lineHeight: 2, p: 1 }}
            >
              INSET(In Service Educational Training), FAST (First Aid Specialist
              Training. Training moderation. Accreditation process for all
              vendors, collaborators and suppliers.
            </Typography>
          </CardContent>
        </Card>
        <Box>
          <img
            src={MamaDoc}
            alt="sslag event"
            style={{
              height: 450,
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Container>
  );
}
