import React, {useState} from 'react'
import {

  Card,
 
  ListItemIcon,
  ListItemText,
  ListItemButton,
  List,

  Box,
  Typography,

} from "@mui/material";
import Alert from "@material-ui/icons/NotificationsActiveOutlined";
import {Notifyme} from "../../_mock/data-mock";







function AdminDashboardAlert() {
      const [notifyme, setNotifyme] = useState(Notifyme);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Card>
      <Box padding={3} color={"white"} backgroundColor={"#FF0000"}>
        <Typography variant="h6">SOS Alerts</Typography>
      </Box>

      <Box sx={{ width: "100%", maxWidth: 1060 }}>
        <List component="nav" aria-label="main mailbox folders">
          {Notifyme.map((eachData, index) => (
            <ListItemButton
              key={index}
              selected
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemText>{eachData.index}</ListItemText>
              <ListItemIcon>{eachData.icon}</ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Card>
  );
}

export default AdminDashboardAlert;
