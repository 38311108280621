import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Box, Button, Container,Link, Typography, Paper, Grid } from "@mui/material";

// _mock_
import { _homePlans } from "../../../_mock";
import Page from "../../../components/Page";
import { Divider } from "@mui/material";
// import PDFViewer from "pdf-viewer-reactjs";
// components
import Image from "../../../components/Image";
import Iconify from "../../../components/Iconify";
// import InvoicePDF from "./InvoicePDF";
// import { Document } from "@react-pdf/renderer";
import { Document } from "react-pdf";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(11, 0),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function BosPdf() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";


  return (
    <Page title="Bos">
      <RootStyle>
        <Container>
          <Box sx={{ mb: 10, textAlign: "center" }}>
            <Typography>bos-pdfvvvvvvvvvvvvvvvvvvvvvvvvv</Typography>
            <div>
            {/* <PDFViewer
            document={{ url: "https://console.cloudinary.com/console/c-ea3a39e9d62da88d1b368f2943cfe1/media_library/asset/838ec9d14fff223802ecd00ba86f8e07/managef" }}
          /> */}
            </div>
          </Box>

          <Link
              color="#4169e1"
              underline="always"
              target="_blank"
              rel="noopener"
              href="https://console.cloudinary.com/console/c-ea3a39e9d62da88d1b368f2943cfe1/media_library/asset/838ec9d14fff223802ecd00ba86f8e07/managef"
              sx={{
                typography: "body2",
                display: "flex",
                alignItems: "center",
                ml: "3px",
              }}
            >
             
              {/* <Iconify icon={"eva:chevron-right-fill"} width={20} height={20} /> */}
            </Link>
        </Container>
        <Divider
          orientation="vertical"
          sx={{ my: 10, mx: "auto", width: 2, height: 40 }}
        />
      </RootStyle>
    </Page>
  );
}
