import {
  Box,
  Divider,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";

import {
  FormProvider,
  RHFRadioGroup,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useNavigate, useParams } from "react-router";

function Safeguarding({ nextStep, prevStep, schoolId, auditId }) {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const handleChangeCheckBox = (event) => {
    setCheckBoxChecked(event.target.value);
  };

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const defaultValues = {
    policySighted: "",
    staffTeamsIdentified: "",
    policyStatementObserved: "",
    areStudentsAware: "",
    isInformationSharedWithParents: "",
    areStaffAware: "",
    isCommunityAwareOfLockInformation: "",
    isStaffAwareOfChildAbuse: "",
    isStaffAwareOfConfidentialityClause: "",
    safetyPrecautionsInOnlineLearning: "",
    onlineSafetyprecautions: "",
  };

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetSafeGuardingStrategiesSectionFiveB?schoolId=${schoolId}&auditId=${auditId}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         setValue("policySighted", data.policySighted ?? "");
  //         setValue("staffTeamsIdentified", data.staffTeamsIdentified ?? "");
  //         setValue(
  //           "policyStatementObserved",
  //           data.policyStatementObserved ?? ""
  //         );
  //         setValue("areStudentsAware", data.areStudentsAware ?? "");
  //         setValue(
  //           "isInformationSharedWithParents",
  //           data.isInformationSharedWithParents ?? ""
  //         );
  //         setValue("areStaffAware", data.areStaffAware ?? "");
  //         setValue(
  //           "isCommunityAwareOfLockInformation",
  //           data.isCommunityAwareOfLockInformation ?? ""
  //         );
  //         setValue(
  //           "isStaffAwareOfChildAbuse",
  //           data.isStaffAwareOfChildAbuse ?? ""
  //         );
  //         setValue(
  //           "isStaffAwareOfConfidentialityClause",
  //           data.isStaffAwareOfConfidentialityClause ?? ""
  //         );
  //         setValue(
  //           "safetyPrecautionsInOnlineLearning",
  //           data.safetyPrecautionsInOnlineLearning ?? ""
  //         );
  //       }
  //       //setGeneralSection(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId]);

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });
  const sample = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionSixSafeguarding`,
        {
          schoolId,
          auditId,
          auditTaskId: taskId,
          policySighted: dataToSubmit.policySighted,
          onlineSafetyprecautions: dataToSubmit.onlineSafetyprecautions,
          staffTeamsIdentified: dataToSubmit.staffTeamsIdentified,
          policyStatementObserved: dataToSubmit.policyStatementObserved,
          areStudentsAware: dataToSubmit.areStudentsAware,
          isInformationSharedWithParents:
            dataToSubmit.isInformationSharedWithParents,
          areStaffAware: dataToSubmit.areStaffAware,
          isCommunityAwareOfLockInformation:
            dataToSubmit.isCommunityAwareOfLockInformation,
          isStaffAwareOfChildAbuse: dataToSubmit.isStaffAwareOfChildAbuse,
          isStaffAwareOfConfidentialityClause:
            dataToSubmit.isStaffAwareOfConfidentialityClause,
          safetyPrecautionsInOnlineLearning:
            dataToSubmit.safetyPrecautionsInOnlineLearning,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "60%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h4"
                  sx={{
                    background: "#00bbea",
                    // textAlign:"centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 6: Strategies adopted for safeguarding
                </Typography>
              </Box>

              <Typography fontSize={"15px"}>
                *Please indicate all that are seen
              </Typography>

              <ol>
                <Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography> Safeguarding policy sighted?</Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="policySighted"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>Are staff terms identified?</Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="staffTeamsIdentified"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Are staff code of conduct Observed?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="policyStatementObserved"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>Are students aware?</Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="areStudentsAware"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Is information about this shared with parents?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isInformationSharedWithParents"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Are most staff aware of safeguarding(and know what to do
                        to uphold it)?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="areStaffAware"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Is lockdown information known to the school community?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isCommunityAwareOfLockInformation"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Are staff aware of sign of child abuse and know what to
                        do if children are risk ?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isStaffAwareOfChildAbuse"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Are online safety precautions utilised in learning
                        platforms within the school / Safe Online internet
                        deployed?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="onlineSafetyprecautions"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Is the obligations for confidentiality shared with
                        members of staff ?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="isStaffAwareOfConfidentialityClause"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                  <Divider sx={{ borderStyle: "dashed" }} />
                  <Box sx={{ marginBlock: 2 }}>
                    <li>
                      <Typography>
                        Are online safety precautions are utilised in learning
                        platforms with the school ?
                      </Typography>
                    </li>

                    <RHFRadioGroup
                      options={sample.map((x) => x.value)}
                      name="safetyPrecautionsInOnlineLearning"
                      getOptionLabel={sample.map((x) => x.label)}
                    />
                  </Box>
                </Box>
              </ol>

              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    // onClick={() => handleDirectionChange("forward")}
                    loading={isSubmitting}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}
export default Safeguarding;
