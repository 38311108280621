import { Grid, Box, Typography, Link } from "@mui/material";
import { Container } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import logoBg from "../../images/sslagBookingForm.JPG";
import axiosCustomAgent from "../../utils/axiosCustomAgent";

function SchoolCJLetter() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const { enqueueSnackbar } = useSnackbar();
  const [linkStatus, setLinkStatus] = useState(null);
  const [dataStatus, setDataStatus] = useState(null);

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "CJ 001 letter",
            token,
          }
        );

        const { statusCode, message } = response.data;
        const { data } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }

        setLinkStatus(message);
        setDataStatus(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Box textAlign={"center"}>
          <Box>
            <Grid>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 400,
                }}
              />
            </Grid>
          </Box>
        </Box>

        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 800,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            
          </Box>
          <Grid item sx={{ textAlign: "right", }}>
            <Typography>{dataStatus?.sentOn.split("T")[0] }</Typography>
          </Grid>
          <Box paddingTop={5}>
            <Typography variant="h5">Dear {dataStatus?.schoolName}</Typography>
          </Box>

          <Box textAlign={"center"} mt={5} fontSize={"20px"}>
            
              <u>IMPORTANT NOTICE TO SCHOOL MANAGEMENT TEAMS</u>
         
          </Box>
          <Box>
            <Box paddingTop={3}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography>1.</Typography>
                <Typography ml={2}>
                  This is to bring to your notice that the Lagos State Safety
                  Commission has flagged off the Safe Schools Lagos (SSLAG)
                  compliance drive for all schools in Lagos State in accordance
                  with the State Government’s vision to ensure SAFETY of all our
                  children in schools.
                </Typography>
              </Box>
            </Box>
            <Box paddingTop={3}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography>2.</Typography>
                <Typography ml={2}>
                  As such, SSLAG Assessors & Evaluators have been assigned to
                  schools to conduct SMS–walkthroughs. This is to support the
                  compliance journey in schools and enable the development and
                  implementation of a Safety Management System (SMS).
                </Typography>
              </Box>
            </Box>
            <Box paddingTop={3}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography>3.</Typography>
                <Typography ml={2}>
                  The SSLAG A&Es MUST Follow the approved Protocol of the Safety
                  Commission as listed below:
                </Typography>
              </Box>
            </Box>
            <Box paddingTop={3}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography>4.</Typography>
                <Typography ml={2}>
                  The Safe Schools Lagos guidelines include the mandatory
                  implementation of an SMS, which includes the development of
                  relevant policies, procedures and maintaining a structured and
                  safe environment to promote effective teaching and learning.
                </Typography>
              </Box>
              <Box display={"flex"} paddingLeft={9} mt={2}>
                <Typography>i.</Typography>
                <Typography ml={2}>
                  show 2 copies of ‘the SSLAG Compliance Journey initiation
                  letter’ from LSC (Lagos State Safety Commission), signed by
                  the DG/CEO: <b>Mr Lanre Mojola</b> . The school must stamp an
                  <b>acknowledgement copy</b>
                  (the stamp on it must be the stamp of the school in question).
                </Typography>
              </Box>
              <Box display={"flex"} paddingLeft={9} mt={2}>
                <Typography>ii.</Typography>
                <Typography ml={2}>
                  SSLAG A&Es must show their SSLAG Implementation Team ID. If
                  unsure you may contact the Project Lead on
                  <div>
                    <u
                      style={{
                        color: "blue",
                        marginLeft: 1,
                      }}
                    >
                      PD@safeschoolslagos.ng
                    </u>
                  </div>
                  or The LSC school safety desk officer, at Block 18B 2nd & 3rd
                  Floors, Lagos State Secretariat, Alausa- Lagos.{" "}
                  <b>
                    The A&Es will give you more information about compliance
                    requirements and guide you on next steps.
                  </b>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box paddingTop={3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography>5.</Typography>
              <Typography ml={2}>
                The full co-operation of all stakeholders is required in order
                to create a safe school environment for all children in Lagos
                state.
              </Typography>
            </Box>
          </Box>
          <Box paddingTop={3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography>6.</Typography>
              <Typography ml={2}>
                Contact the office of the Director-General for further enquiries
                or the Lead resource, Dr Bisi Esuruoso on 0803 221 2221 Or via
                email
                <div>
                  <u
                    style={{
                      color: "blue",
                    }}
                  >
                    LR@safeschoolslagos.ng
                  </u>
                </div>
              </Typography>
            </Box>
          </Box>
          <Box paddingTop={3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography>7.</Typography>
              <Typography ml={2}>
                <i>
                  <b>Thank You</b>
                </i>
              </Typography>
            </Box>
          </Box>

          <Box paddingTop={3}>
            <Box>
              <Typography>
                {" "}
                <u>Signature Space</u>
              </Typography>
              <Typography variant="h6">Dr Bisi Esuruoso</Typography>
              <Typography variant="h6">SSLAG, Lead Resource</Typography>
            </Box>
          </Box>
          <Box textAlign={"center"}>
            <Box>
              <Typography variant="h6">NEXCHANGE OFFICE SUITE</Typography>
              <Typography>
                284B Ajose Adeogun Street, Victoria-Island, Lagos
                <Link href="  www.schoolrun.com.ng" underline="always">
                  www.schoolrun.com.ng
                </Link>{" "}
              </Typography>
              <a href="mailto:info@schoolrun.com.ng">info@schoolrun.com.ng</a>
              {/* <i>
                <u
                  style={{
                    color: "blue",
                  }}
                >
                  info@schoolrun.com.ng
                </u>
              </i> */}
            </Box>
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default SchoolCJLetter;
