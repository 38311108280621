import React, { Fragment, useEffect, useState } from "react";
import { sentenceCase } from "change-case";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Button,
  Box,
  Divider,
  MenuItem,
  IconButton,
  Card,
} from "@mui/material";
// import { Link } from "react-router-dom";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import Label from "../../components/Label";

import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import Iconify from "../../components/Iconify";
// import Label from "../../components/Label";
import Back from "@material-ui/icons/ChevronLeft";
import useAuth from "src/hooks/useAuth";

import styled from "styled-components";
import DataTable from "react-data-table-component";
import useAuthAdmin from "src/hooks/useAuthAdmin";

import AdminSendMessage from "./AdminSendMessage";

import AdminSchoolDetail from "./AdminSchoolDetail";
import AdminAllAlert from "./AdminAllAlert";
import { useNavigate, useParams } from "react-router";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="School Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function AdminAssessors() {
  const { stakeholderCategory, id } = useAuthAdmin();

  const theme = useTheme();
  let { schoolId } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [school, setAllSchool] = useState([]);
  const [loading, setLoading] = useState(false);

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

 
    const customStyles = {
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
          // background: "#34a8e1",
          fontSize: "19px",
          borderRadius: 2,
        },
      },
    };


  useEffect(() => {
    let unmounted = false;

    const getAllSchool = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Schools/GetAllSchools`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setAllSchool([...data]);
       
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "") getAllSchool();
    return () => {
      unmounted = true;
    };
  }, [id]);

  const Gridimage = {
    background: "#fafafa",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = school.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },

    {
      name: "School Name",
      selector: (row) => row.schoolName,
    },

    {
      name: "School Type",
      selector: (row) => row.schoolTypeName,
    },
    {
      name: "School Programme",
      selector: (row) => row.schoolProgram,
    },

    {
      name: "District",
      selector: (row) => row.schoolDistrict,
    },
    {
      name: "User progress",
      selector: (row) => row.onboardingStage,
    },

    {
      cell: (row) => (
        <>
          <Button
            className="view"
            href={`/admin/Audit/onSiteProtocol/${row.schoolId}`}
          >
            Audit
          </Button>
        </>
      ),
    },
    
  ];

  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading All Schools...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      <Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "100%",

            padding: 2,
          }}
        >
          <Typography variant="h4" p={3}>
           Audit
          </Typography>
        </Grid>

        {/* <Button
          startIcon={<Back />}
          // variant="contained"
          href="/"
          sx={{
            mt: 2,
          }}
        >
          Back
        </Button> */}
        <Card>
         
       
            <Box p={2} background={"#edf5f9"}>
              {school.length === 0 ? (
                <Typography>No School Present</Typography>
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  responsive
                  customStyles={customStyles}
                />
              )}
            </Box>
          
        </Card>
      </Grid>
    </Fragment>
  );
}

export default AdminAssessors;
