import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  Stack,
  Alert,
  FormHelperText,
} from "@mui/material";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import logoBg from "../../images/sslagBookingForm.JPG";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { GridActionsCellItem } from "@mui/x-data-grid";

function SchoolCJExtractionTable() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "data extraction table",
            token,
          }
        );

        const { statusCode, message } = response.data;

        if (statusCode === 500) {
          throw new Error(message); 
        }

        setLinkStatus(message);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const defaultValues = {
    numOfAnnexes: "",
    remarksOnNumOfAnnexes: "",
    annexesAddress: "",
    remarksOnAnnexesAddress: "",
    numOfSectionPerAnnex: "",
    remarksOnNumOfSectionPerAnnex: "",
    dateSchoolFounded: "",
    remarksOnDateSchoolFounded: "",
    totalStaffstrength: "",
    remarksOnTotalStaffstrength: "",
    nameOfPrincipal: "",
    remarksOnNameOfPrincipal: "",
    emailOfPrincipal: "",
    remarksOnEmailOfPrincipal: "",
    phoneNumberOfPrincipal: "",
    remarksOnPhoneNumberOfPrincipal: "",
    numOfPupilsOnRoll: "",
    remarksOnNumOfPupilsOnRoll: "",
    schoolComposition: "",
    remarksOnSchoolComposition: "",
    schoolStructure: "",
    remarksOnSchoolStructure: "",
    ageofYoungestPupil: "",
    remarksOnAgeofYoungestPupil: "",
  };

  const methods = useForm({
    //resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitDataExtractionInterventionForm`,
        {
          schoolId,
          token,
          numOfAnnexes: data.numOfAnnexes,
          remarksOnNumOfAnnexes: data.remarksOnNumOfAnnexes,
          annexesAddress: data.annexesAddress,
          remarksOnAnnexesAddress: data.remarksOnAnnexesAddress,
          numOfSectionPerAnnex: data.numOfSectionPerAnnex,
          remarksOnNumOfSectionPerAnnex: data.remarksOnNumOfSectionPerAnnex,
          dateSchoolFounded: data.dateSchoolFounded,
          remarksOnDateSchoolFounded: data.remarksOnDateSchoolFounded,
          totalStaffstrength: data.totalStaffstrength,
          remarksOnTotalStaffstrength: data.remarksOnTotalStaffstrength,
          nameOfPrincipal: data.nameOfPrincipal,
          remarksOnNameOfPrincipal: data.remarksOnNameOfPrincipal,
          emailOfPrincipal: data.emailOfPrincipal,
          remarksOnEmailOfPrincipal: data.remarksOnEmailOfPrincipal,
          phoneNumberOfPrincipal: data.phoneNumberOfPrincipal,
          remarksOnPhoneNumberOfPrincipal: data.remarksOnPhoneNumberOfPrincipal,
          numOfPupilsOnRoll: data.numOfPupilsOnRoll,
          remarksOnNumOfPupilsOnRoll: data.remarksOnNumOfPupilsOnRoll,
          schoolComposition: data.schoolComposition,
          remarksOnSchoolComposition: data.remarksOnSchoolComposition,
          schoolStructure: data.schoolStructure,
          remarksOnSchoolStructure: data.remarksOnSchoolStructure,
          ageofYoungestPupil: data.ageofYoungestPupil,
          remarksOnAgeofYoungestPupil: data.remarksOnAgeofYoungestPupil,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid>
            <Box textAlign={"center"}>
              <Box>
                <Grid>
                  <img
                    src={logoBg}
                    alt=""
                    style={{
                      width: 400,
                    }}
                  />
                </Grid>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "#018fe2",
                  mt: 2,
                }}
              >
                Data Extraction Table
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid>
          <Grid p={2}>
            <Box
              sx={{
                background: "#018fe2",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                Extraction Table
              </Typography>
            </Box>
          </Grid>
          <Container>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                <Typography>No. of annexes</Typography>
                </Grid>
                <Grid>
                  <RHFTextField
                    name="numOfAnnexes"
                    type="number"
                    label="Response"
                    variant="filled"
                    placeholder="Response ....."
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnNumOfAnnexes"
                  label="Remark"
                  variant="filled"
                  placeholder="Remark ....."
                />
              </Grid>
            </Box>
            {/* <Box display={"flex"} justifyContent={"space-around"}>
              <Box minWidth={"37"}>
                <Grid>
                  <Typography>No. of annexes</Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="numOfAnnexes"
                    type="number"
                    label="Response"
                    variant="filled"
                    placeholder="Response ....."
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnNumOfAnnexes"
                  label="Remark"
                  variant="filled"
                  placeholder="Remark ....."
                />
              </Grid>
            </Box> */}
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography>Addresses of annexes</Typography>
                </Grid>
                <Grid>
                  <RHFTextField
                    name="annexesAddress"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnAnnexesAddress"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography>No. of section per annex</Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="numOfSectionPerAnnex"
                    label="Response "
                    type="number"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnNumOfSectionPerAnnex"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography>Date school was founded</Typography>
                </Grid>

                <Grid>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      disablePast
                      value={methods.watch().dateSchoolFounded}
                      onChange={(value) => {
                        methods.setValue("dateSchoolFounded", value, {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <RHFTextField
                          variant="filled"
                          {...params}
                          name="dateSchoolFounded"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnDateSchoolFounded"
                  label="Remark"
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"35%"}>
                <Grid>
                  <Typography>Total Staff strength</Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="totalStaffstrength"
                    label="Response"
                    type="number"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnTotalStaffstrength"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"35%"}>
                <Grid>
                  <Typography>Name of Principal</Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="nameOfPrincipal"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnNameOfPrincipal"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography>Telephone No. of Principal</Typography>
                </Grid>

                <Grid minWidth={"35%"}>
                  <RHFTextField
                    name="phoneNumberOfPrincipal"
                    type="number"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnPhoneNumberOfPrincipal"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography> Email address of Principal </Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="emailOfPrincipal"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnEmailOfPrincipal"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"35%"}>
              <Grid>
                {/* <FormHelperText>Name</FormHelperText> */}

                <Typography> No. of pupils on roll</Typography>
              </Grid>

              <Grid >
                <RHFTextField
                  name="numOfPupilsOnRoll"
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
              </Box>
           
              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnNumOfPupilsOnRoll"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"35%"}>
                <Grid>
                  <Typography fontSize={"14px"}>
                    School composition: Pry, Secondary or both?
                  </Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="schoolComposition"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnSchoolComposition"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"35%"}>
                <Grid>
                  <Typography fontSize={"14px"}>
                    {" "}
                    School structure: Day, Boarding or both?
                  </Typography>
                </Grid>

                <Grid>
                  <RHFTextField
                    name="schoolStructure"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnSchoolStructure"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box display={"flex"} justifyContent={"space-around"} mt={2}>
              <Box minWidth={"36%"}>
                <Grid>
                  <Typography fontSize={"14px"}>
                    Age of the youngest pupil in the lowest grade
                  </Typography>
                </Grid>

                <Grid minWidth={"35%"}>
                  <RHFTextField
                    name="ageofYoungestPupil"
                    type="number"
                    label="Response"
                    placeholder="Response ....."
                    variant="filled"
                  />
                </Grid>
              </Box>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  multiline={true}
                  minRows={3}
                  name="remarksOnAgeofYoungestPupil"
                  label="Remark"
                  placeholder="Remark ....."
                  variant="filled"
                />
              </Grid>
            </Box>
            <Grid item sx={{ textAlign: "right", p: 2 }}>
              <LoadingButton
                type="submit"
                color="secondary"
                variant="contained"
                loading={isSubmitting}
              >
                Sumbit
              </LoadingButton>
            </Grid>
          </Container>
        </Grid>
      </FormProvider>
    </Fragment>
  );
}

export default SchoolCJExtractionTable;
