import React, { Fragment, useState, useEffect } from "react";
import { Grid, Box, Typography, Container, Stack, Alert } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { LoadingButton } from "@mui/lab";

function AdminJourneyInsetBooking() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [insetBooking, setInsetBooking] = useState(null);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `GetInsetBookingInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setInsetBooking(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (insetBooking === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (insetBooking === null) {
    return <Typography>No Record Found....</Typography>;
  }

  return (
    <Fragment>
      <Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
          }}
        >
          <Grid>
            <Box textAlign={"center"}>
              <Box>
                <Grid>
                  <img
                    src={logoBg}
                    alt=""
                    style={{
                      width: 400,
                    }}
                  />
                </Grid>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h5"
                sx={{
                  color: "#731f1c",
                  mt: 2,
                }}
              >
                Compliance Journey Station 5 Safety INSET Booking Form
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 0.5,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 800,
          }}
        >
          <Box>
            <Typography>Date:</Typography>
          </Box>
          <Grid>
            <Box
              sx={{
                background: "#731f1c",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                SCHOOL DETAILS
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  // name="name"
                  value={insetBooking.name}
                  label="Name"
                  variant="filled"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  value={insetBooking.name}
                  name="address"
                  label="Address"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  value={insetBooking.educationalDistrict}
                  name="educationalDistrict"
                  label="Educational district"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  value={insetBooking.phoneNumber}
                  name="phoneNumber"
                  label="Phone Number"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  name="email"
                  value={insetBooking.email}
                  label="Email"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
          </Box>
          <Grid mt={1}>
            <Box
              sx={{
                background: "#731f1c",
                borderRadius: 0.5,
                boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                color: "#ffff",
                minHeight: 40,
              }}
            >
              <Typography variant="h6" padding={2}>
                PAYMENT DETAILS
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  // name="invoiceName"
                  value={insetBooking.invoiceName}
                  label="Name invoice should be addressed to"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  // name="courseTitle"
                  value={insetBooking.courseTitle}

                  label="Course Title"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
            <Box>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  // name="totalNoOfStaff"
                  value={insetBooking.totalNoOfStaff}
                  label="Total No of Staff"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
              <Grid mt={1}>
                {/* <FormHelperText>Name</FormHelperText> */}
                <RHFTextField
                  id="filled-basic"
                  // name="numberOfStudent"
                  value={insetBooking.numberOfStudent}
                  label="Total No of students/pupils on roll"
                  sx={{
                    marginBlock: 2,
                    width: 500,
                  }}
                  variant="filled"
                />
              </Grid>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminJourneyInsetBooking;
