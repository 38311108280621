import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  CardContent,
  CardActions,
  Card,
  Button,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Grid,
  Modal,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
// _mock_
import { _homePlans } from "../../../_mock";
// components
import Iconify from "../../../components/Iconify";
import EventBannerOne from "../../../images/sslag-event4.jpg";
import logo from "../../../images/Group 129.png";
import { MotionInView, varFade } from "../../../components/animate";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import Location from "@material-ui/icons/LocationOn";
import Date from "@material-ui/icons/CalendarToday";
import Time from "@material-ui/icons/AccessAlarm";
import Eye from "@material-ui/icons/RemoveRedEyeOutlined";
import { Divider } from "@mui/material";
// components
import Page from "../../../components/Page";
// components

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function AdvocacyEvent() {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [descriptionModal, setDescriptionModal] = React.useState(false);
  const [registrationmodal, setRegistrationModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [isSchool, setIsSchool] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [event, setEvent] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [currentEventId, setCurrentEventId] = useState("");
  const [learnMoreEvent, setLearnMoreEvent] = useState(null);

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );

  useEffect(() => {
    let unmounted = false;

    const getEvent = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `Events/GetEventsByTypeId?typeId=${2}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        setEvent(data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getEvent();

    return () => {
      unmounted = true;
    };
  }, []);

  const handleOnAttend = (e, eventId) => {
    setCurrentEventId(eventId);
    handleOpen();
  };

  const chooseNavigation = () => {
    if (isSchool === null) {
    } else if (isSchool) {
      navigateToIndividual();
    } else if (!isSchool) {
      navigateToSchool();
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const option = [
    { label: "School", value: true },
    { label: "Individual", value: false },
  ];

  const defaultValues = {
    // new========================
    reason: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickOpenRegistration = () => {
    setRegistrationModal(true);
  };

  const handleCloseRegistration = () => {
    setRegistrationModal(false);
  };
  const handleClickOpenDescription = (event) => {
    setDescriptionModal(true);
    setLearnMoreEvent(event);
    // console.log("xxxxxxxxxxxxxxxxxxxxxx");
  };

  const handleCloseDescription = () => {
    setDescriptionModal(false);
    setLearnMoreEvent(null);
  };
  const navigateToSchool = () => {
    // navigate to public url
    navigate(`/events-school/${currentEventId}`);
  };

  const navigateToIndividual = () => {
    // navigate to Private url
    navigate(`/events-individual/${currentEventId}`);
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Advocacy...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Page title="BOS">
      <RootStyle>
        <Container sx={{ mt: 10 }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12}>
              {event.length === 0 ? (
                <Typography textAlign={"center"}>No records found</Typography>
              ) : (
                <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                  <Grid container spacing={2}>
                    {event.map((eachItem, index) => (
                      <Grid item xs={4}>
                        <Card key={index} sx={{ maxWidth: 400, }}>
                          <CardActions>
                            <img
                              src={eachItem.image}
                              alt="sslag event"
                              style={{
                                height: 360,
                                width: 360,
                              }}
                            />
                          </CardActions>
                          <CardContent >
                            <Typography
                              textAlign={"left"}
                              fontSize={"13px"}
                              pl={1}
                            >
                              {eachItem.name}
                            </Typography>
                            <Box display={"flex"}>
                              <Typography>
                                <Location color="action" />
                              </Typography>
                              <Typography fontSize={"13px"} textAlign={"left"}>
                                {eachItem.venue}
                              </Typography>
                            </Box>

                            <Grid
                              display={"flex"}
                              textAlign={"left"}
                              justifyContent={"space-between"}
                              pl={1}
                            >
                              <Typography>
                                {" "}
                                <Typography>
                                  <Date color="primary" />
                                </Typography>{" "}
                                {eachItem.date.split("T")[0]}
                              </Typography>
                              <Typography>
                                <Typography>
                                  <Time color="secondary" />
                                </Typography>{" "}
                                {eachItem.time}
                              </Typography>
                            </Grid>
                            <Grid
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <CardActions>
                                <Button
                                  onClick={() =>
                                    handleClickOpenDescription(eachItem)
                                  }
                                  size="small"
                                >
                                  Learn More
                                </Button>
                              </CardActions>
                              <Button
                                variant="contained"
                                onClick={(e) => handleOnAttend(e, eachItem.id)}
                                endIcon={
                                  <Eye />
                                  // <Iconify icon={"eva:checkmark-circle-2-fill"} />
                                }
                              >
                                Register
                              </Button>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you registering as an individual or school
                  </Typography>
                  <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    <Box>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="personnel"
                            control={<Radio />}
                            label="School"
                            onChange={() => setIsSchool(false)}
                          />
                          <FormControlLabel
                            value="govt"
                            control={<Radio />}
                            label="Individual"
                            onChange={() => setIsSchool(true)}
                          />
                        </RadioGroup>
                      </FormControl>

                      <Grid item sx={{ textAlign: "right" }}>
                        <LoadingButton
                          type="submit"
                          color="secondary"
                          onClick={chooseNavigation}
                          variant="contained"
                          sx={{
                            width: 160,
                          }}
                        >
                          Continue
                        </LoadingButton>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              <Dialog open={descriptionModal} onClose={handleCloseDescription}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={2}
                    >
                      <Box>
                        <img
                          src={learnMoreEvent?.image}
                          alt="sslag event"
                          style={{
                            height: 360,
                            width: 400,
                            p: 3,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box pl={2}>
                      <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                          <Typography variant="body2">
                            Topic: {learnMoreEvent?.name}
                          </Typography>
                          <Typography variant="body1">
                            {learnMoreEvent?.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Container>

        <Divider
          orientation="vertical"
          sx={{ my: 10, mx: "auto", width: 2, height: 40 }}
        />
      </RootStyle>
    </Page>
  );
}
