import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function Playground({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // ==========================
    playgroundEquipment: "",
    ageRangeEquipment: "",
    safetySignOnDisplay: "",
    staffRoster: "",
    freeOfDebris: "",
    studentSpace: "",
    playGroup: "",
    behaviourManagement: "",
    rangeOfPlayground: "",
    evidenceOfStudentHappening: "",
    activeDuty: "",
    outdooorRiskAssessment: "",
    groupObserved: "",
    playObserved: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetObservationsStageOne?schoolId=${items[0]}&auditId=${items[1]}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         const currentSectionData = data.find(
  //           (x) => x.section === section && x.item === item
  //         );
  //         console.log(currentSectionData);
  //         console.log(section);
  //         console.log(item);
  //         if (currentSectionData !== undefined) {
  //           setValue("section", currentSectionData.section ?? "");
  //           setValue("item", currentSectionData.item ?? "");
  //           setValue("focus", currentSectionData.focus ?? "");
  //           setValue("loContext", currentSectionData.loContext ?? "");
  //           setValue(
  //             "yearGroupObserved",
  //             currentSectionData.yearGroupObserved ?? ""
  //           );
  //           setValue(
  //             "subjectObserved",
  //             currentSectionData.subjectObserved ?? ""
  //           );
  //           setValue("genderMix", currentSectionData.genderMix ?? "");
  //           setValue("schoolType", currentSectionData.schoolType ?? "");
  //           setValue(
  //             "otherUsefulObservation",
  //             currentSectionData.otherUsefulObservation ?? ""
  //           );

  //           setValue(
  //             "groupInformationPropsUsed",
  //             currentSectionData.groupInformationPropsUsed ?? ""
  //           );
  //           setValue(
  //             "isThereFireActionNotice",
  //             currentSectionData.isThereFireActionNotice ?? ""
  //           );
  //           setValue(
  //             "areSafeGuidesOnDisplay",
  //             currentSectionData.areSafeGuidesOnDisplay ?? ""
  //           );
  //           setValue(
  //             "teacherPupilsRapport",
  //             currentSectionData.teacherPupilsRapport ?? ""
  //           );
  //           setValue(
  //             "isRoomFreeOfHazard",
  //             currentSectionData.isRoomFreeOfHazard ?? ""
  //           );
  //           setValue(
  //             "isThereAccessToWater",
  //             currentSectionData.isThereAccessToWater ?? ""
  //           );
  //           setValue(
  //             "isClassTooLarge",
  //             currentSectionData.isClassTooLarge ?? ""
  //           );
  //           setValue("groupObserved", currentSectionData.classControl ?? "");
  //           setValue(
  //             "appropriateTeacherPupilRatio",
  //             currentSectionData.appropriateTeacherPupilRatio ?? ""
  //           );

  //           setValue(
  //             "evidenceOfSafeLearning",
  //             currentSectionData.evidenceOfSafeLearning ?? ""
  //           );
  //           setValue(
  //             "evidenceOfSafetyAwarenessForStaff",
  //             currentSectionData.evidenceOfSafetyAwarenessForStaff ?? ""
  //           );
  //           setValue(
  //             "evidenceOfRiskAssessmentInLessons",
  //             currentSectionData.evidenceOfRiskAssessmentInLessons ?? ""
  //           );
  //         }
  //       }
  //       //setGeneralSection(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId, section, item]);

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {};

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SumbitSectionOnePlayground",
        {
          auditTaskId: taskId,
          playgroundEquipment: data.playgroundEquipment,
          ageRangeEquipment: data.ageRangeEquipment,
          safetySignOnDisplay: data.safetySignOnDisplay,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          staffRoster: data.staffRoster,
          freeOfDebris: data.freeOfDebris,
          studentSpace: data.studentSpace,
          playGroup: data.playGroup,
          behaviourManagement: data.behaviourManagement,
          rangeOfPlayground: data.rangeOfPlayground,
          evidenceOfStudentHappening: data.evidenceOfStudentHappening,
          activeDuty: data.activeDuty,
          outdooorRiskAssessment: data.outdooorRiskAssessment,
          groupObserved: data.groupObserved,
          playObserved: data.playObserved,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1B: Playground
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <Typography>Large playground equipment present?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="playgroundEquipment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Equipment appropriate for play or sporting for the age
                      ranges of pupils?
                    </Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="ageRangeEquipment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography> Safety signs on Display?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="safetySignOnDisplay"
                    />
                  </Box>

                  <Box>
                    <Typography>
                      Staff on supervision duties/ duties roster seen?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="staffRoster"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Playground free of debris/hazards or obstructions?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="freeOfDebris"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box>
                    <Typography>
                      Space appropriate to students population?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="studentSpace"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>Play groups too large?</Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="playGroup"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <Typography>
                      Evidence of effective behaviour management seen?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="behaviourManagement"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Appropriate ground coverings for the range of activities
                      on the playground?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="rangeOfPlayground"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      General evidence of pupils/students safe play happening?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfStudentHappening"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>Evidence of active duties going on</Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="activeDuty"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Evidence of risk assessment for outdoor play?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="outdooorRiskAssessment"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="groupObserved"
                      label="Year Group Observed "
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="playObserved"
                      label="Types of play observed"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="comment"
                      label="Comments?"
                      variant="filled"
                      multiline
                      minRows={3}
                    />
                  </Box>
                  <Grid sx={{ textAlign: "right" }}>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      loading={isSubmitting}
                      // onClick={Navigate}
                      sx={{ width: 100, color: "#fff", background: "#018fe2" }}
                      // startIcon={<Back />}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default Playground;
