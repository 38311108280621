import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Container,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

const Gridimage = {
  fontSize: "14px",
};

function Laboratory({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // ==========================
    laboratoryAvailable: "",
    laboratoryPosition: "",
    availableLaboratory: "",
    laboratoryEquipment: "",
    safetySignOnDisplay: "",
    fireEquipment: "",
    firstAidProvision: "",
    waterAvailable: "",
    roomDimension: "",
    safetyRule: "",
    equipmentInformation: "",
    evidenceOfSafePractices: "",
    pointControl: "",
    availablePPE: "",
    subjectObserved: "",
    groupObserved: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  // useEffect(() => {
  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetObservationsStageOne?schoolId=${items[0]}&auditId=${items[1]}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         const currentSectionData = data.find(
  //           (x) => x.section === section && x.item === item
  //         );
  //         console.log(currentSectionData);
  //         console.log(section);
  //         console.log(item);
  //         if (currentSectionData !== undefined) {
  //           setValue("section", currentSectionData.section ?? "");
  //           setValue("item", currentSectionData.item ?? "");
  //           setValue("focus", currentSectionData.focus ?? "");
  //           setValue("loContext", currentSectionData.loContext ?? "");
  //           setValue(
  //             "yearGroupObserved",
  //             currentSectionData.yearGroupObserved ?? ""
  //           );
  //           setValue(
  //             "subjectObserved",
  //             currentSectionData.subjectObserved ?? ""
  //           );
  //           setValue("genderMix", currentSectionData.genderMix ?? "");
  //           setValue("schoolType", currentSectionData.schoolType ?? "");
  //           setValue(
  //             "otherUsefulObservation",
  //             currentSectionData.otherUsefulObservation ?? ""
  //           );

  //           setValue(
  //             "groupInformationPropsUsed",
  //             currentSectionData.groupInformationPropsUsed ?? ""
  //           );
  //           setValue(
  //             "isThereFireActionNotice",
  //             currentSectionData.isThereFireActionNotice ?? ""
  //           );
  //           setValue(
  //             "areSafeGuidesOnDisplay",
  //             currentSectionData.areSafeGuidesOnDisplay ?? ""
  //           );
  //           setValue(
  //             "fireEquipment",
  //             currentSectionData.teacherPupilsRapport ?? ""
  //           );
  //           setValue(
  //             "isRoomFreeOfHazard",
  //             currentSectionData.isRoomFreeOfHazard ?? ""
  //           );
  //           setValue(
  //             "isThereAccessToWater",
  //             currentSectionData.isThereAccessToWater ?? ""
  //           );
  //           setValue(
  //             "isClassTooLarge",
  //             currentSectionData.isClassTooLarge ?? ""
  //           );
  //           setValue("classControl", currentSectionData.classControl ?? "");
  //           setValue(
  //             "appropriateTeacherPupilRatio",
  //             currentSectionData.appropriateTeacherPupilRatio ?? ""
  //           );

  //           setValue(
  //             "evidenceOfSafeLearning",
  //             currentSectionData.evidenceOfSafeLearning ?? ""
  //           );
  //           setValue(
  //             "evidenceOfSafetyAwarenessForStaff",
  //             currentSectionData.evidenceOfSafetyAwarenessForStaff ?? ""
  //           );
  //           setValue(
  //             "evidenceOfRiskAssessmentInLessons",
  //             currentSectionData.evidenceOfRiskAssessmentInLessons ?? ""
  //           );
  //         }
  //       }
  //       //setGeneralSection(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId, section, item]);

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SubmitObservationsStageOne",

        {
          auditTaskId: taskId,
          laboratoryAvailable: data.laboratoryAvailable,
          laboratoryPosition: data.laboratoryPosition,
          availableLaboratory: data.availableLaboratory,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          laboratoryEquipment: data.laboratoryEquipment,
          safetySignOnDisplay: data.safetySignOnDisplay,
          fireEquipment: data.fireEquipment,
          firstAidProvision: data.firstAidProvision,
          waterAvailable: data.waterAvailable,
          roomDimension: data.roomDimension,
          safetyRule: data.safetyRule,
          equipmentInformation: data.equipmentInformation,
          evidenceOfSafePractices: data.evidenceOfSafePractices,
          pointControl: data.pointControl,
          availablePPE: data.availablePPE,
          subjectObserved: data.subjectObserved,
          groupObserved: data.groupObserved,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h4"
                  sx={{
                    background: "#00bbea",
                    textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  Section 1D: Laboratory
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ marginBlock: 1, width: 400, p: 2, fontSize: "14px" }}
                >
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="laboratoryAvailable"
                      label="3 separate Laboratories available for the 3 sciences ?"
                      variant="filled"
                      multiline
                      minRows={2}
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="laboratoryPosition"
                      label="State the exact provision:?"
                      variant="filled"
                      multiline
                      minRows={2}
                    />
                  </Box>

                  <Box>
                    <Typography>Relevant laboratory equipment seen?</Typography>
                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="laboratoryEquipment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography> Safety signs on Display?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      getOptionLabel={option.map((x) => x.label)}
                      name="safetySignOnDisplay"
                    />
                  </Box>

                  <Box>
                    <Typography>
                      Appropriate Fire safety equipment seen?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="fireEquipment"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Appropriate first aid provision seen?
                    </Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="firstAidProvision"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>

                  <Box>
                    <Typography>Access to water available?</Typography>

                    <RHFRadioGroup
                      options={option.map((x) => x.value)}
                      name="waterAvailable"
                      getOptionLabel={option.map((x) => x.label)}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Room dimension appropriate to occupy at least 30 students?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="roomDimension"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Grid sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <Typography>
                      Evidence of safety rules in the lab seen?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="safetyRule"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Relevant Safety information about the use of chemicals and
                      the equipment seen ?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="equipmentInformation"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      General evidence of safe practicals observed?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfSafePractices"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Evidence of relevant one point controls for Gas, water and
                      electricity by the science teacher?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="pointControl"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Appropriate PPE available in the lab?
                    </Typography>

                    <FormControl>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="availablePPE"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="groupObserved"
                      label="Year Group Observed "
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="subjectObserved"
                      label="Subject Observed"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <RHFTextField
                      name="comment"
                      label="Comments?"
                      variant="filled"
                      multiline
                      minRows={3}
                    />
                  </Box>
                  <Grid sx={{ textAlign: "right" }}>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      // onClick={Navigate}
                      loading={isSubmitting}
                      sx={{ width: 100, color: "#fff", background: "#018fe2" }}
                      // startIcon={<Back />}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default Laboratory;
