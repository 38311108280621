import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Button,
  Container,
  Typography,
  Modal,
} from "@mui/material/";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminInterve from "./AdminInterve";
import { axoisCustomAgentForAdmin } from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

// import { Box } from "@mui/system";

function AdminSchoolDetail() {
  const [page, setPage] = React.useState(false);

  const [isAlertModalOpen, setIsAlertModalOpen] = React.useState(false);
  const [activeAlertMessageOpenInModal, setActiveAlertMessageOpenInModal] =
    React.useState(null);

  const openAlertModal = (alertMessage) => {
    setActiveAlertMessageOpenInModal(alertMessage);
    setIsAlertModalOpen(true);
  };

  const closeAlertModal = () => {
    setActiveAlertMessageOpenInModal(null);
    setIsAlertModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    minHeight: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // width:"700px"
  };

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { schoolId } = useParams();
  const [schoolDetail, setAllSchoolDetails] = useState(null);

  useEffect(() => {
    const getAllSchoolDetail = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `SchoolOnboarding/GetSchoolDetails/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        // const { data } = response.data;
        setAllSchoolDetails(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    getAllSchoolDetail();
  }, []);

  if (schoolDetail === null && loading) {
    return <Typography>Loading School Details....</Typography>;
  }

  if (schoolDetail === null) {
    return <Typography>No Record Found....</Typography>;
  }

  return (
    <Box mt={7}>
      <Button href="/admin/OnboardingProcess/newFormSchool">
        {/* <Iconify icon={"eva:trash-2-outline"} sx={{ ...ICON }} /> */}
        Back
      </Button>

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
      >
        <Grid
          sx={{
            alignSelf: "center",
          }}
        >
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
            }}
          >
            <Grid p={2}>
              <Typography> School Details</Typography>
            </Grid>
            <Box display={"flex"}>
              <Grid>
                <FormHelperText>School name:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="SchoolName"
                  variant="outlined"
                  value={schoolDetail.schoolName}
                  disabled
                />
                <FormHelperText>School address:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="Address"
                  variant="outlined"
                  value={schoolDetail.address}
                  required
                  disabled
                />
                <FormHelperText>School email:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="SchoolEmail"
                  variant="outlined"
                  value={schoolDetail.schoolEmail}
                  required
                  disabled
                />
                <FormHelperText>Type of school:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="TypeOfSchool"
                  variant="outlined"
                  value={schoolDetail.schoolType}
                  required
                  disabled
                />
                <FormHelperText>Start of school day:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="startOfDay"
                  variant="outlined"
                  value={schoolDetail.startOfDay.split("T")[0]}
                  required
                  disabled
                />
                <FormHelperText>End of school day:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="endOfDay"
                  variant="outlined"
                  value={schoolDetail.endOfDay.split("T")[0]}
                  disabled
                />
              </Grid>

              <Grid ml={10}>
                <FormHelperText>School programme:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="SchoolProgramId"
                  value={schoolDetail.schoolProgram}
                  variant="outlined"
                  disabled
                />
                <FormHelperText>School Gender Type:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="SchoolGenderType"
                  value={schoolDetail.gender}
                  variant="outlined"
                  disabled
                />

                <FormHelperText>No of female:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="NoOfFemale"
                  variant="outlined"
                  value={schoolDetail.numberOfFemaleStudents}
                  disabled
                />
                <FormHelperText>No of male:</FormHelperText>
                <TextField
                  sx={{ marginBlock: 1, width: 250 }}
                  id="NoOfMale"
                  variant="outlined"
                  value={schoolDetail.numberOfMaleStudents}
                  disabled
                />
                <Grid item sx={{ textAlign: "right", mt: 5 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => openAlertModal()}
                  >
                    Intervene
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>

        <Modal
          open={isAlertModalOpen}
          onClose={closeAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: 1000 }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Intervene
            </Typography>
            <Grid id="modal-modal-description" sx={{ mt: 2 }}>
              <AdminInterve
                alertMessage={activeAlertMessageOpenInModal}
                closeAlertModal={closeAlertModal}
                schoolId={schoolId}
              />
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Box>
  );
}

export default AdminSchoolDetail;
