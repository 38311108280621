import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Modal,
  Stack,
  Alert,
  Checkbox,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

function SchoolCjresourcesModel({ addData }) {
  const { enqueueSnackbar } = useSnackbar();
  const CHECK = [
    {
      value: "",
      label: "",
    },
    {
      value: "Teaching",
      label: "Teaching",
    },
    {
      value: "Non-teaching",
      label: "Non-teaching",
    },
  ];
  const defaultValues = {
    staffDetails: "",
    staffRole: "",
    dateStarted: "",
    designatedDuty: "",
    safeRecruitmentCheckConducted: "",
    remarks: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    addData(data);
    methods.reset();
  };

  return (
    <Fragment>
      <Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>
          <Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="staffDetails"
                label="Staff detail"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField name="staffRole" label="Roles" variant="filled" />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Date ` "
                  disablePast
                  value={methods.watch().dateStarted}
                  onChange={(value) => {
                    methods.setValue("dateStarted", value, {
                      shouldValidate: true,
                    });
                  }}
                  renderInput={(params) => (
                    <RHFTextField
                      variant="filled"
                      {...params}
                      name="dateStarted"
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="designatedDuty"
                label="Designated duty (if any)"
                variant="filled"
              />
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                select
                name="safeRecruitmentCheckConducted"
                label="Recruitment Check"
                variant="filled"
                required
                SelectProps={{ native: true }}
              >
                {CHECK.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFTextField>
            </Box>
            <Box sx={{ marginBlock: 2 }}>
              <RHFTextField
                name="remarks"
                label="Remarks"
                multiline={true}
                minRows={3}
                variant="filled"
              />
            </Box>
            <Grid sx={{ p: 3, textAlign: "center" }}>
              <LoadingButton
                variant="outlined"
                type="submit"
                sx={{ color: "#fff", width: 160, background: "#018fe2" }}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </FormProvider>
      </Box>
    </Fragment>
  );
}

export default SchoolCjresourcesModel;
