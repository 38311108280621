import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Alert,
  Stack,
} from "@mui/material";
import logoBg from "../../images/sslag.png";
import { Container } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RHFTextField, FormProvider } from "../../components/hook-form";

function SchoolCJFeedbackForm() {
  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  const [haveYouHeardOfSSLAG, setHaveYouHeardOfSSLAG] = React.useState("");
  const [haveAnyinteractions, setHaveAnyinteractions] = React.useState("");
  const [aAndeVisit, setAAndeVisit] = React.useState("");
  const [anyChange, setAnyChange] = React.useState("");

  const handleChangeForHaveYouHeardOfSSLAG = (event) => {
    setHaveYouHeardOfSSLAG(event.target.value);
  };
  const handleChangeForAnyInteraction = (event) => {
    setHaveAnyinteractions(event.target.value);
  };
  const handleChangeAandEVisit = (event) => {
    setAAndeVisit(event.target.value);
  };
  const handleChangeAnyChange = (event) => {
    setAnyChange(event.target.value);
  };




  const onSubmit = () => {};
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Box width={500} textAlign={"center"}>
          <Box>
            <Grid ml={17}>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 200,
                }}
              />
            </Grid>
          </Box>
          <Typography variant="h4">
            Feedback Questionnaire on SSLAG (Safe Schools Lagos) Compliance
            Journey (Stages 1-3)
          </Typography>
        </Box>
        <Grid mt={5}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Stack>

            <Container>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Part 1 </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6" mt={2}>
                    School Name
                  </Typography>
                  <Grid mt={3}>
                    <Typography>
                      Usually SSLAG A&Es are assigned for scheduled visits to
                      support school teams better understand the SSLAG Framework
                      and how to get started. If your school has been visited,
                      please, state how the visit went and go to Part 2: * (-If
                      not, pls go to question 2)*
                    </Typography>

                    <TextField
                      id="filled-textarea"
                      label="Describe Visit"
                      placeholder="Describe Visit"
                      multiline
                      variant="outlined"
                      minRows={4}
                      sx={{ width: 400, mt: 2 }}
                    />
                    <Box sx={{ marginBlock: 2 }}>
                      <Typography>
                        Have you heard of SSLAG before now?
                      </Typography>

                      <FormControl>
                        <RadioGroup
                          row
                          onChange={handleChangeForHaveYouHeardOfSSLAG}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>

                        {haveYouHeardOfSSLAG === "yes" ? (
                          <Grid>
                            <Typography>
                              If yes, pls choose one of the following sources:
                            </Typography>
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              row
                            >
                              <FormControlLabel
                                label="School media"
                                control={<Radio />}
                              />
                              <FormControlLabel
                                value="WhatsApp post"
                                control={<Radio />}
                                label="WhatsApp posts"
                              />
                              <FormControlLabel
                                value="Mainstream media"
                                control={<Radio />}
                                label="Mainstream media"
                              />
                              <FormControlLabel
                                value="from parents"
                                control={<Radio />}
                                label="from parents"
                              />
                              <FormControlLabel
                                value="from colleagues or other schools"
                                control={<Radio />}
                                label="from colleagues or other schools"
                              />
                              <FormControlLabel
                                value="from ministry of education"
                                control={<Radio />}
                                label="from ministry of education"
                              />
                              <FormControlLabel
                                value="SSLAG Digital application"
                                control={<Radio />}
                                label="SSLAG Digital application"
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="others"
                              />
                            </RadioGroup>
                          </Grid>
                        ) : null}
                      </FormControl>
                    </Box>
                    <Box>
                      <Typography>
                        Have you had any interactions with the SSLAG Team or any
                        LSC personnel on school safety?If yes, state how the
                        interaction went:
                      </Typography>

                      <FormControl>
                        <RadioGroup
                          row
                          onChange={handleChangeForAnyInteraction}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {haveAnyinteractions === "yes" ? (
                        <Grid>
                          <Typography>
                            If yes, pls choose one of the following sources:
                          </Typography>
                          <TextField
                            id="filled-textarea"
                            label="Describe Interaction"
                            placeholder="Describe Visit"
                            multiline
                            variant="outlined"
                            minRows={4}
                            sx={{ width: 400, mt: 2 }}
                          />
                        </Grid>
                      ) : null}
                    </Box>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Part 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box mt={3}>
                    <Typography>
                      You must have completed the evaluation documents that were
                      sent with this feedback questionnaire. If not kindly
                      complete them before proceeding with the rest of the
                      questionnaire;
                    </Typography>
                  </Box>

                  <Grid sx={{ marginBlock: 2 }}>
                    <Box display={"flex"}>
                      <Typography>4.</Typography>
                      <Typography>
                        Describe your perceptions towards the information sent
                        about SSLAG. State if you find it useful, clear and
                        straightforward *
                      </Typography>
                    </Box>

                    <TextField
                      id="filled-textarea"
                      label="Describe Information"
                      placeholder="Describe Information about sslag"
                      multiline
                      variant="outlined"
                      minRows={4}
                      sx={{ width: 400, mt: 2 }}
                    />
                  </Grid>
                  <Grid sx={{ marginBlock: 2 }}>
                    <Box display={"flex"}>
                      <Typography>5.</Typography>
                      <Typography>
                        Were the A&Es that visited your school (if applicable)
                        professional in their conduct?*
                      </Typography>
                    </Box>
                    <FormControl>
                      <RadioGroup row onChange={handleChangeAandEVisit}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="not applicable"
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          value=""
                          control={<Radio />}
                          label="Not Applicable"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>6.</Typography>
                        <Typography>
                          If you answered “YES” to question 5, please state
                          three positive things about your school’s engagement
                          with SSLAG (otherwise write “Not Applicable” in the
                          space below)*
                        </Typography>{" "}
                      </Box>

                      <TextField
                        id="filled-textarea"
                        label="Describe Visit"
                        placeholder="Describe Visit"
                        multiline
                        variant="outlined"
                        minRows={4}
                        sx={{ width: 400, mt: 2 }}
                      />
                    </Box>
                    <Box display={"flex"}>
                      <Typography>7.</Typography>
                      <Typography>
                        Are there things you would like to be changed going
                        forward? *
                      </Typography>
                    </Box>

                    <FormControl>
                      <RadioGroup row onChange={handleChangeAnyChange}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>8.</Typography>
                        <Typography>
                          If you answered "Yes" to the previous question, please
                          state your suggestions here:
                        </Typography>{" "}
                      </Box>

                      <TextField
                        id="filled-textarea"
                        label="Describe Visit"
                        placeholder="Describe Visit"
                        multiline
                        variant="outlined"
                        minRows={4}
                        sx={{ width: 400, mt: 2 }}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>9.</Typography>
                        <Typography>
                          On a scale of 1 to 10 (1 being the lowest & 10 the
                          highest), how would you rate * the entire process of
                          dissemination of information by SSLAG?
                        </Typography>{" "}
                      </Box>

                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        row
                      >
                        <FormControlLabel
                          value="one"
                          label="1"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="two"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          value="third"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          value="forth"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          value="fifth"
                          control={<Radio />}
                          label="5"
                        />
                        <FormControlLabel
                          value="six"
                          control={<Radio />}
                          label="6"
                        />
                        <FormControlLabel
                          value="seven"
                          control={<Radio />}
                          label="7"
                        />
                        <FormControlLabel
                          value="eight"
                          control={<Radio />}
                          label="8"
                        />
                        <FormControlLabel
                          value="nine"
                          control={<Radio />}
                          label="9"
                        />
                        <FormControlLabel
                          value="ten"
                          control={<Radio />}
                          label="10"
                        />
                      </RadioGroup>
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>10.</Typography>
                        <Typography>
                          On a scale of 1 to 10 (1 being the lowest & 10 the
                          highest), how ready do you * think your school is for
                          the Safety Audit at this stage?
                        </Typography>{" "}
                      </Box>

                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        row
                      >
                        <FormControlLabel
                          value="one"
                          label="1"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="two"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          value="third"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          value="forth"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          value="fifth"
                          control={<Radio />}
                          label="5"
                        />
                        <FormControlLabel
                          value="six"
                          control={<Radio />}
                          label="6"
                        />
                        <FormControlLabel
                          value="seven"
                          control={<Radio />}
                          label="7"
                        />
                        <FormControlLabel
                          value="eight"
                          control={<Radio />}
                          label="8"
                        />
                        <FormControlLabel
                          value="nine"
                          control={<Radio />}
                          label="9"
                        />
                        <FormControlLabel
                          value="ten"
                          control={<Radio />}
                          label="10"
                        />
                      </RadioGroup>
                    </Box>

                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>11.</Typography>
                        <Typography>
                          If you answered "Yes" to the previous question, please
                          state your suggestions here:
                        </Typography>{" "}
                      </Box>

                      <TextField
                        id="filled-textarea"
                        label="Describe Visit"
                        placeholder="Describe Visit"
                        multiline
                        variant="outlined"
                        minRows={4}
                        sx={{ width: 400, mt: 2 }}
                      />
                    </Box>

                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>12.</Typography>
                        <Typography>
                          How would you rate your overall satisfaction of the
                          SSLAG implementation so * far, on a scale of 1
                          (lowest) to 10 (highest)?
                        </Typography>{" "}
                      </Box>

                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        row
                      >
                        <FormControlLabel
                          value="one"
                          label="1"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="two"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          value="third"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          value="forth"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          value="fifth"
                          control={<Radio />}
                          label="5"
                        />
                        <FormControlLabel
                          value="six"
                          control={<Radio />}
                          label="6"
                        />
                        <FormControlLabel
                          value="seven"
                          control={<Radio />}
                          label="7"
                        />
                        <FormControlLabel
                          value="eight"
                          control={<Radio />}
                          label="8"
                        />
                        <FormControlLabel
                          value="nine"
                          control={<Radio />}
                          label="9"
                        />
                        <FormControlLabel
                          value="ten"
                          control={<Radio />}
                          label="10"
                        />
                      </RadioGroup>
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <Box display={"flex"}>
                        <Typography>13.</Typography>
                        <Typography>
                          On a scale of 1 (lowest) to 10 (highest), please
                          provide an overall rating for the A&Es that visited
                          your school (if applicable)*
                        </Typography>{" "}
                      </Box>

                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        row
                      >
                        <FormControlLabel
                          value="one"
                          label="1"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="two"
                          control={<Radio />}
                          label="2"
                        />
                        <FormControlLabel
                          value="third"
                          control={<Radio />}
                          label="3"
                        />
                        <FormControlLabel
                          value="forth"
                          control={<Radio />}
                          label="4"
                        />
                        <FormControlLabel
                          value="fifth"
                          control={<Radio />}
                          label="5"
                        />
                        <FormControlLabel
                          value="six"
                          control={<Radio />}
                          label="6"
                        />
                        <FormControlLabel
                          value="seven"
                          control={<Radio />}
                          label="7"
                        />
                        <FormControlLabel
                          value="eight"
                          control={<Radio />}
                          label="8"
                        />
                        <FormControlLabel
                          value="nine"
                          control={<Radio />}
                          label="9"
                        />
                        <FormControlLabel
                          value="ten"
                          control={<Radio />}
                          label="10"
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid item sx={{ textAlign: "right" }}>
                <LoadingButton
                  type="submit"
                  color="secondary"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{
                    width: 160,
                  }}
                >
                  Sumbit
                </LoadingButton>
              </Grid>
            </Container>
          </FormProvider>
        </Grid>
      </Grid>
      <Box>.</Box>
    </Fragment>
  );
}

export default SchoolCJFeedbackForm;
