import { Button, Grid, Typography, Container } from "@mui/material";
import React, { Fragment } from "react";
import Logo from "../../images/background.png";
import { PATH_AUTH } from "../../routes/paths";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function OnSuccess() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
    color: "#fff",
  }));
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <RootStyle
      sx={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${Logo})`,
        // backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${Logo})`,
      }}
    >
      <Grid sx={{ alignSelf: "center" }}>
        <Container
          sx={{
            borderRadius: 2,
            padding: 2,
            margin: 10,
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
          }}
        >
          <Typography variant="h4" color={"#00000"}>
            Onboarding process done successfully
          </Typography>
          <Grid sx={{ mt: 5, textAlign: "center" }}>
            <Button
              size="large"
              variant="contained"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              // component={RouterLink}
              // to={PATH_AUTH.login}
            >
              Back to login page
            </Button>
          </Grid>
        </Container>
      </Grid>
    </RootStyle>
  );
}

export default OnSuccess;
