import { Box, Container, Grid, Typography, Stack, Alert } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import { useForm } from "react-hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

import {
  FormProvider,
  RHFTextField,
  RHFRadioGroup,
  RHFCheckbox,
} from "../../../components/hook-form";

function StaffRoleAndResponsibility({ nextStep, prevStep, schoolId, auditId }) {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId } = useParams();

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };
  const defaultValues = {
    numberOfHeadTeachers: "",
    isThereFirstAiderCert: "",
    isThereFirstAider: "",
    isThereCso: "",
    isPtaConstituted: "",
    isThereSafetyCommittee: "",
    doesSafetyCommitteHaveCbcCertificate: "",
    isThereHeadOfPastoralCare: "",
    isHeadOfPastoralCareCbcCertAvailable: "",
    isThereBursar: "",
    isSickbayAsstAvailable: "",
    doesSickbayAsstHaveCbcCert: "",
    isThereLiberian: "",
    areThereLabTechs: "",
    dolabTechsHaveCbcCert: "",
    areThereCleaners: "",
    isSSLAGFoodCertPresentInKitchen: "",
    otherSupportingEvidence: "",
    evidenceOfStaffSafetyAwareness: "",
    evidenceOfSafetyRecruitment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const sample = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  //   let unmounted = false;
  //   const GetAllGeneralSection = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axoisCustomAgentForAdmin.get(
  //         `Audit/GetStaffRolesAndResponsibilitiesSectionSix?schoolId=${schoolId}&auditId=${auditId}`
  //       );
  //       const { statusCode, message } = response.data;

  //       if (statusCode !== 200) {
  //         throw new Error(message);
  //       }
  //       const { data } = response.data;
  //       if (data !== null) {
  //         setValue("numberOfHeadTeachers", data.numberOfHeadTeachers ?? "");
  //         setValue("isThereFirstAiderCert", data.isThereFirstAiderCert ?? "");
  //         setValue("isThereFirstAider", data.isThereFirstAider ?? "");
  //         setValue("isThereCso", data.isThereCso ?? "");
  //         setValue("isPtaConstituted", data.isPtaConstituted ?? "");
  //         setValue("isThereSafetyCommittee", data.isThereSafetyCommittee ?? "");
  //         setValue(
  //           "doesSafetyCommitteHaveCbcCertificate",
  //           data.doesSafetyCommitteHaveCbcCertificate ?? ""
  //         );
  //         setValue(
  //           "isThereHeadOfPastoralCare",
  //           data.isThereHeadOfPastoralCare ?? ""
  //         );
  //         setValue(
  //           "isHeadOfPastoralCareCbcCertAvailable",
  //           data.isHeadOfPastoralCareCbcCertAvailable ?? ""
  //         );
  //         setValue("isThereBursar", data.isThereBursar ?? "");
  //         setValue("isSickbayAsstAvailable", data.isSickbayAsstAvailable ?? "");
  //         setValue(
  //           "doesSickbayAsstHaveCbcCert",
  //           data.doesSickbayAsstHaveCbcCert ?? ""
  //         );
  //         setValue("isThereLiberian", data.isThereLiberian ?? "");
  //         setValue("areThereLabTechs", data.areThereLabTechs ?? "");
  //         setValue("dolabTechsHaveCbcCert", data.dolabTechsHaveCbcCert ?? "");
  //         setValue("areThereCleaners", data.areThereCleaners ?? "");
  //         setValue(
  //           "isSSLAGFoodCertPresentInKitchen",
  //           data.isSSLAGFoodCertPresentInKitchen ?? ""
  //         );
  //         setValue(
  //           "otherSupportingEvidence",
  //           data.otherSupportingEvidence ?? ""
  //         );
  //         setValue(
  //           "evidenceOfStaffSafetyAwareness",
  //           data.evidenceOfStaffSafetyAwareness ?? ""
  //         );
  //         setValue(
  //           "evidenceOfSafetyRecruitment",
  //           data.evidenceOfSafetyRecruitment ?? ""
  //         );
  //       }
  //       //setGeneralSection(response.data.data);
  //     } catch (error) {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (id !== null && id !== "" && !unmounted) GetAllGeneralSection();
  //   return () => {
  //     unmounted = true;
  //   };
  // }, [schoolId]);
  const Navigate = () => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };

  const onSubmit = async (dataToSubmit) => {
    try {
      const response = await axoisCustomAgent.post(
        `/Audit/SumbitSectionSevenStaffResponsibilities`,
        {
          schoolId,
          auditId,
          numberOfHeadTeachers: dataToSubmit.numberOfHeadTeachers,
          isThereFirstAiderCert: dataToSubmit.isThereFirstAiderCert,
          isThereFirstAider: dataToSubmit.isThereFirstAider,
          isThereCso: dataToSubmit.isThereCso,
          isPtaConstituted: dataToSubmit.isPtaConstituted,
          isThereSafetyCommittee: dataToSubmit.isThereSafetyCommittee,
          doesSafetyCommitteHaveCbcCertificate:
            dataToSubmit.doesSafetyCommitteHaveCbcCertificate,
          isThereHeadOfPastoralCare: dataToSubmit.isThereHeadOfPastoralCare,
          isHeadOfPastoralCareCbcCertAvailable:
            dataToSubmit.isHeadOfPastoralCareCbcCertAvailable,
          isThereBursar: dataToSubmit.isThereBursar,
          isSickbayAsstAvailable: dataToSubmit.isSickbayAsstAvailable,
          doesSickbayAsstHaveCbcCert: dataToSubmit.doesSickbayAsstHaveCbcCert,
          isThereLiberian: dataToSubmit.isThereLiberian,
          areThereLabTechs: dataToSubmit.areThereLabTechs,
          dolabTechsHaveCbcCert: dataToSubmit.dolabTechsHaveCbcCert,
          areThereCleaners: dataToSubmit.areThereCleaners,
          isSSLAGFoodCertPresentInKitchen:
            dataToSubmit.isSSLAGFoodCertPresentInKitchen,
          otherSupportingEvidence: dataToSubmit.otherSupportingEvidence,
          evidenceOfStaffSafetyAwareness:
            dataToSubmit.evidenceOfStaffSafetyAwareness,
          evidenceOfSafetyRecruitment: dataToSubmit.evidenceOfSafetyRecruitment,
        }
      );
      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;
      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "70%",
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 7: Staff roles & responsibilities
                </Typography>
              </Box>

              <ol>
                <Box display={"flex"} mt={2}>
                  <Box width={"48%"}>
                    <Typography>*Please indicate all that are seen</Typography>
                    <Box sx={{ marginBlock: 2, width: 300 }}>
                      <RHFTextField
                        id="filled-basic"
                        label="number of School leaders (heads, asst heads and/deputies)"
                        name="numberOfHeadTeachers"
                        variant="filled"
                        type="number"
                      />
                    </Box>

                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>First aider's certificate seen?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereFirstAiderCert"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>First aider's seen?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereFirstAider"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography> Are CSO position in school?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereCso"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography> Are PTA constituted?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isPtaConstituted"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Are there safety committee members?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereSafetyCommittee"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Safety committee have CBC certificate?
                        </Typography>
                      </li>
                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="doesSafetyCommitteHaveCbcCertificate"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Head of pastoral care ?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereHeadOfPastoralCare"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Bursar ?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereBursar"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Sick bay assistance Seen?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isSickbayAsstAvailable"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                  <Grid
                    sx={{
                      mt: 3,
                      borderLeft: "2px solid  #018fe2",
                      minHeight: 70,
                      // borderleft: 6px solid green;
                    }}
                  ></Grid>

                  <Box width={"48%"} p={2}>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Sick bay assistance CBC certificate Seen?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="doesSickbayAsstHaveCbcCert"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Are librarian sighted?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isThereLiberian"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Are lab technicians sighted?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="areThereLabTechs"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Lab Technicians CBC certificate seen?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="dolabTechsHaveCbcCert"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Are cleaners seen?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="areThereCleaners"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          SSLAG food hygiene certification sighted for kitchen
                          staffs?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="isSSLAGFoodCertPresentInKitchen"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>Other supporting evidence?</Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="otherSupportingEvidence"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        <Typography>
                          Evidence of safety awareness & approved professional
                          development for staff on SS ?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="evidenceOfStaffSafetyAwareness"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                    <Box sx={{ marginBlock: 2 }}>
                      <li>
                        {" "}
                        <Typography>
                          Evidence of safe recruitment checks done?
                        </Typography>
                      </li>

                      <RHFRadioGroup
                        options={sample.map((x) => x.value)}
                        name="evidenceOfSafetyRecruitment"
                        getOptionLabel={sample.map((x) => x.label)}
                      />
                    </Box>
                  </Box>
                </Box>
              </ol>

              <Box display={"flex"} justifyContent={"right"}>
                <Grid sx={{ p: 3 }}>
                  <LoadingButton
                    variant="outlined"
                    type="submit"
                    loading={isSubmitting}
                    // onClick={() => handleDirectionChange("forward")}
                    // onClick={Navigate}
                    sx={{ color: "#fff", background: "#018fe2" }}
                    // startIcon={<Back />}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}
export default StaffRoleAndResponsibility;
