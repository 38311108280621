import React, { Fragment, useState, useEffect } from "react";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useForm } from "react-hook-form";
import { Grid, Container, Box, Typography, Stack, Alert } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";

function EditConcessionCode() {
  useEffect(() => {
    let unmounted = false;
    const getAllDiscountType = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetConcessionTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setDiscountType([...discountType, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllDiscountType();

    const getAllDiscountValue = async () => {
      let unmounted = false;
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `Audit/GetConcessionValueTypes`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setDiscountValue([...discountValue, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllDiscountValue();
    return () => {
      unmounted = true;
    };
  }, []);

  const DiscountSchema = Yup.object().shape({
    discountValue: Yup.string().required("field  is required"),
    discountName: Yup.string().required("field  is required"),
    discountType: Yup.string().required("field  is required"),
    percentageRate: Yup.string().when("discountValue", {
      is: "1",
      then: Yup.string().required("field is required"),
    }),
    targetedemail: Yup.string().when("discountType", {
      is: "1",
      then: Yup.string().required("field is required"),
    }),
    flatRate: Yup.string().when("discountValue", {
      is: "2",
      then: Yup.string().required("field is required"),
    }),
  });

  const defaultValues = {
    targetedemail: "",
    discountType: "",
    discountValue: "",
    discountName: "",
    percentageRate: "",
    flatRate: "",
  };

  const [discountValue, setDiscountValue] = useState([
    {
      id: "",
      name: "select discount value",
    },
  ]);

  const [discountType, setDiscountType] = useState([
    {
      id: "",
      name: "select discount type",
    },
  ]);

  const methods = useForm({
    resolver: yupResolver(DiscountSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const watchDiscount = watch("discountType");
  const watchDiscounts = watch("discountValue");

  const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [getToken, setGetToken] = useState([]);

    const onSubmit = async (data) => {
        let valueToSubmit = 0;
        if (data.discountValue == 1) {
          valueToSubmit = data.percentageRate;
        } else if (data.discountValue == 2) {
          valueToSubmit = data.flatRate;
        }
   
      };








  if (loading) {
    return (
      <Grid
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Typography>Loading ....</Typography>
        </Grid>
      </Grid>
    );
  }

 
  return (
    <Fragment>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1} m={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}
        </Stack>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <RHFTextField
                name="discountName"
                label="Discount Name"
                variant="filled"
              />
            </Grid>

            <Grid item xs={3}>
              <RHFTextField
                select
                name="discountType"
                required
                variant="filled"
                SelectProps={{ native: true }}
              >
                {discountType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </RHFTextField>
            </Grid>
            {watchDiscount === "1" ? (
              <Grid item xs={3}>
                <RHFTextField
                  name="targetedemail"
                  minRows={3}
                  multiline
                  label="targeted Email"
                  variant="filled"
                />
              </Grid>
            ) : null}

            <Grid item xs={3}>
              <RHFTextField
                select
                name="discountValue"
                required
                variant="filled"
                SelectProps={{ native: true }}
              >
                {discountValue.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </RHFTextField>
            </Grid>

            {watchDiscounts === "1" ? (
              <Grid item xs={3}>
                <RHFTextField
                  name="percentageRate"
                  label="Percentage Rate (please enter value range 1-100) "
                  variant="filled"
                />
              </Grid>
            ) : null}

            {watchDiscounts === "2" ? (
              <Grid item xs={3}>
                <RHFTextField
                  name="flatRate"
                  label="flat Rate (please this should be in naira)"
                  variant="filled"
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <Grid textAlign={"right"} p={2}>
          <LoadingButton
            type="submit"
            color="secondary"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Grid> 
      </FormProvider>
    </Fragment>
  );
}

export default EditConcessionCode;
