import Alert from "@material-ui/icons/NotificationImportantOutlined";
import firstSlide from "../images/PHOTO-2023-07-07-04-38-51 1.png";
export const data = [
  {
    index: 1,
    courseDesc: "CBC (capacity building conference)",
    code: "KTCM_AOB001",
    modules: "variable",
    date: "31 May",
    targetPart: "School teams",
    venue: "LSC conference room",
    time: "10-2 prompt",
  },
  {
    index: 2,
    courseDesc: "INSET (available for taker)",
    code: "KTCM_AOB002",
    modules: "3",
    date: "4 June",
    targetPart: "School teams",
    venue: "Dowen college's Site",
    time: "11am prompt",
  },
  {
    index: 3,
    courseDesc: "CBC",
    code: "KTCM-1919/1",
    modules: "2",
    date: "7 June",
    targetPart: "School teams",
    venue: "Folarin coker hall alausa",
    time: "10-2 prompt",
  },
  {
    index: 4,
    courseDesc: "CBC",
    code: "KTCM-1919/1",
    modules: "2",
    date: "23 June",
    targetPart: "School teams",
    venue: "Folarin coker hall alausa",
    time: "10-2 prompt",
  },
  {
    index: 5,
    courseDesc: "CBC",
    code: "KTCM-1919/1",
    modules: "2",
    date: "7 Sept",
    targetPart: "School teams",
    venue: "Adeyemi Bero",
    time: "10-2 prompt",
  },
  {
    index: 6,
    courseDesc: "CBC (capacity building conference)",
    code: "KTCM_AOB002",
    modules: "variable",
    date: "14 Sept",
    targetPart: "School teams",
    venue: "Adeyemi Bero",
    time: "10-2 prompt",
  },
  {
    index: 7,
    courseDesc: "INSET (available for taker)",
    code: "KTCM_AOB001",
    modules: "3",
    date: "17 Sept",
    targetPart: "School teams",
    venue: "school site",
    time: "11am prompt",
  },
  {
    index: 8,
    courseDesc: "CBC",
    code: "KTCM_AOB002",
    modules: "3",
    date: "30 Sept",
    targetPart: "School teams",
    venue: "Adeyemi Bero",
    time: "10-2 prompt",
  },
  {
    index: 9,
    courseDesc: "SAFETY INSET (safety in service education training)",
    code: "KTCM_AOB001",
    modules: "3",
    date: "8 Oct",
    targetPart: "School teams",
    venue: "School site",
    time: "11am prompt",
  },
  {
    index: 10,
    courseDesc: "CBC ",
    code: "KTCM_AOB002",
    modules: "3",
    date: "31 Oct",
    targetPart: "School teams",
    venue: "Adeyemi Bero",
    time: "11am prompt",
  },
  {
    index: 11,
    courseDesc: "CBC ",
    code: "KTCM_AOB002",
    modules: "3",
    date: "21 Nov",
    targetPart: "School teams",
    venue: "Adeyemi Bero",
    time: "11am prompt",
  },
];

export const MandatoryData = [
  {
    index: 1,
    name: "SSLAG MS (minimum standards) Day",
    size: "A2",
    Price: "3500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667885164/mandatory%20supplies/IMG_7681_nyryg3.png",
  },
  {
    index: 2,
    name: "SSLAG MS (minimum standards) Boarding",
    size: "A2",
    Price: "3500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1667885210/mandatory%20supplies/IMG_7682_xebqiw.png",
  },
  {
    index: 3,
    name: "SSLAG Compliance Manual",
    size: "",
    Price: "5000",
  },
  {
    index: 4,
    name: "SSLAG Quick Guide",
    size: "",
    Price: "3000",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957252/mandatory%20supplies/Quick_guide_Document_wgz6tg.png",
  },
  {
    index: 5,
    name: "Fire Action Sign",
    size: "15 x 20cm",
    Price: "1500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957250/mandatory%20supplies/Fire_action_sign_tnyjk4.png",
  },
  {
    index: 6,
    name: "No Smoking Sign",
    size: "15 x 20cm",
    Price: "1500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957249/mandatory%20supplies/No_smoking_sign_u8fldl.png",
  },
  {
    index: 7,
    name: "First Aid Kits Compliant With SSLAG Framework v.01",
    size: "270 x 295 x 100 for a group of 25 to 30 students",
    Price: "27,000 (with contents) 20,000(without contents)",

    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957254/mandatory%20supplies/First_aid_box_yojghv.png",
  },
  {
    index: 8,
    name: "Fire Door (keep shut) Sign",
    size: "10 x 10cm",
    Price: "500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957251/mandatory%20supplies/Fire_door_keep_shut_ns0s3a.png",
  },
  {
    index: 9,
    name: "Hand wash",
    size: "10 x 5cm",
    Price: "500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957252/mandatory%20supplies/Hand_wash_1_alrzib.png",
  },
  {
    index: 10,
    name: "Assembly Point",
    size: "30 x 10cm",
    Price: "1500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957250/mandatory%20supplies/Assembly_point_srzkdw.png",
  },
  {
    index: 11,
    name: "Fire Exit(running man) Sign",
    size: "30 x 10cm",
    Price: "1500",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957251/mandatory%20supplies/Fire_exit_tjthxs.png",
  },
  {
    index: 12,
    name: "Lollipop Traffic control signage",
    size: "35.5 x 57cm",
    Price: "8000",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957247/mandatory%20supplies/Lollipop_sticks_hah1mh.jpg",
  },
  {
    index: 13,
    name: "Foldable Wet Sign",
    size: "",
    Price: "9000",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957249/mandatory%20supplies/Wet_floor_tlrjpj.jpg",
  },
  {
    index: 14,
    name: "High Visibility Vest",
    size: "All children & adult sizes",
    Price: "3000(children sizes) 4000(adult sizes)",
    image:
      "https://res.cloudinary.com/peax3/image/upload/v1666957251/mandatory%20supplies/High_visibility_vest_hvba37.png",
  },
];

export const AboutUs = [
  {
    index: 1,
    descriptions: 1,
    image: firstSlide,
  },
  {
    index: 1,
    descriptions: 1,
    image: firstSlide,
  },
  {
    index: 1,
    descriptions: 1,
    image: firstSlide,
  },
];

// =======================================================================
export const KnowledgeTransfer = [
  {
    index: 1,
    event: "SSLAG induction for collaborators",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 2,
    event: "Delivering SSLAG Compliance insurance plan",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 3,
    event: "Delivering Specialist Training for SSLAG",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 4,
    event: "Refresher Courses for SSLAG SMEs(subject matter experts)",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 5,
    event: "Evalutors Masterclass",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 6,
    event: "Assessors Masterclass",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 7,
    event: "SSLAG Audit Clerks and Resources Briefing sessions",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 8,
    event: "Building a successful A&Es Portfolio for SSLAG",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 9,
    event: "SSLAG Advocacy & Stakeholders Engagements",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 10,
    event: "Parental Partnership course",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 11,
    event:
      "Safe Practice Status for private lesson Tutors and Skills/Sports coaches",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 12,
    event: "Working with young children as Nannies or Children Keywords",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 13,
    event: "Food Vendors Workshops",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 14,
    event: "Prevent for Safeguarding the Security personnel course",
    targetAud: "",
    registerDel: "",
  },

  {
    index: 15,
    event: "Observing effective COSHH in Schools",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 16,
    event: "The school Transit providers course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 17,
    event: "Basic 3rd Party Agents course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 18,
    event: "Advance Safeguarding course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 19,
    event: "All on board: SSLAG for support staff in schools",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 20,
    event: "Parents course",
    targetAud: "",
    registerDel: "",
  },
];
export const MandatoryKnowledgeTransfer = [
  {
    index: 1,
    event: "First (First Aiders Specialist Training) ",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 2,
    event: "School Transit Driver training",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 3,
    event: "Food Handlers Course ",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 4,
    event: "DSO(Designated Safeguarding Officer) Course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 5,
    event:
      "Implementing Safety Management System for SSLAG: for school leaders",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 6,
    event: "School owners Fitness Course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 7,
    event: "Fire handling course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 8,
    event: "Risk Assessment and Emergency preparedness course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 9,
    event: "Behaviour management course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 10,
    event: "Parental Partnership course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 11,
    event: "INSET (in service education training)Day on Safety",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 12,
    event: "Education Trips Essential Course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 13,
    event: "Train the Trainer Course",
    targetAud: "",
    registerDel: "",
  },
  {
    index: 14,
    event: "Capacity Building Conference (CBC)",
    targetAud: "",
    registerDel: "",
  },
];

export const EventData1 = [
  {
    index: 1,
    event: "SSLAG induction for collaborators",
  },
  {
    index: 2,
    event: "Delivering Specialist Training for SSLAG",
  },
  {
    index: 3,
    event: "All on board: SSLAG for support staff in schools",
  },
  {
    index: 4,
    event: "Basic 3rd party agents course",
  },
  {
    index: 5,
    event: "Delivering Specialist Training for SSLAG",
  },
];

export const EventData2 = [
  {
    index: 1,
    event: "Parental partnership course",
  },
  {
    index: 2,
    event: "SSLAG induction for collaborators",
  },
  {
    index: 3,
    event: "Observing effective COSHH in schools",
  },
  {
    index: 4,
    event: "Food vendors workshops",
  },
  {
    index: 5,
    event: "Observing effective COSHH in schools",
  },
];

export const EventData3 = [
  {
    index: 1,
    event: "White Sand School",
  },
  {
    index: 2,
    event: "Green Spring college",
  },
  {
    index: 3,
    event: "Chrisland college",
  },
  {
    index: 4,
    event: "Kuramo high ",
  },
  {
    index: 5,
    event: "Green Spring college",
  },
];

// ============================

export const Notifyme = [
  {
    index: "Whitesand intervention",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "chrisland intervention",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "Admin Warning",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "whitesand intervention",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "Admin Warning",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "Whitesand intervention",
    school: "chrisland",
    icon: <Alert />,
  },
  {
    index: "chrisland intervention",
    school: "chrisland",
    icon: <Alert />,
  },
];

export const Incident = [
  {
    index: 1,
    event: "Whitesand intervention",
  },
  {
    index: 2,
    event: "chrisland intervention",
  },
  {
    index: 3,
    event: "Admin Warning",
  },
  {
    index: 4,
    event: "whitesand intervention",
  },
  {
    index: 5,
    event: "Admin Warning",
  },
];

export const Accident = [
  {
    index: 1,
    event: "Whitesand intervention",
  },
  {
    index: 2,
    event: "chrisland intervention",
  },
  {
    index: 3,
    event: "Admin Warning",
  },
  {
    index: 4,
    event: "whitesand intervention",
  },
  {
    index: 5,
    event: "Admin Warning",
  },
];
export const Compliant = [
  {
    index: 1,
    event: "Whitesand intervention",
  },
  {
    index: 2,
    event: "chrisland intervention",
  },
  {
    index: 3,
    event: "Admin Warning",
  },
  {
    index: 4,
    event: "whitesand intervention",
  },
  {
    index: 5,
    event: "Admin Warning",
  },
];
export const ComplianceObligations = [
  {
    index: 1,
    checklist:
      "A current copy of the Nigeria Insurance Association (NIA) approved school safety compliance policy showing adequate insurance coverage that meets the limit required for your entire school operation",
  },
  {
    index: 2,
    checklist:
      "An up-to-date SMS documentation showing the relevant safety policy framework and procedures that meets the SSLAG Framework guidelines",
  },
  {
    index: 3,
    checklist:
      "A constituted safety committee, named first aiders and school safety wardens or officers",
  },
  {
    index: 4,
    checklist:
      "A summary of all self assessment checks conducted in the past calendar year, along with the status of any action plans arising from the self assessment checks ",
  },
  {
    index: 5,
    checklist:
      "Safe recruitment clearances (SRCs) on all new and old members of staff",
  },
  {
    index: 6,
    checklist:
      "A copy of your PSL (provisional safety licence) or full safety license if applicable",
  },
  {
    index: 7,
    checklist:
      "Documentary Proof that appropriate no of staff hold current certificates of participation for the Capacity Building Conference (CBC)",
  },
  {
    index: 8,
    checklist: "Completed safety drill logs dating back to three terms",
  },
  {
    index: 9,
    checklist: "Documentary proof of food hygiene training for catering staff",
  },
  {
    index: 10,
    checklist:
      "Records of INSET days on safety for the different school teams, advocacy briefings for students and parents",
  },
  {
    index: 11,
    checklist:
      "At least 14 items from the mandatory safety supplies (Compliance manual 2 copies per section, Minimum standard guidelines-4 per section, quick guides, high visibility vests, appropriately placed fire extinguishers, approved first gi kits,safety signages and caution signs, traffic control infractructure etc).",
  },
  {
    index: 12,
    checklist: "Complete maintenance records on all installations",
  },
  {
    index: 13,
    checklist: "A current EMP and evidence of parental partnership",
  },
  {
    index: 14,
    checklist:
      "Incident report log book (RIDDOR) for each section of the school",
  },
  {
    index: 15,
    checklist: "COSSH training for cleaners",
  },
  {
    index: 16,
    checklist: "Safe  fumigation log",
  },
];
