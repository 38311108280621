import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Alert,
  Checkbox,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import useResponsive from "../../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import AdminBG from "../../../images/background.png";
import Back from "@material-ui/icons/ArrowBackIosOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../../components/hook-form";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router";

function MandatorySuppliesAdmin({ nextStep, prevStep, auditId, schoolId }) {
  const [checkBoxChecked, setCheckBoxChecked] = React.useState("");
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let { taskId, clerkId } = useParams();

  const defaultValues = {
    isComplianceManualPresent: "",
    isSSFPosterPresent: "",
    isSSRulebookPresent: "",
    isFirstAidBoxPresent: "",
    isCovidSocialDistancingSignPresent: "",
    isSlipperyFloorSignPresent: "",
    isAssemblyNoticePresent: "",
    isFireSignPresent: "",
    isRunningManSignPresent: "",
    isWashYourHandsSignPresent: "",
    isFireExtinguisherPresent: "",
    isCOSHHSignPresent: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [direction, setDirection] = useState("");

  const handleDirectionChange = (direction) => {
    setDirection(direction);
  };

  useEffect(() => {
    let unmounted = false;
    const GetSupplies = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `/Audit/GetSectionFourMandatorySupplies?taskId=${taskId}&clerkId=${clerkId}`
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        const { data } = response.data;
        methods.reset({ ...data });

        //setGeneralSection(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (id !== null && id !== "" && !unmounted) GetSupplies();
    return () => {
      unmounted = true;
    };
  }, []);

  const onSubmit = () => {};
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <Container
          sx={{
            background: "#ffff",
            borderRadius: 1,
            boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
            mt: 2,
            minHeight: 600,
            width: "50%",
            // Width: 100,
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} m={3}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}

              <Box textAlign={"center"}>
                <Typography
                  variant="h5"
                  sx={{
                    background: "#00bbea",
                    // textAlign: "centre",
                    mt: 2,
                    p: 2,
                  }}
                >
                  SECTION 4:Mandatory supplies
                </Typography>
              </Box>

              <Box>
                <Typography> *Please indicate number that are seen</Typography>

                <ol>
                  <Box>
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="isComplianceManualPresent"
                          label="Compliance Manual"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="SSF Poster"
                          name="isSSFPosterPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="SS Rule book"
                          name="isSSRulebookPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="First Aid Box"
                          name="isFirstAidBoxPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Covid-19 Social Distancing Sign"
                          name="isCovidSocialDistancingSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Slippery Floor Sign"
                          name="isSlipperyFloorSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Assembly Point Notice"
                          name="isAssemblyNoticePresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Fire Door (Running Man)Sign"
                          name="isFireSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="In Case Of Fire Sign"
                          name="isRunningManSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Please Wash Your Hands Sign"
                          name="isWashYourHandsSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="Fire Extinguisher Sign"
                          name="isFireExtinguisherPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          name="laboratoryPosition"
                          label="State the exact provision:?"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    <Box sx={{ marginBlock: 1 }}>
                      <li>
                        <RHFTextField
                          label="COSHH Sign"
                          name="isCOSHHSignPresent"
                          variant="filled"
                        />
                      </li>
                    </Box>{" "}
                    {/* =================================== */}
                  </Box>
                </ol>
              </Box>
            </Stack>
          </FormProvider>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default MandatorySuppliesAdmin;
