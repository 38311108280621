import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography, Container, Alert, Stack } from "@mui/material";
import logoBg from "../../images/sslagBookingForm.JPG";
import Send from "@material-ui/icons/SendOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";
// import logoBg from "../../images/sslagBookingForm";

function CBCBookingForm() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "sslag Cbc Booking Form",
            token,
          }
        );

        const { statusCode, message } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }

        setLinkStatus(message);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    fullName: "",
    nameOfSchool: "",
    noOfSchool: "",
    emailOfSchool: "",

    contactNumber: "",
    jobRole: "",
    noOfDelegate: "",
    delegateName: "",
    additionalInfo: "",
  };

  const CBCBookingSchema = Yup.object().shape({
    fullName: Yup.string().required("field is required"),
    nameOfSchool: Yup.string().required("field  is required"),
    noOfSchool: Yup.number().required("field  is required"),
    emailOfSchool: Yup.string().required("field  is required"),
    contactNumber: Yup.number().required("field  is required"),
    jobRole: Yup.string().required("field  is required"),
    noOfDelegate: Yup.number().required("field  is required"),
    delegateName: Yup.string().required("field  is required"),
    additionalInfo: Yup.string().required("field  is required"),
  });
  const methods = useForm({
    resolver: yupResolver(CBCBookingSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,

    watch,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitCBCInterventionForm`,
        {
          schoolId,
          token,
          fullName: data.fullName,
          schoolName: data.nameOfSchool,
          schoolEmailAddress: data.emailOfSchool,
          schoolTelephoneNumber: data.noOfSchool,
          personnalContactAddress: data.contactNumber,
          jobRole: data.jobRole,
          numberOfDelegates: data.noOfDelegate,
          namesOfDelegates: data.delegateName,
          anyAdditionalInformation: data.additionalInfo,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
        }}
      >
        <Box width={500} textAlign={"center"}>
          <Box>
            <Grid ml={17}>
              <img
                src={logoBg}
                alt=""
                style={{
                  width: 300,
                }}
              />
            </Grid>
          </Box>
          <Typography mt={2} variant="h4">
            SSLAG CBC Booking Form
          </Typography>
        </Box>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              boxShadow: "2px 1px 10px rgba(0,0,0,0.3)",
              mt: 2,
              minHeight: 600,
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box width={"48%"}>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>1.</Typography>
                    <Typography ml={1}>Your full name</Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    placeholder="Write..."
                    name="fullName"
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>2.</Typography>
                    <Typography ml={1}>Name of school</Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    placeholder="Write..."
                    name="nameOfSchool"
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>3.</Typography>
                    <Typography ml={1}>School telephone number</Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    name="noOfSchool"
                    type="number"
                    placeholder="write"
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>

                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>3.</Typography>
                    <Typography ml={1}>School email address</Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    type="email"
                    name="emailOfSchool"
                    placeholder="Write..."
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>4.</Typography>
                    <Typography ml={1}>
                      Your personnal contact number
                    </Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    type="number"
                    name="contactNumber"
                    placeholder="Write..."
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>
              </Box>
              <Box width={"48%"}>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>5.</Typography>
                    <Typography ml={1}>Your job role</Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    placeholder="Write..."
                    name="jobRole"
                    variant="filled"
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>6.</Typography>
                    <Typography ml={1}>
                      Number of delegates expected to attend from your school
                    </Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    name="noOfDelegate"
                    variant="filled"
                    type="number"
                    sx={{ mt: 2 }}
                  />
                </Box>
                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>7.</Typography>
                    <Typography ml={1}>
                      Please, list all the name of all the delegates that will
                      be attending the CBC. it is vital that the names are
                      written correctly as it will be used to issue certificate
                      of attendance
                    </Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    placeholder="Describe Visit"
                    name="delegateName"
                    multiline
                    variant="filled"
                    minRows={4}
                    sx={{ mt: 2 }}
                  />
                </Box>

                <Box sx={{ marginBlock: 2 }}>
                  <Box display={"flex"}>
                    <Typography>8.</Typography>
                    <Typography ml={1}>
                      If you any additional information,please state here
                    </Typography>{" "}
                  </Box>

                  <RHFTextField
                    id="filled-textarea"
                    placeholder="Describe Visit"
                    multiline
                    variant="filled"
                    name="additionalInfo"
                    minRows={4}
                    sx={{ mt: 2 }}
                  />
                </Box>

                <Grid item sx={{ textAlign: "right", p: 2 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    endIcon={<Send />}
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Box>
            </Box>
          </Container>
        </FormProvider>
      </Grid>
    </Fragment>
  );
}

export default CBCBookingForm;




