import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Modal,
  Stack,
  Alert,
  Checkbox,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { RHFTextField, FormProvider } from "../../components/hook-form";
import axiosCustomAgent from "../../utils/axiosCustomAgent";
import { useForm } from "react-hook-form";
import logoBg from "../../images/sslagBookingForm.JPG";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import useAuth from "src/hooks/useAuth";

function SchoolCJStaffReport() {
  const [loading, setLoading] = useState(false);
  let { token } = useParams();
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [linkStatus, setLinkStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      try {
        const response = await axiosCustomAgent.post(
          `SchoolIntervention/VerifyInterventionToken`,
          {
            schoolId,
            formName: "Staff Status Report",
            token,
          }
        );

        const { statusCode, message } = response.data;

        if (statusCode === 500) {
          throw new Error(message);
        }

        setLinkStatus(message);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId !== null) validateToken();
  }, [schoolId]);

  const defaultValues = {
    employeeName: "",
    empoyeeId: "",
    employeeRole: "",
    employeeDateOfBirth: "",
    employeeStartDate: "",
    employeeEndDate: "",
    reasonsForStatusChange: "",
    reasonsForRecruitment: "",
    effectiveResumptionDate: "",
    reasonsForLeaving: "",
    effectiveEndDate: "",
    disciplinaryActionFaced: "",
    disciplinaryDueDate: "",
    safeRecruitmentChecksCompleted: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axiosCustomAgent.post(
        `SchoolIntervention/SubmitStaffStatusReportInterventionForm`,
        {
          schoolId,
          token,
          employeeName: data.employeeName,
          empoyeeId: data.empoyeeId,
          employeeRole: data.employeeRole,
          employeeDateOfBirth: data.employeeDateOfBirth,
          employeeStartDate: data.employeeStartDate,
          employeeEndDate: data.employeeEndDate,
          reasonsForStatusChange: data.reasonsForStatusChange,
          reasonsForRecruitment: data.reasonsForRecruitment,
          effectiveResumptionDate: data.effectiveResumptionDate,
          reasonsForLeaving: data.reasonsForLeaving,
          effectiveEndDate: data.effectiveEndDate,
          disciplinaryActionFaced: data.disciplinaryActionFaced,
          disciplinaryDueDate: data.disciplinaryDueDate,
          safeRecruitmentChecksCompleted: data.safeRecruitmentChecksCompleted,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
        //  methods.reset
      });

      methods.reset();
      setFormSubmitted(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading ...</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link not valid") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>This link is not valid.</Typography>
        </Box>
      </Box>
    );
  }
  if (!loading && linkStatus === "Link has been used") {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>You previously provided a response </Typography>
        </Box>
      </Box>
    );
  }

  if (formSubmitted) {
    return (
      <Box minHeight={"100vh"} display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography>Form submitted successfully</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Box>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
            paddingTop: 5,
          }}
        >
          <Box width={500} textAlign={"center"}>
            <Box>
              <Grid ml={17}>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 200,
                  }}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
          </Stack>

          <Container>
            <Box mt={2}>
              <Typography variant="h5">
                SSLAG (SSR) STAFF STATUS REPORT
              </Typography>
            </Box>

            <Grid>
              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Employee Information
                </Typography>
              </Box>

              <Box display={"flex"} justifyContent={"space-between"} p={2}>
                <Grid minWidth={"45%"}>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      name="employeeName"
                      label="Employee Name"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      name="employeeRole`"
                      label="Role"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Start Date"
                        value={methods.watch().employeeStartDate}
                        onChange={(value) => {
                          methods.setValue("employeeStartDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeStartDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid minWidth={"45%"}>
                  <Box sx={{ marginBlock: 2 }}>
                    <RHFTextField
                      name="empoyeeId"
                      label="Employee ID"
                      variant="filled"
                    />
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Date of birth"
                        disableFuture
                        value={methods.watch().employeeDateOfBirth}
                        onChange={(value) => {
                          methods.setValue("employeeDateOfBirth", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeDateOfBirth"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ marginBlock: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Report end date"
                     
                        value={methods.watch().employeeEndDate}
                        onChange={(value) => {
                          methods.setValue("employeeEndDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="employeeEndDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Box>

              <Box
                sx={{
                  background: "#097969",
                  borderRadius: 0.5,
                  boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                  color: "#ffff",
                  minHeight: 40,
                }}
              >
                <Typography variant="h6" padding={2}>
                  Reasons for status change
                </Typography>
              </Box>
              <Box p={2}>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="reasonsForRecruitment"
                      label="Enter reason for recruitment"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter effective 
                        resumption date"
                   
                        value={methods.watch().effectiveResumptionDate}
                        onChange={(value) => {
                          methods.setValue("effectiveResumptionDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="effectiveResumptionDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="reasonsForLeaving"
                      label="Enter reason for leaving"
                      multiline={true}
                      minRows={3}
                      variant="filled"
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter effective end 
                        date"
                   
                        value={methods.watch().effectiveEndDate}
                        onChange={(value) => {
                          methods.setValue("effectiveEndDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="effectiveEndDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="disciplinaryActionFaced"
                      label="State if staff has faced any disciplinary actions
                      In this employment (if reporting end date)"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter due date"
                   
                        value={methods.watch().disciplinaryDueDate}
                        onChange={(value) => {
                          methods.setValue("disciplinaryDueDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="disciplinaryDueDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="safeRecruitmentChecksCompleted"
                      label="State if safe recruitment checks are completed in 
                      respect of this employment (if reporting start date)"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Enter date"
                   
                        value={methods.watch().startDateForSafeRecruitmentCheck}
                        onChange={(value) => {
                          methods.setValue(
                            "startDateForSafeRecruitmentCheck",
                            value,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="startDateForSafeRecruitmentCheck"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <RHFTextField
                      name="fullName"
                      label=" Full name"
                      variant="filled"
                      multiline={true}
                      minRows={3}
                    />
                  </Box>

                  <Box sx={{ marginBlock: 2, minWidth: "45%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Date SSR Completed"
                   
                        value={methods.watch().toDate}
                        onChange={(value) => {
                          methods.setValue("toDate", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="toDate"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid sx={{ textAlign: "right" }}>
              <LoadingButton
                variant="outlined"
                type="submit"
                sx={{ color: "#fff", width: 160, background: "#018fe2" }}
                // startIcon={<Back />}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Container>
        </FormProvider>
      </Box>
    </Fragment>
  );
}

export default SchoolCJStaffReport;
