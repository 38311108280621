import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
// layouts
import LogoOnlyLayout from "../../layouts/LogoOnlyLayout";
// routes
import { PATH_AUTH } from "../../routes/paths";
// components
import Page from "../../components/Page";
// sections
import { ResetPasswordForm } from "../../sections/auth/reset-password";
// assets
import { SentIcon } from "../../assets";
import bg1 from "../../images/bg1.jpg";
import { useNavigate } from "react-router-dom";

import { FormProvider, RHFTextField } from "src/components/hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Iconify from "../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { useSnackbar } from "notistack";
import ClerkConfirmPasswordForm from "./ClerkConfirmPasswordForm";
// import Iconify from "../../../components/Iconify";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  // justifyContent: "center",
  padding: theme.spacing(12, 0),
  color: "#fff",
}));
const onSubmit = async (data) => {
  // try{
  // }catch
};

// ----------------------------------------------------------------------

export default function ClerkConfirmPassword() {
  const [email, setEmail] = useState("");
  const [stakeholderCategoryId, setStakeholderCategoryId] = useState("");
  const [sent, setSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isCodeResending, setIsCodeResending] = useState(false);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const ResetSchema = Yup.object().shape({
    code: Yup.string().required("please fill"),
    password: Yup.string()
      .required("Password is required")
      .min(9, "password must be at least 9 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref("password"), null], "confirm password does not match"),
  });

  const defaultValues = {
    code: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(ResetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <Page title="Reset Password" sx={{ height: 1 }}>
      <RootStyle
        sx={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${bg1})`,
        }}
      >
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: "auto" }}>
            <Box>
              <Typography variant="h3" paragraph>
                Create your password
              </Typography>
              <Typography sx={{ color: "#fff", mb: 5 }}>
                Please create a password to use on the platform
              </Typography>

              <ClerkConfirmPasswordForm />

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ mt: 1 }}
              >
                Back
              </Button>
            </Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
