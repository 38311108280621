import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Container,
  Stack,
  Alert,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import logoBg from "../../images/sslagBookingForm.JPG";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";

function AdminJourneyDataExtractionTable() {
  const { stakeholderCategory, id } = useAuthAdmin();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let { tokenId, formId, schoolId } = useParams();
  const [dataExtraction, setDataExtraction] = useState(null);

  useEffect(() => {
    const getAllData = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgentForAdmin.get(
          `GetDataExtractionInterventionResponse?schoolId=${schoolId}&tokenid=${tokenId}&formId=${formId} `
        );
        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }
        setDataExtraction(response.data.data);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (id !== null && id !== "") getAllData();
    return () => {};
  }, [formId, tokenId, schoolId]);

  if (dataExtraction === null && loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">Loading ...</Typography>
        </Box>
      </Box>
    );
  }

  if (dataExtraction === null) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h5">No Record Found....</Typography>
        </Box>
      </Box>
    );
  }

 

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid>
          <Box textAlign={"center"}>
            <Box>
              <Grid>
                <img
                  src={logoBg}
                  alt=""
                  style={{
                    width: 400,
                  }}
                />
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: "#018fe2",
                mt: 2,
              }}
            >
              Data Extraction Table
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid>
        <Grid p={2}>
          <Box
            sx={{
              background: "#018fe2",
              borderRadius: 0.5,
              boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
              color: "#ffff",
              minHeight: 40,
            }}
          >
            <Typography variant="h6" padding={2}>
              Extraction Table
            </Typography>
          </Box>
        </Grid>
        <Container>
          <Box display={"flex"} justifyContent={"space-around"}>
            <Box minWidth={"37"}>
              <Grid>
                <Typography>No. of annexes</Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.numOfAnnexes}
                  type="number"
                  label="Response"
                  variant="filled"
                  placeholder="Response ....."
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnNumOfAnnexes}
                label="Remark"
                variant="filled"
                placeholder="Remark ....."
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography>Addresses of annexes</Typography>
              </Grid>
              <Grid>
                <RHFTextField
                  value={dataExtraction.annexesAddress}
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnAnnexesAddress}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography>No. of section per annex</Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.numOfSectionPerAnnex}
                  label="Response "
                  type="number"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnNumOfSectionPerAnnex}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography>Date school was founded</Typography>
              </Grid>

              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    value={dataExtraction.dateSchoolFounded}
                    // onChange={(value) => {
                    //   methods.setValue("dateSchoolFounded", value, {
                    //     shouldValidate: true,
                    //   });
                    // }}
                    renderInput={(params) => (
                      <RHFTextField
                        variant="filled"
                        {...params}
                        name="dateSchoolFounded"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnDateSchoolFounded}
                label="Remark"
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"35%"}>
              <Grid>
                <Typography>Total Staff strength</Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.totalStaffstrength}
                  label="Response"
                  type="number"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnTotalStaffstrength}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"35%"}>
              <Grid>
                <Typography>Name of Principal</Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.nameOfPrincipal}
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnNameOfPrincipal}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography>Telephone No. of Principal</Typography>
              </Grid>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  value={dataExtraction.phoneNumberOfPrincipal}
                  type="number"
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnPhoneNumberOfPrincipal}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography> Email address of Principal </Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.emailOfPrincipal}
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnEmailOfPrincipal}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Grid minWidth={"20%"}>
              {/* <FormHelperText>Name</FormHelperText> */}

              <Typography> No. of pupils on roll</Typography>
            </Grid>

            <Grid minWidth={"35%"}>
              <RHFTextField
                value={dataExtraction.numOfPupilsOnRoll}
                label="Response"
                placeholder="Response ....."
                variant="filled"
              />
            </Grid>
            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnNumOfPupilsOnRoll}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"35%"}>
              <Grid>
                <Typography fontSize={"14px"}>
                  School composition: Pry, Secondary or both?
                </Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.schoolComposition}
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnSchoolComposition}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"35%"}>
              <Grid>
                <Typography fontSize={"14px"}>
                  {" "}
                  School structure: Day, Boarding or both?
                </Typography>
              </Grid>

              <Grid>
                <RHFTextField
                  value={dataExtraction.schoolStructure}
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnSchoolStructure}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
          <Box display={"flex"} justifyContent={"space-around"} mt={2}>
            <Box minWidth={"36%"}>
              <Grid>
                <Typography fontSize={"14px"}>
                  Age of the youngest pupil in the lowest grade
                </Typography>
              </Grid>

              <Grid minWidth={"35%"}>
                <RHFTextField
                  value={dataExtraction.ageofYoungestPupil}
                  type="number"
                  label="Response"
                  placeholder="Response ....."
                  variant="filled"
                />
              </Grid>
            </Box>

            <Grid minWidth={"35%"}>
              <RHFTextField
                multiline={true}
                minRows={3}
                value={dataExtraction.remarksOnAgeofYoungestPupil}
                label="Remark"
                placeholder="Remark ....."
                variant="filled"
              />
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Fragment>
  );
}

export default AdminJourneyDataExtractionTable;
