import {
  Grid,
  Box,
  Alert,
  Stack,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../../components/hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import axoisCustomAgent, {
  axoisCustomAgentForAdmin,
} from "../../../utils/axiosCustomAgent";
import useAuthAdmin from "src/hooks/useAuthAdmin";
import { useNavigate, useParams } from "react-router";
import AdminBG from "../../../images/background.png";

function LessonObservation({ section, item, schoolId, auditId }) {
  const { enqueueSnackbar } = useSnackbar();
  // const [loading, setLoading] = useState(false);
  const { stakeholderCategory, id } = useAuthAdmin();
  const [observation, setObservation] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { taskId } = useParams();

  // localStorage.getItem('items', JSON.stringify(item));
  const items = JSON.parse(localStorage.getItem("items"));

  const defaultValues = {
    // new====================
    groupInformationPropsUsed: "",
    subjectObserved: "",
    otherUsefulObservation: "",
    numberOfSupportStaff: "",
    genderMix: "",
    evidenceOfSafetyAwarenessForStaff: "",
    yearGroupObserved: "",
    evidenceOfRiskAssessmentInLessons: "",
    isThereFireActionNotice: "",
    areSafeGuidesOnDisplay: "",
    teacherPupilsRapport: "",
    isRoomFreeOfHazard: "",
    isThereAccessToWater: "",
    isClassTooLarge: "",
    classControl: "",
    evidenceOfSafeLearning: "",
    appropriateTeacherPupilRatio: "",
    comment: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const option = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const Navigate = () => {
    navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
  };
  // const onSubmit = () => {};

  const onSubmit = async (data) => {
    try {
      const response = await axoisCustomAgent.post(
        "/Audit/SumbitSectionOneLessonObservation",
        {
          auditTaskId: taskId,
          groupInformationPropsUsed: data.groupInformationPropsUsed,
          // emaisThereFireActionNoticeil: data.emaisThereFireActionNoticeil,
          subjectObserved: data.subjectObserved,
          otherUsefulObservation: data.otherUsefulObservation,
          numberOfSupportStaff: data.numberOfSupportStaff,
          genderMix: data.genderMix,
          evidenceOfSafetyAwarenessForStaff:
            data.evidenceOfSafetyAwarenessForStaff,
          yearGroupObserved: data.yearGroupObserved,
          evidenceOfRiskAssessmentInLessons:
            data.evidenceOfRiskAssessmentInLessons,
          isThereFireActionNotice: data.isThereFireActionNotice,
          areSafeGuidesOnDisplay: data.areSafeGuidesOnDisplay,
          teacherPupilsRapport: data.teacherPupilsRapport,
          isRoomFreeOfHazard: data.isRoomFreeOfHazard,
          isThereAccessToWater: data.isThereAccessToWater,
          isClassTooLarge: data.isClassTooLarge,
          classControl: data.classControl,
          evidenceOfSafeLearning: data.evidenceOfSafeLearning,
          appropriateTeacherPupilRatio: data.appropriateTeacherPupilRatio,
          comment: data.comment,
        }
      );

      if (response.status === 500) {
        throw new Error("server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/auditClerks/AuditGradingGeneral/${taskId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Fragment>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingInline: "20px",
          paddingTop: 5,
          background: `url(${AdminBG})`,
          minHeight: "100vh",
          backgroundCover: "contain",
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} m={2}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Box textAlign={"center"}>
              <Typography
                variant="h5"
                sx={{
                  background: "#00bbea",
                  textAlign: "centre",
                  // mt: 2,
                  p: 2,
                }}
              >
                Section 1A: 1 Lesson Observation
              </Typography>
            </Box>

            <ol>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <li>
                      <Typography>Group Information/ Props Used?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="groupInformationPropsUsed"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>

                  <Box>
                    <li>
                      <Typography>Is fire action notice available?</Typography>
                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereFireActionNotice"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>Are safety guidelines on display?</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        getOptionLabel={option.map((x) => x.label)}
                        name="areSafeGuidesOnDisplay"
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>Teacher/Pupils Rapport?</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="teacherPupilsRapport"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>Is room free of physical hazards?</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isRoomFreeOfHazard"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>Is access to water available?</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isThereAccessToWater"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>Are class size too large?</Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="isClassTooLarge"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                </Box>

                <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <li>
                      <Typography>
                        Effective behaviour management/class control?
                      </Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="classControl"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box sx={{ marginBlock: 1, width: 400 }}>
                    <li>
                      <Typography>
                        Appropriate teacher/pupil ratio maintained?
                      </Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="appropriateTeacherPupilRatio"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>
                        General evidence of a safe learning taking place?
                      </Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfSafeLearning"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>
                        Evidence of safety awareness &approved professional
                        development for staff on school safety?
                      </Typography>

                      <FormControl>
                        <RHFRadioGroup
                          options={option.map((x) => x.value)}
                          name="evidenceOfSafetyAwarenessForStaff"
                          getOptionLabel={option.map((x) => x.label)}
                        />
                      </FormControl>
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <Typography>
                        Evidence of risk assessment in lesson?
                      </Typography>

                      <RHFRadioGroup
                        options={option.map((x) => x.value)}
                        name="evidenceOfRiskAssessmentInLessons"
                        getOptionLabel={option.map((x) => x.label)}
                      />
                    </li>
                  </Box>
                  <Box>
                    <li>
                      <RHFTextField
                        name="yearGroupObserved"
                        label="Year Group Observed"
                        variant="filled"
                      />
                    </li>
                  </Box>
                </Box>

                {/* 222222222222222222222222222 */}
                <Box sx={{ marginBlock: 1, width: 400, p: 2 }}>
                  <Box>
                    <li>
                      <RHFTextField
                        name="subjectObserved"
                        label="Subject Observed"
                        variant="filled"
                      />
                    </li>
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <li>
                      <RHFTextField
                        name="genderMix"
                        label="gender eg (male, female, both)"
                        variant="filled"
                      />
                    </li>
                  </Box>
                  <Box sx={{ marginBlock: 1 }}>
                    <li>
                      {" "}
                      <RHFTextField
                        name="numberOfSupportStaff"
                        label="Number of support staff"
                        variant="filled"
                        type="number"
                      />
                    </li>
                  </Box>

                  <Box sx={{ marginBlock: 1 }}>
                    <li>
                      <RHFTextField
                        name="comment"
                        label="Comments?"
                        variant="filled"
                        multiline
                        minRows={3}
                      />
                    </li>
                  </Box>

                  <Grid sx={{ textAlign: "right", mt: 1 }}>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      // onClick={Navigate}
                      loading={isSubmitting}
                      sx={{ width: 100, color: "#fff", background: "#018fe2" }}
                      // startIcon={<Back />}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Box>

                {/* ================================================================= */}
              </Box>
            </ol>
          </Stack>
        </FormProvider>
      </Grid>
    </Fragment>
  );
}

export default LessonObservation;
