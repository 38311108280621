import React, { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Box, Typography, Grid, Button, Card } from "@mui/material";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useSnackbar } from "notistack";

import useAuth from "src/hooks/useAuth";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
function AllClasses() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  useEffect(() => {
    const getAllClasses = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllYearGroupsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;

        const classArms = [];

        if (data != null) {
          data.forEach((yearGroup, outerIndex) => {
            const { yearGroupName, classes } = yearGroup;

            if (classes.length === 0) {
              classArms.push({
                className: "",
                yearGroupName,
              });
            } else {
              classes.forEach((eachClass) => {
                const { className } = eachClass;
                classArms.push({ yearGroupName, className });
              });
            }
          });
        }

        setClasses([...classArms]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    if (schoolId != null) getAllClasses();
  }, [schoolId]);

  const Gridimage = {
    background: "#fafafa",
    // minHeight: "100vh",
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = classes.filter(
    (item) =>
      item.className &&
      item.className.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "Year Group",
      selector: (row) => row.yearGroupName,
    },
    {
      name: "Class Name",
      selector: (row) => row.className,
    },

    // { cell: (row) => <MoreMenuButton /> },
  ];

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Classes...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">School Management</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/addClass"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Add Year Group
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/dashboard/user/classArm"
            className="header"
          >
            Add Class
          </Button>

          <Button
            href="/dashboard/user/assign"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Assign Class Tutor
          </Button>
          <Button
            href="/dashboard/user/class"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            All Year Group
          </Button>
        </Box>
      </Box>

      <Card>
        <Grid p={3} style={Gridimage}>
          {classes.length === 0 ? (
            <Typography> No Class present</Typography>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              responsive
              customStyles={customStyles}
            />
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default AllClasses;
