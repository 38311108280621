import merge from "lodash/merge";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, TextField } from "@mui/material";
// components
import { BaseOptionChart } from "../../components/chart";

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    // year: 2022,
    data: [
      { name: "School", data: [45, 41, 15, 51, 49, 62, 69, 91, 148] },
      { name: "Assessors", data: [11, 34, 45, 56, 77, 88, 99, 77, 45] },
      {
        name: "Suppliers",
        data: [15, 56, 13, 56, 77, 88, 93, 77, 45],
      },
      { name: 'Student', data: [20, 34, 13, 56, 23, 88, 99, 77, 43] },
      { name: 'Collaborators & SME', data: [14, 34, 13, 56, 77, 88, 99, 77, 45] },
      // { name: 'EPraise level', data: [10, 34, 13, 56, 77, 88, 99, 77, 25] },
      // { name: 'Response score', data: [12, 34, 13, 56, 77, 88, 99, 77, 85] },
      // { name: 'SMS indicator level', data: [248, 91, 69, 62, 49, 71, 35, 41, 10] },
    ],
  },
  // {
  //   year: 2020,
  //   data: [
  //     { name: 'Asia', data: [148, 91, 69, 62, 49, 51, 35, 41, 10] },
  //     { name: 'America', data: [45, 77, 99, 88, 77, 56, 13, 34, 10] },
  //   
  // },
];

export default function AdminDashboard1() {
  const [seriesData, setSeriesData] = useState();

  const handleChangeSeriesData = (event) => {
    setSeriesData(Number(event.target.value));
  };

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  });

  return (
    <Card>
      <CardHeader
        title="Analytics"
        subheader=""
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              "& fieldset": { border: "0 !important" },
              "& select": {
                pl: 1,
                py: 0.5,
                pr: "24px !important",
                typography: "subtitle2",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 0.75,
                bgcolor: "background.neutral",
              },
              "& .MuiNativeSelect-icon": {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            {CHART_DATA.map((option) => (
              <option key={option.year} value={option.year}>
                {option.year}
              </option>
            ))}
          </TextField>
        }
      />

      {CHART_DATA.map((item) => (
        <Box key={item.year} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.year === seriesData && (
            <ReactApexChart
              type="line"
              series={item.data}
              options={chartOptions}
              height={364}
            />
          )}
        </Box>
      ))}
    </Card>
  );
}
















// import {
//   Link,
//   Card,
//   CardHeader,
//   Stack,
//   Grid,
//   Typography,
//   Button,
//   CardActions,
// } from "@mui/material";
// import React, { useState } from "react";
// import Iconify from "../../components/Iconify";
// import { styled } from "@mui/material/styles";

// import DataTable from "react-data-table-component";

// function AdminDashboard1({schools}) {
//     const Gridimage = {
//       background: "black",
//       borderRadius:"10px",

//     };

//     const columns = [
//       {
//         name: "#",
//         cell: (row, index) => <p>{index + 1}</p>,
//       },

//       {
//         name: "School Name",
//         selector: (row) => row.schoolName,
//       },

//       {
//         name: "School Type",
//         selector: (row) => row.schoolTypeName,
//       },
//       {
//         name: "No Of Student(s)",
//         selector: (row) => row.numberOfStudents,
//       },
//       {
//         name: "No Of Staff",
//         selector: (row) => row.numberOfStaff,
//       },

//     ];
//   return (
//     <Card
//       style={{
//         background: "#edf5f9",
//       }}
//     >
//       <CardHeader />
//       <CardActions sx={{ justifyContent: "space-between" }}>
//         <Typography variant="h6">School Management</Typography>

//         <Button
//           size="small"
//           style={{ color: "#1396e3" }}
//           href="/admin/school/schools-allschools"
//         >
//           View All
//         </Button>
//       </CardActions>
//       <Stack  spacing={2} sx={{ p: 3 }}>
//         <Grid >
//           <Grid style={Gridimage}>
//             <DataTable
//               columns={columns}
//               data={schools}
//               subHeader
//               // subHeaderComponent={subHeaderComponentMemo}
//               // pagination
//               // paginationResetDefaultPage={resetPaginationToggle}
//               responsive

//             />
//           </Grid>
//         </Grid>
//       </Stack>
//     </Card>
//   );
// }

// export default AdminDashboard1;
