import {
  Typography,
  Box as Grid,
  Container,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";

import Logo from "src/components/Logo";
import Bg1 from "../../images/sslagLandingImg.jpg";

import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import LoadingButton from "@mui/lab/LoadingButton";
import Next from "@material-ui/icons/ChevronRight";
import Back from "@material-ui/icons/ChevronLeft";
const INITIAL_STATE = {
  0: {
    starterPackId: 1,
    starterPackName: "Torchlight",
    sslagApprovedQty: 3,
    schoolQty: "",
  },
  1: {
    starterPackId: 2,
    starterPackName: "High Visibility Vest",
    sslagApprovedQty: 5,
    schoolQty: "",
  },
  2: {
    starterPackId: 3,
    starterPackName: "Compliance Manual",
    sslagApprovedQty: 1,
    schoolQty: "",
  },
  3: {
    starterPackId: 4,
    starterPackName: "Wet floor sign",
    sslagApprovedQty: 2,
    schoolQty: "",
  },
  4: {
    starterPackId: 5,
    starterPackName: "Fire exit sign (with the running man)",
    sslagApprovedQty: 5,
    schoolQty: "",
  },
  5: {
    starterPackId: 6,
    starterPackName: "fire door sign",
    sslagApprovedQty: 6,
    schoolQty: "",
  },
  6: {
    starterPackId: 7,
    starterPackName: "lollipop Stop / Go sign",
    sslagApprovedQty: 2,
    schoolQty: "",
  },
  7: {
    starterPackId: 8,
    starterPackName: "Muster point sign",
    sslagApprovedQty: 4,
    schoolQty: "",
  },
  8: {
    starterPackId: 9,
    starterPackName: "Case of fire statement",
    sslagApprovedQty: 4,
    schoolQty: "",
  },
  9: {
    starterPackId: 10,
    starterPackName: "Wash your hands sign",
    sslagApprovedQty: 6,
    schoolQty: "",
  },
  10: {
    starterPackId: 11,
    starterPackName: "First aid signs",
    sslagApprovedQty: 4,
    schoolQty: "",
  },
  11: {
    starterPackId: 12,
    starterPackName: "No smoking sign",
    sslagApprovedQty: 6,
    schoolQty: "",
  },
  12: {
    starterPackId: 13,
    starterPackName: "First aid boxes",
    sslagApprovedQty: 2,
    schoolQty: "",
  },
  13: {
    starterPackId: 14,
    starterPackName:
      "3 types of Fire extinguishers (CO2, Electrical fire, Chemical)",
    sslagApprovedQty: 3,
    schoolQty: "",
  },
  14: {
    starterPackId: 15,
    starterPackName: "Compliance criteria poster",
    sslagApprovedQty: 6,
    schoolQty: "",
  },
  15: {
    starterPackId: 16,
    starterPackName: "SSLAG Rule books",
    sslagApprovedQty: 2,
    schoolQty: "",
  },
  16: {
    starterPackId: 17,
    starterPackName: "CBC certificate",
    sslagApprovedQty: 3,
    schoolQty: "",
  },
  17: {
    starterPackId: 18,
    starterPackName: "SSLAG Posters",
    sslagApprovedQty: 2,
    schoolQty: "",
  },
};

function StarterPark({ nextStep, prevStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useAuth();

  const { schoolId } = userData;

  const [state, setState] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStarterPacks = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `/SchoolOnboarding/GetSchoolStarterPacks/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const starterpacksArray = response.data.data;

          if (starterpacksArray !== null && starterpacksArray.length > 0) {
            setState({ ...starterpacksArray });
          }
        } else {
          throw message;
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchStarterPacks();
  }, [schoolId]);

  const handleChange = (e, index) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        schoolQty: e.target.value,
      },
    });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const starterPacks = Object.keys(state).reduce((acc, eachIndex) => {
      acc.push({
        starterPackId: state[eachIndex].starterPackId,
        schoolQty:
          state[eachIndex].schoolQty === "" ? 0 : state[eachIndex].schoolQty,
      });

      return acc;
    }, []);

    try {
      const response = await axoisCustomAgent.post(
        `/SchoolOnboarding/UpdateSchoolStarterPacks/${schoolId}`,
        {
          starterPacks,
        }
      );

      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        // enqueueSnackbar("successful", { variant: "success" });
        setLoadingSubmit(false);
        nextStep();
      } else {
        throw message;
      }
    } catch (error) {
      enqueueSnackbar("Please fill all available fields", {
        variant: "error",
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  // ==========================

  // =========================

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          background: ` url(${Bg1}) `,
          backgroundSize: "cover",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ justifyContent: "left", padding: 2 }}>
            <Grid>
              <Logo />
            </Grid>
          </Grid>

          <Grid sx={{ padding: 3, justifyContent: "right" }}>
            <Button
              // variant="outlined"
              color="primary"
              href="/auth/login"
              onClick={async () => await handleLogout()}
              sx={{ fontSize: 20 }}
            >
              Sign Out
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ alignSelf: "center" }}>
          <Container
            sx={{
              background: "#ffff",
              borderRadius: 2,
              padding: 2,
              margin: 10,
              boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.5)",
              maxHeight: 1000,
              minWidth: "1000",
            }}
          >
            <Container sx={{}}>
              <Typography
                sx={{
                  padding: 3,
                  color: "#6495ed",
                  textAlign: "center",
                  fontSize: "23px",
                }}
              >
                {" "}
                SSLAG STARTER PACK
              </Typography>
              <Typography mb={2}>
                Enter the quantity of items you have
              </Typography>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <FormGroup>
                  <Grid
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid
                      sx={{
                        width: "50%",
                      }}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Torchlight</Typography>
                        <TextField
                          sx={{ ml: 17, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[0].schoolQty}
                          onChange={(e) => handleChange(e, 0)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography> High Visibility Vest</Typography>
                        <TextField
                          sx={{ ml: 9, mt: 2, width: 100 }}
                          id="Qty"
                          type="number"
                          label="  Quantity "
                          variant="outlined"
                          value={state[1].schoolQty}
                          onChange={(e) => handleChange(e, 1)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Compliance Manual</Typography>
                        <TextField
                          sx={{ mt: 2, width: 100, ml: 8 }}
                          id="Qty"
                          type="number"
                          label="Quantity "
                          variant="outlined"
                          value={state[2].schoolQty}
                          onChange={(e) => handleChange(e, 2)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Wet Floor Sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 13, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[3].schoolQty}
                          onChange={(e) => handleChange(e, 3)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Fire Exit Sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 14, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity "
                          variant="outlined"
                          value={state[4].schoolQty}
                          onChange={(e) => handleChange(e, 4)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Fire Door Sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 13, width: 100 }}
                          id="Qty"
                          type="number"
                          label=" Quantity  "
                          variant="outlined"
                          value={state[5].schoolQty}
                          onChange={(e) => handleChange(e, 5)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Lollipop stop/Go sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 7, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity "
                          variant="outlined"
                          value={state[6].schoolQty}
                          onChange={(e) => handleChange(e, 6)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Muster point statement</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 5, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[7].schoolQty}
                          onChange={(e) => handleChange(e, 7)}
                        />
                      </Stack>
                    </Grid>
                    <Grid
                      sx={{
                        width: "50%",
                      }}
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Case of fire statement</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 10, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[8].schoolQty}
                          onChange={(e) => handleChange(e, 8)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>SSLAG poster </Typography>
                        <TextField
                          sx={{ mt: 2, ml: 18, width: 100 }}
                          id="Qty"
                          type="number"
                          label="  Quantity  "
                          variant="outlined"
                          value={state[17].schoolQty}
                          onChange={(e) => handleChange(e, 17)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>Wash your hands sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 11, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity "
                          variant="outlined"
                          value={state[9].schoolQty}
                          onChange={(e) => handleChange(e, 9)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>First aid sign</Typography>
                        <TextField
                          sx={{ mt: 2, ml: 19, width: 100 }}
                          id="Qty"
                          type="number"
                          label="  Quantity  "
                          variant="outlined"
                          value={state[10].schoolQty}
                          onChange={(e) => handleChange(e, 10)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>No smoking sign </Typography>
                        <TextField
                          sx={{ mt: 2, ml: 15, width: 100 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[11].schoolQty}
                          onChange={(e) => handleChange(e, 11)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>First aid boxes</Typography>
                        <TextField
                          sx={{ ml: 17, width: 100, mt: 2 }}
                          id="Qty"
                          type="number"
                          label="Quantity  "
                          variant="outlined"
                          value={state[12].schoolQty}
                          onChange={(e) => handleChange(e, 12)}
                        />
                      </Stack>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography>SSLAG quick guide</Typography>
                        <TextField
                          sx={{ ml: 13, width: 100, mt: 2 }}
                          id="Qty"
                          type="number"
                          label="Quantity "
                          variant="outlined"
                          value={state[15].schoolQty}
                          onChange={(e) => handleChange(e, 15)}
                        />
                      </Stack>

                      <Grid sx={{ mt: 12, textAlign: "right" }}>
                        <LoadingButton
                          loading={loadingSubmit}
                          label="Prev"
                          variant="contained"
                          type="submit"
                          startIcon={<Back />}
                          sx={{ mr: 4 }}
                          onClick={prevStep}
                        >
                          Back
                        </LoadingButton>

                        <LoadingButton
                          loading={loadingSubmit}
                          label="next"
                          variant="contained"
                          type="submit"
                          endIcon={<Next />}
                          onClick={async () => await handleSubmit()}
                        >
                          Next
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormGroup>
              )}
            </Container>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StarterPark;
