import React from "react";
import Home from "@material-ui/icons/Home";
import User from "@material-ui/icons/VerifiedUser";
import Arrow from "@material-ui/icons/ArrowDropDown";
import Close from "@material-ui/icons/ArrowRight";
import GroupAdd from "@material-ui/icons/GroupAdd";
import AdminIcon from "@material-ui/icons/SettingsApplicationsSharp";

export const TeacherData = [
  {
    title: "Dashboard",
    path: "/staff/dashboard",
    icon: <Home />,
  },

  {
    title: "Attendance",
    path: "attendance",
    icon: <AdminIcon />,
    iconClosed: <Close />,
    iconOpened: <Arrow />,

    Children: [
      {
        title: "Mark Attendance",
        path: "/staff/teacher/mark-attendance",
        icon: <User />,
      },
      {
        title: "View Attendance",
        path: "/staff/teacher/view-attendance",
        icon: <GroupAdd />,
      },
    ],
  },

  {
    title: "Staff Duty",
    path: "/staff/staffDuty",
    icon: <User />,
  },
];
