import React, { FormHelperText } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Paper,
  Alert,
  Stack,
} from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import Scrollbar from "../../components/Scrollbar";

import Iconify from "../../components/Iconify";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "src/hooks/useAuth";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { useSnackbar } from "notistack";

function ViewAttendance() {
  const { userData } = useAuth();
  const { schoolId } = userData;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [viewattendance, setViewAttendance] = useState([]);
  const [loadingAttendance, setLoadingAttendance] = useState(false);

  const PaperStyle = {
    padding: 40,
    width: 1000,
  };

  const Gridimage = {
    background: "#fafafa",
    minHeight: "50vh",
  };

  const ViewAttendance = Yup.object().shape({
    class: Yup.string().required("class Name is required"),
    classArm: Yup.string().required("class Arm is required"),
    AOD: Yup.string().nullable().required("attendance date  is required"),
  });

  const defaultValues = {
    classArm: "",
    class: "",
    AOD: null,
  };

  const methods = useForm({
    resolver: yupResolver(ViewAttendance),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [classes, setClasses] = useState([
    {
      id: "",
      yearGroupName: "Select Year Group",
    },
  ]);

  useEffect(() => {
    let unmounted = false;
    const getClasses = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/yeargroups/GetAllyeargroupsBySchoolId/${schoolId}`
        );

        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        if (!unmounted) setClasses([...classes, ...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    if (schoolId != null) {
      getClasses();
    }

    return () => {
      unmounted = true;
    };
  }, [schoolId]);

  const [classArms, setClassArms] = useState([
    {
      id: "",
      className: "Select Class",
    },
  ]);
  const watchClass = methods.watch("class");

  const { formState, setValue } = methods;

  useEffect(() => {
    if (formState.dirtyFields.class && watchClass) {
      let classArms = classes.find(
        (item) => `${item.id}` === watchClass
      ).classes;
      setClassArms([
        {
          id: "",
          className: "Select Class",
        },
        ...classArms,
      ]);
    }
  }, [watchClass]);

  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Attendance...</Typography>
        </Box>
      </Box>
    );
  }

  // ===============================
  const onSubmit = async (data) => {
    setLoadingAttendance(true);
    try {
      const date = new Date(data.AOD).toISOString();

      const response = await axoisCustomAgent.get(
        `/Students/GetAllStudentAndAttendanceDetails?schoolId=${schoolId}&yearGroupId=${data.class}&classId=${data.classArm}&attendanceDate=${date}`
      );

      if (response.status === 500) {
        throw new Error("Something went wrong");
      }

      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }

      enqueueSnackbar("successful", {
        variant: "success",
      });

      setViewAttendance([...response.data.data]);

      methods.reset();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoadingAttendance(false);
    }
  };
  // ==============================

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Attendance</Typography>

        <Box paddingRight={5}>
          <Button
            href="/dashboard/user/attendance"
            className="header  "
            sx={{ color: "#6495ed" }}
          >
            Mark Attendance
          </Button>

          <Button
            href="/dashboard/user/allAttendance"
            className="header active"
            sx={{ color: "#6495ed" }}
          >
            View Attendance
          </Button>
          <Button
            href="/dashboard/user/Behaviour"
            className="header"
            sx={{ color: "#6495ed" }}
          >
            Student Behaviour
          </Button>
        </Box>
      </Box>

      <Grid container style={Gridimage} justifyContent="center">
        <Grid item padding={5}>
          <Paper style={PaperStyle}>
            <Grid align="center" mt={3}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                  )}
                </Stack>
                <Grid p={2}>
                  <Box
                    sx={{
                      background: "#34a8e1",
                      borderRadius: 0.5,
                      boxShadow: "1px 1px 1px rgba(0,0,0,0.3)",
                      color: "#ffff",
                      minHeight: 40,
                    }}
                  >
                    <Typography variant="h6" padding={2}>
                      View All Attendance
                    </Typography>
                  </Box>
                </Grid>
                {/* <Typography>View All Attendance</Typography>
                <hr /> */}
                <Grid container spacing={5} mt={2}>
                  <Grid item width={300}>
                    <FormHelperText>Select Year Group :</FormHelperText>
                    <RHFTextField
                      select
                      name="class"
                      // required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {classes.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.yearGroupName}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={300}>
                    <FormHelperText>Select class :</FormHelperText>
                    <RHFTextField
                      select
                      name="classArm"
                      // required
                      variant="filled"
                      SelectProps={{ native: true }}
                    >
                      {classArms.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.className}
                        </option>
                      ))}
                    </RHFTextField>
                  </Grid>

                  <Grid item width={300}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormHelperText>Date :</FormHelperText>
                      <DatePicker
                        label="Attendance Date "
                        value={methods.watch().AOD}
                        onChange={(value) => {
                          methods.setValue("AOD", value, {
                            shouldValidate: true,
                          });
                        }}
                        renderInput={(params) => (
                          <RHFTextField
                            variant="filled"
                            {...params}
                            name="AOD"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item sx={{ textAlign: "right", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    View Attendance
                  </LoadingButton>
                </Grid>
              </FormProvider>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* ==============Table================= */}

      <Fragment>
        {loadingAttendance ? (
          <Typography variant="h3">Loading Attendance....</Typography>
        ) : (
          <Grid style={Gridimage}>
            {viewattendance.length === 0 ? (
              <Typography variant="h5" padding={2} textAlign={"center"}>
                No Student Record
              </Typography>
            ) : (
              <Card sx={{ mt: 5, minWidth: 1000 }}>
                <CardHeader title="All Attendance" sx={{ mb: 3 }} />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Morning</TableCell>
                          <TableCell>Afternoon</TableCell>

                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {viewattendance.map(
                          (eachStudentInAttendance, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {eachStudentInAttendance.firstName}
                              </TableCell>
                              <TableCell>
                                {eachStudentInAttendance.lastName}
                              </TableCell>

                              <TableCell>
                                {eachStudentInAttendance.attendance === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .morningAttendanceStatus === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .morningAttendanceStatus}
                              </TableCell>

                              <TableCell>
                                {eachStudentInAttendance.attendance === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .afternoonAttendanceStatus === null
                                  ? "not taken"
                                  : eachStudentInAttendance.attendance
                                      .afternoonAttendanceStatus}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <Divider />

                <Box sx={{ p: 2, textAlign: "right" }}>
                  <Button
                    size="small"
                    color="inherit"
                    endIcon={<Iconify icon={"eva:arrow-ios-forward-fill"} />}
                  >
                    View All
                  </Button>
                </Box>
              </Card>
            )}
          </Grid>
        )}
      </Fragment>
    </Box>
  );
}

export default ViewAttendance;
