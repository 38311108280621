// // ============================================================
import PropTypes from "prop-types";
import { m } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Popover,
  ListItem,
  ListSubheader,
  CardActionArea,
  Button,
} from "@mui/material";
import Home from "@material-ui/icons/Home";
// components
import Iconify from "../../components/Iconify";
import { PaperStyle } from "src/components/nav-section/horizontal/style";

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(3),
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.48,
    textDecoration: "none",
  },
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("color"),
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));

MenuDesktop.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [windowShouldScroll, setWindowShouldScroll] = useState(false);

  const scrollToTarget = (id) => {
    navigate(`/#${id}`);
    setWindowShouldScroll(true);
  };

  useEffect(() => {
    // Check if there's a hash in the URL (e.g., #element-id)
    const hash = window.location.hash;
    if (hash && windowShouldScroll) {
      // Remove the leading # from the hash to get the element ID
      const elementId = hash.substring(1);
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        var fixedHeaderHeight = 70;

        // scroll to your element
        targetElement.scrollIntoView(true);

        // now account for fixed header
        var scrolledY = window.scrollY;

        if (scrolledY) {
          window.scroll(0, scrolledY - fixedHeaderHeight);
        }
      }
    }
    return () => {
      setWindowShouldScroll(false);
    };
  }, [windowShouldScroll]);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem
          key={link.title}
          item={link}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
          scrollToTarget={scrollToTarget}
        />
      ))}
    </Stack>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(["item", "subheader"]),
};

function IconBullet({ type = "item" }) {
  return (
    <Box sx={{ width: 24, height: 16, display: "flex", alignItems: "center" }}>
      <Box
        component="span"
        sx={{
          ml: "2px",
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "currentColor",
          ...(type !== "item" && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
      {/* <PaperStyle></PaperStyle> */}
    </Box>
  );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({
  item,
  isHome,
  isOpen,
  isOffset,
  onOpen,
  onClose,
  scrollToTarget,
}) {
  const { title, path, children } = item;
  const menuRef = useRef(null);
  const navigate = useNavigate();

  // const scrollToTarget = (id) => {
  //   navigate(`/#${id}`);
  //   const targetElement = document.getElementById(id);
  //   console.log({ targetElement });

  //   if (targetElement) {
  //     targetElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHomeClick = () => {
    // navigate(`/`);
    navigate(`/`);
    onOpen();
  };

  if (children) {
    if (title === "Home") {
      return (
        <>
          <Box
            ref={menuRef}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            <LinkStyle
              variant="body2"
              onClick={handleHomeClick}
              sx={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                ...(isHome && { color: "#6495ed" }),
                ...(isOffset && { color: "text.primary" }),
                ...(isOpen && { opacity: 0.48 }),
              }}
            >
              {/* color={"#6495ed"} */}
              {title}
              <Iconify
                icon={
                  isOpen
                    ? "eva:arrow-ios-upward-fill"
                    : "eva:arrow-ios-downward-fill"
                }
                sx={{ ml: 0.5, width: 16, height: 16 }}
              />
            </LinkStyle>

            <PaperStyle
              open={open}
              anchorEl={menuRef.current}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              //anchorPosition={{ top: 70, left: 0 }}
              // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              // transformOrigin={{ vertical: "top", horizontal: "center" }}
              //onClose={handleClose}
              // PaperProps={{
              //   sx: {
              //     px: 3,
              //     pt: 5,
              //     pb: 3,
              //     right: 16,
              //     m: "auto",
              //     borderRadius: 2,
              //     maxWidth: (theme) => theme.breakpoints.values.lg,
              //     boxShadow: (theme) => theme.customShadows.z10,
              //   },
              // }}
            >
              <Grid container spacing={1} direction={"column"}>
                {children.map((list, index) => {
                  const { subheader, items } = list;

                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      md={subheader === "Documentation" ? 6 : 2}
                    >
                      <List disablePadding>
                        {/* <ListSubheader
                      disableSticky disableGutters sx=
                      {{
                        display: "flex",

                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                      >
                      <IconBullet type="subheader" /> {subheader}
                      </ListSubheader> */}
                        {items.map((item) =>
                          item?.isScroll ? (
                            <ListItemStyle
                              key={item.title}
                              onClick={() => scrollToTarget(item.path)}
                              component={Button}
                              underline="none"
                              sx={{
                                "&.active": {
                                  color: "text.primary",
                                  typography: "subtitle2",
                                },
                              }}
                            >
                              <>
                                {item.icon ?? <IconBullet />}

                                {item.title}
                              </>
                            </ListItemStyle>
                          ) : (
                            <ListItemStyle
                              key={item.title}
                              to={item.path}
                              component={RouterLink}
                              underline="none"
                              sx={{
                                "&.active": {
                                  color: "text.primary",
                                  typography: "subtitle2",
                                },
                              }}
                            >
                              <>
                                {item.icon ?? <IconBullet />}

                                {item.title}
                              </>
                            </ListItemStyle>
                          )
                        )}
                      </List>
                    </Grid>
                  );

                  // <PaperStyle></PaperStyle>
                })}
              </Grid>
            </PaperStyle>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box ref={menuRef} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
          <LinkStyle
            variant="body2"
            onClick={onOpen}
            sx={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              ...(isHome && { color: "#6495ed" }),
              ...(isOffset && { color: "text.primary" }),
              ...(isOpen && { opacity: 0.48 }),
            }}
          >
            {/* color={"#6495ed"} */}
            {title}
            <Iconify
              icon={
                isOpen
                  ? "eva:arrow-ios-upward-fill"
                  : "eva:arrow-ios-downward-fill"
              }
              sx={{ ml: 0.5, width: 16, height: 16 }}
            />
          </LinkStyle>

          <PaperStyle
            open={open}
            anchorEl={menuRef.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            //anchorPosition={{ top: 70, left: 0 }}
            // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            // transformOrigin={{ vertical: "top", horizontal: "center" }}
            //onClose={handleClose}
            // PaperProps={{
            //   sx: {
            //     px: 3,
            //     pt: 5,
            //     pb: 3,
            //     right: 16,
            //     m: "auto",
            //     borderRadius: 2,
            //     maxWidth: (theme) => theme.breakpoints.values.lg,
            //     boxShadow: (theme) => theme.customShadows.z10,
            //   },
            // }}
          >
            <Grid container spacing={1} direction={"column"}>
              {children.map((list, index) => {
                const { subheader, items } = list;

                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={subheader === "Documentation" ? 6 : 2}
                  >
                    <List disablePadding>
                      {/* <ListSubheader
                      disableSticky disableGutters sx=
                      {{
                        display: "flex",

                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                      >
                      <IconBullet type="subheader" /> {subheader}
                      </ListSubheader> */}
                      {items.map((item) =>
                        item?.isScroll ? (
                          <ListItemStyle
                            key={item.title}
                            onClick={() => scrollToTarget(item.path)}
                            component={Button}
                            underline="none"
                            sx={{
                              "&.active": {
                                color: "text.primary",
                                typography: "subtitle2",
                              },
                            }}
                          >
                            <>
                              {item.icon ?? <IconBullet />}

                              {item.title}
                            </>
                          </ListItemStyle>
                        ) : (
                          <ListItemStyle
                            key={item.title}
                            to={item.path}
                            component={RouterLink}
                            underline="none"
                            sx={{
                              "&.active": {
                                color: "text.primary",
                                typography: "subtitle2",
                              },
                            }}
                          >
                            <>
                              {item.icon ?? <IconBullet />}

                              {item.title}
                            </>
                          </ListItemStyle>
                        )
                      )}
                    </List>
                  </Grid>
                );

                // <PaperStyle></PaperStyle>
              })}
            </Grid>
          </PaperStyle>
        </Box>
      </>
    );
  }

  if (title === "Documentation") {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        variant="h5"
        sx={{
          ...(isHome && { color: "common.white" }),
          ...(isOffset && { color: "text.primary" }),
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      variant="subtitle2"
      to={path}
      component={RouterLink}
      end={path === "/"}
      sx={{
        ...(isHome && { color: "#6495ed" }),
        ...(isOffset && { color: "text.primary" }),
        "&.active": {
          color: "primary.main",
        },
      }}
    >
      {title}
    </LinkStyle>
  );
}
