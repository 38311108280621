import React, { Fragment, useState, useEffect } from "react";
import AdminBG from "../../images/background.png";
import proficeIcon from "../../images/Gov.png";
import {
  Alert,
  Stack,
  Grid,
  Box,
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
  RHFRadioGroup,
} from "../../components/hook-form";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import axoisCustomAgent from "../../utils/axiosCustomAgent";
import { useTheme } from "@mui/material/styles";
import Label from "../../components/Label";
import { sentenceCase } from "change-case";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  0 &:hover {
    cursor: pointer;
  }
`;
const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function AandESchool() {
  const [newTask, setNewTask] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        // background: "#34a8e1",
        fontSize: "19px",
        borderRadius: 2,
      },
    },
  };

  const defaultValues = {
    // new========================
    reason: "",
  };

  const methods = useForm({
    // resolver: yupResolver(),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentTask, setCurrentTask] = useState(undefined);

  const handleClickOpen = (taskId) => {
    setCurrentTask(taskId);
    setOpen(true);
  };

  const handleClickClose = () => {
    setCurrentTask(undefined);
    setOpen(false);
  };

  const handleOpenReject = (taskId) => {
    setCurrentTask(taskId);
    setDialogOpen(true);
  };

  const handleCloseReject = () => {
    setCurrentTask(undefined);
    setDialogOpen(false);
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = newTask.filter(
    (item) =>
      item.schoolName &&
      item.schoolName.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    // return (
    //   <FilterComponent
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "S/N",
      cell: (row, index) => <p>{index + 1}</p>,
    },
    {
      name: "School Name",
      selector: (row) => row.school.schoolName,
    },
    {
      name: "Audit Date",
      selector: (row) => row.dateOfAudit.split("T")[0],
    },
    {
      name: "Date Created",
      selector: (row) => row.dateCreated.split("T")[0],
    },
    {
      name: "Validation Doc.",
      cell: (row) => (
        <Label variant={theme.palette.mode === "light" ? "ghost" : "filled"}>
          {sentenceCase(row.isValidationDocumentSent ? "Sent" : "Not Sent")}
        </Label>
      ),
      //   selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    // {
    //   name: "Clerk assigned",
    //   selector: (row) => row.auditClerk,
    // },
    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            // onClick={() => handleOpen(row.id)}
            onClick={() => handleClickOpen(row.id)}
          >
            {" "}
            Accept
          </Button>
        </>
      ),
    },
    {
      cell: (row) => (
        <>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => handleOpenReject(row.id)}
            // onClick={() => RejectTask(row.id)}
          >
            {" "}
            Reject
          </Button>
        </>
      ),
    },

    // { cell: (row) => <MoreMenuButton /> },
  ];

  const DeleteTask = (taskId) => {
    const Task = [...newTask];
    const updatedTask = Task.filter((x) => x.id !== taskId);

    setNewTask(updatedTask);
  };

  useEffect(() => {
    let unmounted = false;
    const getAllNewTask = async () => {
      setLoading(true);
      try {
        const response = await axoisCustomAgent.get(
          `AandE/GetAuditTasks?status=${1}`
        );
        const { status } = response.data;

        const { statusCode, message } = response.data;

        if (statusCode !== 200) {
          throw new Error(message);
        }

        const { data } = response.data;
        setNewTask([...data]);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } finally {
        setLoading(false);
      }
    };
    getAllNewTask();
    return () => {
      unmounted = true;
    };
  }, []);

  const AcceptTask = async (taskId) => {
    try {
      const response = await axoisCustomAgent.put(
        `/AandE/AcceptAuditTask/${taskId}`
      );

      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleClickClose();
      DeleteTask(taskId);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const RejectTask = async (data) => {
    try {
      const response = await axoisCustomAgent.put(
        `/AandE/RejectAuditTask/${currentTask}
        `,
        {
          reason: data.reason,
        }
      );
      // /api/AandE/k/{taskId}
      if (response.status === 500) {
        throw new Error("Server error");
      }
      const { statusCode, message } = response.data;

      if (statusCode !== 200) {
        throw new Error(message);
      }
      enqueueSnackbar(message, {
        variant: "success",
      });
      handleCloseReject();
      DeleteTask(currentTask);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };



  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading All Record...</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <Typography variant="h4">Assessor Dashboard</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minWidth: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h4">Task</Typography>

        <Box paddingRight={5}>
          <Button
            sx={{ color: "#6495ed" }}
            href="AandEAssignSchool"
            className="header active"
          >
            New Task
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/pendingTask"
            className="header"
          >
            Pending Task
          </Button>

          <Button
            sx={{ color: "#6495ed" }}
            href="/AandE/completeTask"
            className="header "
          >
            Completed Task
          </Button>
        </Box>
      </Box>

      <Box>
        <Card
          sx={{
            minWidth: "80%",
            mt: 4,
          }}
        >
          <Grid p={3}>
            {newTask.length === 0 ? (
              <Typography>No records found</Typography>
            ) : (
              <DataTable
                columns={columns}
                data={newTask}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                responsive
                customStyles={customStyles}
              />
            )}
          </Grid>
        </Card>
      </Box>
      {/* Accept Task */}
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Accept Task
        </DialogTitle>
        <DialogContent
          sx={{
            mt: 3,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Do you want to accept this audit task?.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickClose}>No</Button>
          <Button onClick={() => AcceptTask(currentTask)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reject Task */}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* {"Use Google's location service?"} */}
          Reject Task
        </DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(RejectTask)}>
          <DialogContent
            sx={{
              mt: 3,
            }}
          >
            <DialogContentText id="alert-dialog-description">
              Do you want to reject this audit task?.
            </DialogContentText>

            <Box>
              <Stack spacing={1} m={3}>
                {!!errors.afterSubmit && (
                  <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
              </Stack>

              <RHFTextField
                name="reason"
                label="Reason"
                variant="filled"
                multiline
                minRows={2}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleCloseReject}>
              No
            </Button>
            <Button type="submit" loading={isSubmitting}>
              Yes
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </Fragment>
  );
}

export default AandESchool;
