import { Fragment, useState } from "react";
import { sentenceCase } from "change-case";
// @mui
import { useTheme } from "@mui/material/styles";

// components
import axoisCustomAgent from "src/utils/axiosCustomAgent";
import { setSessionCustom } from "src/utils/jwtCustomAgent";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import useSettings from "../../hooks/useSettings";
import { Container, Grid, Box, Typography } from "@mui/material";
import { Alerts, AppNewInvoice } from "src/sections/@dashboard/general/app";

// ----------------------------------------------------------------------

export default function Pending() {
  const { user } = useAuth();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  //=====================================
  const { userData } = useAuth();
  const { schoolId, isOnboardingCompleted, schoolName } = userData;

  const initialState = {
    schoolInformation: [],
    invoices: [],
    schoolDirectorAlerts: [],
    schoolMOEAlerts: [],
    schoolHMOAlerts: [],
    schoolPersonnelInfoAlerts: [],
    schoolProvisionAlerts: [],
    schoolSafetyCBCALerts: [],
    AllAlerts: [],
  };

  const [state, setState] = useState(initialState);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDashboardDetails = async () => {
      setLoading(true);

      try {
        const response = await axoisCustomAgent.get(
          `/Schools/GetSchoolDashboardDetails/${schoolId}`
        );

        const { statusCode, message } = response.data;

        if (statusCode === 200) {
          const { data } = response.data;
          const {
            schoolDirectorAlerts,
            schoolMOEAlerts,
            schoolHMOAlerts,
            schoolPersonnelInfoAlerts,
            schoolProvisionAlerts,
            schoolSafetyCBCALerts,
          } = data;

          setState({
            ...data,
            AllAlerts: [
              ...schoolDirectorAlerts,
              ...schoolMOEAlerts,
              ...schoolHMOAlerts,
              ...schoolPersonnelInfoAlerts,
              ...schoolProvisionAlerts,
              ...schoolSafetyCBCALerts,
            ],
          });
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (schoolId && isOnboardingCompleted) {
      fetchDashboardDetails();
    }
  }, [schoolId, isOnboardingCompleted]);

  const [isSchoolOpenSample, setIsSchoolOpenSample] = useState(false);

  //==================================

  //====================================

  // check when it is loading
  if (loading) {
    return (
      <Box
        minHeight={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography>Loading Notifications....</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Fragment>
      <Box>
        {state.invoices.length > 0 ? (
          <Grid item xs={12} lg={8}>
            <AppNewInvoice invoices={state.invoices} />
          </Grid>
        ) : (
          <Typography>No Invoice </Typography>
        )}
      </Box>
      <Box>
        {state.AllAlerts.length > 0 ? (
          <Grid item xs={12} lg={8}>
            <Alerts alerts={state.AllAlerts} heading={"Pending Alert"} />
          </Grid>
        ) : (
          <Typography mt={4}>No Alerts</Typography>
        )}
      </Box>
    </Fragment>
  );
}
