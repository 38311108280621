import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuthAdmin from "../hooks/useAuthAdmin";
// pages
import Login from "../pages/auth/Login";
// components
import LoadingScreen from "../components/LoadingScreen";
import AdminLogin from "src/sections/Admin/AdminLogin";

// ----------------------------------------------------------------------

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default function AdminGuard({ children }) {
  const { stakeholderCategory, isInitialized } = useAuthAdmin();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (stakeholderCategory !== "Admins") {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <AdminLogin />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
