import { m } from "framer-motion";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Container,
  Typography,
  Grid,
  Tab,
  Tabs,
  // TabPanel,
 
} from "@mui/material";
import React, { useState, useEffect } from "react";

// components
import {
  MotionContainer,
  TextAnimate,
  varFade,
} from "../../../components/animate";
import PropTypes from "prop-types";
import { MotionInView } from "../../../components/animate";
import Pending from "./Pending";
import RejectedConcession from "./RejectedConcession";
import ApprovedConcession from "./ApprovedConcession";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
  minWidth: "100%",
  [theme.breakpoints.up("md")]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    position: "absolute",
    bottom: theme.spacing(5),
  },
}));

// ----------------------------------------------------------------------

export default function AdminConcession() {
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <RootStyle>
      <Container sx={{ mt: 10, minWidth: "100%" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Grid item sx={{ textAlign: "right" }}>
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Pending" {...a11yProps(0)} />
                    <Tab label="Rejected " {...a11yProps(1)} />
                    <Tab label="Approved" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Pending />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                  <RejectedConcession />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <ApprovedConcession />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
